import React from 'react';
import {   withRouter    } from "react-router-dom";  
import {Host as host , Img as img, ImgGral as img2, gmapsKey} from './../../../app.json'
import estadosJSON from './../../../estados.json'
import { Globalrequest as request } from './../../../request.js';
import GoogleMapReact from 'google-map-react';
import NumberFormat from 'react-number-format';
import axios from 'axios';
import {Switch,TimePicker,Row,Col,Select,Modal} from 'antd';
import moment from 'moment'
import Loading from '../../../modalCargando/loading';
import {Marka} from '../../../modalCargando/miDiv';

import {userContext} from './../context'

import './sucursal.scss';

class Sucursal extends React.Component{

  static contextType = userContext

	constructor(props){
    super(props);

    this.cancelar = this.cancelar.bind(this)

    /* Funciones asociadas a los eventos del mapa */
    this.apiLoadEnd = this.apiLoadEnd.bind(this)
    //this.mapChange = this.mapChange.bind(this)
    this.mapDragEnd = this.mapDragEnd.bind(this)
    this.findMe = this.findMe.bind(this)
    this.findMe2 = this.findMe2.bind(this)

    /* Funciones asociadas a la funcionalidad de la página */
    this.tipoDePagoChange = this.tipoDePagoChange.bind(this)
    this.rangoChange = this.rangoChange.bind(this)
    this.controlSemanal = this.controlSemanal.bind(this)
    this.changeSwitch2 = this.changeSwitch2.bind(this)
    this.submit = this.submit.bind(this)

    this.goToPanel = this.goToPanel.bind(this)
    this.handleModal = this.handleModal.bind(this)

    /* */

    this.entregaChange = this.entregaChange.bind(this)
    this.costoChange = this.costoChange.bind(this)
    this.cambioMinino = this.cambioMinino.bind(this)

    this.opAuxChange = this.opAuxChange.bind(this)
      

    this.state = {
      codPostalValue:'',
      /**/
      APIMaps:{},
      /**/
      metadata:'',
      entrega:2,
      costo:false,
      gratis:true,
      minimo:2,
      /* */
      center: { lat: 16.859166 , lng: -99.872328, },
      pendiente:false,
      map:'',
      circle:'',
      tipoPagoSucursal:[],
      tipoPagoDomicilio:[],
      rango:0,
      semaforo:1,
      deshabilitado:false,
      /* horasDeHorario */
      desde1: moment('00:00:00', 'HH:mm:ss'), hasta1: moment('00:00:00', 'HH:mm:ss'),/* Lunes */
      desde2: moment('00:00:00', 'HH:mm:ss'), hasta2: moment('00:00:00', 'HH:mm:ss'),/* Martes */
      desde3: moment('00:00:00', 'HH:mm:ss'), hasta3: moment('00:00:00', 'HH:mm:ss'),/* Miercoles */
      desde4: moment('00:00:00', 'HH:mm:ss'), hasta4: moment('00:00:00', 'HH:mm:ss'),/* Jueves */
      desde5: moment('00:00:00', 'HH:mm:ss'), hasta5: moment('00:00:00', 'HH:mm:ss'),/*  Viernes */
      desde6: moment('00:00:00', 'HH:mm:ss'), hasta6: moment('00:00:00', 'HH:mm:ss'),/* Sabado */
      desde7: moment('00:00:00', 'HH:mm:ss'), hasta7: moment('00:00:00', 'HH:mm:ss'),/* Domingo */
      /* */
      tituloVentana:'',
      modal:false,
      nombreSuc: '',
      loading:false,
      tiposPagoSucursal:[],
      tiposPagoDomicilio:[],

      modalAlert:false,
      txtAlert:'',
      marker:false,
      dataAux:[],
      opAux:'',
    }
  }

  componentDidMount(){
    document.title = "Registro de sucursal";
    let {validCurrentUser} = this.props
    
    if(this.context['type']===2){
      let obj = JSON.parse(JSON.stringify(this.context))
      if(Object.entries(this.context['data']).length ===0){
        let permisosPreSaved = sessionStorage.getItem('perfiles')||'none'
        if(permisosPreSaved!=='none')
          obj['data'] = JSON.parse(permisosPreSaved)  
      }
      let uservalid = validCurrentUser(obj,'sucursales','object')
      if((Number(uservalid['CREAR']) !== 1)){
        this.props.redirectTo2('/home/sucursales')
        return
      }
    }

    axios.post(`${host}empresas/empresas/listaMetodosDePago`,0,request).then(rt=>{
      this.setState({
        tiposPagoSucursal:rt.data.filter(tp => Number(tp['TIPO']) ===1 ),
        tiposPagoDomicilio:rt.data.filter(tp => Number(tp['TIPO']) ===3 ),
      })
    })
    this.props.setMensaje(1)
    this.props.updateState('nopuedoCambiarSinAvisarte',true)
    if( (localStorage.getItem('mail') === null || localStorage.getItem('mail') === undefined) &&
      (sessionStorage.getItem('mail') === null || sessionStorage.getItem('mail') === undefined)
    ){
      this.props.redirectTo('/')
      return
    }
    let data = JSON.parse(sessionStorage.getItem('metadata'))
    let tituloVentana = data['sucursales'].length === 0 ? 'Registra tu primera sucursal' : 'Registro de sucursal' 
    this.setState({tituloVentana:tituloVentana,metadata:data})    
  }

  /* Funciones asociadas a los eventos del mapa */

  apiLoadEnd(map, maps){
    let circle = new maps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.3,
      center: this.state.center,
      radius: 1000,
    })

    let APIMaps = maps

    this.setState({circle:circle,map:map,APIMaps:APIMaps})

    map.setOptions({
      fullscreenControl: false,
      mapTypeControl: true,
      zoomControl: true,
      zoomControlOptions: {
        style: maps.ZoomControlStyle.SMALL
      },
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
    })

    let controlUI = document.createElement('div');
    controlUI.className="findme"
    controlUI.title="Ir a mi ubicación actual"
    let centerControlDiv = document.createElement('div');
    centerControlDiv.appendChild(controlUI);
    controlUI.addEventListener('click', this.findMe ) ;
    centerControlDiv.index = 1;
    map.controls[maps.ControlPosition.RIGHT_CENTER].push(centerControlDiv);
  }

  findMe(){
    let {map} = this.state
    let location = window.navigator && window.navigator.geolocation
    if (location) {
      location.getCurrentPosition((position) => {
        map.setCenter({lat:position.coords.latitude,lng: position.coords.longitude})
      })
      this.setState({map:map})
    }
  }

  findMe2(){
    let {map,marker,dataAux} = this.state
    let data = { lat : map.getCenter().lat() , lng : map.getCenter().lng() }
    if(!marker)
    axios.post(`${host}empresas/empresas/getLocation`,data,request).then(rt=>{
      if(rt.data['status']==='OK'){
        let Numcolonia = ''
        let Nomcolonia = ''
        let political = ''
        let neighborhood = ''
        let pais = ''
        let estado = ''

        let results = rt.data['results'][0]['address_components']
        for(let pos in results ){
          let type = results[pos]['types'][0]
          let value = results[pos]['long_name']
          if(type==='postal_code'){ this.ponValores('codigoPostald',Number(value)) }
          if(type==='country'){ /*this.ponValores('paisd',value)*/ pais = value  }
          if(type==='administrative_area_level_1'){ /*this.ponValores('estadod',value)*/ estado = value }
          if(type==='locality'){ this.ponValores('ciudadd',value) }
          if(type==='street_number'){ Numcolonia = value }
          if(type==='route'){ Nomcolonia = value }
          if(type==='neighborhood'){ neighborhood = value }
          if(type==='political'){ political = value }
        }
        this.ponValores('called',Nomcolonia+' núm. '+Numcolonia)
        this.ponValores('coloniad',neighborhood !== '' ? neighborhood : political)
        if( pais.trim().toLowerCase() === "mexico" || pais.trim().toLowerCase() === "méxico" )pais = "México"
        if(pais === "México"){
          dataAux = []
          for(let estadoJSON in estadosJSON){
            if(estadoJSON.toUpperCase().includes(estado.toUpperCase())){
              dataAux = estadosJSON[estadoJSON]
            }
          }
          this.setState({dataAux:dataAux})
        }
        this.ponValores('paisd',pais)
        this.ponValores('estadod',estado)
      }
    })
    //axios.post(url,0,request).then(rt=>{    })
    //document.getElementById('findme2').style.display = 'none'
    this.setState({marker:!marker})
  }

  ponValores(id,valor){
    if(document.getElementById(id)!==null && document.getElementById(id)!==undefined)
      document.getElementById(id).value = valor
    if(id==="codigoPostald")
      this.setState({codPostalValue:valor})
  }

  /*mapChange(data){
    let {center} = data
    let {circle}  = this.state
    if(circle==='')return
    circle.setCenter(center)
  }*/

  mapDragEnd(map){
    let c = map.getCenter()
    let lat = c.lat()
    let lng = c.lng()
    this.setState({
      center: { lat: lat , lng: lng , dataAux:[]}
    });
    //document.getElementById('findme2').style.display='block'
    document.getElementById('paisd').value = ''
    document.getElementById('estadod').value = ''
    document.getElementById('ciudadd').value = ''
    if(document.getElementById('municipiod')!==undefined && document.getElementById('municipiod')!==null)document.getElementById('municipiod').value = ''
    document.getElementById('coloniad').value = ''
    
    document.getElementById('codigoPostald').value = ''
    this.setState({codPostalValue:''})

    document.getElementById('called').value = ''
    document.getElementById('numExtd').value = ''
    document.getElementById('numIntd').value = ''

  }

  /* Funciones asociadas a la funcionalidad de la página */

  tipoDePagoChange(value,op){
    if(op===1){
      this.setState({tipoPagoSucursal:value})
    }
    else if(op===2){
      this.setState({tipoPagoDomicilio:value})
    }
  }

  rangoChange(event){
    this.props.updateState('nopuedoCambiarSinAvisarte',true)
    let value = Number(event.target.value)
    let {circle,map} = this.state
    circle.setRadius( 1000 * value )
    circle.setMap(map)
    this.setState({rango:value,circle:circle,map:map})
  }

  controlSemanal(event){
    if(event.target.id !== 'mibandera')return
    let {semaforo,deshabilitado} = this.state
    if( semaforo === 1 ){
      if(document.getElementById('swSlave2').getAttribute('aria-checked')==='false')document.getElementById('swSlave2').click()
      if(document.getElementById('swSlave3').getAttribute('aria-checked')==='false')document.getElementById('swSlave3').click()
      if(document.getElementById('swSlave4').getAttribute('aria-checked')==='false')document.getElementById('swSlave4').click()
      if(document.getElementById('swSlave5').getAttribute('aria-checked')==='false')document.getElementById('swSlave5').click()
      if(deshabilitado)this.setState({deshabilitado:false})
    }
    else if( semaforo === 2){
      document.getElementById('swSlave1').click()
      this.setState({deshabilitado:true})
    }
    else if( semaforo === 3){
      if(document.getElementById('swSlave2').getAttribute('aria-checked')==='true')document.getElementById('swSlave2').click()
      if(document.getElementById('swSlave3').getAttribute('aria-checked')==='true')document.getElementById('swSlave3').click()
      if(document.getElementById('swSlave4').getAttribute('aria-checked')==='true')document.getElementById('swSlave4').click()
      if(document.getElementById('swSlave5').getAttribute('aria-checked')==='true')document.getElementById('swSlave5').click()
    }
    semaforo++;
    if(semaforo===4){ semaforo=1; }
    this.setState({semaforo:semaforo})
  }
  
  changeSwitch2(checked,event,idBloque){
    document.getElementById(idBloque).style.display=checked?'flex':'none'
    this.props.updateState('nopuedoCambiarSinAvisarte',true)
  }

  submit(){

    let equivalenciaCampos={
      nombre:'Nombre de la sucursal',
      telefono:'Telefono',
      whats:'Whatsapp',
      pais:'País',
      estado:'Estado',
      ciudad:'Ciudad',
      colonia:'Colonia',
      codigoPostal:'Código postal',
      calle:'Calle',
      numExt:'Núm. Exterior',
    }

    let {entrega,costo,minimo,rango} = this.state

    let formData = new FormData(document.getElementById('formulasucursalContactos'))
    let {tipoPagoDomicilio,tipoPagoSucursal,dataAux,opAux} = this.state

    let valueMinimo = document.getElementById('minimod').value
    valueMinimo = valueMinimo.split(',').join('').split('MXN').join('').split('$').join('').split(' ').join('')
    
    if(tipoPagoSucursal.length === 0){
        this.setState({modalAlert:true,txtAlert:"Debe elegir al menos un método de pago para su sucursal"})
        return
    }

    if(Number(minimo)===1&&valueMinimo===''){
      this.setState({modalAlert:true,txtAlert:"Debes especificar el mínimo de compra"})
      return
    }

    formData.append('servicio',entrega)
    formData.set('costo',costo)
    formData.append('minimo',minimo)
    formData.append('precioMinimo',valueMinimo)
    formData.append('cobertura',rango)

    let nombreSuc = formData.get('nombre')
    let data = {}

    let telefono = formData.get('telefono');
    telefono = telefono.split('_').join('');
    telefono = telefono.split('(').join('');
    telefono = telefono.split(')').join('');
    telefono = telefono.split(' ').join('');

    formData.set('telefono',telefono)

    if(formData.get('telefono').length < 10){
        this.setState({modalAlert:true,txtAlert:"El teléfono debe tener 10 caracteres"})
        return
    }

    let whats = formData.get('whats');
    whats = whats.split('_').join('');
    whats = whats.split('(').join('');
    whats = whats.split(')').join('');
    whats = whats.split(' ').join('');
    
    formData.set('whats',whats)

    if(formData.get('whats').length < 10){
        this.setState({modalAlert:true,txtAlert:"El campo whatsapp debe tener 10 caracteres"})
        return
    }

    if( Number(formData.get('servicio')) === 1 && tipoPagoDomicilio.length===0){
      this.setState({modalAlert:true,txtAlert:"Debe elegir al menos un método de pago para sus entregas a domicilio"})
      return
    }


    if( Number(formData.get('servicio')) === 1 && formData.get('minimo') === '1' && formData.get('precioMinimo').trim()=== '' ){
      this.setState({modalAlert:true,txtAlert:"Indica el monto mínimo de compra para la entrega a domicilio"})
      return
    }

    for(let iterador of formData.entries() ){
      if(
          iterador[0]==='gratis'||
          iterador[0]==='precioCosto'||
          iterador[0]==='precioMinimo'
        ){data[iterador[0]] = iterador[1];continue;}
      if(iterador[1].trim() === '' ){
        this.setState({pendiente:false})
        this.setState({txtAlert:'El campo '+equivalenciaCampos[iterador[0]]+' es obligatorio',modalAlert:true})
        return
      }
      data[iterador[0]] = iterador[1]
    }

    let horarios = 0
    let activos = 0
    for(let i = 1;i<=7;i++){
      let id = 'swSlave'+i
      let desde = 'desde'+i
      let hasta = 'hasta'+i
      horarios++
      if( document.getElementById(id).getAttribute('aria-checked') === 'true' ){
        if( formData.get(desde).trim() === '' || formData.get(hasta).trim() === '' ){
          this.setState({pendiente:false})
          this.setState({txtAlert:'No puede haber campos vacíos',modalAlert:true})
          return
        }
        data[desde] = formData.get(desde)
        data[hasta] = formData.get(hasta)
        activos++
      }
      else{
        data[desde] = 'no';
      }
    }

    if(activos===0){
      this.setState({txtAlert:'Debes elegir marcar al menos un día como abierto',modalAlert:true})
      return
    }


    data['horarios'] = horarios

    formData = new FormData(document.getElementById('formulasucursalGogle'))
    formData.append('latitud',this.state.center['lat'])
    formData.append('longitud',this.state.center['lng'])
    //formData.append('idEmpresa',sessionStorage.getItem('id'))
    formData.append('codigo',2)
    formData.append('id',0)
    formData.append('estatus',1)

    for(let iterador of formData.entries() ){
      if( iterador[0]==='numInt'){
        data[iterador[0]] = iterador[1];
        continue;
      }
      if( iterador[1].trim() === '' ){
        this.setState({pendiente:false})
        this.setState({txtAlert:'El campo '+equivalenciaCampos[iterador[0]]+' es obligatorio',modalAlert:true})
        return
      }
      data[iterador[0]] = iterador[1]
    }

    if(dataAux.length>0 && opAux===''){
      this.setState({txtAlert:'Debes elegir un municipio',modalAlert:true})
      return
    }
    this.setState({ pendiente:true, nombreSuc: nombreSuc })
    
    data['Op'] = 1;
    data['idhorario1'] = '0';
    data['idhorario2'] = '0';
    data['idhorario3'] = '0';
    data['idhorario4'] = '0';
    data['idhorario5'] = '0';
    data['idhorario6'] = '0';
    data['idhorario7'] = '0';

    data['idEmpresa'] = sessionStorage.getItem('id')

    data['municipio'] = (data['municipio']===undefined || data['municipio']===null) ? opAux : data['municipio']
    data['tipoPago'] = tipoPagoDomicilio.concat(tipoPagoSucursal)
    
    axios.post(`${host}empresas/empresas/altaUpdateSucursal`,data,request).then(rt=>{
      this.props.updateState('nopuedoCambiarSinAvisarte',false)
      this.setState({pendiente:false})
      let rpa = Number(rt.data)
      if(rpa === -1){//ni repetido ni editado
        this.setState({txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde. [Código de error: SV-ER: #13-01]',modalAlert:true})
      } 
      else if(rpa === 1){
        this.handleModal(true)
      }
      else if(rpa === 2){
        this.setState({txtAlert:'Parece que la sucursal '+nombreSuc+' ya está registrada.',modalAlert:true})
      }
      else{
        this.setState({txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde.',modalAlert:true})
      }
    })
  }

  horaChange(value,name){
    this.props.updateState('nopuedoCambiarSinAvisarte',true)
    if(name === 'desde1'){
      for(let i=1; i<=7; i++ ){
        let pos = 'desde'+i
        this.setState({ [pos] : value })
      }
    }
    else if(name === 'hasta1'){
      for(let i=1; i<=7; i++ ){
        let pos = 'hasta'+i
        this.setState({ [pos] : value })
      }
    }
    else{
      this.setState({[name]:value})
    }

  }
  
  jumpClick(e){
    if(e.target.className==='inputs-label')e.preventDefault()
  }

  goToPanel(){
    let data = {mail:sessionStorage.getItem('mail'),op:-1,detalle:1}

    let userSubType = localStorage.getItem('userSubType')||-1
    let typeSubUser = localStorage.getItem('typeSubUser')||-1
    let subusuario = false
    
    if(userSubType === 'nosubuser' && Number(typeSubUser)===3){
      data['userSubType'] = localStorage.getItem('submail')
      subusuario = true
    }

    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/datosPorEmpresa`,data,request).then(rt=>{
      this.setState({loading:false})
      if( rt.data === 0 ){
        this.props.redirectTo('/home/sucursales/nueva') 
      }
      else{
        
        rt.data['datos']['LABEL'] = rt.data['datos']['CORREO_REGISTRO']
        rt.data['datos']['ALIAS'] = btoa(btoa(rt.data['datos']['ALIAS']))

        if(subusuario){
          localStorage.setItem('userSubType','nosubuser')
          localStorage.setItem('typeSubUser','3')

          localStorage.setItem('subid',rt.data['extra']['code'])//subid encriptado
          localStorage.setItem('submail',rt.data['extra']['index'])//submail encriptado
          rt.data['datos']['LABEL'] = rt.data['extra']['LABEL']
          rt.data['datos']['ALIAS'] = btoa(btoa(rt.data['extra']['alias']))
        }

        sessionStorage.setItem('subid',rt.data['extra']['code'])
        sessionStorage.setItem('submail',rt.data['extra']['index'])

        sessionStorage.setItem('metadata',JSON.stringify(rt.data))
        this.props.setSucursales(rt.data['sucursales'])
        this.props.updateState('datos',rt.data['datos'])
        this.props.updateState('sucursales',rt.data['sucursales'])
        this.props.updateState('categorias',rt.data['categorias'])
        this.props.updateState('tags',rt.data['tags'])
        this.props.updateState('notificaciones',rt.data['notificaciones'])
        this.props.redirectTo2('/home/sucursales')
      }
    })
  }

  handleModal(bool){
    this.setState({modal : bool})
  }

  entregaChange(e){
    let valor = Number(e.target.value)
    this.setState({entrega:valor,costo: false, gratis: true ,minimo:2 ,rango : 0  })
    document.getElementById('minimod').value=""
  }

  costoChange(e){
      let { checked , id } = e.target
      if( id === 'checkGratis') this.setState({ gratis: checked , costo: !checked})
      else  this.setState({ gratis: !checked , costo: checked})
      //document.getElementById('costod').value=''
  }

  cambioMinino(e){
    let valor = Number(e.target.value)
    this.setState({minimo:valor})
    document.getElementById('minimod').value=""
    console.log(valor)
    if(valor===2){
      document.getElementById('minimod').style.display = "none"
    }
    else{
      document.getElementById('minimod').style.display = "flex"
    }
  }

  cancelar(){
    this.props.redirectTo2('/home/sucursales/lista')
  }

  opAuxChange(v){
    this.setState({opAux:v})
  }

  codigoChange(a){
    console.log(a,'JAJA')
  }

  render(){
    return( 
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3">
        

        <Loading loading={this.state.pendiente} />

        <Modal centered title="" maskClosable={false} keyboard = {false} 
                okText="Aceptar"
                okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelButtonProps={{ style: { display:'none'} }}
                visible={this.state.modal}
                //visible={true}
                closable={false}
                onOk={this.goToPanel}
                
                className="miAntModal"
            >
                <div className="mymodal">
                    <div className="body-alert" >
                        <img src={`${img2}correo/bubook.png`} alt="..." className="icon-alert" />
                        <div style={{marginBottom:'-20px'}}>
                            <h4> ¡Registro exitoso! </h4>
                            Podrás ver y editar los datos de la sucursal <strong> {this.state.nombreSuc} </strong> en el panel de configuración
                        </div>
                    </div>
                </div>
            </Modal>

        <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
            okText="Aceptar" okButtonProps={{style: { padding:'5px 10px' ,fontSize:'14px' , backgroundImage:'linear-gradient(to right,#FF9700,#F77400)', border:'none', borderRadius:'10px'} }}
            cancelButtonProps={{ style: { padding:'5px 10px',color: '#F77400' , fontSize:'14px' , backgrounColor:'white', border:'1px solid #d5d5d5', borderRadius:'10px',display:'none'} }}
            visible={this.state.modalAlert}
            closable={false}
            onOk={() => this.setState({modalAlert:false,txtAlert:''})}
            className="miAntModal newVar alertMy"
            >
            <div className="myAlertBody">
                {
                    this.state.modalAlert &&
                    <span className="txtAlert"> {this.state.txtAlert} </span>
                }
            </div>
        </Modal>

        {/*<div className="Nav-HomeEmpresa">
        
                  <div className="Nav-Logo" onClick={()=>this.props.redirectTo2('/home')} title="Ir al inicio">
                    <img src={img2+'BuBook_Horizontal.png'} alt="Logo_bubook" />
                    <img src={img2+'Logo_BuBook_Simple.png'} alt="Logo_bubook_simple" />
                  </div>
                
                </div>*/}

        <div className="metaControles">
          <h3 className="tituloPanel"> {this.state.tituloVentana} </h3>
        </div>
        
        <div className="contSucursal">
          <Row>
              <Col span={12}>
                <form id="formulasucursalContactos">

                  <label className="subtitleForm" > Datos generales </label>

                  <div className="inputs-group">
                      <label className="inputs-label"> Nombre de la sucursal </label>
                      <input type="text" className="inputs-input" name="nombre"  onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                  </div>

                  <div className="inputs-group">
                      <label className="inputs-label"> Teléfono </label>
                      <NumberFormat format="(###) ### ####" mask="_" type="text" className="inputs-input" name="telefono"  onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                  </div>

                  <div className="inputs-group">
                      <label className="inputs-label"> Whatsapp </label>
                      <NumberFormat format="(###) ### ####" mask="_" type="text" className="inputs-input" name="whats" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                  </div>

                  <div className="inputs-group">
                    <label className="inputs-label lbl-Activa"> Métodos de pago en sucursal </label>
                    <Select  value={this.state.tipoPagoSucursal} onChange={(v)=>this.tipoDePagoChange(v,1)} mode="multiple" >
                    {
                      this.state.tiposPagoSucursal.map(tp => (
                        <Select.Option value={tp.ID} key={tp.ID} > {tp.NOMBRE} </Select.Option>
                       ))
                    }
                    </Select>
                  </div>

                  <div className="fatherContRadios" >

                  <div className="contRadios" >
                    <label className="ask-Radios"> ¿ Cuentas con servicio a domicilio propio ?  </label>
                    <div className="radiocontrol" >
                        <div className="radiocontrol1">
                            <input type="radio" name="domicilio" checked={this.state.entrega===1} value="1" onChange={this.entregaChange} className="radioBubook" id="domicilio1d" />
                            <label className="labelForRadio" htmlFor="domicilio1d" > Si </label>
                        </div>
                    </div>

                    <div  className="radiocontrol3">
                      <input type="radio" name="domicilio" checked={this.state.entrega===2} value="2" onChange={this.entregaChange} className="radioBubook" id="domicilio2d" />
                      <label className="labelForRadio" htmlFor="domicilio2d"  > No </label>
                    </div>
                    </div>

                    <div className="contRadios" id="minimovisible" style={{display:this.state.entrega===1?'block':'none'}} >

                    <div className="inputs-group">
                      <label className="inputs-label lbl-Activa"> Métodos de pago a domicilio </label>
                      <Select  value={this.state.tipoPagoDomicilio} onChange={(v)=>this.tipoDePagoChange(v,2)} mode="multiple" >
                      {
                        this.state.tiposPagoDomicilio.map(tp => (
                          <Select.Option value={tp.ID} key={tp.ID} > {tp.NOMBRE} </Select.Option>
                         ))
                      }
                      </Select>
                    </div>

                    <br/>

                    <label className="ask-Radios"> ¿ Requieres de un mínimo de compra ?  </label>
                    <div className="radiocontrol">
                        <div className="radiocontrol1">
                            <input type="radio"  name="minimo" value="1" checked={this.state.minimo ===1 } onChange={this.cambioMinino} id="minimo1" className="radioBubook"/>
                            <label className="labelForRadio" htmlFor="minimo1" > Si </label>
                        </div>
                        <div  className="radiocontrol2">
                          <NumberFormat type="text" allowNegative={false} allowLeadingZeros={false} prefix="$" suffix=' MXN' thousandSeparator="," decimalSeparator="." placeholder="Mínimo de compra" disabled={this.state.minimo !== 1 } name="minimo" id="minimod" className="inputIncrustado" style={{display:'none'}} />
                        </div>
                    </div>

                    <div  className="radiocontrol3">
                        <input type="radio" name="minimo" value="2" checked={this.state.minimo ===2 } onChange={this.cambioMinino} id="minimo2" className="radioBubook" />
                        <label className="labelForRadio" htmlFor="minimo2" > No </label>
                    </div>

                    </div>
                  </div>{/*ddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddddd*/}

                  <div id="horariosSemana">

                    <br/>
                    <label className="subtitleForm" > Horarios de atención y servicio </label>

                    <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                        
                        <label className="inputs-label" >
                          {this.state.semaforo === 1 ? 'Lunes a Viernes' : 'Lunes' }
                            <div className="contSwitch">
                              <div className="panelAux" id="mibandera" onClick={this.controlSemanal} ></div>
                              <Switch id="swSlave1" disabled={ this.state.deshabilitado } className="myswitch" onChange={ (checked,event) => this.changeSwitch2(checked,event,'bsw1')} />
                            </div>
                        </label>
                        
                        <div className="mytimers" id="bsw1">
                          <TimePicker allowClear={false} placeholder="Desde" value={this.state.desde1} onChange={ (value,svalue) => this.horaChange(value,'desde1') } name="desde1" format={'HH:mm'} />
                          <TimePicker allowClear={false} placeholder="Hasta" value={this.state.hasta1} onChange={ (value,svalue) => this.horaChange(value,'hasta1') } name="hasta1" format={'HH:mm'} />
                        </div>

                    </div>

                    <div style={{display: this.state.semaforo === 1 ? 'none' : 'block'  }} className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                        
                        <label className="inputs-label">
                          Martes
                          <Switch id="swSlave2" className="myswitch" onChange={ (checked,event) => this.changeSwitch2(checked,event,'bsw2')}/>
                        </label>
                        
                        <div className="mytimers" id="bsw2">
                          <TimePicker allowClear={false} placeholder="Desde" value={this.state.desde2} onChange={ (value,svalue) => this.horaChange(value,'desde2') } name="desde2" format={'HH:mm'} />
                          <TimePicker allowClear={false} placeholder="Hasta" value={this.state.hasta2} onChange={ (value,svalue) => this.horaChange(value,'hasta2') } name="hasta2" format={'HH:mm'} />
                        </div>

                    </div>

                    <div style={{display: this.state.semaforo === 1 ? 'none' : 'block'  }} className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                        
                        <label className="inputs-label">
                          Miércoles
                          <Switch id="swSlave3"  className="myswitch" onChange={ (checked,event) => this.changeSwitch2(checked,event,'bsw3')} />
                        </label>
                        
                        <div className="mytimers" id="bsw3">
                          <TimePicker allowClear={false} placeholder="Desde" value={this.state.desde3} onChange={ (value,svalue) => this.horaChange(value,'desde3') } name="desde3" format={'HH:mm'} />
                          <TimePicker allowClear={false} placeholder="Hasta" value={this.state.hasta3} onChange={ (value,svalue) => this.horaChange(value,'hasta3') } name="hasta3" format={'HH:mm'} />
                        </div>

                    </div>

                    <div style={{display: this.state.semaforo === 1 ? 'none' : 'block'  }} className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                        
                        <label className="inputs-label">
                          Jueves
                          <Switch id="swSlave4" className="myswitch" onChange={ (checked,event) => this.changeSwitch2(checked,event,'bsw4')} />
                        </label>
                        
                        <div className="mytimers" id="bsw4">
                          <TimePicker allowClear={false} placeholder="Desde" value={this.state.desde4} onChange={ (value,svalue) => this.horaChange(value,'desde4') } name="desde4" format={'HH:mm'} />
                          <TimePicker allowClear={false} placeholder="Hasta" value={this.state.hasta4} onChange={ (value,svalue) => this.horaChange(value,'hasta4') } name="hasta4" format={'HH:mm'} />
                        </div>

                    </div>

                    <div style={{display: this.state.semaforo === 1 ? 'none' : 'block'  }} className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                        
                        <label className="inputs-label">
                          Viernes
                          <Switch id="swSlave5" className="myswitch" onChange={ (checked,event) => this.changeSwitch2(checked,event,'bsw5')}/>
                        </label>
                        
                        <div className="mytimers" id="bsw5">
                          <TimePicker allowClear={false} placeholder="Desde" value={this.state.desde5} onChange={ (value,svalue) => this.horaChange(value,'desde5') } name="desde5" format={'HH:mm'} />
                          <TimePicker allowClear={false} placeholder="Hasta" value={this.state.hasta5} onChange={ (value,svalue) => this.horaChange(value,'hasta5') } name="hasta5" format={'HH:mm'} />
                        </div>

                    </div>
                  </div>

                  <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                      
                      <label className="inputs-label">
                        Sábado
                        <Switch id="swSlave6" className="myswitch" onChange={ (checked,event) => this.changeSwitch2(checked,event,'bsw6')} />
                      </label>
                      
                      <div className="mytimers" id="bsw6" >
                        <TimePicker allowClear={false} placeholder="Desde" value={this.state.desde6} onChange={ (value,svalue) => this.horaChange(value,'desde6') } name="desde6" format={'HH:mm'} />
                        <TimePicker allowClear={false} placeholder="Hasta" value={this.state.hasta6} onChange={ (value,svalue) => this.horaChange(value,'hasta6') } name="hasta6" format={'HH:mm'} />
                      </div>

                  </div>

                  <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                      
                      <label className="inputs-label">
                        Domingo
                        <Switch id="swSlave7" className="myswitch" onChange={ (checked,event) => this.changeSwitch2(checked,event,'bsw7')} />
                      </label>
                      
                      <div className="mytimers" id="bsw7">
                        <TimePicker allowClear={false} placeholder="Desde" value={this.state.desde7} onChange={ (value,svalue) => this.horaChange(value,'desde7') } name="desde7" format={'HH:mm'} />
                        <TimePicker allowClear={false} placeholder="Hasta" value={this.state.hasta7} onChange={ (value,svalue) => this.horaChange(value,'hasta7') } name="hasta7" format={'HH:mm'} />
                      </div>

                  </div>

                </form>
              </Col>
              <Col span={12}>
                <form id="formulasucursalGogle">
                
                <label className="subtitleForm" > Ubicación de la sucursal </label>
                
                <span className="subtitleForm-span">
                  Indica la ubicación de tu negocio moviendo el mapa.
                </span>

                <div className="inputs-group map">
                    <GoogleMapReact
                      bootstrapURLKeys={{key: gmapsKey}}
                      ref={ ref => {this.mimapa = ref} }
                      draggable={true}
                      center={this.state.center}
                      defaultZoom={15}
                      onChange={this.mapChange}
                      onGoogleApiLoaded={ ({map, maps}) => this.apiLoadEnd(map,maps) }
                      onDragEnd = {this.mapDragEnd}
                      yesIWantToUseGoogleMapApiInternals={true}
                      > 
                    </GoogleMapReact>
                    <Marka/>
                    <div className="findme2" id="findme2" title="Marcar ubicación" onClick={this.findMe2} >
                      <span> {!this.state.marker? 'Marcar ubicación' : 'Editar ubicación' } </span>
                    </div>
                    {this.state.marker&&<div className="masker"></div>}
                  </div>

                  {/*
                    Number(this.state.entrega) === 1
                      ?
                        <div className="inputs-group">
                          <label className="inputs-label"> Indica tu zona de cobertura </label>
                          <input type="range" className="inputs-input myrange" name="cobertura" id="coberturad" onChange={this.rangoChange} min="0" max="10" step="0.1" />
                          <label className="inputs-label labelValueRange" > {this.state.rango} Km </label>
                        </div>
                      :
                      <React.Fragment/>*/
                  }

                  <br/>
                  <label className="subtitleForm" > Completa y confirma la dirección </label>                  

                  <div className="fechaSexo">
                    <div className="inputs-group inputs-group-inline">
                      <label className="inputs-label"> País </label>
                      <input type="text" className="inputs-input" name="pais" id="paisd" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                    </div>

                    <div className="inputs-group inputs-group-inline">
                      <label className="inputs-label"> Estado </label>
                      <input type="text" className="inputs-input" name="estado" id="estadod" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                    </div>
                  </div>

                  <div className="fechaSexo">
                    
                    <div className="inputs-group inputs-group-inline" style={{width:'50%'}} >
                      <label className="inputs-label"> Ciudad </label>
                      <input type="text" className="inputs-input" name="ciudad" id="ciudadd" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                    </div>

                    <div className="inputs-group inputs-group-inline" style={{width:'50%'}} >
                      <label className="inputs-label"> Municipio </label>
                      {
                          this.state.dataAux.length === 0 ?
                            <input type="text" className="inputs-input" name="municipio" id="municipiod" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />:
                            <Select className="myAutocomplete" showSearch value={this.state.opAux} onChange={v => this.opAuxChange(v)}>
                            {
                              this.state.dataAux.map(tp => (
                                <Select.Option value={tp} key={tp} > {tp} </Select.Option>
                               ))
                            }
                            </Select>
                        }
                     </div>
                  </div>

                  <div className="fechaSexo">
                    <div className="inputs-group inputs-group-inline">
                      <label className="inputs-label"> Colonia </label>
                      <input type="text" className="inputs-input" name="colonia" id="coloniad" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                    </div>

                    <div className="inputs-group inputs-group-inline">
                      <label className="inputs-label"> Código postal </label>
                      <NumberFormat value={this.state.codPostalValue} onValueChange={value => this.setState({codPostalValue:value['formattedValue']}) } format="#####" type="text" className="inputs-input fakeNumber" name="codigoPostal" id="codigoPostald" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                    </div>
                  </div>

                  <div className="inputs-group">
                    <label className="inputs-label"> Calle </label>
                    <input type="text" className="inputs-input" name="calle" id="called" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                  </div>

                  <div className="fechaSexo">
                    <div className="inputs-group inputs-group-inline">
                      <label className="inputs-label"> Núm. Exterior </label>
                      <input type="text" className="inputs-input" name="numExt" id="numExtd" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                    </div>
                    <div className="inputs-group inputs-group-inline">
                      <label className="inputs-label"> Núm. Interior <span>(Opcional)</span> </label>
                      <input type="text" className="inputs-input" name="numInt" id="numIntd" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                    </div>
                  </div>

                </form>
              </Col>
          </Row>
        </div>

        <div className="newProductFoot newPromoFoot">
           <div></div>
            <div className="dosBtones">
              <button className="btnEliminar" onClick={this.cancelar} > Cancelar </button>
              <button className="btnAceptar" onClick={this.submit} > Guardar </button>
            </div>
        </div>

        <div className="cont-instancias5"></div>
      </div>
      </div>
      </div>
    );
  }
}

export default withRouter(Sucursal);