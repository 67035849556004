import React from 'react';
/* Librerías */
import axios from 'axios';
import {Host as host, ImgGral as img} from './../app.json';
import { Globalrequest as request } from './../request.js';
import { withRouter} from "react-router-dom";
/* CSS */
import './activacion.scss'

class Activacion extends React.Component {
    constructor(props){
      super(props);

      this.clickBotton = this.clickBotton.bind(this);
      this.reenviar = this.reenviar.bind(this)
      
      this.state = {
          mail: props.match.params.mail,
          error:props.match.params.error,
          redirect: false,
          correo:'',
          valido: false,
          activo:false,
          msj:'Los datos ingresados no son válidos',
          txtButton:'Ir a inicio',
      }
    }

    componentDidMount(){
      document.title = "Activación de cuenta" ;  
      let {mail,error} = this.state
      let errors = ['0xNNN','0xDTC','0xNFD','0xAPE']
      if(!errors.includes(error))return;
      let data = { mail,error }
      axios.post(`${host}usuarios/usuarios/isActive/0`,data,request).then(rt=>{
          if(rt.data['status']==='error'){}

          else if(rt.data['status']==='ok'){
            let {txtButton,valido,msj} = this.state
            if(rt.data['valid']==='valid'){
              txtButton = 'Ir a inicio'
              valido=true
              txtButton = (error ==='0xNNN')?'Iniciar sesión':'Ir a inicio'
              msj = (error ==='0xNNN')?'Su cuenta ha sido activada correctamente':'Los datos ingresados no son válidos'
              let tipe = Number(rt.data['usertype'])
              localStorage.setItem('mail',rt.data['kii'])
              localStorage.setItem('id',rt.data['kiikai'])
              if(tipe===1){
                localStorage.setItem('userType','user')
                localStorage.setItem('typeUser',1)
              }else if(tipe===2){
                localStorage.setItem('userType','nouser')
                localStorage.setItem('typeUser',2)
              }
              this.props.redirectTo('/')

            }else{
              valido=false
              switch(error){
                case '0xDTC':
                txtButton = 'Reenviar correo'
                msj = 'El enlace ha caducado'
                break;
                case '0xNFD':
                txtButton = 'Ir a inicio'
                msj = 'El correo ya no se encuentrá registrado.'
                break;
                case '0xAPE':
                txtButton = 'Reenviar correo'
                msj = 'Parece que ocurrió un error interno, te recomendamos reenviar el correo.'
                break;
                default:
                 txtButton = 'Reenviar correo'
                 msj = 'No pudimos activar tu cuenta, te recomendamos reenviar el correo.'                
                break;
              }
            }

            this.setState({
              correo:rt.data['correo'],
              valido, txtButton,msj
            })
          }
      })
    }

    reenviar(){
      let data = {mail:this.state.correo}
      axios.post(`${host}usuarios/usuarios/sendMail`,data,request).then(rt=>{
          if(Number(rt.data['RESULTADO'])===-1){
              this.setState({ valido: 2 });
          }
          if(Number(rt.data['RESULTADO'])=== 0){
              this.setState({ valido: -1 });
          }
          if(Number(rt.data['RESULTADO'])=== 1){
              this.setState({ valido: 3 });
          }
          switch(rt.data){
              case 1: this.props.redirectTo('/');  break;
              case 2: this.handleValido(-1); break;
              default:console.log("");
          }
      })
    }
    
    clickBotton(){
      let{txtButton}=this.state
      switch(txtButton){
        case 'Ir a inicio':  this.props.redirectTo('/');break;
        case 'Iniciar sesión':this.props.redirectTo('/auth/login');break;
        case 'Reenviar':this.reenviar();break;
        default:break;
      }

    }

    render(){
      return(
        <div className='cont-Aviso'>
          <div className="avisoContainer centrado">
            <img src={`${img}correo/bubook.png`} alt="Bubook" title="Bubook" />
            <span className="avisoTitulo"> {this.state.msj} </span>
            <button className="btn-Aviso" onClick={this.clickBotton} > {this.state.txtButton} </button>
            <div className="powered">
                Copyright &#169; 2019
            </div>
          </div>
        </div>
      );
  }
}


export default withRouter(Activacion);