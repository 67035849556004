import React from 'react';
import axios from 'axios';
import { Globalrequest as request } from './../../../request.js';
import { Host as host ,Img as img , ImgPanelAdmin as imgPanel,bancos} from './../../../app.json'
import { Modal,Spin,Icon ,Input,Select,DatePicker} from 'antd';
import NumberFormat from 'react-number-format';
import moment from 'moment'

import {getImageBanco,formatDatosCard} from './../../../utilities/generalfunctions';

import Loading from '../../../modalCargando/loading';
import {userContext} from './../context'
import ModalAlert from '../../../modalCargando/modalAlert';

class MetodosDePago extends React.Component{

  static contextType = userContext

  constructor(props){
    super(props)

    this.handleError = this.handleError.bind(this)

    this.updateDatos = this.updateDatos.bind(this)
    this.switchType = this.switchType.bind(this)
    /**/
    this.showDetalleTransferencia = this.showDetalleTransferencia.bind(this)
    this.updateDatosTransferencia = this.updateDatosTransferencia.bind(this)
    this.saveDetalleTransferencia = this.saveDetalleTransferencia.bind(this)
    this.deleteDataTransfer = this.deleteDataTransfer.bind(this)

    /**/
    
    this.changeModalStrip = this.changeModalStrip.bind(this)
    
    this.state={
      hoy:moment(),
      bancos:bancos,//["ABC CAPITAL", "ACCIVAL", "ACTINVER", "AFIRME", "AKALA", "AMERICAN EXPRESS", "ASEA", "AUTOFIN", "AZTECA", "B&B", "BAJIO", "BAMSA", "BANAMEX", "BANCO FAMSA", "BANCOMEXT", "BANCOPPEL", "BANJERCITO", "BANOBRAS", "BANORTE", "BANREGIO", "BANSEFI", "BANSI", "BARCLAYS", "BBASE", "BBVA BANCOMER", "BMONEX", "BMULTIVA", "BULLTICK CB", "CB ACTINVER", "CB INTERCAM", "CB JPMORGAN", "CBDEUTSCHE", "CI BOLSA", "CIBANCO", "CLS", "COMPARTAMOS", "CONSUBANCO", "CREDIT SUISSE", "DEUTSCHE", "ESTRUCTURADORES", "EVERCORE", "FINAMEX", "FINCOMUN", "GBM", "HDI SEGUROS", "HIPOTECARIA FEDERAL", "HSBC", "INBURSA", "INDEVAL", "ING", "INTERACCIONES", "INTERBANCO", "INVEX", "IXE", "JP MORGAN", "KUSPIT", "LIBERTAD", "MAPFRE", "MASARI", "MERRILL LYNCH", "MIFEL", "MONEXCB", "NAFIN", "OACTIN", "OPCIONES EMPRESARIALES DEL NOROESTE", "ORDER", "PROFUTURO", "REFORMA", "SANTANDER", "SCOTIABANK", "SEGMTY", "SKANDIA", "SKANDIA", "SOFIEXPRESS", "STERLING", "STP", "SU CASITA", "TELECOMM", "THE ROYAL BANK", "TIBER", "TOKYO", "UBS BANK", "UNAGRA", "UNICA", "VALMEX", "VALUE", "VE POR MAS", "VECTOR", "VOLKSWAGEN", "WAL-MART", "ZURICH", "ZURICHVI"],
      loading:false,
      modalAlert:false,
      txtAlert:'',
      tiposPago:[],
      /**/
      modalTransferencia:false,
      modalNewDataTransfer:false,
      modalConfirmEliminar:false,
      listaDataTransfer:[],
      currentDataTransfer:{},
      bancoActual:0,
      dataTransferInterface:{
        ID:-1,
        BANCO:0,
        NUM_CUENTA:'',
        CUENTA_CLAVE:'',
        NUM_TARJETA:'',
        NOMBRE_TITULAR:'',
        ID_DATOS_TRANSFERENCIA_SUCURSAL:-1,
      },
      /**/
      modalIntegracionStripe:false,
      modalIntegracionStripe2:false,
      tipoEmpresa:0,
    }
  }

  componentDidMount(){
    const {getRef} = this.props;
    getRef(this);
  }

  componentWillUnmount(){
     const {getRef} = this.props;
     getRef(undefined);
  }

  handleError(error,bdd){
    alert(error)
    alert(bdd)
    this.props.close()
  }

  updateDatos(){
    //let configuracion_ventana = "width=550,height=700,menubar=no,resizable=no,scrollbars=yes,location=no,toolbar=no";
    //let stripeReference = window.open('https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_H1wieGKws0kDTNUtVpLerGmejWLjBWwW&scope=read_write','Stripe',configuracion_ventana)
    //stripeReference.onunload = function(){ console.log('unload'); }
    this.setState({loadingStripe:true,txtStripe:'Obteniendo métodos de pago'})
    axios.post(`${host}empresas/empresas/listaIntegracionMetodosDePago`,0,request).then(rt=>{
      let {tiposPago} = this.state
      tiposPago= rt.data
      tiposPago = tiposPago.map(tp => { tp['img'] = this.switchImage(1,tp['META']) ; return tp; }  )
      this.setState({tiposPago,loadingStripe:false,txtStripe:'Obteniendo métodos de pago'})

      let preopen = sessionStorage.getItem('preopen')
      if(preopen==='ok'){
        this.switchType({META:5})
        sessionStorage.removeItem('preopen')
      }

    }).catch(error => this.handleError(error,1))
  }

  switchType(tipo){
    let {validCurrentUser} = this.props
    let permiso = validCurrentUser(this.context,'metodoPago','object',false,false)
    let meta  = Number(tipo['META'])
    if(meta === 5 && Number(permiso['EDITAR'])!==1 ){
      this.setState({txtAlert:'El perfil actual no tiene permiso para editar Integraciones de Métodos de Pago',modalAlert:true})
      return
    }

    this.setState({loading:true})
    switch(meta){
      case 4:
        this.updateDatosTransferencia();
        break;
      case 5:
        let {datos} = this.props
        let data = {idE:datos['ID']}
        axios.post(`${host}empresas/empresas/datosEmpresaStripe`,data,request).then(rt=>{
          if(rt.data['status']==='ok' && Number(rt.data['result'])>0){
            document.getElementById('openHashModal').click()
            this.setState({loading:false})
          }else{
            this.setState({modalIntegracionStripe:true,loading:false})
          }
        })
        break;
       default:
         this.setState({modalAlert:true,txtAlert:'Esta integración no está disponible por el momento.',loading:false})
         break
    }
  }

  updateDatosTransferencia(){
    let {datos} = this.props
    let {listaDataTransfer} = this.state
    let data={idE:datos['ID']}
    axios.post(`${host}empresas/empresas/listaDataTransfer`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        listaDataTransfer = rt.data['result']
        this.setState({loading:false,modalTransferencia:true,listaDataTransfer})
      }
    })
  }

  showDetalleTransferencia(index){
    let {listaDataTransfer,currentDataTransfer,dataTransferInterface,bancoActual} = this.state
    if(index===-1){
      Object.assign(currentDataTransfer,dataTransferInterface)
      bancoActual=1
    }
    else{
      let {validCurrentUser} = this.props
      if(!validCurrentUser(this.context,'metodoPago','bool',false,'EDITAR')){
        this.setState({txtAlert:'El perfil actual no tiene permiso para editar Datos de transferencia',modalAlert:true})
        return
      }
      Object.assign(currentDataTransfer,listaDataTransfer[index])
      bancoActual=Number(currentDataTransfer['BANCO'])
    }
    this.setState({modalNewDataTransfer:true,modalTransferencia:false,currentDataTransfer,bancoActual})
  }

  saveDetalleTransferencia(){
    let {datos} = this.props
    let {listaDataTransfer,currentDataTransfer,dataTransferInterface,bancoActual} = this.state

    let nombreTitular = document.getElementById('nombreTitular').value.trim()
    let numCuenta = document.getElementById('numCuenta').value.trim()
    let clabe = document.getElementById('clabe').value.trim()
    let numTarjeta = document.getElementById('numTarjeta').value.trim()
    
    if(nombreTitular===""||numCuenta===""||clabe===""||numTarjeta===""||bancoActual===0){
      this.setState({txtAlert:'Ningún campo puede estar vacío',modalAlert:true})
      return
    }

    if(numCuenta.length<10){
      this.setState({txtAlert:'El número de cuenta debe terner al menos 10 dígitos',modalAlert:true})
      return
    }

    if(clabe.length<18){
      this.setState({txtAlert:'La cuenta CLABE debe terner al menos 18 dígitos',modalAlert:true})
      return
    }

    numTarjeta = numTarjeta.split('-').join('').split(' ').join('')
    if(numTarjeta.length!==16){
      this.setState({txtAlert:'El número de tarjeta debe tener 16 digitos',modalAlert:true})
      return
    }
    currentDataTransfer['BANCO'] = bancoActual
    currentDataTransfer['NOMBRE_TITULAR'] = nombreTitular
    currentDataTransfer['NUM_CUENTA'] = numCuenta
    currentDataTransfer['CUENTA_CLAVE'] = clabe
    currentDataTransfer['NUM_TARJETA'] = numTarjeta
    currentDataTransfer['idE'] = datos['ID']
    this.setState({loadingStripe:true,txtStripe:'Guardando cambios'})
    axios.post(`${host}empresas/empresas/addDtaTransfer`,currentDataTransfer,request).then(rt=>{
      if(rt.data['status']==='ok'){
        listaDataTransfer = rt.data['msg']
        currentDataTransfer = {}
        Object.assign(currentDataTransfer,dataTransferInterface)
        bancoActual=0
        this.setState({listaDataTransfer,currentDataTransfer,bancoActual,txtStripe:'',loadingStripe:false})
        let rta = Number(rt.data['rta'])
        if(rta>0||rta===-2){
          this.setState({txtAlert:'Datos agregados correctamente',modalAlert:true,modalNewDataTransfer:false,modalTransferencia:true})
        }
        else if(rta === -3){
          this.setState({txtAlert:'Los datos ingresados ya han sido registrados.',modalAlert:true,loadingStripe:false})
        }
        else{
          this.setState({txtAlert:'Parece que ocurrió un error, por favor intentalo nuevamente',modalAlert:true,modalNewDataTransfer:false,modalTransferencia:true,loadingStripe:false})
        }
      }else{
        this.setState({txtAlert:'Parece que ocurrió un error, por favor intentalo nuevamente',modalAlert:true,modalNewDataTransfer:false,modalTransferencia:true,loadingStripe:false})
      }
    })
  }

  deleteDataTransfer(){
    let {listaDataTransfer,currentDataTransfer,dataTransferInterface,bancoActual} = this.state
    this.setState({loadingStripe:true,txtStripe:'Guardando cambios',modalConfirmEliminar:false})
    let data ={idD:currentDataTransfer['ID']}
    axios.post(`${host}empresas/empresas/deleteDataTransfer`,data,request).then(rt=>{
      if(rt.data['status']==='ok' && Number(rt.data['rta'])===1){
        listaDataTransfer = listaDataTransfer.filter(dt => dt['ID'] !== currentDataTransfer['ID'] )
        currentDataTransfer = {}
        Object.assign(currentDataTransfer,dataTransferInterface)
        bancoActual=0
        this.setState({loadingStripe:false,modalTransferencia:true,modalNewDataTransfer:false,modalConfirmEliminar:false,listaDataTransfer,currentDataTransfer,bancoActual,})
      }else{
        this.setState({txtAlert:'Parece que ocurrió un error, por favor intentalo nuevamente',modalAlert:true,modalNewDataTransfer:false,modalTransferencia:true,loadingStripe:false})
      }
    })
  }

  changeModalStrip(op){
    let {datos} = this.props
    if(op===1){
      let {tipoEmpresa} = this.state
      let tipoRegimen = Number(datos['TIPO_REGIMEN'])
      let idRegimen = Number(datos['ID_REGIMEN_FISCAL'])
      tipoEmpresa=tipoRegimen===1?'sole_prop':idRegimen===2?'non_profit':idRegimen===15?'partnership':idRegimen===12?'llc':'corporation'
      this.setState({modalIntegracionStripe2:true, modalIntegracionStripe:false,tipoEmpresa})
    }
    if(op===2){
      this.setState({modalIntegracionStripe:false})
    }
    if(op===3){
      this.setState({modalIntegracionStripe:true,modalIntegracionStripe2:false})
    }
    if(op===4){
      let {tipoEmpresa} = this.state
      let {datos} = this.props
      //let urlStripe="https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_H1wieGKws0kDTNUtVpLerGmejWLjBWwW&scope=read_write&state="+datos['CORREO_REGISTRO']
      //let urlStripe = "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_H1wieGKws0kDTNUtVpLerGmejWLjBWwW&scope=read_write&state="+datos['CORREO_REGISTRO']
      
      let urlStripe = "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=ca_IUbslmS0ka1xK6BCmuoWjdLXwhQzF3jR&scope=read_write&state="+datos['CORREO_REGISTRO']

      let formula1 = new FormData(document.getElementById('formula1'))
      let formula2 = new FormData(document.getElementById('formula2'))      
      for(let data of formula1){
        urlStripe = urlStripe.concat(`&stripe_user[${data[0]}]=${data[1]}`)
      }
      for(let data of formula2){
        urlStripe = urlStripe.concat(`&stripe_user[${data[0]}]=${data[1]}`)
      }
      urlStripe = urlStripe.concat(`&stripe_user[business_type]=${tipoEmpresa}`)
      window.open(urlStripe)
      this.setState({loading:true,modalIntegracionStripe2:false,})
      return
    }
  }
  /**/

  switchImage(op,value){
    if(op===1){
      let obj = {
        4:imgPanel+"transferencia.svg",
        5:imgPanel+"stripe.png",
        6:imgPanel+"paypal.svg",
        7:imgPanel+"mercadoPago.png",
      }
      return obj[value]      
    }
  }

  render(){
    return(
      <Modal centered title="Integración de métodos de pago" maskClosable={false} keyboard = {false} closable={false}
        visible={this.props.visible} zIndex={999} header={null} width={450}
        okText="Cancelar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
        cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',display:'none'} }}
        onOk={this.props.close}
        onCancel={this.props.close}
        style={{borderRadius:'10px!important',padding:'5px!important'}}
        className="miAntModal miconfigModal miconfigModalCitas">

        {/*alta datos transferencia*/}
        <Modal centered title="" header={null} footer={null} onCancel={()=>this.setState({modalNewDataTransfer:false,modalTransferencia:true})}
          visible={this.state.modalNewDataTransfer} className="modalBaseBuBooker" width={400} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}}
          closable={true} destroyOnClose >
          <div className="controlesNuevaCita">
            <div className="title flexcenter Fcenter"> Agregar datos de transferencia </div>
            
            <div className="data flexcenter Fspaceb">
              <label> Banco </label>
            </div>

            <div className="data flexcenter Fspaceb" style={{marginTop:'-15px'}} >
              <Select className="inputs-input" style={{width:'100%'}} value={this.state.bancoActual} onChange={bancoActual => this.setState({bancoActual})} >
              {
                this.state.bancos.map((banco,i)=>(
                  <Select.Option value={i+1} key={'banco_'+i}> {banco} </Select.Option>
                ))
              }
              </Select>
            </div>

            <div className="data flexcenter Fspaceb" style={{marginTop:'0px'}}>
              <label> Nombre del titular </label>
            </div>
            <Input className={"inputs-input"} id="nombreTitular" maxLength={100}  defaultValue={this.state.currentDataTransfer['NOMBRE_TITULAR']} style={{marginTop:'-10px'}} />

            <div className="data flexcenter Fspaceb" style={{marginTop:'5px'}}>
              <label> Número de cuenta </label>
            </div>
            <NumberFormat className="ant-input inputs-input" id="numCuenta" maxLength={20} defaultValue={this.state.currentDataTransfer['NUM_CUENTA']} style={{marginTop:'-10px'}} />

            <div className="data flexcenter Fspaceb" style={{marginTop:'5px'}}>
              <label> Cuenta CLABE </label>
            </div>
            <NumberFormat
              className="ant-input inputs-input"
              id="clabe"
              maxLength={20}
              allowLeadingZeros={true}
              defaultValue={this.state.currentDataTransfer['CUENTA_CLAVE']}
              
              //onPaste={e => console.log('HTMLPaste:',e.clipboardData.getData('Text'))}

              style={{marginTop:'-10px'}}
              />

            <div className="data flexcenter Fspaceb" style={{marginTop:'5px'}}>
              <label> Número de tarjeta </label>
            </div>
            <NumberFormat className="ant-input inputs-input" id="numTarjeta" format='####-####-####-####' mask='_' defaultValue={this.state.currentDataTransfer['NUM_TARJETA']} style={{marginTop:'-10px'}} />

            {
              (
                this.state.currentDataTransfer['ID']!==-1 &&
                this.props.validCurrentUser(this.context,'metodoPago','bool',false,'ELIMINAR')
              )
              ?<button style={{marginTop:'25px',width:'100%'}} className="flexcenter Fcenter btn btn2" onClick={()=>this.setState({modalConfirmEliminar:true})} > Eliminar </button>
              :<button style={{marginTop:'25px',width:'100%'}} className="flexcenter Fcenter btn btn2" onClick={()=>this.setState({modalNewDataTransfer:false,modalTransferencia:true})} > Cancelar </button>
            }
            <button style={{marginTop:'10px',width:'100%'}} className="flexcenter Fcenter btn btn1" onClick={this.saveDetalleTransferencia} > Aceptar </button>
          </div>
        </Modal>

        {/*lista datos transferencia*/}
        <Modal centered title="Datos de transferencia" visible={this.state.modalTransferencia}
          closable={false}//maskClosable={false} keyboard={false}
          header={null} footer={null}
          style={{borderRadius:'10px!important',padding:'5px!important'}}
          width={450}
          onCancel={()=>this.setState({modalTransferencia:false})}
          className="miAntModal miconfigModal miconfigModalCitas">
          <div className="miconfigModalMetodoPago miconfigModalMetodoPago2 flexcenter Fspaceb columnxd">
            
            <div className="contItems2">
            {
              this.state.listaDataTransfer.length>0?
              this.state.listaDataTransfer.map((dato,i) => (
                <div className="listItemTransfer flexcenter Fcenter" key={'tarjeta_'+i} onClick={()=>this.showDetalleTransferencia(i)} >
                  
                  <div className="dataImg">
                    <img alt="..." src={getImageBanco(Number(dato['BANCO']))} />
                  </div>

                  <table>
                  <tbody>
                    <tr>
                      <td className="meta" > Titular </td>
                      <td className="data" > {dato['NOMBRE_TITULAR']} </td>
                    </tr>

                    <tr>
                      <td className="meta" > Banco </td>
                      <td className="data" > {this.state.bancos[Number(dato['BANCO'])-1]} </td>
                    </tr>

                    <tr>
                      <td className="meta" > No. Cuenta </td>
                      <td className="data" > {formatDatosCard(dato['NUM_CUENTA'],1)} </td>
                    </tr>

                    <tr>
                      <td className="meta"> Cuenta CLABE </td>
                      <td className="data"> {formatDatosCard(dato['CUENTA_CLAVE'],3)} </td>
                    </tr>

                    <tr>
                      <td className="meta"> No. Tarjeta </td>
                      <td className="data"> {formatDatosCard(dato['NUM_TARJETA'],2)} </td>
                    </tr>
                  </tbody>
                  </table>

                </div>
              )):
              <div className="nonResults flextop Fcenter " >
                <img alt="..." src={imgPanel+'nonproductos.png'} />
              </div>
            }            
            </div>

            {
              this.props.validCurrentUser(this.context,'metodoPago','bool',false,'CREAR')&&
              <button className="flexcenter Fcenter btnAdd" onClick={()=>this.showDetalleTransferencia(-1) } > Agregar datos de transferencia </button>
            }
            
            <button style={{marginTop:'10px',width:'100%'}} className="flexcenter Fcenter btnAdd btn2" onClick={()=>this.setState({modalTransferencia:false})} > Cancelar </button>

          </div>
        </Modal>
       
        <div className="listaConfiguraciones miconfigModalMetodoPago">
          <div className="nta flexcenter Fcenter">
            La integración de los diferentes métodos de pago estarán disponibles para todas las sucursales
          </div>
          <div className="contItems">
          {
            this.state.tiposPago.map((tp,i) => (
              <div className="itemTipo flexcenter Fstart" key={'meta_'+i} onClick={()=>this.switchType(tp)}>
                <div className="icon"> <img alt="..." src={tp['img']} /> </div>
                <div className="noticon flexcenter Fspaceb">
                  {tp['NOMBRE']}
                  <Icon type="right" />
                </div>
              </div>
            ))
          }
          </div>
        </div>
        
        {/* varios */}
        <Loading loading={this.state.loading} /> 
        <ModalAlert visible={this.state.modalAlert} text={this.state.txtAlert} onOk={()=>this.setState({modalAlert:false})} zIndex={1001} />

        <ModalAlert visible={this.state.modalConfirmEliminar} text={'¿Está seguro que desea eliminar los datos de transferencia?'} withCancel onCancel={()=>this.setState({modalConfirmEliminar:false})} onOk={this.deleteDataTransfer} />

        <Modal centered maskClosable={false} keyboard = {false} 
          visible={this.state.loadingStripe} closable={false} width={300}
          footer={null} header={null} bodyStyle={{padding:'0'}} zIndex={1001}>
          <div className="loadLogin">
            <label> {this.state.txtStripe} </label>
            <Spin indicator={<Icon type="loading" style={{ fontSize: 50,color:'#FF9700'}} />} />
          </div>
        </Modal>

        <Modal centered title="" header={null} footer={null} //onCancel={()=>this.setState({modalIntegracionStripe:false,})}
          visible={this.state.modalIntegracionStripe} className="modalBaseBuBooker" width={500} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}}
          onCancel={()=>this.changeModalStrip(2)}
          closable={false} style={{marginTop:'20px'}} >
          <div className="controlesNuevaCita">

            <div className="title flexcenter Fcenter" style={{fontSize:'20px',color:'#F77400'}} > Integración Stripe </div>

            <div className="presentacionStripe">
              <h1 className="falseIcon flexcenter Fcenter"> Stripe </h1>

              <div className="flexcenter Fcenter titleTarifa">  TARIFA </div>
              
              <div className="contPros">
                <label className="flexcenter Fcenter comision"> 3.6% + $3.00 MXN </label>

                <div className="flexcenter Fstart item"> <img alt="..." src={img+'Palomita.png'} /> <span> Sin costos de instalción </span> </div>
                <div className="flexcenter Fstart item"> <img alt="..." src={img+'Palomita.png'} /> <span> Sin comisiones ocultas </span> </div>
                <div className="flexcenter Fstart item"> <img alt="..." src={img+'Palomita.png'} /> <span> Paga solo por lo que consumes </span> </div>
                <div className="flexcenter Fstart item"> <img alt="..." src={img+'Palomita.png'} /> <span> Informes de comisiones en tiempo real </span> </div>
                <div className="flexcenter Fstart item"> <img alt="..." src={img+'Palomita.png'} /> <span> Los fondos llegan a tu cuenta cada 7 días </span> </div>
                <div className="flexcenter Fstart item"> <img alt="..." src={img+'Palomita.png'} /> <span> Las comisiones no incluyen IVA. </span> </div>

              </div>

              <div className="nta">
                Stripe es un tercero que provee servicios de pago en línea. Para empezar a recibir pagos a través de stripe es necesario que registre su Empresa en Stripe. En primer lugar deberá confirmar que la siguiente información sea correcta:
              </div>

            </div>

            <div className="title" style={{color:'#FF9700'}} > Datos del representante legal de la empresa </div>
            <form id="formula1">

            <div className="data flexcenter Fspaceb" style={{marginTop:'0px'}}>
              <label> Nombre </label>
            </div>
            <Input className={"inputs-input"} maxLength={100} name="first_name" defaultValue={this.props.datos['NOMBRE_CONTACTO']} style={{marginTop:'-10px'}} />

            <div className="data flexcenter Fspaceb" style={{marginTop:'5px'}}>
              <label> Apellido(s) </label>
            </div>
            <Input className={"inputs-input"} maxLength={100} name="last_name" defaultValue={this.props.datos['APELLIDO_CONTACTO']} style={{marginTop:'-10px'}} />

            <div className="data flexcenter Fspaceb" style={{marginTop:'5px'}}>
              <label> Fecha de Nacimiento </label>
            </div>
            <DatePicker
              defaultValue={this.state.hoy}
              format="DD / MM / YYYY" locale='es-MX'
              onChange={(hoy)=>this.setState({hoy})}
              allowClear={false} suffixIcon={null}
              style={{width:'100%'}}
              placeholder="" className="inputs-input" />

            <Input type="hidden" name="dob_day" value={this.state.hoy.format('DD')} />
            <Input type="hidden" name="dob_month" value={this.state.hoy.format('MM')} />
            <Input type="hidden" name="dob_year" value={this.state.hoy.format('YY')} />
            </form>
            <button style={{marginTop:'10px',width:'100%',fontSize:'16px'}} className="flexcenter Fcenter btn btn1" onClick={()=>this.changeModalStrip(1)} > Continuar </button>
          </div>
        </Modal>

        <Modal centered title="" header={null} footer={null} //onCancel={()=>this.setState({modalIntegracionStripe:false,})}
          visible={this.state.modalIntegracionStripe2} className="modalBaseBuBooker" width={500} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}}
          onCancel={()=>this.changeModalStrip(3)}
          closable={false} style={{marginTop:'20px'}} >
          <div className="controlesNuevaCita">

            <div className="title flexcenter Fcenter" style={{fontSize:'20px',color:'#F77400'}} > Integración Stripe </div>

            <div className="title" style={{color:'FF9700'}} > Datos generales de la empresa </div>
            <form id="formula2">
            <div className="data flexcenter Fspaceb" style={{marginTop:'0px'}}>
              <label> Nombre legal de la empresa </label>
            </div>
            <Input className={"inputs-input"} name="business_name" maxLength={100} defaultValue={this.props.datos['NOMBRE_COMERCIAL']} style={{marginTop:'-10px'}} />

            <div className="data flexcenter Fspaceb" style={{marginTop:'1px'}}>
              <label> Tipo de empresa </label>
            </div>

            <div className="data flexcenter Fspaceb" style={{marginTop:'-13px'}}>
            
            <Select className="inputs-input" name="business_type" style={{width:'100%'}} value={this.state.tipoEmpresa} onChange={tipoEmpresa=>this.setState({tipoEmpresa}) } >
              <Select.Option value={'sole_prop'}> Persona Física con Actividad Empresarial </Select.Option>
              <Select.Option value={'non_profit'}> Organización sin fines de lucro </Select.Option>
              <Select.Option value={'partnership'}> Sociedad Civil (SC) </Select.Option>
              <Select.Option value={'llc'}> Sociedad de Responsabilidad Limitada (S de RL) </Select.Option>
              <Select.Option value={'corporation'}> Sociedad Anónima (SA) </Select.Option>
            </Select>

            </div>

            <div className="data flexcenter Fspaceb" style={{marginTop:'1px'}}>
              <label> Teléfono de soporte </label>
            </div>
            <Input className={"inputs-input"} name="phone_number" maxLength={100} defaultValue={this.props.datos['TELEFONO_CONTACTO']} style={{marginTop:'-10px'}} />

            <div className="data flexcenter Fspaceb" style={{marginTop:'1px'}}>
              <label> Correo electrónico </label>
            </div>
            <Input className={"inputs-input"} name="email" maxLength={100} defaultValue={this.props.datos['CORREO_REGISTRO']} style={{marginTop:'-10px'}} />

            <div className="data flexcenter Fspaceb" style={{marginTop:'1px'}}>
              <label> Sitio web de la empresa </label>
            </div>
            <Input className={"inputs-input"} name="url" maxLength={100} defaultValue={
              this.props.datos['PAGINAWEB']!==''?this.props.datos['PAGINAWEB']:this.props.datos['USUARIO_EMPRESA']!==''?'https://bubook.nibirusystem.com/empresas/'+this.props.datos['USUARIO_EMPRESA']:'https://bubook.nibirusystem.com/empresas/'+this.props['NOMBRE_COMERCIAL']+'000000'
            } style={{marginTop:'-10px'}} />

            <div className="data flexcenter Fspaceb" style={{marginTop:'1px'}}>
              <label> Calle </label>
            </div>
            <Input className={"inputs-input"} name="street_address" maxLength={100} defaultValue={this.props.sucursales.length>0?this.props.sucursales[0]['CALLE']:''} style={{marginTop:'-10px'}} />

            <div className="data flexcenter Fspaceb" style={{marginTop:'1px'}}>
              <label> Ciudad </label>
            </div>
            <Input className={"inputs-input"} name="city" maxLength={100}  defaultValue={this.props.sucursales.length>0?this.props.sucursales[0]['CIUDAD']:''} style={{marginTop:'-10px'}} />

            <div className="data flexcenter Fspaceb">
              <label style={{width:'50%'}} > Estado </label>
              <label style={{width:'40%'}} > C.P. </label>
            </div>

            <div className="data flexcenter Fspaceb">
              <Input className={"inputs-input"} name="state" maxLength={100}  defaultValue={this.props.sucursales.length>0?this.props.sucursales[0]['ESTADO']:''} style={{marginTop:'-10px',width:'50%'}} />
              <Input className={"inputs-input"} name="zip" maxLength={100}  defaultValue={this.props.sucursales.length>0?this.props.sucursales[0]['CODPOSTAL']:''} style={{marginTop:'-10px',width:'40%'}} />
            </div>

            </form>

             <div className="presentacionStripe">
              <div className="nta">
                A contnuación, deberá llenar un formulario de Stripe para vincular la cuenta de su empres a BuBook. Si tiene algún problema durante el proceso no dude en contactarse a soporte.
              </div>
            </div>
            <button style={{marginTop:'10px',width:'100%',fontSize:'16px'}} className="flexcenter Fcenter btn btn1" onClick={()=>this.changeModalStrip(4)} > Conectar con Stripe </button>
          </div>
        </Modal>

      </Modal>
    )
  }
}
export default MetodosDePago;