import React from 'react';
/* Librerías */
import { CSSTransition } from "react-transition-group"; 
import { Globalrequest as request , isLogged,isOtraPestana} from './../../request.js';
import { BrowserRouter , Route, Link,withRouter } from "react-router-dom";
/* Componentes */
import axios from 'axios';
import {Host as host } from './../../app.json'

import Loading from './../../modalCargando/loading';
import ModalAlert from './../../modalCargando/modalAlert';

import Home from './home'
import { Modal} from 'antd';
/*CSS*/

const rutas = [
  //Rutas hermanas
  { path: '/home', Component: Home },
  //Rutas hijas de home
  { path: '/home/notificaciones', Component: Home },
  
  { path: '/home/sucursales', Component: Home },  
  { path: '/home/sucursales/:any', Component: Home },
  { path: '/home/sucursales/:any/:any', Component: Home },

  { path: '/home/configuracion', Component: Home },
  { path: '/home/productos', Component: Home },
  { path: '/home/productos/:any', Component: Home },
  
  { path: '/home/categorias', Component: Home },
  { path: '/home/unidades', Component: Home },
  { path: '/home/reseñas', Component: Home },
  { path: '/home/clientes', Component: Home },
  { path: '/home/transacciones', Component: Home },
  { path: '/home/estadisticas', Component: Home },
  
  { path: '/home/pedidos', Component: Home },
  { path: '/home/pedidos/:any', Component: Home },

  { path: '/home/promociones', Component: Home },
  { path: '/home/promociones/:any', Component: Home },

  { path: '/home/citas', Component: Home },
  { path: '/home/agenda', Component: Home },
  { path: '/home/citas/:any', Component: Home },

  { path: '/home/usuarios', Component: Home },
]

class Router extends React.Component{
  constructor(props) {
    super(props);
    
    this.setSucursales = this.setSucursales.bind(this)
    this.redirectTo = this.redirectTo.bind(this)
    this.updateState = this.updateState.bind(this)
    this.redirectToDefinitivo = this.redirectToDefinitivo.bind(this)
    this.setMensaje = this.setMensaje.bind(this)

    this.actualizarDatos = this.actualizarDatos.bind(this)
    this.tellmmeFunc = this.tellmmeFunc.bind(this)
    this.cerraSesion = this.cerraSesion.bind(this)

    this.isnotAllow = this.isnotAllow.bind(this)
    
    this.state = {
      loading:false,
      /**/
      actualpage : '',
      sucursales : [],
      datos:[],
      tags:[],
      categorias:[],
      notificaciones:[],
      /* */
      actualpage2 : '',
      nopuedoCambiarSinAvisarte:false,
      msjMoGo:'',
      modal:false,
      /* */
      valid:true,
      mood:sessionStorage.getItem('TU9ET19QUlVFQkE=') || 0,
      modalAlert:false,
      txtAlert:'',
      /**/
      notallow : [
        '/home/sucursales/nueva',
        '/home/sucursales/editar/',
        '/home/sucursales/coberturas',
        '/home/productos/nuevo',
        '/home/productos/editar',
        '/home/promociones/nueva',
        '/home/promociones/editar',
        '/home/promociones/nueva',
        '/home/promociones/editar',
        '/home/citas',
        '/home/agenda',
        '/home/clientes',
        '/home/configuracion',
      ],
    }

  }

  componentDidMount(){
    let userType = localStorage.getItem('userType')
    let typeUser = localStorage.getItem('typeUser')
    if(isLogged()){
      if(isOtraPestana()){
        if(userType==='nouser'|| Number(typeUser) ===2){
          this.actualizarDatos()
        }
      }
    }else{
      this.cerraSesion()
      return
    }
  }

  actualizarDatos(a=-1){
    let data={mail:localStorage.getItem('mail'),op:-1,detalle:1}

    let userSubType = localStorage.getItem('userSubType')||-1
    let typeSubUser = localStorage.getItem('typeSubUser')||-1
    let subusuario = false
    
    if(userSubType === 'nosubuser' && Number(typeSubUser)===3){
      data['userSubType'] = localStorage.getItem('submail')
      subusuario = true
    }

    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/datosPorEmpresa`,data,request).then(rt=>{
      if( rt.data === -1 ){
        this.cerraSesion()
      }
      else{
        localStorage.setItem('id',rt.data['datos']['ID'])
        localStorage.setItem('mail',rt.data['datos']['CORREO_REGISTRO2'])
        
        localStorage.setItem('userType','nouser')
        localStorage.setItem('typeUser','2')

        rt.data['datos']['LABEL'] = rt.data['datos']['CORREO_REGISTRO']
        rt.data['datos']['ALIAS'] = btoa(btoa(rt.data['datos']['ALIAS']))

        if(subusuario){
          localStorage.setItem('userSubType','nosubuser')
          localStorage.setItem('typeSubUser','3')

          localStorage.setItem('subid',rt.data['extra']['code'])//subid encriptado
          localStorage.setItem('submail',rt.data['extra']['index'])//submail encriptado
          rt.data['datos']['LABEL'] = rt.data['extra']['LABEL']
          rt.data['datos']['ALIAS'] = btoa(btoa(rt.data['extra']['alias']))
        }

        sessionStorage.setItem('subid',rt.data['extra']['code'])
        sessionStorage.setItem('submail',rt.data['extra']['index'])


        sessionStorage.setItem('id',rt.data['datos']['ID'])// id encriptado
        sessionStorage.setItem('mail',rt.data['datos']['CORREO_REGISTRO2']) //  maiol encriptado

        sessionStorage.setItem('folder',rt.data['folder']) //folder
        sessionStorage.setItem('src',rt.data['datos']['DIRECCION_IMG_PERFIL']) 

        sessionStorage.setItem('idNotificacionPorAtender','null')
        sessionStorage.setItem('isEliminada',rt.data['datos']['ESTATUS'])
        sessionStorage.setItem('metadata',JSON.stringify(rt.data))
        
        if(a<0)window.location.reload();
        else this.setState({loading:false}) 

      }
    })
  }

  tellmmeFunc(a=1){
    this.actualizarDatos(a);
  }

  cerraSesion(){
    let ciudadUsuario = localStorage.getItem('ciudadUsuario')
    let estadoUsuario = localStorage.getItem('estadoUsuario')
    let carrito = JSON.parse(localStorage.getItem('carrito')) || {}
    localStorage.clear()
    sessionStorage.clear()
    localStorage.setItem('ciudadUsuario',ciudadUsuario)
    localStorage.setItem('estadoUsuario',estadoUsuario)
    localStorage.setItem('carrito',JSON.stringify(carrito))
    this.props.redirectTo('/')
  }

  setSucursales(sucursales){
    this.setState({sucursales:sucursales})
  }
  
  updateState(campo,valor){
    this.setState({[campo]:valor})
  }

  setMensaje(a){
    let msjs = [
      'Los cambios realizados en su configuración se perderán',
      'Los cambios realizados en esta sucursal se perderán',
      'Los cambios realizados en este producto se perderán',
      'Los cambios realizados en esta promoción se perderán',
    ]
    this.setState({msjMoGo:msjs[a]})
  }

  isnotAllow(url){
    let {notallow} = this.state
    for(let not of notallow){
      if(
        (not==='/home/sucursales/editar/' && url.includes('sucursales/editar/')) ||
        not === url )return true
    }
    return false
  }

  redirectTo(url){
    let {nopuedoCambiarSinAvisarte,mood,valid} = this.state
    if(!valid){//valid=false      
      if(Number(mood)===1){//prove        
        if( this.isnotAllow(url) ){
          this.setState({modalAlert:true,txtAlert:'No puedes acceder a esta opción porque tu cuenta está con funciones limitadas.'})
          return
        }
      }else{
        this.setState({modalAlert:true,txtAlert:'Debes tener una suscripción activa.'})
        return
      }
    }

    if(nopuedoCambiarSinAvisarte){
      this.setState({actualpage2:url,modal:true})
    }
    else{
      this.setState({actualpage:url})
      setTimeout(function(){ document.getElementById('auxToE').click() },1);
    }
  }

  redirectToDefinitivo(){
    let {actualpage2} = this.state
    this.setState({actualpage:actualpage2,actualpage2:'',modal:false,nopuedoCambiarSinAvisarte:false})
    setTimeout(function(){ document.getElementById('auxToE').click() },1);
  }

  render(){
    return (
      <BrowserRouter  >
        
        <Loading loading={this.state.loading} />

        <ModalAlert visible={this.state.modalAlert} text={this.state.txtAlert} onOk={()=>this.setState({modalAlert:false})} />

        <Modal centered title="¿Desea salir sin guardar cambios?" maskClosable={false} keyboard = {false} 
            okText="Continuar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
            cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
            visible={this.state.modal}
            closable={false}
            onOk={this.redirectToDefinitivo}
            onCancel={()=>this.setState({modal:false})}
            className="miAntModal newVar alertMy"
            >
            {
              this.state.modal&&
              <div className="myAlertBody">
                <span className="txtAlert"> {this.state.msjMoGo} </span>
              </div>
            }
        </Modal>

        <Link to={this.state.actualpage} id="auxToE"/>
        {
          rutas.map(({path, Component }) => (
            <Route key={path} exact path={path}  >
            {({ match }) => (
              <CSSTransition in={match != null} timeout={500} classNames="page" unmountOnExit > 
              <div className="page pagenoscroll">
                <Component
                  cerraSesion={this.cerraSesion}
                  redirectTo={this.props.redirectTo}
                  redirectTo2={this.redirectTo}
                  setSucursales={this.setSucursales}
                  updateState={this.updateState}
                  
                  sucursales={this.state.sucursales}
                  datos={this.state.datos}
                  tags={this.state.tags}
                  categorias={this.state.categorias}
                  notificaciones={this.state.notificaciones}

                  tellmmeFunc={this.tellmmeFunc}

                  setMensaje = {this.setMensaje}
                  mood={this.state.mood}
                  isnotAllow={this.isnotAllow}
                /> 
              </div>
              </CSSTransition>
              )}
            </Route>
          ))
        }
      </BrowserRouter>
    );
  }
}

export default withRouter(Router);