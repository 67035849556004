import React from 'react';
import axios from 'axios';
import {Globalrequest as request} from './../../../request.js'
import { Host as host,ImgPanelAdmin as panelAdmin,Img as img} from './../../../app.json';

import Loading from './../../../modalCargando/loading';
import Alert from './../../../modalCargando/modalAlert';
import NotFound from './../../../modalCargando/notfound';

import {userContext} from './../context'

import {unique,groupBy,capitalizeFirs} from './../../../utilities/generalfunctions'

/* Librerías */
import moment from 'moment';
import { Modal,Input,Icon,Calendar,Select} from 'antd';
import { withRouter } from "react-router-dom";

class Configuracion extends React.Component{

  static contextType = userContext
  
  constructor(props){
    super(props);
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.makeCitasBetter = this.makeCitasBetter.bind(this)

    this.onPanelChange = this.onPanelChange.bind(this)

    this.clickOnCita = this.clickOnCita.bind(this)
    this.eliminarCita = this.eliminarCita.bind(this)
    this.agregarCita = this.agregarCita.bind(this)

    this.changeFiltros = this.changeFiltros.bind(this)
    this.filtrarCitas = this.filtrarCitas.bind(this)
    this.borrarFiltros = this.borrarFiltros.bind(this)

    this.setReagendarFecha = this.setReagendarFecha.bind(this)
    this.setReagendarHora = this.setReagendarHora.bind(this)

    this.changeControlCitas = this.changeControlCitas.bind(this)

    this.clickHoverable = this.clickHoverable.bind(this)

    this.state={
      loading:false,
      citas:[],
      citasCopia:[],
      citas2:{},
      /**/
      idNotificacionPorAtender:sessionStorage.getItem('idNotificacionPorAtender'),
      idCit:sessionStorage.getItem('idCit'),
      /**/
      mutableDataCita:{
        inicio:'',
        fin:'',
        idHorarioAtencionCita:'',
      },

      hoy:moment(),
      fechaReagendar:moment(),
      formatedDate : moment().format('dddd, LL'),
      /**/
      addingCita:false,
      //configActual:{},
      currentCitaCopy:{cita:{},},
      currentCita:{cita:{},},
      citaInterface:{ESTATUS:'1',OBSERVACIONES_CLIENTES:'',OBSERVACIONES_EMPRESA:'',MOTIVOS_CLIENTES:'',MOTIVOS_EMPRESA:'',ID_USUARIO_EMPRESA:-1,FK_ID:-1,ID_PEDIDO:-1,ID:-1,CALIFICACION_CLIENTE:0,MINE:false,NOMBRE_CLIENTE:'',FECHA_CITA_INICIO:'',FECHA_CITA_TERMINO:''},
      editanding:false,
      caducada:false,
      finalizada:false,
      estatus:-1,
      cancelanding:false,
      /**/
      confirmEliminar:false,
      txtAlert:'',
      modalAlert:false,
      /**/
      listaCitas2:[],
      /**/
      filtro1:true,
      filtro2:true,
      filtro3:true,
      filtro4:true,
      filtro6:true,

      /**/

      sucursales:[],
      indexConfig:'',
      nombreConfig:'',
      codeConfig:'',
    }
  }

  componentDidMount(){
    document.title="Mis citas agendadas"

    let {idNotificacionPorAtender} = this.state
    if(idNotificacionPorAtender !== 'null'){
      let data = {idN:idNotificacionPorAtender,estatus:2}
      axios.post(`${host}clientes/clientes/cambiarEstatusNotificacion`,data,request)
      sessionStorage.setItem('idNotificacionPorAtender','null')
    }

    let {validCurrentUser,redirectTo2} = this.props

    let obj = JSON.parse(JSON.stringify(this.context))
    if(Object.keys(this.context['data']).length === 0 ){
      let permisosPreSaved = sessionStorage.getItem('perfiles')||'none'
      if(permisosPreSaved!=='none')
        obj['data'] = JSON.parse(permisosPreSaved)  
    }

    
    if(!validCurrentUser(obj,'citas','bool',false,false)){
      redirectTo2('/home')
      return
    }

    this.actualizarCatalogo(1,0)
  }

  actualizarCatalogo(isFirst,idS){
    let {idCit} = this.state
    idCit = (idCit !== null && idCit !== undefined) ? idCit:'-100'
    let data = {idE:sessionStorage.getItem('id'),isFirst,idS,idCit}
    sessionStorage.removeItem('idCit')
    this.setState({loading:true})
    axios.post(`${host}citas/citas/agendaSucursal/-1`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let showPre = false
        let {validCurrentUser} = this.props

        if(idCit!=='-100'){
          this.setState({indexConfig:rt.data['data'][rt.data['selected']]['id'] , nombreConfig:rt.data['data'][rt.data['selected']]['nombre'],idCit:undefined,sucursales:rt.data['data']})
          showPre=true
        }
        else if(isFirst>0){
          
          let obj = JSON.parse(JSON.stringify(this.context))
          if(Object.keys(this.context['data']).length === 0 ){
            let permisosPreSaved = sessionStorage.getItem('perfiles')||'none'
            if(permisosPreSaved!=='none')
              obj['data'] = JSON.parse(permisosPreSaved)  
          }

          if(!validCurrentUser(obj,'citas','bool',rt.data['data'][0]['code'],false)){
            this.setState({indexConfig:'' , nombreConfig:'',sucursales:rt.data['data']})
            rt.data['msg'] = []
          }else{
            this.setState({indexConfig:rt.data['data'][0]['id'] , nombreConfig:rt.data['data'][0]['nombre'],codeConfig:rt.data['data'][0]['code'],sucursales:rt.data['data']})
          }
        }
        this.setState({citasCopia:rt.data['msg'], })

        this.makeCitasBetter(rt.data['msg'],showPre)
      }
    })
  }

  makeCitasBetter(citasPara,showPre=false){
    let{citas,citas2}= this.state
    let labels = []
    
    citas = citasPara.map(ct => (ct['FECHA_VIEW'] = moment(ct['FECHA_CITA_INICIO'],'YYYY-MM-DD HH:mm:ss').format('DD/MMMM/YYYY') ) )
    labels = citasPara.map(ct => (moment(ct['FECHA_CITA_INICIO'],'YYYY-MM-DD HH:mm:ss').format('DD/MMMM/YYYY') ) )
    labels = unique(labels)
    labels.sort((a,b) => moment(a,'DD/MMMM/YYYY').valueOf()-moment(b,'DD/MMMM/YYYY').valueOf() )
    labels = labels.reverse()
    citas = groupBy(citasPara,'FECHA_VIEW')
    citas2={}
    let citaToClick = {}
    for(let fecha of labels){
      citas2[fecha] = citas[fecha]
      citas2[fecha].sort((a,b) => moment(b['FECHA_CITA_INICIO']).valueOf()-moment(a['FECHA_CITA_INICIO']).valueOf() )
      citas2[fecha] = citas2[fecha].reverse()
      for(let citaAux of citas2[fecha]){

        let textoCliente = citaAux['OBSERVACIONES_CLIENTES'].split('~Ç~')
        let textoEmpresas = citaAux['OBSERVACIONES_EMPRESA'].split('~Ç~')
        citaAux['OBSERVACIONES_CLIENTES'] = textoCliente.length>0?textoCliente[0].trim():''
        citaAux['MOTIVOS_CLIENTES'] = textoCliente.length>1?textoCliente[1].trim():''
        citaAux['OBSERVACIONES_EMPRESA'] = textoEmpresas.length>0?textoEmpresas[0].trim():''
        citaAux['MOTIVOS_EMPRESA'] = textoEmpresas.length>1?textoEmpresas[1].trim():''

        citaAux['OBSERVACIONES_CLIENTES'] = citaAux['OBSERVACIONES_CLIENTES'].trim().toLowerCase()==='undefined'?'':citaAux['OBSERVACIONES_CLIENTES'].trim()
        citaAux['MOTIVOS_CLIENTES'] = citaAux['MOTIVOS_CLIENTES'].trim().toLowerCase()==='undefined'?'':citaAux['MOTIVOS_CLIENTES'].trim()
        citaAux['OBSERVACIONES_EMPRESA'] = citaAux['OBSERVACIONES_EMPRESA'].trim().toLowerCase()==='undefined'?'':citaAux['OBSERVACIONES_EMPRESA'].trim()
        citaAux['MOTIVOS_EMPRESA'] = citaAux['MOTIVOS_EMPRESA'].trim().toLowerCase()==='undefined'?'':citaAux['MOTIVOS_EMPRESA'].trim()
        if(citaAux['PRELOAD']==='found')
          citaToClick = citaAux
        citaAux['PRELOAD'] = 'none'
      }
    }
    if(citaToClick.hasOwnProperty('PRELOAD')&&showPre)
      this.clickOnCita(citaToClick)
    else if(showPre)
      this.setState({modalNotFound:true})

    citas = citasPara.map( c => (c))
    this.setState({loading:false,citas,citas2})
  }

  onPanelChange(fechaReagendar){
    let{formatedDate} = this.state
    formatedDate = fechaReagendar.format('dddd, LL');
    this.setState({fechaReagendar,formatedDate})    
  }

  switchEstatus(estatus,op=1){
    estatus = Number(estatus)
    if(op===1){
      if(estatus===1)return "Pendiente"
      if(estatus===2)return "Confirmada"
      if(estatus===3)return "No asistió"
      if(estatus===4)return "Asistió"
      if(estatus===5)return "Reagendada"
      if(estatus===6)return "Cancelada"
    }
    if(op===2){
      if(estatus===1)return "pendiente"
      if(estatus===2)return "confirmada"
      if(estatus===3)return "noasistio"
      if(estatus===4)return "asistio"
      if(estatus===5)return "reagendada"
      if(estatus===6)return "cancelada"
    }
    return estatus
  }

  switchFecha(fechaString){
    let date = moment(fechaString,'YYYY-MM-DD HH:mm:ss')
    return date.format('HH:mm')
  }

  clickOnCita(cita){
    let {currentCita,currentCitaCopy,finalizada,caducada,estatus,fechaReagendar,formatedDate,hoy,mutableDataCita} = this.state
    Object.assign(currentCita,cita)
    let ahora = moment()
    let fechaCita = moment(cita['FECHA_CITA_INICIO'],'"YYYY-MM-DD HH:mm:ss"')
    let fechaCitaFin = moment(cita['FECHA_CITA_TERMINO'],'"YYYY-MM-DD HH:mm:ss"')
    estatus = Number(cita['ESTATUS'])
    finalizada = (estatus>2)
    caducada = ahora.isSameOrAfter(fechaCita)

    fechaReagendar = moment(fechaCita)
    formatedDate = fechaReagendar.format('dddd, LL');

    hoy = moment(fechaCita)

    currentCita['inicio'] = fechaCita.format('HH:mm')
    currentCita['fin'] = fechaCitaFin.format('HH:mm')
    currentCita['idHorarioAtencionCita'] = cita['ID_HORARIOS_ATENCION_CITA']

    mutableDataCita['inicio'] = fechaCita.format('HH:mm')
    mutableDataCita['fin'] = fechaCitaFin.format('HH:mm')
    mutableDataCita['idHorarioAtencionCita'] = cita['ID_HORARIOS_ATENCION_CITA']

    Object.assign(currentCitaCopy,currentCita)

    this.setState({addingCita:true,editanding:true,currentCita,currentCitaCopy,finalizada,caducada,estatus,fechaReagendar,formatedDate,hoy,mutableDataCita})
  }

  eliminarCita(){
    let {currentCita,citas} = this.state
    let data = {idCita:currentCita['ID']}
    this.setState({loading:true,confirmEliminar:false})
    axios.post(`${host}citas/citas/eliminarCita`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let rta = Number(rt.data['result'])
        if(rta===1){
          citas = citas.filter(c => c['ID']!==data['idCita'])
          this.makeCitasBetter(citas)
          currentCita = {cita:Object.create({})}
          this.setState({txtAlert:'Cita eliminada correctamente',modalAlert:true,addingCita:false,currentCita,loading:false})
        } else{ this.setState({txtAlert:'Parece que ocurrió un error, por favor vuelve a intentalo',modalAlert:true,loading:false}) }
      }else{ this.setState({txtAlert:'Parece que ocurrió un error, por favor vuelve a intentalo',modalAlert:true,loading:false}) }
    })
  }

  agregarCita(op,nocambiar=false){
    let {currentCita,currentCitaCopy,hoy,fechaReagendar,formatedDate,estatus,mutableDataCita} = this.state
    
    let motivo = currentCita['MOTIVOS_EMPRESA'] ? currentCita['MOTIVOS_EMPRESA'] : ''
    if(op===3){
      motivo = document.getElementById('motivoCancel').value.trim()
      if(motivo===''){
        this.setState({txtAlert:'El motivo de cancelación es obligatorio',modalAlert:true})
        return
      }
      document.getElementById('motivoCancel').value=''
    }
    
    let indicacion = document.getElementById('indicacion').value.trim()
    let date = hoy.format('YYYY-MM-DD')

    let inicio = currentCita['inicio'],
        fin = currentCita['fin'],
        idHorarioAtencionCita = currentCita['idHorarioAtencionCita']

    if(op===2){
      let oldFecha = moment(currentCitaCopy['FECHA_CITA_INICIO'],'YYYY-MM-DD HH:mm:ss')
      date = fechaReagendar.format('YYYY-MM-DD')
      inicio = mutableDataCita['inicio']
      fin = mutableDataCita['fin']
      idHorarioAtencionCita = mutableDataCita['idHorarioAtencionCita']

      if(fechaReagendar.isSame(oldFecha,'day')){//es la misma fecha, pero puede ser distinta hora
        if(  (currentCita['inicio']!==inicio && currentCita['fin'] !== fin) || indicacion !== currentCitaCopy['OBSERVACIONES_EMPRESA'].trim() || Number(currentCitaCopy['ESTATUS']) !== estatus  ){ }
        else{
          if(estatus!==1){
            this.setState({editanding:false,addingCita:false})
            return
          }
        }
      }else{
        //alert('si cambio')
      }
    }

    if(!nocambiar){
      let oldstatus = Number(currentCitaCopy['ESTATUS'])
      if(oldstatus===2&estatus>2){
        this.setState({confirmCambiarStatus:true,})
        return
      }
    }else{
      this.setState({confirmCambiarStatus:false})
    }
    
    estatus = estatus === 1 ? 2 : estatus
    estatus = op===3?6:estatus;

    formatedDate = hoy.format('dddd, LL');
    if(currentCita['inicio']==='--:--' || currentCita['fin']==='--:--'){
      this.setState({modalAlert:true,txtAlert:'Debe elegir un horario para la cita'})
      return
    }
    let citaToAdd = {
      idHorarioAtencionCita: idHorarioAtencionCita, //currentCita['idHorarioAtencionCita'],
      idCliente:currentCita['ID_CLIENTE'],
      fkId:currentCita['FK_ID'],
      idUsuarioEmpresa:currentCita['ID_USUARIO_EMPRESA'],
      idPedido:currentCita['ID_PEDIDO'],
      fechaInicio: `${date} ${inicio}`,
      fechaTermino:`${date} ${fin}`,
      observacionesCliente:currentCita['OBSERVACIONES_CLIENTES']+'~Ç~'+currentCita['MOTIVOS_CLIENTES'],
      observacionesEmpresa:indicacion+'~Ç~'+motivo,
      calificacion:currentCita['CALIFICACION_CLIENTE'],
      estatus:estatus===1?2:estatus,
      id:currentCita['ID'],
      op:-1,
      idE:sessionStorage.getItem('id'),
      esCliente:2,
    }
    document.getElementById('indicacion').value = ''    
    this.setState({loading:true})
    axios.post(`${host}citas/citas/agregarEditarCitaCliente`,citaToAdd,request).then(rt=>{
      if(rt.data['status'] === 'ok'){
        if(Number(rt.data['data'])===1){
          let {indexConfig} = this.state
          mutableDataCita={ inicio:'', fin:'', idHorarioAtencionCita:'', }
          this.setState({mutableDataCita,listaCitas2:[],currentCita:{},currentCitaCopy:{},addingCita:false,formatedDate:moment().format('dddd, LL'),hoy:moment(),fechaReagendar:moment(),cancelanding:false,})
          this.actualizarCatalogo(-1,indexConfig)
        }else{ this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde',modalAlert:true,formatedDate,isNew:false,cancelanding:false})}
      }
      else{ this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde',modalAlert:true,formatedDate,isNew:false,cancelanding:false}) }
    })
  }

  changeFiltros(campo,value){
    this.setState({ [campo]:value })
    setTimeout( ()=>this.filtrarCitas() , 1 )
  }

  filtrarCitas(){
    let {filtro1,filtro2,filtro3,filtro4,filtro6,citasCopia} = this.state
    let citasAux = citasCopia.map(c => (c))
    citasAux = filtro1?citasAux:citasAux.filter(c => Number(c['ESTATUS']) !== 1 )
    citasAux = filtro2?citasAux:citasAux.filter(c => Number(c['ESTATUS']) !== 2 )
    citasAux = filtro3?citasAux:citasAux.filter(c => Number(c['ESTATUS']) !== 3 )
    citasAux = filtro4?citasAux:citasAux.filter(c => Number(c['ESTATUS']) !== 4 )
    citasAux = filtro6?citasAux:citasAux.filter(c => Number(c['ESTATUS']) !== 6 )
    this.makeCitasBetter(citasAux)
  }

  borrarFiltros(){
    let {filtro1,filtro2,filtro3,filtro4,filtro6} = this.state
    filtro1 = true
    filtro2 = true
    filtro3 = true
    filtro4 = true
    filtro6 = true
    this.setState({ filtro1,filtro2,filtro3,filtro4,filtro6 })
    setTimeout( ()=>this.filtrarCitas() , 1 )
  }

  setReagendarFecha(){
    let {fechaReagendar} = this.state
    let orita = moment()
    if(fechaReagendar.isBefore(orita)){
      this.setState({modalAlert:true,txtAlert:'La fecha seleccionada no está disponible'})
      return
    }
    this.actualizarHorariosCitas2(false)
  }

  actualizarHorariosCitas2(justHora){
    let {currentCita,fechaReagendar,listaCitas2} = this.state
    let orita = moment()
    this.setState({loading:true,})
    let data = {idC:currentCita['ID_CONFIGURACION_CITA'],fecha:fechaReagendar.format('YYYY-MM-DD'), dia:fechaReagendar.day()===0?7:fechaReagendar.day() }
    axios.post(`${host}citas/citas/disponibilidadHorarioFecha`,data,request).then(rt=>{
      this.setState({loading:false})
      if(rt.data['status']==='ok'){
        let horariosHoy = rt.data['horarios']
        horariosHoy = Object.keys(horariosHoy).map((key) => horariosHoy[key] );
        let horariosAbreHoy = horariosHoy.filter(h => Number(h['tipoHora']) === 1)
        let horariosDescansoHoy = horariosHoy.filter(h => Number(h['tipoHora']) === 2)
        if(horariosAbreHoy.length>0){
          horariosAbreHoy = horariosAbreHoy[0]
          let horaInicio = moment(data['fecha']+' '+horariosAbreHoy['horaInicio'],'YYYY-MM-DD HH:mm:ss')
          let horaFin = moment(data['fecha']+' '+horariosAbreHoy['horaFin'],'YYYY-MM-DD HH:mm:ss')
          
          let duratCita = moment.duration(rt.data['duratCita']).asMinutes()
          let interCita = moment.duration(rt.data['interCita']).asMinutes()
          let tolerancia=0

          let citas = rt.data['list']

          horariosDescansoHoy = horariosDescansoHoy.map(h => ({inicio: moment(h['horaInicio'],'HH:mm:ss').add(tolerancia,'minutes') , fin: moment(h['horaFin'],'HH:mm:ss')}) )
          listaCitas2 = []
          while(horaInicio.isBefore(horaFin)){
            orita = moment()
            let horaInicioAux = moment(horaInicio)
            horaInicioAux.add(duratCita,'minutes')

            let invalida = horaInicio.isBefore(orita)
            if(!invalida){
              let yahay = citas.filter(c => c['HORA_INICIO'] === horaInicio.format('HH:mm:ss') && c['HORA_TERMINO'] === horaInicioAux.format('HH:mm:ss') && Number(c['ESTATUS']) < 3 )
              let descanso = horariosDescansoHoy.some(h => horaInicio.isBetween(h['inicio'],h['fin']) || horaInicioAux.isBetween(h['inicio'],h['fin']))
              listaCitas2.push({
                inicio: horaInicio.format('HH:mm'),
                fin: horaInicioAux.format('HH:mm'),
                concita:yahay.length>0,
                clase:yahay.length===0?'confirmada': 'cancelada' ,
                descanso:descanso.length>0,
                newIdHorario:horariosAbreHoy['id'],
              })
            }
            horaInicio.add(duratCita,'minutes')
            horaInicio.add(interCita,'minutes')
          }
          if(listaCitas2.length>0){
            this.setState({reagending:false,reagending2:true,listaCitas2,duracionCita:duratCita,justHora})
          }
          else{
            this.setState({txtAlert:'No hay horarios disponibles para la fecha elegida.',modalAlert:true})
          }
        }
        else{//no hay horarios disponibles
          this.setState({txtAlert:'No hay horarios disponibles para la fecha elegida.',modalAlert:true})
        }
      }
    })
  }

  setReagendarHora(citaClick){
    if(citaClick['descanso']||citaClick['concita']){
      this.setState({modalAlert:true,txtAlert:'El horario seleccionado no está disponible'})
      return
    }
    let {mutableDataCita} = this.state
    mutableDataCita['inicio'] = citaClick['inicio']
    mutableDataCita['fin'] = citaClick['fin']
    mutableDataCita['idHorarioAtencionCita'] = citaClick['newIdHorario']
    this.setState({mutableDataCita,reagending2:false,})
  }

  changeControlCitas(indexConfig){
    let{sucursales} = this.state
    let sucursal = sucursales.filter(s => s['id']===indexConfig )[0]
    this.setState({indexConfig,codeConfig:sucursal['code']})
    this.actualizarCatalogo(-1,indexConfig)
  }

  classToggle(){
    document.getElementById('toggleFiltrarId').classList.toggle('toggleFiltrar2');
    document.getElementById('filtersRBD').style.display= document.getElementById('contControlesId').classList.toggle('contControles2')?'block':'none'
  }

  clickHoverable(op){
    let {fechaReagendar,caducada, finalizada} = this.state
    console.log('finalizada',finalizada,'caducada',caducada)
    if(finalizada || caducada)return

    if(op===1){ this.setState({reagending:true}) }
    if(op===2){
      let ahora = moment()
      if(fechaReagendar.isBefore(ahora,'day')){
        this.setState({modalAlert:true,txtAlert:'No hay horarios de atención disponibles para la fecha seleccionada.'})
        return
      }      
      this.actualizarHorariosCitas2(true)
    }    
  }

  render(){
    return(
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3">
        
          <Loading loading={this.state.loading} />

          <Alert text={'Una vez eliminada, esta cita no podrá recuperarse.'} visible={this.state.confirmEliminar} withCancel onOk={this.eliminarCita} onCancel={()=>this.setState({confirmEliminar:false})}  />
          <Alert text={this.state.txtAlert} visible={this.state.modalAlert} onOk={()=>this.setState({txtAlert:'',modalAlert:false,})  } />
          <Alert text='No podrá volver a cambiar su estatus una vez realizada esta operación. ¿Desea continar?' visible={this.state.confirmCambiarStatus} onOk={() => this.agregarCita(2,true)  } withCancel onCancel={()=>this.setState({confirmCambiarStatus:false})} />

          <NotFound 
            visible={this.state.modalNotFound}
            title="¡Este regitro no está disponible!"
            txtNotFound='Es posible que el elemento que has intentado visualizar ya no exista o haya dejadod de ser público.'
            okText="Ver mi lista de citas"
            onOk={() => this.setState({modalNotFound:false}) }
          />


          {/* Filtros */}
          <div className="tituloYControles" style={{marginBottom:'10px'}} >

            <div className="metaControles">
              <h3 className="tituloPanel"> Citas agendadas - {this.state.nombreConfig} </h3>
              <span className="toggleFiltrar" id="toggleFiltrarId" onClick={this.classToggle} > Filtrar </span>
            </div>

            <div className="contControles" id="contControlesId">
              <div className="contControlesHijo" id="contControlesHijoId">
                <div className="filtradosExtras outer flexcenter Fspaceb" style={{width:'100%'}}  >
                  <div className="inputs-group-inline" style={{display:'none'}} id="filtersRBD" >
                    <label className="inputs-label" htmlFor="pesopd" >  Sucursal </label>
                    <Select className="inputs-input" value={this.state.indexConfig} onChange={this.changeControlCitas} >
                      <Select.Option value='' style={{display:'none'}} >Elija una sucursal </Select.Option>
                    {
                      this.state.sucursales.filter(s => this.props.validCurrentUser(this.context,'citas','bool',s['code'],false) )
                      .map((sucursal,i)=>(
                        <Select.Option value={sucursal['id']} key={'sucursal_'+i}> {sucursal['nombre']} </Select.Option>
                      ))
                    }
                    </Select>
                  </div>
                </div>
              </div>
            </div>


          </div>

         {/* detalle calendar */}
          <Modal Modal centered title="" closable={false} header={null} footer={null} zIndex={5} visible={this.state.reagending}
            className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}}
            onCancel={()=>document.getElementById('reagendingButton').click()}
            >
            <div className="reagenda">

              <div className="myheadCalendar flexcenter Fspaceb">
                <Icon type="left" className="control left" onClick={()=>this.setState({fechaReagendar: this.state.fechaReagendar.add(-1,'months') })} />
                <span>{this.state.fechaReagendar.format('DD-MMMM-YYYY')}</span>
                <Icon type="right" className="control right" onClick={()=>this.setState({fechaReagendar: this.state.fechaReagendar.add(1,'months') })} />
              </div>

              <Calendar className="mycalendar" value={this.state.fechaReagendar}
              //dateFullCellRender={this.cellrender}
              //onSelect={this.onSelect}
              //onPanelChange={this.onPanelChange}
              onChange={this.onPanelChange}
              />
              <div className="flexcenter Fend" style={{padding:'0 10px'}}>
                <button className="flexcenter Fcenter btn btn2" id="reagendingButton" onClick={()=>this.setState({reagending:false,fechaReagendar:this.state.hoy, formatedDate : this.state.hoy.format('dddd, LL')})} > Cancelar </button>
                <button className="flexcenter Fcenter btn btn1" onClick={this.setReagendarFecha} > Aceptar </button>
              </div>
            </div>
          </Modal>

            {/* Tarjetas cinepolis */}
          <Modal Modal centered title="" header={null} footer={null} zIndex={5} 
            onCancel={
              ()=>{
                let {justHora} = this.state
                if(justHora){ this.setState({reagending2:false,justHora:false,}) }
                else{ this.setState({reagending2:false,reagending:true}) }
              }
            }
            visible={this.state.reagending2} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
            <div className="reagenda">
              <div className="title"> Selecione un horario para la cita </div>
              <label className="nta"> Duración de la cita: {this.state.duracionCita} minutos. </label>
              <div className="citasCinepolis">
              {
                this.state.listaCitas2.map((cita,i)=>(
                  <div className="cuadrito flexcenter Fspacea" key={'cuadrito_'+i} onClick={()=>this.setReagendarHora(cita)} >
                    <div className={'cirulo '+(cita['descanso']?' descanso':cita['clase'])} />
                    {cita['inicio']}
                  </div>
                ))
              }
              </div>
            </div>
          </Modal>

         {/* motivo cancelacion */}
          <Modal centered title="" header={null} footer={null} zIndex={5} onCancel={()=>this.setState({cancelanding:false}) }
            visible={this.state.cancelanding} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
            <div className="controlesNuevaCita">
              <div className="title"> Ingrese el motivo de la cancelación </div>
              <div className="data flexcenter Fspaceb">
                <label>Motivo de cancelación </label>
              </div>
              <Input.TextArea className={"inputs-input"} id="motivoCancel" maxLength={50} autoSize={{minRows:1,maxRows:4}} />
              <button style={{marginTop:'25px'}} className="flexcenter Fcenter btnAdd" onClick={()=>this.agregarCita(3)} > Aceptar </button>
            </div>
          </Modal>

          <Modal centered title="" header={null} footer={null} zIndex={4} onCancel={ ()=> this.setState({addingCita:false,editanding:false, currentCita:{cita:Object.create(this.state.citaInterface)}, fechaReagendar:this.state.hoy, formatedDate : this.state.hoy.format('dddd, LL'), caducada:false, finalizada:false} ) }
            visible={this.state.addingCita} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
            <div className="controlesNuevaCita">
              <div className="title"> Detalle de la cita </div>
              <div style={{position:'relative'}}  >
              <label className="nta flexcenter Fcenter linkeable" onClick={()=>this.clickHoverable(1)} >
                {capitalizeFirs(this.state.formatedDate)}
              </label>

              {
                (
                  !this.props.validCurrentUser(this.context,'citas','bool',this.state.codeConfig,'EDITAR')
                  &&
                  this.state.editanding
                )&&
                <div className='fakemask' style={{zIndex:'201',top:'-5px',left:'-2.5%',width:'105%',height:'35px'}}
                  title='El perfil actual no tiene permisos para editar Citas sobre la sucursal relacionada con el registro actual.'
                  onClick={()=>this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Citas sobre la sucursal relacionada con el registro actual.'})} />
                }

              </div>

              <div className="detalle">

                <div className="data flexcenter Fspaceb">
                  <label> Cliente </label>
                </div>

                <div className="data flexcenter Fspaceb">
                  <Input className="inputs-input" value={this.state.currentCita['NOMBRE_CLIENTE']} readOnly />
                </div>
                
                <div className="data flexcenter Fspaceb middle" style={{position:'relative'}} >
                  <label>Horario cita</label>
                  <label>Estatus</label>

                  {
                  (
                    !this.props.validCurrentUser(this.context,'citas','bool',this.state.codeConfig,'EDITAR')
                    &&
                    this.state.editanding
                  )&&
                  <div className='fakemask' style={{zIndex:'201',top:'-5px',left:'-2.5%',width:'105%',height:'75px'}}
                    title='El perfil actual no tiene permisos para editar Citas sobre la sucursal relacionada con el registro actual.'
                    onClick={()=>this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Citas sobre la sucursal relacionada con el registro actual.'})} />
                  }

                </div>


                <div className="data flexcenter Fspaceb middle" >

                  <Input className="inputs-input linkeable" onClick={()=>this.clickHoverable(2)} value={this.state.mutableDataCita['inicio']+'-'+this.state.mutableDataCita['fin']} readOnly />
                  {
                    this.state.caducada && this.state.finalizada?
                      <Input className="inputs-input" value={this.switchEstatus(this.state.estatus)} readOnly />
                    :this.state.caducada?
                      <Select className="inputs-input" value={this.state.estatus}  onChange={estatus => this.setState({estatus})}  >
                        <Select.Option value={1} style={{display:'none'}} > Pendiente </Select.Option>
                        <Select.Option value={2} style={{display:'none'}} > Confirmada </Select.Option>
                        <Select.Option value={3} > No asistió </Select.Option>
                        <Select.Option value={4} > Asistió </Select.Option>
                      </Select>:
                    this.state.finalizada?
                      <Input className="inputs-input" value={this.switchEstatus(this.state.estatus)} readOnly />:
                      <Select className="inputs-input" value={this.state.estatus}  onChange={estatus => this.setState({estatus})}  >
                        <Select.Option value={1} style={{display:'none'}} > Pendiente </Select.Option>
                        <Select.Option value={2} > Confirmada </Select.Option>
                      </Select>
                  }
                  {/*<Input className="inputs-input" value={this.switchEstatus(this.state.estatus)} readOnly />*/}
                </div>

                <div className="data flexcenter Fspaceb">
                  <label> Comentarios por parte del cliente </label>
                </div>
                {
                  this.state.addingCita&&
                  <Input.TextArea className="inputs-input notfocusable" readOnly defaultValue={this.state.currentCita['OBSERVACIONES_CLIENTES']} maxLength={450} autoSize={{minRows: 1, maxRows:4,} } />
                }

                {
                  Number(this.state.estatus)===6&&
                  this.state.currentCita['MOTIVOS_CLIENTES']!==''&&
                  <React.Fragment>
                    <div className="data flexcenter Fspaceb">
                      <label> Motivo de la cancelación </label>
                    </div>
                    <Input.TextArea className="inputs-input" value={this.state.currentCita['MOTIVOS_CLIENTES']} readOnly maxLength={50} autoSize={{minRows: 1, maxRows:4,} } />
                  </React.Fragment>
                }

                <div className="data flexcenter Fspaceb">
                  <label> Comentarios por parte de la empresa </label>
                </div>

                {
                  this.state.addingCita&&
                  <Input.TextArea className="inputs-input" readOnly={Number(this.state.estatus)!==1} id="indicacion" defaultValue={this.state.currentCita['OBSERVACIONES_EMPRESA']} maxLength={450} autoSize={{minRows: 1, maxRows:4,} } placeholder="Agregar comentario o indicación" />   
                }


                {
                  Number(this.state.estatus)===6&&
                  this.state.currentCita['MOTIVOS_EMPRESA']!==''&&
                  <React.Fragment>
                    <div className="data flexcenter Fspaceb">
                      <label> Motivo de la cancelación </label>
                    </div>
                    <Input.TextArea className="inputs-input" value={this.state.currentCita['MOTIVOS_EMPRESA']} readOnly maxLength={50} autoSize={{minRows: 1, maxRows:4,} } />
                  </React.Fragment>
                }

                {
                  this.state.editanding&&//editando un cita
                  <div style={{marginTop:'25px'}} className="contButtons">
                  {
                    (!this.state.finalizada && this.state.caducada) ? //fecha vencida, pero esta pendiente o confirmada
                    <React.Fragment>
                      {
                        (Number(this.state.currentCita['ESTATUS'])===1 && this.props.validCurrentUser(this.context,'citas','bool',this.state.codeConfig,'ELIMINAR')) &&
                        <button className="flexcenter Fcenter btn btn2" style={{width:'100%'}} onClick={()=>this.setState({confirmEliminar:true})} > Eliminar </button>
                      }

                      {
                        this.props.validCurrentUser(this.context,'citas','bool',this.state.codeConfig,'EDITAR')&&
                        <button className="flexcenter Fcenter btnAdd" onClick={()=>this.agregarCita(2)} >
                          {Number(this.state.currentCita['ESTATUS'])===1 ? 'Confirmar' : 'Guardar'}
                        </button>
                      }

                    </React.Fragment>:
                    !this.state.finalizada ? //fecha futura, y aun no finalizada
                    <React.Fragment>

                      <div className="flexcenter Fspaceb">
                        {
                        Number(this.state.currentCita['ESTATUS'])===1?
                          <React.Fragment>
                          {
                            this.props.validCurrentUser(this.context,'citas','bool',this.state.codeConfig,'ELIMINAR')&&
                            <button className="flexcenter Fcenter btn btn2" style={{flexGrow:'1',marginRight:'20px'}} onClick={()=>this.setState({confirmEliminar:true})} > Eliminar </button>
                          }
                          </React.Fragment>
                          :
                          <React.Fragment>
                          {
                            this.props.validCurrentUser(this.context,'citas','bool',this.state.codeConfig,'EDITAR')&&
                            <button className="flexcenter Fcenter btn btn2" style={{flexGrow:'1',marginRight:'20px'}} onClick={()=>this.setState({cancelanding:true})} > Cancelar </button>
                          }
                          </React.Fragment>
                        }
                        <React.Fragment>
                        {
                          this.props.validCurrentUser(this.context,'citas','bool',this.state.codeConfig,'EDITAR')&&
                          <button className="flexcenter Fcenter btn btn2" style={{color:'#F07505',flexGrow:'1'}} id="btnReagendar" onClick={()=>this.setState({reagending:true,justHora:false})} > Reagendar </button>
                        }
                        </React.Fragment>
                      </div>

                      {
                        this.props.validCurrentUser(this.context,'citas','bool',this.state.codeConfig,'EDITAR')&&
                        <button className="flexcenter Fcenter btnAdd" onClick={()=>this.agregarCita(2)} >
                          {Number(this.state.currentCita['ESTATUS'])===1 ? 'Confirmar' : 'Guardar'}
                        </button>
                      }

                    </React.Fragment>:
                    <React.Fragment/>
                  }
                  </div>
                }

              </div>
            </div>
          </Modal>
          
          <div className="conCitas">
          <div className="listCItasBuBooker">
          {

            Object.entries(this.state.citas2).length===0?            
            <div className="nonResult flexcenter Fspaceb" style={{marginTop:'50px'}} >
              <img alt="..." src={panelAdmin+'noncitas.png'} />
            </div>:
            Object.entries(this.state.citas2).map(([fecha,citas],i) =>(
              <React.Fragment key={"block_"+i}>
                <div className="header" > {fecha} </div>
                <div className="conCitas">
                  {
                    citas.map((c,i)=> (
                      <div className="instanciaCita flexcenter Fspaceb" key={'citaHoy_'+i} onClick={()=>this.clickOnCita(c)} >
                        <div className="horas flexcenter Fcenter">
                          <span> {this.switchFecha(c['FECHA_CITA_INICIO'])} </span>
                          <span> {this.switchFecha(c['FECHA_CITA_TERMINO'])} </span>
                        </div>
                        <div className={"texto flexcenter Fstart "}>
                          
                          <div className={this.switchEstatus(c['ESTATUS'],2)+' barras'} />
                          
                          
                          
                          <div className="inTexto flextop Fcenter">
                            <span>{c['NOMBRE_CLIENTE']}</span>
                            {
                              c['OBSERVACIONES_CLIENTES'].trim() !=='' &&
                              <label>{c['OBSERVACIONES_CLIENTES']}</label>
                            }
                          </div>

                        </div>
                      </div>
                    ))
                  }
                </div>
              </React.Fragment>
            ))
          }            
          </div>
          </div>
          <div className="cont-instancias5"></div>
        
      
      
      </div>
      </div>
      </div>
    );
  }
}

export default withRouter(Configuracion);