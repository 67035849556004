import React from 'react';
import axios from 'axios';
import {Modal,Col,Row,Input,Icon,message} from 'antd';

import {withRouter} from 'react-router-dom'
import 'moment/locale/es'
import moment from 'moment'
import GoogleMapReact from 'google-map-react';
import Cropper from 'react-cropper';

import Loading from '../../../modalCargando/loading';
import { Globalrequest as request } from './../../../request.js';
import {Host as host,Img as Imgurl,gmapsKey,bancos} from './../../../app.json'

import {formatNumber,getImageBanco,formatDatosCard,copyTextGeneral} from './../../../utilities/generalfunctions'
import {Marka} from '../../../modalCargando/miDiv';

class Detalle extends React.Component{
  constructor(props){
    super(props);
    this.actualizarDatos = this.actualizarDatos.bind(this)

    this.verifyStatuPago = this.verifyStatuPago.bind(this)

    this.clickBeforeChangeFile = this.clickBeforeChangeFile.bind(this)
    this.changeFile = this.changeFile.bind(this)
    this.cancelCropper = this.cancelCropper.bind(this)
    this.acceptCropper = this.acceptCropper.bind(this)

    this.markAsPayed = this.markAsPayed.bind(this)

    this.showAddComent = this.showAddComent.bind(this)
    this.cancelarPedido = this.cancelarPedido.bind(this)

    this.state = {
      loading:true,
      /**/
      folio:'',
      /**/
      detalles:{ESTATUS:'',HORA_ATENDIDO:'',COMENTARIOS:'',NOMBRE_EMPRESA:'',ATENDIDO_POR:''},
      productos:[],
      idPed:sessionStorage.getItem('idPed'),
      /**/
      modalResponder:false,
      modalAlert:false,
      txtAlert:'',
      center: { lat: 16.859166 , lng: -99.872328, },
      usarMapa:false,
      /**/
      idNotificacionPorAtender:sessionStorage.getItem('idNotificacionPorAtenderB'),
      /**/
      modalMakeReport:false,
      modalPagarPedido:false,
      modalCropper:false,
      /**/
      bancos:bancos,//["ABC CAPITAL", "ACCIVAL", "ACTINVER", "AFIRME", "AKALA", "AMERICAN EXPRESS", "ASEA", "AUTOFIN", "AZTECA", "B&B", "BAJIO", "BAMSA", "BANAMEX", "BANCO FAMSA", "BANCOMEXT", "BANCOPPEL", "BANJERCITO", "BANOBRAS", "BANORTE", "BANREGIO", "BANSEFI", "BANSI", "BARCLAYS", "BBASE", "BBVA BANCOMER", "BMONEX", "BMULTIVA", "BULLTICK CB", "CB ACTINVER", "CB INTERCAM", "CB JPMORGAN", "CBDEUTSCHE", "CI BOLSA", "CIBANCO", "CLS", "COMPARTAMOS", "CONSUBANCO", "CREDIT SUISSE", "DEUTSCHE", "ESTRUCTURADORES", "EVERCORE", "FINAMEX", "FINCOMUN", "GBM", "HDI SEGUROS", "HIPOTECARIA FEDERAL", "HSBC", "INBURSA", "INDEVAL", "ING", "INTERACCIONES", "INTERBANCO", "INVEX", "IXE", "JP MORGAN", "KUSPIT", "LIBERTAD", "MAPFRE", "MASARI", "MERRILL LYNCH", "MIFEL", "MONEXCB", "NAFIN", "OACTIN", "OPCIONES EMPRESARIALES DEL NOROESTE", "ORDER", "PROFUTURO", "REFORMA", "SANTANDER", "SCOTIABANK", "SEGMTY", "SKANDIA", "SKANDIA", "SOFIEXPRESS", "STERLING", "STP", "SU CASITA", "TELECOMM", "THE ROYAL BANK", "TIBER", "TOKYO", "UBS BANK", "UNAGRA", "UNICA", "VALMEX", "VALUE", "VE POR MAS", "VECTOR", "VOLKSWAGEN", "WAL-MART", "ZURICH", "ZURICHVI"],
      /**/
      srcImg:'',
      classImg:'',
      loadedImg:false,
    }
  }

  componentDidMount(){
    document.title="Detalle pedido"
    
    let {makeRef} = this.props
    makeRef({mounted:true,component:this,name:'DetalleP'})

    let {idNotificacionPorAtender,folio} = this.state
    folio = this.props['match']['params']['folio']
    if(idNotificacionPorAtender !== 'null'){
      let data = {idN:idNotificacionPorAtender,estatus:2}
      axios.post(`${host}clientes/clientes/cambiarEstatusNotificacionBubooker`,data,request)
      sessionStorage.setItem('idNotificacionPorAtenderB','null')
    }
    this.actualizarDatos(folio)
  }

  actualizarDatos(folio){   
    let data = {folio,idC:sessionStorage.getItem('id')}
    this.setState({loading:true,folio})
    axios.post(`${host}pedidos/pedidos/detallesPedidoBubooker`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let productos2 = rt.data['data']['PRODUCTOS']
        let productos = []

        for(let i=0;i<productos2.length;i++){
          let producto = productos2[i]
          
          if( Number(producto['DEPENDENCIA_PRODUCTO']) > 0 && Number(producto['DEPENDENCIA_PROMOCION']) > 0 )
            continue;
          else if( Number(producto['DEPENDENCIA_PRODUCTO']) === 0 && Number(producto['DEPENDENCIA_PROMOCION']) > 0 && Number(producto['TIPO_PROMOCION']) > 2  )
            producto['SUBPRODUCTOS'] = [productos2[i+1]]
          else
            producto['SUBPRODUCTOS'] = []

          productos.push(producto)
        }

        rt.data['data']['DETALLES']['ATENDIDO_POR'] = rt.data['data']['DETALLES']['ATENDIDO_POR'].trim()

        let center = {lat:Number(rt.data['data']['DETALLES']['LATITUD']),lng: Number(rt.data['data']['DETALLES']['LONGITUD']) }
        let usarMapa = (Number(rt.data['data']['DETALLES']['USAR_MAPA']) > 0);
        let usartarjeta = (Number(rt.data['data']['DETALLES']['conTarjeta']) > 0);
        let srcImg,classImg,loadedImg
        if(rt.data['data']['DETALLES']['IMG_COMPROBANTE_PAGO_CLIENTE'].trim()===""){
          srcImg = Imgurl+'new_camera_icon.png'
          classImg = 'camarita'
        }else{
          srcImg = rt.data['data']['DETALLES']['FOLDER'] + rt.data['data']['DETALLES']['IMG_COMPROBANTE_PAGO_CLIENTE']+'.png'
          classImg = 'imgPago'
          loadedImg=true
        }

        let showRecibo = sessionStorage.getItem('showPago') || 'none'
        sessionStorage.removeItem('showPago')
        if(showRecibo !== 'none'){
          setTimeout(() => {
            this.verifyStatuPago('nonone')
          }, (500));
        }

        this.setState({loading:false, detalles:rt.data['data']['DETALLES'], classImg,srcImg,loadedImg, productos , usarMapa, center,usartarjeta })
      }else{
      }
    })
  }

  verifyStatuPago(e){
    let {detalles,usartarjeta} = this.state
    let status = Number(detalles['ESTATUS_PAGO'])
    
    if(
        (usartarjeta) &&
        (status===1 || status===2 )
      ){
      this.setState({modalPagarPedido:true})
      return
    }

  }

  clickBeforeChangeFile(e){
    let{loadedImg}=this.state
    if(loadedImg)
      e.preventDefault()
  }

  changeFile(evt){
    let {files} = evt.target
    if(files.length===0)return
    if(!files[0].type.includes('image/')){
        this.setState({txtAlert:'Solo puedes elegir imagenes',modalAlert:true})
        return;
    }
    let file = evt.target.files[0]
    let image = new Image()
    image.src = URL.createObjectURL(file);
    this.cropper.replace(URL.createObjectURL(file))
    evt.target.value=""
    this.setState({modalCropper:true})
  }

  cancelCropper(){
    this.cropper.replace('')
    this.setState({modalCropper:false})
  }

  acceptCropper(){
    this.setState({loading:true,modalCropper:false,})
    let src = this.cropper.getCroppedCanvas().toDataURL()
    let imagen = new Image()
    imagen.onload = () => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        canvas.width = imagen.width;
        canvas.height = imagen.height;
        ctx.drawImage(imagen, 0, 0);
        canvas.toBlob((blob)=>{
          let reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            this.setState({srcImg:reader.result,loading:false,classImg:'imgPago'})
          }
        },"image/webp")
    };
    imagen.src=src;
  }

   markAsPayed(status){//1 PENDIENTE DE PAGO 2 PAGADO 3 REMBOLSADO
    let {detalles,srcImg} = this.state
    if(!srcImg.includes('data:image/')){
      this.setState({modalAlert:true,txtAlert:'Debes elegir una imagen.'})
      return
    }
    let data = {idP:detalles['ID'],srcImg,idB:sessionStorage.getItem('id'),referencia:detalles['REFERENCIA_PAGO'],folio:detalles['FOLIO'],op:1}
    this.setState({loading:true,modalPagarPedido:false,})
    axios.post(`${host}pedidos/pedidos/agregarImagenPagoPedido`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        this.setState({ srcImg:rt.data['data'], loadedImg:true, loading:false,txtAlert:'Comprobante de pago cargado correctamente',modalAlert:true})
      }
      else{
        this.setState({loading:false,txtAlert:'Ocurrió un error, por favor intentalo más tarde.',modalAlert:true})
      }
    })
  }
  
  formatFecha(fecha){
    let fecha2 = moment(fecha,'YYYY-MM-DD')
    return fecha2.format('DD/MMMM/YYYY')
  }

  switchStatusPago(op,caso){
    op = Number(op)
    if(caso===1){
      if(op===1)return "Pendiente de pago"
      if(op===2)return "Pagado"
      if(op===3)return "Reembolso"
    }
    if(caso===2){
      if(op===1)return "pendientedepago"
      if(op===2)return "pagado"
      if(op===3)return "reembolso"
    }
  }

  showAddComent(){
    let motivoReporte = document.getElementById('reportd').value.trim();
    if(motivoReporte===''){
      this.setState({txtAlert:'El motivo del reporte es obligaorio',modalAlert:true})
      return
    }
    let metadata = JSON.parse(sessionStorage.getItem('metadata'))
    let {detalles} = this.state
    metadata = metadata['datos']
    let data = {
      tipoUsuario:'2',
      idUsuario:metadata['ID'],
      nombre:metadata['nombre']+' '+metadata['apellido'] ,
      correo:metadata['mail'],
      telefono: metadata['telefono'] ,
      tipoAyuda:'7',
      id:'-1',
      /**/
      folio:detalles['FOLIO'] ,
      idE:detalles['ID_EMPRESA'] ,
      nombreE:detalles['NOMBRE_EMPRESA'] ,

      idS:detalles['ID_SUCURSAL'] ,
      nombreS:detalles['NOMBRE_SUCURSAL'] ,

      comentario:motivoReporte ,
    }
    this.setState({modalMakeReport:false,loading:true})
    axios.post(`${host}pedidos/pedidos/reportarproblemaBu/-1`,data,request).then(rt=>{
      if(rt.data['status']==='ok' && Number(rt.data['msg'])>0){
        this.setState({loading:false,txtAlert:'El reporte ha sido registrado existosamente',modalAlert:true})
      }
      else{
        this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde',modalAlert:true})
      }
    })
  }

  formatNumberCel(num){
    if(num===undefined||num===null)return
    let numa = num.slice(0,3)
    let numb = num.slice(3,6)
    let numc = num.slice(6,10)
    return '('+numa+') '+numb + ' ' +numc ;
  }

  formatDireccionCliente(dom){
    return dom['CALLE']+ ' ' + dom['NUM_EXTERIOR']+dom['NUM_INTERIOR'] +', '+dom['COLONIA']+' C.P. '+dom['COD_POSTAL'] + ' '
    + dom['CIUDAD']+', '+dom['MUNICIPIO'] + ', '+dom['ESTADO'] +', '+ dom['PAIS']
  }

  cancelarPedido(){
    let {detalles} = this.state
    let comentario = document.getElementById('comentCanceld').value.trim()
    if(comentario === ''){
      this.setState({modalAlert:true,txtAlert:'El comentario es obligaorio'})
      return
    }
    let data = {
      idPedido:detalles['ID'],
      idUsuario:sessionStorage.getItem('id'),
      tipo:5,
      comentario
    }
    this.setState({loading:true})
    axios.post(`${host}pedidos/pedidos/cancelarPedidoBubooker`,data,request).then(rt=>{
      document.getElementById('comentCanceld').value = ''
      this.setState({loading:false,modalResponder:false})
      if(Number(rt.data)!==1){
        this.setState({modalAlert:true,txtAlert:'Parece que ocurrió un problema, por favor intentalo más tarde. [Código de error: SV-ER: #04-01]'})
      }else{
        detalles['ESTATUS'] = 'Cancelado'
        detalles['COMENTARIO_CANCELAR'] = comentario
        this.setState({ detalles:detalles})
      }
    })
  }

  copyText(id,meta){
    let a = copyTextGeneral(id)
    if(a.startsWith('ok'))
      message.success(meta+' copiado al portapapeles')
    else
      console.log(a)
  }

  
  render(){
    return (
      <div className="containerBrowserBubookerChildTwo">

        <Modal centered title="Cambiar el estatus de pago"
            okText="" okButtonProps={{ className:'btn btn1' , style:{ display:'none'} }}
            cancelText="" cancelButtonProps={{ className:'btn btn2' , style:{ display:'none'} }}
            visible={this.state.modalPagarPedido}
            className="miAntModal alertMy modalUnidad"
            onCancel={()=>this.setState({modalPagarPedido:false})}
            width={400} footer={null} zIndex={5}  >

          <input type="file" accept="image/*" id="inputPagoImg" onChange={this.changeFile} />
          
          <label htmlFor="inputPagoImg" onClick={this.clickBeforeChangeFile} className="flexcenter Fcenter inputNewPagoImg">
            <img id="loadImg" src={this.state.srcImg} className={this.state.classImg} title="Cargar comprobante de pago" alt="..." />
          </label>
          {
            !this.state.loadedImg&&<button className="bigButton" onClick={this.markAsPayed } > Cargar comprobante de pago </button>
          }
        </Modal>

         <Modal centered title="Configura tu foto de perfil" closable={false} maskClosable={false} keyboard={false} 
          okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
          cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',} }}
          visible={this.state.modalCropper} forceRender={true}
          onCancel={this.cancelCropper}
          onOk={this.acceptCropper}
          className="miAntModal newVar alertMy modalCrop" >
          <div className="wrapperCropper" title="Usa el scroll para hacer zoom" >
            <Cropper ref={element => this.cropper = element} src='' style={{height:'100%', width: '100%',zIndex:'5'}} aspectRatio={3/4} />                      
          </div>
        </Modal>

        <div className="containerBrowserBubookerChildThree">

            <Loading loading={this.state.loading} />

            <Modal centered title="Reportar un problema"
                okText="Guardar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
                cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
                visible={this.state.modalMakeReport}
                closable={false} //maskClosable={false} keyboard = {false} 
                className="miAntModal alertMy modalUnidad"
                onCancel={()=>this.setState({modalMakeReport:false})}
                onOk={this.showAddComent} zIndex={5} >
                <div className="miUniDiv" style={{margin:'-30px -10px',height:'auto'}} >
                {
                  this.state.modalMakeReport &&
                  <div className="inputs-group" style={{marginTop:'40px',marginBottom:'5px',height:'auto'}}>
                    <label className="inputs-label" htmlFor="reportd" style={{top:'-10px'}} > Motivo del reporte o comentario </label>
                    <Input.TextArea
                      className="inputs-input"
                      id="reportd" name="report" maxLength={300}
                      autoSize={{minRows:1,maxRows:4}}
                      /> 
                  </div>
                }
                </div>
            </Modal>

            <Modal centered title="Cancelar pedido" maskClosable={false} keyboard = {false} 
                okText="Guardar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
                cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
                visible={this.state.modalResponder}
                closable={false}
                className="miAntModal alertMy modalUnidad"
                onCancel={()=>this.setState({modalResponder:false})}
                onOk={this.cancelarPedido}
                zIndex={5} >
              <form id="formulaUnidad" style={{margintBottom:'10px'}} >
                {
                    this.state.modalResponder ?
                    <div className="miUniDiv" style={{margin:'-30px -10px',height:'auto'}} >

                        <div className="inputs-group" style={{marginTop:'40px',marginBottom:'5px',height:'auto'}}>
                            <label className="inputs-label" htmlFor="comentCanceld" style={{top:'-10px'}} > Comentario </label>
                            <Input.TextArea
                              className="inputs-input"
                              id="comentCanceld" name="comentCancel"
                              maxLength={200}
                              autoSize={{minRows:1,maxRows:4}}
                              /> 

                        </div>
                    </div>:
                    <React.Fragment/>
                }
              </form>
            </Modal>

            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' }}
                cancelButtonProps={{ className:'btn btn2', style: {display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy" >
                <div className="myAlertBody">
                    {
                        this.state.modalAlert   ?
                        <span className="txtAlert"> {this.state.txtAlert} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            <div style={{marginTop:'20px'}} className="itemBackBu flexcenter Fstart" onClick={()=>this.props.redirectTo('/config/pedidos')} >
              <Icon type="left" />
              <span> Regresar </span>
            </div>

            <div className="titleSectionBubooker flexcenter Fstart">
              Detalles pedido / {this.state.folio}
            </div>

            <div className="metaControles" >
              <h3 className="tituloPanel">  </h3>
            </div>

              <div className="tarjetaPedido2">
                <div className="headGris">{this.formatFecha(this.state.detalles['FECHA_PEDIDO'])}</div>
                <div>
                <div className="detallePedido">
                  
                  <div className="headhead" style={{background:'white'}} >
                    
                    <div className="info infoInline flex">
                     <div className="infoInlineChild"> <div className="meta">Folio: </div>  <div className="content">{this.state.detalles['FOLIO']}</div></div>
                     <div className="infoInlineChild">
                       <div className={"content left "+this.state.detalles['ESTATUS'].toLowerCase().split(' ').join('') }> {this.state.detalles['ESTATUS']} </div>
                     </div>
                    </div>                   
                   
                     <div className="info flex"> <div className="meta">Fecha pedido: </div>  <div className="content"> {moment(this.state.detalles['FECHA_PEDIDO']+' '+this.state.detalles['HORA_PEDIDO'],'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')} </div></div>
                     <div className="info flex"> <div className="meta">Sucursal: </div>  <div className="content">{this.state.detalles['NOMBRE_SUCURSAL']}</div></div>
                     <div className="info flex"> <div className="meta">Tiempo de entrega: </div>  <div className="content"> {Number(this.state.detalles['TIPO_ENTREGA']) ===1 ? 'Inmediata' : 'Programada'} </div></div>

                     {
                       Number(this.state.detalles['TIPO_ENTREGA']) !== 1&&
                       <React.Fragment>
                          <div className="info flex">
                            <div className="meta">Fecha de entrega: </div>
                            <div className="content">{moment(this.state.detalles['FECHA_ENTREGA'],'YYYY-MM-DD').format('DD/MM/YYYY')}</div>
                          </div>
                          <div className="info flex">
                            <div className="meta">Horario de entrega: </div>
                            <div className="content">{moment(this.state.detalles['HORA_ENTREGA'],'HH:mm:ss').format('HH:mm:ss')}</div>
                          </div>
                       </React.Fragment>
                     }
                  </div>

                  <div className="spaceMaker" />

                  <div className="contTabla">

                    <Row className="tablaHead" >
                      <Col span={4}> Cantidad </Col>
                      <Col span={12}> Nombre del producto </Col>
                      <Col span={4} className="monry" > Precio unitario </Col>
                      <Col span={4} className="monry" > Importe </Col>
                    </Row>
                     {
                        this.state.productos.map((producto,indice) =>(
                          <div key={'row_'+indice} className="filatablaPorProducto" >
                            <Row className="tablaBody" >
                              
                              <Col span={4} className="cantidad" > {producto['CANTIDAD']}x </Col>
                              
                              <Col span={12} className="campo" > {producto['NOMBRE_PRODUCTO']} </Col>
                              
                              <Col span={4} className="precio" >
                                
                                <div className={Number(producto['DESCUENTO'])>0?'preGris':'preNan'}>
                                  ${formatNumber(producto['PRECIO'],2) } MXN
                                </div>
                                {
                                  Number(producto['DESCUENTO'])>0&&
                                  <div className="preNan">
                                    ${ formatNumber( Number(producto['PRECIO']) - Number(producto['DESCUENTO'],2 ) ) } MXN
                                  </div>
                                }
                              </Col>

                              <Col className="campo imprt" span={4}> ${ formatNumber(producto['TOTAL'],2) }MXN </Col>

                            </Row>
                            {
                              producto.COMENTARIOS!==''&&
                              <Row className="tablaBody sub">
                                <Col span={4}></Col>
                                <Col span={20} className="campo" > {producto['COMENTARIOS']} </Col>
                              </Row>
                            }
                            {
                              producto.SUBPRODUCTOS.map((subproducto,indice2)=>(
                                <React.Fragment  key={'row_'+indice+'_'+indice2}>
                                <Row className="tablaBody sub">
                                  <Col span={4} className="cantidad" > {subproducto['CANTIDAD']}x </Col>
                                  <Col span={12} className="campo" > {subproducto['NOMBRE_PRODUCTO']} </Col>
                                  <Col span={4} className="precio" >
                                    {
                                      Number(subproducto['TIPO_PROMOCION']) === 4 ? 
                                        <div className='preNan'> ${ formatNumber(subproducto['PRECIO'],2) } MXN </div>
                                      :
                                      <React.Fragment>
                                        <div className={Number(subproducto['DESCUENTO'])>0?'preGris':'preNan'}> ${ formatNumber(subproducto['PRECIO'],2) } MXN </div>
                                        {
                                          Number(subproducto['DESCUENTO'])>0&&
                                          <div className="preNan">
                                              ${formatNumber( Number(subproducto['PRECIO']) - Number(subproducto['DESCUENTO'] ), 2)} MXN
                                          </div>
                                        }
                                      </React.Fragment>
                                    }
                                  </Col>

                                  <Col span={4} className="campo imprt imprtChild">
                                    <div className="preGris" > ${formatNumber( Number(subproducto['CANTIDAD']) * Number(subproducto['PRECIO'])  ,2)}MXN </div>
                                    <div className="preNan" > ${formatNumber(subproducto['TOTAL'],2)}MXN </div>
                                  </Col>

                                </Row>
                                {
                                  subproducto.COMENTARIOS!==''&&
                                  <Row className="tablaBody sub">
                                    <Col span={4}></Col>
                                    <Col span={20} className="campo" > {subproducto['COMENTARIOS']} </Col>
                                  </Row>
                                }
                                </React.Fragment>                              
                              ))
                            }
                          </div>
                        ))
                     }
                     {
                      this.state.detalles['ESTATUS'] !== 'ENTREGADO'?<React.Fragment/>:<React.Fragment/>
                     }
                  </div>

                  <div className="spaceMaker" />
                   
                   <div className="bodybody" >
                     <div className="info flex"> <div className="meta">Estatus de pago: </div>  <div className={"content "+this.switchStatusPago(this.state.detalles['ESTATUS_PAGO'],2)} onClick={this.verifyStatuPago} > {this.switchStatusPago(this.state.detalles['ESTATUS_PAGO'],1)} </div></div>
                     <div className="info flex"> <div className="meta">Forma de pago: </div>  <div className="content"> {this.state.detalles['FORMA_PAGO']} </div></div>

                     {
                       this.state.usartarjeta &&
                       <div className="tarjetaEmbded flexcenter Fstart" style={{width:'100%'}} >
                         <div style={{marginRight:'25px'}} >
                           <img alt="..." src={getImageBanco(Number(this.state.detalles['datosTarjeta']['BANCO']))} width={110} />
                         </div>

                         <div style={{flexGrow:'1'}} className="flexend Fspacea" >
                            
                            <div className="info" style={{display:'block'}} >
                              <div className="meta">{this.state.bancos[Number(this.state.detalles['datosTarjeta']['BANCO'])-1]}</div>
                              
                              <div className="subinfo flex">
                                <div className="meta">Titular: </div>
                                <div className="content"> {this.state.detalles['datosTarjeta']['NOMBRE_TITULAR']} </div>
                              </div>

                            </div>
                           


                           <div className="info flexcenter Fspaceb">
                             <div className="subinfo flex">
                               <div className="meta">Núm. Cuenta: </div>
                               <div className="content" id="copy1" > {formatDatosCard(this.state.detalles['datosTarjeta']['NUM_CUENTA'],1)} </div>
                             </div>

                             <Icon type="copy" onClick={()=>this.copyText('copy1','Número de Cuenta')} />
                           </div>

                           <div className="info flexcenter Fspaceb">
                             <div className="subinfo flex">
                               <div className="meta">Núm. Tarjeta: </div>
                               <div className="content" id="copy2"> {formatDatosCard(this.state.detalles['datosTarjeta']['NUM_TARJETA'],2)} </div>
                             </div>
                             
                             <Icon type="copy" onClick={()=>this.copyText('copy2','Número de Tarjeta')} />
                           </div>
                           
                           <div className="info flexcenter Fspaceb ">
                             
                             <div className="subinfo flex">
                               <div className="meta">Cuenta CLABE: </div>
                               <div className="content" id="copy3" > {formatDatosCard(this.state.detalles['datosTarjeta']['CUENTA_CLAVE'],3)} </div>
                             </div>

                             <Icon type="copy" onClick={()=>this.copyText('copy3','Cuenta CLABE')} />
                           </div>

                         </div>
                       </div>
                     }
                     
                     <div className="info flex"> <div className="meta">Subtotal: </div>  <div className="content"> ${ formatNumber(this.state.detalles['SUBTOTAL'],2) }MXN </div></div>

                     {
                       this.state.usarMapa &&
                       <div className="info flex"> <div className="meta">Gastos de envío: </div>
                         <div className="content">
                         {
                           Number(this.state.detalles['ENVIO'])>0?
                           '$'+formatNumber(this.state.detalles['ENVIO'],2)+' MXN':
                           'Gratis'
                         }
                         </div>
                       </div>
                     }

                     <div className="info flex"> <div className="meta">Descuento: </div>  <div className="content"> ${ formatNumber(this.state.detalles['DESCUENTO'],2) }MXN  </div></div>
                     <div className="info flex"> <div className="meta">Total: </div>  <div className="content preNan"> ${formatNumber(this.state.detalles['TOTAL'],2) }MXN </div></div>

                     <div className="info flex"> <div className="meta"/>  <div className="content refer"> #{this.state.detalles['REFERENCIA_PAGO']}  </div></div>
                   </div>

                   <div className="spaceMaker" />

                   <div className="bodybody">
                     <div className="info flex"> <div className="meta">Cliente: </div>  <div className="content"> {this.state.detalles['CLIENTE']} </div></div>
                     <div className="info flex"> <div className="meta">Correo: </div>  <div className="content"> {this.state.detalles['CORREO_CLIENTE']} </div></div>
                     <div className="info flex"> <div className="meta">Teléfono: </div>  <div className="content"> {this.formatNumberCel(this.state.detalles['TELEFONO_CLIENTE'])} </div></div>
                     <div className="info flex"> <div className="meta">WhatsApp: </div>  <div className="content"> {this.formatNumberCel(this.state.detalles['TELEFONO_CLIENTE'])} </div></div>

                     <div className="info flex"> <div className="meta">Tipo de entrega: </div>  <div className="content"> {this.state.usarMapa?'Envío a domicilio':'Recoger en sucursal'} </div></div>
                     {
                       this.state.usarMapa &&
                       <div className="info flexcenter Fcenter" >
                         <div className="content"> {this.formatDireccionCliente(this.state.detalles['DOMICILIO'])} </div>
                       </div>
                     }

                     {
                       this.state.usarMapa &&
                       <div className="infoMap">
                        <GoogleMapReact
                          bootstrapURLKeys={{key: gmapsKey}}
                          center={this.state.center}
                          defaultZoom={15}> 
                          <Marka lat={this.state.center['lat']}  lng={this.state.center['lng']} />
                        </GoogleMapReact>
                        </div>
                      }

                   </div>
                   
                  {
                    this.state.detalles['COMENTARIOS'].trim()!==''&&
                    <React.Fragment>
                      <div className="spaceMaker" />
                      <div className="bodybody">
                        <div className="comentario">
                          <div className="txtMeta"> Comentarios del cliente </div>
                          <div className="txtComen"> {this.state.detalles['COMENTARIOS']} </div>
                        </div>
                      </div>
                    </React.Fragment>
                  }

                   <div className="spaceMaker" />
                   {
                     (this.state.detalles['ESTATUS'].trim().toLowerCase() === 'entregado' || this.state.detalles['ESTATUS'].trim().toLowerCase() === 'cancelado') &&
                     <div className="bodybody">
                       
                       <div className="info flex">
                         <div className="meta"> Usuario atendió: </div>
                         <div className="content">
                           {
                             this.state.detalles['ATENDIDO_POR'].trim()===''?
                             this.state.detalles['NOMBRE_EMPRESA']:
                             this.state.detalles['ATENDIDO_POR']
                           }
                         </div>
                       </div>

                       {
                          this.state.detalles['HORA_ATENDIDO'].trim() !== '' &&
                          <div className="info flex">
                            <div className="meta">Fecha: </div> 
                            <div className="content"> {moment(this.state.detalles['HORA_ATENDIDO'],'YYYY-MM-DD H:mm A').format('DD/MM/YYYY, HH:mm:ss')} </div>
                          </div>
                       }
                       {
                         this.state.detalles['COMENTARIO_CANCELAR'].trim()!==''&&
                          <div className="comentario">
                            <div className="txtMeta"> Comentario de cancelación </div>
                            <div className="txtComen"> {this.state.detalles['COMENTARIO_CANCELAR']} </div>
                          </div>
                       }
                     </div>
                   }
                </div>
                </div>

                {
                  this.state.detalles['ESTATUS'].trim().toLowerCase() === 'pendiente' &&
                    <div className="footBut">
                      <button className="btn btn2" onClick={()=>this.setState({modalResponder:true})} > Cancelar pedido </button>
                    </div>                
                  
                }
                
                {
                  (
                   (this.state.detalles['ESTATUS'].trim().toLowerCase() === 'cancelado' ||
                   this.state.detalles['ESTATUS'].trim().toLowerCase() === 'entregado')
                   &&
                   (Number(this.state.detalles['canreport']) < 0 )
                  )
                  &&
                  <label className="makeReport flexcenter Fcenter" >
                    <span onClick={()=>this.setState({modalMakeReport:true})} > Reportar un problema </span>
                  </label>
                }

              </div>            
        </div>
       </div>
    );
  }
}

export default withRouter(Detalle);