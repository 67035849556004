import React from 'react';
import axios from 'axios';
import { Globalrequest as request } from './../../../request.js';
import { Host as host, Img as img,stripeKey } from './../../../app.json'
import { Modal } from 'antd';
import { Spin, Icon,Input} from 'antd';
import moment from 'moment'
import './stripe.scss';
import { loadStripe } from '@stripe/stripe-js';

import {formatNumber} from './../../../utilities/generalfunctions'

class Alert extends React.Component {
  //constructor(props){ super(props)     }
  render() {
    return (
      <Modal centered title="Atención" maskClosable={false} keyboard={false}
        okText="Aceptar"
        okButtonProps={{ className: 'btn btn1', style: { width: '120px' } }}
        cancelButtonProps={{ className: 'btn btn2', style: { display: 'none' } }}
        visible={this.props.modalAlert}
        closable={false}
        onOk={() => this.props.showHide(false, '')}
        className="miAntModal newVar alertMy"
      >
        <div className="myAlertBody">
          {
            this.props.modalAlert ?
              <span className="txtAlert"> {this.props.txtAlert} </span> :
              <React.Fragment />
          }
        </div>
      </Modal>
    )
  }
}

class Stripe extends React.Component {
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
    this.showHide = this.showHide.bind(this)
    this.makeMount = this.makeMount.bind(this)

    this.getClienteSecret = this.getClienteSecret.bind(this)

    this.actualizarDatosBDD = this.actualizarDatosBDD.bind(this)
    this.changeSimulated = this.changeSimulated.bind(this)
    this.showSecondModal = this.showSecondModal.bind(this)

    this.makeCardPlan = this.makeCardPlan.bind(this)
    this.clickonPlan = this.clickonPlan.bind(this)

    this.changeCode = this.changeCode.bind(this)
    this.aceptarCortesia = this.aceptarCortesia.bind(this)

    this.cotinuarConLimite = this.cotinuarConLimite.bind(this)
    this.continuarsinsuscripcion = this.continuarsinsuscripcion.bind(this)

    this.exitToMembre = this.exitToMembre.bind(this)

    this.state = {
      checked: 2,
      fecha: moment(),
      cargo: 8988,
      periodo: 'año',
      plan: 'price_1HupxbLbFLa4rut6b5D8vRXY',
      trial_period_days: 0,
      /**/
      modalAlert: false,
      txtAlert: '',
      loadingStripe2: false,
      loadingStripe: false,
      txtStripe: 'Cargando',
      /**/
      clientID: '',
      clientSecret: '',
      cardElement: {},
      paises: [{ id: 'MX', label: 'México' }, { id: 'US', label: 'Estados Unidos' }],
      tarjetaComplete: false,
      tarjetaError: true,
      /**/
      btnDisabled: false,
      listaPlanes: [],
      modalVisible: 1,
      /**/
      validData: {},
      statusEmpresa:'',
      nuevoIdLocal: -1,

      /**/
      addingCode:false,
      modalCortesia:false,
      /**/
      modalNirvana:false,
      /**/
      tipo:'Prueba',
      inicio:'dd/mm/yyyy',
      fin:'dd/mm/yyyy',
      nombreE:'Empresa',
    }
  }

  componentDidMount() {
    const { getRef } = this.props;
    getRef(this);
    axios.post(`${host}payment/GatewayStripe/listAllPlans/0x404`, 0, request).then(rt => {
      if (typeof (rt.data) === "object") {
        let listaPlanes = []
        listaPlanes.push(rt.data.filter(p => p['interval'] === 'month')[0])
        listaPlanes.push(rt.data.filter(p => p['interval'] === 'year')[0])
        let trial_period_days = listaPlanes[1]['trial_period_days']
        let cargo = Number(listaPlanes[1]['amount']) / 100 
        let plan = listaPlanes[1]['id']
        this.setState({ listaPlanes, trial_period_days,cargo ,plan})
      }
    }).catch(e => console.log(e))
  }

  componentWillUnmount() {
    const { getRef } = this.props;
    getRef(undefined);
  }

  makeMount() {
    let { validData,pp,estatusCalculado } = this.props
    this.setState({ validData, nuevoIdLocal: validData['idLocal'] })
    this.setState({ loadingStripe: true })
    loadStripe(stripeKey, { locale: 'es' }).then(stripe => {
      this.stripe = stripe

      let elements = this.stripe.elements()
      let cardElement = elements.create('card', {
        iconStyle: 'solid',
        style: { invalid: { color: '#ED3B48', iconColor: '#ED3B48' }, }
      })

      cardElement.on('change', (event) => {
        let div = document.getElementById('toshow')
        this.setState({ tarjetaComplete: event['complete'] })
        if (event.error) {
          div.textContent = '*' + event.error.message;
          this.setState({ tarjetaError: true })
        }
        else {
          div.textContent = '';
          this.setState({ tarjetaError: false })
        }
      })

      //let statusEmpresa = Number(metadata['ESTATUS'])
      if(estatusCalculado === 2 && pp >0){ this.setState({modalNirvana:true}) }
      this.setState({loadingStripe:false,cardElement: cardElement,statusEmpresa:estatusCalculado})
    })
  }

  getClienteSecret(flag = false){
    
    let { validData } = this.props

    let metadata = JSON.parse(sessionStorage.getItem('metadata'))['datos']
    let data = {
      "name": metadata['NOMBRE_COMERCIAL'],
      "email": metadata['CORREO_REGISTRO'],
      "customerStatus": validData['idFK'], //'cli_###CCCC',
      "number": metadata['ID'],
    }
    
    this.setState({ loadingStripe: true, txtStripe: 'Cargando'})
    axios.post(`${host}payment/GatewayStripe/createSetupForSubWeb`, data, request).then(rt => {
      if (rt.data.hasOwnProperty('clientId')) {
        this.setState({ clientID: rt.data['clientId'], clientSecret: rt.data['clientSecret'] })
        if (validData['idLocal'].toString() === '-1') {
          this.actualizarDatosBDD(rt.data['clientId'], 0);
        } else {
          this.setState({ loadingStripe: false })
        }        
        if(flag===10){ this.showSecondModal() }
      }
    })
  }

  showHide(visible, txt) {
    this.setState({ modalAlert: visible, txtAlert: txt })
  }

  actualizarDatosBDD(clientID, status) {
    let { validData, nuevoIdLocal } = this.state
    let validData2 = {}
    Object.assign(validData2, validData);
    validData2['idFK'] = clientID
    validData2['estatus'] = status
    validData2['idE'] = sessionStorage.getItem('id')
    validData2['idLocal'] = nuevoIdLocal
    axios.post(`${host}empresas/empresas/altaStripe`, validData2, request).then(rt => {
      if (rt.data === '-1') {
        console.log('error: ' + rt.data)
      }
      else {
        if (rt.data['response'] !== 'error') {
          let rta = Number(rt.data['response'])
          validData['idFK'] = rt.data['idFK']
          validData['estatus'] = rt.data['estatus']
          validData['idLocal'] = rt.data['idLocal']
          this.setState({ validData, nuevoIdLocal: rt.data['idLocal'] })
          if (rta === -2) {
            let customer = { customer: clientID }
            axios.post(`${host}payment/GatewayStripe/updateCustomerAfterSub`, customer, request).then(rt => {
              this.setState({ loadingStripe: false })
              this.props.controlSelf(false)
              this.props.tellmmeFunc()
            })
          }
          else this.setState({ loadingStripe: false })
        }
        else this.setState({ loadingStripe: false })
      }
    })
  }

  submit() {
    let { tarjetaError, tarjetaComplete } = this.state
    if (!tarjetaComplete) {
      this.showHide(true, 'Debe completar los datos de su tarjeta')
      return
    }
    if (tarjetaError) {
      this.showHide(true, 'Debe corregir los datos de su trajeta')
      return
    }
    let formData = new FormData(document.getElementById('formulaStripe'))
    let metacampos = {
      number: 'Número de tarjeta',
      cvc: 'CVC',
      dir1: 'Dirección',
      colonia: 'Colonia',
      ciudad: 'Ciudad',
      estado: 'Estado',
      cp: 'CP',
      pais: 'País',
    }
    let vacio = false, campo = ''
    for (let data of formData.entries()) {
      if (data[1].trim() === '') { vacio = true; campo = data[0]; break; }
    }
    if (vacio) {
      let html = (<span>El campo <span style={{ fontWeight: 'bold' }}>{metacampos[campo]}</span> no puede estar vacío</span>)
      this.showHide(true, html)
      return
    }
    let { clientSecret, clientID, cardElement, plan } = this.state
    let obj = {
      payment_method: {
        card: cardElement,
        billing_details: {
          "address": {
            "city": formData.get('ciudad'),
            "country": 'MX',
            "line1": formData.get('dir1'),
            "line2": formData.get('colonia'),
            "postal_code": formData.get('cp'),
            "state": formData.get('estado'),
          },
        }
      }
    }
    this.setState({ loadingStripe: true, txtStripe: 'Procesando método de pago' })
    this.stripe.confirmCardSetup(clientSecret, obj).then(result => {
      if (result.error) {
        let msjtoshow = ''
        let msj = result.error['message']
        if (msj.includes("insufficient")) {
          msjtoshow = "La tarjeta no tiene fondos suficientes para completar la compra. Intenta nuevamente con otra tarjeta.";
        } else if (msj.includes("lost")) {
          msjtoshow = "La tarjeta ingresada se encuentra actualmente reportada como extraviada. Intenta nuevamente con otra tarjeta.";
        } else if (msj.includes("stolen")) {
          msjtoshow = "La tarjeta ingresada se encuentra actualmente reportada como robada. Intenta nuevamente con otra tarjeta.";
        } else if (msj.includes("expired")) {
          msjtoshow = "La tarjeta ingresada ha expirado. Intenta nuevamente con otra tarjeta.";
        } else if (msj.includes("security")) {
          msjtoshow = "El código de seguridad de la tarjeta ingresada es incorrecto. Verifica e inténtalo nuevamente.";
        } else if (msj.includes("declined")) {
          msjtoshow = "Tu tarjeta fue rechazada.";
        } else if (msj.includes("support")) {
          msjtoshow = "Tu tarjeta no admite este tipo de compra.";
        } else {
          msjtoshow = msj;
        }
        this.setState({ loadingStripe: false })
        this.showHide(true, msjtoshow)
        return;
      } else {
        this.setState({ loadingStripe: true, txtStripe: 'Generando datos' })
        let { validData } = this.state
        let data = {
          paymentMethod: result['setupIntent']['payment_method'],
          customer: validData['idFK'],
          plan: plan,
          indata: validData['idLocal'],
        }
        axios.post(`${host}payment/GatewayStripe/createSubWeb`, data, request).then(rt => {
          if (rt.data['status'] === 'error') {
            this.showHide(true, 'Parece que ocurrio un error, por favor contacte con soporte. Error' + rt.data['response'])
            this.setState({ loadingStripe: false, })
          } else {
            this.setState({ loadingStripe: true, txtStripe: 'Iniciando membresía' })
            this.actualizarDatosBDD(clientID, 1);
          }
        })
      }
      /*else if(result['setupIntent'] && result['setupIntent']['result']==='succeeded'){ }*/
    })
  }

  changeSimulated(e) { }

  showSecondModal() {
    let { cardElement,clientSecret } = this.state
    if(clientSecret.length===0){
      this.getClienteSecret(10)
    }else{
      this.setState({ modalVisible: 2 })
      setTimeout(() => cardElement.mount('#cardElement'), 100)
    }
  }

  makeCardPlan(plan, i) {
    let montocentavos = Number(plan['amount']) / 100
    let montopesos = parseInt(montocentavos, 10)
    montocentavos -= montopesos
    montocentavos = montocentavos.toString()
    let label = plan['nickname'].replace('Membresía ', '')
    let montomen = montopesos/12
    
    montomen = formatNumber(montomen,2)

    if (montocentavos.length === 1) montocentavos = montocentavos + '0'
    if (plan['interval'] === 'month') {
      return (
        <div key='plan_mensual' className="plan" title="Plan Mensual" onClick={() => this.clickonPlan(plan, 1)}>
          <input type="radio" className="radioBubook" checked={this.state.checked === 1} onChange={this.changeSimulated} />
          <div className="etiqueta"> <div className="trapecio"> {label} </div>  <div className="triangulo"></div> </div>
          <div className="Precio">  <span>$</span> <label>{montopesos}</label> <h4> <span>.{montocentavos}</span> <label>MXN</label> </h4> </div>
        </div>
      )
    }
    if (plan['interval'] === 'year') {
      return (
        <div key='plan_anual' className="plan" title="Plan Anual" onClick={() => this.clickonPlan(plan, 2)}>
          <input type="radio" className="radioBubook" checked={this.state.checked === 2} onChange={this.changeSimulated} />
          <div className="etiqueta etiqueta2"> <div className="trapecio"> {label} </div>  <div className="triangulo"></div> </div>
          <div className="Precio">  <span>$</span> <label>{montopesos}</label> <h4> <span>.{montocentavos}</span> <label>MXN</label> </h4> </div>
          <div className="nota"> <div style={{fontSize:'17px'}} > Equivale a ${montomen} MXN mensual. </div> </div>
        </div>
      )
    }
  }

  clickonPlan(plan, checked) {
    let cargo = Number(plan['amount']) / 100
    let periodo = checked === 1 ? 'mes' : 'año'
    this.setState({ checked, cargo, periodo, plan: plan['id'], trial_period_days: plan['trial_period_days'] })//m
  }

  changeCode(){
    let codigo = document.getElementById('codid').value.trim()
    let metadata = JSON.parse(sessionStorage.getItem('metadata'))['datos']

    if(codigo===''){
      this.setState({txtAlert:'Debes especificar tu código promocional',modalAlert:true})
      return
    }
    let data = {idE:sessionStorage.getItem('id'),codigo}
    this.setState({loadingStripe:true,txtStripe:'Comprobando codigo'})
    axios.post(`${host}empresas/empresas/canjearCodigo/7`, data, request).then(rt => {
      if(rt.data['status']==='ok'){
        let rta = Number(rt.data['rta'][0]),msj = ''
        if(rta<0){
          switch(rta){
            case -1: msj = 'El código ingresado no existe'; break;
            case -2: msj = 'El código ingresado expiró'; break;
            case -3: msj = 'El código ingresado ha superado el límite de uso'; break;
            case -4: msj = 'La empresa ya canjeó el código'; break;
            default: msj ='Parece que ocurrio un error, por favor vuelve a intentarlo.';
          }
          this.setState({modalAlert:true,txtAlert:msj,loadingStripe:false,txtStripe:''})
        }
        else{
          let {tipo,inicio,fin} = this.state
          switch(rta){
            case 1: tipo = 'Cortesía'; break;
            case 2: tipo = 'Membresía'; break;
            default: break;
          }
          inicio = moment().format('DD/MM/YYYY')
          fin = moment(rt.data['rta'][1],'YYYY-MM-DD').format('DD/MM/YYYY')
          this.setState({tipo,inicio,fin,nombreE:metadata['NOMBRE_COMERCIAL'],modalCortesia:true,addingCode:false,loadingStripe:false,txtStripe:''})
        }
      }
    })
  }

  aceptarCortesia(){
    let {tellmmeFunc,controlSelf} = this.props
    this.setState({addingCode:false,modalCortesia:false,modalAlert:false,loadingStripe:false,loadingStripe2:false,})
    controlSelf(false)
    tellmmeFunc(-1)
  }

  cotinuarConLimite(){
    let {tellmmeFunc,controlSelf,updateState,editState} = this.props
    this.setState({modalNirvana:false,addingCode:false,modalCortesia:false,modalAlert:false,loadingStripe:false,loadingStripe2:false,})
    sessionStorage.setItem('TU9ET19QUlVFQkE=',1)
    controlSelf(false)
    updateState('mood',1)
    editState('mood',1)
    tellmmeFunc()
  }

  continuarsinsuscripcion(){
    let {controlSelf} = this.props
    this.setState({modalNirvana:false,addingCode:false,modalCortesia:false,modalAlert:false,loadingStripe:false,loadingStripe2:false,modalAvisoVencimientoProximo:false,})
    controlSelf(false)
  }

  exitToMembre(){
    let {setFromMembresia} = this.props
    setFromMembresia(false)
    this.continuarsinsuscripcion()
  }

  render() {
    return (
      <React.Fragment>
        <Alert showHide={this.showHide} modalAlert={this.state.modalAlert} txtAlert={this.state.txtAlert} />
        {
          this.state.modalVisible === 1 ?
            <Modal centered title="" closable={false} maskClosable={false} keyboard={false}
              visible={this.props.loading}
              zIndex={999} header={null} footer={null}
              className="nodalRegistroStripe"
              wrapClassName="nodalRegistroStripePapa" >


              <Modal centered title="" visible={this.state.addingCode} 
                closable={false} //maskClosable={false} keyboard={false} // //zIndex={999}
                header={null} footer={null} destroyOnClose
                className="nodalRegistroStripe"
                onCancel={()=>this.setState({addingCode:false})}
                wrapClassName="nodalRegistroStripePapa">
                
                <div className="contentstripe">

                  <div className="head pdd">
                    <img src={`${img}bubook_blanco.png`} alt="Bubook" title="Bubook" className="logoBu" />
                  </div>
                  
                  <div className="body codPro">
                    
                    <div className="title"> Ingrese el codigo promocional </div>

                    <div className="inputs-group" >
                      <label className="inputs-label" htmlFor="descripciond" style={{top:'-10px'}} > Código promocional </label>
                      <Input className="inputs-input" autoFocus id="codid" />
                    </div>

                    <div className="notaData">
                      Si tienes alguna duda acerca de tu código promocional, escríbenos a ayuda.empresas@bubook.mx o llama al (222) 954 5168.
                    </div>

                    <button onClick={this.changeCode} > Canjear código </button>

                  </div>

                </div>
              </Modal>

              <Modal centered title="" visible={this.state.modalCortesia} 
                maskClosable={false} keyboard={false} closable={false} //zIndex={999}
                header={null} footer={null} destroyOnClose
                className="nodalRegistroStripe"
                wrapClassName="nodalRegistroStripePapa">
                
                <div className="contentstripe">

                  <div className="head pdd">
                    <img src={`${img}bubook_blanco.png`} alt="Bubook" title="Bubook" className="logoBu" />
                  </div>
                  
                  <div className="body codPro table">
                    
                    <div className="title flexcenter Fcenter"> ¡Felicidades! </div>
                    <div className="titlesub flexcenter Fcenter"> Obtuviste una {this.state.tipo} </div>

                    <div className="tbla">
                      <div className="heade"> Detalle {this.state.tipo} </div>

                      <div className="rowt flexcenter Fspaceb">
                        <div className="meta"> Empresa </div>
                        <div className="data"> {this.state.nombreE} </div>
                      </div>

                      <div className="rowt flexcenter Fspaceb">
                        <div className="meta"> Inicio </div>
                        <div className="data"> {this.state.inicio} </div>
                      </div>

                      <div className="rowt flexcenter Fspaceb">
                        <div className="meta"> Vencimiento </div>
                        <div className="data"> {this.state.fin} </div>
                      </div>
                      
                    </div> 

                    <button onClick={this.aceptarCortesia} > Aceptar </button>

                  </div>

                </div>
              </Modal>

              <Modal centered title="" visible={this.state.modalNirvana} 
                maskClosable={false} keyboard={false} closable={false} //zIndex={999}
                header={null} footer={null} destroyOnClose width={450}
                className="nodalRegistroStripe"
                wrapClassName="nodalRegistroStripePapa">
                
                <div className="contentstripe">

                  <div className="head pdd">
                    <img src={`${img}bubook_blanco.png`} alt="Bubook" title="Bubook" className="logoBu" />
                  </div>
                  
                  <div className="body codPro">
                    <div className="title flexcenter Fcenter"> ¡Tu suscripción ha vencido! </div>
                    <img alt="..." src={img+'nirvana.svg'} className="nirvana" />
                    <div className="notaData">
                      Le informamos que su suscripción a BuBook Mx ha vencido debido a que no hemos podido procesar su última factura con éxito.
                      <br/><br/>Esto puede deberse a que su tarjeta ha expirado, no cuenta con saldo suficiente para realizar la operación o simplemente a un error temporal del sistema.
                      <br/><br/>Para continuar disfrutando todos los servicios que ofrece <i> BuBook.Mx </i> renueva tu suscripción lo más pronto posible.
                    </div>

                    <button className="whithe" onClick={this.cotinuarConLimite} > Continuar con funciones limitadas </button>

                    <button onClick={()=>this.setState({modalNirvana:false})} > Renovar mi suscripción </button>

                  </div>

                </div>

              </Modal>

              <Modal centered title="" visible={this.state.modalAvisoVencimientoProximo} 
                maskClosable={false} keyboard={false} closable={false} //zIndex={999}
                header={null} footer={null} destroyOnClose width={450}
                className="nodalRegistroStripe"
                wrapClassName="nodalRegistroStripePapa">
                
                <div className="contentstripe">

                  <div className="head pdd">
                    <img src={`${img}bubook_blanco.png`} alt="Bubook" title="Bubook" className="logoBu" />
                  </div>
                  
                  <div className="body codPro">
                    <div className="title flexcenter Fcenter"> ¡Tu suscripción vencerá pronto! </div>
                    <img alt="..." src={img+'nirvana.svg'} className="nirvana" />
                    
                    <div className="notaData">
                      Le informamos que su suscripción a BuBook Mx vencerá el proximo {moment(this.props.vence,'DD-MM-YYYY').format('dddd LL')}.
                      <br/>Si haces caso omiso a este mensaje, perderá el acceso a las funciones principales de <i> BuBook.Mx </i> despues de la fecha indicada.
                    </div>

                    <button className="whithe" style={{padding:'8px'}} onClick={this.continuarsinsuscripcion} > Continuar sin suscripción </button>

                    <button style={{padding:'8px'}} onClick={()=>this.setState({modalAvisoVencimientoProximo:false})} > Renovar mi suscripción </button>

                  </div>

                </div>

              </Modal>

              <Modal centered closable={false} maskClosable={false} keyboard={false}
                visible={this.state.loadingStripe || this.state.loadingStripe2} width={300}
                footer={null} header={null} bodyStyle={{ padding: '0' }} >
                <div className="loadLogin">
                  <label> {this.state.txtStripe} </label>
                  <Spin indicator={<Icon type="loading" style={{ fontSize: 50, color: '#FF9700' }} />} />
                </div>
              </Modal>

              <div className="contentstripe">
                <div className="head pdd">
                  <img src={`${img}bubook_blanco.png`} alt="Bubook" title="Bubook" className="logoBu" />
                </div>
                <div className="body">

                  <div className="titleBlock">
                    <div className="title black"> ¿Cuánto cuesta tener tu </div>
                    <div className="title orange"> propia aplicación? </div>
                  </div>

                  <div className="separadorRects"> <div className="rect3"></div> <div className="rect2"></div>  <div className="rect1"></div>  </div>

                  <div className="texto">
                    <div> ¡Elige la opción de suscripción que mejor se adapte a ti! </div>
                  </div>

                  {
                    this.state.listaPlanes.length > 0 ?
                      <div className="contPlanes">
                        {
                          this.state.listaPlanes.map((plan, i) => (
                            this.makeCardPlan(plan, i)
                          ))
                        }
                      </div> :

                      <div className="contPlanes">

                        <div className="plan" title="Plan Mensual" onClick={() => this.setState({ checked: 1, cargo: 749, periodo: 'mes', plan: 'price_1HupxbLbFLa4rut6IhEB1tSo' })}>
                          <input type="radio" className="radioBubook" checked={this.state.checked === 1} onChange={this.changeSimulated} />
                          <div className="etiqueta"> <div className="trapecio"> Mensual </div>  <div className="triangulo"></div> </div>
                          <div className="Precio">  <span>$</span> <label>749</label> <h4> <span>.00</span> <label>MXN</label> </h4> </div>
                        </div>

                        <div className="plan" title="Plan Anual" onClick={() => this.setState({ checked: 2, cargo: 8988, periodo: 'año', plan: 'price_1HupxbLbFLa4rut6b5D8vRXY' })}>
                          <input type="radio" className="radioBubook" checked={this.state.checked === 2} onChange={this.changeSimulated} />
                          <div className="etiqueta etiqueta2"> <div className="trapecio"> Anual </div>  <div className="triangulo"></div> </div>
                          <div className="Precio">  <span>$</span> <label>8,988</label> <h4> <span>.00</span> <label>MXN</label> </h4> </div>
                          <div className="nota"> <div style={{fontSize:'17px'}} > Equivale a $624.17 mensual </div> </div>
                          {/*<div className="nota"> <div> + 2 meses </div> <div> GRATIS </div> </div>*/}
                        </div>

                      </div>

                  }

                  <div className="flexcenter Fcenter" style={{ color: '#F07505', cursor: 'pointer',margin:'10px', fontSize:'16px' }} onClick={()=>this.setState({addingCode:true})} > ¿Tienes un código promocional? </div>

                  {
                    Number(this.props.validData['estatus'])===0?
                    <div className="prueba"> Empieza tu periodo de prueba de {this.state.trial_period_days} días y disfruta de: </div>:
                    <div className="prueba"> Expande tu mercado actual </div>
                  }
                  <div className="disfruta">
                    <div className="itemFruta"> <span> &#10003; </span> <label> Control del inventario masivo </label> </div>
                    <div className="itemFruta"> <span> &#10003; </span> <label> Publicación de promociones y ofertas </label> </div>
                    <div className="itemFruta"> <span> &#10003; </span> <label> Control de múltiples sucursales </label> </div>
                    <div className="itemFruta"> <span> &#10003; </span> <label> Control de citas y reservaciones </label> </div>
                  </div>

                  <div className="prueba"> ¡Y mucho más! </div>

                  <div className="warning">
                    {
                      Number(this.props.validData['estatus'])===0?
                      <React.Fragment>Después del periodo de prueba de {this.state.trial_period_days} días, BuBook cuesta ${formatNumber(this.state.cargo,2)} MXN al {this.state.periodo}. Puedes cancelar tu suscripción en cualquier momento.</React.Fragment>:
                      <React.Fragment>Al momento de suscribirte, <i> BuBook.Mx </i>  realiza un cargo ${formatNumber(this.state.cargo,2)} MXN al {this.state.periodo}. Puedes cancelar tu suscripción en cualquier momento.</React.Fragment>
                    }
                  </div>

                  <div className="contButton" onClick={this.showSecondModal} >
                    {
                      Number(this.props.validData['estatus'])===0?
                      <button disabled={this.state.btnDisabled} > Empieza tu prueba GRATIS </button>:
                      <button disabled={this.state.btnDisabled} > ¡Suscríbete ahora! </button>
                    }
                  </div>

                  
                  {
                    Number(this.props.estatusCalculado)===3&&
                    <div className="contButton" onClick={()=>this.setState({modalAvisoVencimientoProximo:true})} >
                      <button style={{background: 'none',color: '#ff9700'}} > Continuar sin suscripción </button>
                    </div>
                  }

                  {
                    this.props.fromMembresia&&
                    <div className="contButton" onClick={this.exitToMembre} >
                      <button style={{background: 'none',color: '#ff9700'}} > Cancelar </button>
                    </div>
                  }

                </div>

              </div>
            </Modal> :

            <Modal centered title="" closable={false} maskClosable={false} keyboard={false}
              visible={this.props.loading}
              zIndex={999} header={null} footer={null}
              className="nodalRegistroStripe"
              wrapClassName="nodalRegistroStripePapa" >

              <Modal centered maskClosable={false} keyboard={false}
                visible={this.state.loadingStripe || this.state.loadingStripe2} closable={false} width={300}
                footer={null} header={null} bodyStyle={{ padding: '0' }} >
                <div className="loadLogin">
                  <label> {this.state.txtStripe} </label>
                  <Spin indicator={<Icon type="loading" style={{ fontSize: 50, color: '#FF9700' }} />} />
                </div>
              </Modal>

              <div className="contentstripe">
                <div className="head pdd">
                  <img src={`${img}bubook_blanco.png`} alt="Bubook" title="Bubook" className="logoBu" />
                </div>
                <div className="body">
                  <h4 className="titleB" style={{ fontSize: '17px' }}> ¡Únete a BuBook! </h4>
                  <div className="prueba" style={{ fontSize: '15px' }} > ¡Suscríbete a BuBook y haz que tu empresa llegue a millones de personas en todo el mundo! </div>

                  <form id="formulaStripe">

                    <div className="bloqueInputs">
                      <h4 className="titleB titleLeft"> Método de pago </h4>

                      <div className="inputs-group" id="cardElement"></div>
                      <div className="error-content" id="toshow"></div>
                    </div>

                    {
                      this.state.tarjetaComplete && !this.state.tarjetaError &&
                      <div className="bloqueInputs">
                        <h4 className="titleB titleLeft"> Dirección de facturación </h4>

                        <div className="inputs-group">
                          <label className="inputs-label"> Dirección linea 1 (Calle, núm. ext., núm. int., etc.) </label>
                          <input type="text" className="inputs-input" name="dir1" id="dir1d" />
                        </div>

                        <div className="inputs-group inputs-group-inline">
                          <label className="inputs-label"> Colonia </label>
                          <input type="text" className="inputs-input" name="colonia" id="coloniad" />
                        </div>

                        <div className="inputs-group inputs-group-inline">
                          <label className="inputs-label"> Ciudad </label>
                          <input type="text" className="inputs-input" name="ciudad" id="ciudadd" />
                        </div>

                        <div className="fechaSexo">
                          <div className="inputs-group inputs-group-inline">
                            <label className="inputs-label"> Estado </label>
                            <input type="text" className="inputs-input" name="estado" id="estadod" />
                          </div>
                          <div className="inputs-group inputs-group-inline">
                            <label className="inputs-label"> C.P. </label>
                            <input type="text" className="inputs-input" maxLength={5} name="cp" id="cpd" />
                          </div>
                        </div>

                        <div className="inputs-group inputs-group-inline">
                          <label className="inputs-label"> País </label>
                          <input type="text" className="inputs-input" value="México" name="pais" readOnly id="paisd" />
                        </div>
                      </div>
                    }

                  </form>

                  <div className="warning">
                    Al suscribirte confirmas que has leído y aceptado los términos y condiciones de <i> BuBook.Mx </i> y que nos autorizas a realizar el cargo de ${formatNumber(this.state.cargo,2)} MXN por el primer {this.state.periodo} a tu tarjeta de crédito o débito una vez que termine el periodo de prueba de 7 días. Puedes cancelar tu suscripción en cualquier momento desde el panel de Configuración.<br />Para más información visita <i> BuBook.Mx </i>
                  </div>

                  <div className="contButton" onClick={()=>this.setState({modalVisible:1})} >
                    <button className="white" > Cancelar </button>
                  </div>
                  
                  <div className="contButton" onClick={this.submit} >
                    <button disabled={this.state.btnDisabled} > Suscribirme </button>
                  </div>

                </div>
              </div>
            </Modal>
        }
      </React.Fragment>
    )
  }
}
export default Stripe;