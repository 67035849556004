import React from 'react';
import axios from 'axios';
import {Globalrequest as request} from './../../../request.js'
import { Host as host,} from './../../../app.json';

import Loading from './../../../modalCargando/loading';
import Spinner from './../../../modalCargando/loadingSpinner';
import Alert from './../../../modalCargando/modalAlert';
import {Filtrar,Ubicacion,Corazon} from "./../../../utilities/svg.js";

/* Librerías */
import { Icon} from 'antd';
import { withRouter } from "react-router-dom";

import './direcciones.scss'

class Direcciones extends React.Component{
  constructor(props) {
    super(props);
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.confirmEliminar = this.confirmEliminar.bind(this)
    this.submitEliminar = this.submitEliminar.bind(this)
    this.makeFav = this.makeFav.bind(this)
    
    this.state={
    	id:sessionStorage.getItem('id'),
      listado:[],

      loading:false,
      modalAlert:false,
      txtAlert:'',
      modalEliminar:false,
      txtEliminar:'',

      idEliminar:-1,
    }
  }

  componentDidMount(){
    document.title = "Mis direcciones"
    /*let {makeRef} = this.props
    makeRef({mounted:true,component:this,name:'DireccionesA'})*/
    this.actualizarCatalogo()
  }

  actualizarCatalogo(){
    let {id,listado} = this.state
    let data = { idC:id}
    this.setState({loading:true})
    axios.post(`${host}clientes/clientes/domsUser`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        listado = rt.data['data']
        this.setState({listado,loading:false})
      }
    })
  }

  confirmEliminar(name,id){
    this.setState({modalEliminar:true,txtEliminar:'¿Está seguro que desea eliminar la dirección "'+name+'" de forma permanente?',idEliminar:id})
  }

  submitEliminar(){
    let {idEliminar} = this.state
    let data={id:idEliminar}
    this.setState({loading:true,modalEliminar:false,txtEliminar:''})
    axios.post(`${host}clientes/clientes/rmDom/0`,data,request).then(rt=>{
       if(rt.data['status']==='ok'){
        let rta = Number(rt.data['msg'])
        let msj = ''
        let {listado} = this.state
        if(rta===1){
          msj = "La dirección ha sido eliminada correctamente"
          listado = listado.filter(dir => dir['ID']!==idEliminar)
          if(listado.length>0)
            listado[0]['FAVORITO'] = 1
        }else{
          msj="Parece que ocurrió un error, por favor intentalo más tarde."
        }
        this.setState({loading:false,txtAlert:msj,modalAlert:true,listado,idEliminar:-1})
      }else{
        this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde.',modalAlert:true,})
      }
    })
  }

  formatAdress(obj){
    return obj['CALLE'] + ' ' +  obj['NUMERO_EXTERIOR'] + ' ' +
    ( (obj['NUMERO_INTERIOR']!=='0' && obj['NUMERO_INTERIOR']!== '' )? obj['NUMERO_INTERIOR'] : '') +
    ' ' + obj['COLONIA'] + ' C.P. ' + obj['COD_POSTAL'] + ' ' + obj['MUNICIPIO'] + ', ' + obj['CIUDAD'] + ', ' + obj['ESTADO']+'.'
  }

  detalle(id){
    let {redirectTo} = this.props
    if(id===-1){
      redirectTo('/config/direcciones/registrar')
    }else{
      redirectTo('/config/direcciones/'+id)
    }
  }

  makeFav(idD){
    let data={idD}
    this.setState({loading:true,})
    axios.post(`${host}clientes/clientes/Favdom/0`,data,request).then(rt=>{
       if(rt.data['status']==='ok'){
        let rta = Number(rt.data['msg']['0']['RESULTADO'])
        let msj = ''
        let {listado} = this.state
        if(rta===1){
          for(let direccion of listado){
            direccion['FAVORITO'] = (direccion['ID']===idD)?1:2
          }
          this.setState({listado,loading:false})
        }else{
          msj="Parece que ocurrió un error, por favor intentalo más tarde."
          this.setState({loading:false,txtAlert:msj,modalAlert:true,})
        }
      }else{
        this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde.',modalAlert:true,})
      }
    })
  }

  render(){
    return(
  		<div className="containerBrowserBubookerChildTwo">
      <div className="containerBrowserBubookerChildThree">
        
        <Loading loading={this.state.loading} />
        <Spinner loading={this.state.modalSpinner} text={this.state.txtSpinner}/>
        <Alert text={this.state.txtAlert} visible={this.state.modalAlert} onOk={()=>this.setState({modalAlert:false,txtAlert:''})} /> 
        <Alert text={this.state.txtEliminar} visible={this.state.modalEliminar} withCancel onOk={this.submitEliminar} onCancel={()=>this.setState({modalEliminar:false,txtEliminar:''})}  /> 

        <div style={{marginTop:'20px'}} className="itemBackBu flexcenter Fstart" onClick={()=>this.props.indiceRedirecter('/')} >
          <Icon type="left" />
          <span> Regresar </span>
        </div>
        
        <div className="titleSectionBubooker flexcenter Fstart">
          Mis direcciones
          <i><Filtrar/></i>
        </div>

        <div className="listAdressClient">
        {
          this.state.listado.map((dir,i) => (
            <div className="itemDireccion flexcenter Fspaceb" key={'dir_'+i}>

              <div className="metaInfo flexcenter Fstart">
              
                <i> <Ubicacion/> </i>

                <div className="info flextop Fstart">
                  <h4 className="flexcenter Fstart">
                    <i className={ Number(dir['FAVORITO']) === 1 ? 'yesfavorito' : 'nofavorito'} onClick={()=>this.makeFav(dir['ID'])} >
                    <Corazon /> </i> {dir['NOMBRE']}
                  </h4>
                  <span> {this.formatAdress(dir)} </span>
                  <label> Referencia:  {dir['REFERENCIA']}</label>
                  <label className="delete" onClick={()=>this.confirmEliminar( dir['NOMBRE'] , dir['ID'] )} > Eliminar </label>
                </div>

              </div>

              <button className="detail" onClick={()=>this.detalle(dir['NOMBRE'])} > Detalles </button>

            </div>
          ))
        }
          <div className="itemDireccion new flexcenter Fspaceb">
            
            <div className="metaInfo flexcenter Fstart">
              
              <i> <Ubicacion/> </i>
              <div className="info flexcenter Fstart">
                <h4>Dirección nueva</h4>
              </div>

            </div>

            <button className="new" onClick={()=>this.detalle(-1) } > Añadir </button>

          </div>
        </div>
      </div>
  		</div>
    );
  }
}

export default withRouter(Direcciones);