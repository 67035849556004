import React,{useState} from 'react';
import axios from 'axios'; 

import { Host,ImgGral as img2 } from './../../app.json';
import { Icon,Carousel} from 'antd';
import { Globalrequest as request   } from './../../request.js';

import { SwitchTransition, CSSTransition } from "react-transition-group";

import { withRouter } from "react-router-dom";

import {Estrella,Corazon} from "./../../utilities/svg.js";

const changeSrcError = (evt) => { evt.target.src=img2+'panelAdmin/perfilDefault.jpg'; }

const Section = props => {

  const minimoCounter = 12
  const [counter,setCounter] = useState(minimoCounter)

  const showMoreResenas = () => {
    const counterL = counter + minimoCounter
    setCounter(counterL)
  }

  const showLessresenas = () => {
    const counterL = counter - minimoCounter
    setCounter(counterL)
  }

  return(
    <div className="pageSection" id={props['ID']} >
      <div className="limiter">
        
        <div className="titleSection"> <span className="dark"> {props.titulo['dark']}  {props.titulo['jump'] && <br/>  }  </span> <span className="light"> {props.titulo['light']} </span> </div>

        <div className="contentSection flexcenter Fstart fww">
          {
            props.validUbication &&  !props.permisoUbicacion?
            <div className="txtNota">
              {props.txtNoUbication}
            </div>:
            props.data.length === 0?
            <div className="txtNota">
              {props.txtNoData}
            </div>
            :
            <React.Fragment>
            {
              props.data.map((empresa,i) => {
                if(i<counter)
                return(
                  <div className="tarjetaHorizontalEmpresa sombraBaseHover flexcenter Fcenter" key={'mueva_'+i} onClick={()=>props.indiceRedirecter('/empresas/'+empresa['USUARIO_EMPRESA'])}>
                    <div className="leftCard">
                      <img alt="..." src={empresa['FOLDER'] + empresa['IMG_PERFIL']+'.png'} onError={changeSrcError} />
                    </div>
                    
                    <div className="contentCard">
                      
                      <div className="head flextop Fspaceb">
                        <div className="nombreCard"> {empresa['NOMBRE_SOCIAL']} </div>
                        <div className="calf flexcenter Fspacea"> <Estrella className="starCalf" /> {Number(empresa['PUNTUACION']).toFixed(1)}  </div>
                      </div>
     
                      <div className="catCard"> {empresa['CATEGORIA']} </div>
                      
                      <div className="footCard">
                        <div className="catFrom flexcenter Fspaceb" onClick={(e)=>e.stopPropagation()}>
                          {empresa['PRODUCTO_BARA']}
                          <i id={props['prefijo']+''+i+'_C'} onClick={()=>props.verifyUser(props.agregarEliminarFavorito, empresa['USUARIO_EMPRESA'], empresa[props['indexID']] ,empresa['NOMBRE_SOCIAL'],props['prefijo']+''+i)}><Corazon className={'corazon ' + (props.isFavorita(empresa['USUARIO_EMPRESA'])?'filled':'')}/></i>
                          <Icon id={props['prefijo']+''+i+'_L'} type="loading" className="loading notshow"   />
                        </div>
                      </div>

                    </div>

                  </div>
                )
                return null
              })
            }

            {
              props.data.length > counter &&
              <div className="iconInteract" title="Ver más" > <Icon type="down" onClick={showMoreResenas} /> </div>
            } 

            {
              counter>minimoCounter&&
              <div className="iconInteract" title="Ver menos" > <Icon type="up" onClick={showLessresenas} /> </div>
            }
              
            </React.Fragment>
          }
        </div>
      </div>
    </div>
  )
}


class Home extends React.Component{

  constructor(props) {
    super(props);
    this.actualizarFavoritos = this.actualizarFavoritos.bind(this)
    this.agregarEliminarFavorito = this.agregarEliminarFavorito.bind(this)
    /**/
    //this.changeSrcError = this.changeSrcError.bind(this)
    this.changeBanner = this.changeBanner.bind(this)
    this.isFavorita = this.isFavorita.bind(this)
    this.verifyUser = this.verifyUser.bind(this)

    this.state={
      carrousel:[
        'https://bubook.nibirusystem.com/DocumentosBuBook/BuBook/BannersWeb/banner1.jpg',
        'https://bubook.nibirusystem.com/DocumentosBuBook/BuBook/BannersWeb/banner2.jpg',
        'https://bubook.nibirusystem.com/DocumentosBuBook/BuBook/BannersWeb/banner3.jpg',
        'https://bubook.nibirusystem.com/DocumentosBuBook/BuBook/BannersWeb/banner4.jpg',
        'https://bubook.nibirusystem.com/DocumentosBuBook/BuBook/BannersWeb/banner5.jpg',
        'https://bubook.nibirusystem.com/DocumentosBuBook/BuBook/BannersWeb/banner6.jpg',
        'https://bubook.nibirusystem.com/DocumentosBuBook/BuBook/BannersWeb/banner7.jpg',
      ],
      /**/
      withUser:this.props.withUser,
      favoritos:[],
    }
  }

  componentDidMount(){
    document.title = "BuBook Mx"
    let {makeRef} = this.props
    makeRef({mounted:true,component:this,name:'Home'})
    this.timer = setInterval(()=>document.getElementById('next').click(),5*1000);
    this.actualizarFavoritos();
    axios.post(`${Host}indice/indice/listaBanner`,0,request).then(rt=>{
      if(rt.data['status'] === 'ok' && typeof(rt.data['data']) === 'object' ){
        let a = []
        for(let item of rt.data['data']){
          if(item.includes('banner')){
            item = 'https://bubook.nibirusystem.com/DocumentosBuBook/BuBook/BannersWeb/'+item;
            a.push(item)
          }
        }
        this.setState({carrousel:a})
      }
    })
  }

  componentWillUnmount(){
    let {stateHandler} = this.props
    stateHandler('empresasBusquedaFiltradas2',[])
    stateHandler('showFilter',false)
    stateHandler('valueSearch','')
    clearInterval(this.timer)
  }

  agregarEliminarFavorito(link,idE,nombre,indice){
    let {favoritos} = this.state
    let {stateHandler} = this.props
    let data = {}
    let isFavorito = favoritos.filter(fav => fav['link']===link)
    if(isFavorito.length>0){
      isFavorito=isFavorito[0]
      data['op']=-1
      data['idFav'] = isFavorito['registro']
    }else{
      data['op']=1
      data['idU'] = sessionStorage.getItem('id')
      data['idE'] = idE
      data['tipo'] = 1
    }
    
    let corazao = document.querySelector('#'+indice+'_C')
    let loading = document.querySelector('#'+indice+'_L')

    corazao.classList.toggle('notshow')
    loading.classList.toggle('notshow')

    axios.post(`${Host}usuarios/usuarios/eliminarAgregarFavoritas`,data,request).then(rt=>{

      if(data['op']===-1){
        if(Number(rt.data)===1) favoritos = favoritos.filter(fav => fav['link']!==link)
      }
      else{
        if(Number(rt.data['status']) === 1)
          favoritos.push({link:link,nombre:nombre,registro:rt.data['registro']})
      }
      let metadata = JSON.parse(sessionStorage.getItem('metadata'))
      metadata['favoritas'] = favoritos
      sessionStorage.setItem('metadata',JSON.stringify(metadata))
      this.setState({favoritos})
      stateHandler('favoritos',favoritos)

      corazao.classList.toggle('notshow')
      loading.classList.toggle('notshow')

    })
  }
  
  actualizarFavoritos(){
    let {withUser,favoritos} = this.state
    if(withUser){
      let { stateHandler } = this.props
      favoritos = JSON.parse(sessionStorage.getItem('metadata'))
      if(favoritos!==null&&favoritos!==undefined){
        favoritos = favoritos['favoritas'] || []
        stateHandler('favoritos',favoritos)
      }
      else {favoritos = []}
      this.setState({favoritos})
    }
  }

  changeBanner(a){
    clearInterval(this.timer)
    if(a>0) this.banner.next()
    else this.banner.prev()
    this.timer = setInterval(()=>document.getElementById('next').click(),5*1000);
  }
  
  makeScrooll(a){ document.getElementById('innerContentSectionPopularesD').scrollLeft+=a }

  isFavorita(link){
    let {withUser,favoritos} = this.state
    return (withUser && favoritos.some(fav => fav['link']===link))
  }

  verifyUser(_callBack,arg1=0,arg2=0,arg3=0,arg4=-1){
    let {withUser} = this.state
    if(withUser){_callBack(arg1,arg2,arg3,arg4)}
    else{this.props.stateHandler('modalVerMas',true)}
  }
  

  render(){
    return(
      <React.Fragment>
      
      {/*Carrousel|banner*/}
      <div className="carrousel">
        <div className="flexcenter Fcenter control cotrolLeft" onClick={()=>this.changeBanner(-1)} > <Icon type="left" /> </div>
        <div className="flexcenter Fcenter control cotrolRigth" onClick={()=>this.changeBanner(1)} id="next" > <Icon type="right" /> </div>
        <div className="carrouselContent">
        <Carousel className="banner" ref={e => this.banner = e} >
        {
          this.state.carrousel.map((c,i) => (
            <div key={i} className="holiboli">
              <img alt="..." src={c} />
            </div>
          ))
         }
         </Carousel>
        </div>
      </div>

      <div className="mainswitchGroup">
        <SwitchTransition >
          <CSSTransition
            key={this.props.showFilter}
            addEndListener={(node, done) => {
              node.addEventListener("transitionend", done, false);
            }}
            classNames="switchGroup"
          >
           {
             this.props.showFilter?
              /*Empresas filtradas por buscador*/
              <div className="pageSectionParent">
                
                {/*<div className="pageSection" id="promocionesD">
                  <div className="limiter">
                    
                    <div className="titleSection"> <span className="dark"> Resultados para <br/> </span> <span className="light"> "{this.props.valueSearch}" </span> </div>

                    <div className="contentSection flexcenter Fstart fww">
                    {
                      this.props.empresasBusquedaFiltradas.map((empresa,i) => (
                        <div className="tarjetaHorizontalEmpresa sombraBaseHover flexcenter Fcenter" key={'mueva_'+i} onClick={()=>this.props.indiceRedirecter('/empresas/'+empresa['USUARIO_EMPRESA'])}>
                          <div className="leftCard">
                            <img alt="..." src={empresa['FOLDER'] + empresa['DIRECCION_IMG_PERFIL']+'.png'} onError={changeSrcError} />
                          </div>
                          
                          <div className="contentCard">
                            
                            <div className="head flextop Fspaceb">
                              <div className="nombreCard"> {empresa['NOMBRE_COMERCIAL']} </div>
                              <div className="calf flexcenter Fspacea"> <Estrella className="starCalf" /> {Number(empresa['PUNTUACION']).toFixed(1)}  </div>
                            </div>

                            <div className="catCard"> {empresa['NOMBRE_CATEGORIA']} </div>
                            
                            <div className="footCard">
                              <div className="catFrom flexcenter Fspaceb" onClick={(e)=>e.stopPropagation()}>
                                {empresa['PRODUCTO_BARA']}
                                <i id={'resultados'+i+'_C'} onClick={()=>this.verifyUser(this.agregarEliminarFavorito,empresa['USUARIO_EMPRESA'],empresa['ID'],empresa['NOMBRE_SOCIAL'],'resultados'+i)}><Corazon className={'corazon ' + (this.isFavorita(empresa['USUARIO_EMPRESA'])?'filled':'')}/></i>
                                <Icon id={'resultados'+i+'_L'} type="loading" className="loading notshow"   />
                              </div>
                            </div>

                          </div>

                        </div>
                      ))
                    }
                    </div>
                  </div>
                </div>*/}

                <Section 
                  ID="promocionesD"
                  titulo={{dark:'Resultados para',light:'"'+this.props.valueSearch+'"',jump:true}}
                  txtNoData="Lo sentimos, no encontramos empresas que cumplan el filtro ingresado"
                  prefijo="resultados"

                  data={this.props.empresasBusquedaFiltradas}
                  agregarEliminarFavorito={this.agregarEliminarFavorito}
                  verifyUser={this.verifyUser}
                  indiceRedirecter={this.props.indiceRedirecter}
                  isFavorita={this.isFavorita}

                  indexID="ID"

                />


              </div>:
              this.props.buscandoCategoria?
              /*Empresas filtradas por categoria*/
              <div className="pageSectionParent">
              {
                /*Populares filtradas por categoria*/
                this.props.popularesFiltradas.length>0?
                <div className="pageSection">
                  <div className="limiter">
                    <div className="titleSection"> <span className="dark"> Los más </span> <span className="light"> populares </span> <br/> <span className="dark"> "{this.props.categoriaBuscando}" </span> </div>
                    <div className="separadorRects flexcenter Fcenter"> <div className="rect1"/> <div className="rect2"/>  <div className="rect3"/>  </div>
                    <div className="contentSection">
                      <div className="descSection">
                        ¡Descubre los negocios que han tenido mayores
                        <br/>
                        ventas, la mejor puntuación y más reseñas!
                      </div>
                      <div className="flexcenter Fcenter control cotrolLeft" onClick={()=>this.makeScrooll(-100)} > <Icon type="left" /> </div>
                      <div className="flexcenter Fcenter control cotrolRigth" onClick={()=>this.makeScrooll(100)} > <Icon type="right" /> </div>
                      <div className="innerContentSection">
                      {
                        this.props.popularesFiltradas.map((empresa,i) => (
                          <div className="tarjetaVerticalEmpresa sombraBaseHover" key={'popular_'+i} onClick={()=>this.props.indiceRedirecter('/empresas/'+empresa['USUARIO_EMPRESA'])} >
                            <div className="head flexcenter Fspaceb">
                              <div className="topPlace flexcenter Fcenter"> Top {Number(i)+1}  </div>
                              <div className="calf flexcenter Fcenter"> <Estrella className="starCalf" />  {Number(empresa['PUNTUACION']).toFixed(1)} </div>
                            </div>
                            <div className="contentCard">
                              <img alt="..." src={empresa['FOLDER'] + empresa['IMG_PERFIL']+'.png'} onError={changeSrcError} />
                              <div className="nombreCard"> {empresa['NOMBRE_SOCIAL']} </div>
                              <div className="catCard"> {empresa['CATEGORIA']} </div>
                              <div className="catFrom flexcenter Fspaceb " onClick={(e)=>e.stopPropagation()}> 
                                {empresa['PRODUCTO_BARA']}
                                <i id={'popularesCat'+i+'_C'} onClick={()=>this.verifyUser(this.agregarEliminarFavorito,empresa['USUARIO_EMPRESA'],empresa['ID_EMPRESA'],empresa['NOMBRE_SOCIAL'],'popularesCat'+i)}><Corazon className={'corazon ' + (this.isFavorita(empresa['USUARIO_EMPRESA'])?'filled':'')}/></i>
                                <Icon id={'popularesCat'+i+'_L'} type="loading" className="loading notshow"   />
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      </div>
                    </div>
                  </div>
                </div>:<React.Fragment/>
              }
                

                {/*Filtradas por categoria*/}

                {/*<div className="pageSection" >
                  <div className="limiter">
                    <div className="titleSection"> <span className="dark"> Empresas en la categoría <br/> </span> <span className="light"> "{this.props.categoriaBuscando}" </span> </div>
                    <div className="separadorRects flexcenter Fcenter"> <div className="rect1"/> <div className="rect2"/>  <div className="rect3"/>  </div>
                    <div className="contentSection flexcenter Fstart fww">
                    {
                      this.props.empresasBusquedaFiltradas.length>0?
                      this.props.empresasBusquedaFiltradas.map((empresa,i) => (
                        <div className="tarjetaHorizontalEmpresa sombraBaseHover flexcenter Fcenter" key={'mueva_'+i} onClick={()=>this.props.indiceRedirecter('/empresas/'+empresa['USUARIO_EMPRESA'])}>
                          <div className="leftCard">
                            <img alt="..." src={empresa['FOLDER'] + empresa['DIRECCION_IMG_PERFIL']+'.png'} onError={changeSrcError} />
                          </div>
                          
                          <div className="contentCard">
                            
                            <div className="head flextop Fspaceb">
                              <div className="nombreCard"> {empresa['NOMBRE_COMERCIAL']} </div>
                              <div className="calf flexcenter Fspacea"> <Estrella className="starCalf" /> {Number(empresa['PUNTUACION']).toFixed(1)}  </div>
                            </div>

                            <div className="catCard"> {empresa['CATEGORIA']} </div>
                            
                            <div className="footCard">
                              <div className="catFrom flexcenter Fspaceb" onClick={(e)=>e.stopPropagation()}>
                                {empresa['PRODUCTO_BARA']}
                                <i id={'categoria'+i+'_C'} onClick={()=>this.verifyUser(this.agregarEliminarFavorito,empresa['USUARIO_EMPRESA'],empresa['ID_EMPRESA'],empresa['NOMBRE_SOCIAL'],'categoria'+i)}><Corazon className={'corazon ' + (this.isFavorita(empresa['USUARIO_EMPRESA'])?'filled':'')}/></i>
                                <Icon id={'categoria'+i+'_L'} type="loading" className="loading notshow"   />
                              </div>
                            </div>

                          </div>

                        </div>
                      )):
                      <div className="txtNota"> Lo sentimos, no encontramos empresas pertenecientes a la categoría "{this.props.categoriaBuscando}" </div>
                    }
                    </div>
                  </div>
                </div>*/}

                <Section 
                  ID="noID"
                  titulo={{dark:'Empresas en la categoría',light:'"'+this.props.categoriaBuscando+'"',jump:true}}
                  txtNoData={'Lo sentimos, no encontramos empresas pertenecientes a la categoría "'+this.props.categoriaBuscando+'"'}
                  prefijo="categoria"

                  data={this.props.empresasBusquedaFiltradas}
                  agregarEliminarFavorito={this.agregarEliminarFavorito}
                  verifyUser={this.verifyUser}
                  indiceRedirecter={this.props.indiceRedirecter}
                  isFavorita={this.isFavorita}
                  indexID="ID"

                />


              </div>
              :
              /*No especial display*/
              <div className="pageSectionParent">
                
                <div className="pageSection" id="promocionesD">
                  <div className="limiter">
                    <div className="titleSection"> <span className="dark"> Descubre nuestras <br/> </span> <span className="light"> promociones </span> </div>
                    <div className="contentSection flexcenter Fcenter">
                      <div className="txtTemporal">
                        ¡Próximamente!
                      </div>
                    </div>
                  </div>
                </div>

                <div className="pageSection" id="popularesD">
                  <div className="limiter">
                    <div className="titleSection"> <span className="dark"> Los más </span> <span className="light"> populares </span> </div>
                    <div className="separadorRects flexcenter Fcenter"> <div className="rect1"></div> <div className="rect2"></div>  <div className="rect3"></div>  </div>
                    <div className="descSection">
                      ¡Descubre los negocios que han tenido mayores
                      <br/>
                      ventas, la mejor puntuación y más reseñas!
                    </div>
                    <div className="contentSection">
                      <div className="flexcenter Fcenter control cotrolLeft" onClick={()=>this.makeScrooll(-100)} > <Icon type="left" /> </div>
                      <div className="flexcenter Fcenter control cotrolRigth" onClick={()=>this.makeScrooll(100)} > <Icon type="right" /> </div>
                      <div className="innerContentSection" id="innerContentSectionPopularesD">
                      {
                        this.props.empresasPopulares.map((empresa,i) => (
                          <div className="tarjetaVerticalEmpresa sombraBaseHover" key={'popular_'+i} onClick={()=>this.props.indiceRedirecter('/empresas/'+empresa['USUARIO_EMPRESA'])} >
                            <div className="head flexcenter Fspaceb">
                              <div className="topPlace flexcenter Fcenter"> Top {Number(i)+1}  </div>
                              <div className="calf flexcenter Fcenter"> <Estrella className="starCalf" />  {Number(empresa['PUNTUACION']).toFixed(1)} </div>
                            </div>
                            <div className="contentCard">
                              <img alt="..." src={empresa['FOLDER'] + empresa['IMG_PERFIL']+'.png'} onError={changeSrcError} />
                              <div className="nombreCard"> {empresa['NOMBRE_SOCIAL']} </div>
                              <div className="catCard"> {empresa['CATEGORIA']} </div>
                              <div className="catFrom flexcenter Fspaceb " onClick={(e)=>e.stopPropagation()}> 
                                {empresa['PRODUCTO_BARA']}
                                <i id={'populares'+i+'_C'} onClick={()=>this.verifyUser(this.agregarEliminarFavorito,empresa['USUARIO_EMPRESA'],empresa['ID_EMPRESA'],empresa['NOMBRE_SOCIAL'],'populares'+i)}><Corazon className={'corazon ' + (this.isFavorita(empresa['USUARIO_EMPRESA'])?'filled':'')}/></i>
                                <Icon id={'populares'+i+'_L'} type="loading" className="loading notshow"   />
                              </div>
                            </div>
                          </div>
                        ))
                      }
                      </div>
                    </div>
                  </div>
                </div>

                <Section 
                  ID="nuevosD"
                  titulo={{dark:'Nuevos en',light:'BuBook',}}
                  txtNoData="Lo sentimos, no encontramos empresas que cumplan el filtro de 'Nuevos en BuBook'"
                  prefijo="nuevos"

                  data={this.props.empresasNuevas}
                  agregarEliminarFavorito={this.agregarEliminarFavorito}
                  verifyUser={this.verifyUser}
                  indiceRedirecter={this.props.indiceRedirecter}
                  isFavorita={this.isFavorita}

                  indexID="ID_EMPRESA"
                />

                {/*<div className="pageSection" id="nuevosD">
                  <div className="limiter">
                    <div className="titleSection"> <span className="dark"> Nuevos en </span> <span className="light"> BuBook </span> </div>
                    <div className="contentSection flexcenter Fstart fww">
                      {
                        this.props.empresasNuevas.length === 0?
                         <div className="txtNota">
                          Lo sentimos, no encontramos empresas que cumplan el filtro de 'Nuevos en BuBook'.
                        </div>
                        :
                        this.props.empresasNuevas.map((empresa,i) => (
                          <div className="tarjetaHorizontalEmpresa sombraBaseHover flexcenter Fcenter" key={'mueva_'+i} onClick={()=>this.props.indiceRedirecter('/empresas/'+empresa['USUARIO_EMPRESA'])}>
                            <div className="leftCard">
                              <img alt="..." src={empresa['FOLDER'] + empresa['IMG_PERFIL']+'.png'} onError={changeSrcError} />
                            </div>
                            
                            <div className="contentCard">
                              
                              <div className="head flextop Fspaceb">
                                <div className="nombreCard"> {empresa['NOMBRE_SOCIAL']} </div>
                                <div className="calf flexcenter Fspacea"> <Estrella className="starCalf" /> {Number(empresa['PUNTUACION']).toFixed(1)}  </div>
                              </div>

                              <div className="catCard"> {empresa['CATEGORIA']} </div>
                              
                              <div className="footCard">
                                <div className="catFrom flexcenter Fspaceb" onClick={(e)=>e.stopPropagation()}>
                                  {empresa['PRODUCTO_BARA']}
                                  <i id={'nuevos'+i+'_C'} onClick={()=>this.verifyUser(this.agregarEliminarFavorito, empresa['USUARIO_EMPRESA'], empresa['ID_EMPRESA'], empresa['NOMBRE_SOCIAL'],'nuevos'+i)}><Corazon className={'corazon ' + (this.isFavorita(empresa['USUARIO_EMPRESA'])?'filled':'')}/></i>
                                  <Icon id={'nuevos'+i+'_L'} type="loading" className="loading notshow"   />
                                </div>
                              </div>

                            </div>

                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>*/}

                <Section
                  ID="esquinaD"
                  titulo={{dark:'A la vuelta de la',light:'esquina',}}                  
                  txtNoData="Lo sentimos, no encontramos empresas cercanas a tu ubicación."
                  prefijo="cercanos"

                  validUbication
                  permisoUbicacion={this.props.permisoUbicacion}
                  txtNoUbication="Lo sentimos, necesitas activar la ubicación para ver empresas cercanas."

                  data={this.props.empresasCercanas}
                  agregarEliminarFavorito={this.agregarEliminarFavorito}
                  verifyUser={this.verifyUser}
                  indiceRedirecter={this.props.indiceRedirecter}
                  isFavorita={this.isFavorita}

                  indexID="ID_EMPRESA"
                />

                {/*<div className="pageSection" id="esquinaD">
                  <div className="limiter">
                    <div className="titleSection"> <span className="dark"> A la vuelta de la </span> <span className="light"> esquina </span> </div>
                    <div className="separadorRects flexcenter Fcenter"> <div className="rect1"></div> <div className="rect2"></div>  <div className="rect3"></div>  </div>
                    <div className="contentSection flexcenter Fstart fww">
                      {
                        !this.props.permisoUbicacion ?
                        <div className="txtNota">
                          Lo sentimos, necesitas activar la ubicación para ver empresas cercanas.
                        </div>
                        :
                        this.props.empresasCercanas.length === 0?
                         <div className="txtNota">
                          Lo sentimos, no encontramos empresas cercanas a tu ubicación.
                        </div>
                        :
                        this.props.empresasCercanas.map((empresa,i) => (
                          <div className="tarjetaHorizontalEmpresa flexcenter Fcenter" key={'cercana_'+i} onClick={()=>this.props.indiceRedirecter('/empresas/'+empresa['USUARIO_EMPRESA'])} >
                            <div className="leftCard">
                              <img alt="..." src={empresa['FOLDER'] + empresa['IMG_PERFIL']+'.png'} onError={changeSrcError} />
                            </div>
                            
                            <div className="contentCard">
                              
                              <div className="head flextop Fspaceb">
                                <div className="nombreCard"> {empresa['NOMBRE_SOCIAL']} </div>
                                <div className="calf flexcenter Fspacea"> <Estrella className="starCalf" /> {Number(empresa['PUNTUACION']).toFixed(1)}  </div>
                              </div>

                              <div className="catCard"> {empresa['CATEGORIA']} </div>
                              
                              <div className="footCard">
                                <div className="catFrom flexcenter Fspaceb" onClick={(e)=>e.stopPropagation()}>
                                  {empresa['PRODUCTO_BARA']}
                                  <i id={'cercanos'+i+'_C'} onClick={()=>this.verifyUser(this.agregarEliminarFavorito,empresa['USUARIO_EMPRESA'],empresa['ID_EMPRESA'],empresa['NOMBRE_SOCIAL'],'cercanos'+i)}><Corazon className={'corazon ' + (this.isFavorita(empresa['USUARIO_EMPRESA'])?'filled':'')}/></i>
                                  <Icon id={'cercanos'+i+'_L'} type="loading" className="loading notshow"   />
                                </div>
                              </div>

                            </div>

                          </div>
                        ))
                      }
                    </div>
                  </div>
                </div>*/}

              </div>
          }
          </CSSTransition>
        </SwitchTransition>
      </div>

      </React.Fragment>
    );
  }
}

export default withRouter(Home);