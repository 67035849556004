import React,{useEffect,useState,useContext} from 'react';

import axios from 'axios';

import { Globalrequest as request } from './../../../request.js';
import {Host as host ,  Img as img} from './../../../app.json'
import {Modal,message} from 'antd';

import {withRouter} from 'react-router-dom'

import Loading from '../../../modalCargando/loading';

import Alert from '../../../modalCargando/modalAlert';

import {userContext} from './../context'

import   './unidades.scss';

const Lista = props => {
  const contextType = useContext(userContext)

  const tiposUnidad = [ 'Unidad', 'Longitud', 'Área', 'Volumen', 'Peso', 'Otro' ]

  const [catalogoDeUnidades,setCatalogoDeUnidades] = useState([])

  const [loading,setLoading] = useState(false)
  const [editanding, setEditanding] = useState(false,)
  const [agregarEditar, setAgregarEditar] = useState(false)

  const [nuevaUnidad, setNuevaUnidad] = useState({})

  const [modalAlert,setModalAlert] = useState({visible:false,txt:'',})
  const [modalConfirm,setModalConfirm] = useState({visible:false,txt:'',tipo:-1,id:-1})
  const [modalNivel3,setModalNivel3] = useState({visible:false,txt:'',tipo:-1,title:'',})


  useEffect(()=>{
    actualizarCatalogo()
    return ()=>{}
  },[])

  const actualizarCatalogo = () => {
    let HTTPHeaders = {id:sessionStorage.getItem('id')}
    setLoading(true)
    axios.post(`${host}productos/productos/catalogoDeUnidades`,HTTPHeaders,request).then(rt=>{
      setLoading(false)
      setCatalogoDeUnidades(rt.data)
    })
  }

  const showModalConfirm = (e,id,tipo,msj) => {//  1 -> eliminar || 2 -> desactivar || 3 -> eliminar  
    e.stopPropagation()

    let {valid,mood,validCurrentUser} = props

    if(!validCurrentUser(contextType,'unidades','bool',false,'ELIMINAR')){
      setModalAlert({visible:true,txt:'El perfil actual no  tiene permisos para eliminar Unidades de Medida',})
      return
    }

    
    if(!valid){
      setModalConfirm({visible:false,tipo:-1,id:-1,txt:''})
      if(Number(mood)===1){
        setModalAlert({visible:true,txt:'No puedes acceder a esta opción porque tu cuenta está con funciones limitadas.'})
      }else{
        setModalAlert({visible:true,txt:'No puedes acceder a esta opción porque debes tener una suscripción activa.'})
      }
      return
    }

    if(contextType['type']===2)
      msj = 'Esta acción afectará a todas la sucursales de la empresa.'
    else
      msj = '¿Desea eliminar la unidad de medida '+msj+'?'

    setModalConfirm({visible:true,id,tipo,txt:msj})
  }

  const acceptConfirm = () => {
    let {id,tipo} = modalConfirm
    if(tipo===1)
      eliminar(id)
  }

  const eliminar = (id) => {
    let unidad = catalogoDeUnidades.filter( unidad =>  unidad.ID === id );
    if(Number(unidad[0].EDITABLE)===2){
      setModalAlert({txt:'No puedes eliminar esta unidad',visible:true,})
      return
    }
    let data = {id:unidad[0].ID}
    setLoading(true)
    setModalConfirm({visible:false,txt:'',tipo:-1,id:-1})
    
    axios.post(`${host}productos/productos/eliminarUnidadMedidaEmpresa`,data,request).then(rt=>{
      if(Number(rt.data)===-1){
        setModalAlert({txt:'Unidad eliminada',visible:true})
        actualizarCatalogo()
      }
      else if(Number(rt.data)>0){
        setLoading(false)
        setModalAlert({txt:'No puedes eliminar esta unidad de medida porque tienes productos asociados',visible:true,})
      }
      else{
        setLoading(false)
        setModalAlert({txt:'Parece que ocurrió un error, por favor intentalo más tarde [Código de error: SV-ER: #15-02]',visible:true,})        
      }
    })
  }

  const showModaNew = (id) => {
    let {valid,mood,validCurrentUser} = props

    if(id!==-1 && !validCurrentUser(contextType,'unidades','bool',false,'EDITAR') ){
      setModalAlert({visible:true,txt:'El perfil actual no  tiene permisos para editar Unidades de Medida',})
      return
    }

    if(!valid){
      if(Number(mood)===1){
        setModalAlert({visible:true,txt:'No puedes acceder a esta opción porque tu cuenta está con funciones limitadas.'})
      }else{
        setModalAlert({visible:true,txt:'No puedes acceder a esta opción porque debes tener una suscripción activa.'})
      }
      return
    }

    let nuevaUnidadLocal = {},
        editandingLocal = false

    if(id===-1)
      nuevaUnidadLocal = { descripcion:'', id:-1, idE:sessionStorage.getItem('id'), editable:1, }
    else{
      if(Number(id['EDITABLE'])===2){
        message.error('No puedes editar esta unidad de medida')
        return
      }
      nuevaUnidadLocal = { descripcion:id.DESCRIPCION, id:id.ID, idE:id.ID_EMPRESA, editable:id.EDITABLE, }
      editandingLocal = true
    }

    setEditanding(editandingLocal)
    setNuevaUnidad(nuevaUnidadLocal)
    setAgregarEditar(true)
  }

  const submit = (force) => {
    let formData = new FormData(document.getElementById('formulaUnidad'));

    if(contextType['type']===2 && !force){
      let labelAction = editanding ? 'Editar' : 'Crear'
      setModalNivel3({ visible:true,title:`¿${labelAction} Unidad de medida?`,txt:'Esta acción afectará a todas las sucursales de la empresa.',tipo:1 })
      return
    }

    if(Number(formData.get('editable'))===2){
      setModalAlert({txt:'No puedes editar esta unidad de medida',visible:true})
      return
    }
    
    if(formData.get('descripcion').trim()===''){
      setModalAlert({txt:'No puede haber campos vacíos',visible:true})
      return
    }

    let repetidos = catalogoDeUnidades.filter( unidad => unidad.ID !== formData.get('id') )    
    repetidos = repetidos.filter( unidad => unidad.DESCRIPCION.toLowerCase().trim() === formData.get('descripcion').toLowerCase().trim() )

    if(repetidos.length >= 1){
      setModalAlert({txt:'Ya existe una unidad de medida con el nombre '+formData.get('descripcion'),visible:true})
      return
    }

    let HTTPHeaders={ id: formData.get('id') , idE: formData.get('idE') , descripcion: formData.get('descripcion') , }

    setAgregarEditar(false)
    setLoading(true)

    axios.post(`${host}productos/productos/agregarUnidadesDeMedida`,HTTPHeaders,request).then(rt=>{
      if(Number(rt.data)>0){
        setModalAlert({txt:'El registro de la unidad de medida fue correcto',visible:true})
        actualizarCatalogo();
      }
      else if(Number(rt.data)===-2){
        setModalAlert({txt:'La unidad de medida se editó correctamente',visible:true})
        actualizarCatalogo();
      }
      else{
        setModalAlert({txt:'Parece que ocurrió un error, por favor intentalo más tarde. [Código de error: SV-ER: #15-01]',visible:true})
      }
    })
  }

  const okNivel3 = () => {
    let {tipo} = modalNivel3
    if(tipo===1)//agregareditar
      submit(true)
    setModalNivel3({visible:false,title:'',txt:'',tipo:-1})
  }

  return(
    <div className="cont-sucursales" >
      <div className="cont-sucursales2" >
        <div className="cont-sucursales3">


          <Loading loading={loading} />

          <Modal centered title={editanding ? "Editar unidad de medida" : "Agregar unidad de medida"} maskClosable={false} keyboard = {false} 
            okText="Guardar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
            cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
            visible={agregarEditar} closable={false}
            className="miAntModal alertMy modalUnidad"
            zIndex={10}
            onCancel={()=>setAgregarEditar(false) } onOk={()=>submit(false)} >
            <form id="formulaUnidad" onSubmit={e => e.preventDefault() }>
              {
                  agregarEditar &&
                  <div className="miUniDiv">
                      
                      <div className="inputs-group">
                          <label className="inputs-label" htmlFor="descripciond" style={{top:'-10px'}} > Nombre de la medida </label>
                          <input type="text" autoFocus maxLength={30} className="inputs-input" name="descripcion" defaultValue={nuevaUnidad.descripcion} id="descripciond"/>
                      </div>

                      <input type="hidden" name="id"  value={nuevaUnidad.id}  />
                      <input type="hidden" name="idE" value={nuevaUnidad.idE} />
                      <input type="hidden" name="editable" value={nuevaUnidad.editable} />

                  </div>
              }
            </form>
          </Modal>

          <Alert visible={modalAlert['visible']} onOk={() => setModalAlert({visible:false,txt:''})} text={modalAlert['txt']} />

          <Alert title="¿Eliminar unidad de medida?" visible={modalConfirm['visible']} text={modalConfirm['txt']} withCancel onOk={acceptConfirm} onCancel={() => setModalConfirm({visible:false,tipo:-1,txt:'',id:-1})} />

          <Alert title={modalNivel3['title']} visible={modalNivel3['visible']} text={modalNivel3['txt']}
            onOk={okNivel3} withCancel onCancel={()=>setModalNivel3({visible:false,tipo:-1,txt:'',title:'',})}
            />


          <div className="metaControles">
            <h3 className="tituloPanel"> Unidades de medida </h3>
          </div>

          <div className="cont-instancias2">
            {
              tiposUnidad.map((tipo,indice)=>(
                <React.Fragment key={"tipo_"+tipo}>
                <label className="nombreCate" > <span className="nombreCateint"> {tipo} </span> </label>
                <div className="cont-Unidades">
                  {
                    catalogoDeUnidades.filter(unidad => Number(unidad.TIPO_UNIDAD)===(indice+1)).map((unidad,indice) => {
                      return(
                        <div className="miunidad" key={'unidad_'+unidad.ID} onClick={()=>showModaNew(unidad)} >
                            <span className="nombre" >{unidad.DESCRIPCION}</span>
                            {
                              Number(unidad.EDITABLE) !== 2 &&
                              <span className="delete">
                                <img src={img+'basurero_gris.png'} onClick={(e)=>showModalConfirm(e,unidad['ID'],1,unidad['DESCRIPCION'])} alt="..." title='Eliminar registro' />
                              </span>
                            }
                         </div>
                      )
                    })
                  }
                </div>
               </React.Fragment>
              ))
            }
          </div>

          {
            props.validCurrentUser(contextType,'unidades','bool',false,'CREAR') && 
            <div className="buttomFlotanding" title="Agregar nuevo registro" onClick={()=>showModaNew(-1)} > + </div>
          }

          <div className="cont-instancias5"></div>

        </div>
      </div>
    </div>
  )

}

export default withRouter(Lista);