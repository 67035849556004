import React from 'react';
/* Librerías */
import {TransitionGroup, CSSTransition} from "react-transition-group"; 
import {BrowserRouter,Route,Link} from "react-router-dom";

/* */
import {Modal } from 'antd';
/* Componentes */

/* COMPONENTE NOT LOGGED*/
import CardsN from './index/indice'
/**/

import Login  from './login/login';
import Activacion  from './activacion/activacion';

import RegistroEmpresa from './registro/empresa';
import RegistroBuBooker from './registro/bubooker';

import Stripe from './stripe/alta';
import StripeSuccess from './stripe/stripesuccess';

import Landing from './landing2/landing';
import Politicas from './privacidad/privacidad';

import axios from 'axios';
import {Host as host } from './app.json'

import Empresas from './home/empresa/router';
import { Globalrequest as request , isLogged } from './request.js';

/*CSS*/
import 'antd/dist/antd.css';

import './app.scss';
 
class App extends React.Component{
  constructor(props) {
    super(props);
    this.redirectTo = this.redirectTo.bind(this)
    this.cerraSesion = this.cerraSesion.bind(this)
    this.state = {
      actualpage:"",
      visibleMobile:false,
      mail:'',
    }
  }
  
  componentDidMount(){
    const data =  {
      mail: isLogged() ? localStorage.getItem('mail') : ''
    }
    axios.post(`${host}usuarios/usuarios/setBrowserNavigation`,data,request).then(rt => {
      if(rt.data['mobile'] === "Mobile"){

        this.setState({visibleMobile:true,mail:rt.data['mail']})
      }
    })
  }

  redirectTo(url){
    this.setState({actualpage:url})
    setTimeout(function(){ document.getElementById('auxTo').click() },1);
  }
  cerraSesion(a=false){
    let ciudadUsuario = localStorage.getItem('ciudadUsuario')
    let estadoUsuario = localStorage.getItem('estadoUsuario')
    let carrito = JSON.parse(localStorage.getItem('carrito')) || {}
    localStorage.clear()
    sessionStorage.clear()
    localStorage.setItem('ciudadUsuario',ciudadUsuario)
    localStorage.setItem('estadoUsuario',estadoUsuario)
    localStorage.setItem('carrito',JSON.stringify(carrito))
    if(!a)
      window.location.reload()
  }
  render(){
    return ( 
      <BrowserRouter>
        <Modal
          className="modalNotPhone"
          centered closable={false} footer={null} title={null}
          visible={this.state.visibleMobile}
        >
          <a href={`intent:/#Intent;scheme=bubookv;package=com.nibirusystem.android.bubook;S.codigo='${this.state.mail}';end`} >
            Abrir aplicación
          </a>
        </Modal>

        <Link id="auxTo" to={this.state.actualpage} />
          <Route 
            render={({ location }) => {
              const { pathname } = location;
              return (
                <TransitionGroup>
                  <CSSTransition  key={pathname} classNames="page" timeout={{ enter: 250, exit: 250,}} >
                  <Route
                    location={location}
                    render={() => (
                      <div className="page">
                        {
                          <React.Fragment>
                          
                          <Route exact path={['/','/empresas/:usuario','/config','/config/:any','/config/:any/:any']}>
                            <CardsN redirectTo={this.redirectTo} cerraSesion={this.cerraSesion} b={1} />
                          </Route>

                          <Route exact path="/auth/login"> <Login redirectTo={this.redirectTo} cerraSesion={this.cerraSesion} /> </Route>
                          
                          <Route exact path="/auth/registro/negocio"> <RegistroEmpresa redirectTo={this.redirectTo} /> </Route>
                          <Route exact path="/auth/registro/bubooker"> <RegistroBuBooker redirectTo={this.redirectTo} /> </Route>

                          <Route exact path="/activacion/:mail/:error" > <Activacion redirectTo={this.redirectTo} /> </Route>

                          <Route exact path="/legacy/politicadeprivacidad"> <Politicas redirectTo={this.redirectTo} /> </Route>

                          <Route exact path="/infoempresas" > <Landing redirectTo={this.redirectTo} /> </Route>
                          <Route exact path="/stripe/:var" > <Stripe /> </Route>
                          <Route exact path="/confirm/:var" > <StripeSuccess /> </Route>

                          <Route path="/home" > <Empresas redirectTo={this.redirectTo} /> </Route>
                          </React.Fragment>
                        }
                      </div>
                    )}
                  />
                </CSSTransition>
              </TransitionGroup>
            );
          }}
        />  
      </BrowserRouter>
    );
  }
}

export default App;