import React from 'react';
import { Modal} from 'antd';
import { ImgGral as img2,ImgPanelAdmin as ImgPanel} from './../app.json'; 

const NotFound = (props) => {  
  return(
    <Modal centered title="" maskClosable={false} keyboard = {false} 
      okText={props.okText?props.okText:'Aceptar'}
      okButtonProps={{ className:'btn btn1' , style: { width:'100%',marginLeft:'0px'} }}
      cancelButtonProps={{style: { display:'none'} }}
      visible={props.visible}
      closable={false}
      onOk={ props.onOk }
      className="modalBaseBuBooker ftrblack"
      width={400} >
      <div className="selectFind" style={{height:'420px',paddingBottom:'20px'}} >
      
        <div className="head flexcenter Fcenter" >
          <img src={`${img2}iconos/bubook_blanco.png`} alt="..." className="icon-alert"/>
        </div>

        <div className="body" style={{width:'400px'}} >
          <h4 style={{textAlign:'center',color:'#FF9700'}} > {props.title} </h4>
          <div style={{textAlign:'center'}} >
            <img src={ImgPanel+'404back.png'} alt="..."  width={250} />
          </div>

          <label style={{display:'block',textAlign:'center',marginTop:'10px'}}> {props.txtNotFound} </label>

          <div className="cascaderWithIcon inverted flexcenter Fstart"/>

        </div>

      </div>
    </Modal>
  )
};
export default(NotFound);