import React from 'react';
import axios from 'axios';
import {Globalrequest as request} from './../../../request.js'
import { Host as host, gmapsKey } from './../../../app.json';

import estadosJSON from './../../../estados.json'

import Loading from './../../../modalCargando/loading';
import Spinner from './../../../modalCargando/loadingSpinner';
import {Marka} from '../../../modalCargando/miDiv';

import Alert from './../../../modalCargando/modalAlert';
import {Filtrar} from "./../../../utilities/svg.js";

import GoogleMapReact from 'google-map-react';

/* Librerías */
import { Modal ,  AutoComplete ,Icon} from 'antd';
import { withRouter } from "react-router-dom";


class Registrar extends React.Component{
  constructor(props) {
    super(props);

    this.verifyName = this.verifyName.bind(this)
    
    this.apiLoadEnd = this.apiLoadEnd.bind(this)
    this.mapDragEnd = this.mapDragEnd.bind(this)
    this.findMe = this.findMe.bind(this)
    this.findMe2 = this.findMe2.bind(this)

    this.searchMunicipio = this.searchMunicipio.bind(this)
    this.changeMunicipio = this.changeMunicipio.bind(this)    
    this.submit = this.submit.bind(this)

    this.state={
      
      center: {  },
    	
      id:sessionStorage.getItem('id'),
      
      loading:false,
      modalAlert:false,
      txtAlert:false,
      modalRespuesta:false,
      txtRespuesta:'',
      
      marker:false,
      dataAux:[],
      dataAux2:[],

      listName:[],

      isnew:false,

      detalle:{
        nombre:'',
        lng:'',
        lat:'',
        cp:'',
        pais:'',
        estado:'',
        ciudad:'',
        municipio:'',
        colonia:'',
        calle:'',
        numE:'',
        numI:'',
        referencia:'',
        favorito:'',
        id:-1,
      }
    }
  }

  componentDidMount(){
    let {dataAux2,dataAux} = this.state
    
    /*let {makeRef} = this.props
    makeRef({mounted:true,component:this,name:'DireccionesB'})*/

    dataAux = []
    for(let estadoJSON in estadosJSON){
      if(estadoJSON.toUpperCase().includes('Guerrero'.toUpperCase())){
        dataAux = estadosJSON[estadoJSON]
      }
    }
    dataAux2 = dataAux
    this.setState({dataAux,dataAux2})
    this.verifyName()
  }

  verifyName(map, maps){
    let {id,detalle,listName,dataAux,dataAux2} = this.state
    let {match} = this.props
    let name = match['params']['name'].trim().toLowerCase()
    let data = {idC:id,name}
    this.setState({loading:true})

    axios.post(`${host}clientes/clientes/domNames/0`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let center = {}
        listName = rt.data['list'].map(name => name.trim().toLowerCase() )
        if(name!=='registrar'){
          if(rt.data['detalle'].length===0){//no hay registro con el nombre pasado como parametro
            this.props.redirectTo('/config/direcciones/registrar')
            return
          }
          else{
            let detalleAux = Object.keys(rt.data['detalle']).map((key) => rt.data['detalle'][key] );
            detalleAux = detalleAux[0]
            let pais = detalleAux['PAIS']
            let estado = detalleAux['ESTADO']
            detalle = {
              nombre:detalleAux['NOMBRE'],
              lng:Number(detalleAux['ALTITUD']),
              lat:Number(detalleAux['LATITUD']),
              cp:detalleAux['COD_POSTAL'],
              pais:detalleAux['PAIS'],
              estado:detalleAux['ESTADO'],
              ciudad:detalleAux['CIUDAD'],
              municipio:detalleAux['MUNICIPIO'],
              colonia:detalleAux['COLONIA'],
              calle:detalleAux['CALLE'],
              numE:detalleAux['NUMERO_EXTERIOR'],
              numI:detalleAux['NUMERO_INTERIOR'],
              referencia:detalleAux['REFERENCIA'],
              favorito:detalleAux['FAVORITO'],
              id:detalleAux['ID'],
            }
            center['lng'] = Number(detalleAux['ALTITUD'])
            center['lat'] = Number(detalleAux['LATITUD'])
            if( pais.trim().toLowerCase() === "mexico" || pais.trim().toLowerCase() === "méxico" )pais = "México"
            if(pais === "México"){
              dataAux = []
              for(let estadoJSON in estadosJSON){
                if(estadoJSON.toUpperCase().includes(estado.toUpperCase())){
                  dataAux = estadosJSON[estadoJSON]
                }
              }
              dataAux2 = dataAux
            }
            this.setState({listName,detalle,center,loading:false,marker:true,dataAux,dataAux2})
          }
        }else{
          center = {lat: 16.859166 , lng: -99.872328,}
          this.setState({listName,center,loading:false,isnew:true})
        }
      }
    })
    
  }

  apiLoadEnd(map, maps){
    map.setOptions({
      zoomControl: true,
      fullscreenControl: true,
      mapTypeControl: true,
      zoomControlOptions: { style: maps.ZoomControlStyle.SMALL },
      fullscreenControlOption:{position:maps.ControlPosition.RIGHT_TOP},
      mapTypeControlOptions: { style: maps.MapTypeControlStyle.DROPDOWN_MENU, },
    })
    let controlUI = document.createElement('div');
    controlUI.className="findme"
    controlUI.title="Ir a mi ubicación actual"
    let centerControlDiv = document.createElement('div');
    centerControlDiv.appendChild(controlUI);
    controlUI.addEventListener('click', this.findMe ) ;
    centerControlDiv.index = 1;
    map.controls[maps.ControlPosition.RIGHT_CENTER].push(centerControlDiv);
    this.setState({map:map})
  }

  mapDragEnd(map){
    let c = map.getCenter()
    let {center,dataAux,dataAux2} = this.state
    center['lat'] = c.lat()
    center['lng'] = c.lng()
    dataAux = []
    dataAux2 = []
    
    this.setState({center,dataAux ,dataAux2});

    document.getElementById('paisd').value = ''
    document.getElementById('estadod').value = ''
    document.getElementById('ciudadd').value = ''
    document.getElementById('municipiod').value = ''
    document.getElementById('coloniad').value = ''
    document.getElementById('cpd').value = ''

    document.getElementById('called').value = ''
    document.getElementById('referenciad').value = ''
    document.getElementById('numEd').value = ''
    document.getElementById('numId').value = ''

  }

  findMe(){
    let location = window.navigator && window.navigator.geolocation
    if (location) {
      location.getCurrentPosition(
        (position) => {
          let {center} = this.state
          center['lat'] = position.coords.latitude
          center['lng'] = position.coords.longitude
          this.setState({center})
        },
        (error) => {
          console.log(error)
          this.setState({txtAlert:"No fue posible obtener tu ubicación actual de manera automática. Por favor, intenta seleccionar manualmente la ubicación de tu sucursal.",modalAlert:true})
        },
        {
          enableHighAccuracy: false,
          timeout: 10 * 1000,
          maximumAge: 0
        }
      )
    }
  }

  findMe2(){
    let {map,marker,dataAux, dataAux2} = this.state
    let data = { lat : map.getCenter().lat() , lng : map.getCenter().lng() }
    if(!marker)
    axios.post(`${host}empresas/empresas/getLocation`,data,request).then(rt=>{
      if(rt.data['status']==='OK'){
        let Numcolonia = ''
        let Nomcolonia = ''
        let political = ''
        let neighborhood = ''
        let pais = ''
        let estado = ''
        let results = rt.data['results'][0]['address_components']
        for(let pos in results ){
          let type = results[pos]['types'][0]
          let value = results[pos]['long_name']
          //console.log(type,value)
          if(type==='postal_code'){ this.ponValores('cpd',value) }
          if(type==='country'){pais = value  }
          if(type==='administrative_area_level_1'){estado = value }
          if(type==='locality'){ this.ponValores('ciudadd',value) }
          if(type==='street_number'){ Numcolonia = value }
          if(type==='route'){ Nomcolonia = value }
          if(type==='neighborhood'){ neighborhood = value }
          if(type==='political'){ political = value }
        }
        this.ponValores('called',Nomcolonia+' núm. '+Numcolonia)
        this.ponValores('coloniad',neighborhood !== '' ? neighborhood : political)
        if( pais.trim().toLowerCase() === "mexico" || pais.trim().toLowerCase() === "méxico" )pais = "México"
       if(pais === "México"){
          dataAux = []
          for(let estadoJSON in estadosJSON){
            if(estadoJSON.toUpperCase().includes(estado.toUpperCase())){
              dataAux = estadosJSON[estadoJSON]
            }
          }
          dataAux2 = dataAux
          this.setState({dataAux,dataAux2})
        }
        this.setState({marker:!marker})
        this.ponValores('paisd',pais)
        this.ponValores('estadod',estado)
      }
    })
    this.setState({marker:!marker})
  }

  ponValores(id,valor){
    if(document.getElementById(id)!==null && document.getElementById(id)!==undefined){
      document.getElementById(id).value = valor
    }
  }

  searchMunicipio(value){
    value = value.toLowerCase().trim()
    let {dataAux,dataAux2} = this.state
    dataAux = dataAux2.filter(m => m.toLowerCase().trim().includes(value) )
    this.setState({dataAux})
  }

  changeMunicipio(value){
    let {detalle} = this.state
    detalle['municipio'] = value.trim()
    this.setState({detalle})
  }

  submit(){
    let form = new FormData(document.getElementById('formulaDire'))
    let {center,id,detalle,isnew,listName} = this.state
    let data = detalle
    data['idC'] = id
    data['lng'] = center['lng']
    data['lat'] = center['lat']
    data['favorito'] = isnew ? 2 : detalle['favorito']
    
    let campos = { nombre :"'Nombre de la dirección'", pais :"'País'", estado :"'Estado'", ciudad :"'Ciudad'", municipio :"'Municipio'", colonia :"'Colonia'", cp :"'Código postal'", calle :"'Calle'", referencia :"'Referencias'", numE :"'Núm. Exterior'", }
    for(let [key,value] of form.entries()){
      value = value.trim()
      data[key] = value
      if(value === '' && key!=='numI' ){
        this.setState({txtAlert:'El campo '+ campos[key] + ' es obligatorio.'  ,modalAlert:true})
        return
      }
    }
    if(isnew && listName.includes(form.get('nombre').trim().toLowerCase()) ){
      this.setState({txtAlert:'Ya cuentas con una dirección con nombre '+form.get('nombre'),modalAlert:true})
      return
    }
    this.setState({loading:true})
    axios.post(`${host}clientes/clientes/updateAddAdress`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let rta = Number(rt.data['msg'])
        let msj = ''
        if(rta===1||rta===2){
          msj = "Los datos han sigo guardados correctamente."
        }else{
          msj="Parece que ocurrió un error, por favor intentalo más tarde."
        }
        this.setState({loading:false,txtRespuesta:msj,modalRespuesta:true,})
      }else{
        this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde.',modalAlert:true,})
      }
    })
  }


  render(){
    return(
  		<div className="containerBrowserBubookerChildTwo">
      <div className="containerBrowserBubookerChildThree">
        
        <Loading loading={this.state.loading} />
        <Spinner loading={this.state.modalSpinner} text={this.state.txtSpinner}/>
        <Alert text={this.state.txtAlert} visible={this.state.modalAlert} onOk={()=>this.setState({modalAlert:false,txtAlert:''})} />

        <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
          okText="Mis direcciones" okButtonProps={{ className:"btn btn1" ,style:{width:'180px'} }}
          cancelText="Nuevo registro" cancelButtonProps={{ className:"btn btn2" ,style:{width:'170px'} }}
          visible={this.state.modalRespuesta}
          closable={false}
          onOk={()=>this.props.redirectTo('/config/direcciones')}
          onCancel={()=> window.location.reload() }
          className="miAntModal newVar alertMy"
          >
          <div className="myAlertBody">
            {
              this.state.modalRespuesta   ?
              <span className="txtAlert"> {this.state.txtRespuesta} </span>:
              <React.Fragment/>
            }
          </div>
        </Modal>

        <div style={{marginTop:'20px'}} className="itemBackBu flexcenter Fstart" onClick={()=>this.props.redirectTo('/config/direcciones')} >
          <Icon type="left" />
          <span> Regresar </span>
        </div>
        
        <div className="titleSectionBubooker flexcenter Fstart">
          Añadir dirección
          <i><Filtrar/></i>
        </div>

        <div className="newItemAdress flexcenter Fstart">
          
          <div className="divMap">
            <GoogleMapReact
              bootstrapURLKeys={{key: gmapsKey}}
              ref={ ref => {this.mimapa = ref} }
              draggable={true}
              center={this.state.center}
              defaultZoom={15}
              onGoogleApiLoaded={ ({map, maps}) => this.apiLoadEnd(map,maps) }
              onDragEnd = {this.mapDragEnd}
              yesIWantToUseGoogleMapApiInternals={true}
              onZoomAnimationEnd = {this.zoomMap}
              > 
            </GoogleMapReact>
            <Marka/>
            <div className="findme2" id="findme2" title="Marcar ubicación" onClick={this.findMe2} >
              <span> {!this.state.marker? 'Marcar ubicación' : 'Editar ubicación' } </span>
            </div>
            {this.state.marker&&<div className="masker"></div>}
          </div>
          
          <div className="divInputs flextop Fspaceb ">
            <form id="formulaDire">
              
            <div className="inputGruop">
              <label className="labelsB" > Nombre de la dirección </label>
              <input className="ant-input inputsB" id="nombred" defaultValue={this.state.detalle['nombre']} name="nombre" />
            </div>

            <div className="inputGruoper flexcenter Fspaceb ">

              <div className="inputGruop">
                <label className="labelsB" > País </label>
                <input className=" ant-input inputsB" id="paisd" defaultValue={this.state.detalle['pais']} name="pais" />
              </div>

              <div className="inputGruop">
                <label className="labelsB" > Estado</label>
                <input className=" ant-input inputsB" id="estadod" defaultValue={this.state.detalle['estado']} name="estado" />
              </div>

            </div>

            <div className="inputGruoper flexcenter Fspaceb ">

              <div className="inputGruop">
                <label className="labelsB" > Ciudad </label>
                <input className=" ant-input inputsB" id="ciudadd" defaultValue={this.state.detalle['ciudad']} name="ciudad"/>
              </div>

              <div className="inputGruop">
                <label className="labelsB" > Municipio </label>
                <AutoComplete dataSource={this.state.dataAux} id="municipiodd" onSearch={this.searchMunicipio}
                  value={this.state.detalle['municipio']} onChange={this.changeMunicipio}
                  children={ <input className="ant-input inputsB" id="municipiod" autoComplete={'off'} name="municipio" /> } />
              </div>

            </div>

            <div className="inputGruoper flexcenter Fspaceb ">

              <div className="inputGruop">
                <label className="labelsB" > Colonia </label>
                <input className="ant-input inputsB" id="coloniad" defaultValue={this.state.detalle['colonia']} name="colonia" />
              </div>

              <div className="inputGruop">
                <label className="labelsB" > Código postal </label>
                <input className="ant-input inputsB" id="cpd" defaultValue={this.state.detalle['cp']} name="cp" type="number" />
              </div>

            </div>

            <div className="inputGruop">
              <label className="labelsB" > Calle </label>
              <input className="ant-input inputsB" id="called" defaultValue={this.state.detalle['calle']} name="calle" />
            </div>

            <div className="inputGruop">
              <label className="labelsB" > Referencias </label>
              <input className="ant-input inputsB" id="referenciad" defaultValue={this.state.detalle['referencia']} name="referencia" />
            </div>

            <div className="inputGruoper flexcenter Fspaceb ">

              <div className="inputGruop">
                <label className="labelsB" > Núm. Exterior </label>
                <input className="ant-input inputsB" id="numEd" defaultValue={this.state.detalle['numE']} name="numE" />
              </div>

              <div className="inputGruop">
                <label className="labelsB" > Núm. Interior <span>(Opcional)</span> </label>
                <input className="ant-input inputsB" id="numId" defaultValue={this.state.detalle['numI']} name="numI" />
              </div>

            </div>

            </form>

            <div className="inputGruop">
              <button  onClick={this.submit} className="addAddressButton"> Terminar registro </button>
            </div>

          </div>

        </div>

      </div>
  		</div>
    );
  }
}

export default withRouter(Registrar);