import React from 'react';
import axios from 'axios'
import { withRouter ,BrowserRouter,Route,Link,Switch as Switcher ,HashRouter} from "react-router-dom";
import NumberFormat from 'react-number-format';
import { Globalrequest as request } from './../../../request.js';
import {Host as host ,  Img as img, ImgPanelAdmin as ImgPanel} from './../../../app.json'
import { Modal , Select, Icon, Switch ,TimePicker,Input,TreeSelect} from 'antd';

import ModalMembresia from './membresia'
import MetodosDePago from './metodosDePago'

import {userContext} from './../context'

import moment from 'moment'

import Loading from '../../../modalCargando/loading';
import ModalAlert from '../../../modalCargando/modalAlert';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css'

import './config.scss';


class ConfigModal extends React.Component{
  constructor(props){
    super(props);
    this.state={
      txt:'',
      txt2:'',
      icon:'',
      result:'',
      clase:'',
    }
  }

  componentWillMount(){
    let {match} = this.props
    let {txt,txt2,icon,result,clase} = this.state
    result = match.params.var
    if(result.toLowerCase().trim() === 'done' ){
      clase = 'little'
      txt='!Verificando informacion!'
      txt2='Stripe se encuentra validando tu información. Este proceso toma aproximadamente 4 días hábiles. Una vez que la validación haya sido completada, estarás listo para recibir pagos en línea. Te notificaremos el resultado a través de BuBook y por correo electrónico. Si deseas conocer más al respecto, no dudes en contactar a soporte.'
      icon=img+'tickCalendar.svg'
    }
    else if(result.toLowerCase().trim() === 'connected' ){
      txt='¡Listo!'
      txt2='Ya puedes recibir pagos a traves de Stripe'
      icon=img+'tick.svg'
    }
    else if(result.toLowerCase().trim() === 'failed' ){
      txt='Error'
      txt2='Algo salió mal. Por favor, contacte a soporte.'
      icon=img+'notick.svg'
    }
    this.setState({txt,icon,txt2,result,clase})
  }
  
  componentDidMount(){

    let idNotificacionPorAtender = sessionStorage.getItem('idNotificacionPorAtender')
    if(idNotificacionPorAtender !== 'null'){
      let data = {idN:idNotificacionPorAtender,estatus:2}
      axios.post(`${host}clientes/clientes/cambiarEstatusNotificacion`,data,request)
      sessionStorage.setItem('idNotificacionPorAtender','null')
    }

    let data = {idE:sessionStorage.getItem('id')}
    axios.post(`${host}empresas/empresas/datosEmpresaStripe`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let {txt,txt2,icon,result,clase} = this.state
        let rta = Number(rt.data['result'])
        if(rta===2){
          clase = 'little'
          txt='!Verificando informacion!'
          txt2='Stripe se encuentra validando tu información. Este proceso toma aproximadamente 4 días hábiles. Una vez que la validación haya sido completada, estarás listo para recibir pagos en línea. Te notificaremos el resultado a través de BuBook y por correo electrónico. Si deseas conocer más al respecto, no dudes en contactar a soporte.'
          icon=img+'tickCalendar.svg'
        }
        else if(rta===1){
          txt='¡Listo!'
          txt2='Ya puedes recibir pagos a traves de Stripe'
          icon=img+'tick.svg'
        }
        else{
          txt='Error'
          txt2='Algo salió mal. Por favor, contacte a soporte.'
          icon=img+'notick.svg'
        }
        this.setState({txt,icon,txt2,result,clase})
      }
      else{
        return
      }
    })
  }

  close(){
    document.getElementById('closeModal').click()
  }

  render(){
    return(
      <Modal visible={true} wrapClassName="modalBaseStripe" className="modalBaseStripeContent"
        closable={false} keyboard={false} maskClosable={false} header={null} footer={null}>
      <div className={'contStripeChild flexcenter Fspaceb '+this.state.clase}>
        
        <div className="phantom"> a </div>

        <div className="content">
          <h1> {this.state.txt} </h1>
          <div>
            <img alt="..." src={this.state.icon} />
          </div>
          <h3> {this.state.txt2} </h3>
        </div>
        
        <div className="fakebutton flexcenter Fcenter" onClick={this.close} > Volver a Configuración </div>

      </div>
      </Modal>
    )
  }
}


class Config extends React.Component{

  static contextType = userContext

  constructor(props) {
    super(props);
    
    this.actualizarSucursales = this.actualizarSucursales.bind(this)
    
    this.showModalDatosAcceso = this.showModalDatosAcceso.bind(this)
    this.saveDatosUsuario = this.saveDatosUsuario.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.submit1 = this.submit1.bind(this)
    this.submit2 = this.submit2.bind(this)
    this.submit3 = this.submit3.bind(this)
    //this.submit = this.submit.bind(this)
    this.getInfoVaria = this.getInfoVaria.bind(this)
    /**/

    this.changeFile = this.changeFile.bind(this)
    this.changeFile2 = this.changeFile2.bind(this)
    this.getImage = this.getImage.bind(this)
    this.cancelImage = this.cancelImage.bind(this)
    /* */
    this.addTag = this.addTag.bind(this)
    this.removeTag = this.removeTag.bind(this)
    this.cambioTipoRegimen = this.cambioTipoRegimen.bind(this)
    this.cambioRegimen = this.cambioRegimen.bind(this)
    this.cambioCategoria = this.cambioCategoria.bind(this)
    this.showPassword=this.showPassword.bind(this)

    this.changeSwitch = this.changeSwitch.bind(this)
    this.changeNotiEstatus = this.changeNotiEstatus.bind(this)
    /* */
    this.clickCancelar = this.clickCancelar.bind(this)
    this.changeIdSucursalId = this.changeIdSucursalId.bind(this)
    this.changeSucursalIdConfirm = this.changeSucursalIdConfirm.bind(this)    
    this.actualizarConfiguraciones = this.actualizarConfiguraciones.bind(this)
    this.showModalNewConfiguracion = this.showModalNewConfiguracion.bind(this)
    this.closeModalNewConfiguracion = this.closeModalNewConfiguracion.bind(this)
    this.changeSwitch2 = this.changeSwitch2.bind(this)
    this.controlSemanal = this.controlSemanal.bind(this)
    this.horaChange = this.horaChange.bind(this)
    this.changeChecked = this.changeChecked.bind(this)
    this.addConfig = this.addConfig.bind(this)
    this.modalEliminarSucursal = this.modalEliminarSucursal.bind(this)
    this.elimiarSucursalDeConfigCitas = this.elimiarSucursalDeConfigCitas.bind(this)
    this.eliminarAllConfig = this.eliminarAllConfig.bind(this)
    /**/
    
    this.showNewHijo = this.showNewHijo.bind(this)
    this.appendHorarioHijo = this.appendHorarioHijo.bind(this)
    this.removeHijo = this.removeHijo.bind(this)
    this.setAllAsLunes = this.setAllAsLunes.bind(this)

    this.eliminarCuenta = this.eliminarCuenta.bind(this)
    this.eliinacionConfirmadaCuenta = this.eliinacionConfirmadaCuenta.bind(this)

    this.habilitarCuenta = this.habilitarCuenta.bind(this)
    this.cerrarSesion = this.cerrarSesion.bind(this)

    this.addDescripcion = this.addDescripcion.bind(this)
    this.submitContrasena = this.submitContrasena.bind(this)

    this.atLeatsOne = this.atLeatsOne.bind(this)

    this.state = {
      estatusEmpresa:0,
      /**/
      folder : sessionStorage.getItem('folder'),
      /**/
      sucursales:this.props.sucursales,
      tags2:this.props.tags,
      cats2:this.props.categorias,
      notificaciones:this.props.notificaciones,
      notiPush:{},
      notiMsj:{},
      notiMail:{},
      datos:{DESCRIPCION:'',},
      datospre:{},
      /* */
      loading:true,
      loading2:true,
      categorias:[],
      regimenes:[
          [],[],[],[],[]
      ],
      /* */
      regimenActual:4,
      valueRegimen:37,
      categoriaActual:[],
      tags:[],
      /* */
      entrega:2,
      costo:false,
      gratis:true,
      minimo:'',
      /**/
      modal1:false,
      modal2:false,
      modal3:false,
      modal4:false,
      pass:true,
      /* banderas */
      abri1:false,
      destroyable1:'',
      destroyable2:'',
      destroyable3:'',
      /* Data img empresa */
      dataPortada:'0',
      dataPerfil:'0',
      /**/
      modalAlert:false,
      txtAlert:'',
      /**/
      cancelanding:false,
      modalConfirmChangeSucursal:false,
      idSucursalActual:-1,
      newIdSucursalActual:-1,
      modalDetalleCita:false,
      modalListaConfiguraciones:false,
      vectorCitas:[],
      citas:{
        HORARIOS:[
          {},
          {},
          {},
          {},
          {},
          {},
          {},
        ]
      },
      citas2:{
        HORARIOS:[
          {},
          {},
          {},
          {},
          {},
          {},
          {},
        ]
      },
      deshabilitado:false,
      semaforo:1,
      idEditando:-1,
      modalEliminarSucursal:false,
      idEliminar:-1,
      modalConfirmDisable:false,
      /**/
      modalHorarioHijo:false,
      indexCurrPadre:-1,
      indexCurrHijo:-1,
      empiezaAux:'00:00',
      terminaAux:'00:00',
      modalEliminarHijo:false,
      modalCopyAll:false,
      /**/
      modalConfirmEliminarCuenta:false,
      alertConfirmEliminarCuentayCitas:false,
      opExec:-1,
      modalCuentaEliminada:false,
      modalConfirmInhabilitarCuenta:false,
      modalCuentaInhabilitada:false,
      /**/
      nuevaDescripcion:'',
      /**/
      modalMembresia:false,
      /**/
      treeData:[],
      /**/
      modalDatosUsuario:false,
      datosU:{},
      tipoU:-1,
    }
  }

  componentDidMount(){
    document.title = 'Mis datos'
    this.setState({loading:true})
    let idE = sessionStorage.getItem('id')
    if(idE===null||idE===undefined)return
    this.props.setMensaje(0)
    axios.post(`${host}empresas/empresas/listaCategoriasRegimenes`,0,request).then(rt=>{
      let {treeData,categorias,regimenes} = this.state

      regimenes[0] = []
      regimenes[1] = rt.data['1']
      regimenes[2] = rt.data['2']
      regimenes[3] = rt.data['3']
      regimenes[4] = rt.data['4']
      categorias = rt.data['CATEGORIAS']
      treeData = []
      let id=0,children=[],checkable=true
      for(let categoria of categorias){
        id = Number(categoria['ID'])
        children = categoria['HIJOS'].map( sub => ( {title:sub['DESCRIPCION'],value:Number(sub['ID']),key:Number(sub['ID'])} ) )
        checkable=true
        if(children.length>0){
          checkable = false
          children.unshift({title:categoria['DESCRIPCION'],value:id,key:id,})
        }
        treeData.push({title:categoria['DESCRIPCION'],value:checkable?id:id+'_',key:id+'_',children:children,checkable:checkable,})
      }
      this.setState({ categorias, regimenes, treeData  })
    })
    this.actualizarSucursales()
  }

  actualizarSucursales(){
    //let metadata = JSON.parse(sessionStorage.getItem('metadata'))
    let data = {mail:sessionStorage.getItem('mail'),op:-1,detalle:1}
    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/datosPorEmpresa`,data,request).then(rt=>{
      if( rt.data === 0 ){ this.props.redirectTo('/home/sucursales/nueva')  }
      else{
        let {datos,nuevaDescripcion,estatusEmpresa,destroyable1,destroyable2,destroyable3,notiPush,notiMail,notiMsj,vectorCitas,sucursales} = this.state

        estatusEmpresa = Number(rt.data['datos']['ESTATUS'])

        sucursales = rt.data['sucursales']
        vectorCitas = this.makeVectorCitas(rt.data['controlCitas'],sucursales)

        nuevaDescripcion = rt.data['datos']['DESCRIPCION'].split('[/*/r/n*/]').join('\r\n')

        rt.data['datos']['DESCRIPCION'] = nuevaDescripcion
        datos = rt.data['datos']

        datos['RFC'] = atob(datos['RFC'])
        datos['CONTRASENA'] = atob(datos['CONTRASENA'])
        //datos['CONTRASENA'] = atob(datos['CONTRASENA'])

        rt.data['datos']['LABEL'] = JSON.parse(sessionStorage.getItem('metadata'))['datos']['LABEL']  //rt.data['datos']['CORREO_REGISTRO']
        rt.data['datos']['ALIAS'] = JSON.parse(sessionStorage.getItem('metadata'))['datos']['ALIAS']

        sessionStorage.setItem('metadata',JSON.stringify(rt.data))
        sessionStorage.setItem('src',rt.data['datos']['DIRECCION_IMG_PERFIL'])

        datos['TELEFONO_CONTACTO'] = atob(datos['TELEFONO_CONTACTO'])
        datos['CORREO_REGISTRO'] = atob(datos['CORREO_REGISTRO'])

        this.props.setSucursales(rt.data['sucursales'])
        this.props.editState('datos',rt.data['datos'])
        this.props.editState('sucursales',rt.data['sucursales'])
        this.props.editState('categorias',rt.data['categorias'])

        let tags = [] , cats = []
        for(let tag of rt.data['tags']){ tags.push(tag.NOMBRE)}
        
        for(let cat of rt.data['categorias']){
          cats.push( {label:cat['CATEGORIA'],value:cat['ID_CATEGORIA_EMPRESA']} )
        }

        notiPush = rt.data['notificaciones'].filter(n => Number(n.NOTIFICACION) === 1)
        notiMail = rt.data['notificaciones'].filter(n => Number(n.NOTIFICACION) === 2)
        notiMsj = rt.data['notificaciones'].filter(n => Number(n.NOTIFICACION) === 3)

        notiPush = notiPush.length > 0 ? notiPush[0] : { ESTATUS:2,ID:-1 }
        notiMsj = notiMsj.length > 0 ? notiMsj[0] : { ESTATUS:2,ID:-1 }
        notiMail = notiMail.length > 0 ? notiMail[0] : { ESTATUS:2,ID:-1 }

        destroyable1 = new FormData(document.getElementById('formularegistroempresa1'))
        destroyable2 = new FormData(document.getElementById('formularegistroempresa2'))
        destroyable3 = new FormData(document.getElementById('formularegistroempresa3'))

        this.setState({
          nuevaDescripcion,
          sucursales,
          vectorCitas,
          categoriaActual:cats,
          cats2:cats.slice(),
          tags:tags,
          tags2:tags.slice(),

          notificaciones:rt.data['notificaciones'],          
          datos,
          datospre:datos,
          regimenActual : Number(datos['TIPO_REGIMEN']),
          valueRegimen: Number(datos['ID_REGIMEN_FISCAL']),          
          dataPortada:'0',
          dataPerfil:'0',
          notiPush,
          notiMsj,
          notiMail,
          estatusEmpresa,
          destroyable1,destroyable2,destroyable3,
          loading:false,
        })        

        let preopen = sessionStorage.getItem('preopen')
        if(preopen==='ok'){
          document.getElementById('imp').click()
        }
        
      }
    })
  }

  actualizarConfiguraciones(){
    let data = {idE:sessionStorage.getItem('id')}
    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/getControlCitas`,data,request).then(rt=>{
      let {vectorCitas,sucursales} = this.state
      vectorCitas = this.makeVectorCitas(rt.data,sucursales)
      this.setState({vectorCitas,loading:false})
    })
  }

  makeVectorCitas(vectorBDD,sucursales){
    let vectorCitas = [], horarios = []
    for(let sucursal of sucursales)
      sucursal['conCitas'] = false
    for(let config of vectorBDD){
      config['dCitas'] = moment.duration(config['DURACION_CITA']).asMinutes()
      config['iCitas'] = moment.duration(config['INTERVALO_ENTRE_CITAS']).asMinutes()
      horarios = []
      for(let horario of config['HORARIOS']){
        if(Number(horario['TIPO_HORA']) === 2){
          horario['inicio'] = moment(horario['HORA_INICIO'],'HH:mm:ss')
          horario['labelInicio'] = horario['inicio'].format('HH:mm')
          horario['HORA_INICIO'] = horario['inicio'].format('HH:mm')
          
          horario['fin'] = moment(horario['HORA_TERMINO'],'HH:mm:ss')
          horario['labelFin'] = horario['fin'].format('HH:mm')
          horario['HORA_TERMINO'] = horario['fin'].format('HH:mm')
          continue;
        }
        horario['MOMENT_INICIO'] = moment(horario['HORA_INICIO'],'HH:mm:ss')
        horario['MOMENT_TERMINO'] = moment(horario['HORA_TERMINO'],'HH:mm:ss')
        
        horario['ESTATUS'] = Number(horario['ESTATUS'])
        horario['HIJOS'] = config['HORARIOS'].filter(ch => Number(ch['TIPO_HORA'])===2 && Number(ch['DIA']) === Number(horario['DIA']) )
        horarios.push(horario)
      }
      config['HORARIOS'] = horarios
      for(let sucursal of sucursales){
        if( sucursal['CODE'].trim().toLowerCase() === config['CODE'].trim().toLowerCase()){
          config['ID_SUCURSAL'] = sucursal['ID']
          sucursal['conCitas'] = true
        }
      }
      vectorCitas.push(config)
    }
    return vectorCitas
  }

  addTag(event,b=0,b2=''){
    if( ( (event !== null ) && (event.key==='Enter') ) || b !== 0){
        let tag = b === 0 ? document.getElementById('tag').value.trim() : b2
        if(tag!==''){
           let {tags} = this.state
           let tagss = this.state.tags.filter(x=>x.toLowerCase() === tag.toLowerCase())
            if(tagss.length===0){
                tag = tag.split(' ').filter(t => t !== ' ')
                tag = tag.join(' ').trim()
                //tag = tag.charAt(0).toUpperCase() + tag.slice(1).toLowerCase()
                tags.push(tag)
            }
            document.getElementById('tag').value="";
            this.setState({tags:tags})
        }
    }
  }

  removeTag(taga,indice){
      let tags = this.state.tags.filter( x => taga!==x )
      this.setState({tags:tags})
  }

  showPassword(){
      let {pass} = this.state
      this.setState({pass:!pass})
  }

  showError(msj,i){
    document.getElementById('spanerror'+i).innerHTML=msj
    document.getElementById('spanerror'+i).style.display="block"
  }

  cambioTipoRegimen(value){
    this.setState({
      regimenActual:value,
      valueRegimen: Number(value) === 4 ? '37' : '' ,
    })
    document.getElementById('rfcd').value=""
  }

  cambioRegimen(value){
      this.setState({valueRegimen: value })
  }

  cambioCategoria(value){
      if( value.length > 3 ) value = value.slice(0,3)
      this.setState({categoriaActual:value})
  }

  showModalDatosAcceso(){
    if(this.context['type']===2){
      let data = {id:sessionStorage.getItem('subid'),withps:1}
      this.setState({loading:true})
      axios.post(`${host}subusuarios/subusuarios/detalleUsuario`,data,request).then(rt=>{
        if(rt.data['status']==='ok'){

          if(rt.data['data']==='none'){
            this.setState({loading:false,txtAlert:'Parece que el usuario que seleccionaste ya no se encuentra registrado.',modalAlert:true})
            return
          }
          let datosU = {
            nombre:rt.data['data']['NOMBRE'], apellido:rt.data['data']['APELLIDO'],
            cargo:rt.data['data']['CARGO'], telefono:rt.data['data']['TELEFONO'],
            correo:rt.data['data']['CORREO_REGISTRO'], id:rt.data['data']['ID'],
            contra:rt.data['data']['CONTRASENA'],estatus:rt.data['data']['ESTATUS']
          }

          this.setState({modalDatosUsuario:true,datosU,loading:false,})

        }
        else
          this.setState({loading:false,txtAlert:'Parece que el ocurrió un error al consultar la información, por favor intentelo más tarde.',modalAlert:true})
      })
    }
    else
      this.setState({modal3:true})
  }

  saveDatosUsuario(){
    let {datosU} = this.state
    let form = new FormData(document.getElementById('formulaUser'))
    
    for(let campo of form.entries()){
      datosU[campo[0]] = campo[1]

      if(campo[0]!=='contra' && campo[0]!=="contra2")
        campo[1] = campo[1].trim()

      if(campo[1]===''){
        this.setState({modalAlert:true,txtAlert:'Debe completar los datos del formulario'})
        return
      }
    }

    datosU['telefono'] = datosU['telefono'].split('(').join('').split(')').join('').split(' ').join('')
    if(datosU['telefono'].length<10){
      this.setState({txtAlert:'Ingrese un número de teléfono válido.',modalAlert:true})
      return
    }

    if(datosU['contra'].length < 6){
      this.setState({txtAlert:'La contraseña debe tener al menos 6 caracteres.',modalAlert:true})
      return
    }

    if(datosU['contra']!==datosU['contra2']){
      this.setState({modalAlert:true,txtAlert:'Las contraseñas ingresadas no coinciden'})
      return
    }

    datosU['idEmpresa'] = sessionStorage.getItem('id')
    datosU['complete'] = 1
    datosU['sucursales'] = []
    datosU['withps'] = 1
    
    this.setState({loading:true})
    axios.post(`${host}subusuarios/subusuarios/altaUsuario`,datosU,request).then(rt=>{
      if(rt.data['status']==='ok'){
        this.setState({loading:false,modalDatosUsuario:false,tipoU:-1,datosU:{},})
      }else{
        this.setState({modalAlert:true,txtAlert:'Parece que ocurrió un error. Por favor intentelo má tarde,',loading:false})
      }
    })

  }

  showModalContrasena(tipoU){
    this.setState({modalContrasena:true,tipoU})
  }

  closeModal(modal,bool,form=-1){
    this.setState({[modal]:bool,})
    if(form!==-1){
      document.getElementById(form).reset()
    }
    if(form==='formularegistroempresa1'){
      let {datospre,tags2,cats2} = this.state
     
      let tags = tags2.slice() , cats = cats2.slice()

      /*for(let tag of tags2){ tags.push(tag.NOMBRE)}
      for(let cat of cats2){ cats.push(cat.ID_CATEGORIA_EMPRESA)}*/

      this.setState({
        rows:this.state.oldRows,
        tags:tags,
        categoriaActual:cats,
        regimenActual : Number(datospre['TIPO_REGIMEN']),
        valueRegimen:  Number(datospre['ID_REGIMEN_FISCAL']),
      })
    }
  }

  submit1(){
    let {regimenActual,valueRegimen,entrega,costo,minimo,categoriaActual,tags} = this.state
    let formData = new FormData(document.getElementById('formularegistroempresa1'))
    formData.append('tipoRegimen',regimenActual)
    formData.append('idRegimen',valueRegimen)
    formData.append('servicio',entrega)
    formData.append('costo',costo)
    formData.append('precioCosto',0)
    formData.append('minimo',minimo)
    formData.append('precioMinimo',0)

    this.props.updateState('nopuedoCambiarSinAvisarte',true)

    let categoriaActualFlatter = categoriaActual.map(ct => (ct['value']))

    if(
        formData.get('tipoRegimen').trim()===''||
        formData.get('idRegimen').trim()===''||
        categoriaActualFlatter.length === 0 ||
        formData.get('rfc').trim()===''||
        formData.get('razon').trim()===''||
        formData.get('usuarioE').trim()===''||
        formData.get('nombreE').trim()===''
    ){
      this.setState({txtAlert:'No puede haber campos vacíos',modalAlert:true})
      return
    }
    if(
      (formData.get('tipoRegimen') === '1' && formData.get('rfc').length !== 13) ||
      (formData.get('tipoRegimen') === '2' && formData.get('rfc').length !== 12)
    ){
      this.setState({txtAlert:'Ingrese un RFC válido',modalAlert:true})
      return
    }
    
    let regexCurp = /[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}/

    if( formData.get('tipoRegimen') === '4' && !regexCurp.test(formData.get('rfc'))  ){
      this.setState({txtAlert:'Ingrese una CURP válida',modalAlert:true})
      return
    }

    if( categoriaActualFlatter.length===0  ){
      this.setState({txtAlert:'Debes tener al menos una categoría',modalAlert:true})
      return
    }
    let iden = formData.get('usuarioE')
    
    if( iden[0] === '.' || iden[iden.length-1]==='.' ){
      this.setState({txtAlert:'El identificador de empresa no puede empezar ni terminar con punto',modalAlert:true})
      return
    }
    let dataempresa = {}
    for(let data of formData.entries()){ dataempresa[data[0]] = data[1] }
    Object.assign(dataempresa,this.getInfoVaria(2))
    Object.assign(dataempresa,this.getInfoVaria(3))
    Object.assign(dataempresa,this.getInfoVaria(4))

    dataempresa['idCategoria'] = categoriaActualFlatter

    let data = {idE:sessionStorage.getItem('id'), identificador:iden}
    let repetido = false
    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/identificadorDeEmpresaDisponible`,data,request).then(rt=>{
      repetido = Number(rt.data) === 2
      if(repetido){
        this.setState({txtAlert:'El identificador de empresa ingresado no está disponible',modalAlert:true})
        return
      }else{
        axios.post(`${host}empresas/empresas/registrarEditar`,dataempresa,request).then(rt=>{
          this.props.updateState('nopuedoCambiarSinAvisarte',false)
          this.setState({loading:false,destroyable1:formData,cats2:categoriaActual.slice(),tags2:tags.slice()})
          this.closeModal('modal1',false)
        })        
      }
    })
  }
  
  submit2(){
    let formData = new FormData(document.getElementById('formularegistroempresa2'))
    let dataempresa = {}
    for(let data of formData.entries()){ dataempresa[data[0]] = data[1].trim() }

    if( dataempresa['facebook'].length > 0 ){
      if(!dataempresa['facebook'].includes('www.facebook.com')){
        dataempresa['facebook'] = `https://www.facebook.com/${dataempresa['facebook']}`
        document.querySelector('#facebookd').value = dataempresa['facebook']
      }
    }

    if( dataempresa['instagram'].length > 0 ){
      if(!dataempresa['instagram'].includes('www.instagram.com')){
        dataempresa['instagram'] = `https://www.instagram.com/${dataempresa['instagram']}`
        document.querySelector('#instagramd').value = dataempresa['instagram']
      }
    }

    console.log('dataempresa',dataempresa)

    Object.assign(dataempresa,this.getInfoVaria(1))
    Object.assign(dataempresa,this.getInfoVaria(3))
    Object.assign(dataempresa,this.getInfoVaria(4))
    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/registrarEditar`,dataempresa,request).then(rt=>{
      this.setState({loading:false})
      this.setState({destroyable2:formData})
      this.closeModal('modal2',false)
      this.props.updateState('nopuedoCambiarSinAvisarte',false)
    })
  }

  submit3(){
    let formData = new FormData(document.getElementById('formularegistroempresa3'))
    let vacio = false
    for(let data of formData.entries()){
      if(data[1].trim()==='')vacio = true
    }
    if(vacio){
      this.showError("No puede haber campos vacíos",3)
      return
    }
    this.props.updateState('nopuedoCambiarSinAvisarte',true)

    let regexMail = /(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$){1,200}/;

    let telefono = formData.get('telefono');
    telefono = telefono.split('_').join('');
    telefono = telefono.split('(').join('');
    telefono = telefono.split(')').join('');
    telefono = telefono.split(' ').join('');
    
    formData.set('telefono',telefono)

    if(formData.get('telefono').length < 10){
      this.setState({txtAlert:'El teléfono debe tener 10 caracteres',modalAlert:true})
      return
    }

    if(!regexMail.test(formData.get('mail')) ) {
      this.setState({txtAlert:'Ingrese un correo válido',modalAlert:true})
      return
    }
    if(formData.get('contra').length < 6 ){
      this.setState({txtAlert:'La contraseña debe tener al menos 6 caracteres',modalAlert:true})
      return
    }
    if(formData.get('contra') !== formData.get('contra2') ){
      this.setState({txtAlert:'Las contraseñas no coinciden',modalAlert:true})
      return
    }

    let dataempresa = {}
    for(let data of formData.entries()){ dataempresa[data[0]] = data[1] }
    Object.assign(dataempresa,this.getInfoVaria(1))
    Object.assign(dataempresa,this.getInfoVaria(2))
    Object.assign(dataempresa,this.getInfoVaria(4))
    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/registrarEditar`,dataempresa,request).then(rt=>{
      this.setState({loading:false})
      this.setState({destroyable3:formData})
      this.closeModal('modal3',false)
      this.props.updateState('nopuedoCambiarSinAvisarte',false)
    })
  }


  getInfoVaria(yes){
    let {destroyable1,destroyable2,destroyable3,regimenActual,valueRegimen,entrega,costo,minimo} = this.state
    let dataempresa = {}
    if(yes===1){
      destroyable1 = new FormData(document.getElementById('formularegistroempresa1'))
      destroyable1.append('tipoRegimen',regimenActual)
      destroyable1.append('idRegimen',valueRegimen)
      destroyable1.append('servicio',entrega)
      destroyable1.append('costo',costo)
      destroyable1.append('precioCosto',0)
      destroyable1.append('minimo',minimo)
      destroyable1.append('precioMinimo',0)

      for(let data of destroyable1.entries()){ dataempresa[data[0]] = data[1] }
    }

    if(yes===2){
      destroyable2 = new FormData(document.getElementById('formularegistroempresa2'))
      for(let data of destroyable2.entries()){ dataempresa[data[0]] = data[1] }
    }

    if(yes===3){
      destroyable3 = new FormData(document.getElementById('formularegistroempresa3'))
      for(let data of destroyable3.entries()){ dataempresa[data[0]] = data[1] }
      let telefono = dataempresa['telefono'];
      telefono = telefono.split('_').join('');
      telefono = telefono.split('(').join('');
      telefono = telefono.split(')').join('');
      telefono = telefono.split(' ').join('');
      dataempresa['telefono'] = telefono
    }

    if(yes===4){
      let {notiPush,notiMsj,tags,notiMail,categoriaActual,datos,dataPerfil,dataPortada,sucursales,nuevaDescripcion} = this.state
      
      dataempresa['idCategoria'] = categoriaActual.map(ct => (ct['value']))
      dataempresa['tags'] = tags
      dataempresa['nSucursales'] = sucursales.length
      dataempresa['src'] = ''
      dataempresa['ventasNoExistencias'] = datos.VENTA_SIN_EXISTENCIAS
      dataempresa['catalogoCompartido'] = datos.CATALOGO_COMPARTIDO
      dataempresa['pagoCompartido'] = datos.TIPO_PAGO_COMPARTIDO
      dataempresa['pedidoMultiple'] = datos.PEDIDOS_MULTIPLE_SUCURSAL
      dataempresa['controlCitas'] = datos.USAR_CONTROL_CITAS
      dataempresa['estatus'] = datos.ESTATUS
      dataempresa['id'] = sessionStorage.getItem('id')
      dataempresa['idFacebook'] = datos.ID_FACEBOOK

      dataempresa['dataPerfil'] = dataPerfil
      dataempresa['dataPortada'] = dataPortada
      
      dataempresa['srcPerfil'] = datos.DIRECCION_IMG_PERFIL
      dataempresa['srcPortada'] = datos.DIRECCION_IMG_PORTADA
      //dataempresa['usuarioE'] = this.state.datos.USUARIO_EMPRESA
      /**/
      dataempresa['notiPush'] = notiPush['ESTATUS']
      dataempresa['idNotiPush'] = notiPush['ID']
      
      dataempresa['notiMsn'] = notiMsj['ESTATUS']
      dataempresa['idNotiMsn'] = notiMsj['ID']

      dataempresa['notiCorreo'] = notiMail['ESTATUS']
      dataempresa['idNotiCorreo'] = notiMail['ID']
      dataempresa['descripcionEmpresa'] = nuevaDescripcion
      /**/
      dataempresa['Op'] = 2
    }
    return dataempresa;
  }

  changeSwitch(bool,name){
    let {datos,vectorCitas} = this.state
    if(name==="USAR_CONTROL_CITAS" && !bool && vectorCitas.length>0){

      let {validAllSucursal,validCurrentUser} = this.props
      let a = validAllSucursal(this.context)
      let b = true
      if(!bool)
        b = validCurrentUser(this.context,'citasDATA','bool',false,'ELIMINAR')
      if(a&&b)
        this.setState({modalConfirmDisable:true})
      else
        this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar la Configuración de citas.'})
      
      return
    }

    let valuesOBJ = {
      VENTA_SIN_EXISTENCIAS:1,
      TIPO_PAGO_COMPARTIDO:3,
      PEDIDOS_MULTIPLE_SUCURSAL:4,
      USAR_CONTROL_CITAS:5,
    }
    let data={
      idE:datos['ID'],
      tipo:valuesOBJ[name],
      estatus:bool?'1':'2',
    }    

    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/changeStatusSwitchEmpresa`,data,request).then(rt=>{
      if(rt.data.length>0){
        let rta = Number(rt.data[0]['RESULTADO'])
        if(rta===1){
          const {setValidCitas} = this.props
          datos[name] = data['estatus']
          setValidCitas(bool)
          this.setState({loading:false,datos})
        }
      }      
    })
  }

  changeNotiEstatus(checked,state){
    let {notiPush ,notiMsj ,notiMail} = this.state
    let value = checked ? 1 : 2
    let data = {
      idE:sessionStorage.getItem('id'),
      tipoNoti:state,
      estatus:value,
      idNoti: state===1?notiPush['ID']:state===2?notiMail['ID']:notiMsj['ID']
    }
    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/altaNotificacionEmpresa`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let rta = Number(rt.data['data'])
        if(rta>0 || rta === -2){
          if(state === 1){notiPush['ESTATUS'] = value; this.setState({notiPush,loading:false}); }
          if(state === 2){notiMail['ESTATUS'] = value; this.setState({notiMail,loading:false}); }
          if(state === 3){notiMsj['ESTATUS'] = value; this.setState({notiMsj,loading:false}); }
        }
      }
    })
  }

  changeFile(event){
    let {files} = event.target
    if(files.length===0)return
    if(!files[0].type.includes('image/')){
        this.setState({txtAlert:'Solo puedes elegir imagenes',modalAlert:true})
        return;
    }
    document.getElementById('myCroper').style.display='block'
    let file = event.target.files[0]
    let image = new Image()
    image.src = URL.createObjectURL(file);
    this.cropper.replace(URL.createObjectURL(file))
    event.target.value=""
  }

  changeFile2(event){
    let {files} = event.target
    if(files.length===0)return
    if(!files[0].type.includes('image/')){
        this.setState({txtAlert:'Solo puedes elegir imagenes',modalAlert:true})
        return;
    }
    document.getElementById('myCroper2').style.display='block'
    document.getElementById('myCroper2But').style.display='block'
    let file = event.target.files[0]
    let image = new Image()
    image.src = URL.createObjectURL(file);
    this.cropper2.replace(URL.createObjectURL(file))
    event.target.value=""
  }

  getImage(cropper){
    this.setState({loading:true})
    if(cropper===1){
      let src = this.cropper.getCroppedCanvas().toDataURL()
      document.getElementById('myCroper').style.display='none'
      let image2 = new Image()
      image2.onload = () => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        canvas.width = image2.width;
        canvas.height = image2.height;
        ctx.drawImage(image2, 0, 0);
        canvas.toBlob((blob)=>{
          let reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            let {datos} = this.state

            let base64data = reader.result;                
            //document.getElementById('srcPortada').src = base64data
            document.getElementById('srcPortada').style.display='block'
            
            let data = {}
            data['idE'] = datos['ID']
            data['dataPerfil'] = '0' 
            data['dataPortada'] = base64data
            
            data['srcPerfil'] = datos.DIRECCION_IMG_PERFIL
            data['srcPortada'] = datos.DIRECCION_IMG_PORTADA

            axios.post(`${host}empresas/empresas/altaImagen`,data,request).then(rt=>{
              this.setState({loading:false,dataPortada:base64data})
            })

          }
        },"image/webp")
      };
      image2.src=src;
    }
    if(cropper===2){
      let src = this.cropper2.getCroppedCanvas().toDataURL()
      this.setState({ dataPerfil:src })
      document.getElementById('myCroper2').style.display='none'
      document.getElementById('myCroper2But').style.display='none'
      let imagen = new Image()
      imagen.onload = () => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        canvas.width = imagen.width;
        canvas.height = imagen.height;
        ctx.drawImage(imagen, 0, 0);
        canvas.toBlob((blob)=>{
          let reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            let {datos} = this.state

            let base64data = reader.result;            
            let data = {}
            data['idE'] = datos['ID']
            data['dataPerfil'] = base64data
            data['dataPortada'] = '0'
            
            data['srcPerfil'] = datos.DIRECCION_IMG_PERFIL
            data['srcPortada'] = datos.DIRECCION_IMG_PORTADA

            axios.post(`${host}empresas/empresas/altaImagen`,data,request).then(rt=>{
              if(rt.data['status']==='ok'){
                
                /*let dataSrc = rt.data['src'].split('..').join('https://bubook.nibirusystem.com')
                document.getElementById('srcPerfil').src = dataSrc+Math.random()
                document.getElementById('fotopanelmini').src = dataSrc+Math.random()*/
                let {editState} = this.props
                
                editState('misrc2',base64data)
                this.setState({loading:false,dataPerfil:base64data})
              }else{
                this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde',modalAlert:true})
              }
            })

          }
        },"image/webp")
      };
      imagen.src=src;
    }
    //this.props.updateState('nopuedoCambiarSinAvisarte',true)
  }
  
  cancelImage(cropper){
    let {folder} = this.state
    if(cropper===1){
      this.setState({dataPortada:'0'})
      document.getElementById('srcPortada').src = folder + '/Perfil/'+this.state.datos.DIRECCION_IMG_PORTADA+'.png'
      document.getElementById('myCroper').style.display='none'
      document.getElementById('srcPortada').style.display = this.state.datos.DIRECCION_IMG_PORTADA===""?'none':'block'
    }
    if(cropper===2){
      this.setState({ dataPerfil:'0' })
      document.getElementById('srcPerfil').src= this.state.datos.DIRECCION_IMG_PERFIL===''?ImgPanel+'perfilDefault.jpg':folder+'/Perfil/'+this.state.datos.DIRECCION_IMG_PERFIL+'.png'
      document.getElementById('fotopanelmini').src= this.state.datos.DIRECCION_IMG_PERFIL===''?ImgPanel+'perfilDefault.jpg': folder+'/Perfil/'+this.state.datos.DIRECCION_IMG_PERFIL+'.png'
      document.getElementById('myCroper2').style.display='none'
      document.getElementById('myCroper2But').style.display='none'
    }
  }
  
  changeIDenti(e){
    let {value} = e.target
    value = value.split('á').join('a')
    value = value.split('é').join('e')
    value = value.split('í').join('i')
    value = value.split('ó').join('o')
    value = value.split('ú').join('u')
    value = value.replace(/[^A-Za-z0-9.]+/g,'')
    e.target.value = value
  }

  subClick(id){
    document.getElementById(id).click()
  }

  clickCancelar(){
    let {cancelanding} = this.state
    let motivo = document.getElementById('motivoCancel').value.trim()
    if(motivo===''){
      this.setState({txtAlert:'El motivo de cancelación para las citas es obligatorio',modalAlert:true})
      return
    }
    if(cancelanding){
      this.eliminarAllConfig(motivo)
    }
  }

  changeIdSucursalId(newIdSucursalActual){
    this.setState({newIdSucursalActual,modalConfirmChangeSucursal:true})
  }

  changeSucursalIdConfirm(){
    let {idSucursalActual,newIdSucursalActual,citas,sucursales,semaforo,deshabilitado} = this.state
    let sucursal = sucursales.filter(s=>s['ID']===newIdSucursalActual)
    sucursal = sucursal[0]
    idSucursalActual = newIdSucursalActual
    newIdSucursalActual = -1
    let horarios = this.makeHorarios(sucursal);
    semaforo = this.takeSemaforoOut(horarios)
    deshabilitado = (semaforo===3)
    citas['HORARIOS'] = horarios
    this.setState({idSucursalActual,newIdSucursalActual,modalConfirmChangeSucursal:false,semaforo,deshabilitado})
  }

  removeAccents(str){
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  }

  makeHorarios(sucursal){
    let horarios = []
    let dias = { LUNES:1, MARTES:2, MIERCOLES:3, JUEVES:4, VIERNES:5, SABADO:6, DOMINGO:7, }
    let status = { ABIERTO:1, CERRADO:2,}
    for(let horario of sucursal['horarios']){
      horarios.push({
        ID:-1,
        DIA: dias[this.removeAccents(horario['DIA'])],
        HORA_INICIO: horario['HORA_ABRE'],
        MOMENT_INICIO:moment(horario['HORA_ABRE'],'HH:mm:ss'),
        HORA_TERMINO:horario['HORA_CIERRA'],
        MOMENT_TERMINO:moment(horario['HORA_CIERRA'],'HH:mm:ss'),
        ESTATUS:Number(status[horario['ESTATUS']]),
        TIPO_HORA:1,
        HIJOS:[]
      })
    }    
    return horarios
  }

  takeSemaforoOut(horarios){
    if( Number(horarios[0]['ESTATUS'])===2 &&
        Number(horarios[1]['ESTATUS'])===2 &&
        Number(horarios[2]['ESTATUS'])===2 &&
        Number(horarios[3]['ESTATUS'])===2 &&
        Number(horarios[4]['ESTATUS'])===2
    ) return 1

    if( Number(horarios[0]['ESTATUS'])===1 
    ) return 2

    return 3;    
  }

  showModalNewConfiguracion(index){
    let {vectorCitas,sucursales,idSucursalActual,semaforo,deshabilitado} = this.state
    let {validCurrentUser} = this.props
    let obj = { }
    let obj2 = { }
    if(index===-1){
      let sucursales2 =  sucursales.filter(s => !s['conCitas'] && validCurrentUser(this.context,'citasDATA','bool',s['CODE'],'CREAR') )
      sucursales2 = sucursales2[0]
      idSucursalActual = sucursales2['ID']
      let horarios = this.makeHorarios(sucursales2)
      semaforo = this.takeSemaforoOut(horarios)
      deshabilitado = (semaforo===3)
      obj = {
        HORARIOS:horarios,
        ID:-1,
        ID_EMPRESA:sessionStorage.getItem('id'),
        DURACION_CITA:0,//'00:00',
        INTERVALO_ENTRE_CITAS:0,//'00:00',
        MAXIMO_CITAS_DIAS:0,
        ID_SUCURSAL:-1,
        activo:true,
        hijosCompartidos:false,
      }
    }
    else{
      semaforo = this.takeSemaforoOut(vectorCitas[index]['HORARIOS'])
      obj = JSON.parse(JSON.stringify(vectorCitas[index]))
      obj2 = JSON.parse(JSON.stringify(vectorCitas[index]))
      obj['HORARIOS'].sort((a,b)=> Number(a['DIA']) - Number(b['DIA']) )
      
      for(let key in obj['HORARIOS']){
        obj['HORARIOS'][key]['MOMENT_TERMINO'] = moment(obj['HORARIOS'][key]['MOMENT_TERMINO'])
        obj['HORARIOS'][key]['MOMENT_INICIO'] = moment(obj['HORARIOS'][key]['MOMENT_INICIO'])

        obj['HORARIOS'][key]['ESTATUS_INICIAL'] = obj['HORARIOS'][key]['ESTATUS']
        obj['HORARIOS'][key]['MOMENT_TERMINO_INICIAL'] = moment(obj['HORARIOS'][key]['MOMENT_TERMINO'])
        obj['HORARIOS'][key]['MOMENT_INICIO_INICIAL'] = moment(obj['HORARIOS'][key]['MOMENT_INICIO'])
        obj['HORARIOS'][key]['HIJOS_INICIAL'] =  JSON.parse(JSON.stringify(obj['HORARIOS'][key]['HIJOS']))

        for(let hijo of obj['HORARIOS'][key]['HIJOS'] ){
          hijo['inicio'] = moment(hijo['inicio'])
          hijo['fin'] = moment(hijo['fin'])
        }

        obj2['HORARIOS'][key]['MOMENT_TERMINO'] = moment(obj2['HORARIOS'][key]['MOMENT_TERMINO'])
        obj2['HORARIOS'][key]['MOMENT_INICIO'] = moment(obj2['HORARIOS'][key]['MOMENT_INICIO'])
      }

      obj['DURACION_CITA'] = moment.duration(obj['DURACION_CITA'],'HH:mm:ss').asMinutes()
      obj['INTERVALO_ENTRE_CITAS'] = moment.duration(obj['INTERVALO_ENTRE_CITAS'],'HH:mm:ss').asMinutes()
      
      obj['DURACION_CITA_INICIAL'] = Number(obj['DURACION_CITA']+'')
      obj['INTERVALO_ENTRE_CITAS_INICIAL'] = Number(obj['INTERVALO_ENTRE_CITAS']+'')
    }
    
    setTimeout(()=>{
      document.getElementById('duracionCita').value = obj['DURACION_CITA']      
      document.getElementById('intervaloCita').value = obj['INTERVALO_ENTRE_CITAS']
    },50)


    this.setState({idEditando:index,citas:obj,citas2:obj2, modalDetalleCita:true,modalListaConfiguraciones:false,idSucursalActual,semaforo,deshabilitado})
  }

  closeModalNewConfiguracion(){
    let {idEditando,citas,citas2,vectorCitas} = this.state
    let objBase={ HORARIOS:[ {}, {}, {}, {}, {}, {}, {}, ] }
    if(idEditando!==-1){
      vectorCitas[idEditando] = JSON.parse(JSON.stringify(citas2))
      citas2 = JSON.parse(JSON.stringify(objBase))
      citas = JSON.parse(JSON.stringify(objBase))
    }
    this.setState({modalDetalleCita:false,idEditando:-1,modalListaConfiguraciones:true,citas,citas2})
  }

  jumpClick(e){
    if(e.target.className==='inputs-label'){
      e.preventDefault()
      e.stopPropagation()
    }
  }

  changeSwitch2(pos,checked){
    let {citas} = this.state
    citas['HORARIOS'][pos]['ESTATUS'] = checked ? 1: 2;
    citas['HORARIOS'][pos]['HIJOS'] = citas['HORARIOS'][pos]['HIJOS'].filter(h => h.ID !== -1)
    for(let h of citas['HORARIOS'][pos]['HIJOS']){
      h['ESTATUS'] = 2
    }
    this.setState({citas:citas})
  }

  controlSemanal(event){
    if(event.target.id !== 'mibandera')return
    let {semaforo,deshabilitado} = this.state
    if( semaforo === 1 ){
      if(document.getElementById('swSlave2').getAttribute('aria-checked')==='false')document.getElementById('swSlave2').click()
      if(document.getElementById('swSlave3').getAttribute('aria-checked')==='false')document.getElementById('swSlave3').click()
      if(document.getElementById('swSlave4').getAttribute('aria-checked')==='false')document.getElementById('swSlave4').click()
      if(document.getElementById('swSlave5').getAttribute('aria-checked')==='false')document.getElementById('swSlave5').click()
      if(deshabilitado)this.setState({deshabilitado:false})
    }
    else if( semaforo === 2){
      document.getElementById('swSlave1').click()
      this.setState({deshabilitado:true})
    }
    else if( semaforo === 3){
      if(document.getElementById('swSlave2').getAttribute('aria-checked')==='true')document.getElementById('swSlave2').click()
      if(document.getElementById('swSlave3').getAttribute('aria-checked')==='true')document.getElementById('swSlave3').click()
      if(document.getElementById('swSlave4').getAttribute('aria-checked')==='true')document.getElementById('swSlave4').click()
      if(document.getElementById('swSlave5').getAttribute('aria-checked')==='true')document.getElementById('swSlave5').click()
    }
    semaforo++;
    if(semaforo===4){ semaforo=1; }
    this.setState({semaforo:semaforo})
  }

   horaChange(value,svalue,pos,bandera){
     let {citas} = this.state
     let name = bandera === 1 ? 'HORA_INICIO' : 'HORA_TERMINO'
     let moment_name = bandera === 1 ? 'MOMENT_INICIO' : 'MOMENT_TERMINO'
     if(pos === 0){
       for(let i=0;i<7;i++){
         citas['HORARIOS'][i][name] = value.format('HH:mm')
         citas['HORARIOS'][i][moment_name] = value
       }
     }
     else{
       citas['HORARIOS'][pos][name] = value.format('HH:mm')
       citas['HORARIOS'][pos][moment_name] = value
     }
     this.setState({citas:citas})
  }

  changeChecked(checked){
    let {citas} = this.state
    citas['ID_SUCURSAL'] = checked
    this.setState({citas:citas})
  }

  changeMinutes(v,sv,c){
    let {citas} = this.state
    citas[c] = sv
    this.setState({citas:citas})
  }

  formatDuration(minutes){
    return moment.utc(moment.duration(minutes, "minutes").asMilliseconds()).format("HH:mm")
  }

  addConfig(){
    let {citas,idSucursalActual,idEditando} = this.state

    let duracion = document.getElementById('duracionCita').value.trim()
    let intervalo = document.getElementById('intervaloCita').value.trim()

    
    if( duracion==='' || Number(duracion)===0 ){
      this.setState({modalAlert:true,txtAlert:'La duración de la cita debe ser mayor a 0'})
      return
    }

    duracion = Number(duracion)
    intervalo = Number(intervalo)

    citas['DURACION_CITA'] = this.formatDuration(duracion)
    citas['INTERVALO_ENTRE_CITAS'] = this.formatDuration(intervalo)

    if(citas['HORARIOS'].some(horario => Number(horario['ESTATUS'])===1 && horario['MOMENT_TERMINO'].isSameOrBefore(horario['MOMENT_INICIO']) )  ){
      this.setState({modalAlert:true,txtAlert:'Las horas de cierre deben ser mayores a las horas de apertura.'})
      return
    }

    citas['MAXIMO_CITAS_DIAS'] = 0
    citas['ID_SUCURSAL']=idSucursalActual
    let idE = ''

    if(idEditando!==-1){//editando
      if(
        duracion !== Number(citas['DURACION_CITA_INICIAL']) ||
        intervalo !== Number(citas['INTERVALO_ENTRE_CITAS_INICIAL'])
      ){//Edite datos que no son horarios

        let idE = ''
        for(let horario of citas['HORARIOS']){
          idE+=horario['ID']+'-'
        }
        let data = { idE, tipo:1, motivo:'', }
        this.setState({loading:true})
        axios.post(`${host}citas/citas/cancelarCitasMuiltiples`,data,request).then(rt=>{
          if( rt.data['status'] === 'ok'){
            let pendientes = Number(rt.data['result'][0]['RESULTADO'])
            if(pendientes>0){
              this.setState({txtAlert:'Tienes citas confirmadas que impiden que puedas editar tu configuración actual. Si deseas cambiar los horarios de atención, deberás cancelar manualmente las citas confirmadas que tengas.',modalAlert:true,loading:false,modalDetalleCita:true})
            }else{
              this.setState({loading:true})
              axios.post(`${host}empresas/empresas/agregarEditarConfiCitas`,citas,request).then(rt=>{
                if(Number(rt.data)>0||Number(rt.data)===-2){
                  idSucursalActual = -1
                  this.setState({loading:false,txtAlert:'Los cambios han sido guardados correctamente',modalAlert:true,idEditando:-1,modalDetalleCita:false,idSucursalActual})
                  this.actualizarConfiguraciones()
                }
                else
                  this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #03-01]',modalAlert:true,idEditando:-1,modalDetalleCita:false})
              })
            }
          }else{
            this.setState({txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #03-02]',modalAlert:true,loading:false})
          }
        })
      }else{
        for(let horario of citas['HORARIOS']){
          if(Number(horario['ESTATUS_INICIAL'])===1 && Number(horario['ESTATUS'])===2 ){//estaba activado y ya no
            idE+=horario['ID']+'-'
          }
          else if( horario['MOMENT_INICIO_INICIAL'].isBefore(horario['MOMENT_INICIO'])  ){//hora de inicio cambio 
            idE+=horario['ID']+'-'
          }
          else if( horario['MOMENT_TERMINO_INICIAL'].isAfter(horario['MOMENT_TERMINO'])  ){// hora de fin cambio
            idE+=horario['ID']+'-'
          }
          else if( horario['HIJOS_INICIAL'].length <  horario['HIJOS'].length  ){//hijos cambiaron
            idE+=horario['ID']+'-'
          }
        }
        let data = {idE}
        this.setState({loading:true,})
        axios.post(`${host}citas/citas/citasDePorHorario`,data,request).then(rt=>{        
          if( rt.data['status'] === 'ok' ){
            idE = idE.split('-')
            idE.pop()
            let sipuedoCambiar=true
            for(let idHorario of idE){
              if(!sipuedoCambiar)break;
              let auxHora = citas['HORARIOS'].filter(h => h['ID'] === idHorario)
              auxHora = auxHora[0]
              let auxCitas = rt.data['result'].filter(c=> c['ID_HORARIO'] === idHorario)
              for(let cita of auxCitas){

                if(!sipuedoCambiar)break;
                
                let inicioCita = moment(cita['HORA_INICIO'],'HH:mm:ss')
                let finCita = moment(cita['HORA_TERMINO'],'HH:mm:ss')

                if( Number(auxHora['ESTATUS']) === 2 ){
                  sipuedoCambiar = false
                  break
                }

                if(inicioCita.isBefore(auxHora['MOMENT_INICIO'])){
                  sipuedoCambiar = false
                  break
                }

                if(finCita.isAfter(auxHora['MOMENT_TERMINO'])){
                  sipuedoCambiar = false
                  break
                }

                for(let horahijo of auxHora['HIJOS']){
                  if(inicioCita.isBetween(horahijo['fin'],horahijo['inicio'])){
                    sipuedoCambiar = false
                    break
                  }
                }

              }
            }
            if(!sipuedoCambiar){
              this.setState({txtAlert:'Tienes citas confirmadas que impiden que puedas editar tu configuración actual. Si deseas cambiar los horarios de atención, deberás cancelar manualmente las citas confirmadas que tengas.',modalAlert:true,loading:false,modalDetalleCita:true})
            }else{
              this.setState({loading:true})
              axios.post(`${host}empresas/empresas/agregarEditarConfiCitas`,citas,request).then(rt=>{
                if(Number(rt.data)>0||Number(rt.data)===-2){
                  idSucursalActual = -1
                  this.setState({loading:false,txtAlert:'Los cambios han sido guardados correctamente',modalAlert:true,idEditando:-1,modalDetalleCita:false,idSucursalActual})
                  this.actualizarConfiguraciones()
                }
                else
                  this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #03-01]',modalAlert:true,idEditando:-1,modalDetalleCita:false})
              })
            }
          }
          else
            this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #03-01]',modalAlert:true,idEditando:-1,modalDetalleCita:false})
        })
      }
    }else{//agregando
      this.setState({loading:true})
      axios.post(`${host}empresas/empresas/agregarEditarConfiCitas`,citas,request).then(rt=>{
        if(Number(rt.data)>0||Number(rt.data)===-2){
          idSucursalActual = -1
          this.setState({loading:false,txtAlert:'Los cambios han sido guardados correctamente',modalAlert:true,idEditando:-1,modalDetalleCita:false,idSucursalActual})
          this.actualizarConfiguraciones()
        }
        else
          this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #03-01]',modalAlert:true,idEditando:-1,modalDetalleCita:false})
      })
    }

  }

  modalEliminarSucursal(e,sucursal){
    e.stopPropagation();
    let {validCurrentUser} = this.props
    if(!validCurrentUser(this.context,'citasDATA','bool',sucursal['CODE'],'ELIMINAR')){
      this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permiso para eliminar la Configuración de Cita seleccionada.',})
      return
    }

    this.setState({modalEliminarSucursal:true,idEliminar:sucursal})
  }

  elimiarSucursalDeConfigCitas(){
    let {idEliminar} = this.state
    let idE = ''
    for(let horario of idEliminar['HORARIOS']){
      idE+=horario['ID']+'-'
    }
    let data = { idE, tipo:1, motivo:'', }
    this.setState({modalEliminarSucursal:false,loading:true})
    axios.post(`${host}citas/citas/cancelarCitasMuiltiples`,data,request).then(rt=>{
      if( rt.data['status'] === 'ok' ){
        let pendientes = Number(rt.data['result'][0]['RESULTADO'])
        if(pendientes>0){
          this.setState({txtAlert:'Tienes citas confirmadas que impiden que puedas eliminar la configuración seleccionada. Si deseas eliminar esta configuración, deberás cancelar manualmente las citas confirmadas que tengas.',modalAlert:true,loading:false,idEliminar:-1})
        }else{
          data={ idEliminar:idEliminar['ID'], }
          this.setState({modalEliminarSucursal:false,idEliminar:-1,loading:true})
          axios.post(`${host}empresas/empresas/eliminarSucuralFromConfiguracion`,data,request).then(rt=>{
            let rta = Number(rt.data)
            if(rta === 1){
              this.setState({txtAlert:'La configuración ha sido eliminada correctamente',modalAlert:true,})
              this.actualizarConfiguraciones()
            }
            else{
              this.setState({txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #03-02]',modalAlert:true,loading:false})
            }
          })
        }
      }
    })
  }

  eliminarAllConfig(motivo){
    let data = {
      idE:sessionStorage.getItem('id'),
      tipo:2,
      motivo:motivo,
    }
    this.setState({modalConfirmDisable:false,loading:true,cancelanding:false})
    axios.post(`${host}citas/citas/cancelarCitasMuiltiples`,data,request).then(rt=>{
      if( rt.data['status'] === 'ok' ){
        let pendientes = Number(rt.data['result'][0]['RESULTADO'])
        if(pendientes>0){
          this.setState({cancelanding:true,loading:false})
        }else{
          let {vectorCitas,datos} = this.state
          data['collect'] = vectorCitas.map(vc => vc.ID)
          data['idE'] = sessionStorage.getItem('id')
          datos['USAR_CONTROL_CITAS'] = false
          this.setState({datos})
          axios.post(`${host}empresas/empresas/eliminarTodasLasConfiguraciones`,data,request).then(rt=>{
            let rta = Number(rt.data)
            if(rta === 1){
              this.setState({txtAlert:'Las configuraciónes de citas han sido eliminadas',modalAlert:true,})
              this.actualizarConfiguraciones()
            }
            else{
              this.setState({txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #03-02]',modalAlert:true,loading:false})
            }
          })
        }
      }
    })
  }

  showNewHijo(id,index){
    let {terminaAux ,empiezaAux,citas} = this.state
    empiezaAux = moment('00:00','HH:mm')
    terminaAux = moment('00:00','HH:mm')
    if(id!==-1){
      empiezaAux = citas['HORARIOS'][index]['HIJOS'][id]['inicio']
      terminaAux = citas['HORARIOS'][index]['HIJOS'][id]['fin']
    }
    this.setState({modalHorarioHijo:true,indexCurrPadre:index,indexCurrHijo:id,terminaAux:terminaAux,empiezaAux:empiezaAux})
  }

  appendHorarioHijo(){
    let {empiezaAux,terminaAux,indexCurrPadre,indexCurrHijo,citas} = this.state
    if(empiezaAux.isBefore(citas['HORARIOS'][indexCurrPadre]['MOMENT_INICIO']) || terminaAux.isAfter(citas['HORARIOS'][indexCurrPadre]['MOMENT_TERMINO']) ){
      this.setState({modalAlert:true, txtAlert:'El horario de descanso debe estar en el rango de horario de atención', })
      return
    }
    
    for(let horario of citas['HORARIOS'][indexCurrPadre]['HIJOS']){
      if(
        empiezaAux.isBetween(horario['inicio'],horario['fin']) || 
        terminaAux.isBetween(horario['inicio'],horario['fin']) ||
        terminaAux.format('HH:mm:ss') === horario['fin'].format('HH:mm:ss') ||
        empiezaAux.format('HH:mm:ss') === horario['inicio'].format('HH:mm:ss')
      ){
        this.setState({modalAlert:true, txtAlert:'Los horarios de descanso no pueden interferirse.', })
        return
      }
    }

    if(indexCurrHijo===-1)
      citas['HORARIOS'][indexCurrPadre]['HIJOS'].push({ ID:-1, ESTATUS:1, inicio:empiezaAux, labelInicio:empiezaAux.format('HH:mm'), HORA_INICIO:empiezaAux.format('HH:mm'), fin:terminaAux, labelFin:terminaAux.format('HH:mm'), HORA_TERMINO:terminaAux.format('HH:mm'), })
    else{
      citas['HORARIOS'][indexCurrPadre]['HIJOS'][indexCurrHijo]['inicio'] = empiezaAux
      citas['HORARIOS'][indexCurrPadre]['HIJOS'][indexCurrHijo]['labelInicio'] = empiezaAux.format('HH:mm')
      citas['HORARIOS'][indexCurrPadre]['HIJOS'][indexCurrHijo]['HORA_INICIO'] = empiezaAux.format('HH:mm')

      citas['HORARIOS'][indexCurrPadre]['HIJOS'][indexCurrHijo]['fin'] = terminaAux
      citas['HORARIOS'][indexCurrPadre]['HIJOS'][indexCurrHijo]['labelFin'] = terminaAux.format('HH:mm')
      citas['HORARIOS'][indexCurrPadre]['HIJOS'][indexCurrHijo]['HORA_TERMINO'] = terminaAux.format('HH:mm')
      citas['HORARIOS'][indexCurrPadre]['HIJOS'][indexCurrHijo]['ESTATUS'] = 1
    }
    this.setState({citas:citas,modalHorarioHijo:false})
  }

  removeHijo(){
    let {indexCurrHijo,indexCurrPadre,citas} = this.state
    if(citas['HORARIOS'][indexCurrPadre]['HIJOS'][indexCurrHijo]['ID']===-1)
      citas['HORARIOS'][indexCurrPadre]['HIJOS'].splice(indexCurrHijo,1)
    else
      citas['HORARIOS'][indexCurrPadre]['HIJOS'][indexCurrHijo]['ESTATUS'] = 2
    this.setState({indexCurrHijo:-1,indexCurrPadre:-1,citas:citas,modalEliminarHijo:false})
  }

  setAllAsLunes(){
    let {citas} = this.state
    let aux = citas['HORARIOS'][0]['HIJOS']
    for(let h of citas['HORARIOS']){
      if(h.ESTATUS === 1 && h.DIA !== 0 )h['HIJOS'] = aux.slice(0)
    }
    this.setState({citas:citas,modalCopyAll:false})
  }

  eliminarCuenta(op){
    this.setState({modalConfirmEliminarCuenta:false,modalConfirmInhabilitarCuenta:false,loading:true})
    let data = {idE:sessionStorage.getItem('id')}
    axios.post(`${host}pedidos/pedidos/pedidosPendientesEmpresa`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        if(rt.data['data'].length>0){
          let r = Number(rt.data['data'][0]['RESULTADO'])
          if(r>0){
            let obj = {7:'deshabilitar',4:'eliminar'}
            let label = obj[op]
            this.setState({modalAlert:true,txtAlert:'Tienes pedidos pendientes. Para '+label+' su cuenta, primero deberá terminar todos los pedidos pendientes que tenga.',loading:false})
          }
          else{
            if(op===7)
              this.setState({loading:false,modalConfirmInhabilitarCuenta:true,opExec:op})
            if(op===4)
              this.setState({loading:false,modalConfirmEliminarCuenta:true,opExec:op})
          }
        }
        else{
          this.setState({modalAlert:true,txtAlert:'Parece que ocurrió un error durante el proceso, por favor intentelo más tarde.',loading:false})
        }
      }else{
        this.setState({modalAlert:true,txtAlert:'Parece que ocurrió un error durante el proceso, por favor intentelo más tarde.',loading:false})
      }
    })
  }

  eliinacionConfirmadaCuenta(){
    let {validData} = this.props
    let {opExec} = this.state
    let data = {customer:validData['idFK'],type:opExec}
    if(opExec===7)
      this.setState({loading:true,modalConfirmInhabilitarCuenta:false})
    if(opExec===4)
      this.setState({loading:true,modalConfirmEliminarCuenta:false})

    axios.post(`${host}payment/GatewayStripe/pauseSubWeb`,data,request).then(rt=>{
      if(Number(rt.data['result']) === 1){
        if(opExec===4)this.setState({modalCuentaEliminada:true,loading:false})
        if(opExec===7){
          this.setState({txtAlert:'Tu cuenta ha sido deshabilitada correctamente',modalAlert:true,loading:false,estatusEmpresa:3,opExec:-1,})
        }
      }
      else{
        this.setState({modalAlert:true,txtAlert:'Parece que ocurrió un error durante el proceso, por favor intentelo más tarde.',loading:false})
      }
    })
  }

  habilitarCuenta(){
    let {validData} = this.props
    this.setState({loading:true,modalConfirmHabilitarCuenta:false})
    let data = {customer:validData['idFK'],}
    axios.post(`${host}payment/GatewayStripe/enableUserWeb`,data,request).then(rt=>{
      if(Number(rt.data['result'])===1){
        this.setState({loading:false,txtAlert:'Tu cuenta fue habilitada correctamente',modalAlert:true,estatusEmpresa:1})
      }
      else{
        this.setState({loading:false,txtAlert:'Parece que hubo un error, por favor intentalo de nuevo',modalAlert:true,estatusEmpresa:1})
      }
    })
  }


  cerrarSesion(){
    this.setState({modalCuentaEliminada:false,modalCuentaInhabilitada:false,})
    this.props.cerraSesion()
  }

  addDescripcion(){
    let descripcionEmpresa = document.getElementById('descripcionDES').value.trim()
    let data = {id:sessionStorage.getItem('id') , descripcionEmpresa}
    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/altaDescripcionEmpresa`,data,request).then(rt=>{      
      if(Number(rt.data)===1){
        let{datos}=this.state
        datos['DESCRIPCION'] = descripcionEmpresa
        this.setState({loading:false,addingdEscrip:false,datos})
      }else{
        this.setState({loading:false,addingdEscrip:false,txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde.',modalAlert:true})
      }

    })
    
  }

  submitContrasena(){
    let {datos,datosU,tipoU} = this.state
    let oldp = document.getElementById('contraseniad').value
    let tocompare = (tipoU===1) ? datos['CONTRASENA'] : datosU['contra']
    if(oldp===tocompare){
      document.getElementById('labelPsswrd'+tipoU).style.display = 'none'
      document.getElementById('bloquePsswrd'+tipoU).style.display = 'initial'
      if(tipoU===1)
        document.getElementById('contra2d').value = ''
      else
        document.getElementById('contra2d2').value = ''

      this.setState({modalContrasena:false})
    }
    else
      this.setState({modalAlert:true,txtAlert:'La contraseña ingresada no coincide'})
  }

  atLeatsOne(){
    let{sucursales,vectorCitas} = this.state
    let{validCurrentUser} = this.props
    let sucursalesAllowed = sucursales.filter(sucursal => !vectorCitas.some(cita => cita['CODE']===sucursal['CODE']  ) )
    let returned = false
    for(let sucursal of sucursalesAllowed){
      if(!returned)
        returned = validCurrentUser(this.context,'citasDATA','bool',sucursal['CODE'],'CREAR')
    }
    return returned
  }

  render(){
    return (
      <div className="cont-sucursales" >

        <div id="divijo"></div>

        <ModalMembresia
          modalMembresia={this.state.modalMembresia}
          validData={this.props.validData}
          datos={this.state.datospre}
          close={()=>this.setState({modalMembresia:false})}
          getRef={ref => {this.childMembresia = ref}}
          controlStripeModal={this.props.controlStripeModal}
          setFromMembresia={this.props.setFromMembresia}
          />

        <MetodosDePago
          visible={this.state.modalMetodosDePago}
          datos={this.state.datospre}
          sucursales={this.state.sucursales}
          close={()=>this.setState({modalMetodosDePago:false})}
          getRef={ref => {this.childMetodos = ref}}
          validCurrentUser={this.props.validCurrentUser}
          validCurrentSucursal={this.props.validCurrentSucursal}
          validAllSucursal={this.props.validAllSucursal} />
        
        <Modal centered title="Información general" closable={false} maskClosable={false} keyboard = {false} forceRender={true}
          okText="Guardar"
          okButtonProps={{ className:'btn btn1',
            style: { width:'120px', display:(this.props.validCurrentUser(this.context,'infor','bool',false,'EDITAR')?'flex':'none' ) } 
          }}

          cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',} }}
          visible={this.state.modal1} zIndex={10}
          onOk={this.submit1}
          onCancel={() => this.closeModal('modal1',false,'formularegistroempresa1')}
          className="miAntModal miconfigModal alined" >
          
          <form id="formularegistroempresa1">
            <div className="miconfigDiv nomgtp" style={{position:'relative'}} >

            {
            !this.props.validCurrentUser(this.context,'infor','bool',false,'EDITAR')&&
            <div className='fakemask'
              title='El perfil actual no tiene permisos para editar la Información general.'
              onClick={()=>this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar la Información general.'})} />
            }

            <div className="inputs-group">
              <label className="inputs-label"> Tipo de contribuyente </label>
              <Select value={this.state.regimenActual} readOnly disabled id="tipoA" >
                <Select.Option value={4} > No aplica </Select.Option>
                <Select.Option value={1} > Persona física </Select.Option>
                <Select.Option value={2} > Persona Moral </Select.Option>
              </Select>
            </div>

            <div className="inputs-group" style={{display: Number(this.state.regimenActual) === 4 ? 'none':'block'}} >
              <label className="inputs-label"> Régimen fiscal </label>
                <Select value={this.state.valueRegimen} readOnly disabled >
                {
                  this.state.regimenes[Number(this.state.regimenActual)].map((x,indice)=>{
                    return(
                      <Select.Option key={'reg_'+x['ID']} value={Number(x['ID'])} > {x['DESCRIPCION']} </Select.Option>
                    )
                  })
                }
                </Select>
            </div>

            <div className="inputs-group">
              <label className="inputs-label" htmlFor="rfcd" > { Number(this.state.regimenActual) === 4 ? 'CURP' :  'RFC' } </label>
                <input defaultValue={this.state.datos.RFC} readOnly type="text" className="inputs-input" maxLength={this.state.regimenActual===1?13:this.state.regimenActual===2?12:18} name="rfc" id="rfcd" />
            </div>

            <div className="inputs-group">
              <label className="inputs-label" htmlFor="razond" > { Number(this.state.regimenActual) === 4 ? 'Nombre completo del representante' :  'Razón social' }  </label>
                <input defaultValue={this.state.datos.RAZON_SOCIAL} type="text" className="inputs-input" name="razon" id="razond"  />
            </div>

            <div className="inputs-group">
              <label className="inputs-label" htmlFor="nombreEd" > Nombre comercial </label>
                <input defaultValue={this.state.datos.NOMBRE_COMERCIAL} type="text" className="inputs-input" name="nombreE" id="nombreEd" />
            </div>

            <div className="inputs-group">
              <label className="inputs-label" htmlFor="usuarioEd" > Identificador de empresa </label>
              <input defaultValue={this.state.datos.USUARIO_EMPRESA} maxLength={50} type="text" className="inputs-input" name="usuarioE" id="usuarioEd" onChange={this.changeIDenti} />
            </div>

            <div className="inputs-group">
              <label className="inputs-label"> Categoría </label>

              <TreeSelect treeCheckable={true} showSearch={true} treeCheckStrictly={true} placeholder='' className="inputBaseTree" dropdownClassName="myDropdownClassName"
                filterTreeNode={this.filterNode} onChange={this.cambioCategoria}
                value={this.state.categoriaActual} treeData={this.state.treeData} />

            </div>

            <div className="inputs-group cont-tags">
              <label className="inputs-label"> Tags </label>
              <div className="group-tags" id="tags" > 
              {
                this.state.tags.map((x,indice)=>{
                  return(
                    <label key={'tag_'+indice} id={'tag_'+indice} className="mytag" >
                      {x} <span onClick={()=>this.removeTag(x,indice)} > × </span>
                    </label>
                  );
                })
              }
              </div>
              <input type="text" autoComplete="off" placeholder="Tag" id="tag" onKeyPress={this.addTag} className="inputs-input input-tag" />
              <span className="nota"> Son palabras clave que describen tu negocio, esto permitirá que te encuentren más rápido. Los primeros tres se mostraran en la portada de tu perfil. </span>
            </div>

            </div>
          </form>
        </Modal>
            
          <Modal centered title="Redes sociales" closable={false} maskClosable={false} keyboard = {false} forceRender={true}
            okText="Guardar"
            okButtonProps= {{ className:'btn btn1' ,
              style: { width:'120px', display:(this.props.validCurrentUser(this.context,'redes','bool',false,'EDITAR')?'flex':'none' ) } 
            }}
            cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',} }}
            visible={this.state.modal2} zIndex={10}
            onOk={this.submit2}
            onCancel={() => this.closeModal('modal2',false,'formularegistroempresa2')}
            className="miAntModal miconfigModal alined" >
            <form id="formularegistroempresa2">
              <div className="miconfigDiv nomgtp" style={{position:'relative'}} >
              {
                !this.props.validCurrentUser(this.context,'redes','bool',false,'EDITAR')&&
                <div className='fakemask'
                   title='El perfil actual no tiene permisos para editar las Redes sociales.'
                   onClick={()=>this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar las Redes sociales.'})} />
              }

                <div className="inputs-group">
                  <label className="inputs-label" id="facebookd" > Facebook </label>
                  <input type="text" defaultValue={this.state.datos.FACEBOOK} className="inputs-input" name="facebook" placeholder="https://www.facebook.com/(opcional)" id="facebookd" />
                </div>
                
                <div className="inputs-group">
                  <label className="inputs-label" htmlFor="instagramd" > Instagram </label>
                  <input type="text" defaultValue={this.state.datos.INSTAGRAM} className="inputs-input" name="instagram" placeholder="https://www.instagram.com/(opcional)" id="instagramd"  />
                </div>

                <div className="inputs-group">
                  <label className="inputs-label" htmlFor="pagewebd" > Página web </label>
                  <input type="text" defaultValue={this.state.datos.PAGINAWEB} className="inputs-input" name="pageweb" placeholder="Página web" id="pagewebd" />
                </div>

              </div>  
              </form>
          </Modal>


          
          <Modal centered title="Datos de acceso" closable={false} maskClosable={false} keyboard={false} forceRender={true}
            okText="Guardar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
            cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',} }}
            visible={this.state.modal3} zIndex={10}            
            onOk={this.submit3} className="miAntModal miconfigModal alined"
            onCancel={() => this.closeModal('modal3',false,'formularegistroempresa3')} >
            <form id="formularegistroempresa3">
              <div className="miconfigDiv nomgtp">
                <div className="fechaSexo">

                  <div className="inputs-group inputs-group-inline">
                    <label className="inputs-label" htmlFor="nombred" > Nombre(s) </label>
                    <input type="text" defaultValue={this.state.datos.NOMBRE_CONTACTO} className="inputs-input" name="nombre" id="nombred" />
                  </div>

                  <div className="inputs-group inputs-group-inline">
                    <label className="inputs-label" htmlFor="apellidosd" > Apellido(s) </label>
                    <input type="text" defaultValue={this.state.datos.APELLIDO_CONTACTO} className="inputs-input" name="apellidos" id="apellidosd" />
                  </div>

                </div>

                <div className="inputs-group">
                  <label className="inputs-label" htmlFor="cargod" > Cargo desempeñado en la empresa </label>
                  <input type="text" defaultValue={this.state.datos.CARGO_DESEMPENIADO} className="inputs-input" name="cargo" id="cargod" />
                </div>

                <div className="inputs-group">
                  <label className="inputs-label" htmlFor="telefonod" > Teléfono </label>
                  <NumberFormat format="(###) ### ####" value={this.state.datos.TELEFONO_CONTACTO} mask="_" type="text" className="inputs-input" name="telefono" id="telefonod"  />
                </div>

                <div className="inputs-group">
                  <label className="inputs-label" htmlFor="maild" > Correo electrónico </label>
                  <input type="text" defaultValue={this.state.datos.CORREO_REGISTRO} readOnly className="inputs-input" name="mail" id="maild" />
                </div>

                <div className="flexcentercenter" id="labelPsswrd1" style={{color:'#F07505',cursor:'pointer'}} >
                  <span onClick={ () => this.showModalContrasena(1) } > ¿Cambiar contraseña? </span>                
                </div>

                <div id="bloquePsswrd1" style={{display:'none'}} >

                  <div className="inputs-group">
                    <label className="inputs-label" htmlFor="contrad" > Contraseña </label>
                      {
                          !this.state.pass?
                          <Icon onClick={this.showPassword} type="eye-invisible" theme="twoTone" style={{fontSize:'20px'}} className="iconSee" twoToneColor="#DC730E" />:
                          <Icon onClick={this.showPassword} type="eye" style={{fontSize:'20px'}} className="iconSee"/>
                      }
                      <input type={this.state.pass?'password':'text'} defaultValue={this.state.datos.CONTRASENA} className="inputs-input" name="contra" id="contrad" />
                  </div>

                  <div className="inputs-group">
                    <label className="inputs-label" htmlFor="contra2d"> Confirmar contraseña </label>
                    <input type={this.state.pass?'password':'text'} defaultValue={this.state.datos.CONTRASENA} className="inputs-input" name="contra2" id="contra2d" />
                  </div>

                </div>

              </div>  
            </form>

          </Modal>

          <Modal centered title="Datos de acceso" closable={false} maskClosable={false} keyboard={false}
            okText="Guardar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
            cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',} }}
            visible={this.state.modalDatosUsuario} zIndex={10} destroyOnClose
            onOk={this.saveDatosUsuario} className="miAntModal miconfigModal alined"
            onCancel={() => this.setState({modalDatosUsuario:false}) } >
            <form id="formulaUser">
              <div className="miconfigDiv nomgtp">

                <div className="inputs-group inputs-group-inline">
                  <label className="inputs-label" htmlFor="nombred" > Nombre(s) </label>
                  <input type="text" name="nombre" className="inputs-input" maxLength={50} defaultValue={this.state.datosU.nombre} />
                </div>

                <div className="inputs-group inputs-group-inline">
                  <label className="inputs-label" htmlFor="apellidosd" > Apellido(s) </label>
                  <input type="text" name="apellido" className="inputs-input" maxLength={30} defaultValue={this.state.datosU.apellido} />
                </div>

                <div className="inputs-group">
                  <label className="inputs-label" htmlFor="cargod" > Cargo desempeñado en la empresa </label>
                  <input type="text" name="cargo" className="inputs-input" maxLength={20} defaultValue={this.state.datosU.cargo} />
                </div>

                <div className="inputs-group">
                  <label className="inputs-label" htmlFor="telefonod" > Teléfono </label>
                  <NumberFormat type="text" name="telefono" format="(###) ### ####" mask="_" className="inputs-input" defaultValue={this.state.datosU.telefono } />
                </div>

                <div className="inputs-group">
                  <label className="inputs-label" htmlFor="maild" > Correo electrónico </label>
                  <input type="text" name="correo" className="inputs-input" maxLength={40} defaultValue={this.state.datosU.correo} readOnly />
                </div>

                <div className="flexcentercenter" id="labelPsswrd2" style={{color:'#F07505',cursor:'pointer'}} >
                  <span onClick={ () => this.showModalContrasena(2) } > ¿Cambiar contraseña? </span>                
                </div>

                <div id="bloquePsswrd2" style={{display:'none'}} >

                  <div className="inputs-group">
                    <label className="inputs-label" htmlFor="contrad" > Nueva contraseña </label>
                      {
                          !this.state.pass?
                          <Icon onClick={this.showPassword} type="eye-invisible" theme="twoTone" style={{fontSize:'20px'}} className="iconSee" twoToneColor="#DC730E" />:
                          <Icon onClick={this.showPassword} type="eye" style={{fontSize:'20px'}} className="iconSee"/>
                      }
                      <input type={this.state.pass?'password':'text'} defaultValue={this.state.datosU.contra} className="inputs-input" name="contra" id="contrad2" maxLength={12} />
                  </div>

                  <div className="inputs-group">
                    <label className="inputs-label" > Confirmar contraseña </label>
                    <input type={this.state.pass?'password':'text'} defaultValue={this.state.datosU.contra} className="inputs-input" name="contra2" id="contra2d2"maxLength={12}  />
                  </div>

                </div>

              </div>  
            </form>

          </Modal>

          <Modal centered title='Cambiar contraseña' maskClosable={false} keyboard = {false} 
            okText="Aceptar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
            cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
            visible={this.state.modalContrasena} className="miAntModal alertMy modalUnidad" width={400}
            onOk={this.submitContrasena} destroyOnClose
            onCancel={()=>this.setState({modalContrasena:false})} >

            <div className="miUniDiv">
                            
              <div className="inputs-group">
                <label className="inputs-label" style={{top:'-10px'}} > Ingrese su contraseña actual </label>
                <Input.Password type="password" autoFocus className="inputs-input" id="contraseniad" />
              </div>

            </div>

          </Modal>

          <Modal centered title="Configuración de citas" closable={false} maskClosable={false} keyboard = {false} zIndex={10}
            okText="Cerrar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }} cancelButtonProps={{ style: { display:'none',} }}
            visible={this.state.modalListaConfiguraciones}
            onOk={() => this.setState({modalListaConfiguraciones:false})}
            className="miAntModal miconfigModal miconfigModalCitas" >
            <div className="listaConfiguraciones">
              {
                this.state.vectorCitas.length===0?
                <div className="nonResult flexcenter Fspaceb" style={{flexDirection:'column'}} >
                  <img alt="..." src={ImgPanel+'noncitas.png'} height={350} />
                  <h3 style={{marginTop:'10'}} > ¡No se encontraron resultados! </h3>
                </div>
                :
                this.state.vectorCitas.map((sucursal,index) => (
                  <div className="itemSucursal" key={'sucur_'+sucursal.ID} onClick={()=>this.showModalNewConfiguracion(index)} >
                    <label className="title">
                      {sucursal['NOMBRE_SUCURSAL']}
                      <img src={img+'basurero_gris.png'} onClick={(e) =>  this.modalEliminarSucursal(e,sucursal)} alt="..." title='Eliminar registro' />
                    </label>

                    <div>
                      <label className="meta"> Duración de la cita </label>
                      <label className="data"> {sucursal['dCitas']} minutos </label>
                    </div>

                    <div>
                      <label className="meta"> Intervalo entre citas </label>
                      <label className="data"> {sucursal['iCitas']} minutos </label>
                    </div>
                  </div>
                ))
              }
            </div>
            {
              (
                //this.state.sucursales.length>this.state.vectorCitas.length&&
                this.atLeatsOne()
              )&&
              <label className="newItem" onClick={()=>this.showModalNewConfiguracion(-1)}> Agregar configuración </label>
            }
          </Modal>

          <Modal centered title={<span className="flexcenter Fcenter" style={{paddingBottom:'25px'}} > Detalle de configuración</span>} closable={false}  maskClosable={false} keyboard = {false} forceRender={true}
            okText="Guardar"
            okButtonProps={{ className:'btn btn1',
              style: { width:'120px', display:((this.state.idEditando === -1 || this.props.validCurrentUser(this.context,'citasDATA','bool',this.state.citas['CODE'],'EDITAR'))?'flex':'none' ) } 
            }}
            cancelText="Cancelar"
            cancelButtonProps={{ className:'btn btn2' , 
              style: { width:'120px', marginTop:((this.state.idEditando === -1 || this.props.validCurrentUser(this.context,'citasDATA','bool',this.state.citas['CODE'],'EDITAR'))?'initial':'20px' ) }
            }}

            visible={this.state.modalDetalleCita} 
            onOk={this.addConfig}
            onCancel={this.closeModalNewConfiguracion}
            className="miAntModal miconfigModal alined miconfigModalCitas miconfigModalCitasMg" >
            <div className="miconfigDiv nomgtp" style={{position:'relative'}} >

              {
                this.state.idEditando === -1 ?
                <React.Fragment>
                <span className="tititle"  > Elije la sucursal en la que esta configuración estará disponible: </span>
                <Select style={{width:'100%',marginBottom:'10px'}} value={this.state.idSucursalActual} onChange={this.changeIdSucursalId} >
                {
                  this.state.sucursales.filter( s => !s['conCitas'] && this.props.validCurrentUser(this.context,'citasDATA','bool',s['CODE'],'CREAR') )
                  .map((sucursal,indice)=>(
                    <Select.Option value={sucursal['ID']} key={'selector_'+indice}> {sucursal.NOMBRE} </Select.Option>
                  ))
                }
                </Select>
                </React.Fragment>:
                <React.Fragment>
                <Input className="inputs-input" style={{margin:'30px auto 20px auto'}} value={this.state.citas['NOMBRE_SUCURSAL']}  readOnly />
                {
                  !this.props.validCurrentUser(this.context,'citasDATA','bool',this.state.citas['CODE'],'EDITAR')&&
                  <div className='fakemask'
                    title='El perfil actual no tiene permisos para editar la Configuración de Citas seleccionada.'
                    onClick={()=>this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar la Configuración de Citas seleccionada'})} />
                }
                </React.Fragment>

              }
              <div className="selectorMinutos">
                
                <div className="selector">
                  <label> Duración de la cita (en minutos) </label>
                  <NumberFormat maxLength={4} allowNegative={false} className="inputs-input" type="text" id="duracionCita" />
                </div>
                
                <div className="selector">
                  <label> Intervalo entre citas (en minutos) </label>
                  <NumberFormat maxLength={4} allowNegative={false} className="inputs-input" type="text" id="intervaloCita" />
                </div>
              </div>
              <div className="contentModalMapa">

                <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                        
                  <label className="inputs-label" >
                    {this.state.semaforo===1?'Lunes a Viernes':'Lunes'}
                      <div className="contSwitch">
                        <div className="panelAux" id="mibandera" onClick={this.controlSemanal} ></div>
                        <Switch id="swSlave1" disabled={ this.state.deshabilitado } checked={this.state.citas['HORARIOS'][0]['ESTATUS']===1}  className="myswitch" onChange={ (checked) => this.changeSwitch2(0,checked)} />
                      </div>
                  </label>

                  <div className="mytimers" style={{display:this.state.citas['HORARIOS'][0]['ESTATUS']===1?'flex':'none'}} >
                    <TimePicker className="myTimer" allowClear={false} placeholder="Desde" value={this.state.citas['HORARIOS'][0]['MOMENT_INICIO']} onChange={ (value,svalue) => this.horaChange(value,svalue,0,1) } format={'HH:mm'} />
                    <TimePicker className="myTimer" allowClear={false} placeholder="Hasta" value={this.state.citas['HORARIOS'][0]['MOMENT_TERMINO']} onChange={ (value,svalue) => this.horaChange(value,svalue,0,2) } format={'HH:mm'} />
                  </div>
                  {
                    this.state.citas['HORARIOS'][0]['ESTATUS']===1?
                    <div className="timerhijos">
                      <div className="title"> <span> Horas no disponible </span> <span className="spanAdd" onClick={() => this.showNewHijo(-1,0)} >+ Agregar</span> </div>
                      {
                        this.state.citas['HORARIOS'][0]['HIJOS'].map((hijo,index) =>(
                          hijo['ESTATUS']===2?<React.Fragment/>:<div className="itemHorarioHijo" key={'hijo0_'+index}> <span onClick={()=>this.showNewHijo(index,0)} title="Editar registro" > {hijo['labelInicio']} - {hijo['labelFin']} </span> <img onClick={()=>this.setState({modalEliminarHijo:true,indexCurrHijo:index,indexCurrPadre:0})} alt="..." src={img+'basurero_gris.png'} title="Eliminar registro"/> </div>
                        ))
                      }
                      {this.state.citas['HORARIOS'][0]['HIJOS'].length>0? <div> <span className="spanAdd" onClick={()=>this.setState({modalCopyAll:true})} >Asignar a todos los días</span> </div>:<React.Fragment/>}
                    </div>:<React.Fragment/>
                  }

                </div>

                {/*martes*/}
                <div className="inputs-group inputs-groupSwitch" style={{display:this.state.semaforo===1?'none':'block'}}  onClick={this.jumpClick} >
                    
                    <label className="inputs-label">
                      Martes
                      <Switch id="swSlave2" className="myswitch" checked={this.state.citas['HORARIOS'][1]['ESTATUS']===1} onChange={ (checked,event) => this.changeSwitch2(1,checked)}/>
                    </label>
                    
                    <div className="mytimers" style={{display:this.state.citas['HORARIOS'][1]['ESTATUS']===1?'flex':'none'}} >
                      <TimePicker className="myTimer" allowClear={false} placeholder="Desde" value={this.state.citas['HORARIOS'][1]['MOMENT_INICIO']} onChange={ (value,svalue) => this.horaChange(value,svalue,1,1) } format={'HH:mm'} />
                      <TimePicker className="myTimer" allowClear={false} placeholder="Hasta" value={this.state.citas['HORARIOS'][1]['MOMENT_TERMINO']} onChange={ (value,svalue) => this.horaChange(value,svalue,1,2) } format={'HH:mm'} />
                    </div>

                    {
                        this.state.citas['HORARIOS'][1]['ESTATUS']===1?
                        <div className="timerhijos">
                          <div className="title"> <span> Horas no disponible </span> <span className="spanAdd" onClick={() => this.showNewHijo(-1,1)}>+ Agregar</span> </div>
                          {
                            this.state.citas['HORARIOS'][1]['HIJOS'].map((hijo,index) =>(
                              hijo['ESTATUS']===2?<React.Fragment/>:<div className="itemHorarioHijo" key={'hijo1_'+index}> <span onClick={()=>this.showNewHijo(index,1)} title="Editar registro" > {hijo['labelInicio']} - {hijo['labelFin']} </span> <img alt="..." onClick={()=>this.setState({modalEliminarHijo:true,indexCurrHijo:index,indexCurrPadre:1})} src={img+'basurero_gris.png'} title="Eliminar registro"/> </div>
                            ))
                          }
                        </div>:<React.Fragment/>
                      }
                </div>
                
                {/*miercoles*/}
                <div className="inputs-group inputs-groupSwitch" style={{display:this.state.semaforo===1?'none':'block'}} onClick={this.jumpClick} >
                    
                    <label className="inputs-label">
                      Miércoles
                      <Switch id="swSlave3"  className="myswitch" checked={this.state.citas['HORARIOS'][2]['ESTATUS']===1} onChange={ (checked,event) => this.changeSwitch2(2,checked)}/>
                    </label>
                    
                    <div className="mytimers" style={{display:this.state.citas['HORARIOS'][2]['ESTATUS']===1?'flex':'none'}} >
                      <TimePicker className="myTimer" allowClear={false} placeholder="Desde" value={this.state.citas['HORARIOS'][2]['MOMENT_INICIO']} onChange={ (value,svalue) => this.horaChange(value,svalue,2,1) } format={'HH:mm'} />
                      <TimePicker className="myTimer" allowClear={false} placeholder="Hasta" value={this.state.citas['HORARIOS'][2]['MOMENT_TERMINO']} onChange={ (value,svalue) => this.horaChange(value,svalue,2,2) } format={'HH:mm'} />
                    </div>

                    {
                        this.state.citas['HORARIOS'][2]['ESTATUS']===1?
                        <div className="timerhijos">
                          <div className="title"> <span> Horas no disponible </span> <span className="spanAdd" onClick={() => this.showNewHijo(-1,2)} >+ Agregar</span> </div>
                          {
                            this.state.citas['HORARIOS'][2]['HIJOS'].map((hijo,index) =>(
                              hijo['ESTATUS']===2?<React.Fragment/>:<div className="itemHorarioHijo" key={'hijo2_'+index}> <span onClick={()=>this.showNewHijo(index,2)} title="Editar registro" > {hijo['labelInicio']} - {hijo['labelFin']} </span> <img alt="..." onClick={()=>this.setState({modalEliminarHijo:true,indexCurrHijo:index,indexCurrPadre:2})} src={img+'basurero_gris.png'} title="Eliminar registro"/> </div>
                            ))
                          }
                        </div>:<React.Fragment/>
                      }
                </div>
                
                {/*jueves*/}
                <div className="inputs-group inputs-groupSwitch" style={{display:this.state.semaforo===1?'none':'block'}} onClick={this.jumpClick} >
                      
                      <label className="inputs-label">
                        Jueves
                        <Switch id="swSlave4" className="myswitch" checked={this.state.citas['HORARIOS'][3]['ESTATUS']===1} onChange={ (checked,event) => this.changeSwitch2(3,checked)}/>
                      </label>
                      
                      <div className="mytimers" style={{display:this.state.citas['HORARIOS'][3]['ESTATUS']===1?'flex':'none'}} >
                        <TimePicker className="myTimer" allowClear={false} placeholder="Desde" value={this.state.citas['HORARIOS'][3]['MOMENT_INICIO']} onChange={ (value,svalue) => this.horaChange(value,svalue,3,1) } format={'HH:mm'} />
                        <TimePicker className="myTimer" allowClear={false} placeholder="Hasta" value={this.state.citas['HORARIOS'][3]['MOMENT_TERMINO']} onChange={ (value,svalue) => this.horaChange(value,svalue,3,2) } format={'HH:mm'} />
                      </div>

                      {
                        this.state.citas['HORARIOS'][3]['ESTATUS']===1?
                        <div className="timerhijos">
                          <div className="title"> <span> Horas no disponible </span> <span className="spanAdd" onClick={() => this.showNewHijo(-1,3)}>+ Agregar</span> </div>
                          {
                            this.state.citas['HORARIOS'][3]['HIJOS'].map((hijo,index) =>(
                              hijo['ESTATUS']===2?<React.Fragment/>:<div className="itemHorarioHijo" key={'hijo3_'+index}> <span onClick={()=>this.showNewHijo(index,3)} title="Editar registro" > {hijo['labelInicio']} - {hijo['labelFin']} </span> <img alt="..." onClick={()=>this.setState({modalEliminarHijo:true,indexCurrHijo:index,indexCurrPadre:3})} src={img+'basurero_gris.png'} title="Eliminar registro"/> </div>
                            ))
                          }
                        </div>:<React.Fragment/>
                      }
                </div>

                {/*viernes*/}
                <div className="inputs-group inputs-groupSwitch" style={{display:this.state.semaforo===1?'none':'block'}} onClick={this.jumpClick} >
                      
                      <label className="inputs-label">
                        Viernes
                        <Switch id="swSlave5" className="myswitch" checked={this.state.citas['HORARIOS'][4]['ESTATUS']===1} onChange={ (checked,event) => this.changeSwitch2(4,checked)}/>
                      </label>
                      
                      <div className="mytimers" style={{display:this.state.citas['HORARIOS'][4]['ESTATUS']===1?'flex':'none'}} >
                        <TimePicker className="myTimer" allowClear={false} placeholder="Desde" value={this.state.citas['HORARIOS'][4]['MOMENT_INICIO']} onChange={ (value,svalue) => this.horaChange(value,svalue,4,1) } format={'HH:mm'} />
                        <TimePicker className="myTimer" allowClear={false} placeholder="Hasta" value={this.state.citas['HORARIOS'][4]['MOMENT_TERMINO']} onChange={ (value,svalue) => this.horaChange(value,svalue,4,2) } format={'HH:mm'} />
                      </div>

                      {
                        this.state.citas['HORARIOS'][4]['ESTATUS']===1?
                        <div className="timerhijos">
                          <div className="title"> <span> Horas no disponible </span> <span className="spanAdd" onClick={() => this.showNewHijo(-1,4)}>+ Agregar</span> </div>
                          {
                            this.state.citas['HORARIOS'][4]['HIJOS'].map((hijo,index) =>(
                              hijo['ESTATUS']===2?<React.Fragment/>:<div className="itemHorarioHijo" key={'hijo4_'+index}> <span onClick={()=>this.showNewHijo(index,4)} title="Editar registro" > {hijo['labelInicio']} - {hijo['labelFin']} </span> <img alt="..." onClick={()=>this.setState({modalEliminarHijo:true,indexCurrHijo:index,indexCurrPadre:4})} src={img+'basurero_gris.png'} title="Eliminar registro"/> </div>
                            ))
                          }
                        </div>:<React.Fragment/>
                      }
                </div>

                {/*sabado*/}
                <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                      
                      <label className="inputs-label">
                        Sábado
                        <Switch id="swSlave6" className="myswitch" checked={this.state.citas['HORARIOS'][5]['ESTATUS']===1} onChange={ (checked,event) => this.changeSwitch2(5,checked)}/>
                      </label>
                      
                      <div className="mytimers" style={{display:this.state.citas['HORARIOS'][5]['ESTATUS']===1?'flex':'none'}} >
                        <TimePicker className="myTimer" allowClear={false} placeholder="Desde" value={this.state.citas['HORARIOS'][5]['MOMENT_INICIO']} onChange={ (value,svalue) => this.horaChange(value,svalue,5,1) } format={'HH:mm'} />
                        <TimePicker className="myTimer" allowClear={false} placeholder="Hasta" value={this.state.citas['HORARIOS'][5]['MOMENT_TERMINO']} onChange={ (value,svalue) => this.horaChange(value,svalue,5,2) } format={'HH:mm'} />
                      </div>

                      {
                        this.state.citas['HORARIOS'][5]['ESTATUS']===1?
                        <div className="timerhijos">
                          <div className="title"> <span> Horas no disponible </span> <span className="spanAdd" onClick={() => this.showNewHijo(-1,5)}>+ Agregar</span> </div>
                          {
                            this.state.citas['HORARIOS'][5]['HIJOS'].map((hijo,index) =>(
                              hijo['ESTATUS']===2?<React.Fragment/>:<div className="itemHorarioHijo" key={'hijo5_'+index}> <span onClick={()=>this.showNewHijo(index,5)} title="Editar registro" > {hijo['labelInicio']} - {hijo['labelFin']} </span> <img alt="..." onClick={()=>this.setState({modalEliminarHijo:true,indexCurrHijo:index,indexCurrPadre:5})} src={img+'basurero_gris.png'} title="Eliminar registro"/> </div>
                            ))
                          }
                        </div>:<React.Fragment/>
                      }
                </div>

                {/*domingo*/}
                <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                      
                      <label className="inputs-label">
                        Domingo
                        <Switch id="swSlave7" className="myswitch" checked={this.state.citas['HORARIOS'][6]['ESTATUS']===1} onChange={ (checked,event) => this.changeSwitch2(6,checked)}/>
                      </label>
                      
                      <div className="mytimers" style={{display:this.state.citas['HORARIOS'][6]['ESTATUS']===1?'flex':'none'}} >
                        <TimePicker className="myTimer" allowClear={false} placeholder="Desde" value={this.state.citas['HORARIOS'][6]['MOMENT_INICIO']} onChange={ (value,svalue) => this.horaChange(value,svalue,6,1) } format={'HH:mm'} />
                        <TimePicker className="myTimer" allowClear={false} placeholder="Hasta" value={this.state.citas['HORARIOS'][6]['MOMENT_TERMINO']} onChange={ (value,svalue) => this.horaChange(value,svalue,6,2) } format={'HH:mm'} />
                      </div>

                      {
                        this.state.citas['HORARIOS'][6]['ESTATUS']===1?
                        <div className="timerhijos">
                          <div className="title"> <span> Horas no disponible </span> <span className="spanAdd" onClick={() => this.showNewHijo(-1,6)}>+ Agregar</span> </div>
                          {
                            this.state.citas['HORARIOS'][6]['HIJOS'].map((hijo,index) =>(
                              hijo['ESTATUS']===2?<React.Fragment/>:<div className="itemHorarioHijo" key={'hijo6_'+index}> <span onClick={()=>this.showNewHijo(index,6)} title="Editar registro" > {hijo['labelInicio']} - {hijo['labelFin']} </span> <img alt="..." onClick={()=>this.setState({modalEliminarHijo:true,indexCurrHijo:index,indexCurrPadre:6})} src={img+'basurero_gris.png'} title="Eliminar registro"/> </div>
                            ))
                          }
                        </div>:<React.Fragment/>
                      }
                </div>

              </div>
            </div>
          </Modal>

         {/* motivo cancelacion */}
          <Modal centered title="" header={null} footer={null} zIndex={5} onCancel={()=>this.setState({cancelanding:false}) }
            visible={this.state.cancelanding} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
            <div className="controlesNuevaCita">
              <div className="title"> Motivo de cancelación </div>
              <div className="data flexcenter Fspaceb">
                <label> Ingrese un motivo de cancelación para sus citas confirmadas </label>
              </div>
              <Input.TextArea className={"inputs-input"} id="motivoCancel" maxLength={50} autoSize={{minRows:1,maxRows:4}} />
              <button style={{marginTop:'25px'}} className="flexcenter Fcenter btnAdd" onClick={this.clickCancelar} > Aceptar </button>
            </div>
          </Modal>

          <Modal centered title="Horario no disponible" maskClosable={false} keyboard = {false} 
            okText="Guardar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
            cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',} }}
            visible={this.state.modalHorarioHijo}
            closable={false}
            onOk={this.appendHorarioHijo}
            onCancel={() => this.setState({modalHorarioHijo:false,indexCurrPadre:-1,})}
            className="miAntModal miconfigModal miconfigModalCitas miconfigModalCitasMg" >
            <div className="contentModalMapa">
              <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                  <label className="inputs-label"> Especifica el horario </label>

                  <div className="flexcenter Fspaceb" style={{margin:'10px auto'}} >
                    <label className="inputs-label" style={{width:'45%'}} > Desde: </label>
                    <label className="inputs-label" style={{width:'45%'}} > Hasta: </label>
                  </div>

                  <div className="mytimers">
                    <TimePicker className="myTimer" allowClear={false} placeholder="Desde" value={this.state.empiezaAux} onChange={ (value) => this.setState({empiezaAux:value}) } format={'HH:mm'} />
                    <TimePicker className="myTimer" allowClear={false} placeholder="Hasta" value={this.state.terminaAux} onChange={ (value) => this.setState({terminaAux:value}) } format={'HH:mm'} />
                  </div>

              </div>
            </div>
          </Modal>

          <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
              okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
              cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
              visible={this.state.modalEliminarHijo}
              closable={false}
              onOk={() => this.removeHijo() }
              onCancel={()=>this.setState({modalEliminarHijo:false,indexCurrPadre:-1,indexCurrHijo:-1}) }
              className="miAntModal newVar alertMy"
              >
              <div className="myAlertBody">
                  {
                      this.state.modalEliminarHijo   ?
                      <span className="txtAlert"> ¿Está seguro que desea eliminar este horario de la configuración? </span>:
                      <React.Fragment/>
                  }
              </div>
          </Modal>

          <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
              okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
              cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
              visible={this.state.modalCopyAll}
              closable={false}
              onOk={() => this.setAllAsLunes() }
              onCancel={()=>this.setState({modalCopyAll:false}) }
              className="miAntModal newVar alertMy"
              >
              <div className="myAlertBody">
                  {
                      this.state.modalCopyAll   ?
                      <span className="txtAlert"> ¿Está seguro que desea establecer estos horarios para todos los días? </span>:
                      <React.Fragment/>
                  }
              </div>
          </Modal>

          <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
              okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
              cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
              visible={this.state.modalEliminarSucursal}
              closable={false}
              onOk={this.elimiarSucursalDeConfigCitas}
              onCancel={()=>this.setState({modalEliminarSucursal:false,idEliminar:-1})}
              className="miAntModal newVar alertMy"
              >
              <div className="myAlertBody">
                  {
                      this.state.modalEliminarSucursal   ?
                      <span className="txtAlert"> ¿Está seguro que desea eliminar esta sucursal de la configuración? </span>:
                      <React.Fragment/>
                  }
              </div>
          </Modal>

          <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
              okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
              cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
              visible={this.state.modalConfirmDisable}
              closable={false}
              onOk={() => this.eliminarAllConfig('') }
              onCancel={()=>this.setState({modalConfirmDisable:false,})}
              className="miAntModal newVar alertMy"
              >
              <div className="myAlertBody">
                  {
                      this.state.modalConfirmDisable   ?
                      <span className="txtAlert">Si deshabilita esta opción, se eliminarán todas las citas y configuraciones guardadas. Esta operación no se puede revertir. ¿Desea continuar?</span>:
                      <React.Fragment/>
                  }
              </div>
          </Modal>

          <ModalAlert visible={this.state.modalAlert} text={this.state.txtAlert} onOk={()=>this.setState({modalAlert:false})} />

          <ModalAlert visible={this.state.modalConfirmChangeSucursal}
            text="Si cambia la sucursal perderá los cambios que haya realizado, ¿Desea continuar?"
            withCancel
            onOk={this.changeSucursalIdConfirm}
            onCancel={()=>this.setState({modalConfirmChangeSucursal:false,newIdSucursalActual:-1})}
          />

          <Modal centered title="¿Estás seguro que deseas deshabilitar tu cuenta?" maskClosable={false} keyboard = {false} 
              okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
              cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
              visible={this.state.modalConfirmInhabilitarCuenta}
              closable={false}
              onOk={this.eliinacionConfirmadaCuenta}
              onCancel={() => this.setState({modalConfirmInhabilitarCuenta:false})}
              className="miAntModal newVar alertMy" >
              <div className="myAlertBody">
                {
                  this.state.modalConfirmInhabilitarCuenta   &&
                  <span className="txtAlert" style={{textAlign:'justify',padding:'5px'}} >
                    Todas tus citas confirmadas serán canceladas y tu perfil será invisible hasta que habilites tu cuenta. Esta acción no se podrá revertir.
                  </span>
                }
              </div>
          </Modal>

          <Modal centered title="¿Estás seguro que deseas eliminar tu cuenta?" maskClosable={false} keyboard = {false} 
              okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
              cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
              visible={this.state.modalConfirmEliminarCuenta}
              closable={false}
              onOk={this.eliinacionConfirmadaCuenta}
              onCancel={() => this.setState({modalConfirmEliminarCuenta:false})}
              className="miAntModal newVar alertMy"
              >
              <div className="myAlertBody">
                {
                  this.state.modalConfirmEliminarCuenta &&
                  <span className="txtAlert" style={{textAlign:'justify',padding:'5px'}} >
                    Tu cuenta se eliminará en un periodo de 30 días. Para reactivarla deberás iniciar sesión durante ese tiempo, de lo contrario se eliminará permanentemente. Si continuas, todas tus citas confirmadas serán canceladas desde este momento. Esta acción no se podrá revertir.
                  </span>
                }
              </div>
          </Modal>

          <Modal centered title="Agregar descripción"
              okText="Guardar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
              cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
              visible={this.state.addingdEscrip} destroyOnClose width={400}
              //closable={false} maskClosable={false} keyboard = {false} 
              className="miAntModal alertMy modalUnidad"
              onCancel={()=>this.setState({addingdEscrip:false})}
              onOk={this.addDescripcion} zIndex={5} >
              <div className="miUniDiv" style={{margin:'-30px -10px',height:'auto'}} >
                <div className="inputs-group" style={{marginTop:'40px',marginBottom:'5px',height:'auto'}}>
                  <label className="inputs-label" htmlFor="reportd" style={{top:'-10px'}} > Descripción de la empresa </label>
                  <Input.TextArea
                    className="inputs-input"
                    defaultValue={this.state.datos.DESCRIPCION}
                    id="descripcionDES" maxLength={500}
                    autoSize={{minRows:1,maxRows:4}}
                    />
                </div>
              </div>
          </Modal>


          <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
              okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
              cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',display:'none'} }}
              visible={this.state.modalCuentaEliminada}
              closable={false}
              onOk={this.cerrarSesion}
              className="miAntModal newVar alertMy"
              >
              <div className="myAlertBody" style={{textAlign:'justify',padding:'5px'}} >
                  {
                      this.state.modalCuentaEliminada &&
                      <span className="txtAlert"> Se ha programado tu cuenta para eliminarse el día {moment().add(30,'days').format('DD MMMM YYYY')}. Recuerda que puedes reactivarla iniciando sesión.</span>
                  }
              </div>
          </Modal>

         <div className="cont-sucursales2" >

          <div className="cont-sucursales3">

            <Loading loading={this.state.loading} /> 

            <div>
              <div className="configHeadWrap">

                <div className="headPortada">
                  <input type="file" onChange={this.changeFile} id="filePortada" accept="image/*" />

                  <div className="cont-Cropper" id="myCroper" style={{display:'none'}} title="Usa el scroll para hacer zoom" >
                    <Cropper
                      ref={element => this.cropper = element}
                      src=''
                      style={{height:'100%', width: '100%',zIndex:'5'}}
                      aspectRatio={1024 / 379}
                      />
                      
                      <div className="opsImg">
                        <img onClick={() => this.getImage(1) } src={img+'Palomita.png'} alt="..." style={{marginRight:'5px'}} />
                        <img onClick={() => this.cancelImage(1) } src={img+'cross_icon.png'} alt="..." />
                      </div>
                      
                  </div>

                  <div className="contPortada">
                    <img className="srcPortada" id="srcPortada"
                      src={ this.state.dataPortada !== '0' ? this.state.dataPortada : 
                            this.state.datos.DIRECCION_IMG_PORTADA=== "" ?
                             ImgPanel+'portadalDefault.jpg' : 
                             this.state.folder+'/Perfil/'+this.state.datos.DIRECCION_IMG_PORTADA+'.png?'+Math.random() }
                      alt="..."
                      />
                  </div>

                  <label htmlFor="filePortada"><img className="inputNewPortada" htmlFor="filePortada" src={img+'new_camera_icon.png'} alt="..." /></label>
                </div>
              </div>
            </div>

            <div className="configFootWrap" >
              <div className="footPortada">

                <div className="opsImg" id="myCroper2But" style={{display:'none'}} >
                  <img onClick={() => this.getImage(2) } src={img+'Palomita.png'} alt="..." />
                  <img onClick={() => this.cancelImage(2) } src={img+'cross_icon.png'} alt="..." />
                </div>

                <input type="file" onChange={this.changeFile2} id="filePerfil" accept="image/*" />

                <div className="cont-Cropper" id="myCroper2" style={{display:'none'}} >
                  <Cropper
                    ref={element => this.cropper2 = element}
                    src=''
                    style={{height:'100%', width: '100%',zIndex:'5'}}
                    aspectRatio={ 1/1 }
                    />                  
                </div>

                <div className="contPerfil">
                  <img className="srcPerfil" id="srcPerfil"
                    src={
                      this.state.dataPerfil !== '0' ? this.state.dataPerfil : 
                      this.state.datos.DIRECCION_IMG_PERFIL===''?ImgPanel+'perfilDefault.jpg':this.state.folder+'/Perfil/'+this.state.datos.DIRECCION_IMG_PERFIL+'.png?'+Math.random()}
                      alt="..."
                    />
                  
                  <label htmlFor="filePerfil"> <img className="inputNewPerfil" src={img+'new_camera_icon.png'} alt="..."/> </label>

                </div>

              </div>
              
              <div className="infoFootEmpresa">
                <label className="nombreEmpresa flexcenter Fcenter" > {this.state.datos.NOMBRE_COMERCIAL} </label>
                
                {
                  this.state.datos.DESCRIPCION.trim().length===0?
                    <label className="flexcenter Fcenter" style={{color:"#F07505",cursor:'pointer',padding:'0px 10px'}} onClick={()=>this.setState({addingdEscrip:true})} > Agregar descripción </label>:
                    <div className="flexcenter Fcenter" title={this.state.datos.DESCRIPCION}>
                      <label className="descripcionEmpresa" >{this.state.datos.DESCRIPCION}</label>
                      <Icon type="edit" onClick={()=>this.setState({addingdEscrip:true})} />
                    </div>
                }

              </div>

            </div>



            <div className="cont-optionCard" >

              <div className="optionCard" style={{width:'100%'}} >
                
                <div className="cardHead">
                  <label className="cardTitle"> Configuración general </label>
                </div>

                <div className="cardBody" >
                  
                  <div className="cardBodyOption" onClick={()=> this.setState({modal1:true,abri1:true})} >
                    <label className="" > Información general </label>
                    <Icon type="right" />
                  </div>

                  <div className="cardBodyOption" onClick={()=> this.setState({modal2:true})} >
                    <label className="" > Redes sociales </label>
                    <Icon type="right" />
                  </div>

                  <div className="cardBodyOption" onClick={this.showModalDatosAcceso} >
                    <label className=""> Datos de acceso </label>
                    <Icon type="right" />
                  </div>
                  
                  {
                    this.props.validCurrentUser(this.context,'metodoPago','bool',false,false) && 
                    <div className="cardBodyOption" id="imp" onClick={ () => {this.setState({modalMetodosDePago:true}); this.childMetodos.updateDatos();} } >
                      <label className="" > Integración de métodos de pago </label>
                      <Icon type="right" />
                    </div>
                  }

                  {
                    this.context['type'] === 1&&
                    <React.Fragment>
                      <div className="cardBodyOption" onClick={ () => {this.setState({modalMembresia:true}); this.childMembresia.updateDatos(); } } >
                        <label className=""> Membresía </label>
                        <Icon type="right" />
                      </div>

                      <div className="cardBodyOption" onClick={()=>this.eliminarCuenta(4)} >
                        <label className=""> Eliminar cuenta </label>
                      </div>
                      {
                        this.state.estatusEmpresa===1?
                        <div className="cardBodyOption" onClick={()=>this.eliminarCuenta(7) /*()=>this.setState({modalConfirmInhabilitarCuenta:true})*/ } >
                          <label className=""> Deshabilitar cuenta </label>
                        </div>:
                        <div className="cardBodyOption" onClick={this.habilitarCuenta} >
                          <label className=""> Habilitar cuenta </label>
                        </div>
                      }
                    </React.Fragment>
                  }

                </div>


              </div>


              <div className="optionCard">
                
                <div className="cardHead" >
                  <label className="cardTitle" > Configuración de venta </label>
                </div>

                <div className="cardBody" >

                {
                  this.props.validCurrentUser(this.context,'sinexistencia','bool',false,'EDITAR')&&
                  <div className="cardBodyOption">
                    <label className="" onClick={()=>this.subClick('myswitch1')} > Vender productos sin existencia </label>
                    <Switch checked={this.state.datos.VENTA_SIN_EXISTENCIAS === '1'} id="myswitch1" className="myswitch" onChange={(checked) => this.changeSwitch(checked,'VENTA_SIN_EXISTENCIAS')} />
                  </div>
                }

                  <div className="cardBodyOption">
                    <label className="" onClick={()=>this.subClick('myswitch4')}  > Habilitar citas </label>
                    <Switch checked={this.state.datos.USAR_CONTROL_CITAS === '1'} id="myswitch4" className="myswitch" onChange={(checked) => this.changeSwitch(checked,'USAR_CONTROL_CITAS')} />
                  </div>                  
                  
                  {
                    this.state.datos.USAR_CONTROL_CITAS === '1' ?
                    <div className="cardBodyOption" onClick={()=> this.setState({modalListaConfiguraciones:true})}>
                      <label className="" onClick={()=> this.setState({modalListaConfiguraciones:true})} > Configuración de citas </label>
                    </div>:<React.Fragment/>
                  }

                </div>

              </div>

              <div className="optionCard">
                
                <div className="cardHead" >
                  <label className="cardTitle" > Notificaciones </label>
                </div>

                <div className="cardBody">

                  <div className="cardBodyOption">
                    <label className=""> Notificaciones push </label>
                    <Switch checked={Number(this.state.notiPush['ESTATUS']) === 1 } className="myswitch" onChange={(checked) => this.changeNotiEstatus(checked,1)} disabled={this.context['type']===2 } />
                  </div>

                  <div className="cardBodyOption" title="Opción no disponible" >
                    <label className=""> Notificaciones por SMS </label>
                    <Switch checked={Number(this.state.notiMsj['ESTATUS']) === 1 } className="myswitch" onChange={(checked) => this.changeNotiEstatus(checked,3)} disabled />
                  </div>

                  <div className="cardBodyOption">
                    <label className=""> Notificaciones por correo </label>
                    <Switch checked={Number(this.state.notiMail['ESTATUS']) === 1 } className="myswitch" onChange={(checked) => this.changeNotiEstatus(checked,2)} disabled={this.context['type']===2 } />
                  </div>                

                </div>

              </div>

            </div>
            
            <div>
              <BrowserRouter>
                <Switcher>
                  <Route exact path="/home/configuracion">
                    <HashRouter>
                      <Route path="/" component={null} />
                      <Route path="/stripe/:var" component={ConfigModal} />                      
                      <Link to={'/'} id="closeModal"/>
                      <Link to={'/stripe/done'} id="openHashModal"/>
                    </HashRouter>
                  </Route>
                </Switcher>
              </BrowserRouter>
            </div>
          
          <div className="cont-instancias5"></div>

          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Config);