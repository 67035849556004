import React from 'react';

import axios from 'axios';
import 'moment/locale/es'
import moment from 'moment'
import { Globalrequest as request } from './../../../request.js';
import {Host as host,Img as img ,ImgPanelAdmin as ImgPanel} from './../../../app.json'
import {Modal,Select,Input,Icon,Checkbox} from 'antd';
import {withRouter} from 'react-router-dom'

import NumberFormat from 'react-number-format';

import {userContext} from './../context'

import Loading from '../../../modalCargando/loading';
import Alert from './../../../modalCargando/modalAlert';

import   './subusuarios.scss';

import funcionesJSON from './funciones.json'

const DataUsuario = (props) => {
    return(

      <div className="tarjetausuario flexcenter Fspaceb" 
      //key={props.key}
      onClick={props.onClick}  >

        <div className="meta">

          <div className="data flexcenter Fstart" >
            <img alt="..." src={img+'user_empresa_B.png'}  />
            <span title={props.nombre} >{props.nombre}</span>
          </div>

          <div className="data flexcenter Fstart" >
            <img alt="..." src={img+'telefono.png'}  />
            <span title={props.telefono} >{props.telefono}</span>
          </div>

          <div className="data flexcenter Fstart" >
            <img alt="..." src={img+'sucursales.png'}  />
            <span title={props.sucursales} >{props.sucursales}</span>
          </div>

        </div>
        
        <div className="ops flexcenter Fcenter ">
          <img alt="..." src={img+'basurero_gris.png'} onClick={props.clickImage} />
        </div>

      </div>

    )
}

class Lista extends React.Component{

  static contextType = userContext

  constructor(props) {
    super(props);

    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.actualizarFunciones = this.actualizarFunciones.bind(this)
    this.showDetalle = this.showDetalle.bind(this)
    this.showPermisos = this.showPermisos.bind(this)
    this.saveUsuario = this.saveUsuario.bind(this)
    this.commitUser = this.commitUser.bind(this)
    
    this.showElegirSucursal = this.showElegirSucursal.bind(this)
    this.closeElegirSucursal = this.closeElegirSucursal.bind(this)
    this.changeFakeSucursal = this.changeFakeSucursal.bind(this)
    this.asignarTodos = this.asignarTodos.bind(this)
    this.setCheck = this.setCheck.bind(this)

    this.eliminarUsuario = this.eliminarUsuario.bind(this)

    this.state = {
      loading:false,
      /**/
      modalAlert:false,
      txtAlert:'',
      modalEliminar:false,
      /**/
      usuarios:[],
      indice:-1,
      /**/
      caseu:-1,//1 agregar, 2 editar,
      modalDetalle:false,
      estatus:-1,
      detalle:{},
      sucursalesDetalle:[],
      sucursalesDetalleCopia:[],

      permisosDetalle:[],
      /**/
      funciones: [],
      nombreEmpresa:'',
      todos:false,
    }
  }

  componentDidMount(){
    document.title="Usuarios"

    let {validCurrentUser,redirectTo2} = this.props


    let obj = JSON.parse(JSON.stringify(this.context))
    if(Object.keys(this.context['data']).length === 0 ){
      let permisosPreSaved = sessionStorage.getItem('perfiles')||'none'
      if(permisosPreSaved!=='none')
        obj['data'] = JSON.parse(permisosPreSaved)  
    }
    
    validCurrentUser(obj,'estadisticas','bool')
    
    if(!validCurrentUser(obj,'estadisticas','bool',false,false)){
      redirectTo2('/home')
      return
    }
    
    this.actualizarCatalogo()
  }

  actualizarCatalogo(){
    let data = {idE:sessionStorage.getItem('id')}
    this.setState({loading:true})
    axios.post(`${host}subusuarios/subusuarios/listaUsuariosPorEmpresa`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let nombreEmpresa = JSON.parse(sessionStorage.getItem('metadata'))['datos']['NOMBRE_COMERCIAL']
        this.setState({loading:false,usuarios:rt.data['data'],nombreEmpresa})
      }
    })
  }

  actualizarFunciones(withLoading=false){
    if(withLoading) this.setState({loading:true})
    axios.post(`${host}subusuarios/subusuarios/listaFunciones`,0,request).then(rt=>{
      if(rt.data['status']==='ok'){
        if(withLoading)
          this.setState({loading:false,funciones:rt.data['data']})
        this.setState({funciones:rt.data['data']})
      }
    })
  }

  showDetalle(indice){
    let {detalle,usuarios} = this.state
    if(indice===-1){
      detalle={ nombre:'', apellido:'', cargo:'', telefono:'', correo:'', id:'-1',contra:'', }
      this.setState({modalDetalle:true,detalle,estatus:1,caseu:1,indice})
    }else{
      let data = {id:usuarios[indice]['ID']}
      this.setState({loading:true})
      axios.post(`${host}subusuarios/subusuarios/detalleUsuario`,data,request).then(rt=>{
        if(rt.data['status']==='ok'){
          
          if(rt.data['data']==='none'){
            this.setState({loading:false,txtAlert:'Parece que el usuario que seleccionaste ya no se encuentra registrado.',modalAlert:true})
            return
          }

          detalle={
            nombre:rt.data['data']['NOMBRE'], apellido:rt.data['data']['APELLIDO'],
            cargo:rt.data['data']['CARGO'], telefono:rt.data['data']['TELEFONO'],
            correo:rt.data['data']['CORREO_REGISTRO'], id:rt.data['data']['ID'],
            contra:rt.data['data']['CONTRASENA']
          }
          this.setState({loading:false,detalle,modalDetalle:true,estatus:Number(rt.data['data']['ESTATUS']),caseu:2,indice})
        }else
          this.setState({loading:false,txtAlert:'Parece que el ocurrió un error al consultar la información, por favor intentelo más tarde.',modalAlert:true})
        

      })
    }
  }

  empatarPermisos(locales,BDD){
    let idCategoria = 0, flags = []
    for(let categoria in locales){
      let idPermiso = 0
      for(let permiso of locales[categoria]){
        let aux = BDD.filter(p => Number(p['TIPO']) === Number(permiso['TIPO']) )
        permiso['ID'] = -1
        if(aux.length>0){
          aux = aux[0]
          permiso['ID'] = aux['ID']
          for(let funcion of permiso['FUNCIONES']){
            if(funcion['LABEL']==="Crear")
              funcion['ACTIVA'] = Number(aux['CREAR'])===1
            if(funcion['LABEL']==="Editar"||funcion['LABEL']==="Responder"||funcion['LABEL']==="Ver"||funcion['LABEL']==="Atender")
              funcion['ACTIVA'] = Number(aux['EDITAR'])===1
            if(funcion['LABEL']==="Eliminar")
              funcion['ACTIVA'] = Number(aux['ELIMINAR'])===1
          }
          flags.push([idCategoria,idPermiso])
        }
        idPermiso++
      }
      idCategoria++
    }
    return flags
  }

  showPermisos(indice,haveToValid){
    let {permisosDetalle, usuarios, sucursalesDetalle,estatus,} = this.state

    if(haveToValid){
      let {detalle} = this.state
      let aux = this.validForm(detalle)
      if(aux===-1){
        this.setState({modalAlert:true,txtAlert:'Debe completar los datos del formulario'})
        return
      }

      detalle['telefono'] = detalle['telefono'].split('(').join('').split(')').join('').split(' ').join('')

      if(detalle['telefono'].length<10){
        this.setState({txtAlert:'Ingrese un número de teléfono válido.',modalAlert:true})
        return
      }

      let regexMail = /(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$){1,200}/
      if( !regexMail.test(detalle['correo'])) {
        this.setState({txtAlert:'Ingrese un correo válido',modalAlert:true})
        return
      }
      detalle['estatus'] = estatus
      this.setState({detalle})

    }

    permisosDetalle = JSON.parse(JSON.stringify(funcionesJSON))
    let data = {
      id: indice===-1?-1:usuarios[indice]['ID'],
      idE:sessionStorage.getItem('id')
    }
    let flags = []
    if(indice===-1){
      flags = this.empatarPermisos(permisosDetalle , [])
    }
    this.setState({loading:true})
    axios.post(`${host}subusuarios/subusuarios/permisosUsuario`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        
        let hook  = {DATA:[]}

        sucursalesDetalle = Object.values(rt.data['head']).reduce(
          (result,sucursal) => {
            if(sucursal['SELECTED'])
              hook = sucursal
            result.push(sucursal)
            return result
          },[] ) //

        let permisosBDD =  {}
        if(indice!==-1){
          permisosBDD = hook['DATA']
          flags = this.empatarPermisos(permisosDetalle , permisosBDD)
        }
        this.setState({loading:false,permisosDetalle,modalPermisos:true,sucursalesDetalle})
        
        setTimeout(()=>{
          for(let flag of flags)
            this.expand(flag[0],flag[1],true)
        },100)

      }
      else{
        this.setState({loading:false,txtAlert:'Parece que el ocurrió un error al consultar la información, por favor intentelo más tarde.',modalAlert:true})
      }
    })

  }

  validForm(obj){
    let form = new FormData(document.getElementById('formulaUser'))
    for(let campo of form.entries()){
      obj[campo[0]] = campo[1]
      if(campo[1].trim()===''){
        this.setState({modalAlert:true,txtAlert:'Debe completar los datos del formulario'})
        return -1
      }
    }
    return 1
  }

  saveUsuario(tipo){//1->desde el formulario(puede ser agregar o editar) 2->desde el panel de permisos
    let {estatus,detalle,indice,caseu,permisosDetalle,sucursalesDetalle} = this.state
    if(tipo===1){

      let aux = this.validForm(detalle)
      if(aux===-1){
        this.setState({modalAlert:true,txtAlert:'Debe completar los datos del formulario'})
        return
      }

      detalle['telefono'] = detalle['telefono'].split('(').join('').split(')').join('').split(' ').join('')

      if(detalle['telefono'].length<10){
        this.setState({txtAlert:'Ingrese un número de teléfono válido.',modalAlert:true})
        return
      }

      let regexMail = /(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$){1,200}/
      if( !regexMail.test(detalle['correo'])) {
        this.setState({txtAlert:'Ingrese un correo válido',modalAlert:true})
        return
      }
      detalle['estatus'] = estatus
      this.setState({detalle})
      if(caseu===2)//editando
        this.commitUser(detalle,'none')
      else{//agregando
        this.setState({loading:true})
        axios.post(`${host}subusuarios/subusuarios/datosPorMail`,{correo:detalle['correo']},request).then(rt=>{
          if(Number(rt.data)>0){
            this.setState({modalAlert:true,txtAlert:'Correo electrónico registrado. Intente nuevamente con otro correo',loading:false,})
            return
          }
          else
            this.showPermisos(indice,false)
        })
      }
    }
    else if(tipo===2){
      let atleast = sucursalesDetalle.some(sucursal => sucursal['SELECTED'])

      if(!atleast){
        this.setState({txtAlert:'Debes seleccionar al menos una sucursal.',modalAlert:true})
        return
      }
      atleast = false
      let permisosFlatten = []      
      for(let categoria in permisosDetalle){
        for(let permiso of permisosDetalle[categoria]){
          permiso['activo'] = permiso['FUNCIONES'].some(funcion => funcion['ACTIVA'])
          if(!atleast)
            atleast = permiso['activo']
          permisosFlatten.push(permiso)
        }
      }
      if(!atleast){
        this.setState({txtAlert:'Debes seleccionar al menos un permiso.',modalAlert:true})
        return
      }
      this.commitUser(detalle,sucursalesDetalle,permisosFlatten)
    }

  }

  commitUser(data,sucursales='none',permisos='none'){
    data['idEmpresa'] = sessionStorage.getItem('id')
    data['complete'] = 1//solo editar datos del usuario pero no sus permisos
    data['sucursales'] = []
    if(typeof sucursales === "object" && typeof permisos === "object"){
      data['complete'] = 2//usario completo
      for(let permiso of permisos){
        for(let sucursal of sucursales){
          sucursal['activa'] = 2
          
          if(sucursal['SELECTED']){
            sucursal['activa'] = 1
            let permisoAux = sucursal['DATA'].filter(data => Number(data['TIPO']) === permiso['TIPO'] )
            if(permisoAux.length>0){
              permisoAux = permisoAux[0]
              permisoAux['activo'] = permiso['activo']? 1 : 2
              if(permiso['activo']){
                this.helperPermisos(permiso,permisoAux)
              }
            }else if(permiso['activo']){
              let newPermiso = { activo:1, ID: -1, TIPO: permiso['TIPO'], }
              this.helperPermisos(permiso,newPermiso)
              sucursal['DATA'].push(newPermiso)
            }
          }
        }
      }
      data['sucursales'] = sucursales
    }
    this.setState({loading:true})
    axios.post(`${host}subusuarios/subusuarios/altaUsuario`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let {caseu,nombreEmpresa} = this.state

        if(caseu===1){
          let a = { tipo:2, nombreUsuario:data['nombre'], nombreEmpresa, correoRegistro:data['correo'], password:rt.data['code'], }
          axios.post(`${host}usuarios/usuarios/sendMail`,a,request)
        }

        this.setState({
          indice:-1,caseu:-1,
          detalle:{}, permisosDetalle:[],
          sucursalesDetalle:[],sucursalesDetalleCopia:[],
          modalDetalle:false,modalPermisos:false})

        this.actualizarCatalogo()
      }else{
        this.setState({modalAlert:true,txtAlert:'Parece que ocurrió un error. Por favor intentelo má tarde,',loading:false})
      }
    })
  }

  helperPermisos(permisoLocal,permisoBDD){
    for(let funcion of permisoLocal['FUNCIONES']){
      funcion['LABEL'] = funcion['LABEL'].trim().toLowerCase()
      if(funcion['LABEL']==='crear')
        permisoBDD['CREAR'] = funcion['ACTIVA'] ? 1 : 0

      else if(funcion['LABEL']==='eliminar')
        permisoBDD['ELIMINAR'] = funcion['ACTIVA'] ? 1 : 0

      else
        permisoBDD['EDITAR'] = funcion['ACTIVA'] ? 1 : 0
    }
  }

  showElegirSucursal(){
    let{sucursalesDetalleCopia,sucursalesDetalle} = this.state
    sucursalesDetalleCopia = sucursalesDetalle.slice()
    this.setState({modalElegirSucursal:true,sucursalesDetalleCopia})
  }

  closeElegirSucursal(op){
    let{sucursalesDetalleCopia,sucursalesDetalle} = this.state
    if(op===1){//guardar
      if(!sucursalesDetalleCopia.some(sucursal => sucursal['SELECTED'])){
        this.setState({modalAlert:true,txtAlert:'Debes elegir por lo menos una sucursal'})
        return
      }
      sucursalesDetalle = sucursalesDetalleCopia.slice()
      this.setState({modalElegirSucursal:false,sucursalesDetalle,sucursalesDetalleCopia:[]})
    }else//close sin guardar
      this.setState({modalElegirSucursal:false,sucursalesDetalleCopia:[]})
  }

  changeFakeSucursal(e,indice){
    let{sucursalesDetalleCopia} = this.state
    sucursalesDetalleCopia[indice]['SELECTED'] = e.target.checked
    this.setState({sucursalesDetalleCopia})
  }

  asignarTodos(){
    let {permisosDetalle,todos} = this.state
    todos = !todos
    let idCategoria = 0;
    for(let permisos of Object.values(permisosDetalle) ){
      
      for(let idPermiso in permisos){
        for(let funcion of permisos[idPermiso]['FUNCIONES']){
          funcion['ACTIVA'] = todos
        }
        this.expand(idCategoria,idPermiso,todos)
      }
      idCategoria++
    }
       
    this.setState({permisosDetalle,todos})
  }

  setCheck(event,categoria,permiso,funcion){
    let {permisosDetalle,todos} = this.state
    permisosDetalle[categoria][permiso]['FUNCIONES'][funcion]['ACTIVA'] = event.target.checked
    if(todos && !event.target.checked)
      todos = false
    this.setState({permisosDetalle,todos})
  }

  eliminarUsuario(e,op,indice=-1){//1 show modal, 2 confirmed
    if(op===1){
      e.stopPropagation()
      this.setState({modalEliminar:true,indice})
    }
    else if(op===2){
      let {indice,usuarios} = this.state
      this.setState({modalEliminar:false,indice:-1,loading:true})
      axios.post(`${host}subusuarios/subusuarios/eliminarUsuario`,{id:usuarios[indice]['ID']},request).then(rt=>{
        if(Number(rt.data) === 1){
          usuarios.splice(indice,1)
          this.setState({indice:-1,loading:false,usuarios})
        }else{
          this.setState({indice:-1,loading:false,modalAlert:true,txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde.'})
        }
      })
    }
  }

  expand(idCategoria,idPermiso,force){
    document.getElementById('permiso_'+idCategoria+'_'+idPermiso).classList.toggle('opened',force)
    document.getElementById('trigger_'+idCategoria+'_'+idPermiso).classList.toggle('opened',force)
  }

  formatFecha(fecha){
      let fecha2 = moment(fecha,'YYYY-MM-DD')
      return fecha2.format('DD/MMMM/YYYY')
  }

  formatNumberCel(num){
    if(num===undefined||num===null)return
    let numa = num.slice(0,3)
    let numb = num.slice(3,6)
    let numc = num.slice(6)
    return '('+numa+') '+numb + ' ' +numc ;
  }
  render(){
    return (
      <div className="cont-sucursales" >
        
        <Modal centered title={this.state.caseu===2 ? "Editar usuario" : "Registrar usuario"} maskClosable={false} keyboard = {false} 
          okText={this.state.caseu===2?"Guardar":"Continuar"}
          okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
          cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
          visible={this.state.modalDetalle} closable={false} width={450}
          className="miAntModal alertMy modalUnidad" destroyOnClose
          onCancel={()=>this.setState({modalDetalle:false,detalle:{}}) }
          onOk={()=>this.saveUsuario(1)} >
          <div className={"miUniDiv userempresa case"+this.state.caseu}>
          <form id="formulaUser">
            <div className="inputs-group">
              <label className="inputs-label" > Nombre </label>
              <input type="text" name="nombre" className="inputs-input" maxLength={50} defaultValue={this.state.detalle.nombre} />
            </div>

            <div className="inputs-group">
              <label className="inputs-label" > Apellido </label>
              <input type="text" name="apellido" className="inputs-input" maxLength={30} defaultValue={this.state.detalle.apellido} />
            </div>

            <div className="inputs-group">
              <label className="inputs-label" > Cargo desempeñado </label>
              <input type="text" name="cargo" className="inputs-input" maxLength={20} defaultValue={this.state.detalle.cargo} />
            </div>

            <div className="inputs-group">
              <label className="inputs-label" > Teléfono </label>
              <NumberFormat type="text" name="telefono" format="(###) ### ####" mask="_" className="inputs-input" defaultValue={this.state.detalle.telefono } />
            </div>

            <div className="inputs-group">
              <label className="inputs-label" > Correo electrónico </label>
              <input type="text" name="correo" className="inputs-input" maxLength={40} defaultValue={this.state.detalle.correo} readOnly={this.state.caseu===2} />
            </div>

            <div className="inputs-group">
              <label className="inputs-label" > Estatus </label>
              <Select name="estatus" maxLength={30} className="inputs-input" value={this.state.estatus} onChange={estatus => this.setState({estatus})} >
                <Select.Option value={1} > Activo </Select.Option>
                <Select.Option value={2} > Inactivo </Select.Option>
              </Select>
            </div>
          </form>

          {
            this.state.caseu===2&&
            <div className="flexcenter Fcenter" style={{color:'#F07505',margin:'20px auto -30px auto',cursor:'pointer'}} onClick={()=>this.showPermisos(this.state.indice,true)} >
              Editar permisos
            </div>
          }

          </div>
        </Modal>

        <Alert
            visible={this.state.modalEliminar}//confirmar
            withCancel
            text='¿Está seguro que desea eliminar este usuario?'
            onOk={() => this.eliminarUsuario(0,2) }
            onCancel={()=>this.setState({modalEliminar:false,indice:-1}) } />

        <Loading loading={this.state.loading} zIndex={1002} />

        <Modal centered  maskClosable={false} keyboard={false} closable={false} visible={this.state.modalPermisos}
          title={this.state.caseu===2 ? "Editar permisos" : "Registrar permisos"}
          okText="Guardar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
          cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',} }}              
          onOk={()=>this.saveUsuario(2)} destroyOnClose
          onCancel={()=>this.setState({modalPermisos:false})}
          className="modalPermisos">
          
          <div className="divPermisosWrapper miUniDiv userempresa" >
            
            <div className="inputs-group">
              <label className="inputs-label" > Disponible en las suscursales </label>
              <Input className="inputs-input" onClick={this.showElegirSucursal}
                readOnly
                style={{cursor:'pointer'}}
                value={this.state.sucursalesDetalle.reduce(
                  (result,sucursal)=>{
                    if(sucursal['SELECTED'])
                      result = result+','+sucursal['NOMBRE']
                    return result
                  } ,'').slice(1) }  />

            </div>
           
           <div className="inputs-group flexcenter Fend">
             <Checkbox className="mycehc flexcenter Fstart" checked={this.state.todos} onChange={this.asignarTodos}> {this.state.todos?'Remover':'Asignar'} todos </Checkbox>
           </div>

            <div className="contentGroupPermisos" >
            {
              Object.entries(this.state.permisosDetalle).map(([categoria,permisos],i) => (
                <div key={'sucD_'+i} className="itemCategoria" >
                  <label className="titleCat flexcenter Fstart" > {categoria} </label>

                  {
                    permisos.map((permiso,j) => (
                      <div className="bloquePermiso" key={'permiso_'+i+'_'+j} id={'trigger_'+i+'_'+j} >
                        
                        <label className="titlePermi flexcenter Fspaceb" onClick={(e)=>this.expand(i,j)} > {(permiso)['NOMBRE']} <Icon type="down" /> </label>
                        
                        <div className="funcionesParent flextop Fcenter" id={'permiso_'+i+'_'+j} >
                        {
                          permiso['FUNCIONES'].map((funcion,k) => (
                            <Checkbox className="mycehc flexcenter Fstart" key={'funcion_'+k} checked={funcion['ACTIVA']}
                              onChange={(e)=>this.setCheck(e,categoria,j,k)} >
                              {funcion['LABEL']}
                            </Checkbox>
                          ))
                        }                              
                        </div>

                      </div>
                    ))
                  }
                </div>
              ))
            }
            </div>

          </div>

        </Modal>

        <Modal centered title="Elija la sucursal" maskClosable={false} keyboard = {false} //closable={false}
          okText={this.state.caseu===2?"Guardar":"Continuar"}
          okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
          cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px',display:'none'} }}
          visible={this.state.modalElegirSucursal} width={400}
          className="miAntModal alertMy modalUnidad" destroyOnClose
          onCancel={()=>this.closeElegirSucursal(2)}
          onOk={()=>this.closeElegirSucursal(1)} >
          <div className="miUniDiv userempresa choseSuc">
          {
            this.state.sucursalesDetalleCopia.map((sucursal,k) => (
              <Checkbox className="mycehc flexcenter Fstart" key={'funcion_'+k} onChange={(e)=>this.changeFakeSucursal(e,k)} checked={sucursal['SELECTED']} >
                {sucursal['NOMBRE']}
              </Checkbox>
            ))
          }
          </div>
        </Modal>

        <Alert visible={this.state.modalAlert} zIndex={1001} text={this.state.txtAlert} onOk={()=>this.setState({modalAlert:false})} />

        <div className="cont-sucursales2" >
          <div className="cont-sucursales3">

            {
              this.state.usuarios.length === 0 && 
              <div className="imgnonResult" style={{marginTop:'-30px'}} >
                <div  className="nonResultChild">
                  <img src={ImgPanel+'nonproductos.png'} alt="..." />
                </div>
              </div>
            }


            <div className="buttomFlotanding" title="Agregar nuevo registro" onClick={()=>this.showDetalle(-1)} > + </div>

            <div className="metaControles">
              <h3 className="tituloPanel"> Gestión de usuarios </h3>
            </div>

            
            <div className="cont-Promociones">
            {
              this.state.usuarios.map((usuario,indice) => (
                <DataUsuario key={'usuario_'+indice}
                  nombre={usuario['NOMBRE']}
                  telefono={this.formatNumberCel(usuario['TELEFONO'])}
                  sucursales={usuario['ID_SUCURSAL']}
                  onClick={()=>this.showDetalle(indice)}
                  clickImage={(e)=>this.eliminarUsuario(e,1,indice)}
                  />
              ))
            }
            </div>
            

            <div className="cont-instancias5"></div>
          </div>
        </div>
       </div>
    );
  }
}

export default withRouter(Lista);