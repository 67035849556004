import React from 'react';

import moment from 'moment'
import axios from 'axios';

import { Globalrequest as request } from './../../../request.js';
import {Host as host , ImgPanelAdmin as imgAdmin } from './../../../app.json'
import {Modal,Calendar,Select,Icon,Input,Popover} from 'antd';

import {withRouter} from 'react-router-dom'

import {userContext} from './../context'

import Loading from '../../../modalCargando/loading';
import Alert from '../../../modalCargando/modalAlert';

import {capitalizeFirs} from "./../../../utilities/generalfunctions";

import './citas.scss'

class Lista extends React.Component{

  static contextType = userContext

  constructor(props) {
    super(props);

    this.listaClientes = this.listaClientes.bind(this)

    this.clickOnCita = this.clickOnCita.bind(this)
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.actualizarHorarios = this.actualizarHorarios.bind(this)
    this.changeCalendar = this.changeCalendar.bind(this)
    this.changeMonth = this.changeMonth.bind(this)
    this.changeControlCitas = this.changeControlCitas.bind(this)

    this.agregarCita = this.agregarCita.bind(this)
    this.eliminarCita = this.eliminarCita.bind(this)

    this.setReagendarFecha = this.setReagendarFecha.bind(this)
    this.setReagendarHora = this.setReagendarHora.bind(this)
    this.onPanelChange = this.onPanelChange.bind(this)


    this.addNewCita = this.addNewCita.bind(this)
    this.clickHoverable = this.clickHoverable.bind(this)    

    this.cancelReagendarFecha = this.cancelReagendarFecha.bind(this)

    this.state = {
      emty:true,

      loading:false,
      /**/
      hoy:moment(),
      fechaReagendar:moment(),
      formatedDate:capitalizeFirs(moment().format('dddd, LL')),
      toCancel:moment(),

      mes:{nombre:'',citas:[]},
      horarios:[],
      /**/
      modalAlert:false,
      txtAlert:'',

      confirmEliminar:false,
      /**/
      mutableDataCita:{
        inicio:'',
        fin:'',
        idHorarioAtencionCita:'',
      },
      currentCita:{cita:{},},
      currentCitaCopy:{cita:{},},
      citaInterface:{ESTATUS:'1',OBSERVACIONES_CLIENTES:'',OBSERVACIONES_EMPRESA:'',MOTIVOS_CLIENTES:'',MOTIVOS_EMPRESA:'',ID_USUARIO_EMPRESA:-1,FK_ID:-1,ID_PEDIDO:-1,ID:-1,CALIFICACION_CLIENTE:0,MINE:false,NOMBRE_CLIENTE:'',FECHA_CITA_INICIO:'',FECHA_CITA_TERMINO:''},
      addingCita:false,
      reagending:false,
      reagending2:false,
      editanding:false,
      estatus:0,
      auxEstatus:0,
      isNew:false,
      justHora:false,
      cancelanding:false,
      /**/
      confirmCambiarStatus:false,
      /**/
      controlCitas:[],
      indexConfig:'',
      configActual:{},
      duracionCita:'',
      idConfig:'',
      caducada:false,
      finalizada:false,
      /**/
      listaCitas:[],
      listaCitas2:[],

      listaClientes:[],
      listaClientes2:[],
      metaCustom:[],
      metaCustom2:[],
      /**/
      idCliente:-1,
      /**/
      idNot:sessionStorage.getItem('idNot'),
      idNotificacionPorAtender:sessionStorage.getItem('idNotificacionPorAtender'),
    }
  }

  componentDidMount(){
    document.title="Citas agendadas"
    let {idNotificacionPorAtender} = this.state
    let id = sessionStorage.getItem('id')

    if(idNotificacionPorAtender !== 'null'){
      let data = {idN:idNotificacionPorAtender,estatus:2}
      axios.post(`${host}clientes/clientes/cambiarEstatusNotificacion`,data,request)
      sessionStorage.setItem('idNotificacionPorAtender','null')
    }

    if(id===null || id===undefined ) return

    let {validCurrentUser,redirectTo2} = this.props

    let obj = JSON.parse(JSON.stringify(this.context))
    if(Object.keys(this.context['data']).length === 0 ){
      let permisosPreSaved = sessionStorage.getItem('perfiles')||'none'
      if(permisosPreSaved!=='none')
        obj['data'] = JSON.parse(permisosPreSaved)  
    }

    
    if(!validCurrentUser(obj,'citas','bool',false,false)){
      redirectTo2('/home')
      return
    }

    this.listaClientes()
    
  }

  listaClientes(){
    let {listaClientes,listaClientes2,metaCustom,metaCustom2} = this.state
    let data = {idE:sessionStorage.getItem('id')}
    this.setState({loading:true})
    axios.post(`${host}clientes/clientes/minDataClient`,data,request).then(rt=>{
      listaClientes = rt.data
      listaClientes2 = rt.data
      metaCustom = listaClientes2.map(c => (c['ID_CLIENTE']))
      metaCustom2 = listaClientes2.map(c => (c['ID_CLIENTE']))
      this.setState({listaClientes,listaClientes2,metaCustom,metaCustom2})
      data['horas'] = -2;
      axios.post(`${host}empresas/empresas/getControlCitas`,data,request).then(rt=>{
        if(rt.data.length>0){
          let {controlCitas,configActual,indexConfig,emty} = this.state
          let {validCurrentUser} = this.props
          controlCitas = rt.data
          configActual = {}

          let obj = Object.assign(this.context)
          if( Object.entries(this.context['data']).length ===0 ){
            let permisosPreSaved = sessionStorage.getItem('perfiles')||'none'
            if(permisosPreSaved!=='none')
              obj['data'] = JSON.parse(permisosPreSaved)
          }

          for(let aux of controlCitas){
            if(validCurrentUser(obj,'citas','bool',aux['CODE'],false)){
              Object.assign(configActual,aux)
              indexConfig = configActual['ID']
              emty=true
              break
            }
          }

          this.setState({controlCitas , configActual ,indexConfig,emty,})
          this.actualizarCatalogo()
        }else{
          this.setState({txtAlert:'No tienes configuraciones de citas registradas',modalAlert:true,emty:true,loading:false,})
        }
      })
    })
  }

  clickOnCita(cita){
    let {currentCita,hoy,caducada,estatus,finalizada,citaInterface,toCancel,mutableDataCita} = this.state
    let ahora = moment()
    let inicioCita = moment( hoy.format('DD-MMMM-YYYY ') + cita['inicio'],'DD-MMMM-YYYY HH:mm')

    caducada = inicioCita.isBefore(ahora,'minute')//si es hoy, y elige una hora anterior a la actual
    toCancel = moment(hoy)
    
    mutableDataCita={ inicio:cita['inicio'], fin:cita['fin'], idHorarioAtencionCita:cita['idHorarioAtencionCita'], }
    if(!cita['cita'].hasOwnProperty('ID')){//no hay cita para este horario
      if(caducada){
        this.setState({txtAlert:'No puedes agendar una cita en el horario elegido',modalAlert:true})
        return
      }
      currentCita = {}
      currentCita = JSON.parse(JSON.stringify(cita))
      currentCita['cita'] = Object.create(citaInterface)
      estatus = 2
      if(this.context['type']===2){
        currentCita['cita']['ID_USUARIO_EMPRESA'] = sessionStorage.getItem('subid')
      }
      this.setState({currentCita,addingCita:true,estatus,editanding:false,mutableDataCita,toCancel})
    }else{//si hay cita para este horario
      let {currentCitaCopy,idCliente} = this.state
      currentCitaCopy = JSON.parse(JSON.stringify(cita))
      currentCita = JSON.parse(JSON.stringify(cita))
      idCliente = currentCita['cita']['ID_CLIENTE']
      estatus = Number(currentCita['cita']['ESTATUS'])
      finalizada = (estatus===3||estatus===4||estatus===6)
      this.setState({currentCita,currentCitaCopy,addingCita:true,editanding:true,caducada,estatus,finalizada,idCliente,mutableDataCita,toCancel})
    }
  }

  actualizarCatalogo(cambieAlReagendar=false){
    let {configActual,hoy,mes,duracionCita,idConfig,horarios,metaCustom,idNot} = this.state
    
    if(!configActual.hasOwnProperty('ID')){
      this.setState({loading:false,})
      return
    }

    let fecha2 = moment(hoy)
    duracionCita = moment.duration(configActual['DURACION_CITA']).asMinutes()
    this.setState({loading:true,duracionCita})
    let listaCitas=[]

    //mes actual en memoria
    if(mes['nombre']===fecha2.format('MMMMYYYY')){
      listaCitas = this.actualizarHorarios(fecha2,false,true)
      this.setState({loading:false,listaCitas})
      return
    }

    //no está en memoria, por lo que toca actualizarla
    mes['nombre']=fecha2.format('MMMMYYYY')
    let conhorarios = 1 
    let data = {
      idS:configActual['ID_SUCURSAL'],
      fechaInicio:fecha2.startOf('month').format('YYYY-MM-DD') ,
      fechaFin:fecha2.endOf('month').format('YYYY-MM-DD'),
      horario:conhorarios,
      idC:configActual['ID'],
      idB:-1,
      metaCustom:metaCustom,
      idNot
    }

    axios.post(`${host}citas/citas/listaCitasEmpresa`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        mes['citas'] = rt.data['citas']
        
        horarios = conhorarios>0?rt.data['horarios']:horarios
        idConfig = configActual['ID']

        this.setState({idConfig,mes,horarios})
        listaCitas = this.actualizarHorarios(hoy,false,true)
        this.setState({loading:false,listaCitas})

        if(cambieAlReagendar){
          this.setReagendarFecha(false)
        }
      }
    })
  }

  actualizarHorarios(fecha,conFiltro=false,withRepetidos=false){
    let {mes,horarios,citaInterface,configActual} = this.state

    let fechaElegida = moment(fecha)
    let fechaHoy = moment()
    let dia = fechaElegida.day()
    dia = dia===0?7:dia
    let horariosHoy = horarios.filter(h => Number(h['DIA']) === dia && Number(h['ESTATUS'])===1)
    let listaCitas = []
    let idHorarioAtencionCita = -1

    if(horariosHoy.length>0){
      
      let horarioAtencion = horariosHoy.filter(h=>Number(h['TIPO_HORA'])===1)//sacamos el horario de atencion 09:00-05:00
      horarioAtencion = horarioAtencion[0]
      idHorarioAtencionCita = horarioAtencion['ID']

      let horariosNoAtencion = horariosHoy.filter(h=>Number(h['TIPO_HORA'])===2)
      let tolerancia = 0

      horariosNoAtencion = horariosNoAtencion.map(h => ({inicio: moment(h['HORA_INICIO'],'HH:mm:ss').add(tolerancia,'minutes') , fin: moment(h['HORA_TERMINO'],'HH:mm:ss')}) )

      //hora en formato 24 hrs

      let horarioAtencionAbre = moment(horarioAtencion['HORA_INICIO'],'HH:mm:ss') //17:00:00
      let horarioAtencionCierra = moment(horarioAtencion['HORA_TERMINO'],'HH:mm:ss') //17:05:00

      let intervalo = moment.duration(configActual['INTERVALO_ENTRE_CITAS']).asMinutes() //15 min
      let duracionCita = moment.duration(configActual['DURACION_CITA']).asMinutes() // 5min

      let finalizaSiguiente = moment(horarioAtencionAbre) //17:00:00
      finalizaSiguiente.add(duracionCita+intervalo,'minutes') ////17:25:00

      let citasHoy =
        conFiltro ? mes['citas'].filter(cita => fechaElegida.isSame(cita['FECHA_CITA_INICIO'],'day') && Number(cita['ESTATUS']) !== 6 ) :
                    mes['citas'].filter(cita => fechaElegida.isSame(cita['FECHA_CITA_INICIO'],'day'))

      let citaParaHorario
     
      let esHoy = fechaElegida.isSame(fechaHoy,'day')
      
      while(
          horarioAtencionAbre.isBefore(horarioAtencionCierra)
          && finalizaSiguiente.isSameOrBefore(horarioAtencionCierra)
        ){        

        let texto='',clase='',horaEmpiezaString='',horaFinString='',citaActual={}//clase = clasebarrita y calse2 = todo el contenedor del texto

        texto='';clase='';
        citaActual = Object.create(citaInterface)

        let horarioAtencionAbreAux = moment(horarioAtencionAbre)
        horarioAtencionAbreAux.add(duracionCita,'minutes')

        horaEmpiezaString = horarioAtencionAbre.format('HH:mm')
        horaFinString = horarioAtencionAbreAux.format('HH:mm')
        
        let invalida = horariosNoAtencion.some(h => horarioAtencionAbre.isBetween(h['inicio'],h['fin']) || horarioAtencionAbreAux.isBetween(h['inicio'],h['fin'])  )
        if(invalida){//hora de descanso
          texto = 'No disponible para citas'
          clase = 'nodisponible'
        }else{
          citaParaHorario = citasHoy.filter(cita => cita['FECHA_CITA_INICIO'].includes(horaEmpiezaString) && cita['FECHA_CITA_TERMINO'].includes(horaFinString) )
          if(citaParaHorario.length>0){// hay cita a esa hora

            if(withRepetidos && citaParaHorario.length>1){
              for(let citaAux of citaParaHorario){
                citaActual = Object.create(citaInterface)
                let textoCliente = citaAux['OBSERVACIONES_CLIENTES'].split('~Ç~')
                let textoEmpresas = citaAux['OBSERVACIONES_EMPRESA'].split('~Ç~')
                citaAux['OBSERVACIONES_CLIENTES'] = textoCliente.length>0?textoCliente[0].trim():''
                citaAux['MOTIVOS_CLIENTES'] = textoCliente.length>1?textoCliente[1].trim():''
                
                citaAux['OBSERVACIONES_EMPRESA'] = textoEmpresas.length>0?textoEmpresas[0].trim():''
                citaAux['MOTIVOS_EMPRESA'] = textoEmpresas.length>1?textoEmpresas[1].trim():''
                Object.assign(citaActual, citaAux)
                citaAux['ESTATUS'] = Number(citaAux['ESTATUS'])
                texto = Number(citaAux['ID_CLIENTE']) === -1 ? 'Usuario no registrado' : citaAux['NOMBRE_CLIENTE']
                clase = citaAux['ESTATUS'] === 1 ? 'pendiente' : citaAux['ESTATUS'] === 2 ? 'confirmada' : citaAux['ESTATUS'] === 3 ? 'noasistio' : citaAux['ESTATUS'] === 4 ? 'asistio' : citaAux['ESTATUS'] === 5 ? 'reagendada' : 'cancelada'
                listaCitas.push({inicio:horaEmpiezaString,fin:horaFinString,texto:texto,clase:clase,descanso:invalida,cita:citaActual,idHorarioAtencionCita:idHorarioAtencionCita})
              }
              horarioAtencionAbre.add(duracionCita,'minutes')
              horarioAtencionAbre.add(intervalo,'minutes')
              continue;
            }

            citaParaHorario = citaParaHorario[0]
            let textoCliente = citaParaHorario['OBSERVACIONES_CLIENTES'].split('~Ç~')
            let textoEmpresas = citaParaHorario['OBSERVACIONES_EMPRESA'].split('~Ç~')
            citaParaHorario['OBSERVACIONES_CLIENTES'] = textoCliente.length>0?textoCliente[0].trim():''
            citaParaHorario['MOTIVOS_CLIENTES'] = textoCliente.length>1?textoCliente[1].trim():''

            citaParaHorario['OBSERVACIONES_EMPRESA'] = textoEmpresas.length>0?textoEmpresas[0].trim():''
            citaParaHorario['MOTIVOS_EMPRESA'] = textoEmpresas.length>1?textoEmpresas[1].trim():''
            Object.assign(citaActual, citaParaHorario)
            citaParaHorario['ESTATUS'] = Number(citaParaHorario['ESTATUS'])
            texto = Number(citaParaHorario['ID_CLIENTE']) === -1 ? 'Usuario no registrado' : citaParaHorario['NOMBRE_CLIENTE']
            clase = citaParaHorario['ESTATUS'] === 1 ? 'pendiente' : citaParaHorario['ESTATUS'] === 2 ? 'confirmada' : citaParaHorario['ESTATUS'] === 3 ? 'noasistio' : citaParaHorario['ESTATUS'] === 4 ? 'asistio' : citaParaHorario['ESTATUS'] === 5 ? 'reagendada' : 'cancelada'
          }
          else{//no hay cita a esa hora
             texto = ''
             clase = ''
          }
        }
        if(conFiltro){

          clase = clase===''?'confirmada':(clase==='confirmada'||clase==='pendiente'||clase==='nodisponible')?'cancelada':'confirmada'

          if(esHoy){
            if(moment(horaEmpiezaString,'HH:mm').isSameOrAfter(moment()))
              listaCitas.push({inicio:horaEmpiezaString,fin:horaFinString,texto:texto,clase:clase,descanso:invalida,cita:citaActual,idHorarioAtencionCita:idHorarioAtencionCita})
          }else{
            listaCitas.push({inicio:horaEmpiezaString,fin:horaFinString,texto:texto,clase:clase,descanso:invalida,cita:citaActual,idHorarioAtencionCita:idHorarioAtencionCita})
          }

        }else{
          listaCitas.push({inicio:horaEmpiezaString,fin:horaFinString,texto:texto,clase:clase,descanso:invalida,cita:citaActual,idHorarioAtencionCita:idHorarioAtencionCita})
        }
        
        horarioAtencionAbre.add(duracionCita+intervalo,'minutes')//21:30
        
        finalizaSiguiente = moment(horarioAtencionAbre)//21:30
        finalizaSiguiente.add(duracionCita+intervalo,'minutes')//21:30 + 20min + 20min = 22:00

      }
    }
    return listaCitas
  }

  changeCalendar(value,circle=false){
    let fechaReagendar = moment(value)
    this.setState({hoy:value, formatedDate:capitalizeFirs(value.format('dddd, LL')),fechaReagendar})
    setTimeout(()=>this.actualizarCatalogo(circle),100)
  }

  changeMonth(c){
    let {hoy,fechaReagendar} = this.state
    hoy = hoy.add(c,'months')
    this.setState({hoy,formatedDate:capitalizeFirs(hoy.format('dddd, LL')),fechaReagendar})
    setTimeout(()=>this.actualizarCatalogo(),100)
  }

  changeControlCitas(indexConfig){
    let {controlCitas,configActual,idConfig} = this.state
    configActual = controlCitas.filter(c => c['ID']===indexConfig)
    configActual = configActual[0]
    idConfig = configActual['ID']

    this.setState({configActual,indexConfig,mes:{nombre:'',citas:[]},idConfig})
    setTimeout(()=>this.actualizarCatalogo(),100)
  }

  agregarCita(op,nocambiar=false){
    let {currentCita,currentCitaCopy,hoy,fechaReagendar,citaInterface,formatedDate,idCliente,estatus,isNew,metaCustom,mutableDataCita,toCancel} = this.state
    let motivo = currentCita['cita']['MOTIVOS_EMPRESA'] ? currentCita['cita']['MOTIVOS_EMPRESA'] : ''
    if(op===3){//cancelando la cita
      motivo = document.getElementById('motivoCancel').value.trim()
      if(motivo===''){
        this.setState({txtAlert:'El motivo de cancelación es obligatorio',modalAlert:true})
        return
      }
      document.getElementById('motivoCancel').value=''
    }
    
    let indicacion = document.getElementById('indicacion').value.trim()
    let date = toCancel.format('YYYY-MM-DD') //hoy.format('YYYY-MM-DD')

    let inicio = currentCita['inicio'],
        fin = currentCita['fin'],
        idHorarioAtencionCita = currentCita['idHorarioAtencionCita']

    if(op === 1){
      inicio = mutableDataCita['inicio']
      fin = mutableDataCita['fin']
      idHorarioAtencionCita = mutableDataCita['idHorarioAtencionCita']
    }

    if(op===2){//guardar con un posible cambio
      /*
        En algun momento del tiempo, el codigo escrito en este if no reagendaba citas que fueran cambiadas de hora, pero que mantenian el dia de la cita igual.
        Para solucionar esto, se optó por hacer la comparacion de la misma hora basado en lo que hay almaceado en el estado <mutablecita>, sin
        embargo, eso hacia que en la view, el horario siepre estuviera estatico, por lo que he decidido no enderizar currentCita['inicio], en su lugar
        renderizare mutablecita['inicio] (:
      */
      let oldFecha = moment(currentCitaCopy['cita']['FECHA_CITA_INICIO'],'YYYY-MM-DD HH:mm:ss')
      date = fechaReagendar.format('YYYY-MM-DD')
      
      inicio = mutableDataCita['inicio']
      fin = mutableDataCita['fin']
      idHorarioAtencionCita = mutableDataCita['idHorarioAtencionCita']
      

      if(fechaReagendar.isSame(oldFecha,'day')){//es la misma fecha, pero puede ser distinta hora
        if( (currentCita['inicio']!==inicio && currentCita['fin'] !== fin) || indicacion !== currentCitaCopy['cita']['OBSERVACIONES_EMPRESA'].trim() || Number(currentCitaCopy['cita']['ESTATUS']) !== estatus ){
        }else{
          if(estatus!==1){
            this.setState({editanding:false,addingCita:false})
            return
          }
        }
      }else{
        //alert('si cambio')
      }
    }
    
    if(!nocambiar){
      let oldstatus = Number(currentCitaCopy['cita']['ESTATUS'])
      if(oldstatus===2&estatus>2){
        this.setState({confirmCambiarStatus:true,})
        return
      }
    }else{
      this.setState({confirmCambiarStatus:false})
    }

    estatus = estatus === 1 ? 2 : estatus
    estatus = op===3?6:estatus;
    if(isNew){
      date = fechaReagendar.format('YYYY-MM-DD')
    }
    formatedDate = capitalizeFirs(hoy.format('dddd, LL'))
    if(inicio === '--:--' || fin === '--:--'){
      this.setState({modalAlert:true,txtAlert:'Debe elegir un horario para la cita'})
      return
    }
    let citaToAdd = {
      idHorarioAtencionCita:idHorarioAtencionCita, //currentCita['idHorarioAtencionCita'],
      idCliente:idCliente,
      fkId:currentCita['cita']['FK_ID'],
      idUsuarioEmpresa:currentCita['cita']['ID_USUARIO_EMPRESA'],
      idPedido:currentCita['cita']['ID_PEDIDO'],
      fechaInicio: `${date} ${inicio}`,
      fechaTermino:`${date} ${fin}`,
      observacionesCliente:currentCita['cita']['OBSERVACIONES_CLIENTES']+'~Ç~'+currentCita['cita']['MOTIVOS_CLIENTES'],
      observacionesEmpresa:indicacion+'~Ç~'+motivo,
      calificacion:currentCita['cita']['CALIFICACION_CLIENTE'],
      estatus:estatus,
      id:currentCita['cita']['ID'],
      metaCustom:metaCustom,
      op:1,
      idE:sessionStorage.getItem('id'),
      esCliente:2,
    }
    document.getElementById('indicacion').value = ''
    
    this.setState({loading:true})  
    axios.post(`${host}citas/citas/agregarEditarCitaCliente`,citaToAdd,request).then(rt=>{
      if(rt.data['status'] === 'ok'){
        if(Number(rt.data['data'])===1){
          let {mes} = this.state
          let newCitacita = rt.data['EXE_CODE'][0]
          newCitacita['MINE'] = true
          if(op === 2|| op ===3){//editando
            mes['citas'] = mes['citas'].filter(c => c['ID'] !== citaToAdd['id'])
          }
          mes['citas'].push(newCitacita)
          currentCita = {cita:Object.create(citaInterface)}
          currentCitaCopy = {cita:Object.create(citaInterface)}
          estatus = 0
          mutableDataCita={ inicio:'', fin:'', idHorarioAtencionCita:'', }

          this.setState({mutableDataCita,loading:false,mes,currentCita,cargandoCitas:true,addingCita:false,editanding:false,currentCitaCopy,formatedDate,estatus,cancelanding:false,isNew:false,})
          let listaCitas = this.actualizarHorarios(hoy,false,true)
          this.setState({cargandoCitas:false,listaCitas,listaCitas2:[]})
        }else{ this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde',modalAlert:true,formatedDate,isNew:false,cancelanding:false})}
      }
      else{ this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde',modalAlert:true,formatedDate,isNew:false,cancelanding:false}) }
    })
  }

  eliminarCita(){
    let {currentCita,mes,citaInterface} = this.state
    this.setState({loading:true,confirmEliminar:false})
    let data = {idCita:currentCita['cita']['ID']}
    axios.post(`${host}citas/citas/eliminarCita`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let rta = Number(rt.data['result'])
        if(rta===1){
          currentCita = {cita:Object.create(citaInterface)}
          mes['citas'] = mes['citas'].filter(cita => cita['ID'] !== data['idCita'])
          this.setState({txtAlert:'Cita eliminada correctamente',modalAlert:true,mes,addingCita:false,currentCita,loading:false})
          this.actualizarCatalogo()
        } else{ this.setState({txtAlert:'Parece que ocurrió un error, por favor vuelve a intentalo',modalAlert:true,loading:false}) }
      }else{ this.setState({txtAlert:'Parece que ocurrió un error, por favor vuelve a intentalo',modalAlert:true,loading:false}) }
    })
  }

  setReagendarFecha(){
    let {fechaReagendar,listaCitas2,mes} = this.state
    let hoy = moment()
    if(fechaReagendar.isBefore(hoy,'day')){
      this.setState({modalAlert:true,txtAlert:'La fecha seleccionada no es válida'})
      return
    }
    
    if(fechaReagendar.format('MMMMYYYY')!==mes['nombre']){
      this.changeCalendar(fechaReagendar,true)
      return
    }

    listaCitas2 = this.actualizarHorarios(fechaReagendar,true,false)
    if(listaCitas2.length===0){
      this.setState({modalAlert:true,txtAlert:'No hay citas disponibles para la fecha seleccionada'})
      return
    }
    this.setState({reagending:false,reagending2:true,listaCitas2})
  }

  setReagendarHora(citaClick){
    if(citaClick['descanso']){
      this.setState({modalAlert:true,txtAlert:'El horario seleccionado no está disponible'})
      return
    }
    if(citaClick['cita'].hasOwnProperty('ID')){
      this.setState({modalAlert:true,txtAlert:'El horario seleccionado no está disponible'})
      return
    }
    let {fechaReagendar} = this.state
    let hoy = moment()
    if(fechaReagendar.isSame(hoy,'day')){
      let nuevaHora = moment(citaClick['inicio'],'HH:mm')
      let viejaHora = moment(fechaReagendar)
      if(nuevaHora.isBefore(viejaHora)){
        this.setState({modalAlert:true,txtAlert:'El horario seleccionado no está disponible'})
        return
      }
    }
    let {mutableDataCita} = this.state
    /*currentCita['inicio'] = citaClick['inicio']
    currentCita['fin'] = citaClick['fin']
    currentCita['idHorarioAtencionCita'] = citaClick['idHorarioAtencionCita']*/
    mutableDataCita['inicio'] = citaClick['inicio']
    mutableDataCita['fin'] = citaClick['fin']
    mutableDataCita['idHorarioAtencionCita'] = citaClick['idHorarioAtencionCita']
    this.setState({mutableDataCita,reagending2:false,})
  }

  onPanelChange(fechaReagendar){
    let{formatedDate} = this.state
    formatedDate = capitalizeFirs(fechaReagendar.format('dddd, LL'))
    this.setState({fechaReagendar,formatedDate})    
  }

  addNewCita(){
    let {currentCita,citaInterface,estatus,isNew,mutableDataCita} = this.state
    let citaActual = Object.create(citaInterface)
    Object.assign(citaActual,citaInterface)
    estatus=2
    let fakeCita = {inicio:'--:--',fin:'--:--',texto:'',clase:'',descanso:false,cita:citaActual,idHorarioAtencionCita:-1}
    currentCita = JSON.parse(JSON.stringify(fakeCita))
    mutableDataCita={ inicio:currentCita['inicio'], fin:currentCita['fin'], idHorarioAtencionCita:currentCita['idHorarioAtencionCita'], }
    isNew = true
    this.setState({addingCita:true,currentCita,estatus,isNew,mutableDataCita})
  }

  clickHoverable(op){
    let {isNew,caducada,finalizada,fechaReagendar,listaCitas2,justHora} = this.state
    if(!caducada && !finalizada && !isNew ){
      this.setState({reagending:true})
      return
    }
    if(isNew){
      if(op===1){ this.setState({reagending:true}) }
      if(op===2){
        let ahora = moment()
        if(fechaReagendar.isBefore(ahora,'day')){
          this.setState({modalAlert:true,txtAlert:'No hay horarios de atención disponibles para la fecha seleccionada.'})
          return
        }
        listaCitas2 = this.actualizarHorarios(fechaReagendar,true,false)
        justHora = true
        if(listaCitas2.length===0){
          this.setState({modalAlert:true,txtAlert:'No hay citas disponibles para la fecha actual.'})
          return
        }
        this.setState({reagending2:true,listaCitas2,justHora})
      }
    }
  }


  switchEstatus(estatus){
    estatus = Number(estatus)
    if(estatus===1)return "Pendiente"
    if(estatus===2)return "Confirmada"
    if(estatus===3)return "No asistió"
    if(estatus===4)return "Asistió"
    if(estatus===5)return "Reagendada"
    if(estatus===6)return "Cancelada"
    return estatus
  }

  cancelReagendarFecha(){
    let {toCancel,hoy} = this.state
    this.setState({reagending:false,fechaReagendar:toCancel, formatedDate: capitalizeFirs(toCancel.format('dddd, LL')) })
    
    if(!toCancel.isSame(hoy,'day')){
      this.changeCalendar(toCancel)
    }
  }

  render(){
    return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3">

            <Alert text={'Una vez eliminada, esta cita no podrá recuperarse.'} visible={this.state.confirmEliminar} withCancel onOk={this.eliminarCita} onCancel={()=>this.setState({confirmEliminar:false})}  />
            <Alert text={this.state.txtAlert} visible={this.state.modalAlert} onOk={()=>this.setState({txtAlert:'',modalAlert:false,})  } />
            <Alert text='No podrá volver a cambiar su estatus una vez realizada esta operación. ¿Desea continar?' visible={this.state.confirmCambiarStatus} onOk={() => this.agregarCita(2,true)  } withCancel onCancel={()=>this.setState({confirmCambiarStatus:false})} />

            {/* detalle calendar */}
            <Modal Modal centered title="" closable={false} header={null} footer={null} zIndex={5} visible={this.state.reagending}
              className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}}
              onCancel={()=>document.getElementById('reagendingButton').click()}> >
              <div className="reagenda">

                <div className="myheadCalendar flexcenter Fspaceb">
                  <Icon type="left" className="control left" onClick={()=>this.setState({fechaReagendar: this.state.fechaReagendar.add(-1,'months') })} />
                  <span>{this.state.fechaReagendar.format('DD-MMMM-YYYY')}</span>
                  <Icon type="right" className="control right" onClick={()=>this.setState({fechaReagendar: this.state.fechaReagendar.add(1,'months') })} />
                </div>

                <Calendar className="mycalendar" value={this.state.fechaReagendar}
                //dateFullCellRender={this.cellrender}
                //onSelect={this.onSelect}
                //onPanelChange={this.onPanelChange}
                onChange={this.onPanelChange}
                />
                <div className="flexcenter Fend" style={{padding:'0 10px'}}>
                  <button className="flexcenter Fcenter btn btn2" id="reagendingButton" onClick={()=>this.cancelReagendarFecha()} > Cancelar </button>
                  <button className="flexcenter Fcenter btn btn1" onClick={()=>this.setReagendarFecha(false)} > Aceptar </button>
                </div>
              </div>
            </Modal>

            {/* Tarjetas cinepolis */}
            <Modal Modal centered title="" header={null} footer={null} zIndex={5} 
              onCancel={
                ()=>{
                  let {justHora} = this.state
                  if(justHora){
                    this.setState({reagending2:false,justHora:false,})
                  }
                  else{
                    this.setState({reagending2:false,reagending:true})
                  }

                }
              }
              visible={this.state.reagending2} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
              <div className="reagenda">
                <div className="title"> Selecione un horario para la cita </div>
                <label className="nta"> Duración de la cita: {this.state.duracionCita} minutos. </label>
                <div className="citasCinepolis">
                {
                  this.state.listaCitas2.map((cita,i)=>(
                    <div className="cuadrito flexcenter Fspacea" key={'cuadrito_'+i} onClick={()=>this.setReagendarHora(cita)} >
                      <div className={'cirulo '+(cita['descanso']?' descanso':cita['clase'])} />
                      {cita['inicio']}
                    </div>
                  ))
                }
                </div>
              </div>
            </Modal>

            {/* detalle cita */}
            <Modal centered title="" header={null} footer={null} zIndex={4} onCancel={ ()=> this.setState({addingCita:false,editanding:false, currentCita:{cita:Object.create(this.state.citaInterface)}, fechaReagendar:this.state.hoy, formatedDate : capitalizeFirs(this.state.hoy.format('dddd, LL')) , idCliente:-1, caducada:false, finalizada:false, isNew:false,} ) }
              visible={this.state.addingCita} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
              <div className="controlesNuevaCita">
                <div className="title"> Detalle de la cita </div>
                
                <div style={{position:'relative'}} >
                  <label className={"nta flexcenter Fcenter linkeable"} onClick={()=>this.clickHoverable(1)} >
                    {this.state.formatedDate}
                  </label>

                  {
                  (
                    !this.props.validCurrentUser(this.context,'citas','bool',this.state.codeConfig,'EDITAR')
                    &&
                    this.state.editanding
                  )&&
                  <div className='fakemask' style={{zIndex:'201',top:'-5px',left:'-2.5%',width:'105%',height:'35px'}}
                    title='El perfil actual no tiene permisos para editar Citas sobre la sucursal relacionada con el registro actual.'
                    onClick={()=>this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Citas sobre la sucursal relacionada con el registro actual.'})} />
                  }
                </div>

                <div className="detalle">

                  <div className="data flexcenter Fspaceb">
                    <label> Cliente </label>
                  </div>

                  <div className="data flexcenter Fspaceb">
                    {
                      this.state.editanding?
                      <Input className="inputs-input" value={this.state.idCliente === -1 ? 'Usuario no registrado' : this.state.currentCita['cita']['NOMBRE_CLIENTE']  } readOnly />:
                      <Select className="inputs-input" style={{width:'100%'}} value={this.state.idCliente} onChange={idCliente => this.setState({idCliente}) }>
                        <Select.Option value={-1} > Usuario no registrado </Select.Option>
                        {
                          this.state.listaClientes.map((cliente,i) => (
                            <Select.Option key={'listCli_'+i} value={cliente['ID_CLIENTE']}> {cliente['NOMBRE']} </Select.Option>
                          ))
                        }
                      </Select>
                    }
                  </div>
                  
                  <div className="data flexcenter Fspaceb middle" style={{position:'relative'}} >
                    <label>Horario cita</label>
                    <label>Estatus</label>
                    {
                    (
                      !this.props.validCurrentUser(this.context,'citas','bool',this.state.codeConfig,'EDITAR')
                      &&
                      this.state.editanding
                    )&&
                    <div className='fakemask' style={{zIndex:'201',top:'-5px',left:'-2.5%',width:'105%',height:'75px'}}
                      title='El perfil actual no tiene permisos para editar Citas sobre la sucursal relacionada con el registro actual.'
                      onClick={()=>this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Citas sobre la sucursal relacionada con el registro actual.'})} />
                    }

                  </div>


                  <div className="data flexcenter Fspaceb middle" >
                    <Input className={"inputs-input linkeable"}
                      value={this.state.mutableDataCita['inicio']+'-'+this.state.mutableDataCita['fin']}
                      readOnly onClick={()=>this.clickHoverable(2)} />
                    {
                      this.state.caducada && this.state.finalizada?
                        <Input className="inputs-input" value={this.switchEstatus(this.state.estatus)} readOnly />
                      :this.state.caducada?
                        <Select className="inputs-input" value={this.state.estatus}  onChange={estatus => this.setState({estatus})}  >
                          <Select.Option value={1} style={{display:'none'}} > Pendiente </Select.Option>
                          <Select.Option value={2} style={{display:'none'}} > Confirmada </Select.Option>
                          <Select.Option value={3} > No asistió </Select.Option>
                          <Select.Option value={4} > Asistió </Select.Option>
                        </Select>:
                      this.state.finalizada?
                        <Input className="inputs-input" value={this.switchEstatus(this.state.estatus)} readOnly />:
                        <Select className="inputs-input" value={this.state.estatus}  onChange={estatus => this.setState({estatus})} readOnly >
                          <Select.Option value={1} style={{display:'none'}} > Pendiente </Select.Option>
                          <Select.Option value={2} > Confirmada </Select.Option>
                        </Select>
                    }
                  </div>

                  <div className="data flexcenter Fspaceb">
                    <label> Comentarios por parte del cliente </label>
                  </div>
                  <Input.TextArea className="inputs-input notfocusable" value={this.state.currentCita['cita']['OBSERVACIONES_CLIENTES']} readOnly maxLength={450} autoSize={{minRows: 1, maxRows:4,} } />

                  {
                    Number(this.state.estatus)===6&&
                    this.state.currentCita['cita']['MOTIVOS_CLIENTES']!==''&&
                    <React.Fragment>
                      <div className="data flexcenter Fspaceb">
                        <label> Motivo de la cancelación </label>
                      </div>
                      <Input.TextArea className="inputs-input" value={this.state.currentCita['cita']['MOTIVOS_CLIENTES']} readOnly maxLength={50} autoSize={{minRows: 1, maxRows:4,} } />
                    </React.Fragment>
                  }

                  <div className="data flexcenter Fspaceb">
                    <label> Comentarios por parte de la empresa </label>
                  </div>

                  {
                    this.state.addingCita&&
                    <Input.TextArea className="inputs-input" readOnly={Number(this.state.currentCita['cita']['ESTATUS'])!==1} defaultValue={this.state.currentCita['cita']['OBSERVACIONES_EMPRESA']} id="indicacion" maxLength={450} autoSize={{minRows: 1, maxRows:4,} } placeholder="Agregar comentario o indicación" />   
                  }


                  {
                    Number(this.state.estatus)===6&&
                    this.state.currentCita['cita']['MOTIVOS_EMPRESA']!==''&&
                    <React.Fragment>
                      <div className="data flexcenter Fspaceb">
                        <label> Motivo de la cancelación </label>
                      </div>
                      <Input.TextArea className="inputs-input" value={this.state.currentCita['cita']['MOTIVOS_EMPRESA']} readOnly maxLength={50} autoSize={{minRows: 1, maxRows:4,} } />
                    </React.Fragment>
                  }



                  {
                    this.state.editanding?//editando un cita
                    <div style={{marginTop:'25px'}} className="contButtons">
                    {
                      (!this.state.finalizada && this.state.caducada) ? //fecha vencida, pero esta pendiente o confirmada
                      <React.Fragment>
                        {
                          (Number(this.state.currentCita['cita']['ESTATUS'])===1 && this.props.validCurrentUser(this.context,'citas','bool',this.state.configActual['CODE'],'ELIMINAR'))&&
                          <React.Fragment>
                            <button className="flexcenter Fcenter btn btn2" style={{width:'100%'}} onClick={()=>this.setState({confirmEliminar:true})} > Eliminar </button>
                          </React.Fragment>
                        }

                        {
                          this.props.validCurrentUser(this.context,'citas','bool',this.state.configActual['CODE'],'EDITAR')&&
                          <React.Fragment>
                            <button className="flexcenter Fcenter btnAdd" onClick={()=>this.agregarCita(2)} > {Number(this.state.currentCita['cita']['ESTATUS'])===1 ? 'Confirmar' : 'Guardar'} </button>
                          </React.Fragment>
                        }


                      </React.Fragment>:
                      !this.state.finalizada ? //fecha futura, y aun no finalizada
                      <React.Fragment>

                        <div className="flexcenter Fspaceb">
                          {
                            Number(this.state.currentCita['cita']['ESTATUS'])===1?
                            <React.Fragment>
                            {
                              this.props.validCurrentUser(this.context,'citas','bool',this.state.configActual['CODE'],'ELIMINAR')&&
                              <button style={{flexGrow:'1',marginRight:'20px'}} className="flexcenter Fcenter btn btn2" onClick={()=>this.setState({confirmEliminar:true})} > Eliminar </button>
                            }
                            </React.Fragment>:
                            <React.Fragment>
                            {
                              this.props.validCurrentUser(this.context,'citas','bool',this.state.configActual['CODE'],'EDITAR')&&
                              <button style={{flexGrow:'1',marginRight:'20px'}} className="flexcenter Fcenter btn btn2" onClick={()=>this.setState({cancelanding:true})} > Cancelar </button>
                            }
                            </React.Fragment>
                          }

                          <React.Fragment>
                          {
                            this.props.validCurrentUser(this.context,'citas','bool',this.state.configActual['CODE'],'EDITAR')&&
                            <button className="flexcenter Fcenter btn btn2" style={{color:'#F07505',flexGrow:'1'}} id="btnReagendar" onClick={()=>this.setState({reagending:true,justHora:false})} > Reagendar </button>
                          }
                          </React.Fragment>

                        </div>

                        <React.Fragment>
                          {
                            this.props.validCurrentUser(this.context,'citas','bool',this.state.configActual['CODE'],'EDITAR')&&
                            <button className="flexcenter Fcenter btnAdd" onClick={()=>this.agregarCita(2)} >
                              {Number(this.state.currentCita['cita']['ESTATUS'])===1 ? 'Confirmar' : 'Guardar'}
                            </button>
                          }
                        </React.Fragment>


                      </React.Fragment>:

                      <React.Fragment/>
                    }
                    </div>
                    ://agregando una cita
                    <React.Fragment>
                      {
                        this.props.validCurrentUser(this.context,'citas','bool',this.state.configActual['CODE'],'CREAR')&&
                        <button style={{marginTop:'25px'}} className="flexcenter Fcenter btnAdd" onClick={()=>this.agregarCita(1)} > Guardar </button>
                      }
                    </React.Fragment>
                  }

                </div>
              </div>
            </Modal>

            {/* motivo cancelacion */}
            <Modal centered title="" header={null} footer={null} zIndex={5} onCancel={()=>this.setState({cancelanding:false}) }
              visible={this.state.cancelanding} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
              <div className="controlesNuevaCita">
                <div className="title"> Ingrese el motivo de la cancelación </div>
                <div className="data flexcenter Fspaceb">
                  <label>Motivo de cancelación </label>
                </div>
                <Input.TextArea className={"inputs-input"} id="motivoCancel" maxLength={50} autoSize={{minRows:1,maxRows:4}} />
                <button style={{marginTop:'25px'}} className="flexcenter Fcenter btnAdd" onClick={()=>this.agregarCita(3)} > Aceptar </button>
              </div>
            </Modal>

            {/* Filtros */}
            <div className="tituloYControles">
              <div className="metaControles"> 
                <h3 className="tituloPanel flexcenter Fspaceb" >
                  Citas agendadas
                  <Popover
                    title=""
                    trigger={'click'}
                      content={
                        <div className="op-Suc">
                          <label onClick={()=>this.props.redirectTo2('/home/agenda')} > Ver todas mis citas</label>
                        </div>
                      }
                      overlayClassName="pop-Suc"
                      placement="bottomLeft" >
                    <Icon type="more" className="moreDots" /> 
                  </Popover>
                </h3>
              </div>
            </div>

            <div className="filtradosExtras outer flexcenter Fspaceb ">

              <div className="inputs-group-inline" style={{width:'50%'}} >
                <label className="inputs-label" htmlFor="pesopd" >  Sucursal </label>
                <Select className="inputs-input" value={this.state.indexConfig} onChange={this.changeControlCitas} >
                  <Select.Option value='' style={{display:'none'}} > Sin configuraciones de citas </Select.Option>
                {
                  this.state.controlCitas.filter(sucursal => this.props.validCurrentUser(this.context,'citas','bool',sucursal['CODE'],false ) )
                  .map((sucursal,i)=>(
                    <Select.Option value={sucursal['ID']} key={'sucursal_'+i}> {sucursal['NOMBRE_SUCURSAL']} </Select.Option>
                  ))
                }
                </Select>
              </div>
              
              <div className="inputs-group-inline" style={{width:'50%',position:'relative'}} >
                <label className="inputs-label" htmlFor="pesopd">  Filtrar por fecha </label>
                <input className="inputs-input" id="fecha" value={this.state.hoy.format('DD-MMMM-YYYY')} readOnly />
                
                <div className="wrapperCalendar">
                  
                  <div className="myheadCalendar flexcenter Fspaceb">
                    <Icon type="left" className="control left" onClick={()=>this.changeMonth(-1)} />
                    <span>{this.state.hoy.format('DD-MMMM-YYYY')}</span>
                    <Icon type="right" className="control right" onClick={()=>this.changeMonth(1)} />
                  </div>

                  <Calendar className="mycalendar" headerRender={null} fullscreen={false} value={this.state.hoy} onChange={this.changeCalendar} />
                </div>
              </div>
            </div>

            <div className="conCitas">
            {
              this.state.controlCitas.length===0||this.state.listaCitas.length===0?
              <div className="nonResult flexcenter Fspaceb" style={{position:'relative'}} >
                <img alt="..." src={imgAdmin+'noncitas.png'} />
                {
                  !this.props.validCitas && 
                  <button
                    style={{
                      position: 'absolute',
                      top: '100%',
                      marginTop: '1.5rem',
                    }}
                    className="btn btn1"
                    onClick={()=> this.props.redirectTo2('/home/configuracion')}
                  > Ir a configuración </button>
                }
              </div>:
              this.state.listaCitas.map((cita,i) => (
                <div key={'cita_E'+i} className="instanciaCita flexcenter Fspaceb" onClick={()=>this.clickOnCita(cita)} >
                  <div className="horas flexcenter Fcenter">
                    <span> {cita['inicio']} </span>
                    <span> {cita['fin']} </span>
                  </div>
                  
                  <div className={"texto flexcenter Fstart "+(cita['descanso']?' descanso':'')}>
                    <div className={cita['clase']+' barras'} />
                    
                    <div className="inTexto flextop Fcenter">
                      <span>{cita['texto']}</span>
                      {
                        (cita['cita'].hasOwnProperty('OBSERVACIONES_CLIENTES') && cita['cita']['OBSERVACIONES_CLIENTES'].trim() !=='' )
                        &&
                        <label>{cita['cita']['OBSERVACIONES_CLIENTES']}</label>
                      }
                    </div>

                  </div>

                </div>
              ))
            }
            </div>

            {
              (
                this.state.configActual.hasOwnProperty(['CODE']) &&
                this.props.validCurrentUser(this.context,'citas','bool',this.state.configActual['CODE'],'CREAR') &&
                !this.state.addingCita &&
                this.props.validCitas
              )&&
              <div className="buttomFlotanding" title="Agregar nuevo registro" onClick={this.addNewCita} > + </div>
            }


            <div className="cont-instancias5"></div>

            <Loading loading={this.state.loading} />
            
          </div>
        </div>
       </div>
    );
  }
}

export default withRouter(Lista);