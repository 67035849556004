import React from 'react';
import axios from 'axios';
import {Globalrequest as request} from './../../../request.js'
import { Host as host,ImgPanelAdmin as panelAdmin,Img as img} from './../../../app.json';

import Loading from './../../../modalCargando/loading';
import Spinner from './../../../modalCargando/loadingSpinner';
import Alert from './../../../modalCargando/modalAlert';

/* Librerías */
import { Icon} from 'antd';
import { withRouter } from "react-router-dom";

class Direcciones extends React.Component{
  constructor(props) {
    super(props);
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.confirmEliminar = this.confirmEliminar.bind(this)
    this.submitEliminar = this.submitEliminar.bind(this)
    this.borrarHistorial = this.borrarHistorial.bind(this)
    
    this.state={
    	id:sessionStorage.getItem('id'),
      listado:[],

      loading:false,
      modalAlert:false,
      txtAlert:'',
      modalEliminar:false,
      txtEliminar:'',
      txtLimpiar:'',
      modalLimpiar:false,

      idEliminar:-1,
    }
  }

  componentDidMount(){
    document.title = "Favoritos"
    
    let {makeRef} = this.props
    makeRef({mounted:true,component:this,name:'Historial'})

    const {id} = this.state

    if(!id)return    
    this.actualizarCatalogo()
  }

  actualizarCatalogo(){
    let {id,listado} = this.state
    let { stateHandler } = this.props
    let data = {idB:id,tipo:1}
    this.setState({loading:true,})
    let metadata = JSON.parse(sessionStorage.getItem('metadata'))
    axios.post(`${host}usuarios/usuarios/listaFavoritas/0`,data,request).then(rt=>{
      if(rt.data['status'] === 'ok'){
        listado = rt.data['data']
        metadata['favoritas'] = listado
        sessionStorage.setItem('metadata',JSON.stringify(metadata))
        if(listado!==null&&listado!==undefined){
          stateHandler('favoritos',listado)
        }
        else {listado = []}
        this.setState({listado,loading:false})
      }
    })
  }

  confirmEliminar(name,id,tipo){
    if(tipo===1){
      this.setState({modalEliminar:true,txtEliminar:'¿Está seguro que desea eliminar a "'+name+'" de su historial de navegación?',idEliminar:id})
      return
    }
    if(tipo===2){
      this.setState({modalLimpiar:true,txtLimpiar:'¿Está seguro que desea eliminar su historial de navegación?',})
    }
  }

  submitEliminar(){
    let {idEliminar,listado} = this.state
    let data={idFav:idEliminar,op:-1}
    this.setState({loading:true,modalEliminar:false,txtEliminar:''})
    axios.post(`${host}usuarios/usuarios/eliminarAgregarFavoritas/0`,data,request).then(rt=>{
      let rta = Number(rt.data),msj=''
      if(rta===1){
        msj = "La empresa ha sido eliminada correctamente de tu lista de favoritos"
        listado = listado.filter(dir => dir['registro']!==idEliminar)
        let metadata = JSON.parse(sessionStorage.getItem('metadata'))
        metadata['favoritas'] = listado
        sessionStorage.setItem('metadata',JSON.stringify(metadata))
      }else{
        msj="Parece que ocurrió un error, por favor intentalo más tarde."
      }
      this.setState({loading:false,txtAlert:msj,modalAlert:true,listado,idEliminar:-1})
    })
  }

  detalle(link){
    let {indiceRedirecter} = this.props
    indiceRedirecter('/empresas/'+link)
  }

  borrarHistorial(){
    let data = {idB:sessionStorage.getItem('id')}
    this.setState({loading:true,txtLimpiar:'',modalLimpiar:false})
    axios.post(`${host}usuarios/usuarios/borrarHistorialUsuario/0`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let rta = Number(rt.data['data'])
        let {listado} = this.state
        let msj = ''
        if(rta===1){
          listado = []
          msj="El historial de navegación ha sido eliminado correctamente"
        }else{
          msj="Parece que ocurrió un error, por favor intentalo más tarde."
        }
        this.setState({loading:false,txtAlert:msj,modalAlert:true,listado})
      }else{
        this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde.',modalAlert:true,})
      }
    })
  }

  errorImg(e){
    e.target.src=panelAdmin+'perfilDefault.jpg'
  }

  render(){
    return(
  		<div className="containerBrowserBubookerChildTwo">
      <div className="containerBrowserBubookerChildThree">
        
        <Loading loading={this.state.loading} />
        <Spinner loading={this.state.modalSpinner} text={this.state.txtSpinner}/>
        <Alert text={this.state.txtAlert} visible={this.state.modalAlert} onOk={()=>this.setState({modalAlert:false,txtAlert:''})} /> 
        <Alert text={this.state.txtEliminar} visible={this.state.modalEliminar} withCancel onOk={this.submitEliminar} onCancel={()=>this.setState({modalEliminar:false,txtEliminar:''})}  /> 
        <Alert text={this.state.txtLimpiar} visible={this.state.modalLimpiar} withCancel onOk={this.borrarHistorial} onCancel={()=>this.setState({modalLimpiar:false,txtLimpiar:''})}  /> 

        <div style={{marginTop:'20px'}} className="itemBackBu flexcenter Fstart" onClick={()=>this.props.indiceRedirecter('/')} >
          <Icon type="left" />
          <span> Regresar </span>
        </div>
                
        <div className="titleSectionBubooker flexcenter Fspaceb">

          Historial de navegación

          {
            this.state.listado.length>0&&
            <button className="btnClear" onClick={()=>this.confirmEliminar( 0 , 0 ,2)} > Limpiar historial </button>
          }

        </div>

        <div className="listAdressClient">
        {
          this.state.listado.length===0?
          <div className="nonResult flexcenter Fspaceb">
            <img alt="..." src={panelAdmin+'nonproductos.png'} />
          </div>:
          this.state.listado.map((dir,i) => (
            <div className="itemDireccion flexcenter Fspaceb" key={'dir_'+i}>

              <div className="metaInfo flexcenter Fstart">
              
                <img alt="..." src={dir['folder']+dir['src']+'.png'}  onError={this.errorImg} />

                <div className="info flextop Fstart">
                  <h4>{dir['nombre']}</h4>
                  <span> {dir['desc'] ==='' ? 'Sin descripción disponible.' : dir['desc']} </span>
                  <label className="delete" onClick={()=>this.confirmEliminar( dir['nombre'] , dir['registro'] ,1)} > Eliminar </label>
                </div>

              </div>

              <button className="detail" onClick={()=>this.detalle(dir['link'])} > Ver empresa </button>

            </div>
          ))
        }
        </div>
      </div>
  		</div>
    );
  }
}

export default withRouter(Direcciones);