import React from 'react';
import axios from 'axios';
import {Globalrequest as request} from './../../../request.js'
import { Host as host,ImgPanelAdmin as panelAdmin,Img as img} from './../../../app.json';

import Spinner from './../../../modalCargando/loadingSpinner';
import Alert from './../../../modalCargando/modalAlert';
import {Corazon} from "./../../../utilities/svg.js";

/* Librerías */
import { withRouter } from "react-router-dom";

import './favoritos.scss'

class Direcciones extends React.Component{
  constructor(props) {
    super(props);
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.submitEliminar = this.submitEliminar.bind(this)
    
    this.state={
    	id:sessionStorage.getItem('id'),
      listado:[],

      loading:false,
      modalAlert:false,
      txtAlert:'',
    }
  }

  componentDidMount(){
    document.querySelector('#fotoCorazonTrigger').src = panelAdmin+"/favoritos.svg"
    this.actualizarCatalogo()
  }

  componentWillUnmount(){ document.querySelector('#fotoCorazonTrigger').src = panelAdmin+"/favoritosB.svg" }

  actualizarCatalogo(){
    let {id,listado} = this.state
    let { stateHandler } = this.props
    let data = {idB:id,tipo:0}
    this.setState({loading:true,})
    let metadata = JSON.parse(sessionStorage.getItem('metadata'))
    axios.post(`${host}usuarios/usuarios/listaFavoritas/0`,data,request).then(rt=>{
      if(rt.data['status'] === 'ok'){
        listado = rt.data['data']
        metadata['favoritas'] = listado
        sessionStorage.setItem('metadata',JSON.stringify(metadata))
        if(listado!==null&&listado!==undefined){
          stateHandler('favoritos',listado)
        }
        else {listado = []}
        this.setState({listado,loading:false})
      }
    })
  }

  submitEliminar(idEliminar,e){
    let {listado} = this.state
    let data={idFav:idEliminar,op:-1}
    axios.post(`${host}usuarios/usuarios/eliminarAgregarFavoritas/0`,data,request).then(rt=>{
      let rta = Number(rt.data)
      if(rta===1){
        listado = listado.filter(dir => dir['registro']!==idEliminar)
        let metadata = JSON.parse(sessionStorage.getItem('metadata'))
        metadata['favoritas'] = listado
        sessionStorage.setItem('metadata',JSON.stringify(metadata))
        this.setState({listado})
      }
    })
    e.stopPropagation()
  }

  detalle(link){
    let {indiceRedirecter,showDataComponentMounted} = this.props
    let component = showDataComponentMounted();
    indiceRedirecter('/empresas/'+link)
    if(component['mounted']&&component['name']==="Perfil"){
      component['component'].actualizarPerfil(link);
    }
  }

  errorImg(e,a){
    e.target.src=panelAdmin+'perfilDefault.jpg'
  }

  render(){
    return(
  		<div className="contentFavsEmbed">

        <Spinner loading={this.state.modalSpinner} text={this.state.txtSpinner}/>
        <Alert text={this.state.txtAlert} visible={this.state.modalAlert} onOk={()=>this.setState({modalAlert:false,txtAlert:''})} /> 
        
        <div className="titleSectionBubooker flexcenter Fstart" style={{fontSize:'17px',padding:'10px 15px'}} >
          Mis empresas favoritas
        </div>

        <div className="listAdressClient">
        {
          this.state.listado.length===0&&!this.state.loading?
          <div className="nonResult flexcenter Fspaceb" style={{flexDirection:'column'}} >
            <img alt="..." src={panelAdmin+'nonproductos.png'} height={300} />
            <span className="nonResult flexcenter Fcenter" style={{fontSize:'15px'}} > ¡No se encontraron resultados! </span>
          </div>:
          this.state.listado.map((dir,i) => (
            <div className="itemDireccion fav flexcenter Fspaceb" key={'dir_'+i} onClick={()=>this.detalle(dir['link'])} >

              <div className="metaInfo flexcenter Fstart">
              
                <img alt="..." src={dir['folder']+dir['src']+'.png'} onError={this.errorImg} />

                <div className="info flextop Fstart">
                  <h4>{dir['nombre']}</h4>
                  {/*<span> {dir['desc'] ==='' ? 'Sin descripción disponible.' : dir['desc']} </span>*/}
                  <span> {dir['cat'] ==='' ? 'Sin categoría disponible.' : dir['cat']} </span>
                </div>

              </div>

              <i style={{cursor:'pointer'}}
                 onClick={ (e) => 
                   this.submitEliminar(dir['registro'],e)
                 }>
                <Corazon className="coraFavs" />
              </i>

            </div>
          ))
        }
        </div>
  		</div>
    );
  }
}

export default withRouter(Direcciones);