import React from 'react';

import axios from 'axios';
import 'moment/locale/es'
import moment from 'moment'
import { Globalrequest as request } from './../../../request.js';
import {Host as host ,  Img as img,ImgPanelAdmin as ImgPanel} from './../../../app.json'
import Cropper from 'react-cropper';
import NumberFormat from 'react-number-format';

import Loading from '../../../modalCargando/loading';

import { Select , Modal, DatePicker,TimePicker,Row,Col,Switch,Input,Popover,Checkbox} from 'antd';

import {userContext} from './../context'

import {formatNumber} from "./../../../utilities/generalfunctions";

import {withRouter} from 'react-router-dom'
import   './promociones.scss';

class Registrar extends React.Component{

  static contextType = userContext

  constructor(props) {
    super(props);
    this.changeState = this.changeState.bind(this)
    this.changeStatus = this.changeStatus.bind(this)
    
    this.showModalProductos = this.showModalProductos.bind(this)
    
    /*tipo2*/
    this.addPromo = this.addPromo.bind(this)

    this.agregarProductos = this.agregarProductos.bind(this)
    this.NoagregarProductos = this.NoagregarProductos.bind(this)

    /*tipo3*/    
    this.miniModal3Clicker = this.miniModal3Clicker.bind(this)
    this.setPrincipal = this.setPrincipal.bind(this)
    this.setSecundario = this.setSecundario.bind(this)

    this.closeModalAux = this.closeModalAux.bind(this)

    this.ponCantidadPrincipal = this.ponCantidadPrincipal.bind(this)
    this.ponCantidadSecundaria = this.ponCantidadSecundaria.bind(this)
    this.noPongasCantidadPrincipal = this.noPongasCantidadPrincipal.bind(this)

    this.saveProducto3 = this.saveProducto3.bind(this)

    /*tipo4*/
    this.miniModal4Clicker = this.miniModal4Clicker.bind(this)
    this.setPrincipal2 = this.setPrincipal2.bind(this)
    this.setSecundario2 = this.setSecundario2.bind(this)

    this.closeModalAux2 = this.closeModalAux2.bind(this)

    this.ponCantidadPrincipal2 = this.ponCantidadPrincipal2.bind(this)
    this.ponCantidadSecundaria2 = this.ponCantidadSecundaria2.bind(this)
    this.noPongasCantidadPrincipal2 = this.noPongasCantidadPrincipal2.bind(this)

    this.saveProducto4 = this.saveProducto4.bind(this)

    /**/    

    this.modalEliminarSucursal = this.modalEliminarSucursal.bind(this)
    this.eliminarRegistroPromocion = this.eliminarRegistroPromocion.bind(this)
    
    this.addDesc = this.addDesc.bind(this)

    this.changeFiltros = this.changeFiltros.bind(this)

    this.actualizarSucursales = this.actualizarSucursales.bind(this)
    this.actualizarProductos = this.actualizarProductos.bind(this)
    this.actualizarCategorias = this.actualizarCategorias.bind(this)
    
    this.changeTipoDescuento = this.changeTipoDescuento.bind(this)
    this.changeTipoDescuento2 = this.changeTipoDescuento2.bind(this)

    this.changeTipoPromocion = this.changeTipoPromocion.bind(this)
    this.aceptChangeTipoPromocion = this.aceptChangeTipoPromocion.bind(this)

    this.changeCambioEn = this.changeCambioEn.bind(this)
    this.eliminarSucursalDePromocion = this.eliminarSucursalDePromocion.bind(this)


    this.fileChange = this.fileChange.bind(this)
    this.cancelCroper = this.cancelCroper.bind(this)
    this.aceptCroper = this.aceptCroper.bind(this)
    this.submit = this.submit.bind(this)
    this.cancelar = this.cancelar.bind(this)
    this.clearSrc = this.clearSrc.bind(this)

    /**/
    this.changeSwitch2 = this.changeSwitch2.bind(this)
    this.controlSemanal = this.controlSemanal.bind(this)
    this.horaChange = this.horaChange.bind(this)
    /**/

    this.state = {
      filtro1:'',
      filtro2:'',
      filtro3:'',
      /**/
      tipoDesc:-1,
      desc:'',
      sucursales : this.props.sucursales,
      sucursales2 : [],
      productos : [],
      productosFiltrados : [],
      catalogoDeCategorias : [],
      catalogoDeCategorias2 : [],
      productosDePromo:[],
      detalles:[],
      detallesAux:[],
      /**/
      idPromo:0,
      elegido:1,
      nextelegido:0,
      status:'1',
      fecha1:moment(),
      fecha2:moment().add(1,'days'),
      hora1:moment(),
      hora2:moment(),
      src:'',
      src2:'',
      /**/
      addingProducto:false,
      addingDesc:false,

      miniModalTipo3:false,
      addingProducto21:false,
      addingProducto22:false,

      addingProducto31:false,
      addingProducto32:false,

      modalAlert:false,
      txtAlert:'',

      modalRespuesta:false,
      txtRespuesta:'',

      modalTipo:false,
      txtTipo:'',

      tiposPromo:[
        {value:1, label: 'Informativa o explicativa', render:' (Imagen con título y descripción que hace referencia a la promoción o descuento)'},
        {value:2, label: 'Descuento directo', render:' (Productos o servicios con descuento porcentual o monetario)'},
        {value:3, label: 'Compra con recompensa gratis', render:' (Productos o servicios que al comprase otorgan gratis una cantidad de algún producto o servicio)'},
        {value:4, label: 'Compra con recompensa de descuento', render:' (Productos o servicios que al comprase otorgan un descuento porcentual o monetario a una cantidad de algún producto o servicio)'},
      ],
      /**/
      productosTipo3:[],
      productosSecundariosTipo3:[],
      productoTipo3Aux:{},
      productoTipo3AuxSecundario:{},
      /**/
      productosTipo4:[],
      productosSecundariosTipo4:[],
      productoTipo4Aux:{},
      productoTipo4AuxSecundario:{},
      indiceElegido:0,
      /**/
      modalEliminar:false,
      indiceEliminar:-1,
      /**/
      modalDisableSucursal:false,
      indiceEliminarSucursal:-1,
      /**/
      desc2:'',
      /**/
      horarios:[
        {id:-1,dia:1,horaInicio:'00:00',momentInicio:moment('00:00','HH:mm'),horaTermino:'00:00',momentTermino:moment('00:00','HH:mm'),estatus:2},
        {id:-1,dia:2,horaInicio:'00:00',momentInicio:moment('00:00','HH:mm'),horaTermino:'00:00',momentTermino:moment('00:00','HH:mm'),estatus:2},
        {id:-1,dia:3,horaInicio:'00:00',momentInicio:moment('00:00','HH:mm'),horaTermino:'00:00',momentTermino:moment('00:00','HH:mm'),estatus:2},
        {id:-1,dia:4,horaInicio:'00:00',momentInicio:moment('00:00','HH:mm'),horaTermino:'00:00',momentTermino:moment('00:00','HH:mm'),estatus:2},
        {id:-1,dia:5,horaInicio:'00:00',momentInicio:moment('00:00','HH:mm'),horaTermino:'00:00',momentTermino:moment('00:00','HH:mm'),estatus:2},
        {id:-1,dia:6,horaInicio:'00:00',momentInicio:moment('00:00','HH:mm'),horaTermino:'00:00',momentTermino:moment('00:00','HH:mm'),estatus:2},
        {id:-1,dia:7,horaInicio:'00:00',momentInicio:moment('00:00','HH:mm'),horaTermino:'00:00',momentTermino:moment('00:00','HH:mm'),estatus:2},
      ],
      modalHorarios:false,
      modalChangeVigencia:false,
      deshabilitado:false,
      semaforo:1,
    }
  }

  componentDidMount(){
    this.props.setMensaje(3)
    document.title = "Registrar promoción"

    let {validCurrentUser} = this.props

    if(this.context['type']===2){
      let obj = JSON.parse(JSON.stringify(this.context))
      if(Object.entries(this.context['data']).length ===0){
        let permisosPreSaved = sessionStorage.getItem('perfiles')||'none'
        if(permisosPreSaved!=='none')
          obj['data'] = JSON.parse(permisosPreSaved)  
      }
      let uservalid = validCurrentUser(obj,'promociones','bool',false,'CREAR')
      if(!uservalid){
        this.props.redirectTo2('/home/promociones')
        return
      }
    }

    if(this.state.sucursales.length === 0){this.actualizarSucursales(); }
    else{
      let {sucursales} = this.state
      let sucursales2 = []
      for(let sucursal of sucursales){
        let subsucursal = {
          ID: -1,
          NOMBRE : sucursal.NOMBRE,
          ID_SUC:sucursal.ID,
          hay:(sucursales.length === 1),
          code:sucursal['CODE']
        }
        sucursales2.push(subsucursal);
      }
      this.setState({sucursales2:sucursales2})
    }
    this.actualizarProductos();
    this.actualizarCategorias();
    this.props.updateState('nopuedoCambiarSinAvisarte',true)
  }

  changeState(valor,campo){
    if(campo==="fecha2" || campo ==="fecha1" || campo==="hora1" || campo==="hora2" ){
      let {fecha1,fecha2,hora1,hora2} = this.state
      
      let a = campo==="fecha1" ?valor:fecha1;
      let b = campo==="hora1" ?valor:hora1;
      let c = campo==="fecha2" ?valor:fecha2;
      let d = campo==="hora2" ?valor:hora2;
      a = a.format('DD/MM/YYYY')
      b = b.format('HH:mm')
      c = c.format('DD/MM/YYYY')
      d = d.format('HH:mm')
      let inicio = moment(a+' '+b,'DD/MM/YYYY HH:mm')
      let fin = moment(c+' '+d,'DD/MM/YYYY HH:mm')
      if(fin<=inicio){
        this.setState({txtAlert:'La fecha término debe ser mayor a la fecha inicio',modalAlert:true})
        return
      }
    }
    this.setState({[campo]:valor})
  }

  changeStatus(value){
    let {horarios} = this.state
    if(value === '1'){
      let h2 = horarios.filter(h => h.estatus === 1).length
      if(h2===0)this.setState({status:value})
      else this.setState({modalChangeVigencia:true})
    }
    else{
      this.setState({status:value})
    }
  }

  showModalProductos(indice = -100){//promocones tipo 2
    let {productos,detalles,sucursales2,elegido} = this.state
    
    let productosFiltrados = productos
    let productosFiltrados2 = []
    let sucursales3 = sucursales2.filter(s => s.hay)
    let nombresS = sucursales3.map(s => s['NOMBRE'])

    for(let producto of productosFiltrados){
      if(producto.existenciaPropia.some(e =>  nombresS.includes(e) )){
        productosFiltrados2.push(producto)
      }
      if(producto.variantes.length>0){
        let subvars = []
        for(let variante of producto['variantes']){
          if( variante['existenciaPropia'].some(e => nombresS.includes(e) )  )
            subvars.push(variante)
        }
        if(subvars.length > 0 ){
          let vuevo = JSON.parse(JSON.stringify(producto))
          vuevo['variantes'] = subvars
          productosFiltrados2.push(vuevo)
        }
      }
    }
    
    switch(elegido){
      case 2:
        for(let producto of productos){
          producto['conPromocion'] = detalles.some(p => (p.ID_PRODUCTO === producto.ID) && (p.activo) )
          for(let variante of producto.variantes){
            variante['conPromocion'] = detalles.some(p => (p.ID_PRODUCTO === variante.ID) && (p.activo) )
          }
        }
      break;
      case 3:
        let {productosTipo3,productoTipo3Aux,productoTipo3AuxSecundario,productosSecundariosTipo3} = this.state
        if(indice===-1){
          productoTipo3Aux = { ID:0, ID_PRODUCTO:'', CANTIDAD:'', NOMBRE:'', PRECIO:'',PRECIO2:''}
          productoTipo3AuxSecundario={ID:0, ID_PRODUCTO:'', CANTIDAD:'', NOMBRE:'', PRECIO:'',PRECIO2:'',}
        }
        else{
          Object.assign(productoTipo3Aux,productosTipo3[indice]);
          Object.assign(productoTipo3AuxSecundario,productosSecundariosTipo3[indice])
        }
        this.setState({productoTipo3Aux,productoTipo3AuxSecundario})
      break;
      case 4:
        let {productosTipo4,productoTipo4Aux,productoTipo4AuxSecundario,productosSecundariosTipo4,tipoDesc} = this.state
        if(indice===-1){
          productoTipo4Aux = { ID:0, ID_PRODUCTO:'', CANTIDAD:'', NOMBRE:'', PRECIO:'',PRECIO2:''}
          productoTipo4AuxSecundario={ID:0, ID_PRODUCTO:'', CANTIDAD:'', NOMBRE:'', PRECIO:'',PRECIO2:'',}
          tipoDesc=-1
        }
        else{
          Object.assign(productoTipo4Aux,productosTipo4[indice])
          Object.assign(productoTipo4AuxSecundario,productosSecundariosTipo4[indice])
          let dem = Number(productoTipo4AuxSecundario['DESCUENTO_MONETARIO'])
          let dep = Number(productoTipo4AuxSecundario['DESCUENTO_PORCENTAJE'])
          if(dem>0)tipoDesc=-1
          if(dep>0)tipoDesc=1
        }
        this.setState({productoTipo4Aux,productoTipo4AuxSecundario,tipoDesc})
      break;
      default:break;
    }
    let tipo = ['','','addingProducto','miniModalTipo3','miniModalTipo4'] //addingProducto21 addingProducto31
    this.setState({productos,productosFiltrados:productosFiltrados2,[tipo[elegido]]:true,indiceElegido:indice,filtro1:'',filtro2:'',filtro3:''})
  }

  addPromo(e,id,idVar){
    let {productosFiltrados,productos} = this.state
    let {checked} = e.target
    let producto = productosFiltrados.filter(p=>p.ID===id)
    let producto2 = productos.filter(p=>p.ID===id)
    let variante = 0 
    let variante2 =  0
    if(idVar===0){
      producto[0]['conPromocion'] = checked
      producto2[0]['conPromocion'] = checked
      for(let variante of producto[0].variantes){variante['conPromocion'] = checked}
      for(let variante of producto2[0].variantes){variante['conPromocion'] = checked}
    }
    else{
      variante = producto[0]['variantes'].filter(v=>v.ID===idVar)
      variante2 = producto2[0]['variantes'].filter(v=>v.ID===idVar)
      variante[0]['conPromocion'] = checked
      variante2[0]['conPromocion'] = checked
    }
    this.setState({productosFiltrados:productosFiltrados,productos:productos})
  }

  agregarProductos(){
    let {productos} = this.state
    let detalles = []
    let detalle={}
    for(let producto of productos){
      if(producto.conPromocion){
        detalle={ID:-1, ID_PRODUCTO:producto.ID,NOMBRE_PRODUCTO:producto.NOMBRE,PRECIO:producto.PRECIO2,activo:true,numVariantes:producto.variantes.length,existenciaPropia:producto.existenciaPropia}
        detalles.push(detalle)
      }
      for(let variante of producto.variantes){
        if(variante.conPromocion){
          detalle={ID:-1, ID_PRODUCTO:variante.ID,NOMBRE_PRODUCTO:producto.NOMBRE+': '+variante.NOMBRE,PRECIO:variante.PRECIO2,activo:true,numVariantes:0,existenciaPropia:producto.existenciaPropia}
          detalles.push(detalle)
        }
      }
    }
    if(detalles.length===0){
      this.setState({modalAlert:true,txtAlert:'Debes elegir al menos un producto para continuar'})
      return
    }
    this.setState({detallesAux:detalles,productos:productos,addingProducto:false,addingDesc:true})
  }

  NoagregarProductos(){
    let {productos,detalles} = this.state
    for(let producto of productos){
      producto['conPromocion'] = detalles.some(p => p.ID_PRODUCTO === producto.ID)
    }
    let detallesAux=[]
    this.setState({productos:productos,addingProducto:false,addingDesc:false,detallesAux:detallesAux})
  }

  miniModal3Clicker(op){
    let{productoTipo3Aux} = this.state
    if(op===2 && productoTipo3Aux['ID_PRODUCTO'].trim()===""){
      this.setState({modalAlert:true,txtAlert:'Debes elegir un producto principal'})
      return
    }
    let modal = 'addingProducto2'.concat(op)
    this.setState({[modal]:true,miniModalTipo3:false})
  }

  setPrincipal(id,idVar){
    let {productosFiltrados,productoTipo3Aux} = this.state
    let cantidad = 0
    let producto, variante
    if(idVar===0){//estoy eleigiendo un producto padre y sin variantes
      producto = productosFiltrados.filter(p=>p.ID === id)
      producto = producto[0]
      productoTipo3Aux = { ID:-1, ID_PRODUCTO:producto.ID, CANTIDAD:cantidad, NOMBRE: producto.NOMBRE, PRECIO: producto.PRECIO, PRECIO2: producto.PRECIO2 , existenciaPropia: producto.existenciaPropia }
    }
    else{//esta eligiendo una variante
      producto = productosFiltrados.filter(p=>p.ID === id)
      producto = producto[0]
      variante = producto.variantes.filter(v => v.ID === idVar)
      variante = variante[0]
      productoTipo3Aux = { ID:-1, ID_PRODUCTO:variante.ID, CANTIDAD:cantidad, NOMBRE: producto.NOMBRE+': '+variante.NOMBRE, PRECIO: variante.PRECIO, PRECIO2: variante.PRECIO2, existenciaPropia: variante.existenciaPropia }
    }
    this.setState({productoTipo3Aux:productoTipo3Aux})
  }

  setSecundario(id,idVar){
    let {productosFiltrados,productoTipo3AuxSecundario,productoTipo3Aux,sucursales2} = this.state
    let cantidad = 0
    let producto,variante
    
    let exisAux1 = productoTipo3Aux.existenciaPropia,exisAux2
    let sucursales3 = sucursales2.filter(s => s.hay)
    exisAux1 = exisAux1.filter(e => sucursales3.some(s => s.NOMBRE===e) )

    if(idVar===0){//esty eleigiendo un producto padre y sin variantes
      producto = productosFiltrados.filter(p=>p.ID === id)
      producto = producto[0]

      exisAux2 = producto.existenciaPropia
      exisAux2 = exisAux2.filter(e => sucursales3.some(s => s.NOMBRE===e) )

      if(exisAux1.some( existencia => exisAux2.some(existencia2 => existencia2===existencia) )){
        productoTipo3AuxSecundario = { ID:-1, ID_PRODUCTO:producto.ID, CANTIDAD:cantidad, NOMBRE: producto.NOMBRE, PRECIO: producto.PRECIO, PRECIO2: producto.PRECIO2 ,existenciaPropia: producto.existenciaPropia }
      }
      else{
        this.setState({modalAlert:true,txtAlert:'Los productos deben pertenecer a la misma sucursal'})
        return
      }
    }
    else{//esta eligiendo una variante
      producto = productosFiltrados.filter(p=>p.ID === id)
      producto = producto[0]
      variante = producto.variantes.filter(v => v.ID === idVar)
      variante = variante[0]
      exisAux2 = variante.existenciaPropia
      exisAux2 = exisAux2.filter(e => sucursales3.some(s => s.NOMBRE===e) )
      if(exisAux1.some( existencia => exisAux2.some(existencia2 => existencia2===existencia) )){
        productoTipo3AuxSecundario = { ID:-1, ID_PRODUCTO:variante.ID, CANTIDAD:cantidad, NOMBRE: producto.NOMBRE+': '+variante.NOMBRE, PRECIO: variante.PRECIO, PRECIO2: variante.PRECIO2 ,existenciaPropia: variante.existenciaPropia }
      }
      else{
        this.setState({modalAlert:true,txtAlert:'Los productos deben pertenecer a la misma sucursal'})
        return
      }
    }
    this.setState({productoTipo3AuxSecundario:productoTipo3AuxSecundario})
  }

  closeModalAux(op){
    let {indiceElegido,productoTipo3Aux,productosTipo3, productoTipo3AuxSecundario,productosSecundariosTipo3} = this.state
    if(op===1){
      if(indiceElegido === -1 ) productoTipo3Aux={ID:0, ID_PRODUCTO:'', CANTIDAD:'', NOMBRE:'', PRECIO:'',PRECIO2:''}
      else Object.assign(productoTipo3Aux,productosTipo3[indiceElegido]);
      this.setState({addingProducto21:false,miniModalTipo3:true,productoTipo3Aux })
    }
    if(op===2){
      if(indiceElegido === -1 ) productoTipo3AuxSecundario={ID:0, ID_PRODUCTO:'', CANTIDAD:'', NOMBRE:'', PRECIO:'',PRECIO2:''}
      else Object.assign(productoTipo3AuxSecundario,productosSecundariosTipo3[indiceElegido]);
      this.setState({addingProducto22:false,miniModalTipo3:true,productoTipo3AuxSecundario })
    }
  }

  ponCantidadPrincipal(){
    let {productoTipo3Aux} = this.state
    let cantidad = document.getElementById('cantidadd')    
    if(productoTipo3Aux['ID_PRODUCTO'] === ''){
      this.setState({txtAlert:'Debe elegir un producto o servicio como principal',modalAlert:true})
      return
    }
    if(cantidad.value.trim()===''){
      this.setState({txtAlert:'La cantidad de compra es obligatoria',modalAlert:true})
       return
    }
    if(Number(cantidad.value)<=0){
      this.setState({txtAlert:'La cantidad de compra debe ser mayor a 0',modalAlert:true})
       return
    }
    productoTipo3Aux['CANTIDAD'] = Number(cantidad.value)
    productoTipo3Aux['activo'] = true
    this.setState({productoTipo3Aux,addingProducto21:false,miniModalTipo3:true})
  }

  ponCantidadSecundaria(){
    let {productoTipo3AuxSecundario, productoTipo3Aux, indiceElegido,sucursales2,productosTipo3,productosSecundariosTipo3} = this.state
    let cantidad = document.getElementById('cantidad2d')
    
    if(productoTipo3AuxSecundario['ID_PRODUCTO']===''){
      this.setState({txtAlert:'Debe elegir un producto o servicio como secundario',modalAlert:true})
      return
    }

    let exisAux1,exisAux2
    let sucursales3 = sucursales2.filter(s => s.hay)

    exisAux1 = productoTipo3Aux.existenciaPropia
    exisAux1 = exisAux1.filter(e => sucursales3.some(s => s.NOMBRE===e) )

    exisAux2 = productoTipo3AuxSecundario.existenciaPropia
    exisAux2 = exisAux2.filter(e => sucursales3.some(s => s.NOMBRE===e) )

    if(!exisAux1.some( existencia => exisAux2.some(existencia2 => existencia2===existencia) )){
      this.setState({modalAlert:true,txtAlert:'Los productos o servicios deben pertenecer a la misma sucursal'})
      return
    }

    if(cantidad.value.trim()===''){
      this.setState({txtAlert:'La cantidad de regalo es obligatoria',modalAlert:true})
       return
    }
    if(Number(cantidad.value)<=0){
      this.setState({txtAlert:'La cantidad de regalo debe ser mayor a 0',modalAlert:true})
       return
    }

    productoTipo3AuxSecundario['CANTIDAD'] = Number(cantidad.value)

    let f = false
    let pfound = -1
    for(let p in productosTipo3){
      if(
         (Number(p) !== indiceElegido)&&
         (productosTipo3[p]['ID_PRODUCTO'] === productoTipo3Aux['ID_PRODUCTO'])&&
         (productosSecundariosTipo3[p]['ID_PRODUCTO'] === productoTipo3AuxSecundario['ID_PRODUCTO'])
        ){
          f = true;
          pfound = p
          break;
        }
    }

    if(f){

      let repeAux = productosTipo3[pfound]
      let repeAuxSecundario = productosSecundariosTipo3[pfound]

      if(Number(repeAux['CANTIDAD']) === Number(productoTipo3Aux['CANTIDAD'])){
        this.setState({txtAlert:'No se puede tener la misma combinación de productos y cantidades en la misma promoción',modalAlert:true})
       return 
      }

      else if(Number(repeAuxSecundario['CANTIDAD']) === Number(productoTipo3AuxSecundario['CANTIDAD'])){
        this.setState({txtAlert:'No se puede tener la misma combinación de productos y cantidades en la misma promoción',modalAlert:true})
       return 
      }
      let factor1 = Number(repeAux['CANTIDAD']) / Number(productoTipo3Aux['CANTIDAD'])
      let factor2 = Number(repeAuxSecundario['CANTIDAD']) / Number(productoTipo3AuxSecundario['CANTIDAD'])

      if(factor1 === factor2){
        this.setState({txtAlert:'No se puede tener la misma combinación de productos y cantidades en la misma promoción',modalAlert:true})
        return 
      }

    }

    this.setState({productoTipo3AuxSecundario, addingProducto22:false,miniModalTipo3:true,})
  }

  noPongasCantidadPrincipal(){
    let {productoTipo3Aux,productoTipo3AuxSecundario,indiceElegido} = this.state
    indiceElegido = 0
    productoTipo3Aux = {}
    productoTipo3AuxSecundario = {}
    this.setState({productoTipo3Aux,indiceElegido,productoTipo3AuxSecundario,addingProducto21:false,addingProducto22:false,miniModalTipo3:false})
  }

  saveProducto3(){
    let {indiceElegido,productosTipo3,productoTipo3Aux, productosSecundariosTipo3, productoTipo3AuxSecundario,  } = this.state
    let obj = {},obj2 = {}

    if(productoTipo3Aux['ID_PRODUCTO'] === ''){
      this.setState({txtAlert:'Debe elegir un producto o servicio como principal',modalAlert:true})
      return
    }

    if(productoTipo3AuxSecundario['ID_PRODUCTO']===''){
      this.setState({txtAlert:'Debe elegir un producto o servicio como secundario',modalAlert:true})
      return
    }

    if(indiceElegido === -1 ){
      Object.assign(obj,productoTipo3AuxSecundario);
      Object.assign(obj2,productoTipo3Aux);
      productosSecundariosTipo3.push(obj)
      productosTipo3.push(obj2)
    }else{
      Object.assign(productosSecundariosTipo3[indiceElegido],productoTipo3AuxSecundario);
      Object.assign(productosTipo3[indiceElegido],productoTipo3Aux);
    }
    productoTipo3AuxSecundario = {}
    productoTipo3Aux = {}
    indiceElegido = 0
    this.setState({indiceElegido,productosSecundariosTipo3,productoTipo3AuxSecundario,productosTipo3,productoTipo3Aux,miniModalTipo3:false})
  }
  /*tipo4*/

  miniModal4Clicker(op){
    let{productoTipo4Aux} = this.state
    if(op===2 && productoTipo4Aux['ID_PRODUCTO'].trim()===""){
      this.setState({modalAlert:true,txtAlert:'Debes elegir un producto principal'})
      return
    }

    let modal = 'addingProducto3'.concat(op)
    this.setState({[modal]:true,miniModalTipo4:false})
  }

  setPrincipal2(id,idVar){
    let {productosFiltrados,productoTipo4Aux} = this.state
    let cantidad = 0
    let producto,variante
    if(idVar===0){//esty eleigiendo un producto padre y sin variantes
      producto = productosFiltrados.filter(p=>p.ID === id)
      producto = producto[0]
      productoTipo4Aux = { ID:-1, ID_PRODUCTO:producto.ID, CANTIDAD:cantidad, NOMBRE: producto.NOMBRE, PRECIO: producto.PRECIO, PRECIO2: producto.PRECIO2 ,existenciaPropia: producto.existenciaPropia }
    }
    else{//esta eligiendo una variante
      producto = productosFiltrados.filter(p=>p.ID === id)
      producto = producto[0]
      variante = producto.variantes.filter(v => v.ID === idVar)
      variante = variante[0]
      productoTipo4Aux = { ID:-1, ID_PRODUCTO:variante.ID, CANTIDAD:cantidad, NOMBRE:producto.NOMBRE+': '+variante.NOMBRE, PRECIO: variante.PRECIO, PRECIO2: variante.PRECIO2,existenciaPropia: variante.existenciaPropia }
    }
    this.setState({productoTipo4Aux:productoTipo4Aux})
  }

  setSecundario2(id,idVar){
    let {productosFiltrados,productoTipo4AuxSecundario, productoTipo4Aux ,sucursales2} = this.state
    let cantidad = 0
    let variante,producto

    let exisAux1 = productoTipo4Aux.existenciaPropia,exisAux2
    let sucursales3 = sucursales2.filter(s => s.hay)
    exisAux1 = exisAux1.filter(e => sucursales3.some(s => s.NOMBRE===e) )

    if(idVar===0){//esty eleigiendo un producto padre y sin variantes
      producto = productosFiltrados.filter(p=>p.ID === id)
      producto = producto[0]

      exisAux2 = producto.existenciaPropia
      exisAux2 = exisAux2.filter(e => sucursales3.some(s => s.NOMBRE===e) )

      if(exisAux1.some( existencia => exisAux2.some(existencia2 => existencia2===existencia) )){
        productoTipo4AuxSecundario = { ID:-1, ID_PRODUCTO:producto.ID, CANTIDAD:cantidad, NOMBRE: producto.NOMBRE, PRECIO: producto.PRECIO2,  PRECIO2: producto.PRECIO2,existenciaPropia: producto.existenciaPropia }
      }else{
        this.setState({modalAlert:true,txtAlert:'Los productos deben pertenecer a la misma sucursal'})
        return
      }

    }
    else{//esta eligiendo una variante
      producto = productosFiltrados.filter(p=>p.ID === id)
      producto = producto[0]
      variante = producto.variantes.filter(v => v.ID === idVar)
      variante = variante[0]
      exisAux2 = variante.existenciaPropia
      exisAux2 = exisAux2.filter(e => sucursales3.some(s => s.NOMBRE===e) )
      if(exisAux1.some( existencia => exisAux2.some(existencia2 => existencia2===existencia) )){
        productoTipo4AuxSecundario = { ID:-1, ID_PRODUCTO:variante.ID, CANTIDAD:cantidad, NOMBRE: producto.NOMBRE+': '+variante.NOMBRE, PRECIO: variante.PRECIO2, PRECIO2: variante.PRECIO2,existenciaPropia: variante.existenciaPropia }
      }else{
        this.setState({modalAlert:true,txtAlert:'Los productos deben pertenecer a la misma sucursal'})
        return
      }
    }
    this.setState({productoTipo4AuxSecundario:productoTipo4AuxSecundario})
  }

  closeModalAux2(op){
    let {indiceElegido,productoTipo4Aux,productosTipo4, productoTipo4AuxSecundario,productosSecundariosTipo4} = this.state
    if(op===1){
      if(indiceElegido === -1 ) productoTipo4Aux={ID:-1, ID_PRODUCTO:'', CANTIDAD:'', NOMBRE:'', PRECIO:'',PRECIO2:''}
      else Object.assign(productoTipo4Aux,productosTipo4[indiceElegido]);
      this.setState({addingProducto31:false,miniModalTipo4:true,productoTipo4Aux })
    }
    if(op===2){
      if(indiceElegido === -1 ) productoTipo4AuxSecundario={ID:-1, ID_PRODUCTO:'', CANTIDAD:'', NOMBRE:'', PRECIO:'',PRECIO2:''}
      else Object.assign(productoTipo4AuxSecundario,productosSecundariosTipo4[indiceElegido]);
      this.setState({addingProducto32:false,miniModalTipo4:true,productoTipo4AuxSecundario })
    }
  }

  ponCantidadPrincipal2(){
    let {productoTipo4Aux} = this.state
    let cantidad = document.getElementById('cantidadd2')

    if(productoTipo4Aux['ID_PRODUCTO'] === ""){
      this.setState({txtAlert:'Debe elegir un producto o servicio como principal',modalAlert:true})
      return
    }
    if(cantidad.value.trim()===''){
      this.setState({txtAlert:'La cantidad de compra es obligatoria',modalAlert:true})
       return
    }
    if(Number(cantidad.value)<=0){
      this.setState({txtAlert:'La cantidad de compra debe ser mayor a 0',modalAlert:true})
       return
    }
    productoTipo4Aux['CANTIDAD'] = Number(cantidad.value)
    productoTipo4Aux['activo'] = true
    this.setState({productoTipo4Aux,addingProducto31:false,miniModalTipo4:true})
  }

  ponCantidadSecundaria2(){
    let {productoTipo4AuxSecundario,productosSecundariosTipo4,productoTipo4Aux,productosTipo4,indiceElegido,sucursales2} = this.state
    let cantidad = document.getElementById('cantidad2d2')
    
    if(productoTipo4AuxSecundario['ID_PRODUCTO']===''){
      this.setState({txtAlert:'Debe elegir un producto o servicio como secundario',modalAlert:true})
      return
    }

    let exisAux1,exisAux2
    let sucursales3 = sucursales2.filter(s => s.hay)

    exisAux1 = productoTipo4Aux.existenciaPropia
    exisAux1 = exisAux1.filter(e => sucursales3.some(s => s.NOMBRE===e) )

    exisAux2 = productoTipo4AuxSecundario.existenciaPropia
    exisAux2 = exisAux2.filter(e => sucursales3.some(s => s.NOMBRE===e) )

    if(!exisAux1.some( existencia => exisAux2.some(existencia2 => existencia2===existencia) )){
      this.setState({modalAlert:true,txtAlert:'Los productos o servicios deben pertenecer a la misma sucursal'})
      return
    }

    if(cantidad.value.trim()===''){
      this.setState({txtAlert:'La cantidad es obligatoria',modalAlert:true})
       return
    }
    if(Number(cantidad.value)<=0){
      this.setState({txtAlert:'La cantidad debe ser mayor a 0',modalAlert:true})
       return
    }
    productoTipo4AuxSecundario['CANTIDAD'] = Number(cantidad.value)


    let f = false
    let pfound = -1
    for(let p in productosTipo4){
      if(
        (Number(p) !== indiceElegido)&&
        (productosTipo4[p]['ID_PRODUCTO'] === productoTipo4Aux['ID_PRODUCTO'])&&
        (productosSecundariosTipo4[p]['ID_PRODUCTO'] === productoTipo4AuxSecundario['ID_PRODUCTO'])
      ){
        f = true;
        pfound = p
        break;
      }
    }

    if(f){

      let repeAux = productosTipo4[pfound]
      let repeAuxSecundario = productosSecundariosTipo4[pfound]

      if(Number(repeAux['CANTIDAD']) === Number(productoTipo4Aux['CANTIDAD'])){
        this.setState({txtAlert:'No se puede tener la misma combinación de productos y cantidades en la misma promoción',modalAlert:true})
       return 
      }

      else if(Number(repeAuxSecundario['CANTIDAD']) === Number(productoTipo4AuxSecundario['CANTIDAD'])){
        this.setState({txtAlert:'No se puede tener la misma combinación de productos y cantidades en la misma promoción',modalAlert:true})
       return 
      }
      let factor1 = Number(repeAux['CANTIDAD']) / Number(productoTipo4Aux['CANTIDAD'])
      let factor2 = Number(repeAuxSecundario['CANTIDAD']) / Number(productoTipo4AuxSecundario['CANTIDAD'])

      if(factor1 === factor2){
        this.setState({txtAlert:'No se puede tener la misma combinación de productos y cantidades en la misma promoción',modalAlert:true})
        return 
      }

    }

    this.setState({addingProducto32:false,miniModalTipo4:true,productoTipo4AuxSecundario})
  }

  noPongasCantidadPrincipal2(){
    let {productoTipo4Aux, productoTipo4AuxSecundario ,indiceElegido} = this.state
    indiceElegido = 0
    productoTipo4Aux = {}
    productoTipo4AuxSecundario = {}
    this.setState({productoTipo4Aux,indiceElegido,miniModalTipo4:false,productoTipo4AuxSecundario})
  }
  
  saveProducto4(){
    let {productoTipo4Aux, productosTipo4 , productoTipo4AuxSecundario, productosSecundariosTipo4  ,indiceElegido} = this.state    
    if(productoTipo4Aux['ID_PRODUCTO'] === ""){
      this.setState({txtAlert:'Debe elegir un producto o servicio como principal',modalAlert:true})
      return
    }    
    if(productoTipo4AuxSecundario['ID_PRODUCTO'] === ""){
      this.setState({txtAlert:'Debe elegir un producto o servicio como secundario',modalAlert:true})
      return
    }

    let monetario = document.getElementById('tipoDes21')
    let porcentaje = document.getElementById('tipoDes22')
    let cantidad = document.getElementById('cantidaddd2').value
    cantidad = cantidad.trim().split(',').join('').split('$').join('').split('MXN').join('').split('%').join('').split(' ').join('')
    
    if(cantidad===''){
      this.setState({txtAlert:'El descuento no puede quedar vacío',modalAlert:true})
      return
    }
    cantidad = Number(cantidad)

    if(cantidad<=0){
      this.setState({txtAlert:'El descuento debe ser mayor a 0',modalAlert:true})
      return
    }

    let tipoDesc
    
    if(monetario['checked']){
      tipoDesc = 1
      if( ( (Number(productoTipo4AuxSecundario['PRECIO2']) * Number(productoTipo4AuxSecundario['CANTIDAD'])  ) - cantidad ) < 0 ){
        this.setState({txtAlert:'El producto no puede tener un precio final menor a $0 MXN',modalAlert:true})
        return
      }
      productoTipo4AuxSecundario['DESCUENTO_PORCENTAJE'] = 0
      productoTipo4AuxSecundario['DESCUENTO_MONETARIO'] = cantidad
    }

    if(porcentaje['checked']){
      tipoDesc = -1
      if(cantidad>100){
        this.setState({txtAlert:'El descuento debe ser menor a 100%',modalAlert:true})
        return
      }
      productoTipo4AuxSecundario['DESCUENTO_PORCENTAJE'] = cantidad
      productoTipo4AuxSecundario['DESCUENTO_MONETARIO'] = 0
      if(  (Number(productoTipo4AuxSecundario['PRECIO2']) * ((100-porcentaje)/100) ) < 0 ){
        this.setState({txtAlert:'El producto no puede tener un precio final menor a $0 MXN',modalAlert:true})
        return
      }      
    }

    this.setState({desc2:cantidad,tipoDesc:tipoDesc,miniModalTipo4:false})

    let obj = {}, obj2 = {}
    if(indiceElegido === -1 ){
      Object.assign(obj,productoTipo4AuxSecundario);
      Object.assign(obj2,productoTipo4Aux);
      productosSecundariosTipo4.push(obj)
      productosTipo4.push(obj2)

    }else{
      Object.assign(productosSecundariosTipo4[indiceElegido],productoTipo4AuxSecundario);
      Object.assign(productosTipo4[indiceElegido],productoTipo4Aux);
    }
    productoTipo4AuxSecundario = {}
    productoTipo4Aux = {}
    indiceElegido = 0
    this.setState({productoTipo4AuxSecundario,productoTipo4Aux,indiceElegido, productosSecundariosTipo4,productosTipo4,})
  }

  modalEliminarSucursal(e,indice){
    this.setState({modalEliminar:true, indiceEliminar:indice})
    e.stopPropagation()
  }

  eliminarRegistroPromocion(){
    let {indiceEliminar, productosTipo3, productosSecundariosTipo3 , productosTipo4, productosSecundariosTipo4 , elegido , detalles} = this.state
    switch(elegido){
      case 2:
        let detalles2 = []
        for(let detalle of detalles){
          if(detalle.ID_PRODUCTO === indiceEliminar) continue;
          detalles2.push(detalle);
        }
        this.setState({detalles:detalles2,indiceEliminar:-1,modalEliminar:false})
      break;
      case 3:
      productosTipo3.splice(indiceEliminar,1)
      productosSecundariosTipo3.splice(indiceEliminar,1)
      this.setState({productosTipo3:productosTipo3,productosSecundariosTipo3:productosSecundariosTipo3,indiceEliminar:-1,modalEliminar:false})
      break;
      case 4:
      productosTipo4.splice(indiceEliminar,1)
      productosSecundariosTipo4.splice(indiceEliminar,1)
      this.setState({productosTipo4:productosTipo4,productosSecundariosTipo4:productosSecundariosTipo4,indiceEliminar:-1,modalEliminar:false})
      break;
      default:break;
    }
  }

  addDesc(){
    let {detallesAux} = this.state
    let detalles2 = detallesAux

    let formData = new FormData(document.getElementById('formulaDescuento'))
    let tipoDesc = Number(formData.get('tipoDes'))
    let porcentaje = formData.get('descP')
    porcentaje = porcentaje.trim().split(' ').join('').split('%').join('')
    let pesos = formData.get('descM')
    pesos = pesos.trim().split(' ').join('').split('$').join('').split(',').join('')
    if(tipoDesc < 0){
      if(porcentaje===''){
        this.setState({txtAlert:'El porcentaje no puede quedar vacío',modalAlert:true})
        return
      }
      porcentaje = Number(porcentaje)
      if(porcentaje<=0){
        this.setState({txtAlert:'El descuento debe ser mayor a 0%',modalAlert:true})
        return
      }
      if(porcentaje>100){
        this.setState({txtAlert:'El descuento debe ser menor a 100%',modalAlert:true})
        return
      }

      detalles2 = detalles2.filter(d => d.numVariantes === 0)
      if(detalles2.some(d =>  ( d.PRECIO * ((100-porcentaje)/100) ) < 0 )  ){
        this.setState({txtAlert:'Ningún producto puede tener un precio final menor a 0',modalAlert:true})
        return
      }

      this.setState({desc:porcentaje,tipoDesc:tipoDesc,addingDesc:false})
    }
    else{
      if(pesos===''){
        this.setState({txtAlert:'El descuento no puede quedar vacío',modalAlert:true})
        return
      }
      if(pesos<=0){
        this.setState({txtAlert:'El descuento debe ser mayor a 0',modalAlert:true})
        return
      }
      detalles2 = detalles2.filter(d => d.numVariantes === 0)
      if(detalles2.some( d =>  ( d.PRECIO - pesos ) < 0 )  ){
        this.setState({txtAlert:'Ningún producto puede tener un precio final menor a 0',modalAlert:true})
        return
      }      
      this.setState({desc:pesos,tipoDesc:tipoDesc,addingDesc:false})
    }
    this.setState({detalles:detallesAux,detallesAux:[]})
  }

  changeFiltros(value,campo){
    let {filtro1,filtro2,filtro3,productosFiltrados,productos,sucursales2} = this.state
    let filtros = { filtro1:filtro1,filtro2:filtro2,filtro3:filtro3,}
    filtros[campo] = value
    filtro1 = filtros['filtro1']
    filtro2 = filtros['filtro2']
    filtro3 = filtros['filtro3']
    
    productosFiltrados = productos
    let productosFiltrados2 = []
    let sucursales3 = sucursales2.filter(s => s.hay)
    
    for(let producto of productosFiltrados){
      if(producto.existenciaPropia.some(e =>  sucursales3.some(s=>s.NOMBRE === e) )){
        productosFiltrados2.push(producto)
      }
      if(producto.variantes.length>0){
        if(producto.variantes.some(v => v.existenciaPropia.some(e => sucursales3.some(s=>s.NOMBRE===e)) )){
          productosFiltrados2.push(producto)
        }
      }
    }

    productosFiltrados = productosFiltrados2


    productosFiltrados = filtro1 === '' ? productosFiltrados : productosFiltrados.filter( p => (p.existenciaPropia.some(xp => xp===filtro1) || p.variantes.some(v => v.existenciaPropia.some(ev => ev === filtro1) ) ) )
    productosFiltrados = filtro2 === '' ? productosFiltrados : productosFiltrados.filter( p => p.CATEGORIA === filtro2 )

    productosFiltrados = filtro3 === '' ? productosFiltrados :
    productosFiltrados.reduce((result,productoActual)=>{
      if(productoActual.NOMBRE.trim().toLowerCase().includes(filtro3.trim().toLowerCase()))
        result.push(productoActual)
      else{
        let p2 = JSON.parse(JSON.stringify(productoActual))
        let v2 = []
        for(let vari of p2.variantes){
          if(vari.NOMBRE.trim().toLowerCase().includes(filtro3.trim().toLowerCase()))
            v2.push(vari)
        }

        if(v2.length > 0){
          p2.variantes = v2
          result.push(p2)
        }
      }
      return result      
    },[])    

    this.setState({productosFiltrados:productosFiltrados,filtro1:filtros['filtro1'],filtro2:filtros['filtro2'],filtro3:filtros['filtro3']})
  }


  fileChange(e){
    let {files} = e.target
    if(files.length===0)return
    if(!files[0].type.includes('image/')){
        this.setState({txtAlert:'Solo puedes elegir imagenes',modalAlert:true})
        return;
    }
    this.cropper.replace(URL.createObjectURL(files[0]))
    e.target.value = ''
    this.setState({modalCroper:true})
  }

  cancelCroper(){
      this.setState({src2:'',modalCroper:false})
  }

  aceptCroper(){
    this.props.updateState('nopuedoCambiarSinAvisarte',true)
    let src = this.cropper.getCroppedCanvas().toDataURL()
    this.setState({ src2 : src,modalCroper:false })
    document.getElementById('srcPromo').src=src
    document.getElementById('srcPromo').style.display='block'
    document.getElementById('forfileaLabel').style.display='none'
  }

  clearSrc(){
    document.getElementById('forfileaLabel').style.display='block'
    document.getElementById('srcPromo').src=''
    document.getElementById('srcPromo').style.display='none'
    this.setState({ src2 : '',modalCroper:false })
  }

  actualizarSucursales(){
    let data = JSON.parse(sessionStorage.getItem('metadata'))
    this.props.setSucursales(data['sucursales'])
    this.setState({ sucursales : data['sucursales'] , sucursales2 : data['sucursales'] ,datos : data['datos'], loading:false, })
    this.props.editState('datos',data['datos'])
    this.props.editState('sucursales',data['sucursales'])
    this.props.editState('categorias',data['categorias'])
    this.props.editState('tags',data['tags'])
    this.props.editState('notificaciones',data['notificaciones'])
    let sucursales2 = []
    for(let sucursal of data['sucursales']){
      let subsucursal = { 
        ID: 0,
        NOMBRE : sucursal.NOMBRE,
        ID_SUC:sucursal.ID,
        hay:(data['sucursales'].length === 1),
        code:sucursal['CODE'],
      }
      sucursales2.push(subsucursal);
    }
    this.setState({sucursales2:sucursales2})
  }

  actualizarProductos(){
    let data = {id:sessionStorage.getItem('id')}
    this.setState({loading:true})
    axios.post(`${host}productos/productos/productosPorEmpresa2`,data,request).then(rt=>{
      let rta = rt.data
      for(let ob of rta){
        ob['conPromocion']=false
        for(let ob2 of ob.variantes){ob2['conPromocion']=false}
      }
      let productos = rta
      productos = productos.sort( (rta1,rta2) => Number(rta1.NIVEL_CATEGORIA) - Number(rta2.NIVEL_CATEGORIA) )
      this.setState({productos:productos,loading:false})
      this.setState({productosFiltrados:productos})
    })
  }

  actualizarCategorias(){
    let data = {id:sessionStorage.getItem('id')}
    this.setState({loading:true})
    axios.post(`${host}productos/productos/catalogoDeCategorias`,data,request).then(rt=>{
      this.setState({catalogoDeCategorias:rt.data,catalogoDeCategorias2:rt.data,loading:false})
    })
  }

  changeTipoDescuento(e){
    let {value,checked} = e.target
    value = Number(value)
    let input1 = document.getElementById('descPd')//1
    let input2 = document.getElementById('descMd')//-1
    if(value===1 && checked){
      input2.readOnly = false
      input1.readOnly = true
      input1.value=''
    }
    if(value===-1 && checked){
      input1.readOnly = false
      input2.readOnly = true
      input2.value = ''
    }
  }

  changeTipoDescuento2(e){
   
  }
  
  changeTipoPromocion(value){
    let {elegido,modalTipo,nextelegido} = this.state
    if(elegido !== 1){
      if(value===0)alert()
      modalTipo = true
      nextelegido = value
    }
    else{
      elegido = value
    }
    this.setState({elegido:elegido,nextelegido:nextelegido,modalTipo:modalTipo})
  }

  aceptChangeTipoPromocion(){
    let{indiceElegido,productoTipo3Aux, productoTipo3AuxSecundario, productosTipo3, productoTipo4Aux, productoTipo4AuxSecundario, productosTipo4,detalles, elegido,nextelegido,productosSecundariosTipo3,productosSecundariosTipo4 } = this.state
    indiceElegido = 0
    if(elegido === 2){
      detalles = []
      elegido = nextelegido
      nextelegido = 0
      this.setState({detalles:detalles,elegido:elegido,nextelegido:nextelegido,modalTipo:false})
    }
    else if(elegido===3){
      productoTipo3Aux = {}
      productoTipo3AuxSecundario = {}
      productosTipo3 = []
      productosSecundariosTipo3 = []
      elegido = nextelegido
      nextelegido = 0
      this.setState({indiceElegido:indiceElegido,productoTipo3Aux:productoTipo3Aux,productoTipo3AuxSecundario:productoTipo3AuxSecundario,productosTipo3:productosTipo3, productosSecundariosTipo3:productosSecundariosTipo3 , elegido : elegido, nextelegido : nextelegido,modalTipo:false})
    }
    else if(elegido===4){
      productoTipo4Aux = {}
      productoTipo4AuxSecundario = {}
      productosTipo4 = []
      productosSecundariosTipo4 = []
      elegido = nextelegido
      nextelegido = 0
      this.setState({indiceElegido:indiceElegido,productoTipo4Aux:productoTipo4Aux,productoTipo4AuxSecundario:productoTipo4AuxSecundario,productosTipo4:productosTipo4, productosSecundariosTipo4:productosSecundariosTipo4 , elegido : elegido, nextelegido : nextelegido,modalTipo:false})
    }
  }

  changeCambioEn(e,indice){
    let {checked} = e.target,
        {validCurrentUser} = this.props,
        {elegido,detalles,productosTipo3,productosTipo4,sucursales2} = this.state

    let permiso = e.target.checked ? 'CREAR' : 'ELIMINAR'    

    if(!validCurrentUser(this.context,'promociones','bool',sucursales2[indice]['code'],permiso) ){
      checked = !checked
      this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para '+permiso.toLowerCase()+' Promociones en esta sucursal'})
      return
    }
    
    if(!checked){
      switch(elegido){
        case 1:
          let {sucursales2} = this.state
          sucursales2[indice]['hay'] = checked
          this.setState({sucursales2:sucursales2})
        break;
        case 2:
        if(detalles.length > 0){//show modal
          this.setState({modalDisableSucursal:true,indiceEliminarSucursal:indice})
        }
        else{
          let {sucursales2} = this.state
          sucursales2[indice]['hay'] = checked
          this.setState({sucursales2:sucursales2})
        }
        break;
        case 3:
        if(productosTipo3.length > 0){//show modal
          this.setState({modalDisableSucursal:true,indiceEliminarSucursal:indice})
        }
        else{
          let {sucursales2} = this.state
          sucursales2[indice]['hay'] = checked
          this.setState({sucursales2:sucursales2})
        }
        break;
        case 4:
        if(productosTipo4.length > 0){//show modal
          this.setState({modalDisableSucursal:true,indiceEliminarSucursal:indice})
        }
        else{
          let {sucursales2} = this.state
          sucursales2[indice]['hay'] = checked
          this.setState({sucursales2:sucursales2})
        }
        break;
        default:break;
      }
    }else{
        let {sucursales2} = this.state
        sucursales2[indice]['hay'] = checked
        this.setState({sucursales2:sucursales2})
    }
  }

  eliminarSucursalDePromocion(){
    let {indiceEliminarSucursal,sucursales2,detalles,elegido,productosTipo3, productosSecundariosTipo3,productosTipo4,productosSecundariosTipo4} = this.state
    let productosFiltrados2 = [], detalles2,productosFiltrados3
    //let sucursales3 = sucursales2.filter(s => s.hay)
    
    switch(elegido){
      case 2:
        sucursales2[indiceEliminarSucursal]['hay'] = false
        detalles2 = detalles
        let sucursales31 = sucursales2.filter(s => s.hay)
        for(let producto of detalles2){
          if(producto.existenciaPropia.some(e =>  sucursales31.some(s=>s.NOMBRE === e) )){
            productosFiltrados2.push(producto)
          }
        }
        this.setState({sucursales2:sucursales2,detalles:productosFiltrados2,modalDisableSucursal:false,indiceEliminarSucursal:-1})
      break;
      case 3:
        sucursales2[indiceEliminarSucursal]['hay'] = false
        productosFiltrados3 = []
        let sucursales32 = sucursales2.filter(s => s.hay)
        detalles2 = productosTipo3
        for(let index in detalles2){
          if(detalles2[index].existenciaPropia.some(e =>  sucursales32.some(s=>s.NOMBRE === e) )){
            productosFiltrados2.push(detalles2[index])
            productosFiltrados3.push(productosSecundariosTipo3[index])
          }
        }
        this.setState({sucursales2:sucursales2,productosTipo3:productosFiltrados2,productosSecundariosTipo3: productosFiltrados3 ,modalDisableSucursal:false,indiceEliminarSucursal:-1})
      break;
      case 4:
        sucursales2[indiceEliminarSucursal]['hay'] = false
        productosFiltrados3 = []
        let sucursales33 = sucursales2.filter(s => s.hay)
        detalles2 = productosTipo4
        for(let index in detalles2){
          if(detalles2[index].existenciaPropia.some(e =>  sucursales33.some(s=>s.NOMBRE === e) )){
            productosFiltrados2.push(detalles2[index])
            productosFiltrados3.push(productosSecundariosTipo4[index])
          }
        }
        this.setState({sucursales2:sucursales2,productosTipo4:productosFiltrados2,productosSecundariosTipo4: productosFiltrados3 ,modalDisableSucursal:false,indiceEliminarSucursal:-1})
      break;
      default:break;
    }
  }

  cancelar(){
    this.props.redirectTo2('/home/promociones')
  }

  submit(){
    let {sucursales2,fecha1,hora1,hora2,fecha2,src2,status,horarios,elegido,idPromo,src,detalles,tipoDesc,desc, productosTipo3, productosSecundariosTipo3, productosSecundariosTipo4,productosTipo4} = this.state
    detalles = detalles.filter(d => (d.activo) && (d.numVariantes === 0))
    let formData = new FormData(document.getElementById('formuladatospromo'));
    if(
      formData.get('titulo').trim()===""
      //formData.get('descripcion').trim()===""
    ){
      this.setState({txtAlert:'No puede haber campos vacíos',modalAlert:true})
      return
    }

    /*if(src2 === ''){
      this.setState({txtAlert:'Debes elegir una imagen',modalAlert:true})
      return
    }*/

    if(!sucursales2.some(s => s.hay)){
      this.setState({txtAlert:'La promoción debe estar en al menos una sucursal',modalAlert:true})
      return
    }

    if(elegido===2 && detalles.length===0){
      this.setState({txtAlert:'La promoción debe aplicarse en al menos un producto',modalAlert:true})
      return
    }

    if(elegido===3){
      if(productosTipo3.length===0){
        this.setState({txtAlert:'Debe elegir al menos un producto principal',modalAlert:true})
        return
      }
      if(productosSecundariosTipo3.length===0){
        this.setState({txtAlert:'Debe elegir al menos un producto secundario',modalAlert:true})
        return
      }
    }

    if(elegido===4){
      if(productosTipo4.length===0){
        this.setState({txtAlert:'Debe elegir al menos un producto principal',modalAlert:true})
        return
      }
      if(productosSecundariosTipo4.length===0){
        this.setState({txtAlert:'Debe elegir al menos un producto secundario',modalAlert:true})
        return
      }
    }

    if(status === '2'){
      let h2 = horarios.filter(h => h.estatus ===1).length
      if(h2 === 0){
        this.setState({txtAlert:'Debe haber por lo menos un horario activo',modalAlert:true})
        return
      }
    }

    let data = {}
    data['idEmpresa'] = sessionStorage.getItem('id')
    data['tipo'] = elegido
    data['tipoDesc'] = tipoDesc
    data['desc'] = desc
    data['titulo'] = formData.get('titulo')
    data['descripcion'] = formData.get('descripcion')
    data['src'] = src
    data['src2'] = src2
    data['fecha1'] = fecha1.format('YYYY-MM-DD') + ' ' + hora1.format('HH:mm:ss')
    data['fecha2'] = fecha2.format('YYYY-MM-DD') + ' ' + hora2.format('HH:mm:ss')
    data['estatus'] = 1
    data['id'] = idPromo
    data['sucursales'] = sucursales2
    data['detalles'] = detalles
    data['productosTipo3'] = productosTipo3
    data['productosSecundariosTipo3'] = productosSecundariosTipo3
    data['productosTipo4'] = productosTipo4
    data['productosSecundariosTipo4'] = productosSecundariosTipo4
    data['tipoVigencia'] = status
    data['horarios'] = horarios
    data['op'] = 1
    data['detallesEliminadosVarios'] = []

    this.setState({loading:true})
    axios.post(`${host}promociones/promociones/agregarEditar`,data,request).then(rt=>{
      this.props.updateState('nopuedoCambiarSinAvisarte',false)
      let msj
      if(Number(rt.data)>0){ msj = 'La promoción se agregó correctamente' }
      else{ msj = 'Parece que ocurrió un error, por favor intentalo más tarde. [Código de error: SV-ER: #09-01]' }
      this.setState({ modalRespuesta:true, txtRespuesta:msj, loading:false })
    })
  }

  getDesc(desc,tipoDesc){
    if(tipoDesc === -1)return desc + '%';
    else return '$' + desc
  }

  getNewPrecio(a){
    let {desc,tipoDesc} = this.state
    let precio = Number(a)
    if(tipoDesc < 0){//porcentaje
      precio = precio * ((100 - desc)/100)
    }
    if(tipoDesc > 0){//moneda
      precio-=desc
    }
    return formatNumber(precio,2)
  }

  getPrecioT4(productoSecundario2){
    let precio = Number(productoSecundario2['PRECIO']) * Number(productoSecundario2['CANTIDAD'])
    if(Number(productoSecundario2['DESCUENTO_PORCENTAJE']) === 0){//hay un descuento monetario
      let desc = productoSecundario2['DESCUENTO_MONETARIO']
      return '$'+formatNumber(precio - desc,2)+' MXN'
    }
    else if(Number(productoSecundario2['DESCUENTO_MONETARIO']) === 0){//hay un descuento en porcentaje
      let desc = productoSecundario2['DESCUENTO_PORCENTAJE']
      return '$'+formatNumber(precio * ((100 - desc)/100) ,2 )+' MXN'
    }
  }

  showHideFiltos(id){
    document.getElementById(id).classList.toggle("floatFiltros2");
  }

  jumpClick(e){
    if(e.target.className==='inputs-label'){
      e.preventDefault()
      e.stopPropagation()
    }
  }

  changeSwitch2(pos,checked){
    let {horarios} = this.state
    horarios[pos]['estatus'] = checked ? 1: 2;
    this.setState({horarios:horarios})
  }

  controlSemanal(event){
    if(event.target.id !== 'mibandera')return
    let {semaforo,deshabilitado} = this.state
    if( semaforo === 1 ){
      if(document.getElementById('swSlave2').getAttribute('aria-checked')==='false')document.getElementById('swSlave2').click()
      if(document.getElementById('swSlave3').getAttribute('aria-checked')==='false')document.getElementById('swSlave3').click()
      if(document.getElementById('swSlave4').getAttribute('aria-checked')==='false')document.getElementById('swSlave4').click()
      if(document.getElementById('swSlave5').getAttribute('aria-checked')==='false')document.getElementById('swSlave5').click()
      if(deshabilitado)this.setState({deshabilitado:false})
    }
    else if( semaforo === 2){
      document.getElementById('swSlave1').click()
      this.setState({deshabilitado:true})
    }
    else if( semaforo === 3){
      if(document.getElementById('swSlave2').getAttribute('aria-checked')==='true')document.getElementById('swSlave2').click()
      if(document.getElementById('swSlave3').getAttribute('aria-checked')==='true')document.getElementById('swSlave3').click()
      if(document.getElementById('swSlave4').getAttribute('aria-checked')==='true')document.getElementById('swSlave4').click()
      if(document.getElementById('swSlave5').getAttribute('aria-checked')==='true')document.getElementById('swSlave5').click()
    }
    semaforo++;
    if(semaforo===4){ semaforo=1; }
    this.setState({semaforo:semaforo})
  }

   horaChange(value,svalue,pos,bandera){
     let {horarios} = this.state
     let name = bandera === 1 ? 'horaInicio' : 'horaTermino'
     let moment_name = bandera === 1 ? 'momentInicio' : 'momentTermino'
     if(pos === 0){
       for(let i=0;i<7;i++){
         horarios[i][name] = value.format('HH:mm')
         horarios[i][moment_name] = value
       }
     }
     else{
       horarios[pos][name] = value.format('HH:mm')
       horarios[pos][moment_name] = value
     }
     this.setState({horarios:horarios})
  }

  render(){
    return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" id="miheight" >
          <div className="cont-sucursales3" >

            <Loading loading={this.state.loading} /> 

            <Modal centered title="Configura tu imagen" maskClosable={false} keyboard = {false} 
                okText="Continuar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
                forceRender={true}
                visible={this.state.modalCroper}
                closable={false}
                className="miAntModal newVar alertMy modalCrop"
                onOk={this.aceptCroper}
                onCancel={this.cancelCroper}
                zIndex={30}
                >
                <div className="myAlertBody">
                  
                  <div className="cont-Cropper" id="myCroper" title="Usa el scroll para hacer zoom" >
                    <Cropper
                      ref={element => this.cropper = element}
                      src=''
                      style={{height:'100%', width: '100%',zIndex:'5'}}
                      aspectRatio={1024/379}
                      />
                  </div>

                </div>
            </Modal>

            {/* tipo 2 */}

            <Modal centered title="Selecciona los productos y servicios participantes" maskClosable={false} keyboard = {false} 
                okText="" okButtonProps={{ style: { display:'none'} }}
                cancelText="" cancelButtonProps={{ style: { display:'none'} }}
                visible={this.state.addingProducto}
                closable={false} zIndex={20}
                className="modalProductosPromo"
                >
                <div className="cont-ProductsPromo">
                  
                  <div className="filtrosPromo">
                    <div className="headFiltros" >
                      
                      <div className="floatFiltros" id="floatFiltros"  >
                        
                        <div className="inputs-group">
                          <label className="inputs-label lbl-Activa"> Sucursal </label>
                          <Select value={this.state.filtro1} onChange={(value)=>this.changeFiltros(value,'filtro1')} >
                            {
                              this.state.sucursales2.filter(sucursal => sucursal.hay).map(sucursal => {
                                return(
                                  <Select.Option value={sucursal.NOMBRE} key={'cat_'+sucursal.ID_SUC} > {sucursal.NOMBRE} </Select.Option>
                                )
                              })
                            }
                            <Select.Option value=""
                              style={{ display:this.state.sucursales2.filter(sucursal => sucursal.hay).length>1? 'block' : 'none'}}
                            > Todas </Select.Option>
                          </Select>
                        </div>
                        
                        <div className="inputs-group">
                          <label className="inputs-label lbl-Activa"> Categoría </label>
                          <Select value={this.state.filtro2} onChange={(value)=>this.changeFiltros(value,'filtro2')} >
                            {
                              this.state.catalogoDeCategorias2.map(categoria => {
                                return(
                                  <Select.Option value={categoria.DESCRIPCION} key={'cat_'+categoria.ID} > {categoria.DESCRIPCION} </Select.Option>
                                )
                              })
                            }
                            <Select.Option value="" > Todas </Select.Option>
                          </Select>
                        </div>

                      </div>

                      <div className="inputs-group">
                        <label className="inputs-label" > Buscar por nombre </label>
                        <input type="text" className="inputs-input" onChange={ e => this.changeFiltros(e.target.value,'filtro3')} />
                      </div>
                      <button className="btn-Filtrar" onClick={()=>this.showHideFiltos('floatFiltros')} > Filtrar </button>

                    </div>

                  </div>
                  
                  <div className="productListPromo">
                     
                     {this.state.productosFiltrados.length === 0 ?
                      <div className="imgnonResult imgnonResult2">
                        <div  className="nonResultChild">
                          <img src={ImgPanel+'nonproductos.png'} alt="..." />
                        </div>
                      </div>
                     :<React.Fragment/>}

                    {
                      this.state.catalogoDeCategorias2.filter(cat => this.state.productosFiltrados.some(pr => pr.CATEGORIA === cat.DESCRIPCION ) ).map(categoria => (
                        <React.Fragment key={'cate_'+categoria.ID}>
                        <div className="nomCate"> {categoria.DESCRIPCION} </div>
                        {
                          this.state.productosFiltrados.filter(producto => producto.CATEGORIA === categoria.DESCRIPCION ).map(producto =>(
                            <React.Fragment key={'prod_'+producto.ID}>
                            <div className="myCheckProd">
                              <div>
                                {/*
                                  <input
                                    type="checkbox"
                                    id={'sucIn_'+producto.ID}
                                    checked={producto.conPromocion}
                                    onChange={(e) => this.addPromo(e,producto.ID,0)}
                                  />
                                  <label className={producto.variantes.length===0?'nomb':'nomb nocheck'} htmlFor={'sucIn_'+producto.ID} > {producto.NOMBRE} </label>
                                */}
                                <Checkbox
                                  id={'sucIn_'+producto.ID}
                                  checked={producto.conPromocion}
                                  onChange={(e) => this.addPromo(e,producto.ID,0)}                                   
                                  className="checkInFilter"
                                >
                                  {producto.NOMBRE}
                                </Checkbox>
                              </div>
                              <label className={producto.variantes.length===0?'prec':'prec nocheck'} htmlFor={'sucIn_'+producto.ID} > {producto.PRECIO} </label>
                            </div>
                            {
                              producto.variantes.map(variante=>(
                                <div className="myCheckProd" key={'var_'+variante.ID}>
                                  <div>
                                    {/*
                                      <input
                                        type="checkbox"
                                        id={'sucIn_'+variante.ID}
                                        checked={variante.conPromocion}
                                        onChange={(e) => this.addPromo(e,producto.ID,variante.ID)}
                                      />
                                      <label className="nomb nomHijo" htmlFor={'sucIn_'+variante.ID} > {variante.NOMBRE} </label>
                                    */}
                                    <Checkbox
                                      id={'sucIn_'+variante.ID}
                                      checked={variante.conPromocion}
                                      onChange={(e) => this.addPromo(e,producto.ID,variante.ID)}                             
                                      className="checkInFilter"
                                    >
                                      {variante.NOMBRE}
                                    </Checkbox>
                                  </div>
                                  <label className="prec" htmlFor={'sucIn_'+variante.ID} > {variante.PRECIO} </label>
                                </div>
                              ))
                            }
                            </React.Fragment>
                          ))
                        }
                        </React.Fragment>
                      ))
                    }
                  </div>

                  <div className="footPromoModal newProductFoot">
                    <div className="dosBtones">
                        <button className="btnEliminar" onClick={this.NoagregarProductos} > Cancelar </button>
                        <button className="btnAceptar" onClick={this.agregarProductos} > Continuar </button>
                    </div>
                  </div>

                </div>
            </Modal>

            <Modal centered title="Elige el tipo de descuento" maskClosable={false} keyboard = {false} 
              okText="Lista de promociones" okButtonProps={{ style: { display:'none'} }}
              cancelText="Agregar promoción" cancelButtonProps={{ style: { display:'none'} }}
              visible={this.state.addingDesc}
              closable={false}
              zIndex={20}
              className="modalProductosPromo"
              >
                {
                  this.state.addingDesc?
                  <div className="contTipoDespuesto">
                    <form id="formulaDescuento">
                    <div className="opDescuento">
                      <input type="radio" name="tipoDes" value="-1" className="radioBubook" defaultChecked={this.state.tipoDesc===-1} onChange={this.changeTipoDescuento} />
                      <div className="inputs-group">
                        <label className="inputs-label"> Descuento en porcentaje </label>
                        <NumberFormat className="inputs-input" name="descP" id="descPd" type="text" suffix="%" maxLength={4}
                          defaultValue={this.state.tipoDesc===-1?this.state.desc:''} readOnly={this.state.tipoDesc===-1?false:true} />
                      </div>
                    </div>

                    <div className="opDescuento">
                      <input type="radio" name="tipoDes" value="1" className="radioBubook" defaultChecked={this.state.tipoDesc===1} onChange={this.changeTipoDescuento}/>
                      <div className="inputs-group">
                        <label className="inputs-label"> Descuento monetario </label>
                        <NumberFormat className="inputs-input" name="descM" id="descMd" type="text" thousandSeparator=',' decimalSeparator='.' prefix='$' 
                          defaultValue={this.state.tipoDesc===1?this.state.desc:''} readOnly={this.state.tipoDesc===1?false:true} />
                      </div>
                    </div>
                    </form>

                    <div className="footPromoModal newProductFoot">
                        <div className="dosBtones">
                          <button className="btnEliminar" onClick={this.NoagregarProductos} > Cancelar </button>
                          <button className="btnAceptar" onClick={this.addDesc} > Aceptar </button>
                        </div>
                    </div>
                  </div>:<React.Fragment/>
                }
            </Modal>

            {/* tipo 3 */}

            <Modal centered visible={this.state.miniModalTipo3} closable={!false} title={null} footer={null}
              onCancel={this.noPongasCantidadPrincipal} destroyOnClose={true}
              className="modalGMaps modalGMaps2 miAntModal" >
              <div className="contentModalMapa contentModalMapaNewPromo">
              <div className="title"> En la compra de </div>
              
              <div className="producto" onClick={()=>this.miniModal3Clicker(1)}>
                {
                  this.state.productoTipo3Aux['ID_PRODUCTO'] !== '' ?
                  <React.Fragment>
                    <span> {this.state.productoTipo3Aux['CANTIDAD']}x </span>
                    <label className="nombre"> {this.state.productoTipo3Aux['NOMBRE']} </label>
                    <label className="precio"> ${formatNumber(this.state.productoTipo3Aux['PRECIO2'] * this.state.productoTipo3Aux['CANTIDAD'] , 2)} MXN</label>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <span className="clickable"> 0x </span>
                    <label className="clickable"> Selecciona un producto o servicio </label>
                  </React.Fragment>
                }
              </div>

              <div className="title"> Llévate gratis </div>
              
              <div className="producto" onClick={()=>this.miniModal3Clicker(2)}>
                {
                  this.state.productoTipo3AuxSecundario['ID_PRODUCTO'] !== '' ?
                  <React.Fragment>
                    <span> {this.state.productoTipo3AuxSecundario['CANTIDAD']}x </span>
                    <label className="nombre"> {this.state.productoTipo3AuxSecundario['NOMBRE']} </label>
                    <label className="preciosGratis">
                      <del> ${formatNumber(this.state.productoTipo3AuxSecundario['PRECIO2'] * this.state.productoTipo3AuxSecundario['CANTIDAD'] , 2)} MXN</del>
                      <label> Gratis </label>
                    </label>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <span className="clickable"> 0x </span>
                    <label className="clickable"> Selecciona un producto o servicio </label>
                  </React.Fragment>
                }
              </div>
              <div className="flexcenter Fcenter btnAceptarPromo" onClick={this.saveProducto3} > Aceptar </div>
              </div>
            </Modal>

            <Modal centered title="Selecciona el producto o servicio principal" visible={this.state.addingProducto21}
                okText="Lista de promociones" okButtonProps={{ style: { display:'none'} }}
                cancelText="Agregar promoción" cancelButtonProps={{ style: { display:'none'} }}
                maskClosable={false} keyboard = {false} 
                closable={false}
                className="modalProductosPromo"
                zIndex={20}
                >
                <div className="cont-ProductsPromo ProductsPromo2">
                  {
                    this.state.addingProducto21?
                  <React.Fragment>
                  <div className="filtrosPromo">
                    <div className="headFiltros" >
                      
                      <div className="floatFiltros" id="floatFiltros2"  >
                        
                        <div className="inputs-group">
                          <label className="inputs-label lbl-Activa"> Sucursal </label>
                          <Select value={this.state.filtro1} onChange={(value)=>this.changeFiltros(value,'filtro1')} >
                            {
                              this.state.sucursales2.filter(sucursal => sucursal.hay).map(sucursal => {
                                return(
                                  <Select.Option value={sucursal.NOMBRE} key={'cat_'+sucursal.ID_SUC} > {sucursal.NOMBRE} </Select.Option>
                                )
                              })
                            }
                            <Select.Option value=""
                              style={{ display:this.state.sucursales2.filter(sucursal => sucursal.hay).length>1? 'block' : 'none'}}
                            > Todas </Select.Option>
                          </Select>
                        </div>
                        
                        <div className="inputs-group">
                          <label className="inputs-label lbl-Activa"> Categoría </label>
                          <Select value={this.state.filtro2} onChange={(value)=>this.changeFiltros(value,'filtro2')} >
                            {
                              this.state.catalogoDeCategorias2.map(categoria => {
                                return(
                                  <Select.Option value={categoria.DESCRIPCION} key={'cat_'+categoria.ID} > {categoria.DESCRIPCION} </Select.Option>
                                )
                              })
                            }
                            <Select.Option value="" > Todas </Select.Option>
                          </Select>
                        </div>

                      </div>

                      <div className="inputs-group">
                        <label className="inputs-label" > Buscar por nombre </label>
                        <input type="text" className="inputs-input" onChange={ e => this.changeFiltros(e.target.value,'filtro3')} />
                      </div>
                      <button className="btn-Filtrar" onClick={()=>this.showHideFiltos('floatFiltros2')} > Filtrar </button>

                    </div>

                  </div>
                  
                  <div className="productListPromo">
                    {this.state.productosFiltrados.length === 0 ?
                      <div className="imgnonResult imgnonResult2">
                        <div  className="nonResultChild">
                          <img src={ImgPanel+'nonproductos.png'} alt="..." />
                        </div>
                      </div>
                     :<React.Fragment/>}
                    {
                      this.state.catalogoDeCategorias2.filter(cat => this.state.productosFiltrados.some(pr => pr.CATEGORIA === cat.DESCRIPCION ) ).map(categoria => (
                        <React.Fragment key={'cate_'+categoria.ID}>
                        <div className="nomCate"> {categoria.DESCRIPCION} </div>
                        {
                          this.state.productosFiltrados.filter(producto => producto.CATEGORIA === categoria.DESCRIPCION ).map(producto =>(
                            <React.Fragment key={'prod_'+producto.ID}>
                            <div className="myCheckProd">
                              <div>
                                {producto.variantes.length===0?<input type="radio" name="prodPrin"
                                  defaultChecked={this.state.productoTipo3Aux.ID_PRODUCTO === producto.ID} className="radioBubook" id={'sucIn2_'+producto.ID}  onChange={(e) => this.setPrincipal(producto.ID,0)} />:<React.Fragment/>}
                                <label className={producto.variantes.length===0?'nomb':'nomb nocheck'} htmlFor={'sucIn2_'+producto.ID} > {producto.NOMBRE} </label>
                              </div>
                              <label className={producto.variantes.length===0?'prec':'prec nocheck'} htmlFor={'sucIn2_'+producto.ID} > {producto.PRECIO} </label>
                            </div>
                            {
                              producto.variantes.map(variante=>(
                                <div className="myCheckProd" key={'var_'+variante.ID}>
                                  <div>
                                    <input type="radio"  name="prodPrin" className="radioBubook" id={'sucIn2_'+variante.ID}
                                      defaultChecked={this.state.productoTipo3Aux.ID_PRODUCTO === variante.ID} onChange={(e) => this.setPrincipal(producto.ID,variante.ID)} />
                                    <label className="nomb nomHijo" htmlFor={'sucIn2_'+variante.ID} > {variante.NOMBRE} </label>
                                  </div>
                                  <label className="prec" htmlFor={'sucIn2_'+variante.ID} > {variante.PRECIO} </label>
                                </div>
                              ))
                            }
                            </React.Fragment>
                          ))
                        }
                        </React.Fragment>
                      ))
                    }
                  </div>
                  
                  <div className="contcont">
                    <div className="inputs-group" style={{width:'95%'}} >
                      <label className="inputs-label lbl-Activa"> Cantidad de compra </label>
                      <NumberFormat className="inputs-input" id="cantidadd" type="text" decimalScale={0} defaultValue={this.state.productoTipo3Aux['CANTIDAD']} />
                    </div>
                  </div>

                  <div className="footPromoModal newProductFoot">
                    <div className="dosBtones">
                        <button className="btnEliminar" onClick={()=>this.closeModalAux(1)} > Cancelar </button>
                        <button className="btnAceptar" onClick={this.ponCantidadPrincipal} > Continuar </button>{/* productoTipo3AuxSecundario = {} */}
                    </div>
                  </div>
                  </React.Fragment>:<React.Fragment/>
                 }
                </div>
            </Modal>

            <Modal centered title="Selecciona el producto o servicio secundario" visible={this.state.addingProducto22}
                okText="Lista de promociones" okButtonProps={{ style: { display:'none'} }}
                cancelText="Agregar promoción" cancelButtonProps={{ style: { display:'none'} }}
                maskClosable={false} keyboard = {false} 
                closable={false}
                className="modalProductosPromo"
                zIndex={20}
                >
                <div className="cont-ProductsPromo ProductsPromo2">
                  {
                    this.state.addingProducto22?
                    <React.Fragment>
                  
                  <div className="filtrosPromo">
                    <div className="headFiltros" >
                      
                      <div className="floatFiltros" id="floatFiltros2"  >
                        
                        <div className="inputs-group">
                          <label className="inputs-label lbl-Activa"> Sucursal </label>
                          <Select value={this.state.filtro1} onChange={(value)=>this.changeFiltros(value,'filtro1')} >
                            {
                              this.state.sucursales2.filter(sucursal => sucursal.hay).map(sucursal => {
                                return(
                                  <Select.Option value={sucursal.NOMBRE} key={'cat_'+sucursal.ID_SUC} > {sucursal.NOMBRE} </Select.Option>
                                )
                              })
                            }
                            <Select.Option value=""
                              style={{ display:this.state.sucursales2.filter(sucursal => sucursal.hay).length>1? 'block' : 'none'}}
                            > Todas </Select.Option>
                          </Select>
                        </div>
                        
                        <div className="inputs-group">
                          <label className="inputs-label lbl-Activa"> Categoría </label>
                          <Select value={this.state.filtro2} onChange={(value)=>this.changeFiltros(value,'filtro2')} >
                            {
                              this.state.catalogoDeCategorias2.map(categoria => {
                                return(
                                  <Select.Option value={categoria.DESCRIPCION} key={'cat_'+categoria.ID} > {categoria.DESCRIPCION} </Select.Option>
                                )
                              })
                            }
                            <Select.Option value="" > Todas </Select.Option>
                          </Select>
                        </div>

                      </div>

                      <div className="inputs-group">
                        <label className="inputs-label" > Buscar por nombre </label>
                        <input type="text" className="inputs-input" onChange={ e => this.changeFiltros(e.target.value,'filtro3')} defaultValue={this.state.filtro3} />
                      </div>
                      <button className="btn-Filtrar" onClick={()=>this.showHideFiltos('floatFiltros2')} > Filtrar </button>

                    </div>

                  </div>
                  
                  <div className="productListPromo">
                  {this.state.productosFiltrados.length === 0 ?
                      <div className="imgnonResult imgnonResult2">
                        <div  className="nonResultChild">
                          <img src={ImgPanel+'nonproductos.png'} alt="..." />
                        </div>
                      </div>
                     :<React.Fragment/>}

                    {
                      this.state.catalogoDeCategorias2.filter(cat => this.state.productosFiltrados.some(pr => pr.CATEGORIA === cat.DESCRIPCION ) ).map(categoria => (
                        <React.Fragment key={'cate_'+categoria.ID}>
                        <div className="nomCate"> {categoria.DESCRIPCION} </div>
                        {
                          this.state.productosFiltrados.filter(producto => producto.CATEGORIA === categoria.DESCRIPCION ).map(producto =>(
                            <React.Fragment key={'prod_'+producto.ID}>
                            <div className="myCheckProd">
                              <div>
                                {producto.variantes.length===0?<input type="radio" name="prodPrin"
                                  checked={this.state.productoTipo3AuxSecundario.ID_PRODUCTO === producto.ID} className="radioBubook" id={'sucIn3_'+producto.ID}  onChange={(e) => this.setSecundario(producto.ID,0)} />:<React.Fragment/>}
                                <label className={producto.variantes.length===0?'nomb':'nomb nocheck'} htmlFor={'sucIn3_'+producto.ID} > {producto.NOMBRE} </label>
                              </div>
                              <label className={producto.variantes.length===0?'prec':'prec nocheck'} htmlFor={'sucIn3_'+producto.ID} > {producto.PRECIO} </label>
                            </div>
                            {
                              producto.variantes.map(variante=>(
                                <div className="myCheckProd" key={'var_'+variante.ID}>
                                  <div>
                                    <input type="radio"  name="prodPrin" className="radioBubook" id={'sucIn3_'+variante.ID}
                                      checked={this.state.productoTipo3AuxSecundario.ID_PRODUCTO === variante.ID} onChange={(e) => this.setSecundario(producto.ID,variante.ID)} />
                                    <label className="nomb nomHijo" htmlFor={'sucIn3_'+variante.ID} > {variante.NOMBRE} </label>
                                  </div>
                                  <label className="prec" htmlFor={'sucIn3_'+variante.ID} > {variante.PRECIO} </label>
                                </div>
                              ))
                            }
                            </React.Fragment>
                          ))
                        }
                        </React.Fragment>
                      ))
                    }
                  </div>
                  
                  <div className="contcont">
                    <div className="inputs-group" style={{width:'95%'}} >
                      <label className="inputs-label lbl-Activa"> Cantidad de regalo </label>
                      <NumberFormat className="inputs-input" id="cantidad2d" decimalScale={0} type="text" defaultValue={this.state.productoTipo3AuxSecundario['CANTIDAD']} />
                    </div>
                  </div>

                  <div className="footPromoModal newProductFoot">
                    <div className="dosBtones">
                        <button className="btnEliminar" onClick={()=>this.closeModalAux(2)} > Cancelar </button>
                        <button className="btnAceptar" onClick={this.ponCantidadSecundaria} > Guardar </button>
                    </div>
                  </div>
                    </React.Fragment>:<React.Fragment/>
                }
                </div>
            </Modal>


            {/* tipo 4 */}

            <Modal centered visible={this.state.miniModalTipo4} closable={!false} title={null} footer={null}
              onCancel={this.noPongasCantidadPrincipal2} destroyOnClose={true}
              className="modalGMaps modalGMaps2 miAntModal" >
              <div className="contentModalMapa contentModalMapaNewPromo">
              <div className="title"> En la compra de </div>
              
              <div className="producto" onClick={()=>this.miniModal4Clicker(1)}>
                 {
                  this.state.productoTipo4Aux['ID_PRODUCTO'] !== '' ?
                  <React.Fragment>
                    <span> {this.state.productoTipo4Aux['CANTIDAD']}x </span>
                    <label className="nombre"> {this.state.productoTipo4Aux['NOMBRE']} </label>
                    <label className="precio"> ${formatNumber(this.state.productoTipo4Aux['PRECIO2'] * this.state.productoTipo4Aux['CANTIDAD'] , 2)} MXN</label>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <span className="clickable"> 0x </span>
                    <label className="clickable"> Selecciona un producto o servicio </label>
                  </React.Fragment>
                }
              </div>

              <div className="title"> Llévate </div>
              
              <div className="producto" onClick={()=>this.miniModal4Clicker(2)}>
                {
                  this.state.productoTipo4AuxSecundario['ID_PRODUCTO'] !== '' ?
                  <React.Fragment>
                    <span> {this.state.productoTipo4AuxSecundario['CANTIDAD']}x </span>
                    <label className="nombre"> {this.state.productoTipo4AuxSecundario['NOMBRE']} </label>
                    <label className="precio"> ${formatNumber(this.state.productoTipo4AuxSecundario['PRECIO2'] * this.state.productoTipo4AuxSecundario['CANTIDAD'] , 2)} MXN</label>
                  </React.Fragment>
                  :
                  <React.Fragment>
                    <span className="clickable"> 0x </span>
                    <label className="clickable"> Selecciona un producto o servicio </label>
                  </React.Fragment>
                }
              </div>

              <div className="title"> Con un descuento </div>
              
              <div className="radiosbubok flexcenter Fcenter">
                <div onClick={()=>this.setState({tipoDesc:-1})} >
                  <input type="radio" name="tipoDes21" id="tipoDes21" value="-1" className="radioBubook" defaultChecked={this.state.tipoDesc===-1} /> Monetario
                </div>
                 
                <div onClick={()=>this.setState({tipoDesc:-2})}>
                  <input type="radio" name="tipoDes21" id="tipoDes22" value="1" className="radioBubook" defaultChecked={this.state.tipoDesc===1} />  Porcentual
                </div>
              </div>

              <div className="contcont">
                <div className="inputs-group" style={{width:'99%'}} >
                  <NumberFormat className="inputs-input" id="cantidaddd2" type="text"
                      defaultValue={ this.state.tipoDesc===-1?
                        this.state.productoTipo4AuxSecundario['DESCUENTO_MONETARIO']:
                        this.state.productoTipo4AuxSecundario['DESCUENTO_PORCENTAJE'] }

                      thousandSeparator={this.state.tipoDesc===-1?',':''}
                      decimalSeparator={'.'}
                      prefix={this.state.tipoDesc===-1?'$':''}
                      suffix={this.state.tipoDesc===-1?' MXN':'%'}
                      decimalScale={this.state.tipoDesc===-1?2:0}
                      allowNegative={false}
                    />
                </div>
              </div>

              <div className="flexcenter Fcenter btnAceptarPromo" onClick={this.saveProducto4} > Aceptar </div>
              </div>
            </Modal>

            <Modal centered title="Selecciona el producto o servicio principal" maskClosable={false} keyboard = {false} 
                okText="Lista de promociones" okButtonProps={{ style: { display:'none'} }}
                cancelText="Agregar promoción" cancelButtonProps={{ style: { display:'none'} }}
                visible={this.state.addingProducto31}
                closable={false}
                className="modalProductosPromo"
                zIndex={20}
                >
                <div className="cont-ProductsPromo ProductsPromo2">
                  {
                    this.state.addingProducto31 ?
                    <React.Fragment>
                  <div className="filtrosPromo">
                    <div className="headFiltros" >
                      
                      <div className="floatFiltros" id="floatFiltros3"  >
                        
                        <div className="inputs-group">
                          <label className="inputs-label lbl-Activa"> Sucursal </label>
                          <Select value={this.state.filtro1} onChange={(value)=>this.changeFiltros(value,'filtro1')} >
                            {
                              this.state.sucursales2.filter(sucursal => sucursal.hay).map(sucursal => {
                                return(
                                  <Select.Option value={sucursal.NOMBRE} key={'cat_'+sucursal.ID_SUC} > {sucursal.NOMBRE} </Select.Option>
                                )
                              })
                            }
                            <Select.Option value=""
                              style={{ display:this.state.sucursales2.filter(sucursal => sucursal.hay).length>1? 'block' : 'none'}}
                            > Todas </Select.Option>
                          </Select>
                        </div>
                        
                        <div className="inputs-group">
                          <label className="inputs-label lbl-Activa"> Categoría </label>
                          <Select value={this.state.filtro2} onChange={(value)=>this.changeFiltros(value,'filtro2')} >
                            {
                              this.state.catalogoDeCategorias2.map(categoria => {
                                return(
                                  <Select.Option value={categoria.DESCRIPCION} key={'cat_'+categoria.ID} > {categoria.DESCRIPCION} </Select.Option>
                                )
                              })
                            }
                            <Select.Option value="" > Todas </Select.Option>
                          </Select>
                        </div>

                      </div>

                      <div className="inputs-group">
                        <label className="inputs-label" > Buscar por nombre </label>
                        <input type="text" className="inputs-input" onChange={ e => this.changeFiltros(e.target.value,'filtro3')} />
                      </div>
                      <button className="btn-Filtrar" onClick={()=>this.showHideFiltos('floatFiltros3')} > Filtrar </button>

                    </div>

                  </div>
                  
                  <div className="productListPromo">
                  {this.state.productosFiltrados.length === 0 ?
                      <div className="imgnonResult imgnonResult2">
                        <div  className="nonResultChild">
                          <img src={ImgPanel+'nonproductos.png'} alt="..." />
                        </div>
                      </div>
                     :<React.Fragment/>}

                    {
                      this.state.catalogoDeCategorias2.filter(cat => this.state.productosFiltrados.some(pr => pr.CATEGORIA === cat.DESCRIPCION ) ).map(categoria => (
                        <React.Fragment key={'cate_'+categoria.ID}>
                        <div className="nomCate"> {categoria.DESCRIPCION} </div>
                        {
                          this.state.productosFiltrados.filter(producto => producto.CATEGORIA === categoria.DESCRIPCION ).map(producto =>(
                            <React.Fragment key={'prod_'+producto.ID}>
                            <div className="myCheckProd">
                              <div>
                                {producto.variantes.length===0?<input type="radio" name="prodPrin"
                                  defaultChecked={this.state.productoTipo4Aux.ID_PRODUCTO === producto.ID} className="radioBubook" id={'sucIn4_'+producto.ID}  onChange={(e) => this.setPrincipal2(producto.ID,0)} />:<React.Fragment/>}
                                <label className={producto.variantes.length===0?'nomb':'nomb nocheck'} htmlFor={'sucIn4_'+producto.ID} > {producto.NOMBRE} </label>
                              </div>
                              <label className={producto.variantes.length===0?'prec':'prec nocheck'} htmlFor={'sucIn4_'+producto.ID} > {producto.PRECIO} </label>
                            </div>
                            {
                              producto.variantes.map(variante=>(
                                <div className="myCheckProd" key={'var_'+variante.ID}>
                                  <div>
                                    <input type="radio"  name="prodPrin" className="radioBubook" id={'sucIn4_'+variante.ID}
                                      defaultChecked={this.state.productoTipo4Aux.ID_PRODUCTO === variante.ID} onChange={(e) => this.setPrincipal2(producto.ID,variante.ID)} />
                                    <label className="nomb nomHijo" htmlFor={'sucIn4_'+variante.ID} > {variante.NOMBRE} </label>
                                  </div>
                                  <label className="prec" htmlFor={'sucIn4_'+variante.ID} > {variante.PRECIO} </label>
                                </div>
                              ))
                            }
                            </React.Fragment>
                          ))
                        }
                        </React.Fragment>
                      ))
                    }
                  </div>
                  
                  <div className="contcont">
                    <div className="inputs-group" style={{width:'95%'}} >
                      <label className="inputs-label lbl-Activa"> Cantidad de compra </label>
                      <NumberFormat className="inputs-input" id="cantidadd2" type="text" decimalScale={0} defaultValue={this.state.productoTipo4Aux['CANTIDAD']} />
                    </div>
                  </div>

                  <div className="footPromoModal newProductFoot">
                    <div className="dosBtones">
                        <button className="btnEliminar" onClick={()=>this.closeModalAux2(1)} > Cancelar </button>
                        <button className="btnAceptar" onClick={this.ponCantidadPrincipal2} > Continuar </button>
                    </div>
                  </div>

                  </React.Fragment>:<React.Fragment/>
                }

                </div>
            </Modal>

            <Modal centered title="Selecciona el producto o servicio secundario" maskClosable={false} keyboard = {false} 
                okText="Lista de promociones" okButtonProps={{ style: { display:'none'} }}
                cancelText="Agregar promoción" cancelButtonProps={{ style: { display:'none'} }}
                visible={this.state.addingProducto32}
                closable={false}
                className="modalProductosPromo"
                zIndex={20}
                >
                <div className="cont-ProductsPromo ProductsPromo2">
                  {
                    this.state.addingProducto32 ?
                    <React.Fragment>
                  
                  <div className="filtrosPromo">
                    <div className="headFiltros" >
                      
                      <div className="floatFiltros" id="floatFiltros4"  >
                        
                        <div className="inputs-group">
                          <label className="inputs-label lbl-Activa"> Sucursal </label>
                          <Select value={this.state.filtro1} onChange={(value)=>this.changeFiltros(value,'filtro1')} >
                            {
                              this.state.sucursales2.filter(sucursal => sucursal.hay).map(sucursal => {
                                return(
                                  <Select.Option value={sucursal.NOMBRE} key={'cat_'+sucursal.ID_SUC} > {sucursal.NOMBRE} </Select.Option>
                                )
                              })
                            }
                            <Select.Option value=""
                              style={{ display:this.state.sucursales2.filter(sucursal => sucursal.hay).length>1? 'block' : 'none'}}
                            > Todas </Select.Option>
                          </Select>
                        </div>
                        
                        <div className="inputs-group">
                          <label className="inputs-label lbl-Activa"> Categoría </label>
                          <Select value={this.state.filtro2} onChange={(value)=>this.changeFiltros(value,'filtro2')} >
                            {
                              this.state.catalogoDeCategorias2.map(categoria => {
                                return(
                                  <Select.Option value={categoria.DESCRIPCION} key={'cat_'+categoria.ID} > {categoria.DESCRIPCION} </Select.Option>
                                )
                              })
                            }
                            <Select.Option value="" > Todas </Select.Option>
                          </Select>
                        </div>

                      </div>

                      <div className="inputs-group">
                        <label className="inputs-label" > Buscar por nombre </label>
                        <input type="text" className="inputs-input" onChange={ e => this.changeFiltros(e.target.value,'filtro3')} defaultValue={this.state.filtro3} />
                      </div>
                      <button className="btn-Filtrar" onClick={()=>this.showHideFiltos('floatFiltros4')} > Filtrar </button>

                    </div>

                  </div>
                  
                  <div className="productListPromo">
                  {this.state.productosFiltrados.length === 0 ?
                      <div className="imgnonResult imgnonResult2">
                        <div  className="nonResultChild">
                          <img src={ImgPanel+'nonproductos.png'} alt="..." />
                        </div>
                      </div>
                     :<React.Fragment/>}
                    {
                      this.state.catalogoDeCategorias2.filter(cat => this.state.productosFiltrados.some(pr => pr.CATEGORIA === cat.DESCRIPCION ) ).map(categoria => (
                        <React.Fragment key={'cate_'+categoria.ID}>
                        <div className="nomCate"> {categoria.DESCRIPCION} </div>
                        {
                          this.state.productosFiltrados.filter(producto => producto.CATEGORIA === categoria.DESCRIPCION ).map(producto =>(
                            <React.Fragment key={'prod_'+producto.ID}>
                            <div className="myCheckProd">
                              <div>
                                {producto.variantes.length===0?<input type="radio" name="prodPrin"
                                  checked={this.state.productoTipo4AuxSecundario.ID_PRODUCTO === producto.ID} className="radioBubook" id={'sucIn5_'+producto.ID}  onChange={(e) => this.setSecundario2(producto.ID,0)} />:<React.Fragment/>}
                                <label className={producto.variantes.length===0?'nomb':'nomb nocheck'} htmlFor={'sucIn5_'+producto.ID} > {producto.NOMBRE} </label>
                              </div>
                              <label className={producto.variantes.length===0?'prec':'prec nocheck'} htmlFor={'sucIn5_'+producto.ID} > {producto.PRECIO} </label>
                            </div>
                            {
                              producto.variantes.map(variante=>(
                                <div className="myCheckProd" key={'var_'+variante.ID}>
                                  <div>
                                    <input type="radio"  name="prodPrin" className="radioBubook" id={'sucIn5_'+variante.ID}
                                      checked={this.state.productoTipo4AuxSecundario.ID_PRODUCTO === variante.ID} onChange={(e) => this.setSecundario2(producto.ID,variante.ID)} />
                                    <label className="nomb nomHijo" htmlFor={'sucIn5_'+variante.ID} > {variante.NOMBRE} </label>
                                  </div>
                                  <label className="prec" htmlFor={'sucIn5_'+variante.ID} > {variante.PRECIO} </label>
                                </div>
                              ))
                            }
                            </React.Fragment>
                          ))
                        }
                        </React.Fragment>
                      ))
                    }
                  </div>
                  
                  <div className="contcont">
                    <div className="inputs-group" style={{width:'95%'}} >
                      <label className="inputs-label lbl-Activa" > Cantidad </label>
                      <NumberFormat className="inputs-input" id="cantidad2d2" type="text" decimalScale={0} defaultValue={this.state.productoTipo4AuxSecundario['CANTIDAD']} />
                    </div>
                  </div>

                  <div className="footPromoModal newProductFoot">
                    <div className="dosBtones">
                        <button className="btnEliminar" onClick={()=>this.closeModalAux2(2)} > Cancelar </button>
                        <button className="btnAceptar" onClick={this.ponCantidadSecundaria2} > Guardar </button>
                    </div>
                  </div>
                  </React.Fragment>:<React.Fragment/>
                }
                </div>
            </Modal>

            {/* modal eliminar */}
            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
                visible={this.state.modalEliminar}
                closable={false}
                onOk={this.eliminarRegistroPromocion}
                onCancel={()=> this.setState({modalEliminar:false,indiceEliminar:-1})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalEliminar ?
                        <span className="txtAlert"> ¿Desea eliminar este producto de la promoción? </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            {/* modal disable sucursal */}
            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
                visible={this.state.modalDisableSucursal}
                closable={false}
                onOk={this.eliminarSucursalDePromocion}
                onCancel={()=> this.setState({modalDisableSucursal:false,indiceEliminarSucursal:-1})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalDisableSucursal ?
                        <span className="txtAlert"> Si desactivas esta sucursal, algunos productos y servicios se eliminarán del listado de promociones.
                        ¿Deseas continuar? </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            {/* modal change tipovigencia */}
            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
                visible={this.state.modalChangeVigencia}
                closable={false}
                onOk={()=>this.setState({modalChangeVigencia:false, status:'1'}) }
                onCancel={()=> this.setState({modalChangeVigencia:false})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalChangeVigencia ?
                        <span className="txtAlert"> Si cambia el tipo de vigencia perderá los horarios ya configurados, ¿Desea continuar? </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            {/*modal change tipo promocion*/}
            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
                visible={this.state.modalTipo}
                closable={false}
                onOk={this.aceptChangeTipoPromocion}
                onCancel={()=> this.setState({modalTipo:false,nextelegido:0})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalTipo ?
                        <span className="txtAlert"> Si cambia el tipo de promoción, se perderán los datos del listado de productos y servicios. </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            {/*modal alert  respuesta*/}
             <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Lista de promociones" okButtonProps={{ className:'btn btn1' , style: { width:'180px'} }}
                cancelText="Nuevo registro" cancelButtonProps={{ className:'btn btn2' , style: { width:'170px'} }}
                visible={this.state.modalRespuesta}
                closable={false}
                onOk={()=>this.props.redirectTo2('/home/promociones')}
                onCancel={()=> window.location.reload() }
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalRespuesta   ?
                        <span className="txtAlert"> {this.state.txtRespuesta} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            {/*modal alert*/}
            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelButtonProps={{ className:'btn btn2' , style: { width:'120px', display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy"
                zIndex={9999}
                >
                <div className="myAlertBody">
                    {
                        this.state.modalAlert   ?
                        <span className="txtAlert"> {this.state.txtAlert} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>


            {/* Modal horarios */}
            <Modal centered title={'Horarios'} maskClosable={false} keyboard = {false} 
              okText="Guardar" okButtonProps={{ className:'btn btn1 flexcenter Fcenter' , style: { width:'120px',display:'inline-flex'} }}
              cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',display:'none'} }}
              visible={this.state.modalHorarios}
              closable={false}
              onOk={() => this.setState({modalHorarios:false})}
              onCancel={() => this.setState({modalHorarios:false})}
              className="modalGMaps modalGMaps2 miAntModal"
            >
              <div className="contentModalMapa">

              <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                      
                <label className="inputs-label" >
                  {this.state.semaforo === 1 ? 'Lunes a Viernes' : 'Lunes' }
                    <div className="contSwitch">
                      <div className="panelAux" id="mibandera" onClick={this.controlSemanal} ></div>
                      <Switch id="swSlave1" disabled={ this.state.deshabilitado }  checked={this.state.horarios[0]['estatus']===1}  className="myswitch" onChange={ (checked) => this.changeSwitch2(0,checked)} />
                    </div>
                </label>
                  <div className="mytimers" style={{display:this.state.horarios[0]['estatus']===1?'flex':'none'}} >
                    <TimePicker allowClear={false} placeholder="Desde" value={this.state.horarios[0]['momentInicio']} onChange={ (value,svalue) => this.horaChange(value,svalue,0,1) } format={'HH:mm'} />
                    <TimePicker allowClear={false} placeholder="Hasta" value={this.state.horarios[0]['momentTermino']} onChange={ (value,svalue) => this.horaChange(value,svalue,0,2) } format={'HH:mm'} />
                  </div>
              </div>

              <div style={{display: this.state.semaforo === 1 ? 'none' : 'block'  }} className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                
                <label className="inputs-label">
                  Martes
                  <Switch id="swSlave2" className="myswitch" checked={this.state.horarios[1]['estatus']===1} onChange={ (checked,event) => this.changeSwitch2(1,checked)}/>
                </label>
                
                <div className="mytimers" style={{display:this.state.horarios[1]['estatus']===1?'flex':'none'}} >
                  <TimePicker allowClear={false} placeholder="Desde" value={this.state.horarios[1]['momentInicio']} onChange={ (value,svalue) => this.horaChange(value,svalue,1,1) } format={'HH:mm'} />
                  <TimePicker allowClear={false} placeholder="Hasta" value={this.state.horarios[1]['momentTermino']} onChange={ (value,svalue) => this.horaChange(value,svalue,1,2) } format={'HH:mm'} />
                </div>
              </div>

             <div style={{display: this.state.semaforo === 1 ? 'none' : 'block'  }} className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                
                <label className="inputs-label">
                  Miércoles
                  <Switch id="swSlave3"  className="myswitch" checked={this.state.horarios[2]['estatus']===1} onChange={ (checked,event) => this.changeSwitch2(2,checked)}/>
                </label>
                
                <div className="mytimers" style={{display:this.state.horarios[2]['estatus']===1?'flex':'none'}} >
                  <TimePicker allowClear={false} placeholder="Desde" value={this.state.horarios[2]['momentInicio']} onChange={ (value,svalue) => this.horaChange(value,svalue,2,1) } format={'HH:mm'} />
                  <TimePicker allowClear={false} placeholder="Hasta" value={this.state.horarios[2]['momentTermino']} onChange={ (value,svalue) => this.horaChange(value,svalue,2,2) } format={'HH:mm'} />
                </div>
             </div>

              <div style={{display: this.state.semaforo === 1 ? 'none' : 'block'  }} className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                  
                  <label className="inputs-label">
                    Jueves
                    <Switch id="swSlave4" className="myswitch" checked={this.state.horarios[3]['estatus']===1} onChange={ (checked,event) => this.changeSwitch2(3,checked)}/>
                  </label>
                  
                  <div className="mytimers" style={{display:this.state.horarios[3]['estatus']===1?'flex':'none'}} >
                    <TimePicker allowClear={false} placeholder="Desde" value={this.state.horarios[3]['momentInicio']} onChange={ (value,svalue) => this.horaChange(value,svalue,3,1) } format={'HH:mm'} />
                    <TimePicker allowClear={false} placeholder="Hasta" value={this.state.horarios[3]['momentTermino']} onChange={ (value,svalue) => this.horaChange(value,svalue,3,2) } format={'HH:mm'} />
                  </div>
              </div>

              <div style={{display: this.state.semaforo === 1 ? 'none' : 'block'  }} className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                  
                  <label className="inputs-label">
                    Viernes
                    <Switch id="swSlave5" className="myswitch" checked={this.state.horarios[4]['estatus']===1} onChange={ (checked,event) => this.changeSwitch2(4,checked)}/>
                  </label>
                  
                  <div className="mytimers" style={{display:this.state.horarios[4]['estatus']===1?'flex':'none'}} >
                    <TimePicker allowClear={false} placeholder="Desde" value={this.state.horarios[4]['momentInicio']} onChange={ (value,svalue) => this.horaChange(value,svalue,4,1) } format={'HH:mm'} />
                    <TimePicker allowClear={false} placeholder="Hasta" value={this.state.horarios[4]['momentTermino']} onChange={ (value,svalue) => this.horaChange(value,svalue,4,2) } format={'HH:mm'} />
                  </div>
              </div>

              <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                  
                  <label className="inputs-label">
                    Sábado
                    <Switch id="swSlave6" className="myswitch" checked={this.state.horarios[5]['estatus']===1} onChange={ (checked,event) => this.changeSwitch2(5,checked)}/>
                  </label>
                  
                  <div className="mytimers"  style={{display:this.state.horarios[5]['estatus']===1?'flex':'none'}} >
                    <TimePicker allowClear={false} placeholder="Desde" value={this.state.horarios[5]['momentInicio']} onChange={ (value,svalue) => this.horaChange(value,svalue,5,1) } format={'HH:mm'} />
                    <TimePicker allowClear={false} placeholder="Hasta" value={this.state.horarios[5]['momentTermino']} onChange={ (value,svalue) => this.horaChange(value,svalue,5,2) } format={'HH:mm'} />
                  </div>
              </div>

              <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                  
                  <label className="inputs-label">
                    Domingo
                    <Switch id="swSlave7" className="myswitch" checked={this.state.horarios[6]['estatus']===1} onChange={ (checked,event) => this.changeSwitch2(6,checked)}/>
                  </label>
                  
                  <div className="mytimers" style={{display:this.state.horarios[6]['estatus']===1?'flex':'none'}} >
                    <TimePicker allowClear={false} placeholder="Desde" value={this.state.horarios[6]['momentInicio']} onChange={ (value,svalue) => this.horaChange(value,svalue,6,1) } format={'HH:mm'} />
                    <TimePicker allowClear={false} placeholder="Hasta" value={this.state.horarios[6]['momentTermino']} onChange={ (value,svalue) => this.horaChange(value,svalue,6,2) } format={'HH:mm'} />
                  </div>
              </div>
            
              </div>
            </Modal>

            <div className="metaControles">
              <h3 className="tituloPanel"> Nueva promoción </h3>
            </div>

            <div className="newProductDiv newProductDivSucursales ">
              <div className="newProductDivTitle">
                <span className="tititle tititle2"> Marca las sucursales en las que esta promoción se encuentra disponible: </span>
              </div>
              <div className="listSucursales milistcheck milistchecknolist">
                {
                  this.state.sucursales2.map((sucursal,indice)=>{
                    return(
                      <div key={'sucursal_'+sucursal.ID_SUC} className="myCheckProd">
                        {/*
                          <input
                            type="checkbox"
                            id={'sucIn_'+sucursal.ID_SUC}
                            checked={sucursal.hay}
                            onChange={(e)=>this.changeCambioEn(e,indice)}
                          />
                          <label htmlFor={'sucIn_'+sucursal.ID_SUC} > {sucursal.NOMBRE} </label>
                        */}
                        
                        <Checkbox
                          id={'sucIn_'+sucursal.ID_SUC}
                          checked={sucursal.hay}
                          onChange={(e)=>this.changeCambioEn(e,indice)}
                          className="checkInFilter"
                        >
                          {sucursal.NOMBRE}
                        </Checkbox>
                      </div>
                    )
                  })
                }
              </div>
            </div>

            <div className="newProductBody">
              
              <div className="newProductDiv newProductDiv2 newProductBodyForm" >
                <form id="formuladatospromo">

                    <div className="inputs-group">
                      <label className="inputs-label lbl-Activa"> Tipo de promoción </label>
                      <Select value={this.state.elegido} onChange={(value)  => this.changeTipoPromocion(value)} optionLabelProp="label" >
                        {
                          this.state.tiposPromo.map(tp => (
                            <Select.Option value={tp.value} key={tp.value} label={tp.label} > {tp.label+tp.render} </Select.Option>
                          ))
                        }
                      </Select>
                    </div>

                    <div className="inputs-group">
                      <label className="inputs-label" htmlFor="titulod" > Título </label>
                      <input type="text" maxLength={50} className="inputs-input" name="titulo" id="titulod" />
                    </div>

                    <div className="inputs-group">
                      <label className="inputs-label" htmlFor="descripcionpd" maxLength={200} > Descripción </label>
                      <Input.TextArea className="inputs-input" name="descripcion" id="descripcionpd" autoSize={{minRows:1, maxRows:10,}} style={{boxShadow:'none'}} />
                      {/*<textarea rows={this.state.rows} className="inputs-input" name="descripcion" id="descripcionpd" onChange={this.changeSizeTextArea} /> */}
                    </div>

                    <div className="inputs-group">
                      <label className="inputs-label lbl-Activa"> Tipo de vigencia </label>
                      <Select value={this.state.status} onChange={(value)=>this.changeStatus(value)} id="tipoA" >
                          <Select.Option value="1"> Definida </Select.Option>
                          <Select.Option value="2"> Indefinida </Select.Option>
                      </Select>
                    </div>
                    <div className="fechaSexo">
                      <div className="inputs-group inputs-group-inline">
                        <label className="inputs-label" htmlFor="pesopd"> Fecha inicio promoción </label>
                        <DatePicker disabled={this.state.status === '2'} format="DD/MM/YYYY" locale='es-MX' name="fecha1" allowClear={false} className="MyDate" value={this.state.fecha1} onChange={(date)=>this.changeState(date,'fecha1')} />
                      </div>

                      <div className="inputs-group inputs-group-inline">
                        <label className="inputs-label" htmlFor="timeprepapd" > Hora inicio promoción </label>
                        <TimePicker disabled={this.state.status === '2'} minuteStep={5} value={this.state.hora1} allowClear={false} format={'HH:mm'} placeholder="" className="myTimer" onChange={(value)=>this.changeState(value,'hora1')}/>
                      </div>
                    </div>

                    <div className="fechaSexo">
                      <div className="inputs-group inputs-group-inline">
                        <label className="inputs-label" htmlFor="timeprepapd" > Fecha término promoción </label>
                        <DatePicker disabled={this.state.status === '2'} format="DD/MM/YYYY" locale='es-MX' name="fecha2" allowClear={false} className="MyDate" value={this.state.fecha2} onChange={(date)=>this.changeState(date,'fecha2')}/>
                      </div>

                      <div className="inputs-group inputs-group-inline">
                        <label className="inputs-label" htmlFor="timeprepapd" > Hora término promoción </label>
                        <TimePicker disabled={this.state.status === '2'} minuteStep={5} value={this.state.hora2} allowClear={false} format={'HH:mm'} placeholder="" className="myTimer" onChange={(value)=>this.changeState(value,'hora2')} />
                      </div>
                    </div>

                  </form>
              </div>

              <div className="newProductDiv newProductBodyImg">
                <label htmlFor="filea" className="labelForImg" title="Elegir imagen" id="forfileaLabel" >
                  <img src={img+'new_camera_icon_prod.png'} id="forfilea" alt="..." />
                </label>
                <input type="file" accept="image/*" onChange={(e)=>this.fileChange(e)} id="filea" />
                
                <Popover title="Opciones"
                  content={
                    <div className="op-Suc">
                    <label htmlFor="filea" > Reemplazar </label>
                    <label onClick={this.clearSrc}> Eliminar </label>
                    </div>
                  }
                  overlayClassName="pop-Suc"
                  placement="bottom"
                >                                    
                <img id="srcPromo" className="fotoPromo" style={{display:'none'}} alt="..." />
                </Popover>

              </div>

              {
                this.state.status==='1'
                ? <React.Fragment/> : <div onClick={()=>this.setState({modalHorarios:true})} ><span className='addProd'>Agregar horarios</span></div>
              }

            </div>

            <div className="contTarjetasProductos">
              <div className="contTarjetasProductosChild">

              {
                this.state.elegido === 2 ?
                <div className="tarjetaAddPromo tarjetaAddPromo1">
                    <Row className="HeadNaranjaTargetaPromo">
                      <Col span={13}> Nombre </Col>
                      <Col span={4} className="head2"> Descuento </Col>
                      <Col span={4} className="head2"> Precio </Col>
                      <Col span={3}>  </Col>
                    </Row>
                    {this.state.detalles.filter(detalle => detalle.numVariantes === 0).map((detalle,indice) => (
                      <Row className="BodyNaranjaTargetaPromo" key={'detalle_'+detalle.ID_PRODUCTO} onClick={()=>this.showModalProductos(-1)} >
                        <Col span={13} className="nomBre">
                          <div className="brebre">
                            {detalle.NOMBRE_PRODUCTO}
                           </div>
                        </Col>
                        <Col span={4} className="descBre">
                          { this.getDesc(this.state.desc,this.state.tipoDesc)}
                        </Col>
                        <Col span={4} className="preCio">
                          <div className="preGris"> ${formatNumber(detalle.PRECIO,2)} </div>
                          <div className="preNan"> ${this.getNewPrecio(detalle.PRECIO)} </div>
                        </Col>
                        <Col span={3} className="imgTrash">
                          <img src={img+'basurero_gris.png'} onClick={(e) =>  this.modalEliminarSucursal(e,detalle.ID_PRODUCTO)} alt="..." title='Eliminar registro' />
                        </Col>
                      </Row>
                    ))}
                </div>:<React.Fragment/>
              }

              {
                this.state.elegido ===3 ?
                <div className="tarjetaAddPromo tarjetaAddPromo2" >
                  <Row>
                    <Col span={12} >
                      <Row className="HeadNaranjaTargetaPromo">
                        <Col span={5}> Compra </Col>
                        <Col span={14}> Nombre </Col>
                        <Col span={5} className="head2"> Precio </Col>
                      </Row>
                    </Col>
                    <Col span={12} >
                      <Row className="HeadGrisTargetaPromo">
                        <Col span={4}> Gratis </Col>
                        <Col span={14}> Nombre </Col>
                        {/*<Col span={4}> Descuento </Col>*/}
                        <Col span={4} className="head2"> Precio </Col>
                        <Col span={2}>  </Col>
                      </Row>
                    </Col>

                  </Row>

                  <Row>
                    <Col span={12}>
                    {
                      this.state.productosTipo3.map((producto,indice)=>(
                        <Row className="BodyNaranjaTargetaPromo" key={'prod_3_'+indice} onClick={()=>this.showModalProductos(indice)} >
                          <Col span={5} className="descBre">
                            {producto.CANTIDAD}
                          </Col>
                          <Col span={14} className="nomBre">
                            <div className="brebre">
                              {producto['NOMBRE']}
                            </div>
                          </Col>
                          <Col span={5} className="preCio2">
                            <div className="prepre">
                              {producto['PRECIO']}
                            </div>
                          </Col>
                        </Row>
                      ))
                    }
                    </Col>
                    <Col span={12}>
                    {
                      this.state.productosSecundariosTipo3.map((producto,indice)=>(
                        <Row className="BodyNaranjaTargetaPromo" key={'prod_3s_'+indice}  onClick={()=>this.showModalProductos(indice)} >
                          <Col span={4} className="descBre">
                            {producto['CANTIDAD'] }
                          </Col>
                          <Col span={14} className="nomBre">
                            <div className="brebre">
                              {producto['NOMBRE']}
                            </div>
                          </Col>
                          {/*<Col span={4} className="descBre" >
                            100%
                      </Col>*/}
                          <Col span={4} className="preCio">

                            <div className="preGris" title={`${formatNumber(producto['PRECIO2'] * producto['CANTIDAD'],2)} MXN`} >
                              ${formatNumber(producto['PRECIO2'] * producto['CANTIDAD'],2)} MXN
                            </div>

                            <div className="preNan"> $0.00 MXN </div>

                          </Col>
                          <Col span={2} className="imgTrash">
                            <img src={img+'basurero_gris.png'} onClick={(e) =>  this.modalEliminarSucursal(e,indice)} alt="..." title='Eliminar registro' />
                          </Col>
                        </Row>
                      ))
                    }
                    </Col>
                  </Row>
                </div>
                :<React.Fragment/>
              }

              {
                this.state.elegido ===4 ?
                <div className="tarjetaAddPromo tarjetaAddPromo2" >
                  <Row>
                    <Col span={10} >
                      <Row className="HeadNaranjaTargetaPromo">
                        <Col span={5}> Compra </Col>
                        <Col span={14}> Nombre </Col>
                        <Col span={5} className="head2"> Precio </Col>
                      </Row>
                    </Col>
                    <Col span={14} >
                      <Row className="HeadGrisTargetaPromo">
                        <Col span={3}> Compra </Col>
                        <Col span={9}> Nombre </Col>
                        <Col span={4}> Descuento </Col>
                        <Col span={5} className="head2"> Precio </Col>
                        <Col span={3} >  </Col>
                      </Row>
                    </Col>

                  </Row>

                  <Row>
                    <Col span={10}>
                    {
                      this.state.productosTipo4.map((producto,indice)=>(
                        <Row className="BodyNaranjaTargetaPromo" key={'prod_3_'+indice} onClick={()=>this.showModalProductos(indice)} >
                          <Col span={5} className="descBre">
                            {producto.CANTIDAD}
                          </Col>
                          <Col span={14} className="nomBre">
                            <div className="brebre">
                              {producto['NOMBRE']}
                            </div>
                          </Col>
                          <Col span={5} className="preCio2">
                            <div className="prepre">
                              {producto['PRECIO']}
                            </div>
                          </Col>
                        </Row>
                      ))
                    }
                    </Col>
                    <Col span={14}>
                    {
                      this.state.productosSecundariosTipo4.map((producto,indice)=>(
                        <Row className="BodyNaranjaTargetaPromo" key={'prod_3s_'+indice} >
                          <Col span={3} className="descBre">
                            {producto['CANTIDAD']}
                          </Col>
                          <Col span={9} className="nomBre">
                            <div className="brebre">
                              {producto['NOMBRE']}
                            </div>
                          </Col>
                          <Col span={4} className="descBre" >
                            {producto.DESCUENTO_MONETARIO === 0 ? producto.DESCUENTO_PORCENTAJE + '%' : '$'+formatNumber(producto.DESCUENTO_MONETARIO,2)+' MXN'}
                          </Col>
                          <Col span={5} className="preCio">
                            <div className="preGris"> ${formatNumber(producto['PRECIO'] * producto['CANTIDAD'],2)} MXN</div>
                            <div className="preNan">{this.getPrecioT4(producto)}</div>
                          </Col>
                          <Col span={3} className="imgTrash">
                            <img src={img+'basurero_gris.png'} onClick={(e) =>  this.modalEliminarSucursal(e,indice)} alt="..." title='Eliminar registro' />
                          </Col>
                        </Row>
                      ))
                    }
                    </Col>
                  </Row>
                </div>
                :<React.Fragment/>
              }
              </div>
            </div>

            <div className="newProductFoot newPromoFoot">
                {
                  (this.state.elegido !== 1 && this.state.sucursales2.filter(s => s.hay).length > 0 ) ?
                  <div className="addProduct">
                    <label onClick={()=>this.showModalProductos(-1)}> Agregar productos y servicios </label>
                  </div>:<div></div>
                }
                <div className="dosBtones">
                  <button className="btnEliminar" onClick={this.cancelar} > Cancelar </button>
                  <button className="btnAceptar" onClick={this.submit}> Guardar </button>
                </div>
            </div>

            <div className="cont-instancias5"></div>

          </div>
        </div>
       </div>
    );
  }
}

export default withRouter(Registrar);