import React from 'react';
import { Modal} from 'antd';

class ModalAlert extends React.Component{
  render(){
    return(
      <Modal centered maskClosable={false} keyboard={false} closable={false} zIndex = {this.props.zIndex?this.props.zIndex:1000}
        okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
        cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',display:this.props.withCancel?'flex':'none'} }}
        visible={this.props.visible}
        onOk={this.props.onOk}
        onCancel={this.props.onCancel}
        title={this.props.title || "Atención"}
        className="miAntModal newVar alertMy flexfoot" >
        <div className="myAlertBody">
        {
          this.props.visible &&
          <span className="txtAlert"> {this.props.text} </span>
        }
        </div>
      </Modal>
    )
  }
};
export default(ModalAlert);