import React from 'react';
import axios from 'axios';
import {   withRouter    } from "react-router-dom";

import { Globalrequest as request } from './../../../request.js';
import {Host as host ,  Img as img,} from './../../../app.json'
import {Icon, Popover , Modal} from 'antd'

import Loading from '../../../modalCargando/loading';

import {userContext,permisosGral} from './../context'

import './sucursal.scss';
class Lista extends React.Component{

  static contextType = userContext

  constructor(props) {
    super(props);
    this.actualizarSucursales = this.actualizarSucursales.bind(this)
    this.listadoSucursales = this.listadoSucursales.bind(this)

    this.duplicar = this.duplicar.bind(this)
    this.eliminar = this.eliminar.bind(this)
    
    this.handlerPopoverOptions = this.handlerPopoverOptions.bind(this)

    this.handleModal = this.handleModal.bind(this)
    this.okModal = this.okModal.bind(this)

    this.state = {
        sucursales : this.props.sucursales,
        datos: this.props.datos,
        loading: false,
        
        modalAction:false,
        txtAction:'',
        id:0,
        opAction:-1,

        modalAlert:false,
        txtAlert:'',
    }
  }
  componentDidMount(){
    document.title='Mis sucursales'
    sessionStorage.removeItem('idSucu')
    let id = sessionStorage.getItem('id')
    if(id!==null && id!==undefined ){
      this.listadoSucursales();
    }
  }
  
  actualizarSucursales(){
    let data = {mail:sessionStorage.getItem('mail'),op:-1,detalle:1}


    let userSubType = localStorage.getItem('userSubType')||-1
    let typeSubUser = localStorage.getItem('typeSubUser')||-1
    let subusuario = false
    
    if(userSubType === 'nosubuser' && Number(typeSubUser)===3){
      data['userSubType'] = localStorage.getItem('submail')
      subusuario = true
    }


    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/datosPorEmpresa`,data,request).then(rt=>{
      if( rt.data === 0 ){
        this.props.redirectTo2('/home/sucursales/nueva') 
      }
      else{

        rt.data['datos']['LABEL'] = rt.data['datos']['CORREO_REGISTRO']
        rt.data['datos']['ALIAS'] = btoa(btoa(rt.data['datos']['ALIAS']))

        if(subusuario){
          localStorage.setItem('userSubType','nosubuser')
          localStorage.setItem('typeSubUser','3')

          localStorage.setItem('subid',rt.data['extra']['code'])//subid encriptado
          localStorage.setItem('submail',rt.data['extra']['index'])//submail encriptado
          rt.data['datos']['LABEL'] = rt.data['extra']['LABEL']
          rt.data['datos']['ALIAS'] = btoa(btoa(rt.data['extra']['alias']))
        }

        sessionStorage.setItem('subid',rt.data['extra']['code'])
        sessionStorage.setItem('submail',rt.data['extra']['index'])
        
        sessionStorage.setItem('metadata',JSON.stringify(rt.data))
        this.props.setSucursales(rt.data['sucursales'])
        this.setState({ sucursales : rt.data['sucursales'] ,datos : rt.data['datos'], loading:false, })
        this.props.editState('datos',rt.data['datos'])
        this.props.editState('sucursales',rt.data['sucursales'])
        this.props.editState('categorias',rt.data['categorias'])
        this.props.editState('tags',rt.data['tags'])
        this.props.editState('notificaciones',rt.data['notificaciones'])
      }
    })
  }

  listadoSucursales(){
    let data = {idE:sessionStorage.getItem('id')}
    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/listadoSucursales`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let metadata = JSON.parse(sessionStorage.getItem('metadata'))
        metadata['sucursales']=rt.data['result']
        sessionStorage.setItem('metadata',JSON.stringify(metadata))
        this.props.editState('datos',metadata['datos'])
        this.props.editState('sucursales',metadata['sucursales'])
        this.props.editState('categorias',metadata['categorias'])
        this.props.editState('tags',metadata['tags'])
        this.props.editState('notificaciones',metadata['notificaciones'])
        this.setState({ sucursales : metadata['sucursales'] ,datos : metadata['datos'], loading:false, })
      }
    })
  }

  duplicar(id){
    let {valid,mood} = this.props
    if(!valid){
      if(Number(mood)===1){
        this.setState({modal:false,modalAlert:true,txtAlert:'No puedes acceder a esta opción porque tu cuenta está con funciones limitadas.'})
      }else{
        this.setState({modal:false,modalAlert:true,txtAlert:'No puedes acceder a esta opción porque debes tener una suscripción activa.'})
      }
      return
    }

    let {sucursales} = this.state
    let sucursal = sucursales.filter(s => s.ID === id)
    let nombreBase = sucursal[0]['NOMBRE']
    nombreBase = nombreBase.split('-copia-')[0]
    let copias = sucursales.filter(s => s.NOMBRE.includes(nombreBase+'-copia-'))
    let count = copias.length+1
    
    let data = {id,count,nombreBase}
    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/duplicar`,data,request).then(rt=>{
      this.setState({loading:false})
      let txtAlert
      let rpa = Number(rt.data)
      if(rpa > 0){
        this.actualizarSucursales()
        txtAlert = 'La sucursal ha sido duplicada correctamente'
      }
      else{
        txtAlert = 'Parece que ocurrió un error, por favor intentalo más tarde. [Código de error: SV-ER: #12-01]'
      }
      this.setState({modalAlert:true,txtAlert, modalAction:false,id:-1,opAction:-1,txtAction:-1})
    })
  }

  eliminar(id){
    let data = {id : id}
    let {valid,mood} = this.props
    if(!valid){
      if(Number(mood)===1){
        this.setState({modal:false,modalAlert:true,txtAlert:'No puedes acceder a esta opción porque tu cuenta está con funciones limitadas.'})
      }else{
        this.setState({modal:false,modalAlert:true,txtAlert:'No puedes acceder a esta opción porque debes tener una suscripción activa.'})
      }
      return
    }

    this.setState({loading:true})
    axios.post(`${host}empresas/empresas/eliminarSucursal`,data,request).then(rt=>{
      this.setState({loading:false})
      let txtAlert
      let rpa = Number(rt.data)
      if(rpa === 1){
        txtAlert = 'La sucursal ha sido eliminada correctamente'
        this.actualizarSucursales()
      }
      else{
        txtAlert = 'Parece que ocurrió un error, por favor intentento más tarde. [Código de error: SV-ER: #12-02]'
      }
      this.setState({modalAlert:true,txtAlert,modalAction:false,id:-1,opAction:-1,txtAction:-1})
    })
  }

  handlerPopoverOptions(op,sucursal,indice){
    let {validCurrentUser,validCurrentSucursal} = this.props
    let labels = ['','EDITAR','CREAR','ELIMINAR']
    if(this.context['type']===2){
      
      if(op===2 || (op===1 && indice===-1) ){
        let uservalid = validCurrentUser(this.context,'sucursales','object')
        if(!uservalid.hasOwnProperty('CREAR') || Number(uservalid['CREAR'])!==1 ){
          let txtAlert = 'El perfil actual no tiene permisos para crear sucursales'
          this.setState({modal:false,modalAlert:true,txtAlert})
          return
        }
      }else{
        let sucursalValid = validCurrentSucursal(this.context,sucursal['CODE'])
        let permisos = sucursalValid['SELECTED'] ? sucursalValid['DATA'].filter(p => p['TIPO'] === permisosGral['sucursales']) : []
        if(permisos.length>0)
          permisos = permisos[0]
        else
          permisos = {CREAR: "0",EDITAR: "0",ELIMINAR: "0",TIPO: 2}
      
        if(Number(permisos[labels[op]]) !== 1){
          let txtAlert = 'El perfil actual no tiene permisos para '+ (labels[op].toLowerCase()) +' sucursales'
          this.setState({modal:false,modalAlert:true,txtAlert})
          return
        }
      }
    }

    switch(op){
      case 1:
        if(indice!==-1)
          this.props.redirectTo2('/home/sucursales/editar/'+indice);//editando
        else
          this.props.redirectTo2('/home/sucursales/nueva')//creando
        break;
      case 2: this.handleModal(true,sucursal['ID'],1); break;//duplicando [creando]
      case 3: this.handleModal(true,sucursal['ID'],2); break;//eliminando
      default :break;
    }
  }

  handleModal(modalAction,id,opAction){ //opAction 1->Duplicar 2->Eliminar
    let {valid,mood} = this.props

    if(modalAction){
      if(!valid){
        if(Number(mood)===1){
          this.setState({modal:false,modalAlert:true,txtAlert:'No puedes acceder a esta opción porque tu cuenta está con funciones limitadas.'})
        }else{
          this.setState({modal:false,modalAlert:true,txtAlert:'No puedes acceder a esta opción porque debes tener una suscripción activa.'})
        }
        return
      }
    }
    let msj= ['','¿Está seguro que desea duplicar esta sucursal?','Si elimina esta sucursal se cancelarán todos los pedidos pendientes y las citas confirmadas que tenga. Esta acción no se podrá revertir.']
    this.setState({modalAction,id,opAction,txtAction:msj[opAction]})
  }

  okModal(){
    let{id,opAction} = this.state
    if(opAction===1)
      this.duplicar(id)
    else if(opAction===2)
      this.eliminar(id)
  }

  prove(id){
    sessionStorage.setItem('idSucu',id)
    this.props.redirectTo2('/home/sucursales/coberturas')
  }

  subClick(e,id){
    e.stopPropagation()
    let abuelo = document.getElementById('sucursal_id_'+id+'_1').parentElement.parentElement
    if(abuelo.classList.contains('ant-popover-hidden'))
      document.getElementById('sucursal_id_'+id).click()
  }

  formatTelefono(str){
    str = str.slice(0,10)
    return '('+str.slice(0,3)+') '+str.slice(3,6) + ' '+str.slice(6)
  }

  render(){
    return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3">

            <Modal centered title="Confirmar" maskClosable={false} keyboard = {false} 
              okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: {width:'120px'} }}
              cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: {width:'120px'} }}
              visible={this.state.modalAction}
              closable={false}
              onOk={() => this.okModal()}
              onCancel={()=>this.handleModal(false,-1,0)}
              className="miAntModal newVar alertMy" >
                <div className="myAlertBody">
                  <span className="txtAlert"> {this.state.txtAction} </span>
                </div>
            </Modal>

            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: {width:'120px'} }}
                cancelButtonProps={{ style: {display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalAlert   ?
                        <span className="txtAlert"> {this.state.txtAlert} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            <Loading loading={this.state.loading} zIndex={1001} />

            <h3 className="tituloPanel" > Catálogo de sucursales </h3>
            <div className="cont-instancias-Suc">
              {
                this.state.sucursales.map(
                  (sucursal,indice) => {
                    return(
                      <div key={'suc_'+sucursal.ID}  id={'suc_'+sucursal.ID} className="tarjetaSucursal" onClick={(e) => this.subClick(e,sucursal.ID)} >
                        <div className="metaInfo"> <img className="suci" alt='...' src={img+'sucursales.png'}  /> <span className="spnNombre" > {sucursal.NOMBRE} </span> </div>
                        <div className="metaInfo"> <img alt='...' src={img+'telefono.png'}  /> <span> {this.formatTelefono(sucursal.TELEFONO)} </span> </div>
                        <div className="metaInfo"> <img alt='...' src={img+'whats.png'}  /> <span> {this.formatTelefono(sucursal.WHATSAPP)} </span> </div>
                        <div className="metaInfo col">
                        <img alt='...' src={img+'ubicacion.png'}  />
                          <span className="direSuc"
                             title={ sucursal.CALLE + ' ' +  sucursal.NUMEXTERIOR + ' '  + sucursal.NUMINTERIOIR + ' ' + sucursal.COLONIA + ' ' + sucursal.CODPOSTAL + ' ' + sucursal.CIUDAD + ' ' + sucursal.ESTADO + ' ' + sucursal.PAIS }
                            >
                            { sucursal.CALLE + ' ' +  sucursal.NUMEXTERIOR + ' '  + sucursal.NUMINTERIOIR + ' ' + sucursal.COLONIA + ' ' + sucursal.CODPOSTAL + ' ' + sucursal.CIUDAD + ' ' + sucursal.ESTADO + ' ' + sucursal.PAIS }
                          </span>
                        </div>
                        <span className="options">
                          <Popover
                            forceRender
                            id={'sucursal_id_'+sucursal.ID+'_1'}
                            title="Opciones"
                            trigger={['click','hover']}
                              content={
                                <div className="op-Suc">

                                  <label onClick={()=>this.handlerPopoverOptions(1,sucursal,indice)} > Actualizar datos </label>

                                  {Number(sucursal.ENTREGA_DOMICILIO)===1&&<label onClick={ ()=>this.prove(sucursal.ID) }> Zonas de cobertura </label>}

                                  { this.state.sucursales.length<3&& <label onClick={()=>this.handlerPopoverOptions(2,sucursal,indice)} > Duplicar </label> }

                                  <label onClick={()=>this.handlerPopoverOptions(3,sucursal,indice)} > Eliminar </label>

                                </div>
                              }
                              overlayClassName="pop-Suc"
                              placement="right"
                            >
                            <Icon type="setting"  id={'sucursal_id_'+sucursal.ID}  style={{fontSize:'20px'}}  onClick={e=>e.stopPropagation()}   />
                          </Popover>
                        </span>
                      </div>
                    )
                  }
                )
              }
              {
                this.state.sucursales.length<3&&
                <div onClick={()=>this.handlerPopoverOptions(1,{},-1)} className="tarjetaSucursal new">
                  <img className="srcSucursalNew" src={img+'mas_aniadir.png'} alt='...' />
                  <label className="labelNew"> Agregar sucursal </label>
                </div>
              }

            </div>

            <div className="cont-instancias5"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Lista);