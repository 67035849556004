import React from 'react';
import {Img as img} from './../app.json'

class MiDiv extends React.Component{
  componentDidMount(){
    let div = document.getElementById(this.props.myid)
    if(div !== null && div !== undefined && this.props.renderable){
      div.innerHTML = this.props.content.split('[/*/r/n*/]').join('<br/>')
    }
  }
  render(){
    return(
      <div className={this.props.class} id={this.props.myid} title={this.props.content.split('[/*/r/n*/]').join('\n')}>
      {
        this.props.renderable?
        <React.Fragment/>:
        this.props.content.split('[/*/r/n*/]').join('\n')
      }
      </div>
    )
  }
}

const Marka = props => {
  return(
    <img src={`${img}ic_direccion.png`} alt="..." className="markerg" style={{cursor: 'pointer'}} />
  )
}

export {MiDiv,Marka};