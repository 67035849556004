import React from 'react';
import {ImgGral as img2,ImgPanelAdmin as imgAdmin ,Host } from './../../app.json';
import { Modal,Input,DatePicker,Spin,Icon,Calendar} from 'antd';
import { Globalrequest as request } from './../../request.js';
import axios from 'axios';
import moment from 'moment'
import Loading from "./../../modalCargando/loading";
import ModalAlert from './../../modalCargando/modalAlert';

import {capitalizeFirs} from "./../../utilities/generalfunctions";

class ControlCitas extends React.Component{
  constructor(props) {
    super(props)
    this.actualizarCitas = this.actualizarCitas.bind(this)
    this.actualizarHorarios = this.actualizarHorarios.bind(this)
    this.changeDate = this.changeDate.bind(this)
    this.clickOnCita = this.clickOnCita.bind(this)

    this.agregarCita = this.agregarCita.bind(this)
    this.eliminarCita = this.eliminarCita.bind(this)

    this.setReagendarFecha = this.setReagendarFecha.bind(this)
    this.setReagendarHora = this.setReagendarHora.bind(this)

    this.clear = this.clear.bind(this)


    this.clickHoverable = this.clickHoverable.bind(this)

    /**/

    //this.onSelect = this.onSelect.bind(this)
    this.onPanelChange = this.onPanelChange.bind(this)
    //this.cellrender = this.cellrender.bind(this)


    this.cancelReagendarFecha = this.cancelReagendarFecha.bind(this)
    
    this.state={
      fecha:moment(),
      invalidDate:false,
      formatedDate:moment().format('dddd, LL') ,
      toCancel:moment(),
      /**/
      justHora:false,
      /**/
      mes:{nombre:'',citas:[]},
      idConfig:'',
      duracionCita:'',
      horarios:[],
      listaCitas:[],
      listaCitas2:[],
      /**/
      mutableDataCita:{
        inicio:'',
        fin:'',
        idHorarioAtencionCita:'',
      },
      currentCita:{cita:{},},
      currentCitaCopy:{cita:{},},
      citaInterface:{ESTATUS:'1',OBSERVACIONES_CLIENTES:'',OBSERVACIONES_EMPRESA:'',MOTIVOS_CLIENTES:'',MOTIVOS_EMPRESA:'',ID_USUARIO_EMPRESA:-1,FK_ID:-1,ID_PEDIDO:-1,ID:-1,CALIFICACION_CLIENTE:0,MINE:false,NOMBRE_CLIENTE:'',FECHA_CITA_INICIO:'',FECHA_CITA_TERMINO:''},
      addingCita:false,
      estatus:0,
      finalizada:false,
      cancelanding:false,
      /**/
      fechaReagendar:moment(),
      //fechaReagendarSelected:moment(),
      /**/
      loading:false,
      txtAlert:'',
      modalAlert:false,
      /**/
      reagending:false,
      reagending2:false,
    }

  }

  componentDidMount(){
    let {makeRef} = this.props
    makeRef(this)
  }

  actualizarCitas(cambieAlReagendar=false){
    let {fecha,mes,idConfig,horarios,duracionCita,formatedDate} = this.state
    let {config,withUser} = this.props
    let fecha2 = moment(fecha)
    duracionCita = moment.duration(config['DURACION_CITA']).asMinutes()
    this.setState({cargandoCitas:true,duracionCita})
    
    let listaCitas =[]
    if(mes['nombre']===fecha2.format('MMMMYYYY')){
      listaCitas = this.actualizarHorarios(fecha2)
      this.setState({cargandoCitas:false,listaCitas})
      return
    }

    mes['nombre']=fecha2.format('MMMMYYYY')
    let conhorarios = (config['ID']===idConfig) ? -1 : 1;      
    let data = {
      idS:config['ID_SUCURSAL'],
      fechaInicio:fecha2.startOf('month').format('YYYY-MM-DD') ,
      fechaFin:fecha2.endOf('month').format('YYYY-MM-DD'),
      horario:conhorarios,
      idC:config['ID'],
      idB:withUser?sessionStorage.getItem('id'):-1,
    }

    if(cambieAlReagendar)
      this.setState({loading:true})

    axios.post(`${Host}citas/citas/listaCitasEmpresa`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        mes['citas'] = rt.data['citas']
        horarios = conhorarios>0?rt.data['horarios']:horarios
        idConfig=config['ID']
        this.setState({idConfig, mes,horarios})
        listaCitas = this.actualizarHorarios(fecha)
        this.setState({cargandoCitas:false,listaCitas})
        
        if(cambieAlReagendar){
        	this.setState({loading:false})
          this.setReagendarFecha(false)
        }

      }
      else{}
    })
  }

  actualizarHorarios(fecha,isForCinepolis=false){
    let {mes,horarios,citaInterface} = this.state
    
    let {config} = this.props
    let fechaElegida = moment(fecha)
    let fechaHoy = moment()
    let dia = fechaElegida.day()
    dia = dia===0?7:dia
    let horariosHoy = horarios.filter(h => Number(h['DIA']) === dia && Number(h['ESTATUS'])===1)
    let listaCitas = []
    let idHorarioAtencionCita = -1
    if(horariosHoy.length>0){
      let horarioAtencion = horariosHoy.filter(h=>Number(h['TIPO_HORA'])===1)//sacamos el horario de atencion 09:00-05:00
      horarioAtencion = horarioAtencion[0]
      idHorarioAtencionCita = horarioAtencion['ID']
      let horariosNoAtencion = horariosHoy.filter(h=>Number(h['TIPO_HORA'])===2)
      let tolerancia = 0
      horariosNoAtencion = horariosNoAtencion.map(h => ({inicio: moment(h['HORA_INICIO'],'HH:mm:ss').add(tolerancia,'minutes') , fin: moment(h['HORA_TERMINO'],'HH:mm:ss')}) )

      let horarioAtencionAbre = moment(horarioAtencion['HORA_INICIO'],'HH:mm:ss')
      let horarioAtencionCierra = moment(horarioAtencion['HORA_TERMINO'],'HH:mm:ss')

      let intervalo = moment.duration(config['INTERVALO_ENTRE_CITAS']).asMinutes()
      let duracionCita = moment.duration(config['DURACION_CITA']).asMinutes()

      let finalizaSiguiente = moment(horarioAtencionAbre) //:00:00
      finalizaSiguiente.add(duracionCita+intervalo,'minutes') ////17:25:00
       
      let citasHoy = mes['citas'].filter(cita => fechaElegida.isSame(cita['FECHA_CITA_INICIO'],'day')
                                                 //&& Number(cita['ESTATUS']) !== 6
                                                 )
      let citaParaHorario

      let esHoy = fechaElegida.isSame(fechaHoy,'day')

      while(
          horarioAtencionAbre.isBefore(horarioAtencionCierra)
          && finalizaSiguiente.isSameOrBefore(horarioAtencionCierra)
      ){

        let texto='',clase='',horaEmpiezaString='',horaFinString='',citaActual={}//clase = clasebarrita y calse2 = todo el contenedor del texto

        texto='';clase='';
        citaActual = Object.create(citaInterface)

        let horarioAtencionAbreAux = moment(horarioAtencionAbre)
        horarioAtencionAbreAux.add(duracionCita,'minutes')

        horaEmpiezaString = horarioAtencionAbre.format('HH:mm')
        horaFinString = horarioAtencionAbreAux.format('HH:mm')
        
        let invalida = horariosNoAtencion.some(h => horarioAtencionAbreAux.isBetween(h['inicio'],h['fin']) ||  horarioAtencionAbre.isBetween(h['inicio'],h['fin']) )
        if(invalida){//hora de descanso
          texto = 'No disponible para citas'
          clase = 'nodisponible'
        }else{
          citaParaHorario = citasHoy.filter(cita => cita['FECHA_CITA_INICIO'].includes(horaEmpiezaString) && cita['FECHA_CITA_TERMINO'].includes(horaFinString) )
          if(citaParaHorario.length>0){//no hay cita a esa hora
            citaParaHorario = citaParaHorario[0]
            if(Number(citaParaHorario['ESTATUS'])===6){
              horarioAtencionAbre.add(duracionCita+intervalo,'minutes')
              finalizaSiguiente = moment(horarioAtencionAbre)
              finalizaSiguiente.add(duracionCita+intervalo,'minutes')
              
              continue
            }
            
            
            const textoCliente = citaParaHorario['OBSERVACIONES_CLIENTES'].split('~Ç~')
            citaParaHorario['OBSERVACIONES_CLIENTES'] = textoCliente.length>0 ? textoCliente[0].trim() : ''
            citaParaHorario['MOTIVOS_CLIENTES'] = textoCliente.length>1 ? textoCliente[1].trim() : ''
            
            const textoEmpresas = citaParaHorario['OBSERVACIONES_EMPRESA'].split('~Ç~')
            citaParaHorario['OBSERVACIONES_EMPRESA'] = textoEmpresas.length>0?textoEmpresas[0].trim():''
            citaParaHorario['MOTIVOS_EMPRESA'] = textoEmpresas.length>1?textoEmpresas[1].trim():''

            Object.assign(citaActual, citaParaHorario)
            citaParaHorario['ESTATUS'] = Number(citaParaHorario['ESTATUS'])
            if(citaParaHorario['ESTATUS']===6){
              texto = ''
              clase = 'cancelada'
            }else{
              clase = citaParaHorario['ESTATUS'] === 1 ? 'pendiente' : citaParaHorario['ESTATUS'] === 2 ? 'confirmada' : citaParaHorario['ESTATUS'] === 3 ? 'noasistio' : citaParaHorario['ESTATUS'] === 4 ? 'asistio' : citaParaHorario['ESTATUS'] === 5 ? 'reagendada' : 'cancelada'
              texto = citaParaHorario['MINE'] ? citaParaHorario['NOMBRE_CLIENTE'] : 'Horario ocupado';
            }
          }
          else{//no hay cita a esa hora
             texto = ''
             clase = ''
          }
        }

        if(esHoy){
        	if(moment(horaEmpiezaString,'HH:mm').isSameOrAfter(moment())){
            if(isForCinepolis)
              clase = clase===''?'confirmada':(clase==='confirmada'||clase==='pendiente'||clase==='asistio'||clase==='noasistio'||clase==='nodisponible')?'cancelada':'confirmada'

        		listaCitas.push({inicio:horaEmpiezaString,fin:horaFinString,texto:texto,clase:clase,descanso:invalida,cita:citaActual,idHorarioAtencionCita:idHorarioAtencionCita})
          }
        }else{
          if(isForCinepolis)
            clase = clase===''?'confirmada':(clase==='confirmada'||clase==='pendiente'||clase==='asistio'||clase==='noasistio'||clase==='nodisponible')?'cancelada':'confirmada'
          
        	listaCitas.push({inicio:horaEmpiezaString,fin:horaFinString,texto:texto,clase:clase,descanso:invalida,cita:citaActual,idHorarioAtencionCita:idHorarioAtencionCita})
        }

        horarioAtencionAbre.add(duracionCita+intervalo,'minutes')

        finalizaSiguiente = moment(horarioAtencionAbre)
        finalizaSiguiente.add(duracionCita+intervalo,'minutes')
      }
    }
    return listaCitas
  }

  clickOnCita(cita){
  	let {currentCita,estatus,finalizada,fecha,toCancel,mutableDataCita,formatedDate} = this.state
  	toCancel = moment(fecha)
    mutableDataCita={ inicio:cita['inicio'], fin:cita['inicio'], idHorarioAtencionCita:cita['idHorarioAtencionCita'], }
    if(!cita['cita'].hasOwnProperty('ID')){
    	currentCita = {}
      Object.assign(currentCita,cita)
      estatus  = Number(cita['cita']['ESTATUS'])
      this.setState({currentCita,addingCita:true,editanding:false,estatus,mutableDataCita,toCancel})
    }else{
    	if(!cita['cita']['MINE'])return
    	estatus  = Number(cita['cita']['ESTATUS'])    	
      finalizada = (estatus===3||estatus===4||estatus===6)
  		let {currentCitaCopy} = this.state
  		currentCitaCopy = {}
  		currentCita = {}
  		Object.assign(currentCita,cita)
  		Object.assign(currentCitaCopy,cita)
      this.setState({currentCita,currentCitaCopy,addingCita:true,editanding:true,estatus,finalizada,mutableDataCita,toCancel})
    }
  }

  changeDate(fecha,circle=false){
  	let {invalidDate,formatedDate,fechaReagendar} = this.state
  	let hoy = moment()
  	invalidDate = fecha.isBefore(hoy,'day')
  	formatedDate = fecha.format('dddd, LL');
  	fechaReagendar = moment(fecha)
    this.setState({fecha,invalidDate,formatedDate,fechaReagendar})

    if(!invalidDate)setTimeout(()=>this.actualizarCitas(circle),100)
  }

  agregarCita(op){
    let {currentCita,currentCitaCopy,fecha,fechaReagendar,citaInterface,formatedDate,estatus,mutableDataCita,toCancel} = this.state
    let {config} = this.props
    let motivo = currentCita['cita']['MOTIVOS_CLIENTES'] ? currentCita['cita']['MOTIVOS_CLIENTES'] : ''   
    
    if(op===3){
      motivo = document.getElementById('motivoCancel').value.trim()
      estatus=6
      if(motivo===''){
        this.setState({txtAlert:'El motivo de cancelación es obligatorio',modalAlert:true})
        return
      }
    }

    let indicacion = document.getElementById('indicacion').value.trim()
    let date = toCancel.format('YYYY-MM-DD') 

    let inicio = currentCita['inicio'],
        fin = currentCita['fin'],
        idHorarioAtencionCita = currentCita['idHorarioAtencionCita']

    if(op===2){
    	let oldFecha = moment(currentCitaCopy['cita']['FECHA_CITA_INICIO'],'YYYY-MM-DD HH:mm:ss')
      date = fechaReagendar.format('YYYY-MM-DD')
      
      inicio = mutableDataCita['inicio']
      fin = mutableDataCita['fin']
      idHorarioAtencionCita = mutableDataCita['idHorarioAtencionCita']

      if(fechaReagendar.isSame(oldFecha,'day')){//es la misma fecha, pero puede ser distinta hora
    		if( (currentCita['inicio']!==inicio && currentCita['fin'] !== fin) || indicacion !== currentCitaCopy['cita']['OBSERVACIONES_CLIENTES'].trim() ){
    			//alert('si cambio')
    		}else{
    			this.setState({editanding:false,addingCita:false})
    			return
    		}
    	}else{
    		//alert('si cambio')
    	}
    }
    
    formatedDate = fecha.format('dddd, LL');
    let citaToAdd = {
      idHorarioAtencionCita:idHorarioAtencionCita,
      idCliente:sessionStorage.getItem('id'),
      fkId:currentCita['cita']['FK_ID'],
      idUsuarioEmpresa:currentCita['cita']['ID_USUARIO_EMPRESA'],
      idPedido:currentCita['cita']['ID_PEDIDO'],
      fechaInicio: `${date} ${inicio}`,
      fechaTermino:`${date} ${fin}`,
      observacionesCliente:indicacion+'~Ç~'+motivo,
      observacionesEmpresa:currentCita['cita']['OBSERVACIONES_EMPRESA']+'~Ç~'+currentCita['cita']['MOTIVOS_EMPRESA'],
      calificacion:currentCita['cita']['CALIFICACION_CLIENTE'],
      estatus:estatus,
      id:currentCita['cita']['ID'],
      op:-1,
      idE:config['ID_EMPRESA'],
      esCliente:1,
    }
    document.getElementById('indicacion').value = ''
    this.setState({loading:true})
    axios.post(`${Host}citas/citas/agregarEditarCitaCliente`,citaToAdd,request).then(rt=>{
    	if(rt.data['status'] === 'ok'){
    		if(Number(rt.data['data'])===1){
    			let {mes} = this.state
    			let newCitacita = rt.data['EXE_CODE'][0]
    			newCitacita['MINE'] = true
    			if(op === 2 || op === 3 ){//editando
    				mes['citas'] = mes['citas'].filter(c => c['ID'] !== citaToAdd['id'])
    			}
  				mes['citas'].push(newCitacita)
  				currentCita = {cita:Object.create(citaInterface)}
  				currentCitaCopy = {cita:Object.create(citaInterface)}
          estatus = 0
    			this.setState({loading:false,mes,currentCita,cargandoCitas:true,addingCita:false,editanding:false,currentCitaCopy,formatedDate,estatus,cancelanding:false,})
          
          setTimeout(()=>{
            let listaCitas =  this.actualizarHorarios(fecha)
            this.setState({cargandoCitas:false,listaCitas,listaCitas2:[]})
          },100)

    		}else{ this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde',modalAlert:true,formatedDate,estatus,cancelanding:false,})}
    	}
    	else{ this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde',modalAlert:true,formatedDate,estatus,cancelanding:false,}) }
    })
  }

  eliminarCita(){
  	let {currentCita,mes,citaInterface} = this.state
  	this.setState({loading:true,confirmEliminar:false})
  	let data = {idCita:currentCita['cita']['ID']}
  	axios.post(`${Host}citas/citas/eliminarCita`,data,request).then(rt=>{
  		if(rt.data['status']==='ok'){
  			let rta = Number(rt.data['result'])
  			if(rta===1){
  				currentCita = {cita:Object.create(citaInterface)}
  				mes['citas'] = mes['citas'].filter(cita => cita['ID'] !== data['idCita'])
  				this.setState({txtAlert:'Cita eliminada correctamente',modalAlert:true,mes,addingCita:false,currentCita,loading:false})
  				this.actualizarCitas()
  			} else{ this.setState({txtAlert:'Parece que ocurrió un error, por favor vuelve a intentalo',modalAlert:true,loading:false}) }
  		}else{ this.setState({txtAlert:'Parece que ocurrió un error, por favor vuelve a intentalo',modalAlert:true,loading:false}) }
  	})
  }

  setReagendarFecha(){
  	let {fechaReagendar,listaCitas2,mes} = this.state
  	
  	let hoy = moment()
    if(fechaReagendar.isBefore(hoy,'day')){
      this.setState({modalAlert:true,txtAlert:'La fecha seleccionada no es válida'})
      return
    }

    if(fechaReagendar.format('MMMMYYYY')!==mes['nombre']){
      this.changeDate(fechaReagendar,true)
      return
    }

  	listaCitas2 = this.actualizarHorarios(fechaReagendar,true)
  	this.setState({reagending:false,reagending2:true,listaCitas2})
  }

  setReagendarHora(citaClick){
  	if(citaClick['descanso']){
  		this.setState({modalAlert:true,txtAlert:'El horario seleccionado no está disponible'})
  		return
  	}
  	if(citaClick['cita'].hasOwnProperty('ID')){
  		this.setState({modalAlert:true,txtAlert:'El horario seleccionado no está disponible'})
  		return
    }
    let {fechaReagendar,mutableDataCita} = this.state
    let hoy = moment()
    if(fechaReagendar.isSame(hoy,'day')){
    	let nuevaHora = moment(citaClick['inicio'],'HH:mm')
    	let viejaHora = moment(fechaReagendar)
    	if(nuevaHora.isBefore(viejaHora)){
    		this.setState({modalAlert:true,txtAlert:'El horario seleccionado no está disponible'})
    		return
    	}
    }
    
    mutableDataCita['inicio'] = citaClick['inicio']
    mutableDataCita['fin'] = citaClick['fin']
    mutableDataCita['idHorarioAtencionCita'] = citaClick['idHorarioAtencionCita']

    this.setState({mutableDataCita,reagending2:false,})
  }

  switchEstatus(estatus){
  	estatus = Number(estatus)
    if(estatus===1)return "Pendiente"
    if(estatus===2)return "Confirmada"
    if(estatus===3)return "No asistió"
    if(estatus===4)return "Asistió"
    if(estatus===5)return "Reagendada"
    if(estatus===6)return "Cancelada"
    return estatus
  }

  clear(where){
  	this.setState({
  		fecha:moment(),
      invalidDate:false,
      formatedDate:moment().format('dddd, LL') ,
      mes:{nombre:'',citas:[]},
      idConfig:'',
      duracionCita:'',
      horarios:[],
      listaCitas:[],
      listaCitas2:[],
      currentCita:{cita:{},},
      currentCitaCopy:{cita:{},},
      fechaReagendar:moment(),
  	})
  }

  /*onSelect(fechaReagendarSelected){ this.setState({fechaReagendarSelected,fechaReagendar:moment(fechaReagendarSelected)}) }

  cellrender(valueCell){
    let {fechaReagendar} = this.state
    if(valueCell.isSame(fechaReagendar,'day'))
      return <label className="ant-fullcalendar-date ant-fullcalendar-date-select">{valueCell.format('DD')}</label>
    else 
      return <label className="ant-fullcalendar-date" >{valueCell.format('DD')}</label>
  }*/

  onPanelChange(fechaReagendar){
  	let{formatedDate} = this.state
  	formatedDate = fechaReagendar.format('dddd, LL');
  	this.setState({fechaReagendar,formatedDate})  	
  }

  cancelReagendarFecha(){
    let {toCancel,fecha} = this.state
    this.setState({reagending:false,fechaReagendar:toCancel, formatedDate:toCancel.format('dddd, LL')})
    
    if(!toCancel.isSame(fecha,'day')){
      this.changeDate(toCancel)
    }
  }


  clickHoverable(op){
    let {fechaReagendar,listaCitas2,justHora} = this.state
    if(op===1){ this.setState({reagending:true}) }
    if(op===2){
      let ahora = moment()
      if(fechaReagendar.isBefore(ahora,'day')){
        this.setState({modalAlert:true,txtAlert:'No hay horarios de atención disponibles para la fecha seleccionada.'})
        return
      }
      listaCitas2 = this.actualizarHorarios(fechaReagendar,true,false)
      justHora = true
      if(listaCitas2.length===0){
        this.setState({modalAlert:true,txtAlert:'No hay citas disponibles para la fecha actual.'})
        return
      }
      this.setState({reagending2:true,listaCitas2,justHora})
    }    
  }

  render(){
    return(
      <Modal centered title="" closable={false} header={null} footer={null}
        visible={this.props.visible}
        className="modalBaseBuBooker"
        onCancel={()=>this.props.handleSelf('visibleCitas',false)}
        style={{marginTop:'20px'}}
        zIndex={3}
      >

      <ModalAlert text={'Una vez eliminada, esta cita no podrá recuperarse.'} visible={this.state.confirmEliminar} withCancel onOk={this.eliminarCita} onCancel={()=>this.setState({confirmEliminar:false})}  />

      <ModalAlert text={this.state.txtAlert} visible={this.state.modalAlert} onOk={()=>this.setState({txtAlert:'',modalAlert:false,})  } />

    	{/* detalle calendar */}
      <Modal Modal centered title="" closable={false} zIndex={5} header={null} footer={null}
        visible={this.state.reagending} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}}
        onCancel={()=>document.getElementById('reagendingButton').click()}>
        <div className="reagenda">

        	<div className="myheadCalendar flexcenter Fspaceb">
            <Icon type="left" className="control left" onClick={()=>this.setState({fechaReagendar: this.state.fechaReagendar.add(-1,'months') })} />
            <span>{this.state.fechaReagendar.format('DD-MMMM-YYYY')}</span>
            <Icon type="right" className="control right" onClick={()=>this.setState({fechaReagendar: this.state.fechaReagendar.add(1,'months') })} />
          </div>

        	<Calendar className="mycalendar" value={this.state.fechaReagendar}
        	//dateFullCellRender={this.cellrender}
        	//onSelect={this.onSelect}
        	//onPanelChange={this.onPanelChange}
        	onChange={this.onPanelChange}
        	/>
			    <div className="flexcenter Fend" style={{padding:'0 10px'}}>
      			<button className="flexcenter Fcenter btn btn2" id="reagendingButton" onClick={()=>this.cancelReagendarFecha()} > Cancelar </button>
      			<button className="flexcenter Fcenter btn btn1" onClick={this.setReagendarFecha} > Aceptar </button>
      		</div>
        </div>
      </Modal>

    	{/* Tarjetas cinepolis */}
      <Modal Modal centered title="" header={null} zIndex={5} footer={null}
        onCancel={
          ()=>{
            const {justHora} = this.state
            if(justHora){
              this.setState({reagending2:false,justHora:false,})
            }
            else{
              this.setState({reagending2:false,reagending:true})
            }
          }
        }

        visible={this.state.reagending2} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
        <div className="reagenda">
        	<div className="title"> Selecione un horario para la cita </div>
        	<label className="nta"> Duración de la cita: {this.state.duracionCita} minutos. </label>
        	<div className="citasCinepolis">
        	{
        		this.state.listaCitas2.map((cita,i)=>(
        			<div className="cuadrito flexcenter Fspacea" key={'cuadrito_'+i} onClick={()=>this.setReagendarHora(cita)} >
        				<div className={'cirulo '+(cita['descanso']?' descanso':cita['clase'])} />
        				{cita['inicio']}
        			</div>
        		))
        	}
        	</div>
        </div>
      </Modal>

    	{/* detalle cita */}
      <Modal centered title="" header={null} zIndex={4} footer={null} onCancel={()=>this.setState({addingCita:false,editanding:false,currentCita:{cita:Object.create(this.state.citaInterface)}, fechaReagendar:this.state.fecha, formatedDate : this.state.fecha.format('dddd, LL'),estatus:0,finalizada:false })}
        visible={this.state.addingCita} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
        <div className="controlesNuevaCita">
          <div className="title"> Detalle de la cita </div>
          
          <label className="nta flexcenter Fcenter linkeable" onClick={()=>this.clickHoverable(1)} > {capitalizeFirs(this.state.formatedDate)} </label>

          <div className="detalle">
            
            <div className="data flexcenter Fspaceb">
              <label>Horario cita</label>
              <label>{this.switchEstatus(this.state.currentCita['cita']['ESTATUS'])}</label>
            </div>

            <div className="data flexcenter Fstart">
              <span onClick={()=>this.clickHoverable(2)} className="linkeable" > {this.state.mutableDataCita['inicio']} - {this.state.mutableDataCita['fin']} </span>
            </div>

            <div className="data flexcenter Fspaceb">
              <label> Comentario o indicación </label>
            </div>
            {
              this.state.addingCita&&//esto aparece siempre, es solo para que actualize el contenido del input
              <Input.TextArea className="inputs-input" readOnly={this.state.estatus!==1} defaultValue={this.state.currentCita['cita']['OBSERVACIONES_CLIENTES']} id="indicacion" maxLength={500} autoSize={{minRows: 1, maxRows:4,} } />
            }

            {
              this.state.currentCita['cita']['OBSERVACIONES_EMPRESA']!==''&&
              <React.Fragment>
                <div className="data flexcenter Fspaceb">
                  <label> Comentario de la empresa </label>
                </div>
                <Input.TextArea className="inputs-input" value={this.state.currentCita['cita']['OBSERVACIONES_EMPRESA']} maxLength={50} readOnly autoSize={{minRows: 1, maxRows:4,} } />
              </React.Fragment>
            }


            {
            	this.state.editanding?
            	<div style={{marginTop:'25px'}} className="contButtons">
            		<div className="flexcenter Fspaceb">
                  {
                    this.state.estatus===1?
                      <button className="flexcenter Fcenter btn btn2" onClick={()=>this.setState({confirmEliminar:true})} > Eliminar </button>:
                      <button className="flexcenter Fcenter btn btn2" onClick={()=>this.setState({cancelanding:true})} > Cancelar </button>
                  }
            			<button className="flexcenter Fcenter btn btn2" style={{color:'#F07505'}} onClick={()=>this.setState({reagending:true,justHora:false})} > Reagendar </button>
            		</div>
            		<button className="flexcenter Fcenter btnAdd" onClick={()=>this.agregarCita(2)} >
                {
                  this.state.estatus===1?'Confirmar':'Aceptar'
                }
                </button>
            	</div>
            	:            	
            	<button style={{marginTop:'25px'}} className="flexcenter Fcenter btnAdd" onClick={()=>this.agregarCita(1)}>
                Aceptar
              </button>
            }

          </div>
        </div>
      </Modal>


      {/* motivo cancelacion */}

      <Modal centered title="" header={null} footer={null} zIndex={5} onCancel={()=>this.setState({cancelanding:false}) }
        visible={this.state.cancelanding} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
        <div className="controlesNuevaCita">
          <div className="title"> Ingrese el motivo de la cancelación </div>
          <div className="data flexcenter Fspaceb">
            <label>Motivo de cancelación </label>
          </div>
          <Input.TextArea className={"inputs-input"} id="motivoCancel" maxLength={50} autoSize={{minRows:1,maxRows:4}} />
          <button style={{marginTop:'25px'}} className="flexcenter Fcenter btnAdd" onClick={()=>this.agregarCita(3)} > Aceptar </button>
        </div>
      </Modal>

      <div className="citasPerfil">
        <div className="head flexcenter Fcenter" >
          <img src={`${img2}elementos/Grupo19236.png`} height={60} alt="..." className="icon-alert"/>
        </div>
        <div className="bodyModal">
          <div className="title"> Citas agendadas </div>

          <div className="inputs-group">
            <label className="inputs-label"> Elige una fecha </label>
            <DatePicker format="DD / MMMM / YYYY" locale='es-MX' allowClear={false} suffixIcon={null} 
              className="MyDate" placeholder="" value={this.state.fecha} onChange={(value,svalue)=>this.changeDate(value,false)} showToday={false} />
          </div>
		      <div className="listCitas">
          {
            this.state.cargandoCitas?
            <div className="cargando flexcenter Fcenter">
              <Spin indicator={<Icon type="loading" style={{ fontSize: 50,color:'#F07505'}} />} />              
            </div>
            :
            (this.state.listaCitas.length===0 || this.state.invalidDate)?
            <div className="nonResult flexcenter Fspaceb">
              <img alt="..." src={imgAdmin+'noncitas.png'} />
            </div>:
            this.state.listaCitas.map((cita,i)=>(
              <div key={'cita_B'+i} className="instanciaCita flexcenter Fspaceb" onClick={()=>this.clickOnCita(cita)} >
                <div className="horas flexcenter Fcenter">
                  <span> {cita['inicio']} </span>
                  <span> {cita['fin']} </span>
                </div>
                
                <div className={"texto flexcenter Fstart "+(cita['descanso']?' descanso':'')}>
                  <div className={cita['clase']+' barras'} />

                  <div className="inTexto flextop Fcenter">
                    <span>{cita['texto']}</span>
                    {
                      (cita['cita'].hasOwnProperty('OBSERVACIONES_CLIENTES') && cita['cita']['OBSERVACIONES_CLIENTES'].trim() !=='' && cita['cita']['MINE'] )
                      &&
                      <label>{cita['cita']['OBSERVACIONES_CLIENTES']}</label>
                    }
                  </div>

                </div>

              </div>
            ))
          }
		     	</div>
        </div>

        <div className="bodyModal flexcenter Fend">
          <button className="btn btn2" style={{width:'120px'}} onClick={()=>this.props.handleSelf('visibleCitas',false)} > Cancelar </button>
        </div>

        <Loading loading={this.state.loading} zIndex={20} />

      </div>
      </Modal>
    )
  }

}

export default ControlCitas;