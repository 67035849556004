import React from 'react';
import {  withRouter  } from "react-router-dom";
import {Img} from './../app.json'
import './stripe.scss'
class AltaStripe extends React.Component {
  
  constructor(props){
      super(props);
      this.state = { 
        txt:'',
        txt2:'',
        icon:'',
        result:'',
        clase:'',
      }
  }

  componentWillMount(){
    let {match} = this.props
    let {txt,txt2,icon,result,clase} = this.state
    result = match.params.var
    if(result.toLowerCase().trim() === 'done' ){
      txt='!VERIFICANDO INFORMACIÓN!'
      txt2='Stripe se encuentra validando tu información. Este proceso toma aproximadamente 4 días hábiles. Una vez que la validación haya sido completada, estarás listo para recibir pagos en línea. Te notificaremos el resultado a través de BuBook y por correo electrónico. Si deseas conocer más al respecto, no dudes en contactar a soporte.'
      icon=Img+'tickCalendar.svg'
      clase = 'little'
    }
    else if(result.toLowerCase().trim() === 'connected' ){
      txt='¡Listo!'
      txt2='Ya puedes recibir pagos a traves de Stripe'
      icon=Img+'tick.svg'
    }
    else if(result.toLowerCase().trim() === 'failed' ){
      txt='Error'
      txt2='Algo salió mal. Por favor, contacte a soporte.'
      icon=Img+'notick.svg'
    }
    this.setState({txt,icon,txt2,result,clase})
  }

  render(){
    return(
      <div className='contStripe flexcenter Fcenter'>
        <div className={'contStripeChild flexcenter Fspaceb '+this.state.clase}>
          
          <div className="phantom"> a </div>

          <div className="content">
            <h1> {this.state.txt} </h1>
            <div>
              <img alt="..." src={this.state.icon} />
            </div>
            <h3> {this.state.txt2} </h3>
          </div>
          
          <div className="fakebutton flexcenter Fcenter">
            <a href={'intent:/#Intent;scheme=bubooks;package=com.nibirusystem.android.bubook;S.result='+this.state.result+';end'} > Volver a Configuración </a>
          </div>

        </div>
      </div>
    );
  }
}
export default withRouter(AltaStripe);