function HeaderRequest(){
	return {
    	headers :  { 'Content-Type' : 'application/x-www-form-urlencoded;multipart/form-data', }
	}
}

function isLogged(a){
	let id = localStorage.getItem('id')
	let mail = localStorage.getItem('mail')
	return (id!==null&&mail!==undefined)&&(mail!==undefined&&mail!==null)
}

function isOtraPestana(a){
	let idS = sessionStorage.getItem('id')
    let mailS = sessionStorage.getItem('mail')
	return (idS===undefined || idS===null || mailS===undefined || mailS===null )
}

let Globalrequest = HeaderRequest();

export {Globalrequest,isLogged,isOtraPestana};