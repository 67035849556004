import React,{ useState , useEffect } from 'react';

import axios from 'axios';
import { Globalrequest as request } from './../../request.js';
import {Host as host, Img as img , stripeKey } from './../../app.json'

import moment from 'moment'

import {userContext,permisosGral} from './context'
import {isEqual} from './../../utilities/isEqual';

import { loadStripe } from '@stripe/stripe-js';

import {ReportarProblema,InvitarAmigos} from './../../index/principal/makereport';

import StripeModal from './suscripcion/stripe';
import Loading from './../../modalCargando/loading';
import ModalAlert from './../../modalCargando/modalAlert';

import {BrowserRouter , Route, withRouter    } from "react-router-dom";
import { CSSTransition } from "react-transition-group"; 
import { Icon ,Popover,Modal} from 'antd';

import { ImgGral, ImgPanelAdmin as ImgPanel} from './../../app.json'

import Config from './configuracion/config';

import Notificaciones from './notificaciones/lista';

import ListaSucursales from './sucursales/lista';
import NuevaSucursal from './sucursales/registrar'
import EditarSucursal from './sucursales/editar'
import Coberturas from './sucursales/coberturas'

import ListaProductos from './productos/lista.js';
import NuevoRegistro from './productos/registrar.js';
import EditarProducto from './productos/editar.js';

import ListaUnidades from './unidades/lista.js';
import ListaCategorias from './categorias/lista.js';
import ListaResenias from './resenias/lista.js';
import ListaClientes from './clientes/lista.js';
import ListaTransacciones from './transacciones/lista.js';
import ListaEstadisticas from './estadisticas/lista.js';

import ListaPedidos from './pedidos/lista.js';
import DetallePedido from './pedidos/detalle.js';

import ListaPromociones from './promociones/lista.js';
import NuevaPromocion from './promociones/registrar.js';
import EditarPromocion from './promociones/editar.js';

import ListaCitas from './citas/lista.js';
import Agenda from './citas/agenda.js';

import Welcome from './welcome/welcome.js';

import ListaUsuarios from './subusuarios/lista.js';

import './home.scss';

const CardUpdater = props => {

  const [vermas,setVermas] = useState(1)
  const [titularN,setTitularN] = useState('')  

  const [stripeAPI,setStripeAPI] = useState({})
  const [cardElement,setCardElement] = useState({})
  /**/
  const [tarjetaComplete,setTarjetaComplete] = useState(false)
  const [tarjetaError,setTarjetaError] = useState(false)

  const sendData = () =>{
    let {controlAlert,loadingHandler,validData} = props
    loadingHandler(true)

    if (!tarjetaComplete) {
      controlAlert(true, 'Debe completar los datos de su tarjeta')
      return
    }
    if (tarjetaError) {
      controlAlert(true, 'Debe corregir los datos de su trajeta')
      return
    }
    stripeAPI.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: { name: titularN.trim(), }
    }).then(result => {
      if (result.error) {
        let msjtoshow = ''
        let msj = result.error['message']
        msjtoshow = validError(msj)
        controlAlert(true, msjtoshow)
        loadingHandler(false)
        return;
      }else{
        let data = {
          customer : validData['idFK'],// El ID Stripe de la empresa
          paymentMethod :result['paymentMethod']['id'],//el ID de la tarjeta
        }
        axios.post(`${host}payment/GatewayStripe/updatePmWeb`,data,request).then(rt=>{
          loadingHandler(false)
          if(typeof rt.data === "object"){
            let msjtoshow = validError(rt.data['message'])
            controlAlert(true,msjtoshow)
            if( Number(rt.data['return']) === 0 ){
              cardElement.unmount()
              cardElement.destroy()
              props.handler(false)
            }
          }else{
            controlAlert(true,"Parece que ocurrió un error, por favor vuelve a intentarlo.")
          }
        })
      }
    })
  }

  const validError = msj => {
    let msjtoshow
    if (msj.includes("insufficient")) {
      msjtoshow = "La tarjeta no tiene fondos suficientes para completar la compra. Intenta nuevamente con otra tarjeta.";
    } else if (msj.includes("lost")) {
      msjtoshow = "La tarjeta ingresada se encuentra actualmente reportada como extraviada. Intenta nuevamente con otra tarjeta.";
    } else if (msj.includes("stolen")) {
      msjtoshow = "La tarjeta ingresada se encuentra actualmente reportada como robada. Intenta nuevamente con otra tarjeta.";
    } else if (msj.includes("expired")) {
      msjtoshow = "La tarjeta ingresada ha expirado. Intenta nuevamente con otra tarjeta.";
    } else if (msj.includes("security")) {
      msjtoshow = "El código de seguridad de la tarjeta ingresada es incorrecto. Verifica e inténtalo nuevamente.";
    } else if (msj.includes("declined")) {
      msjtoshow = "Tu tarjeta fue rechazada.";
    } else if (msj.includes("support")) {
      msjtoshow = "Tu tarjeta no admite este tipo de compra.";
    } else {
      msjtoshow = msj;
    }
    return msjtoshow
  }
  
  const mountStripe = () => {
    loadStripe(stripeKey, { locale: 'es' }).then(stripe => {
      setStripeAPI(stripe)

      let elements = stripe.elements()
      let cardElement = elements.create('card', {
        iconStyle: 'solid',
        style: { invalid: { color: '#ED3B48', iconColor: '#ED3B48' }, },
      })
      cardElement.on('change', (event) => {
        let div = document.getElementById('toshow')
        setTarjetaComplete(event['complete'])
        if (event.error) {
          div.textContent = '*' + event.error.message;
          setTarjetaError(true)
        }
        else {
          div.textContent = '';
          setTarjetaError(false)
        }
      })
      cardElement.mount('#cardElementUpdater')
      setCardElement(cardElement)
    })
  }

  useEffect(()=>{
    if(props.visible)//didmount
      mountStripe()
  },[props.visible])

  return(
    <Modal centered title="" visible={props.visible} 
      maskClosable={false} keyboard={false} //zIndex={999}
      header={null} footer={null} destroyOnClose width={400}
      className="nodalRegistroStripe wiClose" closable
      wrapClassName="nodalRegistroStripePapa"
      onCancel={()=>{
        props.handler(false)
        cardElement.unmount()
        cardElement.destroy()
      }}>

      <div className="contentstripe">
        
        <div className="head" style={{height:'50px'}} >
          <h4 className="titleB flexend Fcenter" style={{ fontSize: '17px',color:'white',height:'100%',marginBottom:'25px' }}> ¡Actualizar método de pago! </h4>
        </div>

        <div className="body">

          <div className="bloqueInputs">
            <h4 className="titleB titleLeft"> Método de pago </h4>

            <div className="inputs-group">
              <label className="inputs-label"> Nombre del titular </label>
              <input type="text" className="inputs-input" onChange={e => setTitularN(e.target.value.trim()) } />
            </div>

          </div>

          <div className="bloqueInputs">

            <div className="inputs-group" id="cardElementUpdater"></div>
            <div className="error-content" id="toshow"></div>
          </div>

          <div className={"warning justifed overoverred"+vermas}>
            <span>
              Al continuar autorizas a <i> BuBook.Mx </i> para recopilar tus datos financieros para su uso en los propósitos previamente expresados, tal como se encuentrá detallado en la <a target="_blank"  rel="noopener noreferrer" href="https://bubook.nibirusystem.com/legacy/politicadeprivacidad"> politica de privacidad de </a> de <i> BuBook.Mx </i>
            </span>
            {
              vermas===1?
              <span className="showM" onClick={()=>setVermas(3)} > <sub> Ver más </sub> </span>:
              <React.Fragment>
              <span>
                <br/>
                <br/>
                Es posible que tu banco o proveedor de telefonía móvil realice cargos adicionales, por ejemplo, aplicar impuestos, tarifas y/o el precio equivalente a la tasa de cambio que decida.<i> BuBook.Mx </i> no realiza estos cargos adicionales.
                <br/>
                <br/>
                <i> BuBook.Mx </i> hará una autorización temporal en tu tarjeta para verificarla. Solo se trata de una autorización y NO de un cargo. Es posible que tu banco te informe acerca de esta autorización.
              </span>
              <span className="showM" onClick={()=>setVermas(1)} > <sub> Ver menos </sub> </span>
              </React.Fragment>
            }

          </div>

          <div className="warning">          </div>
          
          <div className="contButton" onClick={sendData} >
            <button disabled={!tarjetaComplete || tarjetaError || titularN.length===0 } > Guardar tarjeta </button>
          </div>

        </div>

      </div>
    </Modal>
  )
}

const rutas = [
  //Rutas hermanas
  { path: '/home', Component: Welcome },
  { path: '/home/configuracion', Component: Config },

  { path: '/home/notificaciones', Component: Notificaciones },

  { path: '/home/sucursales', Component: ListaSucursales },
  { path: '/home/sucursales/nueva', Component: NuevaSucursal },
  { path: '/home/sucursales/editar/:id', Component: EditarSucursal },
  { path: '/home/sucursales/coberturas', Component: Coberturas },

  { path : '/home/productos', Component : ListaProductos },
  { path : '/home/productos/nuevo', Component : NuevoRegistro },
  { path : '/home/productos/editar', Component : EditarProducto },


  { path : '/home/categorias', Component :ListaCategorias },
  { path : '/home/unidades', Component : ListaUnidades},
  { path : '/home/reseñas', Component : ListaResenias},
  { path : '/home/clientes', Component : ListaClientes},
  { path: '/home/transacciones', Component: ListaTransacciones },
  { path: '/home/estadisticas', Component: ListaEstadisticas },
  
  { path: '/home/pedidos', Component: ListaPedidos },
  { path: '/home/pedidos/detalle', Component: DetallePedido },

  { path : '/home/promociones', Component : ListaPromociones},
  { path : '/home/promociones/nueva', Component : NuevaPromocion},
  { path : '/home/promociones/editar', Component : EditarPromocion},

  { path: '/home/citas', Component: ListaCitas},
  { path : '/home/agenda', Component : Agenda},

  { path: '/home/usuarios', Component: ListaUsuarios },
]

class Index extends React.Component{
	constructor(props){
    super(props);

    this.showPanel = this.showPanel.bind(this)
    this.goTo = this.goTo.bind(this)
    this.editState = this.editState.bind(this)
    this.updatePerfilDeFoto = this.updatePerfilDeFoto.bind(this)

    this.actualizarNotificaciones = this.actualizarNotificaciones.bind(this)

    this.actualizarSucursales = this.actualizarSucursales.bind(this)
    this.controlStripeModal = this.controlStripeModal.bind(this)

    this.controlAlert = this.controlAlert.bind(this)
    this.openModalReport = this.openModalReport.bind(this)

    this.clickAlertaRoja = this.clickAlertaRoja.bind(this)
    this.cancelAlertaRoja = this.cancelAlertaRoja.bind(this)

    this.checkForCurrentUser = this.checkForCurrentUser.bind(this)
    this.validCurrentUser = this.validCurrentUser.bind(this)
    this.updatePermisos = this.updatePermisos.bind(this)

    this.setFromMembresia = this.setFromMembresia.bind(this)
    

    this.state = {
      actualpage : '',
      datos:this.props.datos,
      sucursales : this.props.sucursales,
      categorias : this.props.categorias,
      notificaciones : this.props.notificaciones,
      tags : this.props.tags,
      location: this.props.location,
      /* */
      misrc:'',
      misrc2:'',
      mimail:'',
      minombre:'',
      interval: 2 * 60 * 1000,
      notificaciones2:[],
      useen:0,
      today:moment(),
      altaStripe:false,
      pending:false,
      /**/
      validData:{},
      /**/
      txtAlert:'',
      modalAlert:false,

      metadata:{},
      mood:0,
      /**/
      modalnotAllow:false,
      /**/
      dataR:{},
      modalReporte:false,
      invitanding:false,

      alertaRoja:false,
      tipoAlertaRoja:-1,
      tituloRed:'Error en el pago de tu suscripción',
      txtRedAlert:'Si no contamos con una forma de pago que funcione para tu cuenta, perderás tu suscripción. Te recomendamos que actualices tu método de pago. Volveremos a intentar a hacer el cobro en unos días.',
      txtButtonRed:'Actualizar método de pago',
      cc:0,
      pp:0,
      vence:'00-00-0000',
      cardUpdate:false,
      /**/
      userLogged:{
        type: (sessionStorage.getItem('perfiles')||'none')==='none' ? 1 : 2,
        data: {} //sessionStorage.getItem('perfiles').includes('CREAR') ? JSON.parse(sessionStorage.getItem('perfiles')) : {}
      },
      //
      fromMembresia:false,
      /***/
      validCitas:false,
    }

  }

  base64_decode_no_accents(a){
    a = atob(a)
    return a.split('[/*a*/]').join('á').split('[/*e*/]').join('é').split('[/*i*/]').join('í').split('[/*o*/]').join('ó').split('[/*u*/]').join('ú')
  }

  componentWillUnmount(){
    clearInterval(this.timerID)
    clearInterval(this.timerID2)
  }



  componentDidMount(){
    let {misrc,misrc2,mimail,minombre,metadata,mood,validCitas} = this.state
    
    let data = {idE:sessionStorage.getItem('id')}
    metadata = sessionStorage.getItem('metadata')
    if(metadata===null || metadata===undefined)return
    this.checkForCurrentUser()
    
    metadata = JSON.parse(metadata)
    let folder = metadata['folder']
    metadata = metadata['datos']

    validCitas = (Number(metadata['USAR_CONTROL_CITAS']) === 1)
    this.setState({validCitas})
    
    metadata['TELEFONO_CONTACTO'] = atob(metadata['TELEFONO_CONTACTO'])
    metadata['CORREO_REGISTRO'] = atob(metadata['CORREO_REGISTRO'])

    misrc = metadata['DIRECCION_IMG_PERFIL'].trim() === '' ? ImgPanel+'perfilDefault.jpg' : folder+'/Perfil/'+metadata['DIRECCION_IMG_PERFIL'] +'.png?'+Math.random()
    misrc2 = metadata['DIRECCION_IMG_PERFIL']  === '' ? ImgPanel+'perfilDefault.jpg' : folder+'/Perfil/'+metadata['DIRECCION_IMG_PERFIL'] +'.png?'+Math.random()
    
    mimail = atob(metadata['LABEL'])
    minombre = metadata['NOMBRE_COMERCIAL']

    mood = sessionStorage.getItem('TU9ET19QUlVFQkE=') || 0

    this.setState({metadata,mood,misrc,misrc2,mimail,minombre})

    axios.post(`${host}empresas/empresas/isValid2jaja`,data,request).then(rt=>{
      if(typeof(rt.data) === "object"){
        let validData = rt.data['payment']

        let estatusCalculado = Number(rt.data['data'])
        let isValid = !(estatusCalculado===2 || estatusCalculado===0)

        let cc = Number(rt.data['c']),pp = Number(rt.data['pp'])

        this.props.updateState('valid',isValid)
        this.props.updateState('mood',mood)
        this.setState({validData,isValid,cc,pp,estatusCalculado,vence:rt.data['vence']})

        if(estatusCalculado===3){
          let {alertaRoja,tipoAlertaRoja,tituloRed,txtRedAlert,txtButtonRed} = this.state

          if(Number(rt.data['tipo'])===1){//stripe
            alertaRoja =  true
            tipoAlertaRoja=1
            tituloRed='Error en el pago de tu suscripción'
            txtRedAlert='Si no contamos con una forma de pago que funcione para tu cuenta, perderás tu suscripción. Te recomendamos que actualices tu método de pago. Volveremos a intentar a hacer el cobro en unos días.'
            txtButtonRed='Actualizar método de pago'
            this.setState({alertaRoja,tipoAlertaRoja,tituloRed,txtRedAlert,txtButtonRed})
          }else{
            alertaRoja =  true
            tipoAlertaRoja=2
            tituloRed='Tu cortesía vence en '+rt.data['diff']+' días'
            txtRedAlert='Te invitamos a suscribirte a una membresía antes del '+moment(rt.data['vence'],'DD-MM-YYYY').format('dddd LL')+'. Si no actualizas tu suscripción antes de la fecha indicada, todas tus citas serán canceladas y perderás el acceso a las funciones escenciales de la aplicación.'
            txtButtonRed='Actualizar método de pago'
            this.setState({alertaRoja,tipoAlertaRoja,tituloRed,txtRedAlert,txtButtonRed})
          }
        }
        
        if(!isValid){
          if(Number(mood)!==1){
            this.setState({altaStripe:true}) 
            this.stripeSuscrip.makeMount()
          }
          else{
            if(this.props.isnotAllow(this.props.location.pathname)){
              this.setState({modalnotAllow:true})
            }
          }

          return
        }
        else{
          if(this.state.sucursales.length === 0 ){
            this.actualizarSucursales()
            return
          }
        }

      }else{}
    }).catch(error => { alert(error) })

    this.props.updateState('nopuedoCambiarSinAvisarte',false)
    this.actualizarNotificaciones()
    if(this.props.location.pathname !== '/home/notificaciones')
      this.timerID = setInterval( () => this.actualizarNotificaciones(), this.state.interval );
  }

  checkForCurrentUser(){
    let subid = sessionStorage.getItem('subid')||'none'
    let submail = sessionStorage.getItem('submail')||'none'
    if(subid!=='none'&&submail!=='none'){
      this.updatePermisos()
      this.timerID2 = setInterval(this.updatePermisos, 1000*60*5 )
    }else{
      let{userLogged}=this.state
      userLogged['type'] = 1
      userLogged['data'] = {}
      this.setState({userLogged})
    }
  }


  updatePermisos(){
    let data = {
      id:sessionStorage.getItem('subid'),
      idE:sessionStorage.getItem('id'),
      forCheck:1,
    }
    axios.post(`${host}subusuarios/subusuarios/permisosUsuario`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        if(rt.data['data']==='ok'){
          let{userLogged}=this.state
          let newData = rt.data['head']
          let iwales = isEqual(userLogged['data'],newData)
          if( !iwales ){
            userLogged['data'] = newData
            userLogged['type'] = 2
            sessionStorage.setItem('perfiles',JSON.stringify( newData ) )
            this.setState({userLogged})
          }

        }else//cerrar sesión, pues todo usuario debe tener al menos un permiso asignado
          console.log('oliboli')
      }
    })
  }

  validCurrentSucursal(userLogged,code){
    for(let [index,sucursal] of Object.entries(userLogged['data']) ){
      if(index===code)
        return sucursal
    }
    return {}
  }

  validCurrentUser(userLogged,permiso,_type,_especific=false,_action=false){
    
    if(userLogged['type']===1){
      if(_type === 'object')
        return {TIPO:permisosGral[permiso],EDITAR:'1',CREAR:'1',ELIMINAR:'1'}
      if(_action)
        return true
      return true
    }

    if(_especific){
      for(let [index,sucursal] of Object.entries(userLogged['data']) )
        if(index === _especific){
          let permis = sucursal['DATA'].filter(p => p['TIPO'] === permisosGral[permiso] )
          if(permis.length > 0){
            permis = permis[0]
            if(_type==='object')
              return permis
            if(_action)
              return Number(permis[_action]) === 1
            return true
          }
        }
      
    }else{
      let keys = Object.keys(userLogged['data']).filter(index => userLogged['data'][index]['SELECTED'] )

      if(keys.length>0){
        let permisos = userLogged['data'][keys[0]]['DATA']        
        let found = permisos.filter(permisa => permisa['TIPO'] === permisosGral[permiso] )
        if(found.length>0){
          if(_type === 'object')
            return found[0]
          if(_action)
            return Number(found[0][_action]) === 1
          return true          
        }
      }
    }

    if(_type==='object')
      return {}
    return false
  }

  validAllSucursal(userLogged){
    if(userLogged['type']===1)return true
    let sucursales = Object.values(userLogged['data'])
    return sucursales.every(sucursal => sucursal['SELECTED'] )
  }

  actualizarNotificaciones(){
    let data = {idE:sessionStorage.getItem('id'),op:2}
    axios.post(`${host}clientes/clientes/notificacionesPorEmpresa`,data,request).then(rt=>{

      let {useen,userLogged} = this.state
      useen = 0
      let obj = JSON.parse(JSON.stringify(userLogged))
      if(Object.keys(userLogged['data']).length === 0 ){
        let permisosPreSaved = sessionStorage.getItem('perfiles')||'none'
        if(permisosPreSaved!=='none')
          obj['data'] = JSON.parse(permisosPreSaved)  
      }

      let tiposToFilter = [1,2,3,12,13],
          tiposToFilter2 = [10]

      for (let notificacion of rt.data['array']){
        if(obj['type']===2 && (tiposToFilter.includes( Number(notificacion['TIPO'] ) ) || tiposToFilter2.includes( Number(notificacion['TIPO'] ) ) ) ){
          let permisosToCheck = ''
          if(tiposToFilter.includes( Number(notificacion['TIPO'] ) ) )//pedidos
            permisosToCheck = 'pedidos'
          if(tiposToFilter2.includes( Number(notificacion['TIPO'] ) ) )//citas
            permisosToCheck = 'citas'
          if( this.validCurrentUser(obj,permisosToCheck,'bool',notificacion['CODE'],false) && Number(notificacion['ESTATUS'])===1 )
            useen++
          
        }
        else{
          if(Number(notificacion['ESTATUS'])===1)useen++
        }
      }

      this.setState({ useen})


    })
  }

  actualizarSucursales(){
    let data = JSON.parse(sessionStorage.getItem('metadata'))
    this.props.setSucursales(data['sucursales'])
    this.setState({ sucursales : data['sucursales'] ,datos : data['datos'], loading:false, })
    this.editState('datos',data['datos'])
    this.editState('sucursales',data['sucursales'])
    this.editState('categorias',data['categorias'])
    this.editState('tags',data['tags'])
    this.editState('notificaciones',data['notificaciones'])
    if(data['sucursales'].length === 0){
      this.props.redirectTo2('/home/sucursales/nueva')
    }
  }

  showPanel(){
    document.getElementById("panelAdmin").classList.toggle("Left-Side-Toggle");
  }

  goTo(url){
    let {altaStripe} = this.state
    if(altaStripe)return
    this.props.redirectTo2(url)
  }

  editState(campo,valor){
    this.setState({[campo]:valor})
    if(campo==='datos'||campo==='tags'||campo==='categorias'||campo==='notificaciones'){
      this.props.updateState(campo,valor)
    }
  }

  updatePerfilDeFoto(src){
    sessionStorage.setItem('src',src)
    this.setState({misrc2:'xx_xx'})
    this.setState({misrc2:src})
  }

  controlStripeModal(altaStripe){
    if(altaStripe)
      this.stripeSuscrip.makeMount()
    
    this.setState({altaStripe})
  }

  setFromMembresia(fromMembresia){this.setState({fromMembresia})}

  controlAlert(modalAlert,txtAlert){ this.setState({modalAlert,txtAlert}) }

  openModalReport(a){
    if(a===1){
      let {dataR,metadata} = this.state
      dataR['nombreR'] = metadata['NOMBRE_COMERCIAL']
      dataR['correoR'] = metadata['CORREO_REGISTRO']
      dataR['telefonoR'] = metadata['TELEFONO_CONTACTO']
      dataR['tipoUR'] = 3
      this.setState({modalReporte:true,dataR})
    }else{
      this.setState({invitanding:true})
    }
    document.getElementById('triggerMenuPerfilID').click()    
  }

  clickAlertaRoja(){
    let {tipoAlertaRoja} = this.state
    if(tipoAlertaRoja===2){//cortesia o codigo promocional
      this.setState({altaStripe:true,alertaRoja:false})
      this.stripeSuscrip.makeMount()
    }else{//stripe  Modal para cambiar mi tarjeta
      this.setState({cardUpdate:true,alertaRoja:false})
   }
  }

  cancelAlertaRoja(){
    this.setState({alertaRoja:false})
  }


  render(){
    return( 
      <div className="Layaout-HomeEmpresa">

        <StripeModal
          getRef={element => { this.stripeSuscrip = element } }
          validData={this.state.validData}
          loading={this.state.altaStripe}
          cc={this.state.cc}
          pp={this.state.pp}
          vence={this.state.vence}
          estatusCalculado={this.state.estatusCalculado}
          controlSelf={this.controlStripeModal}
          fromMembresia={this.state.fromMembresia}
          setFromMembresia={this.setFromMembresia}
          tellmmeFunc={this.props.tellmmeFunc}
          updateState={this.props.updateState}
          editState={this.editState}
          />
        
        <Loading loading={this.state.pending} />

        <ModalAlert visible={this.state.modalAlert} text={this.state.txtAlert} onOk={()=>this.setState({modalAlert:false,txtAlert:''})} />

        <ModalAlert visible={this.state.modalnotAllow} text={'Tu cuenta está en modo limitado, por lo que serás redirigido a la página de inicio.'}
          onOk={
            ()=>{
              this.setState({modalnotAllow:false})
              this.props.redirectTo2('/home')
            }}
          />

        <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
            okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
            cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',display:'none'} }}
            visible={this.state.modalCuentaEliminadaBad}
            closable={false}
            onOk={this.props.cerraSesion}
            className="miAntModal newVar alertMy"
            >
            <div className="myAlertBody" style={{textAlign:'justify',padding:'5px'}} >
              <span className="txtAlert"> Parece que ocurrió un error al intentar reactivar tu cuenta. Por favor intentalo mas tarde.</span>
            </div>
        </Modal>

        <ReportarProblema dataR={this.state.dataR} controlAlert={this.controlAlert}  visible={this.state.modalReporte} handler={(modalReporte)=>this.setState({modalReporte})} />
        <InvitarAmigos visible={this.state.invitanding} handler={invitanding => this.setState({invitanding})} />

        <Modal centered title="" visible={this.state.alertaRoja} 
          
          maskClosable={this.state.tipoAlertaRoja===1} keyboard={this.state.tipoAlertaRoja===1} closable={this.state.tipoAlertaRoja===1}

          header={null} footer={null} destroyOnClose width={400}
          className="nodalRegistroStripe wiClose"
          wrapClassName="nodalRegistroStripePapa"
          onCancel={this.cancelAlertaRoja} >
          
          <div className="contentstripe redAlert">           
            
            <div className="body codPro">
              <div className="title flexcenter Fcenter"> {this.state.tituloRed} </div>

              <div className="notaData">
                {this.state.txtRedAlert}
                {
                  this.state.tipoAlertaRoja===1&&
                  <React.Fragment>
                  <br/>
                  <br/>
                  <span> *Si ya actualizaste tu método de pago, haz caso omiso de esta alerta. </span>
                  </React.Fragment>
                }
              </div>

              <button className="whithe" onClick={this.clickAlertaRoja} > {this.state.txtButtonRed} </button>

            </div>

          </div>

        </Modal>

        <CardUpdater
          visible={this.state.cardUpdate}
          controlAlert={this.controlAlert}
          handler={(cardUpdate)=>this.setState({cardUpdate})}
          loadingHandler={pending=>this.setState({pending}) } 
          validData={this.state.validData}
          />

        <div className="Nav-HomeEmpresa">

          <div className="conyey">
            <div className="Toggle-Button">
              <button onClick={this.showPanel}> <Icon type="menu" /></button>
            </div>

            <div className="Nav-Logo" >
              <img src={ImgGral+'iconos/BuBook_Horizontal.png'} alt="Logo_bubook" onClick={()=>this.goTo('/home')} />
              <img src={ImgGral+'iconos/Logo_BuBook_Simple.png'} alt="Logo_bubook_simple" />
            </div>

          </div>

          <Popover
            title=''
            forceRender
            content={
              <div className="config"  >
                
                <div className="bloque1">
                  <div className="divExtra">
                    <img id="fotopanelmini2" src={ this.state.misrc2 } alt="..." />
                  </div>
                  <div className="titleCofigPop">
                    <label className="nameHome" > {this.state.minombre}</label><br/>
                    <label className="mailHome" > {this.state.mimail} </label>
                  </div>
                </div>

                <div className="separador"></div>

                <div className="bloque2">
                  <div className={this.props.location.pathname === '/home/configuracion' ? 'opMenu active' : 'opMenu' } onClick={()=>this.goTo('/home/configuracion')} title="Configuración">
                    <img src={ImgPanel+"configuracion.svg"} alt="..." />
                    <span> Configuración </span>             
                  </div>

                  <div className="separador"></div>

                  <div className="opMenu" onClick={()=>this.openModalReport(1)} title="Reportar un problema">
                    <img src={ImgPanel+"reportarProblema.svg"} alt="..." />
                    <span> Reportar un problema </span>
                  </div>

                </div>

                <div className="separador"></div>


                <div className="bloque3">
                  <button onClick={this.props.cerraSesion} > Cerrar sesión </button>
                </div>

                <div className="separador"></div>

                <div className="bloque4">
                  <span style={{cursor:'pointer'}} onClick={()=>window.open('/legacy/politicadeprivacidad')} > Politica de privacidad </span>
                  <div className="separadorDot"></div>
                  <span> Términos y condiciones </span>
                </div>

                
              </div>
            }
            overlayClassName="pop-Suc configPop"
            placement="leftTop"
            trigger="click" >
            <div className="headHome" title="Gestionar cuenta" id="triggerMenuPerfilID" >
              <div className="divExtra">
                <img id="fotopanelmini" src={ this.state.misrc2 } alt="..." />
              </div>
            </div>
          </Popover>

          <div className="Input-Busqueda" title="Home" >

            <div title="Dashboard" className={this.state.location.pathname === '/home' ?  'Nav-Option active' : 'Nav-Option'} onClick={()=>this.goTo('/home')} >
              <img src={this.state.location.pathname === '/home' ?  ImgPanel+"home.svg" : ImgPanel+"homeB.svg"  } alt="..." />
            </div>

            <div className={this.state.location.pathname === '/home/pedidos' ?  'Nav-Option active' : 'Nav-Option'} title="Pedidos" onClick={()=>this.goTo('/home/pedidos')} >
              <img src={this.state.location.pathname.indexOf('/home/pedidos') !== -1 ?  ImgPanel+"pedidos.svg" : ImgPanel+"pedidosB.svg"} alt="..."/>
            </div>

            <div title="Productos y servicios" className={this.state.location.pathname.indexOf('/home/productos') !== -1 ?  'Nav-Option active' : 'Nav-Option'} onClick={()=>this.goTo('/home/productos')} >
              <img src={this.state.location.pathname.indexOf('/home/productos') !== -1 ?  ImgPanel+"productos.svg" : ImgPanel+"productosB.svg"} alt="..." />
            </div>

            <div title="Promociones y descuentos" className={this.props.location.pathname === '/home/promociones' ? 'Nav-Option active' : 'Nav-Option'} onClick={()=>this.goTo('/home/promociones')} >
              <img src={this.state.location.pathname.indexOf('/home/promociones') !== -1 ? ImgPanel+"promociones.svg" : ImgPanel+"promocionesB.svg" } alt="..." />
            </div>
          
            <div className={this.props.location.pathname === '/home/notificaciones'?"Nav-Option active":"Nav-Option"} title="Notificaciones" onClick={()=>this.goTo('/home/notificaciones')} >
              {this.state.useen > 0 &&  <div className="avisador">  <span> {this.state.useen} </span>  </div> }
              <img src={this.state.location.pathname.indexOf('/home/notificaciones') !== -1 ? ImgPanel+"notificaciones.svg" : ImgPanel+"notificacionesB.svg"} alt="..." />
            </div>

          </div>

        </div>

        <div className="Layaout-Grid-Left" >

          <div className="Left-Side" id="panelAdmin">
            {
              this.validCurrentUser(this.state.userLogged,'estadisticas','bool')&&
              <div className={this.props.location.pathname === '/home/estadisticas' ? 'opMenu active' : "opMenu"} onClick={()=>this.goTo('/home/estadisticas')} title="Estadísticas">
                <img src={ImgPanel+"estadisticas.svg"} alt="..." />
                <span> Estadísticas </span>
              </div>
            }

            <div className={this.props.location.pathname === '/home/reseñas' ? 'opMenu active' : "opMenu"} onClick={()=>this.goTo('/home/reseñas')} title="Reseñas">
              <img src={ImgPanel+"resenas.svg"} alt="..." />
              <span> Reseñas </span>
            </div>

            {
              (
                this.validCurrentUser(this.state.userLogged,'citas','bool')&&
                this.state.validCitas
              )&&
              <div className={this.props.location.pathname === '/home/citas' ? 'opMenu active' : "opMenu"} onClick={()=>this.goTo('/home/citas')} title="Citas agendadas">
                <img src={ImgPanel+"citas.svg"} alt="..." />
                <span> Citas agendadas </span>
              </div>
            }

            <div className="separador"></div>
            
            <div className={this.props.location.pathname === '/home/sucursales' ? 'opMenu active' : "opMenu"} onClick={()=>this.goTo('/home/sucursales')} title="Sucursales">
              <img src={ImgPanel+"sucursales.svg"} alt="..." />
              <span> Sucursales </span>
            </div>

            <div className={this.props.location.pathname === '/home/clientes' ? 'opMenu active' : "opMenu"} onClick={()=>this.goTo('/home/clientes')} title="Clientes">
              <img src={ImgPanel+"clientes.svg"} alt="..." />
              <span> Clientes </span>
            </div>

            <div className={this.props.location.pathname === '/home/categorias' ? 'opMenu active' : "opMenu"} onClick={()=>this.goTo('/home/categorias')} title="Categorías">
              <img src={ImgPanel+"categorias.svg"} alt="..." />
              <span> Categorías </span>
            </div>

            <div className={this.props.location.pathname === '/home/unidades' ? 'opMenu active' : "opMenu"} onClick={()=>this.goTo('/home/unidades')} title="Unidades de medida">
              <img src={ImgPanel+"unidadesMedida.svg"} alt="..." />
              <span> Unidades de medida </span>
            </div>


            <div className="separador"></div>

            {
              this.validCurrentUser(this.state.userLogged,'usuarios','bool')&&
              <div className={this.props.location.pathname === '/home/usuarios' ? 'opMenu active' : "opMenu"} onClick={()=>this.goTo('/home/usuarios')} title="Gestionar usuarios">
                <img src={ImgPanel+"gestionarUsuarios.svg"} alt="..." />
                <span> Gestionar usuarios </span>
              </div>
            }

          </div>

          <userContext.Provider value={this.state.userLogged} >
            <div className="Right-Side">
              <BrowserRouter>
                {
                  rutas.map(({path, Component }) => (
                    <Route key={path} exact path={path}  >
                    {({ match }) => (
                      <CSSTransition in={match != null} timeout={500} classNames="page" unmountOnExit > 
                        <Component
                          /*methods */
                          editState={this.editState}
                          updateState={this.props.updateState}
                          setSucursales={this.props.setSucursales}
                          redirectTo2={this.props.redirectTo2}
                          cerraSesion={this.props.cerraSesion}
                          validCurrentUser={this.validCurrentUser}
                          validCurrentSucursal={this.validCurrentSucursal}
                          validAllSucursal={this.validAllSucursal}
                          /* data */
                          tags={this.state.tags}
                          datos={this.state.datos}
                          categorias={this.state.categorias}
                          sucursales={this.state.sucursales}
                          notificaciones={this.state.notificaciones}
                          validData={this.state.validData}

                          controlStripeModal={this.controlStripeModal}
                          setFromMembresia={this.setFromMembresia}
                          
                          updateFotoDePerfil={this.updatePerfilDeFoto}
                          setMensaje={this.props.setMensaje}
                          mood={this.props.mood}
                          valid={this.state.isValid}


                          validCitas={this.state.validCitas}
                          setValidCitas={ validCitas => {
                            this.setState({validCitas})
                            const metadata = JSON.parse(sessionStorage.getItem('metadata'))
                            metadata['datos']['USAR_CONTROL_CITAS'] = validCitas ? '1' : '2'
                            sessionStorage.setItem('metadata',JSON.stringify(metadata))
                          }}
                        /> 
                      </CSSTransition>
                      )}
                    </Route>
                  ))
                }
              </BrowserRouter>
            </div>
          </userContext.Provider>

        </div>
      </div>
    );
  }
}

export default withRouter(Index);