import React from 'react';
import NumberFormat from 'react-number-format';
import {withRouter} from "react-router-dom";
import axios from 'axios';
import * as moment from 'moment';
import {Host as host, Img as img,ImgGral as img2} from './../app.json'
import { Globalrequest as request, isLogged } from './../request.js';
import {Select,Icon,Modal,Input} from 'antd';

import ModalAlert from './../modalCargando/modalAlert';
import LoadingSpinner from './../modalCargando/loadingSpinner';

import {Cake} from './../utilities/svg'

import './registro.scss'

const meses = [
    {label:'Ene',value:1,nombre:'Enero',dias:31},
    {label:'Feb',value:2,nombre:'Febrero',dias:28},
    {label:'Mar',value:3,nombre:'Marzo',dias:31},
    {label:'Abr',value:4,nombre:'Abril',dias:30},

    {label:'May',value:5,nombre:'Mayo',dias:31},
    {label:'Jun',value:6,nombre:'Junio',dias:30},
    {label:'Jul',value:7,nombre:'Julio',dias:31},
    {label:'Ago',value:8,nombre:'Agosto',dias:31},

    {label:'Sep',value:9,nombre:'Septiembre',dias:30},
    {label:'Oct',value:10,nombre:'Octubre',dias:31},
    {label:'Nov',value:11,nombre:'Noviembre',dias:30},
    {label:'Dic',value:12,nombre:'Diciembre',dias:31},
]

class Bubooker extends React.Component{
    constructor(props){
        super(props)
        this.showPassword=this.showPassword.bind(this)
        this.submit = this.submit.bind(this)
        this.redirectTo = this.redirectTo.bind(this)
        this.handleModal = this.handleModal.bind(this)
        this.reSendMail = this.reSendMail.bind(this)
        /**/
        this.submitNewCorreo = this.submitNewCorreo.bind(this)
        this.showModalNewMail = this.showModalNewMail.bind(this)

        /**/
        this.getDate = this.getDate.bind(this)
        this.getInvalidMonth = this.getInvalidMonth.bind(this)
        this.getInvalidDay = this.getInvalidDay.bind(this)        

        this.state = {
            pass:true,
            hoy: moment().subtract(14,'years'),
            sexo:1,
            pendiente:false,
            modal:false,
            mailmodal:'jajaja@mail.com',
            modalChangeCorreo:false,
            modalAlert:false,
            txtAlert:'',
            /**/
            diasActuales: 31,
            diaActual:-1,
            mesActual:-1,
            anioActual:-1,
        }
    }
    
    componentWillMount(){
      document.title = "Registrarme";
      if(isLogged()){
        this.props.redirectTo('/')
      }
    }
    componentDidMount(){  } 

    getDate(diaActual,mesActual,anioActual){
        let
            {diasActuales} = this.state,
            date = moment()
        
        const
            limitYear = moment().subtract(14,'year').year(),
            limitMonth = moment().subtract(14,'year').month(),
            limitDay = moment().subtract(14,'year').date()

        if(anioActual !== -1 ){//year is changing
            date.set({year:anioActual})
        }

        if(mesActual !== -1 ){//month is changing
            let divrenderedValue = document.querySelector('#selectRefMes .ant-select-selection__rendered .ant-select-selection-selected-value')
            if(anioActual === limitYear && mesActual > limitMonth)
                mesActual = limitMonth
            date.set({month:mesActual})
            diasActuales = date.daysInMonth()
            if(divrenderedValue)
                divrenderedValue.innerText = meses[date.month()]['label']
        }

        if(diaActual !== -1 ){//day is changing
            const maxDays = date.daysInMonth()
            
            let divrenderedValue = document.querySelector('#selectRefDia .ant-select-selection__rendered .ant-select-selection-selected-value')

            if(diaActual > maxDays )
                diaActual = maxDays
            
            if(diaActual > limitDay && anioActual === limitYear && mesActual === limitMonth)
                diaActual = limitDay

            if(divrenderedValue)
                divrenderedValue.innerText = diaActual+''
                
            date.set({date:diaActual})
        }
        console.log('Date formateada ',date.format('DD - MMM - YYYY'))
        console.log('Output: ',diaActual,mesActual,anioActual)
        console.log('-----------------------------------------')
        this.setState({diaActual,mesActual,anioActual,diasActuales})
    }

    fakeMonth = 5

    getInvalidMonth(month){
        let {anioActual} = this.state
        const
            limitYear = moment().subtract(14,'year').year(),
            limitMonth = moment().subtract(14,'year').month()
            
        if(anioActual !== -1 && anioActual === limitYear ){
            return limitMonth < month
        }
        return false
    }

    getInvalidDay(day){
        let {anioActual,mesActual} = this.state
        const
            limitYear = moment().subtract(14,'year').year(),
            limitMonth = moment().subtract(14,'year').month(),
            limitDay = moment().subtract(14,'year').date()
        if(anioActual !== -1 && anioActual === limitYear && mesActual === limitMonth){
            return limitDay < day
        }
        return false
    }


    showPassword(){
        let {pass} = this.state
        this.setState({pass:!pass})
    }
   
    redirectTo(){this.props.redirectTo('/auth/login') }
    
    handleModal(bool,mail=''){
        this.setState({modal:bool, mailmodal:mail })
        if(!bool){this.redirectTo()}
    }

    reSendMail(){
        let data = {mail:this.state.mailmodal}
        axios.post(`${host}usuarios/usuarios/sendMail`,data,request).then(rt=>{
            if(Number(rt.data['RESULTADO']) === 0){
                this.setState({txtAlert:"Correo no registrado",modalAlert:true})
            }
            if(Number(rt.data['RESULTADO']) === -1){
                this.setState({txtAlert:"Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #01-02]",modalAlert:true})
            }
            if(Number(rt.data['RESULTADO']) === 1){
                this.setState({txtAlert:"Correo enviado correctamente",modalAlert:true})
            }
        })
    }

    submitNewCorreo(){
      let newCorreo = document.getElementById('mail23').value
      let {mailmodal} = this.state
      newCorreo = newCorreo.trim()
      if(newCorreo === ''){
          this.setState({txtAlert:"El nuevo correo es obligatorio",modalAlert:true})
          return
      }
      let regexMail = /(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$){1,200}/;
      if(!regexMail.test(newCorreo)){
          this.setState({txtAlert:"Ingrese un correo válido",modalAlert:true})
          return
      }
      let data={ oldCorreo:mailmodal, newCorreo:newCorreo, }
      axios.post(`${host}usuarios/usuarios/cambiarCorreo`,data,request).then(rt=>{
          if(Number(rt.data)===-1){
              this.setState({txtAlert:"Ya hay una cuenta asociada al correo ingresado",modalAlert:true})
              return
          }else if(Number(rt.data)===-2){
              document.getElementById('mail23').value = ''
              this.setState({mailmodal:newCorreo,modalChangeCorreo:false,
                  txtAlert:"Correo enviado correctamente",modalAlert:true
              })
          }else{
              this.setState({txtAlert:"Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #01-02]",modalAlert:true})
          }
      })
    }

    showModalNewMail(bool){
      this.setState({modalChangeCorreo:bool})
    } 

    submit(){
        let {hoy,sexo,diaActual,mesActual,anioActual} = this.state
        let data = {}
        let formData = new FormData(document.getElementById('formularegistrobubooker'))
        
        let regexMail = /(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$){1,200}/;

        if( !regexMail.test(formData.get('mail')) ) {
            //this.showError("Ingrese un correo válido")
            this.setState({txtAlert:"Ingrese un correo válido",modalAlert:true})
            return
        }
        
        if(anioActual === -1 || mesActual === -1 || diaActual === -1 ){
            this.setState({txtAlert:"La fecha de nacimiento es obligatoria.",modalAlert:true})
            return
        }

        hoy.set({month:mesActual,date:diaActual,year:anioActual})
        
        formData.append('src','')
        formData.append('notiCorreo','2')
        formData.append('notiPush','2')
        formData.append('notiMsn','2')
        formData.append('status','2')
        formData.append('id','-1')
        formData.append('srcImgPerfil','-1')
        formData.forEach((valor,indice)=>{ data[indice]=valor })
        if(
            formData.get('nombre').trim()===''||
            formData.get('apellidos').trim()===''||
            //formData.get('fechaN').trim()===''||
            sexo === 0||
            formData.get('telefono').trim()===''||
            formData.get('mail').trim()===''||
            formData.get('contra').trim()===''||
            formData.get('contra2').trim()===''
        ){
          this.setState({txtAlert:"No puede haber campos vacíos",modalAlert:true})
          return
        }
        if(formData.get('contra').length < 6){
            this.setState({txtAlert:"La contraseña debe tener al menos 6 caracteres",modalAlert:true})
            return
        }
        if(formData.get('contra') !== formData.get('contra2')){
            this.setState({txtAlert:"Las contraseñas no coinciden",modalAlert:true})
            return
        }
        
        let telefono = formData.get('telefono');
        telefono = telefono.split('_').join('');
        telefono = telefono.split('(').join('');
        telefono = telefono.split(')').join('');
        telefono = telefono.split(' ').join('');
        
        formData.set('telefono',telefono)
        data['telefono'] = telefono
        data['fechaN'] = hoy.format('YYYY-MM-DD')
        data['sexo'] = sexo
        data['Op'] = 1

        if(formData.get('telefono').length < 10){
            this.setState({txtAlert:"El teléfono debe tener 10 caracteres",modalAlert:true})
            return
        }
        this.setState({pendiente:true})
        axios.post(`${host}usuarios/usuarios/registrar`,data,request).then(rt=>{
            this.setState({pendiente:false})
            if( Number(rt.data) === -2 ){//Al registrar un usuario el caso OperacionesEntradaClientes, no retornó ni 1 ni 3
                this.setState({txtAlert:"Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #01-01]",modalAlert:true})
            }
            else if( Number(rt.data) === -1 || Number(rt.data['RESULTADO']) === -1 ){//no se pudo enviar el correo, pero si hay registro
                this.setState({txtAlert:"Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #01-02]",modalAlert:true})
            }
            else if( Number(rt.data) === 0 || Number(rt.data['RESULTADO']) === 0 ){//la bandera de registro retorno 1, pero al consultar para enviar correo retorno []
                this.setState({txtAlert:"Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #01-03]",modalAlert:true})
            }
            else if( Number(rt.data) === 1 || Number(rt.data['RESULTADO']) === 1){
                //this.setState({pendiente:true})
                this.handleModal(true,formData.get('mail'));
            }
            else if( Number(rt.data) === 3 ){
                this.setState({txtAlert:"El correo ingresado ya está registrado",modalAlert:true})
            }
        })
    }

    showError(msj){
        document.getElementById('spanerror').innerHTML=msj
        document.getElementById('spanerror').style.display="block"
    }

    render(){
      return(
        <div className="cont-Login">
        <div className="logginInner centrado flextop Fspaceb" id="logincvino">
          <Modal centered title="" maskClosable={false} keyboard = {false}
              okText="Aceptar" okButtonProps={{ loading: this.state.loading , className: 'btn btn1'}}
              cancelButtonProps={{ className: 'btn btn2' , style:{display:'none'} }}
              visible={this.state.modal}
              closable={false}
              onOk={ () => this.handleModal(false,'') }
              className="miAntModal"
              zIndex={90}
              >

              <div className="mymodal">
                  <div className="body-alert" >
                      <img src={`${img2}correo/bubook.png`} alt="..." className="icon-alert" />
                      <div>
                          <h4 style={{marginTop:'-25px'}} > ¡Te damos la bienvenida! </h4>
                          <span className="bloque1" >
                              Para poder seguir brindando una atención y servicio de calidad a todos nuestros usuarios, debes de activar tu cuenta. Se ha enviado un mensaje con el enlace de activación a:
                          </span>

                          <label className="nota nopoint"> {this.state.mailmodal} </label>
                          <span className="bloque2" >
                              ¿Aún no te ha llegado la activación?
                          </span>

                          <br/>

                          <span className="bloque1" >
                              Revisa la bandeja de los correos no deseados (spam) o solicita un&nbsp; nuevo mensaje de activación
                          </span>
                          <br/>

                          <span className="bloque1" >
                              <label className="nota" onClick={this.reSendMail}>Reenviar mensaje de activación </label>
                          </span>
                          
                          <br/> <br/>
                          <label className="notanotanota" >
                              ¿No es tu correo?<br/>
                              <span  onClick={()=>this.showModalNewMail(true)} >Cambiar correo registrado</span>
                          </label>

                      </div>
                  </div>
                  <span className="nota error" id="spanerrormm" >  </span>
              </div>
          </Modal>

          <Modal centered title='Cambio de correo' maskClosable={false} keyboard = {false} 
            okText="Guardar" okButtonProps={{ loading: this.state.loading, className:'btn btn1' , style:{ width:'120px'} }}
            cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
            className="miAntModal alertMy modalUnidad"
            visible={this.state.modalChangeCorreo} closable={false}
            onOk={this.submitNewCorreo}
            onCancel={() => this.showModalNewMail(false)}
            zIndex={95} >
            <div className="miUniDiv">
                
              <div className="inputs-group">
                <label className="inputs-label" style={{top:'-10px'}} > Ingresa tu nuevo correo electrónico </label>
                <input type="text" autoFocus className="inputs-input" id="mail23" />
              </div>

            </div>
          </Modal>

          <ModalAlert visible={this.state.modalAlert} text={this.state.txtAlert} onOk={()=>this.setState({modalAlert:false})} />
          
          <LoadingSpinner text={'Cargando'} loading={this.state.pendiente} />

          <div className="login adjustLogin" style={{padding:'10px 30px'}} >

            <div className="itemBack flexcenter Fstart" onClick={()=>this.props.redirectTo('/auth/login')} >
              <Icon type="arrow-left" />                    
              <span> Regresar </span>
            </div>

            <div className="labelForForm"> Crear cuenta de BuBook </div>
              <form id="formularegistrobubooker" >
                  
                  <div className="inputBase">
                      <input type="text" placeholder="Nombre(s)"  name="nombre" id="nombred"  />
                  </div>

                  <div className="inputBase">
                      <input type="text" placeholder="Apellido(s)" name="apellidos" id="apellidosd"  />
                  </div>
                  
                  <div className="inputBaseInliner flexcenter Fspaceb">
                    {/*
                    <div className="inputBase">
                      <DatePicker
                        disabledDate= { d => d.isAfter(moment().subtract(14,'years') ) }
                        format="DD / MM / YYYY" locale='es-MX' //value={this.state.hoy}
                        onChange={(v)=>this.setState({hoy:v})}
                        allowClear={false}suffixIcon={null}
                        placeholder="Fecha de nacimiento" name="fechaN" className="MyDate" />
                    </div>*/}

                    <div className="inputBaseTree-container flexcenter Fspaceb">
                        <i className="cilindir" style={{height:'32px',marginTop:'-1px',paddingTop:'5px',paddingLeft:'8px'}} >
                            <Cake></Cake>
                        </i>
                        
                        <Select id="selectRefDia" className="inputBaseTree" placeholder="Día" onChange={dia => this.getDate(dia,this.state.mesActual,this.state.anioActual) } >
                            {
                                [...Array(this.state.diasActuales).keys()].map(dia => (
                                    <Select.Option disabled={this.getInvalidDay(dia+1)} key={dia} value={dia+1}> {dia+1} </Select.Option>
                                ))
                            }
                        </Select>

                        <Select id="selectRefMes" className="inputBaseTree" optionLabelProp="label" dropdownClassName="holibolillos" placeholder="Mes" onChange={mes => this.getDate(this.state.diaActual,mes,this.state.anioActual) } >
                            {
                                meses.map(mes => (
                                    <Select.Option disabled={this.getInvalidMonth(mes.value-1)} key={mes.value-1} value={mes.value-1} label={mes.label} > {mes.nombre} </Select.Option>
                                    /*{label:'Dic',value:12,nombre:'Diciembre',dias:31},*/
                                ))
                            }
                        </Select>

                        <Select className="inputBaseTree" placeholder="Año" onChange={year => this.getDate(this.state.diaActual,this.state.mesActual,year) } >
                            {
                                [...Array(100).keys()].map(year => (
                                    <Select.Option key={year} value={ moment().subtract(year + 14 ,'year').year() }> { moment().subtract(year + 14,'year').year() } </Select.Option>
                                ))
                            }
                        </Select>
                    </div>

                    <Select className="inputBaseTree" placeholder="Sexo" onChange={v => this.setState({sexo:v}) } >
                      <Select.Option value={1}> Hombre </Select.Option>
                      <Select.Option value={2}> Mujer </Select.Option>
                    </Select>

                  </div>


                  <div className="inputBase flexcenter Fstart">
                    <i className="cilindir">
                        <Icon type="phone" />
                    </i>
                    <NumberFormat style={{borderBottomLeftRadius:'0px',borderTopLeftRadius:'0px',paddingLeft:'3px'}} format="(###) ### ####" mask="_" placeholder="Teléfono" type="text"  name="telefono" id="telefonod"  />
                  </div>

                  <Input autoComplete='off' prefix={<Icon type="mail" />} name="mail" id="maild" placeholder="Correo electrónico" className="inputBase" />

                  <Input autoComplete='off' name="contra" id="contrad" placeholder="Contraseña" className="inputBase pss" type={this.state.pass?'password':'text'}
                        prefix={<Icon type="lock" />} suffix={<Icon onClick={this.showPassword} type={this.state.pass?'eye-invisible':'eye'}/>}  />

                  <Input autoComplete='off' name="contra2" id="contra2d" placeholder="Confirmar contraseña" className="inputBase" type={this.state.pass?'password':'text'}
                  prefix={<Icon type="lock" />}  />

              </form>

              <div className="txtFoot black">
                Al crear mi cuenta, acepto los <span> Términos y Condiciones de Uso </span> de BuBook Mx&reg;, y confirmo que leí y acepto las <span onClick={()=>window.open('/legacy/politicadeprivacidad')}> Políticas de Privacidad </span>
              </div>

              <div className="btnBase botonNegro flexcenter Fcenter" onClick={this.submit}>
                Crear mi cuenta
              </div>
          </div>
                
          <div className="sideTarjetaAuth sideTarjetaAuthRigth">
            <img alt="..." className="imgLogo" src={img+'bubook_blanco.png'} />
            <img alt="..." className="imgCard" src={img+'cardAuth.png'} />
          </div>
        </div>
        </div>
      )
    }
}

export default withRouter(Bubooker);