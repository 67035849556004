import React from 'react';
import { ImgGral as img2,Img as Imgurl  ,ImgPanelAdmin as ImgPanel ,Host , gmapsKey} from './../../app.json'; 
import { Modal, Icon , Select,Progress,Tooltip,Input,Popover,Badge,message,Carousel} from 'antd';
import GoogleMapReact from 'google-map-react';
import {withRouter } from "react-router-dom";
import moment from 'moment'

import { Globalrequest as request } from './../../request.js';
import axios from 'axios';

import {unique,groupBy,formatNumber} from './../../utilities/generalfunctions'

import {Compartir,Corazon,Estrella,Informacion,Facebook,Whatsapp,Telefono,ConPromocion} from "./../../utilities/svg";

import Loading from "./../../modalCargando/loading";
import LoadingSpinner from './../../modalCargando/loadingSpinner';
import ModalAlert from './../../modalCargando/modalAlert';
import {MiDiv} from './../../modalCargando/miDiv';

import NotFound from './../../modalCargando/notfound';


import ControlCitas from './citas'

import './perfil.scss'

const Mark = props => {
  
  const openmap = () => {
    window.open("https://www.google.com/maps/search/?api=1&query="+props.lat+","+props.lng, '_blank');
  }

  return(
    <div className="markada" onClick={openmap} id={props.id} >
      <Tooltip title={props.text} getTooltipContainer={() => document.querySelector("#"+props.id)} overlayClassName='tooltipMark' visible={props.visible} >
        <img src={props.src} alt="..." onError={e => e.target.src = ImgPanel+'perfilDefault.jpg' } />
      </Tooltip>
    </div>
  )
}

class AddResena extends React.Component{
  constructor(props) {
    super(props)
    this.submit = this.submit.bind(this)
    this.state = {
      puntuacion:1,
    }
  }

  submit(){
    let comentario = document.getElementById('responsed').value.trim()
    let {puntuacion} = this.state
    let {handleSelf,data,resenas,factorResenas} = this.props
    if(comentario===''){
      handleSelf('txtAlert','El comentario es obligatorio')
      handleSelf('modalAlert',true)
    }
    let metadata = JSON.parse(sessionStorage.getItem('metadata'))
    metadata = metadata['datos']
    
    let data2 = {
      ID_USUARIO:metadata['id'],
      ID_EMPRESA:data['ID'],
      PUNTUACION:puntuacion,
      COMENTARIOS:comentario,
      RESPUESTA:'',
      FECHA:moment().format('YYYY-MM-DD HH:mm:ss'),
      FECHA_RESPUESTA:'',
      NOMBRE_CLIENTE:metadata['nombre']+' '+metadata['apellido'],
      IMAGEN_CLIENTE:metadata['src'],
      ID:'-1',
    }
    handleSelf('loading',true)
    handleSelf('addingResena',false)
    axios.post(`${Host}resenias/resenias/agregarResenaRespuesta`,data2,request).then(rt=>{
      if(Number(rt.data) > 0 ){
        
        resenas.push(data2)
        let resenasFiltradas = []
        if(resenas.length>factorResenas){
          resenasFiltradas = resenas.slice(0,factorResenas)
        }else{
          resenasFiltradas = resenas
        }

        handleSelf('txtAlert','La reseña ha sido registrada correctamente')
        handleSelf('modalAlert',true)
        handleSelf('loading',false)
        handleSelf('resenas',resenas)
        handleSelf('resenasFiltradas',resenasFiltradas)
        handleSelf('miresena',true)
      }
      else{
        handleSelf('txtAlert','Parece que ocurrió un error, por favor intentelo más tarde [Código de error: SV-ER: #11-01]')
        handleSelf('modalAlert',true)
        handleSelf('loading',false)
      }
    })

  }

  render(){
    return(
      <Modal centered title="Escribir reseña"
        okText="Guardar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
        cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
        visible={this.props.visible}
        closable={false}  destroyOnClose maskClosable={false} keyboard = {false} 
        className="miAntModal alertMy modalUnidad"
        onCancel={()=> {this.props.handleSelf('addingResena',false); this.setState({puntuacion:1}); } }
        onOk={this.submit}
      >
        <div className="miUniDiv">

          <div className="inputs-group" >
            <label className="inputs-label" > Puntuación </label>
          </div>

          <div className="contEstrellas contEstrellasAdd">
            <div className="contEstrellasChild flexcenter Fcenter">
              <img
                src={this.state.puntuacion >=1 ? Imgurl+'estrella_completa2.png' : this.state.puntuacion === 0.5 ? Imgurl+'estrella_mitad2 .png' : Imgurl+'estrella_vacia2.png' }
                  className="strella" alt='...'
                  onClick={()=>this.setState({puntuacion:1})}
               />
              <img 
                src={this.state.puntuacion >=2 ? Imgurl+'estrella_completa2.png' : this.state.puntuacion === 1.5 ? Imgurl+'estrella_mitad2 .png' : Imgurl+'estrella_vacia2.png' }
                 className="strella" alt='...'
                 onClick={()=>this.setState({puntuacion:2})}
                />
              <img 
                src={this.state.puntuacion >=3 ? Imgurl+'estrella_completa2.png' : this.state.puntuacion === 2.5 ? Imgurl+'estrella_mitad2 .png' : Imgurl+'estrella_vacia2.png' }
                 className="strella" alt='...'
                 onClick={()=>this.setState({puntuacion:3})}
                />
              <img 
                src={this.state.puntuacion >=4 ? Imgurl+'estrella_completa2.png' : this.state.puntuacion === 3.5 ? Imgurl+'estrella_mitad2 .png' : Imgurl+'estrella_vacia2.png' }
                 className="strella" alt='...'
                 onClick={()=>this.setState({puntuacion:4})}
                />
              <img 
                src={this.state.puntuacion >=5 ? Imgurl+'estrella_completa2.png' : this.state.puntuacion === 4.5 ? Imgurl+'estrella_mitad2 .png' : Imgurl+'estrella_vacia2.png' }
                 className="strella" alt='...'
                 onClick={()=>this.setState({puntuacion:5})}
                />
            </div>
          </div>

          <div className="inputs-group" style={{marginTop:'10px', height:'auto' }} >
            <label className="inputs-label" htmlFor="responsed" style={{position:'relative'}} > Comentario </label>
            <Input.TextArea
              autoSize={{minRows: 1, maxRows:4,} } 
              className="inputs-input"
              id="responsed" name="response"
              autoFocus
              maxLength={200}
              />
          </div>
        </div>
      </Modal>
    )
  }

}

class ListaResenas extends React.Component{

  render(){
    return(
     <div className="section pdd bdrnd mgg">
        <span className="title"> Reseñas principales </span>
        {
          this.props.data.length ===0 ?
          <div style={{textAlign:'center'}} >
            No hay reseñas para mostrar.
          </div>
          :
          this.props.data.map((resena,i) =>(
            <div key={'reseña_'+i}>
              <div className="cardCliente">

                <div className="head">
                  
                  <div className="perfil"> <img src={resena['IMAGEN_CLIENTE']} alt="..." onError={e => this.props.errorImg(e,2)} /> </div>
                  
                  <div className="metCliente">
                    
                    <div className="nombre flexcenter Fspaceb">

                      <div className="dataNombre">{resena['NOMBRE_CLIENTE']}</div>

                      <div className="dataImgs flexcenter Fcenter">
                        <span> {Number(resena['PUNTUACION']).toFixed(1)} </span>
                        <div className="flexcenter Fcenter">
                          <img src={ Number(resena['PUNTUACION']) >= 1 ? Imgurl+'estrella_completa2A.png':(Number(resena['PUNTUACION'])>0&&Number(resena['PUNTUACION'])<1) ? Imgurl+'estrella_mitad2A.png' : Imgurl+"estrella_vacia2A.png" } alt="..." />
                          <img src={ Number(resena['PUNTUACION']) >= 2 ? Imgurl+'estrella_completa2A.png':(Number(resena['PUNTUACION'])>1&&Number(resena['PUNTUACION'])<2) ? Imgurl+'estrella_mitad2A.png' : Imgurl+"estrella_vacia2A.png" } alt="..." />
                          <img src={ Number(resena['PUNTUACION']) >= 3 ? Imgurl+'estrella_completa2A.png':(Number(resena['PUNTUACION'])>2&&Number(resena['PUNTUACION'])<3) ? Imgurl+'estrella_mitad2A.png' : Imgurl+"estrella_vacia2A.png" } alt="..." />
                          <img src={ Number(resena['PUNTUACION']) >= 4 ? Imgurl+'estrella_completa2A.png':(Number(resena['PUNTUACION'])>3&&Number(resena['PUNTUACION'])<4) ? Imgurl+'estrella_mitad2A.png' : Imgurl+"estrella_vacia2A.png" } alt="..." />
                          <img src={ Number(resena['PUNTUACION']) >= 5 ? Imgurl+'estrella_completa2A.png':(Number(resena['PUNTUACION'])>4&&Number(resena['PUNTUACION'])<5) ? Imgurl+'estrella_mitad2A.png' : Imgurl+"estrella_vacia2A.png" } alt="..." />
                        </div>
                      </div>
                    </div>

                    <div className="fecha">  {moment(resena['FECHA'],'YYYY-MM-DD').format('DD-MM-YYYY')} </div>

                  </div>

                </div>
                <MiDiv class="comen" content={resena['COMENTARIOS']} myid={'cmt_'+i} title={resena['COMENTARIOS']} />
              </div>
              {
                resena['RESPUESTA'].trim() !== '' &&
                <div className="cardRespuesta">
                  <div className="head">
                    <div>
                      <img alt="..." src={this.props.folderEmpresa+resena['IMAGEN_EMPRESA']+'.png'} onError={(e)=>this.props.errorImg(e,2)} />
                      <span className="rtaResena">{this.props.nombreEmpresa }</span>
                    </div>
                    {
                      resena['FECHA_RESPUESTA']!==''&&<span>{moment(resena['FECHA_RESPUESTA'],'YYYY-MM-DD HH:mm:SS').format('DD-MM-YYYY')}</span>
                    }
                  </div>
                  <div>
                    <MiDiv class="comen" content={resena['RESPUESTA']} myid={'rta_'+i} title={resena['RESPUESTA']} />
                  </div>
                </div>
              }
            </div>
          ))
        }
      </div>
    )
  }
}


class Perfil extends React.Component {

  constructor(props) {
    super(props)
    this.actualizarPerfil = this.actualizarPerfil.bind(this)
    this.actualizarFavoritos = this.actualizarFavoritos.bind(this)
    this.agregarEliminarFavorito = this.agregarEliminarFavorito.bind(this)
    /**/
    this.updateValueCarritoFromCarrito = this.updateValueCarritoFromCarrito.bind(this)
    this.changeSucursal = this.changeSucursal.bind(this)
    this.chageCategoria = this.chageCategoria.bind(this)
    //this.isOpen = this.isOpen.bind(this)
    this.productosScroll = this.productosScroll.bind(this)
    this.verifyUser = this.verifyUser.bind(this)
    this.setPromoAuto = this.setPromoAuto.bind(this)
    this.showVariantes = this.showVariantes.bind(this)
    this.closeModalProducto = this.closeModalProducto.bind(this)
    this.agregarAlCarrito = this.agregarAlCarrito.bind(this)
    this.changeCantidad = this.changeCantidad.bind(this)
    /**/
    this.verifyHorarios = this.verifyHorarios.bind(this)
    this.showMoreResenas = this.showMoreResenas.bind(this)
    this.showLessresenas = this.showLessresenas.bind(this)

    this.FiltrarProductos = this.FiltrarProductos.bind(this)
    this.showModalCitas = this.showModalCitas.bind(this)
    this.controlState = this.controlState.bind(this)

    this.getInfoPromo = this.getInfoPromo.bind(this)
    this.setIndexPromo = this.setIndexPromo.bind(this)
    this.savePromoElegida = this.savePromoElegida.bind(this)
    this.nosavePromoElegida = this.nosavePromoElegida.bind(this)

    this.isInCarrito = this.isInCarrito.bind(this)
    this.contactarEmpresa = this.contactarEmpresa.bind(this)

    this.reportarEmpresa = this.reportarEmpresa.bind(this)

    this.preopenProducto = this.preopenProducto.bind(this)
    this.changeBanner = this.changeBanner.bind(this)

    this.state = {
      center: { lat: 16.859166 , lng: -99.872328, },
      /**/
      modalInfo:false,
      loading:true,
      txtSpinner:'',
      modalSpinner:false,
      txtAlert:'',
      modalAlert:false,
      modalVariantes:false,
      modalVariantes2:false,
      modalPromociones:false,
      modalwithPromo:false,
      /**/
      Puntuacionnum:0,
      cantidad1:0.,
      porcentaje1:0.,
      cantidad2:0.,
      porcentaje2:0.,
      cantidad3:0.,
      porcentaje3:0.,
      cantidad4:0.,
      porcentaje4:0.,
      cantidad5:0.,
      porcentaje5:0.,
      /**/
      usuarioEmpresa:props.match['params']['usuario'],

      /**/
      detalleHorario:{status:''},
      /**/
      detalle:{},
      productos:[],
      sucursales:[],
      resenas:[],
      factorResenas:5,
      resenasFiltradas:[],
      controlCitas:[],
      controlCitasActual:{},
      conCita:false,
      miresena:false,
      venderen0:false,

      categorias:[],

      indexSucursal:0,
      categoriaActual:'',
      productosFiltrados:{},
      /**/
      arraySucursales:[],
      arrayMarkas:[],
      arrayCategoriasiD:[],
      /**/
      withUser:this.props.withUser,
      /**/
      favoritos:[],
      isFavorito:false,
      registroFavorito:{},
      /**/
      productoActual:{cantidad:1,precio:'',total:'', VARIANTES:[],ID_PROMOCIONES:[],indexPromoElegida:-1},
      productoActualAuxciliar:{cantidad:1,precio:'',total:'', VARIANTES:[],ID_PROMOCIONES:[],indexPromoElegida:-1},
      /**/
      telefono:'',
      link:'',
      facebook:'',
      whatsapp:'',
      instagram:'',
      resenasPositivas:'Sin reseñas',
      /**/
      idNotificacionPorAtender:sessionStorage.getItem('idNotificacionPorAtenderB'),
      /**/
      carritoEmpresa:{},


      /**/
      newHorarioLabel:'',

      /**/
      productoFromCarrito:'',
    }
  }

  componentDidMount(){
    let {makeRef} = this.props
    makeRef({mounted:true,component:this,name:'Perfil'})
    document.getElementById('searchInput').scrollIntoView()

    let presucursal = sessionStorage.getItem('presucursal')||false
    this.actualizarPerfil(0,presucursal);
    this.actualizarFavoritos();
    let metadata = sessionStorage.getItem('metadata')
    if(metadata!==null&&metadata!==undefined){
      metadata = JSON.parse(metadata)['datos']
      this.setState({metadata})
    }
    let {idNotificacionPorAtender} = this.state

    if(idNotificacionPorAtender !== 'null'){
      let data = {idN:idNotificacionPorAtender,estatus:2}
      axios.post(`${Host}clientes/clientes/cambiarEstatusNotificacionBubooker`,data,request)
      sessionStorage.setItem('idNotificacionPorAtenderB','null')
    }
  }

  getindexes(sucursal,detalle){
    /*calculando indexEmpresa*/
    let indexS = sucursal['NOMBRE']+'~~'+sucursal['METAID']
    indexS = 'Sucursal['+sucursal['METAID']+']'
    /*calculando indexSucursa*/
    let indexE = detalle['METASRC']
    indexE = indexE.split('~~')[0]
    /*calculando indexBubooker*/
    let metadata = JSON.parse(sessionStorage.getItem('metadata'))['datos']
    let indexB = 'Usuario['+metadata['METAID']+']'

    return {indexB,indexE,indexS,metadata}
  }

  getCarritoSucursal(sucursal,detalle){
    let carrito = JSON.parse(localStorage.getItem('carrito'))||{}
    let {metadata,indexB,indexE,indexS,} = this.getindexes(sucursal,detalle)
    let carritoUsuario = carrito[indexB]||{ id: metadata['id'], EMPRESAS:{}, }//buscamos el carrito del usuario, en caso de no encontrarl construimos la base
    let carritoEmpresa = carritoUsuario['EMPRESAS'][indexE]||{
      id: detalle['ID'],
      nombreE: detalle['NOMBRE_COMERCIAL'],
      str: detalle['FOLDER'],
      link:detalle['USUARIO_EMPRESA'],
      SUCURSALES:{},
    }//jalamos el vector empresas y buscamos la empresa actual, en caso de no encontrarl construimos la base

    let carritoSucursal = carritoEmpresa['SUCURSALES'][indexS]||{
      id: sucursal['ID'],
      nombre: sucursal['NOMBRE'],
      metaid:sucursal['METAID'],
      PRODUCTOS:[]
    }//jalamos el vector de sucursales y buscamos la sucursal actual, en caso de no encontrarl construimos la bas
    return {carrito,carritoUsuario,carritoEmpresa,carritoSucursal}
  }

  extractProducto(keySearch,productos){
    for(let producto of productos){
      if(Number(producto['CANT_VARIANTES']) === 0){
        if(producto['METAID'] === keySearch)
          return {found:true,producto:producto}
      }else{
        for(let variante of producto['VARIANTES']){
          if(variante['METAID'] === keySearch){
            Object.defineProperty(variante, 'FOLDER', { enumerable: true, configurable: true, writable: true, value: producto['FOLDER'], })
            return {found:true,producto:producto,variante:variante}
          }
        }
      }
    }
    return {found:false}
  }
  //charliedamelio
  preopenProducto(preopen,productos){
    for(let array of Object.values(productos)){
      let result = this.extractProducto(preopen['metakey'],array)
      if(result['found']){
        let producto = result['producto']
        let ms = 0
        if(result['variante']){
          this.showVariantes(producto)
          ms = 100
          producto = result['variante']
        }
        setTimeout(()=>{
          this.showVariantes(producto,preopen['cantidad'])
        },ms)
        
        setTimeout(()=>{
          this.comentarioproducto.state.value = preopen['comentario']
        },ms+100)

        this.setState({productoFromCarrito: preopen})
        if(Number(preopen['tipoPromo'])>0){
          let promos = producto['ID_PROMOCIONES']
          let c = 0
          for(let promo of promos){
            let cc = c;
            if(promo['METAID'] === preopen['promo'] ){
              if(Number(promo['TIPO'])===2){
                setTimeout(()=>this.setIndexPromo(cc,-1),ms+300)
                setTimeout(this.savePromoElegida,ms+1000)
              }else{
                let c2 = 0
                for(let detalle of promo['DETALLES']){
                  let cc2 = c2;
                  if(detalle['METADETALLE']===preopen['detalle']){
                    setTimeout(()=>this.setIndexPromo(cc,cc2),ms+300)
                    setTimeout(this.savePromoElegida,ms+1000)
                    break
                  }
                  c2++
                }
              }
              break
            }
            c++
          }
        }
      }
    }
  }

  actualizarPerfil(link=0,metaIDSucursal=false){
    
    
    let productoFromCarrito = sessionStorage.getItem('productoFromCarrito')||'none'
    sessionStorage.removeItem('productoFromCarrito')


    sessionStorage.removeItem('presucursal')
    let {usuarioEmpresa} = this.state
    let {withUser} = this.props
    if(link!==0){
      this.setState({usuarioEmpresa:link,detalle:{}, productos:[], resenas:[], sucursales:[], categorias:[], indexSucursal:0, categoriaActual:'', productosFiltrados:{},arrayCategoriasiD:[]})
      usuarioEmpresa = link
    }

    if(this.controlCitas!==undefined)this.controlCitas.clear('actualizarPerfil')
    let data = {
      usuarioEmpresa:usuarioEmpresa,
      idB: withUser ? sessionStorage.getItem('id'):'-1',
    }

    if(!withUser){
      sessionStorage.removeItem('ruta')
      sessionStorage.setItem('ruta',data['usuarioEmpresa'])
    }

    this.setState({loading:true})
    axios.post(`${Host}indice/indice/perfilEmpresa`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let {detalle,productos,resenas,sucursales,/**/indexSucursal,productosFiltrados,arrayMarkas,center,Puntuacionnum/**/,categoriaActual,arrayCategoriasiD,
          cantidad1,cantidad2,cantidad3,cantidad4,cantidad5,
          porcentaje1,porcentaje2,porcentaje3,porcentaje4,porcentaje5,telefono,whatsapp,link,facebook,instagram,resenasPositivas,factorResenas,resenasFiltradas,controlCitas,conCita,controlCitasActual,
          miresena,venderen0, carritoEmpresa,

          newHorarioLabel
        } = this.state

        detalle = rt.data['data']['detalle']
        productos = rt.data['data']['productos']

        sucursales = rt.data['data']['sucursales']
        resenas = rt.data['data']['resenas']
        controlCitas = rt.data['data']['controlCitas']

        indexSucursal = 0

        if(metaIDSucursal){
          for(let i in sucursales){
            if(metaIDSucursal === sucursales[i]['METAID']){
              indexSucursal = i*1
              break
            }
          }
        }

        if(sucursales.length===0){
          this.setState({modalEmpresaNotFound:true,txtNotFound:'Parece que la empresa que estás buscando aún no cuenta con sucursales registradas',loading:false})
          return
        }

        if(withUser)
          carritoEmpresa = this.getCarritoSucursal(sucursales[indexSucursal],detalle)['carritoSucursal']

        miresena = Number(rt.data['data']['detalle']['PUNTUACION_USER']) > 0
        venderen0 = Number(detalle['VENTA_SIN_EXISTENCIAS']) === 1

        controlCitasActual = controlCitas.filter(c => c['NOMBRE_SUCURSAL'].trim().toLowerCase() === sucursales[indexSucursal]['NOMBRE'].trim().toLowerCase() )
        if(controlCitasActual.length>0){
          controlCitasActual = controlCitasActual[0]
          conCita=true
        } else conCita=false

        if(resenas.length>factorResenas){
          resenasFiltradas = resenas.slice(0,factorResenas)
        }else{
          resenasFiltradas = resenas
        }
        document.title = 'BuBook - '+detalle['NOMBRE_COMERCIAL'];
        /**/

        if(sucursales[indexSucursal]['HORARIO_ATENCION'].trim()==="0" || sucursales[indexSucursal]['HORARIO_ATENCION'].trim()===""){
          newHorarioLabel = "cerrado"
        }else{
          let now = moment()
          let horariosHoy = sucursales[indexSucursal]['HORARIO_ATENCION'].split('-')
          let cierra = moment(horariosHoy[1],'HH:mm:ss')
          let abre = moment(horariosHoy[0],'HH:mm:ss')
          if(abre.isBefore(cierra))
            newHorarioLabel = now.isBetween(abre,cierra) ? 'abierto' : 'cerrado'
          else if(abre.isAfter(cierra))
            newHorarioLabel = now.isBetween(cierra,abre) ? 'cerrado' : 'abierto'
          else
            newHorarioLabel = 'abierto'
        }

        /*let minutosDiff = cierra.diff(now,'minutes')
        this.timer = setInterval(this.verifyHorario,minutosDiff*60*1000)*/

        /**/
        for(let producto of productos){
          producto['EXISTENCIA_TOTAL'] = producto['VARIANTES'].map(v => v['STOCK_PROPIO'].map(st => st) )
          producto['EXISTENCIA_TOTAL'].push(producto['STOCK_PROPIO'])
          producto['EXISTENCIA_TOTAL'] = unique(producto['EXISTENCIA_TOTAL'].flat())
        }

        productosFiltrados = productos.filter(producto => producto['EXISTENCIA_TOTAL'].includes(sucursales[indexSucursal]['NOMBRE']) )
        productosFiltrados = groupBy(productosFiltrados,'CATEGORIA')
        arrayCategoriasiD = Object.entries(productosFiltrados).map(([categoria,_])=> 'cat_'+categoria.split(' ').join('_') )
        categoriaActual = arrayCategoriasiD[0]

        telefono = sucursales[indexSucursal]['TELEFONO']
        whatsapp = sucursales[indexSucursal]['WHATSAPP']
        link = 'https://bubook.nibirusystem.com/empresas/'+usuarioEmpresa
        facebook = detalle['FACEBOOK']
        instagram = detalle['INSTAGRAM']

        /**/
        Puntuacionnum = Number(detalle['PUNTUACION']).toFixed(1)

        if(resenas.length === 0){
           porcentaje1=0;porcentaje2=0;porcentaje3=0;porcentaje4=0;porcentaje5=0;
           cantidad1=0;cantidad2=0;cantidad3=0;cantidad4=0;cantidad5=0;
           resenasPositivas = 'Sin reseñas'
         }
         else{
           cantidad1=Number(detalle["PUNTUACION_1"]);
           cantidad2=Number(detalle["PUNTUACION_2"]);
           cantidad3=Number(detalle["PUNTUACION_3"])
           cantidad4=Number(detalle["PUNTUACION_4"]);
           cantidad5=Number(detalle["PUNTUACION_5"]);
           porcentaje1=(cantidad1/resenas.length)*100
           porcentaje2=(cantidad2/resenas.length)*100
           porcentaje3=(cantidad3/resenas.length)*100
           porcentaje4=(cantidad4/resenas.length)*100
           porcentaje5=(cantidad5/resenas.length)*100
           resenasPositivas = ((porcentaje5+porcentaje4).toFixed(0))+'% de calificaciones positivas'
         }
        /**/

        arrayMarkas = sucursales.map((s,i) => {return {lat: Number(s['ALTITUD']) ,lng:Number(s['LONGITUD']), nombre:s['NOMBRE']}} )
        center = {lat:arrayMarkas[0]['lat'] ,lng:arrayMarkas[0]['lng']  }
        
        this.setState({ detalle, productos,resenas, sucursales, indexSucursal,arrayMarkas,center,cantidad1,cantidad2,cantidad3,cantidad4,cantidad5,porcentaje1,porcentaje2,porcentaje3,porcentaje4,porcentaje5,Puntuacionnum, productosFiltrados,arrayCategoriasiD,loading:false,categoriaActual,newHorarioLabel, telefono,whatsapp,link,facebook,instagram,resenasPositivas,resenasFiltradas,controlCitas ,conCita,controlCitasActual,miresena,venderen0,carritoEmpresa})

        setTimeout(()=>{
          if(productoFromCarrito!=='none'){
            productoFromCarrito = JSON.parse(productoFromCarrito)
            this.preopenProducto(productoFromCarrito,productosFiltrados)
          }
        },100)

      }else{
        if(typeof(rt.data['data'])!=="object"){
          if(rt.data['data']===1){
            this.setState({modalEmpresaNotFound:true,txtNotFound:'Parece que la empresa que estás buscando ya no está disponible',loading:false})
            return
          }else{
            this.setState({txtNotFound:'Parece que ocurrió un error al consultar la informacion de la empresa, por favar intentelo más tarde.',modalEmpresaNotFound:true,loading:false})
            //this.setState({txtAlert:'Parece que ocurrió un error al consultar la informacion de la empresa, por favar intentelo más tarde.',modalAlert:true,loading:false})
            return            
          }
        }
      }
      document.getElementById('searchInput').scrollIntoView()
    })
  }

  actualizarFavoritos(){
    let {usuarioEmpresa,isFavorito,favoritos,registroFavorito} = this.state
    favoritos = JSON.parse(sessionStorage.getItem('metadata'))
    if(favoritos!==null&&favoritos!==undefined) favoritos = favoritos['favoritas']
    else favoritos = []
    registroFavorito = favoritos.filter(fav => fav['link'] === usuarioEmpresa)
    isFavorito = registroFavorito.length > 0
    registroFavorito = isFavorito ? registroFavorito[0] : {}
    this.setState({isFavorito,registroFavorito,favoritos})
  }

  agregarEliminarFavorito(e){
    let {favoritos,registroFavorito,isFavorito,usuarioEmpresa,detalle} = this.state
    let dataName = detalle['ID']
    let nombre = detalle['NOMBRE_COMERCIAL']
    let data = {}
    if(isFavorito){
      data['op']=-1
      data['idFav'] = registroFavorito['registro']
    }else{
      data['op']=1
      data['idU'] = sessionStorage.getItem('id')
      data['idE'] = dataName
      data['tipo'] = 1
    }
    document.querySelector('#corazaoID').style.display = "none"
    document.querySelector('#loadingID').style.display = "initial"

    axios.post(`${Host}usuarios/usuarios/eliminarAgregarFavoritas`,data,request).then(rt=>{
      document.querySelector('#corazaoID').style.display = "initial"
      document.querySelector('#loadingID').style.display = "none"
      if(data['op']===-1){
        if(Number(rt.data)===1){
          favoritos = favoritos.filter(fav => fav['link']!==usuarioEmpresa)
          registroFavorito = {}
          isFavorito=false
        }
      }
      else{
        if(Number(rt.data['status']) === 1){
          registroFavorito = {link:usuarioEmpresa,nombre:nombre,registro:rt.data['registro']}
          favoritos.push(registroFavorito)
          isFavorito = true
        }
      }
      let metadata = JSON.parse(sessionStorage.getItem('metadata'))
      metadata['favoritas'] = favoritos
      sessionStorage.setItem('metadata',JSON.stringify(metadata))
      this.setState({favoritos:favoritos,isFavorito:isFavorito,registroFavorito:registroFavorito})
    })
  }

  formatHora(shora){
    return moment(shora,'HH:mm:ss').format('hh:mm a')
  }

  formatTelefono(str){
    if(str.length>10)
      str = str.slice(0,10)
    return '('+str.slice(0,3)+') '+str.slice(3,6) + ' '+str.slice(6)
  }

  goToWhats(number,empresa){
    if(number.length>10)
      number = number.slice(0,10)
    let url = `https://wa.me/521${number}?text=Hola,%20${empresa}`
    window.open(url,'_blank')
  }

  goToFacebook(fb){
    if(fb.trim()==="");
    else if(fb.toLowerCase().includes('facebook.com'))
      window.open(fb,'_blank')
    else 
      window.open('https://facebook.com/'+fb,'_blank')
  }
  goToInstagram(ins){
    if(ins.trim()==="");
    else if(ins.toLowerCase().includes('instagram.com'))
      window.open(ins,'_blank')
    else 
      window.open('https://instagram.com/'+ins,'_blank')
  }

  errorImg(e,a){
    if(a===1){e.target.src=ImgPanel+'portadalDefault.jpg'}
    if(a===2){e.target.src=ImgPanel+'perfilDefault.jpg'}
    if(a===3){
      e.target.src=img2+'iconos/new_camera_icon_prod.png';
      e.target.classList.add('igmError')
    }
  }

  updateValueCarritoFromCarrito(){
    let {sucursales,indexSucursal,detalle} = this.state
    let carritoEmpresa = this.getCarritoSucursal(sucursales[indexSucursal],detalle)['carritoSucursal']
    this.setState({carritoEmpresa})
  }

  changeSucursal(value){
    let {detalle,sucursales,center,productosFiltrados,productos,arrayCategoriasiD ,categoriaActual,newHorarioLabel,telefono,whatsapp,controlCitas ,conCita,controlCitasActual,carritoEmpresa} = this.state
    let {withUser} = this.props
    if(this.controlCitas!==undefined)this.controlCitas.clear('changeSucursal')

    center = {lat:Number(sucursales[value]['ALTITUD']) , lng: Number(sucursales[value]['LONGITUD']) }
    productosFiltrados = productos.filter(producto => producto['EXISTENCIA_TOTAL'].includes(sucursales[value]['NOMBRE']) )
    productosFiltrados = groupBy(productosFiltrados,'CATEGORIA')
    arrayCategoriasiD = Object.entries(productosFiltrados).map(([categoria,_])=> 'cat_'+categoria.split(' ').join('_') )
    categoriaActual = arrayCategoriasiD[0]
    telefono = sucursales[value]['TELEFONO']
    whatsapp = sucursales[value]['WHATSAPP']
    
    controlCitasActual = controlCitas.filter(c => c['NOMBRE_SUCURSAL'].trim().toLowerCase() === sucursales[value]['NOMBRE'].trim().toLowerCase() )
    if(controlCitasActual.length>0){
      controlCitasActual = controlCitasActual[0]
      conCita=true
    } else conCita=false

    if(withUser)
      carritoEmpresa = this.getCarritoSucursal(sucursales[value],detalle)['carritoSucursal']

    if(sucursales[value]['HORARIO_ATENCION'].trim()==="0" || sucursales[value]['HORARIO_ATENCION'].trim()===""){
      newHorarioLabel = "cerrado"
    }else{
      let now = moment()
      let horariosHoy = sucursales[value]['HORARIO_ATENCION'].split('-')
      let cierra = moment(horariosHoy[1],'HH:mm:ss')
      let abre = moment(horariosHoy[0],'HH:mm:ss')
      if(abre.isBefore(cierra))
        newHorarioLabel = now.isBetween(abre,cierra) ? 'abierto' : 'cerrado'
      else if(abre.isAfter(cierra))
        newHorarioLabel = now.isBetween(cierra,abre) ? 'cerrado' : 'abierto'
      else
        newHorarioLabel = 'abierto'
    }

    document.getElementById('filtradorPorductos').value=''
    this.setState({indexSucursal:value,center, productosFiltrados,arrayCategoriasiD ,categoriaActual,telefono,whatsapp,controlCitas ,conCita,controlCitasActual,carritoEmpresa,newHorarioLabel})
  }

  getPrecio(producto){
    let precio = ''
    if(Number(producto['USUAR_VARIENTES']) === 1){
      precio = producto['VARIANTES'].map(v => Number(v['PRECIO']))
      precio = Math.min(...precio)
      precio = 'Desde $ '+formatNumber(precio,2)+' MXN'
    }
    else
      precio = producto['PRECIO']==='0' ? 'Consultar precio' : '$ '+formatNumber(producto['PRECIO'],2)+' MXN';
    
    return precio
  }

  chageCategoria(categoria){
    const cat = 'cat_'+categoria.split(' ').join('_')
    document.getElementById(cat).scrollIntoView();
    this.setState({categoriaActual:cat})
  }

  productosScroll(e){
    /*
    let {arrayCategoriasiD} = this.state

    let posY  = e.target.scrollTop + (e.target.clientHeight/2)
    let topParent = e.target.offsetTop

    let idActual = ''

    for(let id of arrayCategoriasiD){
      let topCat = document.getElementById(id).offsetTop
      if( posY>(topCat-topParent) )idActual =  id
    }
    this.setState({categoriaActual:idActual})
    */
  }

  verifyUser(_callBack,arg1=0,arg2=0,arg3=0){
    let {withUser} = this.state
    if(withUser){
      _callBack(arg1)
    }
    else{this.props.stateHandler('modalVerMas',true)}
  }

  setPromoAuto(){
   let {productoActual} = this.state
   let tipo = Number(productoActual['ID_PROMOCIONES'][0]['TIPO'])
   if(tipo===2){
     this.setIndexPromo(0,-1)
     setTimeout(this.savePromoElegida,100)
   }else if(tipo===3){

    this.setIndexPromo(0,0)
    setTimeout(()=>{
      let a = this.savePromoElegida(-10)
      if(a<0)
        this.setIndexPromo(-1,-1,true)
    },100)
     
     /*let cantidadActual = productoActual['cantidad']
     let detalle = productoActual['ID_PROMOCIONES'][0]['DETALLES'][0]
     let canidadNecesaria = Number(detalle['CANTIDAD'])
     //if(cantidadActual===canidadNecesaria){     }*/
   }
  }

  showVariantes(producto,cantidad=false){
    let {productoActual,productoActualAuxciliar,sucursales,indexSucursal} = this.state
    if(Number(producto['CANT_VARIANTES'])===0){
            
      productoActual = JSON.parse(JSON.stringify(producto))
      
      
      productoActual['STOCK_ACT'] = Number(producto['STOCK_PROPIO_INDEXADO'][sucursales[indexSucursal]['METAID']])


      productoActual['cantidad']=1
      
      if(cantidad){
        if(cantidad > Number(productoActual['STOCK_ACT']) && Number(productoActual['USAR_STOCK']) === 1 )
          productoActual['cantidad'] = productoActual['STOCK_ACT']
        else
          productoActual['cantidad'] = cantidad
      }

      productoActual['descuentoEnPesos']=0

      productoActual['indexPromoElegida'] = -1
      productoActual['indexPromoElegidaPre'] = -1

      productoActual['subindexPromoElegida'] = -1
      productoActual['subindexPromoElegidaPre'] = -1

      productoActual['MINIMO_PROMO'] = 1
      productoActual['MINIMO_PROMO_HIJO'] = 1

      productoActual['hijo'] = {}
      productoActual['hijo']['cantidad'] = 0
      productoActual['hijo']['descuentoEnPesos'] = 0
      productoActual['hijo']['precio'] = 0
      
      productoActual['avisado'] = false
      //productoActual['showAdvise'] = true

      this.setState({modalVariantes:true,productoActual})
      
      if(
          productoActual['ID_PROMOCIONES'].length === 1 && 
          ( Number(productoActual['ID_PROMOCIONES'][0]['TIPO']) === 2 || Number(productoActual['ID_PROMOCIONES'][0]['TIPO']) === 3) && 
          productoActual['ID_PROMOCIONES'][0]['DETALLES'].length===1
        ){
        setTimeout(this.setPromoAuto,100)
      }
    }
    else{
      productoActualAuxciliar = JSON.parse(JSON.stringify(producto))
      productoActualAuxciliar['VARIANTES'] = productoActualAuxciliar['VARIANTES'].map(variante =>  (Object.defineProperty(variante, 'FOLDER', { enumerable: true, configurable: true, writable: true, value: producto['FOLDER'], })) )
      this.setState({modalVariantes2:true,productoActualAuxciliar})
    }
  }

  closeModalProducto(b){
    if(b===2){
      let img = document.getElementById('imgPerfilProductoActual2')
      if(img)
        img.classList.remove('igmError')
      this.setState({modalVariantes:false,productoFromCarrito: {}})
      this.comentarioproducto.state.value = ''
    }
    if(b===1)
      this.setState({modalVariantes2:false})
  }

  buildProductoHijo(producto,folder){
    return {
      nombre:producto['NOMBRE'],
      descripcion:producto['DESCRIPCION'],
      comentario:'',
      cantidad:0,
      precio:Number(producto['PRECIO']),
      folder:folder,
      img:producto['IMAGEN'],
      descuentoEnPesos:'',
      dependenciaPromocion:'-1',
      dependenciaProducto:'-1',
      key:producto['ID'],
      metakey:producto['METAID'],
      detallePromocion:-1,
      detallePromocionMETA:-1,
    }
  }

  getProductoHijo(productos,metaID){
    for(let producto of productos){
      if(Number(producto['USUAR_VARIENTES'])===1){
        for(let variante of producto['VARIANTES']){
          if(variante['METAID']===metaID)
            return this.buildProductoHijo(variante,producto['FOLDER'])
        }
      }else{
        if(producto['METAID']===metaID)
          return this.buildProductoHijo(producto,producto['FOLDER'])
      }
    }
  }

  checkCantidades(productoActual,detalle,productos,sucursales,indexSucursal,productoFromCarrito){
    
    const {indexE,indexS,} = this.getindexes(sucursales[indexSucursal],detalle)
    const {totalEnCarrito} = this.props

    let excluding = productoFromCarrito.hasOwnProperty('metakey')

    let cantidadEnCarrito = totalEnCarrito(indexE,indexS,productoFromCarrito,productoActual['METAID'],excluding)
    let cantidadaComparar = Number(productoActual['cantidad'])

    cantidadaComparar+=cantidadEnCarrito

    if(
        Number(detalle['VENTA_SIN_EXISTENCIAS'])!==1&&
        Number(productoActual['USAR_STOCK'])=== 1 &&
        Number(productoActual['STOCK_ACT']) < cantidadaComparar
    ){
      //return {txtAlert:"Caso 1: No hay cantidad suficiente en el stack del producto: '"+productoActual['NOMBRE']+"'",valid:false,caso:1}
      return {txtAlert:"No hay cantidad suficiente en el stock del producto: '"+productoActual['NOMBRE']+"'",valid:false,caso:1}
    }
    
    let validity = true,txtAlertl = '',casee=0,maxCountHijo = 0

    if(productoActual['indexPromoElegida']!==-1){
      let promo = productoActual['ID_PROMOCIONES'][productoActual['indexPromoElegida']]
      let detallePromo = promo['DETALLES'][productoActual['subindexPromoElegida']]
  

      let factorpaquete    
      let phijo = {}

      if(Number(promo['TIPO']) > 2){
        let cantidadMinima = Number(detallePromo['CANTIDAD'])
        let cantidadMinimaHjio = Number(detallePromo['HIJO']['CANTIDAD'])

        factorpaquete = parseInt( productoActual['cantidad']/cantidadMinima , 10)

        let totalARestar = cantidadMinima

        if(!productoFromCarrito.hasOwnProperty('metakey') ){
          totalARestar+=cantidadEnCarrito
        }


        if(
          Number(detalle['VENTA_SIN_EXISTENCIAS'])!==1&&
          Number(productoActual['USAR_STOCK'])=== 1 &&
          Number(productoActual['STOCK_ACT']) - totalARestar < 0
        ){//validando cantidad del priducto principal
          //return {txtAlert:"Caso 2: No hay cantidad suficiente en el steck del producto: '"+productoActual['NOMBRE']+"'",valid:false,caso:2}
          return {txtAlert:"No hay cantidad suficiente en el stock del producto: '"+productoActual['NOMBRE']+"'",valid:false,caso:2}
        }
        
        phijo = productos.filter(p => p['METAID'] === detallePromo['HIJO']['METAID'] )

        if(phijo.length===0){
          let praux = productos.filter(p => p['VARIANTES'].some(v => v['METAID'] === detallePromo['HIJO']['METAID']  ))
          praux = praux[0]
          phijo = praux['VARIANTES'].filter(v => v['METAID'] === detallePromo['HIJO']['METAID'] )
        }
        phijo = phijo[0]
        let cantidadAuxiliarParaComparar = Number(phijo['STOCK_ACT'])
        
        if(
          ( 
            !productoFromCarrito.hasOwnProperty('hijo') || !productoFromCarrito['hijo'].hasOwnProperty('metakey') )
          ){
          cantidadAuxiliarParaComparar -= totalEnCarrito(indexE,indexS,productoFromCarrito,phijo['METAID'],excluding)
        }

        if(detallePromo['HIJO']['SAME']){
          cantidadAuxiliarParaComparar = cantidadAuxiliarParaComparar - Number(productoActual['cantidad'])
        }

        if(
          Number(detalle['VENTA_SIN_EXISTENCIAS'])!==1&&
          Number(phijo['USAR_STOCK'])=== 1 &&
          cantidadAuxiliarParaComparar - factorpaquete*cantidadMinimaHjio < 0
        ){//validando cantidad del priducto secundario
          //return {txtAlert:"Caso 3: No hay cantidad suficiente en el stick del producto: '"+phijo['NOMBRE']+"'",valid:false,caso:3}
          //return {txtAlert:"No hay cantidad suficiente en el stock del producto: '"+phijo['NOMBRE']+"'",valid:false,caso:3}
          txtAlertl = `No hay cantidad suficiente en el stock del producto: '${phijo['NOMBRE']}'`
          validity = false
          casee = 3
          maxCountHijo = cantidadAuxiliarParaComparar
        }

        productoActual['MINIMO_PROMO'] = cantidadMinima
      }

      productoActual['descuentoEnPesos'] = 0
      productoActual['hijo']['cantidad'] = 0
      productoActual['hijo']['precio'] = 0
      productoActual['hijo']['descuentoEnPesos'] = 0


      let dem = Number(promo['DETALLES'][0]['DESCUENTO_MONETARIO'])
      let dep = Number(promo['DETALLES'][0]['DESCUENTO_PORCENTAJE'])

      productoActual['tipoPromo'] = Number(promo['TIPO'])

      if(productoActual['tipoPromo']===2){
        factorpaquete=0
        if(dem>0)
            productoActual['descuentoEnPesos'] = dem
        if(dep>0){
          dem = dep * Number(productoActual['PRECIO'])
          productoActual['descuentoEnPesos'] = dem/100
        }
      }
      else if(Number(promo['TIPO'])===3){
        let subindexPromoElegida = productoActual['subindexPromoElegida']
        let detallePromo = promo['DETALLES'][subindexPromoElegida]
        productoActual['descuentoEnPesos'] = 0
        productoActual['hijo']['precio'] = Number(phijo['PRECIO'])
        productoActual['hijo']['descuentoEnPesos'] = Number(phijo['PRECIO'])
        productoActual['hijo']['cantidad'] = Number(detallePromo['HIJO']['CANTIDAD'])
      }else if(Number(promo['TIPO'])===4){
        let subindexPromoElegida = productoActual['subindexPromoElegida']
        let detallePromo = promo['DETALLES'][subindexPromoElegida]
        productoActual['descuentoEnPesos'] = 0
        productoActual['hijo']['precio'] = Number(phijo['PRECIO'])
        productoActual['hijo']['cantidad'] = Number(detallePromo['HIJO']['CANTIDAD'])

        dem = Number(detallePromo['HIJO']['DESCUENTO_MONETARIO'])
        dep = Number(detallePromo['HIJO']['DESCUENTO_PORCENTAJE'])
        if(dem>0)
          productoActual['hijo']['descuentoEnPesos'] = dem
        if(dep>0){
          dem = dep * Number(phijo['PRECIO'])
          productoActual['hijo']['descuentoEnPesos'] = (dem/100)
        }
      }

      productoActual['MINIMO_PROMO_HIJO'] = productoActual['hijo']['cantidad']
      productoActual['hijo']['cantidad'] = validity ? factorpaquete * productoActual['MINIMO_PROMO_HIJO'] : maxCountHijo
    
    }

    //alert('Padre: '+productoActual['cantidad'])
    //alert('Hijo: '+productoActual['hijo']['cantidad'])

    return {valid:validity,txtAlert:txtAlertl,caso:casee}
  }
  
  agregarAlCarrito(bandera){
    let {productoActual,productoActualAuxciliar,detalle,sucursales,indexSucursal,productos,productoFromCarrito} = this.state

    if( Number(productoActual['USAR_STOCK'])===1 && 
        Number(productoActual['STOCK_ACT'])===0 &&
        Number(detalle['VENTA_SIN_EXISTENCIAS']) !== 1
      )return

    let cantidadesVerificadas = this.checkCantidades(productoActual,detalle,productos,sucursales,indexSucursal,productoFromCarrito)

    if(!cantidadesVerificadas['valid']){
      if( cantidadesVerificadas['caso'] !== 1 ){
        productoActual['avisado'] = true
      }else{
        message.error({content:cantidadesVerificadas['txtAlert']+ 'para satisfacer la promoción.',key:'holi'})
        productoActual['avisado'] = false
        return
      }
    }
    
    let productotoAdd = {
      nombre:'',
      descripcion:'',
      comentario:'',
      precio:'',
      cantidad:'',
      folder:'',
      img:'',
      descuentoEnPesos:'',
      dependenciaPromocionMETA:'-1',
      dependenciaPromocion:'-1',
      dependenciaProducto:'-1',
      key:'',
      metakey:'',
      hijo:{},
      tipoPromo:0,
      minimoPromo:1,
      detallePromocion:-1,
      detallePromocionMETA:-1,
    }

    let tipoAdd = Number(productoActual['USUAR_VARIENTES'])

    if(tipoAdd===2){//productNormal

      let comentario = document.getElementById('inputComentario').value.trim()
      productotoAdd['nombre'] = productoActual['NOMBRE']
      productotoAdd['descripcion'] = productoActual['DESCRIPCION']
      productotoAdd['cantidad'] = productoActual['cantidad']
      productotoAdd['folder'] = productoActual['FOLDER']
      productotoAdd['img'] = productoActual['IMAGEN']
      productotoAdd['key'] = productoActual['ID']
      productotoAdd['metakey'] = productoActual['METAID']
      productotoAdd['precio'] = Number(productoActual['PRECIO'])
      productotoAdd['comentario'] = comentario

      if(bandera===1&&productoActual['ID_PROMOCIONES'].length>0&&productoActual['indexPromoElegida']===-1){//tiene promociones pero quise guardar sin elegir
        this.setState({modalwithPromo:true})
        return
      }
      else if( (productoActual['ID_PROMOCIONES'].length>0&&productoActual['indexPromoElegida']===-1) || productoActual['ID_PROMOCIONES'].length === 0){//tiene promociones pero no quiero o no tiene promociones
        productotoAdd['descuentoEnPesos'] = 0
        productotoAdd['dependenciaPromocion'] = -1
        productotoAdd['dependenciaProducto'] = -1
      }else if(productoActual['ID_PROMOCIONES'].length>0){//tiene promociones y si elegi una
        let promoElegida = productoActual['ID_PROMOCIONES'][productoActual['indexPromoElegida']]
        let tipo = Number(promoElegida['TIPO'])
        
        productotoAdd['dependenciaPromocion'] = promoElegida['ID']
        productotoAdd['dependenciaPromocionMETA'] = promoElegida['METAID']
        productotoAdd['dependenciaProducto'] = -1
        productotoAdd['tipoPromo'] = tipo

        if(tipo===2){//descuento directo
          productotoAdd['detallePromocion'] = promoElegida['DETALLES'][0]['ID']
          productotoAdd['detallePromocionMETA'] = promoElegida['DETALLES'][0]['METADETALLE']
          productotoAdd['descuentoEnPesos'] = Number(productoActual['descuentoEnPesos'])
        }else if(tipo===3){
          let indexPromoElegida = productoActual['indexPromoElegida']
          let subindexPromoElegida = productoActual['subindexPromoElegida']
          let promoElegida = productoActual['ID_PROMOCIONES'][indexPromoElegida]
          let detallePromo = promoElegida['DETALLES'][subindexPromoElegida]

          productotoAdd['descuentoEnPesos'] = 0
          productotoAdd['minimoPromo'] = productoActual['MINIMO_PROMO']
          productotoAdd['cantidad'] = productoActual['cantidad']
          productotoAdd['detallePromocion'] = detallePromo['ID']
          productotoAdd['detallePromocionMETA'] = detallePromo['METADETALLE']

          productotoAdd['hijo'] = this.getProductoHijo(productos,detallePromo['HIJO']['METAID'])
          productotoAdd['hijo']['descuentoEnPesos'] = Number(productoActual['hijo']['precio'])

          productotoAdd['hijo']['minimoPromo'] = productoActual['MINIMO_PROMO_HIJO']
          productotoAdd['hijo']['cantidad']  = productoActual['hijo']['cantidad']
          productotoAdd['hijo']['detallePromocion'] = detallePromo['HIJO']['ID']
          productotoAdd['hijo']['detallePromocionMETA'] = detallePromo['HIJO']['METADETALLE']
        }else if(tipo===4){
          let indexPromoElegida = productoActual['indexPromoElegida']
          let subindexPromoElegida = productoActual['subindexPromoElegida']
          let promoElegida = productoActual['ID_PROMOCIONES'][indexPromoElegida]
          let detallePromo = promoElegida['DETALLES'][subindexPromoElegida]

          productotoAdd['descuentoEnPesos'] = 0
          productotoAdd['minimoPromo'] = productoActual['MINIMO_PROMO']
          productotoAdd['cantidad'] = productoActual['cantidad']
          productotoAdd['detallePromocion'] = detallePromo['ID']
          productotoAdd['detallePromocionMETA'] = detallePromo['METADETALLE']
          
          productotoAdd['hijo'] = this.getProductoHijo(productos,detallePromo['HIJO']['METAID'])
          productotoAdd['hijo']['minimoPromo'] = productoActual['MINIMO_PROMO_HIJO']
          productotoAdd['hijo']['cantidad']  = productoActual['hijo']['cantidad']
          productotoAdd['hijo']['detallePromocion'] = detallePromo['HIJO']['ID']
          productotoAdd['hijo']['detallePromocionMETA'] = detallePromo['HIJO']['METADETALLE']

          productotoAdd['hijo']['descuentoEnPesos'] = productoActual['hijo']['descuentoEnPesos']

        }
      }
    }else{//producto con variantes
      if(bandera===1){
        if(productoActual['ID_PROMOCIONES'].length>0&&productoActual['indexPromoElegida']===-1){
          this.setState({modalwithPromo:true})
          return
        }
      }else{
        //productotoAdd = 
      }
    }

    const {actualizaCarritoContador,eliminarProductoFromCarritoFromPerfil} = this.props

    let {indexB,indexE,indexS,} = this.getindexes(sucursales[indexSucursal],detalle)
    
    if(productoFromCarrito.hasOwnProperty('metakey')){
      eliminarProductoFromCarritoFromPerfil(indexE,indexS,productoFromCarrito)
    }

    let {carrito,carritoUsuario,carritoEmpresa,carritoSucursal} = this.getCarritoSucursal(sucursales[indexSucursal],detalle)

    carritoSucursal['PRODUCTOS'] = this.addProductoToCar(carritoSucursal['PRODUCTOS'],productotoAdd,productoActual['avisado'])

    carritoEmpresa['SUCURSALES'][indexS] = carritoSucursal
    carritoUsuario['EMPRESAS'][indexE] = carritoEmpresa
    carrito[indexB] = carritoUsuario

    localStorage.setItem('carrito',JSON.stringify(carrito))
    actualizaCarritoContador()

    if(Number(productoActual['STOCK_ACT'] === 0))return
    let imgCarro = document.getElementById('imgPerfilProductoActual'+tipoAdd)
    if(imgCarro)
      imgCarro.classList.remove('igmError')
    
    if(tipoAdd===2){
      productoActual = {cantidad:1,precio:'',total:'', VARIANTES:[],ID_PROMOCIONES:[],indexPromoElegida:-1}
      this.setState({modalVariantes:false,modalwithPromo:false,carritoEmpresa:carritoSucursal,productoActual,productoFromCarrito:{}})
    }

    if(tipoAdd===1){
      productoActualAuxciliar = {cantidad:1,precio:'',total:'', VARIANTES:[],ID_PROMOCIONES:[],indexPromoElegida:-1}
      this.setState({modalVariantes2:false,modalwithPromo:false,productoActualAuxciliar})
    }
  }

  addProductoToCar(listaProductos,nuevoProducto,avisado){
    let toAdd = [nuevoProducto],
    modulo = parseInt(nuevoProducto['cantidad']/nuevoProducto['minimoPromo'],10)//numero de paquetitos
    if(avisado){//avisado significa que, 2A->1B, dados 4A y con solo 1B en estock, se le avisaba al usuario si queria seguir agreando A, auque su cantidad de B no aumentara aun teniendo pormoción elegida.
      let cantidadHijo = nuevoProducto['hijo']['cantidad']
      let newmodulo = cantidadHijo/nuevoProducto['hijo']['minimoPromo']//calculando los paquetes completos, dados por la cantidad de productos hijo en el stock
      let conPromo = nuevoProducto['minimoPromo'] * newmodulo
      let sinPromo = nuevoProducto['cantidad'] - conPromo
      if(conPromo>0){//si alcanza a comprar al menos un paquete de la promocion
        toAdd[0]['cantidad']=conPromo
        if(sinPromo>0){
          let nuevo = this.flatProducto(JSON.parse(JSON.stringify(nuevoProducto)))
          nuevo['cantidad'] = sinPromo
          toAdd.push(nuevo)
        }
      }
      else{//si NO alcanza a comprar al menos un paquete de la promocion
        toAdd[0] = this.flatProducto(toAdd[0])
        toAdd[0]['cantidad']=sinPromo
      }

    }else{
      let conPromo = nuevoProducto['minimoPromo'] * modulo
      let sinPromo = nuevoProducto['cantidad'] - conPromo
      if(conPromo>0){//si alcanza a comprar al menos un paquete de la promocion
        toAdd[0]['cantidad'] = conPromo
        
        if(sinPromo>0){
          let nuevo = this.flatProducto(JSON.parse(JSON.stringify(nuevoProducto)))
          nuevo['cantidad'] = sinPromo
          toAdd.push(nuevo)
        }
      }
      else{//si NO alcanza a comprar al menos un paquete de la promocion
        toAdd[0] = this.flatProducto(toAdd[0])
        toAdd[0]['cantidad']=sinPromo
      }
    }

    for(let nuevoProductoIndex of toAdd){
      let metaKEY = nuevoProductoIndex['metakey']
      let metaKEY_child =  nuevoProductoIndex['hijo']['metakey'] //nuevoProductoIndex['hijo'].hasOwnProperty('nombre') ? nuevoProductoIndex['hijo']['metakey'] : '';

      let paquetes = listaProductos.filter(p => p['metakey'] === metaKEY )

      if(paquetes.length===0)//no hay ese producto padre
        listaProductos.push(nuevoProductoIndex)
      else{//ya hay producto
        paquetes = paquetes.filter(p => p['hijo']['metakey'] === metaKEY_child)//buscamos en los paquetes ya agregados por la cobinacion padreHijo, aunque hijo puede ser nulo
        if(paquetes.length===0)//no hyay combinacionpadreHijo
          listaProductos.push(nuevoProductoIndex)
        else{//ya hay combinacion de producto padreHijo, solo se debe aumentar la cantidad. NOTESE que hijo puede ser nulo
          let indice = listaProductos.indexOf(paquetes[0])
          listaProductos[indice]['cantidad'] = listaProductos[indice]['cantidad'] + nuevoProductoIndex['cantidad']
          if(nuevoProductoIndex['comentario'].trim()!=='')
            listaProductos[indice]['comentario'] = nuevoProductoIndex['comentario']
          if(listaProductos[indice]['hijo'].hasOwnProperty('nombre')){
            listaProductos[indice]['hijo']['cantidad'] = listaProductos[indice]['hijo']['cantidad'] + nuevoProductoIndex['hijo']['cantidad']
          }
        }
      }
    }

    return listaProductos
  }

  flatProducto(producto){
    producto['hijo'] = {}
    producto['dependenciaPromocion'] = -1
    producto['dependenciaPromocionMETA'] = -1
    producto['descuentoEnPesos'] = 0
    producto['detallePromocion'] = -1
    producto['detallePromocionMETA'] = -1
    producto['minimoPromo'] = 1
    producto['tipoPromo'] = 0
    return producto
  }

  changeCantidad(a){//a [1=Restar, 2=Sumar]
    let {productoActual,detalle,productos,sucursales,indexSucursal,productoFromCarrito} = this.state

    message.config({ top: 50, duration: 4, maxCount: 1, getContainer: ()=>document.querySelector('#forMessageDiv') });

    let nuevaCantidad = a===1 ? productoActual['cantidad'] - 1 : productoActual['cantidad'] + 1
    if(nuevaCantidad<=0)
      return

    if(
        Number(detalle['VENTA_SIN_EXISTENCIAS'])!==1&&
        a===2&&
        Number(productoActual['USAR_STOCK'])=== 1 &&
        Number(productoActual['STOCK_ACT']) < nuevaCantidad
    ){
      message.error({content:"No hay cantidad suficiente en el stock del producto: '"+productoActual['NOMBRE']+"'",key:'holi'})
      return
    }
    
    productoActual['cantidad'] = nuevaCantidad

    let cantidadesVerificadas = this.checkCantidades(productoActual,detalle,productos,sucursales,indexSucursal,productoFromCarrito)

    if(!cantidadesVerificadas['valid']){
      if( cantidadesVerificadas['caso'] !== 1 ){
        productoActual['avisado'] = true
        if(cantidadesVerificadas['caso'] === 3 )
          message.error({content:cantidadesVerificadas['txtAlert']+ 'para satisfacer la promoción.',key:'holi'})
      }else{
        message.error({content:cantidadesVerificadas['txtAlert'],key:'holi'})
        productoActual['avisado'] = false
      }
    }

    this.setState({productoActual})
  }

  showTextArea(id){
    document.getElementById(id).style.opacity='1'
    document.getElementById(id).style.height ='auto'
  }

  copyText(id){
    try{
      let div2 = document.getElementById(id)
      let selection = window.getSelection();
      let range = document.createRange();
      range.selectNodeContents(div2);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
    }
     catch(e) {
       console.log(e)
    }
  }

  verifyHorarios(){
    let {indexSucursal,sucursales} = this.state
    let horarios = sucursales[indexSucursal]['horarios']
    let aux = { abre:horarios['abre'][0], cierra:horarios['cierra'][0], status:horarios['status'][0] }
    let newaux = {},dias=['Lunes','Martes','Miércoles','Jueves','Viernes','Sábado','Domingo','']
    let start='Lunes', end=''

    let fragmentos = []

    for(let c=0;c<7;c++){
      newaux = { abre:horarios['abre'][c], cierra:horarios['cierra'][c], status:horarios['status'][c] }

      if(aux['abre'] === newaux['abre'] && aux['cierra'] === newaux['cierra'] && aux['status'] === newaux['status'] ){
        if(c===6){

          if(aux['status']==='ABIERTO'){
            fragmentos.push(
              <React.Fragment key={'fragentHorariosHijo_'+c} >
                <span> {start +' -'} Domingo </span>
                <label> {newaux['abre']} - { newaux['cierra']} </label>
              </React.Fragment>
            )
          }
        }
        end = dias[c]
      }else{
        if(aux['status']==='ABIERTO'){
          fragmentos.push(
            <React.Fragment key={'fragentHorariosHijo_'+c} >
              {
                start!==end?
                <span> {start +' - '+end} </span>:
                <span> {start}</span>
              }
              <label> {aux['abre']} - { aux['cierra']} </label>
            </React.Fragment>
          )
        }
        if(newaux['status']==='ABIERTO'){
          if(c===6){
            fragmentos.push(
              <React.Fragment key={'fragentHorariosHijo_'+c} >
                <span> Domingo </span>
                <label> {newaux['abre']} - { newaux['cierra']} </label>
              </React.Fragment>
            )
          }
          else{}
        }
        start = dias[c]
        end = ''
        aux = { abre:horarios['abre'][c], cierra:horarios['cierra'][c], status:horarios['status'][c] }
      }
      end = dias[c]
    }
    return(
      <div className="contentHorarios" key={'fragentHorariosPadre'}>
        {fragmentos}
      </div>
    )
  }

  showMoreResenas(){
    let {factorResenas, resenasFiltradas, resenas} = this.state
    factorResenas+=5
    resenasFiltradas = resenas.slice(0,factorResenas)
    this.setState({resenasFiltradas,factorResenas})
  }

  showLessresenas(){
    let {factorResenas, resenasFiltradas, resenas} = this.state
    factorResenas-=5
    resenasFiltradas = resenas.slice(0,factorResenas)
    this.setState({resenasFiltradas,factorResenas})
  }

  FiltrarProductos(e){
    let {productos,productosFiltrados,arrayCategoriasiD,categoriaActual,sucursales,indexSucursal} = this.state
    let valor = e.target.value
    valor = valor.trim().toLowerCase()
    if(valor===''){
      productosFiltrados = productos.filter(producto => producto['EXISTENCIA_TOTAL'].includes(sucursales[indexSucursal]['NOMBRE']) )
      productosFiltrados = groupBy(productosFiltrados,'CATEGORIA')
      arrayCategoriasiD = Object.entries(productosFiltrados).map(([categoria,_])=> 'cat_'+categoria.split(' ').join('_') )
      categoriaActual = arrayCategoriasiD.length>0? arrayCategoriasiD[0] : ''
    }
    else{
      productosFiltrados = productos.filter(producto => producto['EXISTENCIA_TOTAL'].includes(sucursales[indexSucursal]['NOMBRE']) )
      productosFiltrados = productos.filter(producto => producto['NOMBRE'].trim().toLowerCase().includes(valor) )
      productosFiltrados = groupBy(productosFiltrados,'CATEGORIA')
      arrayCategoriasiD = Object.entries(productosFiltrados).map(([categoria,_])=> 'cat_'+categoria.split(' ').join('_') )
      categoriaActual = arrayCategoriasiD.length>0? arrayCategoriasiD[0] : ''
    }
    this.setState({productosFiltrados,arrayCategoriasiD,categoriaActual})
  }

  controlState(a,b){
    this.setState({[a]:b})
  }

  showModalCitas(){
    this.setState({visibleCitas:true})
    this.controlCitas.actualizarCitas()
  }

  constPromo(titulo,des,indice,subindice){
    return(
      <div className="itemPromo" key={'promo_'+indice+'_'+subindice} onClick={()=>this.setIndexPromo(indice,subindice)} >
                    
        <div className="flexcenter Fstart">
          
          <div className="selector flexcenter Fstart">
          {
            indice===this.state.productoActual['indexPromoElegida'] && subindice === this.state.productoActual['subindexPromoElegida'] ? 
            <div className='radioFake selected' />:
            <div className='radioFake' />
          }
          </div>

          <div>
            <div className="titulo"> {titulo} </div>
            <div className="desc" title={des}> {des} </div>
          </div>

        </div>
      </div>
    )
  }

  getInfoPromo(indice,promo){
    let {indexSucursal,sucursales} = this.state

    if(! promo['ID_SUCURSAL'].includes(sucursales[indexSucursal]['METAID']) )
      return null;

    let titulo =''
    let des = ''
    let dem, dep,descuento=''

    if(Number(promo['TIPO'])===2){
      let detalle = promo['DETALLES'][0]
      dem = Number(detalle['DESCUENTO_MONETARIO'])
      dep = Number(detalle['DESCUENTO_PORCENTAJE'])
      titulo=null
      if(dem>0)
        des = `$${dem} MXN de descuento.`
      if(dep>0)
        des = `${dep}% de descuento.`
      return(
        this.constPromo(titulo,des,indice,-1)
      )

    }
    if(Number(promo['TIPO'])===3){//recompensa gratis
      let detalles = []
      let i = 0
      for(let detalle of promo['DETALLES']){
        let nombre = detalle['HIJO']['NOMBRE'].substring(detalle['HIJO']['NOMBRE'].indexOf(':')+1)
        let txt = detalle['HIJO']['SAME'] ? 
          `Compra ${detalle['CANTIDAD']} y llévate ${detalle['HIJO']['CANTIDAD']}.` : //mismo hijo
          `En la compra de ${detalle['CANTIDAD']} , llévate GRATIS ${detalle['HIJO']['CANTIDAD']} "${nombre}".` // no mismo hijo
        detalles.push(
          this.constPromo(promo['TITULO'],txt,indice,i)
        )
        i++
      }
      return( 
        <React.Fragment key={'fagment_'+indice}>
          {detalles}
        </React.Fragment>
      )
    }

    if(Number(promo['TIPO'])===4){
      let detalles = []
      let i = 0
      for(let detalle of promo['DETALLES']){
        dem = Number(detalle['HIJO']['DESCUENTO_MONETARIO'])
        dep = Number(detalle['HIJO']['DESCUENTO_PORCENTAJE'])
        let nombre = detalle['HIJO']['NOMBRE'].substring(detalle['HIJO']['NOMBRE'].indexOf(':')+1)
        descuento=''
        if(dem>0)
          descuento =  `$${formatNumber(dem,2)} MXN`
        if(dep>0)
          descuento = `${dep}%`

        let txt =  '' 
        if(detalle['HIJO']['SAME']){     //mismo hijo
          if(dep>0) //Existe descuento porcentual
            txt = `En la compra de ${detalle['CANTIDAD']}, llévate ${detalle['HIJO']['CANTIDAD']} más con un ${descuento} de descuento.`
          else
            txt = `En la compra de ${detalle['CANTIDAD']}, llévate ${detalle['HIJO']['CANTIDAD']} más con ${descuento} de descuento.`
        }else{        //no mismo hiji
          if(dep>0)//Existe descuento porcentual
            txt = `En la compra de ${detalle['CANTIDAD']}, llévate con un ${descuento} de descuento ${detalle['HIJO']['CANTIDAD']} "${nombre}".`
          else
            txt = `En la compra de ${detalle['CANTIDAD']}, llévate con ${descuento} de descuento ${detalle['HIJO']['CANTIDAD']} "${nombre}".`

        }

        detalles.push(
          this.constPromo(promo['TITULO'],txt,indice,i)
        )
        i++
      }
      return(
        <React.Fragment key={'fagment_'+indice}>
          {detalles}
        </React.Fragment>
      )
    }
  }

  getInfoPromo2(){
    let {productoActual} = this.state
    let promo = productoActual['ID_PROMOCIONES'][productoActual['indexPromoElegida']]

    let indiceHijo, detalle, msj = ''
    if(Number(promo['TIPO'])===2){
      detalle = promo['DETALLES'][0]
      let dem = Number(detalle['DESCUENTO_MONETARIO'])
      let dep = Number(detalle['DESCUENTO_PORCENTAJE'])
      if(dem>0)
        msj = `$${formatNumber(dem,2)} MXN de descuento`
      if(dep>0)
        msj = `${dep}% de descuento.`
    }else if(Number(promo['TIPO'])===3){
      indiceHijo = productoActual['subindexPromoElegida']
      detalle = promo['DETALLES'][indiceHijo]
      
      let nombre = detalle['HIJO']['NOMBRE'].substring(detalle['HIJO']['NOMBRE'].indexOf(':')+1)
      msj = detalle['HIJO']['SAME'] ? 
          `Compra ${detalle['CANTIDAD']} y llévate ${detalle['HIJO']['CANTIDAD']}.` : //mismo hijo
          `En la compra de ${detalle['CANTIDAD']} , llévate GRATIS ${detalle['HIJO']['CANTIDAD']} "${nombre}".` // no mismo hijo

    }else if(Number(promo['TIPO'])===4){
      indiceHijo = productoActual['subindexPromoElegida']
      detalle = promo['DETALLES'][indiceHijo]
      let dem = Number(detalle['HIJO']['DESCUENTO_MONETARIO'])
      let dep = Number(detalle['HIJO']['DESCUENTO_PORCENTAJE'])
      let descuento=''

      if(dem>0)
        descuento =  `${formatNumber(dem,2)} MXN.`
      if(dep>0)
        descuento = `${dep}%.`
      
      let nombre = detalle['HIJO']['NOMBRE'].substring(detalle['HIJO']['NOMBRE'].indexOf(':')+1)

      if(detalle['HIJO']['SAME']){     //mismo hijo
        if(dep>0) //Existe descuento porcentual
          msj = `En la compra de ${detalle['CANTIDAD']} llévate ${detalle['HIJO']['CANTIDAD']} más con un ${descuento} de descuento.`
        else
          msj = `En la compra de ${detalle['CANTIDAD']} llévate ${detalle['HIJO']['CANTIDAD']} más con ${descuento} de descuento.`
      }else{        //no mismo hiji
        if(dep>0)//Existe descuento porcentual
          msj = `En la compra de ${detalle['CANTIDAD']}, llévate con un ${descuento} de descuento ${detalle['HIJO']['CANTIDAD']} "${nombre}".`
        else
          msj = `En la compra de ${detalle['CANTIDAD']}, llévate con ${descuento} de descuento ${detalle['HIJO']['CANTIDAD']} "${nombre}".`
      }

    }
    return msj
  }

  setIndexPromo(indice,subindice, force=false ){
    let {productoActual} = this.state    
    productoActual['indexPromoElegida'] = indice
    productoActual['subindexPromoElegida'] = subindice
    if(force){
      productoActual['indexPromoElegidaPre'] = indice
      productoActual['subindexPromoElegidaPre'] = subindice
      productoActual['showAdvise'] = false
    }
    this.setState({productoActual})
  }

  savePromoElegida(checking = false){
    let {productoActual,detalle,productos,sucursales,indexSucursal,productoFromCarrito} = this.state

    if( productoActual['indexPromoElegida'] === -1){
      this.setState({modalAlert:true,txtAlert:'Debes elegir una promoción'})
      return
    }
    productoActual['indexPromoElegidaPre'] = productoActual['indexPromoElegida']
    productoActual['subindexPromoElegidaPre'] = productoActual['subindexPromoElegida']

    let cantidadesVerificadas = this.checkCantidades(productoActual,detalle,productos,sucursales,indexSucursal,productoFromCarrito)

    if(!cantidadesVerificadas['valid']){
      if( cantidadesVerificadas['caso'] !== 1 ){
        productoActual['avisado'] = true
        if(cantidadesVerificadas['caso'] === 3 )
          message.error({content:cantidadesVerificadas['txtAlert']+ 'para satisfacer la promoción.',key:'holi'})
      }else{
        message.error({content:cantidadesVerificadas['txtAlert'],key:'holi'})
        productoActual['avisado'] = false
      }
    }


    this.setState({modalPromociones:false,productoActual})
    return 1;
  }

  nosavePromoElegida(){
    let {productoActual} = this.state
    productoActual['indexPromoElegida'] = productoActual['indexPromoElegidaPre']
    productoActual['subindexPromoElegida'] = productoActual['subindexPromoElegidaPre']
    this.setState({productoActual,modalPromociones:false})
  }

  isInCarrito(metaID){
    let {carritoEmpresa} = this.state
    let {withUser} = this.props
    if(!withUser)return 0;
    let a = carritoEmpresa['PRODUCTOS'].reduce((total,producto)=>{
      if(producto['metakey']===metaID)
        total += Number(producto['cantidad'])
      if(producto['hijo'].hasOwnProperty('metakey') && producto['hijo']['metakey'] === metaID)
        total += Number(producto['hijo']['cantidad'])
      return total
    },0)
    return a
  }

  contactarEmpresa(){
    let {detalle,whatsapp,productoActual} = this.state
    let metadata = JSON.parse(sessionStorage.getItem('metadata'))
    metadata = metadata['datos']
    let number = whatsapp
    let empresa = detalle['NOMBRE_COMERCIAL']
    if(number.length>10)
      number = number.slice(0,9)
    let url = `https://wa.me/521${number}?text=¡Hola%20${empresa},%20soy%20${metadata['nombre']}!,%20te%20contacto%20desde%20BuBook%20Mx%20para%20solicitar%20información%20sobre%20${productoActual['NOMBRE']}.%20Mis%20datos%20de%20contacto%20son:%0A*Nombre:* ${metadata['nombre']+' '+metadata['apellido']}%0A*Teléfono:* ${metadata['telefono']}%0A*Correo:* ${metadata['mail']}`
    window.open(url,'_blank')
  }


  getInfoEnvios(sucursal){
    let array = sucursal['COSTOS_ENVIO'].split('-')
    array = unique(array).filter(item => item.trim()!=='')
    if(array.length>0){
      return(
        <React.Fragment>
          <div className="title"> <img alt="..." src={Imgurl+'conEnvio.png'} style={{height:'18px'}} /> Servicio a domicilio </div>
          {
            array.map((item,i) => (
              <span key={'item_'+i} className="greyTag" > { Number(item)===0 ? "Gratis"  : '$'+formatNumber(item,2)+' MXN' } </span>
            ))
          }
        </React.Fragment>
      )
    }else{
      return(
        <React.Fragment>
          <div className="title"> <img alt="..." src={Imgurl+'metodosEntrega.png'}/> Servicio a domicilio </div>
          <span className="greyTag" > Solo sucursal </span>
        </React.Fragment>
      )
    }
  }

  reportarEmpresa(){
    let {openModalReport} = this.props
    let {detalle} = this.state
    openModalReport(1,{
      use:true,
      tipoR:6,
      hideMessage:{id:detalle['ID'],nombre:detalle['NOMBRE_COMERCIAL']}
    },false)
  }

  formatDireccion(sucursal){
    if(sucursal===undefined || sucursal===null)return ''
    return `${sucursal['CALLE']} #${sucursal['NUMEXTERIOR']} ${sucursal['NUMINTERIOR']!==''?'Int. '+sucursal['NUMINTERIOR']:''}, Col. ${sucursal['COLONIA']}, C.P. ${sucursal['CODPOSTAL']}, ${sucursal['MUNICIPIO']}, ${sucursal['ESTADO']} `
  }

  getLabelToCarrito(producto){
    console.log('--------------------------------------------')
    console.log('Padre precio: '+producto['PRECIO'])
    console.log('Padre descuentoEnPesos: '+producto['descuentoEnPesos'])
    console.log('Padre cantidad: '+producto['cantidad'])
    console.log('********************************************')
    console.log('Padre tipoPromo: '+producto['tipoPromo'])
    console.log('********************************************')
    console.log('Hijo precio: '+producto['hijo']['precio'])
    console.log('Hijo descuentoEnPesos: '+producto['hijo']['descuentoEnPesos'])
    console.log('Hijo cantidad: '+producto['hijo']['cantidad'])
    console.log('--------------------------------------------')

    if(producto['tipoPromo']===4){
      let a = (producto['PRECIO']-producto['descuentoEnPesos'])*producto['cantidad']
      let b = (producto['hijo']['precio']*producto['hijo']['cantidad']) - producto['hijo']['descuentoEnPesos']
      return formatNumber( a+b, 2 )
    }

    return  formatNumber(
              (producto['PRECIO']-producto['descuentoEnPesos'])*producto['cantidad']+
              (producto['hijo']['precio']-producto['hijo']['descuentoEnPesos'])*producto['hijo']['cantidad'],
              2
            )
  }

  showButton(indexPromoElegida,ID_PROMOCIONES,metaIDSucursal){
    let promosAux = ID_PROMOCIONES.filter(promo => promo['ID_SUCURSAL'].includes(metaIDSucursal))
    let a = ( 
      promosAux.length > 1 ||
      (promosAux.length ===1 && promosAux[0]['DETALLES'].length>1 )
    )
    let b = (
      promosAux.length === 1 && 
      Number(promosAux[0]['TIPO']) === 3 &&
      promosAux[0]['DETALLES'].length===1 && 
      indexPromoElegida === -1
    )
    
    let c = (
      promosAux.length === 1 && 
      Number(promosAux[0]['TIPO']) === 4 &&
      promosAux[0]['DETALLES'].length===1
    )
    return a || b || c
  }

  changeBanner(a){
    clearInterval(this.timer)
    if(a>0) this.carrou.next()
    else this.carrou.prev()
  }

  render() {
    return (
      <div className="ModalContainIndex">

        <Loading loading={this.state.loading} />

        <LoadingSpinner text={this.state.txtSpinner} visible={this.state.modalSpinner} />
        <ModalAlert text={this.state.txtAlert} visible={this.state.modalAlert} onOk={ ()=>this.setState({modalAlert:false,txtAlert:''}) } />

        <ControlCitas visible={this.state.visibleCitas} handleSelf={this.controlState} withUser={this.props.withUser} config={this.state.controlCitasActual} makeRef={element => this.controlCitas=element } />

        {/*<Modal centered title="" maskClosable={false} keyboard = {false} 
            okText="Explorar más empresas"
            okButtonProps={{ className:'btn btn1' , style: { width:'auto',} }}
            cancelButtonProps={{style: { display:'none'} }}
            visible={this.state.modalEmpresaNotFound}
            closable={false}
            onOk={()=> {this.setState({modalEmpresaNotFound:false}) ; this.props.indiceRedirecter('/')} }
            className="modalBaseBuBooker ftrblack"
            width={450}
            style={{background:'blue!important'}} >
            <div className="selectFind">
              
              <div className="head flexcenter Fcenter" >
                <img src={`${img2}iconos/bubook_blanco.png`} alt="..." className="icon-alert"/>
              </div>

              <div className="body" style={{width:'450px'}} >
                <h4 style={{textAlign:'center'}} > ¡Ops, no tenemos nada para mostrar! </h4>
                <div style={{textAlign:'center'}} >
                  <img src={ImgPanel+'nonproductos.png'} alt="..."  width={300} />
                </div>

                <label style={{display:'block',textAlign:'center'}}> {this.state.txtNotFound} </label>

                <div className="cascaderWithIcon inverted flexcenter Fstart">
                </div>
              </div>

            </div>
        </Modal> */}

        <NotFound 
          visible={this.state.modalEmpresaNotFound}
          title="¡Ops, no tenemos nada para mostrar!"
          txtNotFound={this.state.txtNotFound}
          okText="Explorar más empresas"
          onOk={()=> {this.setState({modalEmpresaNotFound:false}) ; this.props.indiceRedirecter('/')} }
        />

        <Modal centered title=""
            //maskClosable={false}
            keyboard = {false}
            closable={false}
            header={null}
            footer={null}visible={this.state.modalInfo}
            onCancel={()=>this.setState({modalInfo:false})}
            className="nodalRegistroStripe mxw800"
            zIndex={5}
            wrapClassName="nodalRegistroStripePapa">
            
            <div className="infoEmpresaModal">
              
              <div className="divmap">
                <GoogleMapReact
                  bootstrapURLKeys={{key: gmapsKey}}
                  ref={ ref => {this.mimapa2 = ref} }
                  draggable={true}
                  center={this.state.center}
                  defaultZoom={15}
                  >
                  {
                    this.state.arrayMarkas.map((m,i) => (
                      <Mark key={'mark_'+m['nombre']} id={'mrk_'+i} src={this.state.detalle['FOLDER']+this.state.detalle['DIRECCION_IMG_PERFIL']+'.png'} lat={m['lat']} lng={m['lng']} text={m['nombre']} visible={(m.lat === this.state.center['lat'] && m.lng === this.state.center['lng'])&&this.state.modalInfo}  />
                    ))
                  }
                </GoogleMapReact>
                <div className="itemSelecttor">
                  <span className="label">
                    Sucursal
                  </span>

                  <Select className="inputs-input" value={this.state.indexSucursal} onChange={(a) => this.changeSucursal(a) } >
                  {
                    this.state.sucursales.map((s,i)=>(
                      <Select.Option value={i} key={s['NOMBRE']} > {s['NOMBRE']} </Select.Option>
                    ))
                  }
                  </Select>
                </div>
                
                <Icon type="close" className="iconCloseModal" onClick={()=>this.setState({modalInfo:false})} />

              </div>

              <div className="divUbicacion flexcenter Fcenter">
                {this.formatDireccion(this.state.sucursales[this.state.indexSucursal])}
              </div>

              <div className="content">
                
                <div className="flexcenter Fspaceb titleShare">
                  <span> {this.state.detalle['NOMBRE_COMERCIAL']} </span>
                  <div>
                    <div className="Share-Popover">
                      <Tooltip title={this.formatTelefono(this.state.telefono)} trigger="hover"> <div title="Teléfono" className="telefono"> <Telefono/></div></Tooltip>
                      <Tooltip title={<span id="textToCopy2">{this.state.link}</span>} trigger="hover"> <div title="Copiar link" onClick={()=>this.copyText('textToCopy2')} className="share"> <Compartir /> </div> </Tooltip>
                      {
                        this.state.facebook!==''&&<Tooltip title={this.state.facebook} trigger="hover"> <div title="Facebook" className="facebook" onClick={()=>this.goToFacebook(this.state.facebook)} > <Facebook/> </div> </Tooltip>
                      }
                      {
                        this.state.instagram!==''&&<Tooltip title={this.state.instagram} trigger="hover"> <div title="Instagram" className="instagram" onClick={()=>this.goToInstagram(this.state.instagram)} > <img alt="..." src={Imgurl+'instagram.png'} /> </div> </Tooltip>
                      }
                      <Tooltip title={this.formatTelefono(this.state.whatsapp)} trigger="hover"> <div title="WhatsApp" className="whats" onClick={()=>this.goToWhats(this.state.whatsapp,this.state.detalle['NOMBRE_COMERCIAL'])} > <Whatsapp/> </div> </Tooltip>
                    </div>
                  </div>
                </div>

                {
                  this.state.detalle['DESCRIPCION']!==''&&
                  <MiDiv class="metaDesc" content={this.state.detalle['DESCRIPCION']} myid={'descRamdom_'} title={this.state.descripcion} />
                }


                <div className="flexcenter Fstart strellas">
                  {this.state.Puntuacionnum} 
                  <Estrella/>
                  ({this.state.resenas.length} Reseñas)
                </div>

                <div className="positiveRese"> {this.state.resenasPositivas} </div>

                <br/>

                <div className="flextop Fspaceb">
                  <div className="metaInfo">
                    <div className="title"> <img alt="..." src={Imgurl+'reloj.png'} /> Horarios de atención </div>
                    {
                      this.state.sucursales.length>0&&
                      this.verifyHorarios()
                    }
                  </div>

                  <div className="metaInfo">
                    {
                      this.state.sucursales.length>0?
                      this.getInfoEnvios(this.state.sucursales[this.state.indexSucursal]):
                      <React.Fragment/>
                    }
                  </div>

                  <div className="metaInfo">
                    <div className="title"> <img alt="..." src={Imgurl+'pesos.png'}/>  Métodos de pago </div>
                    <span>
                    {
                      this.state.sucursales.length>0&&
                      this.state.sucursales[this.state.indexSucursal]['TIPOS_PAGO'].split('-').join('\n')
                    }
                    </span>
                  </div>

                </div>

              <br/>

              <div className="flexcenter Fend">
                <button className="buttonMakeReport" onClick={this.reportarEmpresa} > Reportar esta empresa </button>
              </div>

              <div className="section">
                <span className="title"> Calificaciones y reseñas </span>
                <div className="flexcenter Fspaceb">
                  <div>
                    <div className="contBlockPunt">
                      <label className="fwBold" > {this.state.Puntuacionnum} </label>
                    </div>
                    <div className="contBlockPunt">
                      <div className="contImagenes">
                        <img src={ this.state.Puntuacionnum >= 1 ? Imgurl+'estrella_completa2A.png':(this.state.Puntuacionnum>0&&this.state.Puntuacionnum<1) ? Imgurl+'estrella_mitad2A.png' : Imgurl+"estrella_vacia2A.png" } alt="..." />
                        <img src={ this.state.Puntuacionnum >= 2 ? Imgurl+'estrella_completa2A.png':(this.state.Puntuacionnum>1&&this.state.Puntuacionnum<2) ? Imgurl+'estrella_mitad2A.png' : Imgurl+"estrella_vacia2A.png" } alt="..." />
                        <img src={ this.state.Puntuacionnum >= 3 ? Imgurl+'estrella_completa2A.png':(this.state.Puntuacionnum>2&&this.state.Puntuacionnum<3) ? Imgurl+'estrella_mitad2A.png' : Imgurl+"estrella_vacia2A.png" } alt="..." />
                        <img src={ this.state.Puntuacionnum >= 4 ? Imgurl+'estrella_completa2A.png':(this.state.Puntuacionnum>3&&this.state.Puntuacionnum<4) ? Imgurl+'estrella_mitad2A.png' : Imgurl+"estrella_vacia2A.png" } alt="..." />
                        <img src={ this.state.Puntuacionnum >= 5 ? Imgurl+'estrella_completa2A.png':(this.state.Puntuacionnum>4&&this.state.Puntuacionnum<5) ? Imgurl+'estrella_mitad2A.png' : Imgurl+"estrella_vacia2A.png" } alt="..." />
                      </div>
                    </div>
                    <div className="contBlockPunt">
                      <label> {this.state.resenas.length} Reseñas </label>                  
                    </div>
                  </div>
                  <div className="contProgres">
                    <div className="itemProgres">
                      <span className="calResena"> Excelente </span>
                      <Progress strokeColor={'#22B573'} strokeWidth={8} percent={this.state.porcentaje5} showInfo={false} />
                      <span className="canResena"> {this.state.cantidad5} </span>
                    </div>
                    <div className="itemProgres">
                      <span className="calResena"> Muy bueno </span>
                      <Progress strokeColor={'#12E0A5'} strokeWidth={8} percent={this.state.porcentaje4} showInfo={false} />
                      <span className="canResena"> {this.state.cantidad4} </span>
                    </div>
                    <div className="itemProgres">
                      <span className="calResena"> Bueno </span>
                      <Progress strokeColor={'#F77400'} strokeWidth={8} percent={this.state.porcentaje3} showInfo={false} />
                      <span className="canResena"> {this.state.cantidad3} </span>
                    </div>
                    <div className="itemProgres">
                      <span className="calResena"> Regular </span>
                      <Progress strokeColor={'#FF9700'} strokeWidth={8} percent={this.state.porcentaje2} showInfo={false} />
                      <span className="canResena"> {this.state.cantidad2} </span>
                    </div>
                    <div className="itemProgres">
                      <span className="calResena"> Malo </span>
                      <Progress strokeColor={'#ED3B48'} strokeWidth={8} percent={this.state.porcentaje1} showInfo={false} />
                      <span className="canResena"> {this.state.cantidad1} </span>
                    </div>
                  </div>                  
                </div>
              </div>

              {
                (!this.state.miresena) && 
                <div className="flexcenter Fend addDiv"> <u className="flexcenter Fcenter"
                  onClick={
                    ()=>this.verifyUser( () => this.setState({addingResena:true}) )
                  }
                > + Escribir reseña </u> </div>
              }

              <AddResena visible={this.state.addingResena} handleSelf={this.controlState} data={this.state.detalle} resenas={this.state.resenas} factor={this.state.factorResenas} />

              <br/>
              
              <ListaResenas
                data={this.state.resenasFiltradas}
                factor={this.state.factorResenas}
                errorImg={this.errorImg}
                nombreEmpresa={this.state.detalle['NOMBRE_COMERCIAL']}
                folderEmpresa={this.state.detalle['FOLDER']}

                />
          
              {
                this.state.resenas.length>this.state.factorResenas?
                <div className="flexcenter Fcenter">
                  <button className="botonVerMasResenas" title="Ver más reseñas" onClick={this.showMoreResenas} > Ver más </button>
                </div>:
                this.state.factorResenas>5&&
                <div className="flexcenter Fcenter">
                  <button className="botonVerMasResenas" title="Ver menos reseñas" onClick={this.showLessresenas} > Ver menos </button>
                </div>
              }
              

              <div className="spaceMaker" />
            </div>
          </div>
        </Modal>

        <Modal centered closable={false} header={null} footer={null}
            visible={this.state.modalPortada}
            onCancel={()=>this.setState({modalPortada:false})}
            className="modalPortada"
            wrapClassName="modalPortadaPapa">
            <img alt="..." src={this.state.detalle['FOLDER']+this.state.detalle['DIRECCION_IMG_PORTADA']+'.png'} onError={ e => this.errorImg(e,1)} />
        </Modal>

        {/* Modal del detalle de producto con variantes */}

        <Modal centered title=""
            closable={false}
            header={null}
            footer={null} visible={this.state.modalVariantes2}
            className="nodalRegistroStripe mxw800" destroyOnClose
            wrapClassName="nodalRegistroStripePapa" zIndex={5}
            onCancel={()=>this.setState({modalVariantes2:false,})}
            >
            {this.state.modalVariantes2&&
            <div className="detalleProducto contentstripe">

              <div className="contentclose flexcenter Fspaceb" style={{width:'450px',margin:'auto',}} >
                
                <div className="nombre"> {this.state.productoActualAuxciliar['NOMBRE']} </div>

                <Icon type="close" className="iconCloseModal" onClick={()=>this.setState({modalVariantes2:false,}) } style={{marginRight:'0px',}} />

              </div>

              <div className="info ancher">
                {
                  this.state.modalVariantes2&&
                  <MiDiv class="descripcion" myid={'productoModalOpen'} content={this.state.productoActualAuxciliar['DESCRIPCION']} title={this.state.productoActualAuxciliar['DESCRIPCION']} />
                }
              </div>
              
              <div className="listVar">
              {
                this.state.productoActualAuxciliar['VARIANTES'].filter(v => 
                    v['STOCK_PROPIO'].includes( this.state.sucursales[this.state.indexSucursal]['NOMBRE'] )                    
                ).map((variante,i) => (
                  <div key={'varianteInModal_'+i} className="itemVar" onClick={()=>this.showVariantes(variante)} >
                    <div className="flexcenter Fstart">

                      <div className="img">
                        
                        <Badge
                          title="Cantidad en carrito"
                          count={this.isInCarrito(variante['METAID'])}
                          className="myBadge lime"
                          overflowCount={9} dot={false} />

                        <img alt="..." src={this.state.productoActualAuxciliar['FOLDER'] + variante['IMAGEN']+'.png'} onError={e => this.errorImg(e,3)} />
                      </div>

                      <div className="metaVar flexcenter Fspaceb">

                        <div className="inforVar">
                          <span>
                            {variante['NOMBRE']}
                            <i className="iconPromo" title="Producto con promoción">
                              {
                                variante['ID_PROMOCIONES'].some(promo => promo['ID_SUCURSAL'].includes(this.state.sucursales[this.state.indexSucursal]['METAID'] ) ) &&
                                <ConPromocion />
                              }
                            </i>
                          </span>
                          <label> {this.getPrecio(variante)} </label>
                        </div>

                        <button className="detail"> Ver detalles </button>

                      </div>
                    </div>

                  </div>
                ))
              }
              </div>
            </div>
            }
        </Modal>

        {/* Modal del detalle de producto */}

        <Modal centered title=""
            closable={false}
            header={null}
            footer={null}visible={this.state.modalVariantes}
            onCancel={()=>this.closeModalProducto(2)}
            className="nodalRegistroStripe mxw800" zIndex={6}
            wrapClassName="nodalRegistroStripePapa">
            <div className="detalleProducto contentstripe">

              <div className="contentclose flexcenter Fend">
                <Icon type="close" className="iconCloseModal" onClick={()=>this.closeModalProducto(2)} />
              </div>

              <div id="forMessageDiv" />

              <div className="portadaProducto flexcenter Fcenter">

              {
                this.state.modalVariantes &&
                <React.Fragment>
                {
                  this.state.productoActual['IMAGENES'].length>1?
                  <div className="carrousel">
                  
                    <div className="flexcenter Fcenter control cotrolLeft" onClick={()=>this.changeBanner(-1)} >
                      <Icon type="left" />
                    </div>

                    <div className="flexcenter Fcenter control cotrolRigth" onClick={()=>this.changeBanner(1)} id="next" style={{left:'calc(100% - 40px)'}} >
                      <Icon type="right" />
                    </div>

                    <div className="carrouselContent" style={{background:'white'}} >
                      <Carousel className="banner" ref={e => this.carrou = e} effect="fade" >
                      {
                        this.state.productoActual['IMAGENES'].map((c,i) => (
                          <div key={i}  className="holiboli help">
                            <img  className="holiboli2" alt="..." src={this.state.productoActual['FOLDER'] + c['DIRECCION_IMG']+'.png' } />
                          </div>
                        ))
                      }
                      </Carousel>
                    </div>
                    
                  </div>
                  :
                  <img alt="..." id="imgPerfilProductoActual2" src={this.state.productoActual['FOLDER'] + this.state.productoActual['IMAGEN']+'.png'} onError={e => this.errorImg(e,3)} />
                }
                </React.Fragment>
              }              
              </div>

              <div className="info">
                <div className="nombre"> {this.state.productoActual['NOMBRE']} </div>
                {
                  this.state.modalVariantes&&
                  <MiDiv class="descripcion" myid={'productoModalOpen'} content={this.state.productoActual['DESCRIPCION']} title={this.state.productoActual['DESCRIPCION']} />
                }
                <div className="flexcenter Fspaceb">
                {
                  Number(this.state.productoActual['USAR_STOCK'])=== 1 ?
                  <div className="nombre"> Existencia ({this.state.productoActual['STOCK_ACT']}) </div>:
                  <div/>
                }

                {
                  (Number(this.state.productoActual['PRECIO']) >0) &&
                  <div className="preio flexcenter Fcenter">
                    {
                      this.state.productoActual['descuentoEnPesos']>0&&
                      <span className="notvalid"> ${ formatNumber(this.state.productoActual['PRECIO'],2) } MXN</span>
                    }
                    <span> ${formatNumber(( this.state.productoActual['PRECIO'] - this.state.productoActual['descuentoEnPesos'] )  ,2)} MXN </span>
                  </div>
                }

                </div>

                {
                  this.state.productoActual['indexPromoElegida']!==-1 &&
                  <label className="ntaPromo" > {this.getInfoPromo2()} </label>
                }

                {
                  (
                    this.state.productoActual['ID_PROMOCIONES'].some(promo => promo['ID_SUCURSAL'].includes(this.state.sucursales[this.state.indexSucursal]['METAID'] ) )
                    &&
                    (
                      this.showButton(this.state.productoActual['indexPromoElegida'],this.state.productoActual['ID_PROMOCIONES'],this.state.sucursales[this.state.indexSucursal]['METAID'])
                    )
                  )
                  &&
                  <div className="flexcenter Fend">
                    <button className="btnBase btnViewPromo" title="Ver promociones disponibles" onClick={()=>this.setState({modalPromociones:true})} > Ver promociones </button>
                  </div>
                }

                {
                  (Number(this.state.productoActual['PRECIO']) >0) &&
                  <div className="inputs-group">
                    <label className="inputs-label" > Agregar comentario o indicación </label>
                    <Input.TextArea className="inputs-input" id="inputComentario" autoSize={{minRows: 1, maxRows:3,} }  ref={e => this.comentarioproducto = e} >
                    </Input.TextArea>
                  </div>
                }

              </div>

              {
                Number(this.state.productoActual['PRECIO'])>0?
                <div className="flexcenter Fcenter">
                  <div className="controlCantidad flexcenter Fcenter">
                    <div className="ctrl flexcenter Fcenter" onClick={()=>this.changeCantidad(1)}> - </div>
                    <span>{this.state.productoActual['cantidad']}</span>
                    <div className="ctrl flexcenter Fcenter" onClick={()=>this.changeCantidad(2)}> + </div>
                  </div>
                  <div
                    className={
                      "flexcenter Fspaceb btnBase btnAdd " + (
                      ( Number(this.state.productoActual['USAR_STOCK'])===1 && 
                        Number(this.state.productoActual['STOCK_ACT'])===0 &&
                        Number(this.state.detalle['VENTA_SIN_EXISTENCIAS']) !== 1
                      )
                      ?'nostock':'')
                    }
                    onClick={()=>this.agregarAlCarrito(1)}
                  >
                    <span> Agregar {this.state.productoActual['cantidad']} al carrito </span>
                    
                    <label> $ { this.getLabelToCarrito(this.state.productoActual) }MXN
                    </label>

                  </div>
                </div>:
                <div className="flexcenter Fend" style={{paddingRight:'25px'}} >
                  <div className="flexcenter Fcenter btnBase btnAdd" onClick={this.contactarEmpresa} >
                    Contactar
                  </div>
                </div>
              }

              

            </div>
        </Modal>

         {/* Modal lista de promociones */}

        <Modal centered title=""
            closable={false}
            header={null}
            footer={null}visible={this.state.modalPromociones}
            onCancel={this.nosavePromoElegida}
            className="nodalRegistroStripe"
            width={420}
            wrapClassName="nodalRegistroStripePapa">
            <div className="detalleProducto contentstripe noscroll">

              <div className="contentclose withPPE flexcenter Fspaceb">
                <div className="title"> Promociones disponibles  </div>
                <Icon type="close" className="iconCloseModal" onClick={this.nosavePromoElegida} />
              </div>


              <div className="info mgtopNeg">
              {
                this.state.productoActual['ID_PROMOCIONES'].map( (promo,indice) => 
                  this.getInfoPromo(indice,promo)
                )
              }
              </div>

              <div className="btnAdd adpromo flexcenter Fcenter" onClick={this.savePromoElegida} > Usar promoción </div>

            </div>
        </Modal>

      {/* Modal te vas sin promocion */}

        <Modal centered title=""
            closable={false}
            header={null}
            footer={null} visible={this.state.modalwithPromo}
            //onCancel={()=>console.log()}
            className="nodalRegistroStripe"
            width={420}
            wrapClassName="nodalRegistroStripePapa">
            <div className="detalleProducto contentstripe noscroll">

              <div className="contentclose withPPE flexcenter Fspaceb">
                <div className="title"> Promociones disponibles  </div>
                <Icon type="close" className="iconCloseModal" onClick={()=>this.setState({modalwithPromo:false})} />
              </div>

              <div className="info mgtopNeg" style={{marginTop:'10px'}} >
                Este producto cuenta con promociones disponibles
              </div>

              <div className="flexcenter Fspaceb">
                <div className="btnAdd adpromo nosee flexcenter Fcenter" onClick={()=>this.agregarAlCarrito(2)} title="Continuar" > Continuar sin promociones </div>
                <div className="btnAdd adpromo flexcenter Fcenter" onClick={()=>this.setState({modalwithPromo:false, modalPromociones:true})} title="Ver promociones disponibles" > Ver promociones </div>
              </div>

            </div>
        </Modal>

          
        <div className="perfilEmpresa" id="Modal-View">
          

         {/*
           <div id="publi">
           <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
           <ins className="adsbygoogle"
                style={{display:'block'}}
                data-ad-client="ca-pub-3223714067134112"
                data-ad-slot="5616798429"
                data-ad-format="auto"
                data-full-width-responsive="true"></ins>
           <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
           </script>
          </div>
         */}

         {/*
           <pre>
           {
             JSON.stringify(this.state.productoFromCarrito, null, 4)
           }
          </pre>*/
         }

          <div className="portada" id="portada" onClick={()=>this.setState({modalPortada:true})} >
            <img alt="..." src={this.state.detalle['FOLDER']+this.state.detalle['DIRECCION_IMG_PORTADA']+'.png'} onError={ e => this.errorImg(e,1)} />
          </div>

          <div className="blockInfoGral">
            <div className="limiter">
              
              <div className="controlsPerfil flexcenter Fspaceb">
                
                <div className="itemBack flexcenter Fspaceb" onClick={()=>this.props.indiceRedirecter('/')} >
                  <Icon type="left" />
                  <span> Regresar </span>
                </div>

              </div>

              <div className="meta flexcenter Fspaceb">
                
                <div className="perfil flexcenter Fcenter">
                  
                  <div className="imgPerfil">
                    <img alt="..." src={this.state.detalle['FOLDER']+this.state.detalle['DIRECCION_IMG_PERFIL']+'.png'} onError={ e => this.errorImg(e,2)} />
                  </div>

                  <div className="name">
                    <label> {this.state.detalle['NOMBRE_COMERCIAL']} <i onClick={()=>this.setState({modalInfo:true})}><Informacion /> </i> </label>
                    <span> {this.state.detalle['NOMBRE_CATEGORIA']} </span>
                  </div>

                </div>

                <div className="info">

                  <div className="groupIcons flexcenter Fcenter">
                    
                    {
                      this.state.conCita&&<i onClick={()=>this.verifyUser(this.showModalCitas)} > <img src={Imgurl+'calendario.png'} className="calendario" alt="..." /> </i>
                    }

                    <Popover content={
                      <div className="Share-Popover">
                        <Tooltip title={this.formatTelefono(this.state.telefono)} trigger="hover"> <div title="Teléfono" className="telefono"> <Telefono/></div></Tooltip>
                        <Tooltip title={<span id="textToCopy">{this.state.link}</span>} trigger="hover"> <div title="Copiar link" className="share" onClick={()=>this.copyText('textToCopy')} > <Compartir /> </div> </Tooltip>
                        {
                          this.state.facebook!==''&&<Tooltip title={this.state.facebook} trigger="hover"> <div title="Facebook" className="facebook" onClick={()=>this.goToFacebook(this.state.facebook)}> <Facebook/> </div> </Tooltip>
                        }
                        {
                          this.state.instagram!==''&&<Tooltip title={this.state.instagram} trigger="hover"> <div title="Instagram" className="instagram" onClick={()=>this.goToInstagram(this.state.instagram)} > <img alt="..." src={Imgurl+'instagram.png'} /> </div> </Tooltip>
                        }
                        <Tooltip title={this.formatTelefono(this.state.whatsapp)} trigger="hover"> <div title="WhatsApp" className="whats" onClick={()=>this.goToWhats(this.state.whatsapp,this.state.detalle['NOMBRE_COMERCIAL'])} > <Whatsapp/> </div> </Tooltip>
                      </div>
                    } overlayClassName="poopShare" title={null} trigger="click" placement="top" >
                      <i>
                        <Compartir className="compartir" />
                      </i>
                    </Popover>
                    
                    <i id="corazaoID" onClick={()=>this.verifyUser(this.agregarEliminarFavorito)}> <Corazon className={"corazon"+(this.state.isFavorito?' filled':'')} /> </i>
                    
                    <Icon id="loadingID" type="loading" className="loadingTemp" />

                  </div>
                  
                  <div className="resenasNumber">

                    <Estrella className="estrella" />
                    
                    <span className="calif">{Number(this.state.detalle['PUNTUACION']).toFixed(1)}</span>
                    &nbsp;&nbsp;&nbsp;
                    <span>{this.state.resenas.length} reseñas</span>

                  </div>
                  
                </div>
              </div>

              <div className="contentEmpresa flextop Fspaceb">

                <div className="groupSeleCat" >
                  
                  <div className="itemSelecttor">
                    <span className="label">
                      Sucursal
                    </span>
                    <Select className="inputs-input" value={this.state.indexSucursal} onChange={(a) => this.changeSucursal(a) } >
                    {
                      this.state.sucursales.map((s,i)=>(
                        <Select.Option value={i} key={s['NOMBRE']} > {s['NOMBRE']} </Select.Option>
                      ))
                    }
                    </Select>
                    <div className={"horario "+this.state.newHorarioLabel}>
                      {/*this.state.detalleHorario['status']*/ this.state.newHorarioLabel }
                    </div>
                  </div>
                  
                  <div className="inputBusquedaPadre">
                    <div className="labelfilter"> Buscar </div>
                    <div className="inputBusqueda flexcenter Fcenter">
                      <Icon type="search"/>
                      <input type="text" className="inputs-input" id="filtradorPorductos" onChange={this.FiltrarProductos} />
                    </div>
                  </div>

                  <div className="listCategorias">
                  {
                    Object.entries(this.state.productosFiltrados).map(([categoria,_],i) =>(
                      <div key={'categoria_'+i} className={"itemCategoria flexcenter Fstart"+(this.state.categoriaActual==='cat_'+categoria.split(' ').join('_')?' active':'')} id={'IdFor_cat_'+categoria.split(' ').join('_')}  onClick={()=>this.chageCategoria(categoria)} > 
                        {categoria}
                      </div>
                    ))
                  }
                  </div>

                </div>

                <div className="listProductos">
                  <div className="listProductosContent" id="listaProductos" onScroll={this.productosScroll} >
                    {
                      Object.entries(this.state.productosFiltrados).length>0?
                      Object.entries(this.state.productosFiltrados).map(([categoria,productos],i) =>(
                        <React.Fragment key={'bloque_'+i} >
                        <div className={i===0?"titleCategoria nomg":"titleCategoria"} > {categoria} </div>
                        <div key={'categoria_'+i} id={'cat_'+categoria.split(' ').join('_')} className="bloqueDeProductos flexcenter Fstart fww">
                        {
                          productos.map((producto,ind) => (
                            <div className="tarjetaProductoConDesc" key={'producto_'+ind+'cat_'+categoria} onClick={()=>this.verifyUser(this.showVariantes,producto)} >

                              <Badge
                                title="Cantidad en carrito"
                                count={this.isInCarrito(producto['METAID'])}
                                className="myBadge lime fter"
                                overflowCount={9} dot={false} />

                              <div className="productoNombre"> {producto['NOMBRE']}  </div>
                              
                              <div className="flextop Fcenter">
                                <div className="imgTarjeta flextop Fcenter">
                                  <img alt="..." src={producto['FOLDER']+producto['IMAGEN']+'.png'} onError={e => this.errorImg(e,3)} />
                                </div>

                                <div className="infoTarjeta">
                                  <MiDiv class="productoDesc" myid={'idProducto_'+ind} content={producto['DESCRIPCION']} title={producto['DESCRIPCION']} />
                                  <div className="flexcenter Fspaceb iconPrecio">
                                    <i className="iconPromo" title="Producto con promoción">
                                    {
                                      (
                                        producto['ID_PROMOCIONES'].some(promo => promo['ID_SUCURSAL'].includes(this.state.sucursales[this.state.indexSucursal]['METAID'] ) ) ||
                                        producto['VARIANTES'].some(v => 
                                          v['ID_PROMOCIONES'].some(p =>  p['ID_SUCURSAL'].includes(this.state.sucursales[this.state.indexSucursal]['METAID'] )
                                          )
                                        )
                                      ) &&
                                     <ConPromocion />
                                    }
                                    </i>
                                    <div className="productoPrec"> {this.getPrecio(producto)} </div>
                                  </div>

                                </div>
                              </div>

                            </div>
                          ))
                        }
                        </div>
                        </React.Fragment>
                      )):
                      <div className="noResultss"> <img src={ImgPanel+'nonproductos.png'} alt="..." /> </div>
                    }
                  </div>                  
                </div>

              </div>

            </div>
          </div>

        </div>

      </div>
    );
  }
}
export default withRouter(Perfil);