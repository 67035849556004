import React from 'react';
import {ImgGral as img} from './../app.json'

import   './loading.scss';
class Social extends React.Component{
  render(){
    return(
      <React.Fragment>
         <div className="item rs">
          <a target="_blank" rel="noopener noreferrer" href = "https://www.facebook.com/bubook.mx">
            <img src={img+'iconos/facebook.png'} alt="..." /> Facebook 
          </a>
        </div>

        <div className="item rs">
          <a target="_blank" rel="noopener noreferrer" href = "https://www.instagram.com/bubook.mx/">
            <img src={img+'iconos/instagram.png'} alt="..." /> Instagram
          </a>
        </div>

        <div className="item rs">
          <a target="_blank" rel="noopener noreferrer" href = "https://twitter.com/BuBookMx">
            <img src={img+'iconos/twitter.png'} alt="..." /> Twitter
          </a>
        </div>        
      </React.Fragment>
    )
  }
};

export default(Social);