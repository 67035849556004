import React from 'react';

import axios from 'axios';
import 'moment/locale/es'
import moment from 'moment'
import { Globalrequest as request } from './../../../request.js';
import {Host as host ,  Img as img, ImgPanelAdmin as imgAdmin} from './../../../app.json'
import {Modal,Progress, DatePicker} from 'antd';
import {withRouter} from 'react-router-dom'
import Loading from '../../../modalCargando/loading';

import {MiDiv} from '../../../modalCargando/miDiv';

import {userContext} from './../context'
import   './resenias.scss';

class Lista extends React.Component{

  static contextType = userContext
  
  constructor(props) {
    super(props);
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)

    this.showModaNew = this.showModaNew.bind(this)
    this.submit = this.submit.bind(this)

    this.changeSizeTextArea = this.changeSizeTextArea.bind(this)
    this.borrarFiltros = this.borrarFiltros.bind(this)
    this.changeFiltros = this.changeFiltros.bind(this)

    this.state = {
      idNotificacionPorAtender:sessionStorage.getItem('idNotificacionPorAtender'),
      /**/
      loading:false,
      /**/
      rows: 1,
      minRows: 1,
      maxRows: 4,
      /**/
      resenas:[],
      resenas2:[],
      modalAlert:false,
      txtAlert:'',
      nuevaRespuesta:{},
      detalles:{},
      modalResponder:false,
      current:{},
      /**/
      filtro1:null,
      filtro2:null,
      filtro3:0,
      srcBase:'',
    }
  }

  componentDidMount(){
    document.title="Mis reseñas"
    let id = sessionStorage.getItem('id')
    if(id===null || id===undefined){
      return
    }
    let {idNotificacionPorAtender} = this.state
    if(idNotificacionPorAtender !== 'null'){
      let data = {idN:idNotificacionPorAtender,estatus:2}
      axios.post(`${host}clientes/clientes/cambiarEstatusNotificacion`,data,request)
      sessionStorage.setItem('idNotificacionPorAtender','null')
    }
    let metadata = JSON.parse(sessionStorage.getItem('metadata'))
    let srcBase = metadata['folder']+'/Perfil/'+metadata['datos']['DIRECCION_IMG_PERFIL']+'.png'

    this.setState({srcBase})

    this.actualizarCatalogo()
  }

  actualizarCatalogo(){
    let data = {idE:sessionStorage.getItem('id')}
    this.setState({loading:true})
    axios.post(`${host}resenias/resenias/resenasEmpresa`,data,request).then(rt=>{
      let detalles = rt.data['DETALLE']
      let cant = Number(detalles['CANT_RESENIAS'])
      let puntuacion = Number(detalles['PUNTUACION'])
      
      let entero = parseInt(puntuacion)
      let decimal = puntuacion - entero

      if(decimal === 0.5){
        //puntuacion = puntuacion
      }
      else if(decimal < 0.5){
        puntuacion = entero
      }
      else if(decimal > 0.5){
        puntuacion = entero+1
      }
      
      detalles['PORCENTAJE_1'] = 100*(Number( detalles['PUNTUACION_1'] ) / cant)
      detalles['PORCENTAJE_2'] = 100*(Number( detalles['PUNTUACION_2'] ) / cant)
      detalles['PORCENTAJE_3'] = 100*(Number( detalles['PUNTUACION_3'] ) / cant)
      detalles['PORCENTAJE_4'] = 100*(Number( detalles['PUNTUACION_4'] ) / cant)
      detalles['PORCENTAJE_5'] = 100*(Number( detalles['PUNTUACION_5'] ) / cant)
      //detalles['PUNTUACION'] = puntuacion

      this.setState({resenas:rt.data['RESENAS'], resenas2:rt.data['RESENAS'], detalles,loading:false})
    })
  }

  showModaNew(id){
    let {valid,mood,validCurrentUser} = this.props

    if(!validCurrentUser(this.context,'resenas','bool',false,'EDITAR') ){
      this.setState({modalAlert:true,txtAlert:'El perfil actual no  tiene permisos para responder Reseñas',})
      return
    }

    if(!valid){
      if(Number(mood)===1){
        this.setState({modalAlert:true,txtAlert:'No puedes acceder a esta opción porque tu cuenta está con funciones limitadas.'})
      }else{
        this.setState({modalAlert:true,txtAlert:'No puedes acceder a esta opción porque debes tener una suscripción activa.'})
      }
      return
    }

    let {resenas} = this.state
    let current = resenas.filter(r => r.ID === id)
    current = current[0]
    let rows = current.RESPUESTA.split('[/*/r/n*/]').length > 4 ? 4 : current.RESPUESTA.split('[/*/r/n*/]').length
    this.setState({current,modalResponder:true,rows})
  }

  submit(){
    let {current} = this.state
    let response = document.getElementById('responsed').value
    if(response.trim() === ''){
      this.setState({txtAlert:'La respuesta no puede estar vacía',modalAlert:true})
    }
    current['RESPUESTA'] = response

    this.setState({modalResponder:false,loading:true})

    axios.post(`${host}resenias/resenias/agregarResenaRespuesta`,current,request).then(rt=>{
      if(Number(rt.data) === 2){
        this.setState({txtAlert:'La respuesta se registró correctamente',modalAlert:true,loading:false,current:{},resenas:[],resenas2:[]})
        this.actualizarCatalogo()
      }
      else{
        this.setState({txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde [Código de error: SV-ER: #11-01]',modalAlert:true,loading:false,current:{}})
      }
    })
  }

  changeSizeTextArea(e){
    let textareaLineHeight = 14;
    let { minRows, maxRows } = this.state;  
    let previousRows = e.target.rows;
        e.target.rows = minRows;
    let currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);
        if (currentRows === previousRows) { e.target.rows = currentRows; }  
    if (currentRows >= maxRows) {
        e.target.rows = maxRows;
        e.target.scrollTop = e.target.scrollHeight;
    }
    this.setState({
      value: e.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
  }

  borrarFiltros(){
    let {resenas} = this.state
    this.setState({ fecha1 : '', fecha2  : '', filtro3:0,resenas2:resenas})
    setTimeout(this.filtrar,.1);
  }

  changeFiltros(campo,valor){
    let {filtro1,filtro2,filtro3,resenas,resenas2} = this.state
    this.setState({[campo]:valor})
    let filtrosAux = { filtro1:filtro1,filtro2:filtro2,filtro3:filtro3,}
    filtrosAux[campo] = valor
    
    filtro1 = filtrosAux['filtro1']
    filtro2 = filtrosAux['filtro2']
    filtro3 = filtrosAux['filtro3']
    resenas2 = resenas    
                                                                      //2020-11-07 13:21:33
    resenas2 = filtro1 ? resenas2.filter(resena => moment(resena.FECHA,'YYYY-MM-DD HH:mm:ss').isSameOrAfter(filtro1) ) : resenas2
    resenas2 = filtro2 ? resenas2.filter(resena => moment(resena.FECHA,'YYYY-MM-DD HH:mm:ss').isSameOrBefore(filtro2)) : resenas2
    resenas2 = filtro3 === 0 ? resenas2 : resenas2.filter(resena => Number(resena.PUNTUACION) === filtro3)

    this.setState({resenas2:resenas2})
  }

  classToggle(){
    document.getElementById('toggleFiltrarId').classList.toggle('toggleFiltrar2');
    document.getElementById('contControlesId').classList.toggle('contControles2');
  }

  errorImg(e,op){
    if(op===1){
      e.target.src = img+'new_camera_icon_prod.png'
    }
    if(op===2){
      e.target.src= imgAdmin+'perfilDefault.jpg';
    }
  }

  formatFecha(input,formatInput,formatOutput){
    return moment(input,formatInput).format(formatOutput)
  }

  render(){
    return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3">


            <Loading loading={this.state.loading} />

            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' }}
                cancelButtonProps={{ className:'btn btn2', style: {display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalAlert   ?
                        <span className="txtAlert"> {this.state.txtAlert} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            <Modal centered title="Detalle reseña" maskClosable={false} keyboard = {false} 
                okText="Guardar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
                cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
                visible={this.state.modalResponder}
                closable={false}
                className="miAntModal alertMy modalUnidad"
                onCancel={()=>this.setState({modalResponder:false,current:{} })}
                onOk={this.submit}
              >
              <form id="formulaUnidad" style={{margintBottom:'10px'}} >
                {
                    this.state.modalResponder ?
                    <div className="miUniDiv">
                      
                      <div className="resenaModal" style={{marginBottom:'-25px'}} >
                        
                        <div className="perfilModal" >
                         <img src={this.state.current.IMAGEN_CLIENTE }  onError={e => this.errorImg(e,1)} alt="..." />
                        </div>

                        <div className="bodyModal">
                          <div className="dv1">
                            <div className="nom" > {this.state.current.NOMBRE_CLIENTE} </div>
                            <div> {this.formatFecha(this.state.current.FECHA,'YYYY-MM-DD HH:mm:ss','DD/MM/YYYY HH:mm')} </div>
                          </div>
                          <MiDiv renderable class="respuestaResena" content={this.state.current.COMENTARIOS} myid={'EditcomentR_'+this.state.current.ID} />
                        </div>

                      </div>

                        <div className="inputs-group" style={{marginTop:'40px',marginBottom:'25px'}}>
                            <label className="inputs-label" htmlFor="responsed" style={{top:'-10px'}} > Respuesta </label>
                            <textarea
                              rows={this.state.rows}
                              className="inputs-input textareaModal"
                              id="responsed" name="response"
                              maxLength={200}
                              onChange={this.changeSizeTextArea}
                              defaultValue={this.state.current.RESPUESTA.split('[/*/r/n*/]').join('\r\n')}
                              /> 

                        </div>
                    </div>:
                    <React.Fragment/>
                }
              </form>
            </Modal>


            <div className="tituloYControles">
              
              <div className="metaControles">
                <h3 className="tituloPanel">  </h3>
                <span className="toggleFiltrar" id="toggleFiltrarId" onClick={this.classToggle} > Filtrar </span>
              </div>

              <div className="contControles" id="contControlesId">

                <div className="contControlesHijo" id="contControlesHijoId">

                  <div className="filtradosExtras">
                      <div className="inputs-group-inline">
                          <label className="inputs-label" > Fecha inicio </label>
                          <DatePicker showToday={false} format="DD/MM/YYYY" locale='es-MX' name="fecha1" className="MyDate" value={this.state.filtro1} onChange={(value)=>this.changeFiltros('filtro1',value)}  placeholder="" />
                      </div>
                      <div className="inputs-group-inline">
                        <label className="inputs-label" htmlFor="pesopd" >  Fecha fin </label>
                        <DatePicker showToday={false} format="DD/MM/YYYY" locale='es-MX' name="fecha2" className="MyDate" value={this.state.filtro2} onChange={(value)=>this.changeFiltros('filtro2',value)} placeholder="" />
                      </div>
                  </div>
                  
                  <div className="tiposFiltrados tiposFiltrados3">
                    <label className="filTle" > Puntuación otorgada </label>
                    <div className="contEstrellas">
                      <div className="contEstrellasChild">
                        <img
                          src={this.state.filtro3 >=1 ? img+'estrella_completa2.png' : this.state.filtro3 === 0.5 ? img+'estrella_mitad2.png' : img+'estrella_vacia2.png' }
                            className="strella" alt='...'
                            onClick={()=>this.changeFiltros('filtro3',1)}
                         />
                        <img 
                          src={this.state.filtro3 >=2 ? img+'estrella_completa2.png' : this.state.filtro3 === 1.5 ? img+'estrella_mitad2.png' : img+'estrella_vacia2.png' }
                           className="strella" alt='...'
                           onClick={()=>this.changeFiltros('filtro3',2)}
                          />
                        <img 
                          src={this.state.filtro3 >=3 ? img+'estrella_completa2.png' : this.state.filtro3 === 2.5 ? img+'estrella_mitad2.png' : img+'estrella_vacia2.png' }
                           className="strella" alt='...'
                           onClick={()=>this.changeFiltros('filtro3',3)}
                          />
                        <img 
                          src={this.state.filtro3 >=4 ? img+'estrella_completa2.png' : this.state.filtro3 === 3.5 ? img+'estrella_mitad2.png' : img+'estrella_vacia2.png' }
                           className="strella" alt='...'
                           onClick={()=>this.changeFiltros('filtro3',4)}
                          />
                        <img 
                          src={this.state.filtro3 >=5 ? img+'estrella_completa2.png' : this.state.filtro3 === 4.5 ? img+'estrella_mitad2.png' : img+'estrella_vacia2.png' }
                           className="strella" alt='...'
                           onClick={()=>this.changeFiltros('filtro3',5)}
                          />
                      </div>
                    </div>
                    
                    <div className="cleanDiv">
                      <button className="cleanBut" onClick={this.borrarFiltros} > Borrar filtros </button>
                    </div>

                  </div>

                </div>
              </div>

            </div>

            <div className="metaControles">
              <h3 className="tituloPanel"> Calificaciones </h3>
            </div>

            <div className="divCalificaciones">
              <div className="divCalificacionesChild">
                
                <div className="puntuacion"> {Number(this.state.detalles['PUNTUACION']).toFixed(1)} </div>
                
                <div className="contEstrellas">
                  <div className="contEstrellasChild">
                    <img
                      src={this.state.detalles['PUNTUACION'] >=1 ? img+'estrella_completa2.png' : this.state.detalles['PUNTUACION'] === 0.5 ? img+'estrella_mitad2.png' : img+'estrella_vacia2.png' }
                        className="strella" alt='...'
                     />
                    <img 
                      src={this.state.detalles['PUNTUACION'] >=2 ? img+'estrella_completa2.png' : this.state.detalles['PUNTUACION'] === 1.5 ? img+'estrella_mitad2.png' : img+'estrella_vacia2.png' }
                       className="strella" alt='...'
                      />
                    <img 
                      src={this.state.detalles['PUNTUACION'] >=3 ? img+'estrella_completa2.png' : this.state.detalles['PUNTUACION'] === 2.5 ? img+'estrella_mitad2.png' : img+'estrella_vacia2.png' }
                       className="strella" alt='...'
                      />
                    <img 
                      src={this.state.detalles['PUNTUACION'] >=4 ? img+'estrella_completa2.png' : this.state.detalles['PUNTUACION'] === 3.5 ? img+'estrella_mitad2.png' : img+'estrella_vacia2.png' }
                       className="strella" alt='...'
                      />
                    <img 
                      src={this.state.detalles['PUNTUACION'] >=5 ? img+'estrella_completa2.png' : this.state.detalles['PUNTUACION'] === 4.5 ? img+'estrella_mitad2.png' : img+'estrella_vacia2.png' }
                       className="strella" alt='...'
                      />
                  </div>
                </div>
                <div className="nResenas"> ( {this.state.detalles['CANT_RESENIAS']} Reseñas) </div>

                <div className="contMetaResenas">
                  <div className="metaResena">
                    <span className="calResena"> Excelente </span>
                    <Progress strokeColor={'#22B573'} strokeWidth={8} percent={this.state.detalles['PORCENTAJE_5']} showInfo={false} />
                    <span className="canResena"> {this.state.detalles['PUNTUACION_5']} </span>
                   </div>

                  <div className="metaResena">
                    <span className="calResena"> Muy bueno </span>
                    <Progress strokeColor={'#12E0A5'} strokeWidth={8} percent={this.state.detalles['PORCENTAJE_4']} showInfo={false} />
                    <span className="canResena"> {this.state.detalles['PUNTUACION_4']} </span>
                   </div>

                  <div className="metaResena">
                    <span className="calResena"> Bueno </span>
                    <Progress strokeColor={'#F77400'} strokeWidth={8} percent={this.state.detalles['PORCENTAJE_3']} showInfo={false} />
                    <span className="canResena"> {this.state.detalles['PUNTUACION_3']} </span>
                   </div>

                  <div className="metaResena">
                    <span className="calResena"> Regular </span>
                    <Progress strokeColor={'#FF9700'} strokeWidth={8} percent={this.state.detalles['PORCENTAJE_2']} showInfo={false} />
                    <span className="canResena"> {this.state.detalles['PUNTUACION_2']} </span>
                   </div>

                  <div className="metaResena">
                    <span className="calResena"> Malo </span>
                    <Progress strokeColor={'#ED3B48'} strokeWidth={8} percent={this.state.detalles['PORCENTAJE_1']} showInfo={false} />
                    <span className="canResena"> {this.state.detalles['PUNTUACION_1']} </span>
                   </div>

                </div>

              </div>
            </div>

            <div className="metaControles">
              <h3 className="tituloPanel"> Reseñas </h3>
            </div>

            <div className="cont-instancias2 infoEmpresaModal">
              {
                this.state.resenas2.map((resena,i) => (
                  <div key={'reseña_'+i} onClick={()=>this.showModaNew(resena.ID)} >
                    <div className="cardCliente">

                      <div className="head">
                        
                        <div className="perfil"> <img src={resena['IMAGEN_CLIENTE']} alt="..."onError={(e)=>this.errorImg(e,1)} /> </div>
                        
                        <div className="metCliente">
                          
                          <div className="nombre flexcenter Fspaceb">

                            <div className="dataNombre">{resena['NOMBRE_CLIENTE']}</div>

                            <div className="dataImgs flexcenter Fcenter">
                              <span> {Number(resena['PUNTUACION']).toFixed(1)} </span>
                              <div className="flexcenter Fcenter">
                                <img src={ Number(resena['PUNTUACION']) >= 1 ? img+'estrella_completa2A.png':(Number(resena['PUNTUACION'])>0&&Number(resena['PUNTUACION'])<1) ? img+'estrella_mitad2A.png' : img+"estrella_vacia2A.png" } alt="..." />
                                <img src={ Number(resena['PUNTUACION']) >= 2 ? img+'estrella_completa2A.png':(Number(resena['PUNTUACION'])>1&&Number(resena['PUNTUACION'])<2) ? img+'estrella_mitad2A.png' : img+"estrella_vacia2A.png" } alt="..." />
                                <img src={ Number(resena['PUNTUACION']) >= 3 ? img+'estrella_completa2A.png':(Number(resena['PUNTUACION'])>2&&Number(resena['PUNTUACION'])<3) ? img+'estrella_mitad2A.png' : img+"estrella_vacia2A.png" } alt="..." />
                                <img src={ Number(resena['PUNTUACION']) >= 4 ? img+'estrella_completa2A.png':(Number(resena['PUNTUACION'])>3&&Number(resena['PUNTUACION'])<4) ? img+'estrella_mitad2A.png' : img+"estrella_vacia2A.png" } alt="..." />
                                <img src={ Number(resena['PUNTUACION']) >= 5 ? img+'estrella_completa2A.png':(Number(resena['PUNTUACION'])>4&&Number(resena['PUNTUACION'])<5) ? img+'estrella_mitad2A.png' : img+"estrella_vacia2A.png" } alt="..." />
                              </div>
                            </div>
                          </div>

                          <div className="fecha">  {this.formatFecha(resena['FECHA'],'YYYY-MM-DD','DD/MM/YYYY')} </div>


                        </div>

                      </div>
                      <MiDiv class="comen" content={resena['COMENTARIOS']} myid={'cmt_'+i} title={resena['COMENTARIOS']} />
                    </div>
                    {
                      resena['RESPUESTA'].trim() !== '' &&
                      <div className="cardRespuesta">
                        <div className="head">
                          <div>
                            <img alt="..." src={this.state.srcBase} onError={(e)=>this.errorImg(e,2)} />
                            <span className="rtaResena">{this.state.detalles.NOMBRE_COMERCIAL}</span>
                          </div>
                          {
                            resena['FECHA_RESPUESTA']!==''&&<span>{this.formatFecha(resena['FECHA'],'YYYY-MM-DD HH:mm:SS','DD/MM/YYYY')}</span>
                          }
                        </div>
                        <div>
                          <MiDiv class="comen" content={resena['RESPUESTA']} myid={'rta_'+i} title={resena['RESPUESTA']} />
                        </div>
                      </div>
                    }
                  </div>
                ))
              }
            </div>

            <div className="cont-instancias5"></div>
          </div>
        </div>
       </div>
    );
  }
}

export default withRouter(Lista);

/*
<div className="targetaResena" key={'resen_'+indice} onClick={()=>this.showModaNew(resena.ID)} >
                    <div className="head">

                      <div className="divImgNom">

                        <div className="imgCliente" >
                          <img src={resena.IMAGEN_CLIENTE } alt="..." onError={(e)=>this.errorImg(e,1)} />
                        </div>

                        <div>
                          <div> <div className="NomCliente"> {resena.NOMBRE_CLIENTE} </div> </div>
                          <div className="califCliente" >
                            <div className="calif" > {resena.PUNTUACION} </div>
                            <div className="contEstrellasMini">
                              <div className="contEstrellasChild">
                                <img
                                  src={ Number(resena.PUNTUACION) >= 1 ? img+'estrella_completa2.png' : img+'estrella_vacia2.png' }
                                    className="strella" alt='...'
                                />
                                <img
                                  src={ Number(resena.PUNTUACION) >= 2 ? img+'estrella_completa2.png' : img+'estrella_vacia2.png' }
                                  className="strella" alt='...'
                                  />
                                <img
                                  src={ Number(resena.PUNTUACION) >= 3 ? img+'estrella_completa2.png' : img+'estrella_vacia2.png' }
                                  className="strella" alt='...'
                                  />
                                <img
                                  src={ Number(resena.PUNTUACION) >= 4 ? img+'estrella_completa2.png' : img+'estrella_vacia2.png' }
                                  className="strella" alt='...'
                                  />
                                <img
                                  src={ Number(resena.PUNTUACION) >= 5 ? img+'estrella_completa2.png' : img+'estrella_vacia2.png' }
                                  className="strella" alt='...'
                                  />
                              </div>
                            </div>
                          </div>
                        </div>

                      </div>

                      <div className="fecha1">
                        {this.formatFecha(resena.FECHA,'YYYY-MM-DD HH:mm:ss','DD/MM/YYYY HH:mm')}
                      </div>

                    </div>


                    <MiDiv renderable class="respuestaResena" content={resena.COMENTARIOS} myid={'comentR_'+resena.ID} />

                    {
                      resena['RESPUESTA'].trim() !== ''&&

                      <div className="body2">

                        <div className="Headrespuesta">
                          <div className="respuestatxt"> Respuesta </div>
                           <div className="fecha2">
                             {this.formatFecha(resena.FECHA_RESPUESTA,'YYYY-MM-DD HH:mm:ss','DD/MM/YYYY HH:mm:ss')}
                           </div>
                        </div>

                        <div className="NomCliente">
                          
                          <div className="imgCliente" >
                            <img alt="..." src={this.state.srcBase} onError={(e)=>this.errorImg(e,2)} />
                          </div>
                          <div> {this.state.detalles.NOMBRE_COMERCIAL} </div>

                        </div>

                        <MiDiv renderable class="respuestaResena" content={resena.RESPUESTA} myid={'descR_'+resena.ID} />

                      </div>
                    }
                  </div>
*/