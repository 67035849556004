import React from 'react';
import NumberFormat from 'react-number-format';
import {withRouter} from "react-router-dom";
import axios from 'axios';
import {Host as host, Img as img,ImgGral as img2} from './../app.json'
import { Globalrequest as request, isLogged} from './../request.js';
import { Select , Icon , Modal ,TreeSelect,Input} from 'antd';

import ModalAlert from './../modalCargando/modalAlert';
import LoadingSpinner from './../modalCargando/loadingSpinner';

import './registro.scss'

class Empresa extends React.Component{
  constructor(props){
    super(props)
    this.submit = this.submit.bind(this)
    this.addTag = this.addTag.bind(this)
    this.removeTag = this.removeTag.bind(this)
    this.cambioTipoRegimen = this.cambioTipoRegimen.bind(this)
    this.cambioRegimen = this.cambioRegimen.bind(this)
    this.changeCategoria = this.changeCategoria.bind(this)
    this.showPassword=this.showPassword.bind(this)


    this.changeForm = this.changeForm.bind(this)
    this.setForm = this.setForm.bind(this)

    this.handleModal = this.handleModal.bind(this)
    this.reSendMail = this.reSendMail.bind(this)
    /**/
    this.submitNewCorreo = this.submitNewCorreo.bind(this)
    this.showModalNewMail = this.showModalNewMail.bind(this)

    this.state = {
      form:1,
      loading:true,
      tags:[],
      vectorRegistroEmpresas:'',
      pass:true,
      pendiente:true,
      /**/
      categorias:[],
      categorias2:[],
      regimenes:[
          [],[],[],[],[]
      ],
      /**/
      treeData:[],
      /**/
      regimenActual:0,
      valueRegimen:22,
      categoriaActual:[],
      /**/
      data1:'',
      data3:'',
      /**/
      entrega:2,
      costo:false,
      gratis:true,
      minimo:'',
      /*  */
      modal:false,
      mailmodal:'jajaja@mail.com',
      modalChangeCorreo:false,
      modalAlert:false,
      txtAlert:'',
      /**/
    }
  }

  componentWillMount(){
    document.title = "Registro de negocio o empresa";
    if(isLogged()){
      this.props.redirectTo('/')
    }
  }

  componentDidMount(){
    axios.post(`${host}empresas/empresas/listaCategoriasRegimenes`,0,request).then(rt=>{
      let {regimenes,categorias,treeData} = this.state
      regimenes[0] = []
      regimenes[1] = rt.data['1']
      regimenes[2] = rt.data['2']
      regimenes[3] = rt.data['3']
      regimenes[4] = rt.data['4']
      categorias = rt.data['CATEGORIAS']
      treeData = []
      let id=0,children=[],checkable=true
      for(let categoria of categorias){
        id = Number(categoria['ID'])
        children = categoria['HIJOS'].map( sub => ( {title:sub['DESCRIPCION'],value:Number(sub['ID']),key:Number(sub['ID'])} ) )
        checkable=true
        if(children.length>0){
          checkable = false
          children.unshift({title:categoria['DESCRIPCION'],value:id,key:id,})
        }
        treeData.push({title:categoria['DESCRIPCION'],value:checkable?id:id+'_',key:id+'_',children:children,checkable:checkable,})
      }
      this.setState({ pendiente:false, categorias,treeData,regimenes, }) 
    })
  }

  setForm(a){
    this.setState({form:a})
  }
  
  addTag(event){
    if(event.key==='Enter'){
      let tag = document.getElementById('tag').value.trim();
      if(tag!==''){
         let {tags} = this.state
         let tagss = tags.filter(x=>x.toLowerCase()===tag.toLowerCase())
        if(tagss.length===0){
            tag = tag.charAt(0).toUpperCase() + tag.slice(1).toLowerCase()
            tags.push(tag)
        }
        document.getElementById('tag').value="";
        this.setState({tags:tags})
      }
    }
  }
    
  removeTag(taga,indice){
      let tags = this.state.tags.filter( x => taga!==x )
      this.setState({tags:tags})
  }

  showPassword(){
      let {pass} = this.state
      this.setState({pass:!pass})
  }

  showError(msj,i){
      document.getElementById('spanerror'+i).innerHTML=msj
      document.getElementById('spanerror'+i).style.display="block"
  }

  changeForm(form,formData){
      this.setState({form:form,data1:formData})
  }
  
  handleModal(bool,mail=''){
      this.setState({ modal:bool, mailmodal:mail })
      if(!bool){
          this.props.redirectTo('/auth/login')
      }
  }

  reSendMail(){
      let data = {mail:this.state.mailmodal}
      axios.post(`${host}usuarios/usuarios/sendMail`,data,request).then(rt=>{
          if(Number(rt.data['RESULTADO']) === 0){
              this.setState({txtAlert:'Correo no registrado',modalAlert:true})
          }
          if(Number(rt.data['RESULTADO']) === -1){
              this.setState({txtAlert:'Error al enviar el correoParece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #01-02]',modalAlert:true})
          }
          if(Number(rt.data['RESULTADO']) === 1){
              this.setState({txtAlert:'Correo enviado correctamente',modalAlert:true})
          }
      })
  }

  submitNewCorreo(){
    let newCorreo = document.getElementById('mail23').value
    let {mailmodal} = this.state
    newCorreo = newCorreo.trim()
    if(newCorreo === ''){
        this.setState({txtAlert:"El nuevo correo es obligatorio",modalAlert:true})
        return
    }
    let regexMail = /(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$){1,200}/;
    if(!regexMail.test(newCorreo)){
        this.setState({txtAlert:"Ingrese un correo válido",modalAlert:true})
        return
    }
    let data={ oldCorreo:mailmodal, newCorreo:newCorreo, }
    axios.post(`${host}empresas/empresas/cambiarCorreo`,data,request).then(rt=>{
        if(Number(rt.data)===-1){
            this.setState({txtAlert:"Ya hay una cuenta asociada al correo ingresado",modalAlert:true})
            return
        }else if(Number(rt.data)===-2){
            document.getElementById('mail23').value = ''
            this.setState({mailmodal:newCorreo,modalChangeCorreo:false,
                txtAlert:"Correo enviado correctamente",modalAlert:true
            })
        }else{
            this.setState({txtAlert:"Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #01-02]",modalAlert:true})
        }
    })
  }

  showModalNewMail(bool){ this.setState({modalChangeCorreo:bool}) }

  submit(a){
      let formData
      if(a===1){
          let {regimenActual,valueRegimen,categoriaActual} = this.state
          formData = new FormData(document.getElementById('formularegistroempresa1'))
          formData = new FormData(document.getElementById('formularegistroempresa1'))
          formData.append('tipoRegimen', regimenActual)
          formData.append('idRegimen', valueRegimen)
          if(
              regimenActual === 0 ||
              valueRegimen === 0 ||
              categoriaActual.length === 0 ||
              formData.get('rfc').trim()===''||
              (regimenActual !== 4 && formData.get('razon').trim()==='')||
              formData.get('nombreE').trim()===''
          ){
            this.setState({txtAlert:"No puede haber campos vacíos",modalAlert:true})
            return
          }

          let regexCurp = /[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[HM]{1}(AS|BC|BS|CC|CS|CH|CL|CM|DF|DG|GT|GR|HG|JC|MC|MN|MS|NT|NL|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS|NE)[B-DF-HJ-NP-TV-Z]{3}[0-9A-Z]{1}[0-9]{1}/
          
          if( regimenActual === 4 && !regexCurp.test(formData.get('rfc'))  ){
              this.setState({txtAlert:"Ingrese una CURP válida",modalAlert:true})
              return
          }

          if(regimenActual === 1 ){
            regexCurp = /[A-Z]{1}[AEIOU]{1}[A-Z]{2}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[0-9A-Z]{3}$/
          }else if(regimenActual === 2){
            regexCurp = /[A-Z]{3}[0-9]{2}(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])[0-9A-Z]{3}$/
          }

          if(!regexCurp.test(formData.get('rfc')) ){
            this.setState({txtAlert:"Ingrese un RFC válido",modalAlert:true})
            return
          }

          let data = {RFC:formData.get('rfc')}
          this.setState({pendiente:true})
          axios.post(`${host}empresas/empresas/verificarRFC`,data,request).then(rt=>{
            if(typeof(rt.data)==="object"){
              let rta = Number(rt.data['0']['RESULTADO'])
              if(rta>0){
                let msj = regimenActual===4?'La CURP ingresada ya está asociada a una cuenta existente':'El RFC ingresado ya está asociado a una cuenta existente'
                this.setState({txtAlert:msj,modalAlert:true,pendiente:false})
                return
              }
              else this.setState({data1:formData,form:3,pendiente:false})
            }
            else {
              this.setState({txtAlert:"Ocurrio un error, por favor intentalo de nuevo",modalAlert:true,pendiente:false,})
            }
          })
      }
      if(a===3){
          formData = new FormData(document.getElementById('formularegistroempresa3'))
          let vacio = false
          for(let data of formData.entries()){
              if(data[1].trim()==='')vacio = true
          }
          if(vacio){
              this.setState({txtAlert:"No puede haber campos vacíos",modalAlert:true})
              return
          }
          let regexMail = /(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$){1,200}/;

          let telefono = formData.get('telefono');
          telefono = telefono.split('_').join('');
          telefono = telefono.split('(').join('');
          telefono = telefono.split(')').join('');
          telefono = telefono.split(' ').join('');
          
          formData.set('telefono',telefono)

          if(formData.get('telefono').length < 10){
              this.setState({txtAlert:"El teléfono debe tener 10 caractere",modalAlert:true})
              return
          }

          if(!regexMail.test(formData.get('mail')) ) {
              this.setState({txtAlert:"Ingrese un correo válido",modalAlert:true})
              return
          }
          if(formData.get('contra').length < 6 ){
              this.setState({txtAlert:"La contraseña debe tener al menos 6 caracteres",modalAlert:true})
              return
          }
          if(formData.get('contra') !== formData.get('contra2') ){
              this.setState({txtAlert:"Las contraseñas no coinciden",modalAlert:true})
              return
          }
          let dataEmpresa = {}
          let {data1,categoriaActual} = this.state
          for(let data of data1.entries() ){ dataEmpresa[data[0]] = data[1] }

          for(let data of formData.entries() ){ dataEmpresa[data[0]] = data[1] }
          categoriaActual = categoriaActual.map(ct => (ct['value']))
          dataEmpresa['idCategoria'] = categoriaActual
          let {tags} = this.state
          dataEmpresa['nSucursales'] = '0'
          dataEmpresa['src'] = ''
          dataEmpresa['ventasNoExistencias'] = '2'
          dataEmpresa['catalogoCompartido'] = '2'
          dataEmpresa['pagoCompartido'] = '2'
          dataEmpresa['pedidoMultiple'] = '2'
          dataEmpresa['controlCitas'] = '2'
          dataEmpresa['estatus'] = '2'
          dataEmpresa['id'] = '0'
          dataEmpresa['tags'] = tags

          dataEmpresa['facebook'] = ''
          dataEmpresa['idFacebook'] = ''
          dataEmpresa['instagram'] = ''
          dataEmpresa['pageweb'] = ''
          
          dataEmpresa['dataPerfil'] = '0'
          dataEmpresa['dataPortada'] = '0'  
          dataEmpresa['srcPerfil'] = ''
          dataEmpresa['srcPortada'] = ''
          dataEmpresa['Op'] = 1
          /**/
          dataEmpresa['notiPush'] = 2
          dataEmpresa['idNotiPush'] = -1

          dataEmpresa['notiMsn'] = 2
          dataEmpresa['idNotiMsn'] = -1

          dataEmpresa['notiCorreo'] = 2
          dataEmpresa['idNotiCorreo'] = -1
          /**/

          this.setState({pendiente:true})
          axios.post(`${host}empresas/empresas/registrarEditar`,dataEmpresa,request).then(rt=>{
              this.setState({pendiente:false})
              /*if( Number(rt.data) === -2 ){
                  this.setState({txtAlert:"Ocurrió un error, por favor intentelo más tarde",modalAlert:true})
              }*/
              if( Number(rt.data) === -1 || Number(rt.data['RESULTADO']) === -1 ){
                  this.setState({txtAlert:"Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #01-02]",modalAlert:true})
              }
              else if( Number(rt.data) === 0 || Number(rt.data['RESULTADO']) === 0 ){
                  this.setState({txtAlert:"Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #01-03]",modalAlert:true})
              }
              else if( Number(rt.data) === 1 || Number(rt.data['RESULTADO']) === 1){
                  this.handleModal(true,formData.get('mail'));
              }
              else if( Number(rt.data) === 3 ){
                  this.setState({txtAlert:"El correo ingresado ya está registrado",modalAlert:true})
              }
          })
      }
  }

  cambioTipoRegimen(value){
    this.setState({
      regimenActual:value,
      valueRegimen:value ===4?22:0,
    })
    document.getElementById('rfcd').value=""
    let divrenderedPlaceHolder = document.querySelector('#selectRef .ant-select-selection__rendered .ant-select-selection__placeholder')
    let divrenderedValue = document.querySelector('#selectRef .ant-select-selection__rendered .ant-select-selection-selected-value')

    if(divrenderedValue){
      divrenderedValue.style.display="none"
      divrenderedPlaceHolder.style.display="block"
    }

  }

  cambioRegimen(value){
    this.setState({valueRegimen: value })
    let divrenderedPlaceHolder = document.querySelector('#selectRef .ant-select-selection__rendered .ant-select-selection__placeholder')
    let divrenderedValue = document.querySelector('#selectRef .ant-select-selection__rendered .ant-select-selection-selected-value')

    if(divrenderedValue){
      divrenderedValue.style.display="block"
      divrenderedPlaceHolder.style.display="none"
    }
  }
 
  entregaChange(e){
    let valor = Number(e.target.value)
    this.setState({entrega:valor,costo: false, gratis: true ,minimo:2})
    document.getElementById('checkCosto').checked=false
    document.getElementById('costod').value=''
    document.getElementById('minimod').value=""
  }

  changeCategoria(v){
    v = v.slice(0,3)
    this.setState({categoriaActual:v})
  }

  filterNode(vale,node){
    return node['props']['title'].toLowerCase().trim().includes(vale.trim().toLowerCase())
  }

  render(){
    return(
      <div className="cont-Login">
        <div className="logginInner special centrado flextop Fspaceb" id="logincvino" style={{maxWidth:'800px',minWidth:'800px'}} >
        <Modal centered title="" maskClosable={false} keyboard = {false}
            okText="Aceptar" okButtonProps={{className: 'btn btn1' }}
            cancelButtonProps={{ style: { display:'none'} , className: 'btn btn2' }}
            visible={this.state.modal}
            closable={false}
            onOk={ () => this.handleModal(false,'') }
            className="miAntModal"
            zIndex={90}
            >
            <div className="mymodal">
              <div className="body-alert" >
                <img src={`${img2}correo/bubook.png`} alt="..." className="icon-alert" />
                <div>
                  <h4 style={{marginTop:'-25px'}} > ¡Te damos la bienvenida! </h4>
                  <span className="bloque1" >
                      Para poder seguir brindando una atención y servicio de calidad a todos nuestros usuarios, debes de activar tu cuenta. Se ha enviado un mensaje con el enlace de activación a:
                  </span>
                  
                  <label className="nota nopoint"> {this.state.mailmodal} </label>

                  <span className="bloque2" >
                      ¿Aún no te ha llegado la activación?
                  </span>

                  <br/>

                  <span className="bloque1" >
                      Revisa la bandeja de los correos no deseados (spam) o solicita un&nbsp;
                      nuevo mensaje de activación
                  </span>
                  <br/>

                  <span className="bloque1">
                    <label className="nota" onClick={this.reSendMail}>Reenviar mensaje de activación. </label>                    
                  </span>
                  
                  <br/> <br/>
                  <label className="notanotanota" >
                      ¿No es tu correo?<br/>
                      <span  onClick={()=>this.showModalNewMail(true)} >Cambiar correo registrado</span>
                  </label>
                </div>
              </div>
            </div>
        </Modal>

        <Modal centered title='Cambio de correo' maskClosable={false} keyboard = {false} 
          okText="Guardar" okButtonProps={{ loading: this.state.loading, className:'btn btn1' , style:{ width:'120px'} }}
          cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
          className="miAntModal alertMy modalUnidad"
          visible={this.state.modalChangeCorreo} closable={false}
          onOk={this.submitNewCorreo}
          onCancel={() => this.showModalNewMail(false)}
          zIndex={95} >
          <div className="miUniDiv">
              
            <div className="inputs-group">
              <label className="inputs-label" style={{top:'-10px'}} > Ingresa tu nuevo correo electrónico </label>
              <input type="text" autoFocus className="inputs-input" id="mail23" />
            </div>

          </div>
        </Modal>

        
        <ModalAlert visible={this.state.modalAlert} text={this.state.txtAlert} onOk={()=>this.setState({modalAlert:false,txtAlert:''})}/>
        <LoadingSpinner text={'Cargando'} loading={this.state.pendiente} />

        <div className="divCounter">
          <div className="cont-avc">
            <div className={this.state.form === 1 ? 'avc avcfill':'avc avcfill filled'}></div>
            <div className={this.state.form < 2  ? 'avc' : this.state.form === 2  ? 'avc avcfill' : 'avc avcfill filled' }></div>
            <div className={this.state.form < 3 ? 'avc' : 'avc avcfill' }></div>
          </div>
        </div>

        <div
          className="myScrollable">

          <div className={
            this.state.form === 1 ? "wrapperLogin flexcenter" :
            this.state.form === 3 ? "wrapperLogin flexcenter  ishThree" : "wrapperLogin flexcenter"
          }>
          <div className="login pdd2 loginFiller adjustLogin " >

            <div className="itemBack flexcenter Fstart" onClick={()=>this.props.redirectTo('/auth/login')} >
              <Icon type="arrow-left" />                    
              <span> Regresar </span>
            </div>

            <div className="labelForForm mini"> Registro de negocio o empresa </div>

            <div className="subLabelForForm"> Información general </div>

            <form id="formularegistroempresa1">

              <Select className="inputBaseTree" placeholder="Tipo de contribuyente" onChange={this.cambioTipoRegimen}>
                <Select.Option value={4} > No aplica </Select.Option>
                <Select.Option value={1} > Persona Física </Select.Option>
                <Select.Option value={2} > Persona Moral </Select.Option>
              </Select>

              <div className="inputBase"> </div>

              {
                this.state.regimenActual!==4&&
                <Select className="inputBaseTree" placeholder="Régimen fiscal" onChange={this.cambioRegimen} id="selectRef" name="selectRef" >
                {
                  this.state.regimenes[this.state.regimenActual].map((x,indice)=>(
                    <Select.Option key={'reg_'+x['ID']} value={Number(x['ID'])}> {x['DESCRIPCION']} </Select.Option>
                  ))
                }
                </Select>
              }

              <div className="inputBase">
                <input type="text"
                 placeholder={this.state.regimenActual===4?'CURP':"RFC"}
                 maxLength={this.state.regimenActual===1?13:this.state.regimenActual===2?12:18} name="rfc" id="rfcd" />
              </div>

              <div className="inputBase">
                <input type="text" placeholder={ this.state.regimenActual !== 4 ? "Razón social" : 'Nombre completo del representante' } name="razon" id="razond"  />
              </div>

              <div className="inputBase">
                <input type="text" placeholder="Nombre comercial" name="nombreE" id="nombreEd" />
              </div>

              <TreeSelect treeCheckable={true} showSearch={true} treeCheckStrictly={true} placeholder='Categorías' className="inputBaseTree" dropdownClassName="myDropdownClassName"
                filterTreeNode={this.filterNode} onChange={ v=> this.changeCategoria(v) }
                value={this.state.categoriaActual} treeData={this.state.treeData} />


              <div className="inputBase" style={{margin:'-5px 0 -10px 0'}} >
                <input type="text" autoComplete="off"placeholder="Tags" id="tag" onKeyPress={this.addTag} className="inputBase" />
              </div>

              <div className="group-tags flexcenter Fstart" id="tags" > 
                {
                  this.state.tags.map((x,indice) =>(
                    <label key={'tag_'+indice} id={'tag_'+indice} className="mytag flexcenter" >
                        {x}
                        <span onClick={()=>this.removeTag(x,indice)} > × </span>
                    </label>
                   ))
                }
              </div>
              
              <div className="txtFoot"> Los tags son palabras clave que describen tu negocio, esto permitiría que te encuentren más rápido. </div>

            </form>
            
            <div className="btnBase botonNegro flexcenter Fcenter" onClick={()=>this.submit(1)}> Continuar </div>

          </div>
          </div>

          <div className={
            this.state.form === 1 ? "wrapperLogin flexcenter" :
            this.state.form === 3 ? "wrapperLogin flexcenter  ishThree" : "wrapperLogin flexcenter"
          }>

          <div className="login pdd2 loginFiller adjustLogin" >
            <div className="itemBack flexcenter Fstart" onClick={()=>this.setForm(1)} >
              <Icon type="arrow-left" />                    
              <span> Regresar </span>
            </div>
            <div className="labelForForm mini"> Registro de negocio o empresa </div>

            <div className="subLabelForForm mini"> Datos del responsable del registro </div>

            <form id="formularegistroempresa3">
              
              <div className="inputBase">
                  <input type="text" placeholder="Nombre (s)" name="nombre" id="nombred" />
              </div>

              <div className="inputBase">
                  <input type="text" placeholder="Apellido (s)" name="apellidos" id="apellidosd" />
              </div>

              <div className="inputBase">
                  <input type="text" placeholder="¿Qué cargo desempeña en la empresa?" name="cargo" id="cargod" />
              </div>

              <div className="inputBase">
                  <NumberFormat format="(###) ### ####" placeholder="Teléfono" mask="_" type="text" name="telefono" htmlFor="telefonod" />
              </div>

              <Input autoComplete='off' prefix={<Icon type="mail" />} name="mail" id="maild" placeholder="Correo electrónico" className="inputBase" />

              <Input autoComplete='off' name="contra" id="contrad" placeholder="Contraseña" className="inputBase pss" type={this.state.pass?'password':'text'}
                  prefix={<Icon type="lock" />} suffix={<Icon onClick={this.showPassword} type={this.state.pass?'eye-invisible':'eye'}/>}  />

              <Input autoComplete='off' name="contra2" id="contra2d" placeholder="Repetir contraseña" className="inputBase" type={this.state.pass?'password':'text'}
                  prefix={<Icon type="lock" />}  />

            </form>

            <div className="txtFoot black">
              Al crear mi cuenta, acepto los <span> Términos y Condiciones de Uso </span> de BuBook MX&reg;, y confirmo que leí y acepto las <span onClick={()=>window.open('/legacy/politicadeprivacidad')}> Políticas de Privacidad </span>
            </div>

            <div className="btnBase botonNegro flexcenter Fcenter" onClick={()=>this.submit(3)} > Registrarme </div>

          </div>
          </div>

        </div>

        <div className="sideTarjetaAuth">
          <img alt="..." className="imgLogo" src={img+'bubook_blanco.png'} />
          <img alt="..." className="imgCard" src={img+'cardAuth.png'} />
        </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Empresa);