import React from 'react';
import axios from 'axios';
import {Globalrequest as request} from './../../../request.js'
import { Host as host,ImgPanelAdmin as panelAdmin,Img as img} from './../../../app.json';

import Loading from './../../../modalCargando/loading';
import Alert from './../../../modalCargando/modalAlert';
import NotFound from './../../../modalCargando/notfound';

import {Filtrar} from "./../../../utilities/svg.js";

import {unique,groupBy,capitalizeFirs} from "./../../../utilities/generalfunctions";

/* Librerías */
import moment from 'moment';
import { Modal,Input,Icon,Calendar,Popover} from 'antd';
import { withRouter } from "react-router-dom";

class Configuracion extends React.Component{
  
  constructor(props){
    super(props);
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.makeCitasBetter = this.makeCitasBetter.bind(this)

    this.onPanelChange = this.onPanelChange.bind(this)

    this.clickOnCita = this.clickOnCita.bind(this)
    this.eliminarCita = this.eliminarCita.bind(this)
    this.agregarCita = this.agregarCita.bind(this)

    this.changeFiltros = this.changeFiltros.bind(this)
    this.filtrarCitas = this.filtrarCitas.bind(this)
    this.borrarFiltros = this.borrarFiltros.bind(this)

    this.setReagendarFecha = this.setReagendarFecha.bind(this)
    this.actualizarHorariosCitas2 = this.actualizarHorariosCitas2.bind(this)

    this.setReagendarHora = this.setReagendarHora.bind(this)


    this.clickHoverable = this.clickHoverable.bind(this)
    
    this.state={
      loading:false,
      citas:[],
      citasCopia:[],
      citas2:{},
      /**/
      hoy:moment(),
      fechaReagendar:moment(),
      formatedDate : moment().format('dddd, LL'),
      /**/
      justHora:false,
      /**/
      addingCita:false,
      //configActual:{},
      mutableDataCita:{
        inicio:'',
        fin:'',
        idHorarioAtencionCita:'',
      },
      /**/
      currentCitaCopy:{cita:{},},
      currentCita:{cita:{},},
      citaInterface:{ESTATUS:'1',OBSERVACIONES_CLIENTES:'',OBSERVACIONES_EMPRESA:'',MOTIVOS_CLIENTES:'',MOTIVOS_EMPRESA:'',ID_USUARIO_EMPRESA:-1,FK_ID:-1,ID_PEDIDO:-1,ID:-1,CALIFICACION_CLIENTE:0,MINE:false,NOMBRE_CLIENTE:'',FECHA_CITA_INICIO:'',FECHA_CITA_TERMINO:''},
      editanding:false,
      caducada:false,
      finalizada:false,
      estatus:-1,
      cancelanding:false,
      /**/
      confirmEliminar:false,
      txtAlert:'',
      modalAlert:false,
      /**/
      listaCitas2:[],
      /**/
      filtro1:true,
      filtro2:true,
      filtro3:true,
      filtro4:true,
      filtro6:true,
      /**/
      idNotificacionPorAtender:sessionStorage.getItem('idNotificacionPorAtenderB'),
      //idCit:sessionStorage.getItem('idCit'),
      /**/
    }
  }

  componentDidMount(){
    document.title="Citas agendadas"


    let {makeRef} = this.props
    makeRef({mounted:true,component:this,name:'Citas'})

    this.actualizarCatalogo()
    let {idNotificacionPorAtender} = this.state
    if(idNotificacionPorAtender !== 'null'){
      let data = {idN:idNotificacionPorAtender,estatus:2}
      axios.post(`${host}clientes/clientes/cambiarEstatusNotificacionBubooker`,data,request)
      sessionStorage.setItem('idNotificacionPorAtenderB','null')
    }
    
  }

  componentWillUnmount(){
    let {makeRef} = this.props
    makeRef({mounted:false,component:undefined,name:''})
  }

  actualizarCatalogo(){
    //let {idCit} = this.state
    let idCit = sessionStorage.getItem('idCit')
    idCit = (idCit !== null && idCit !== undefined) ? idCit:'-100'
    sessionStorage.removeItem('idCit')
    let data = {idB:sessionStorage.getItem('id'),idCit}
    this.setState({loading:true})
    axios.post(`${host}citas/citas/citasporUsuario`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let showPre = false
        if(idCit!=='-100'){
          let precita = rt.data['data'].filter(cita => cita['PRELOAD']==='found')
          if(precita.length>0)
            showPre=true
          else
            this.setState({modalNotFound:true})
        }
        this.setState({citasCopia:rt.data['data']})
        this.makeCitasBetter(rt.data['data'],showPre)
      }
    })
  }

  makeCitasBetter(citasPara,showPre=false){
    let{citas,citas2}= this.state
    let labels = []
    
    citas = citasPara.map(ct => (ct['FECHA_VIEW'] = moment(ct['FECHA_CITA_INICIO'],'YYYY-MM-DD HH:mm:ss').format('DD/MMMM/YYYY') ) )
    labels = citasPara.map(ct => (moment(ct['FECHA_CITA_INICIO'],'YYYY-MM-DD HH:mm:ss').format('DD/MMMM/YYYY') ) )
    labels = unique(labels)
    labels.sort((a,b) => moment(a,'DD/MMMM/YYYY').valueOf()-moment(b,'DD/MMMM/YYYY').valueOf() )
    labels = labels.reverse()
    citas = groupBy(citasPara,'FECHA_VIEW')
    citas2={}
    let citaToClick = {}
    for(let fecha of labels){
      citas2[fecha] = citas[fecha]
      citas2[fecha].sort((a,b) => moment(a['FECHA_CITA_INICIO']).valueOf()-moment(b['FECHA_CITA_INICIO']).valueOf() )
      for(let citaAux of citas2[fecha]){
        let textoCliente = citaAux['OBSERVACIONES_CLIENTES'].split('~Ç~')
        let textoEmpresas = citaAux['OBSERVACIONES_EMPRESA'].split('~Ç~')
        citaAux['OBSERVACIONES_CLIENTES'] = textoCliente.length>0?textoCliente[0].trim():''
        citaAux['MOTIVOS_CLIENTES'] = textoCliente.length>1?textoCliente[1].trim():''
        citaAux['OBSERVACIONES_EMPRESA'] = textoEmpresas.length>0?textoEmpresas[0].trim():''
        citaAux['MOTIVOS_EMPRESA'] = textoEmpresas.length>1?textoEmpresas[1].trim():''
        if(citaAux['PRELOAD']==='found')
          citaToClick = citaAux
        citaAux['PRELOAD'] = 'none'
      }
    }
    if(citaToClick.hasOwnProperty('PRELOAD')&&showPre)
      this.clickOnCita(citaToClick)
    citas = citasPara.map( c => (c))
    this.setState({loading:false,citas,citas2})
  }

  groupBy (xs, key) {
    return xs.reduce(function(rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  }

  onPanelChange(fechaReagendar){
    let{formatedDate} = this.state
    formatedDate = fechaReagendar.format('dddd, LL');
    this.setState({fechaReagendar,formatedDate})    
  }

  switchEstatus(estatus,op=1){
    estatus = Number(estatus)
    if(op===1){
      if(estatus===1)return "Pendiente"
      if(estatus===2)return "Confirmada"
      if(estatus===3)return "No asistió"
      if(estatus===4)return "Asistió"
      if(estatus===5)return "Reagendada"
      if(estatus===6)return "Cancelada"
    }
    if(op===2){
      if(estatus===1)return "pendiente"
      if(estatus===2)return "confirmada"
      if(estatus===3)return "noasistio"
      if(estatus===4)return "asistio"
      if(estatus===5)return "reagendada"
      if(estatus===6)return "cancelada"
    }
    return estatus
  }

  switchFecha(fechaString){
    let date = moment(fechaString,'YYYY-MM-DD HH:mm:ss')
    return date.format('HH:mm')
  }

  clickOnCita(cita){
    let {currentCita,currentCitaCopy,finalizada,caducada,estatus,fechaReagendar,formatedDate,mutableDataCita,hoy} = this.state
    Object.assign(currentCita,cita)
    Object.assign(currentCitaCopy,cita)
    let ahora = moment()
    let fechaCita = moment(cita['FECHA_CITA_INICIO'],'"YYYY-MM-DD HH:mm:ss"')
    let fechaCitaFin = moment(cita['FECHA_CITA_TERMINO'],'"YYYY-MM-DD HH:mm:ss"')
    estatus = Number(cita['ESTATUS'])
    finalizada = (estatus>2)
    caducada = ahora.isSameOrAfter(fechaCita)

    fechaReagendar = moment(fechaCita)
    formatedDate = fechaReagendar.format('dddd, LL');

    currentCita['inicio'] = fechaCita.format('HH:mm')
    currentCita['fin'] = fechaCitaFin.format('HH:mm')
    currentCita['idHorarioAtencionCita'] = cita['ID_HORARIOS_ATENCION_CITA']

    mutableDataCita['inicio'] = fechaCita.format('HH:mm')
    mutableDataCita['fin'] = fechaCitaFin.format('HH:mm')
    mutableDataCita['idHorarioAtencionCita'] = cita['ID_HORARIOS_ATENCION_CITA']

    hoy = moment(fechaCita)

    this.setState({addingCita:true,editanding:true,currentCita,currentCitaCopy,finalizada,caducada,estatus,fechaReagendar,formatedDate,mutableDataCita,hoy})
  }

  eliminarCita(){
    let {currentCita,citas} = this.state
    let data = {idCita:currentCita['ID']}
    this.setState({loading:true,confirmEliminar:false})
    axios.post(`${host}citas/citas/eliminarCita`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let rta = Number(rt.data['result'])
        if(rta===1){
          citas = citas.filter(c => c['ID']!==data['idCita'])
          this.makeCitasBetter(citas)
          currentCita = {cita:Object.create({})}
          this.setState({txtAlert:'Cita eliminada correctamente',modalAlert:true,addingCita:false,currentCita,loading:false})
        } else{ this.setState({txtAlert:'Parece que ocurrió un error, por favor vuelve a intentalo',modalAlert:true,loading:false}) }
      }else{ this.setState({txtAlert:'Parece que ocurrió un error, por favor vuelve a intentalo',modalAlert:true,loading:false}) }
    })
  }

  agregarCita(op){
    let {currentCita,currentCitaCopy,hoy,fechaReagendar,formatedDate,mutableDataCita} = this.state
    let estatus = op===3?6:Number(currentCita['ESTATUS'])
    let motivo = currentCita['MOTIVOS_CLIENTES'] ? currentCita['MOTIVOS_CLIENTES'] : ''
    if(op===3){
      motivo = document.getElementById('motivoCancel').value.trim()
      if(motivo===''){
        this.setState({txtAlert:'El motivo de cancelación es obligatorio',modalAlert:true})
        return
      }
      document.getElementById('motivoCancel').value=''
    }
    
    let indicacion = document.getElementById('indicacion').value.trim()
    let date = hoy.format('YYYY-MM-DD')
    
    let inicio = currentCita['inicio'],
        fin = currentCita['fin'],
        idHorarioAtencionCita = currentCita['idHorarioAtencionCita']

    if(op===2){
      let oldFecha = moment(currentCitaCopy['FECHA_CITA_INICIO'],'YYYY-MM-DD HH:mm:ss')
      date = fechaReagendar.format('YYYY-MM-DD')
      inicio = mutableDataCita['inicio']
      fin = mutableDataCita['fin']
      idHorarioAtencionCita = mutableDataCita['idHorarioAtencionCita']

      if(fechaReagendar.isSame(oldFecha,'day')){//es la misma fecha, pero puede ser distinta hora
        if( (currentCita['inicio']!==inicio && currentCita['fin'] !== fin) || indicacion !== currentCitaCopy['OBSERVACIONES_EMPRESA'].trim() || Number(currentCitaCopy['ESTATUS']) !== estatus ){
          //alert('si cambio')
        }else{
          if(estatus!==1){
            this.setState({editanding:false,addingCita:false})
            return
          }
        }
      }else{
        //alert('si cambio')
      }
    }
    
    formatedDate = hoy.format('dddd, LL');
    if(currentCita['inicio']==='--:--' || currentCita['fin']==='--:--'){
      this.setState({modalAlert:true,txtAlert:'Debe elegir un horario para la cita'})
      return
    }
    let citaToAdd = {
      idHorarioAtencionCita:idHorarioAtencionCita,
      idCliente:currentCita['ID_CLIENTE'],
      fkId:currentCita['FK_ID'],
      idUsuarioEmpresa:currentCita['ID_USUARIO_EMPRESA'],
      idPedido:currentCita['ID_PEDIDO'],
      fechaInicio: `${date} ${inicio}`,
      fechaTermino:`${date} ${fin}`,
      observacionesCliente:indicacion+'~Ç~'+motivo,
      observacionesEmpresa: currentCita['OBSERVACIONES_EMPRESA']+'~Ç~'+currentCita['MOTIVOS_EMPRESA'],
      calificacion:currentCita['CALIFICACION_CLIENTE'],
      estatus:estatus,
      id:currentCita['ID'],
      op:-1,
      idE:currentCita['ID_EMPRESA'],
      esCliente:1,
    }
    document.getElementById('indicacion').value = ''

    this.setState({loading:true})

    axios.post(`${host}citas/citas/agregarEditarCitaCliente`,citaToAdd,request).then(rt=>{
      if(rt.data['status'] === 'ok'){
        if(Number(rt.data['data'])===1){
          this.setState({listaCitas2:[],currentCita:{},currentCitaCopy:{},addingCita:false,formatedDate:moment().format('dddd, LL'),hoy:moment(),fechaReagendar:moment(),cancelanding:false,})
          this.actualizarCatalogo()
        }else{ this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde',modalAlert:true,formatedDate,cancelanding:false})}
      }
      else{ this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde',modalAlert:true,formatedDate,cancelanding:false}) }
    })
  }

  changeFiltros(campo,value){
    this.setState({ [campo]:value })
    setTimeout( ()=>this.filtrarCitas() , 1 )
  }

  filtrarCitas(){
    let {filtro1,filtro2,filtro3,filtro4,filtro6,citasCopia} = this.state
    let citasAux = citasCopia.map(c => (c))
    citasAux = filtro1?citasAux:citasAux.filter(c => Number(c['ESTATUS']) !== 1 )
    citasAux = filtro2?citasAux:citasAux.filter(c => Number(c['ESTATUS']) !== 2 )
    citasAux = filtro3?citasAux:citasAux.filter(c => Number(c['ESTATUS']) !== 3 )
    citasAux = filtro4?citasAux:citasAux.filter(c => Number(c['ESTATUS']) !== 4 )
    citasAux = filtro6?citasAux:citasAux.filter(c => Number(c['ESTATUS']) !== 6 )
    this.makeCitasBetter(citasAux)
  }

  borrarFiltros(){
    let {filtro1,filtro2,filtro3,filtro4,filtro6} = this.state
    filtro1 = true
    filtro2 = true
    filtro3 = true
    filtro4 = true
    filtro6 = true
    this.setState({ filtro1,filtro2,filtro3,filtro4,filtro6 })
    setTimeout( ()=>this.filtrarCitas() , 1 )
  }

  setReagendarFecha(){
    let {currentCita,fechaReagendar,listaCitas2} = this.state
    let orita = moment()
    if(fechaReagendar.isBefore(orita)){
      this.setState({modalAlert:true,txtAlert:'La fecha seleccionada no está disponible'})
      return
    }
    this.actualizarHorariosCitas2(false,)
  }

  actualizarHorariosCitas2(justHora=false){
    let {currentCita,fechaReagendar,listaCitas2} = this.state
    let orita = moment()
    this.setState({loading:true,})
    let data = {idC:currentCita['ID_CONFIGURACION_CITA'],fecha:fechaReagendar.format('YYYY-MM-DD'), dia:fechaReagendar.day()===0?7:fechaReagendar.day() }
    axios.post(`${host}citas/citas/disponibilidadHorarioFecha`,data,request).then(rt=>{
      this.setState({loading:false})
      if(rt.data['status']==='ok'){
        let horariosHoy = rt.data['horarios']
        horariosHoy = Object.keys(horariosHoy).map((key) => horariosHoy[key] );
        let horariosAbreHoy = horariosHoy.filter(h => Number(h['tipoHora']) === 1)
        let horariosDescansoHoy = horariosHoy.filter(h => Number(h['tipoHora']) === 2)
        if(horariosAbreHoy.length>0){
          horariosAbreHoy = horariosAbreHoy[0]
          let horaInicio = moment(data['fecha']+' '+horariosAbreHoy['horaInicio'],'YYYY-MM-DD HH:mm:ss')
          let horaFin = moment(data['fecha']+' '+horariosAbreHoy['horaFin'],'YYYY-MM-DD HH:mm:ss')
          
          let duratCita = moment.duration(rt.data['duratCita']).asMinutes()
          let interCita = moment.duration(rt.data['interCita']).asMinutes()
          let tolerancia=0

          let citas = rt.data['list']

          horariosDescansoHoy = horariosDescansoHoy.map(h => ({inicio: moment(h['horaInicio'],'HH:mm:ss').add(tolerancia,'minutes') , fin: moment(h['horaFin'],'HH:mm:ss')}) )
          listaCitas2 = []
          while(horaInicio.isBefore(horaFin)){
            orita = moment()
            let horaInicioAux = moment(horaInicio)
            horaInicioAux.add(duratCita,'minutes')

            let invalida = horaInicio.isBefore(orita)
            if(!invalida){
              let yahay = citas.filter(c => c['HORA_INICIO'] === horaInicio.format('HH:mm:ss') && c['HORA_TERMINO'] === horaInicioAux.format('HH:mm:ss') && Number(c['ESTATUS']) < 3 )
              let descanso = horariosDescansoHoy.some(h => horaInicio.isBetween(h['inicio'],h['fin']) || horaInicioAux.isBetween(h['inicio'],h['fin']))
              listaCitas2.push({
                inicio: horaInicio.format('HH:mm'),
                fin: horaInicioAux.format('HH:mm'),
                concita:yahay.length>0,
                clase:yahay.length===0?'confirmada': 'cancelada' ,
                descanso:descanso.length>0,
                newIdHorario:horariosAbreHoy['id'],
              })
            }
            horaInicio.add(duratCita,'minutes')
            horaInicio.add(interCita,'minutes')
          }
          if(listaCitas2.length>0){
            this.setState({reagending:false,reagending2:true,listaCitas2,duracionCita:duratCita,justHora})
          }
          else{
            this.setState({txtAlert:'No hay horarios disponibles para la fecha elegida.',modalAlert:true})
          }
        }
        else{//no hay horarios disponibles
          this.setState({txtAlert:'No hay horarios disponibles para la fecha elegida.',modalAlert:true})
        }
      }
    })
  }

  setReagendarHora(citaClick){
    if(citaClick['descanso']||citaClick['concita']){
      this.setState({modalAlert:true,txtAlert:'El horario seleccionado no está disponible'})
      return
    }
    let {mutableDataCita} = this.state
    mutableDataCita['inicio'] = citaClick['inicio']
    mutableDataCita['fin'] = citaClick['fin']
    mutableDataCita['idHorarioAtencionCita'] = citaClick['newIdHorario']
    this.setState({mutableDataCita,reagending2:false,})
  }

  clickHoverable(op){
    let {fechaReagendar,caducada, finalizada} = this.state
    if(finalizada || caducada)return

    if(op===1){ this.setState({reagending:true}) }
    if(op===2){
      let ahora = moment()
      if(fechaReagendar.isBefore(ahora,'day')){
        this.setState({modalAlert:true,txtAlert:'No hay horarios de atención disponibles para la fecha seleccionada.'})
        return
      }      
      this.actualizarHorariosCitas2(true)
    }    
  }

  render(){
    return(
  		<div className="containerBrowserBubookerChildTwo">
        <div className="containerBrowserBubookerChildThree">
          
          <Loading loading={this.state.loading} />

          <Alert text={'Una vez eliminada, esta cita no podrá recuperarse.'} visible={this.state.confirmEliminar} withCancel onOk={this.eliminarCita} onCancel={()=>this.setState({confirmEliminar:false})}  />
          <Alert text={this.state.txtAlert} visible={this.state.modalAlert} onOk={()=>this.setState({txtAlert:'',modalAlert:false,})  } />

          <NotFound 
            visible={this.state.modalNotFound}
            title="¡Este regitro no está disponible!"
            txtNotFound='Es posible que el elemento que has intentado visualizar ya no exista o haya dejadod de ser público.'
            okText="Ver mi lista de citas"
            onOk={() => this.setState({modalNotFound:false}) }
          />

          <div style={{marginTop:'20px'}} className="itemBackBu flexcenter Fstart" onClick={()=>this.props.indiceRedirecter('/')} >
            <Icon type="left" />
            <span> Regresar </span>
          </div>
          
          <div className="titleSectionBubooker flexcenter Fstart">
            Citas agendadas
            <Popover content={
                <div className="padreFiltros">
                  
                  <div className="titleFilter" > Filtrar citas por Estatus </div>

                  <div className="flextop Fspacea padreItemsFiltros">

                    <div className=" flexcenter Fstart itemFiltro pendiente">
                      <input checked={this.state.filtro1} type="checkbox" id="checkpendiente" className="checkboxBubook" onChange={e => this.changeFiltros('filtro1',e.target.checked)} />
                      <label htmlFor="checkpendiente" > Pendientes </label>
                    </div>

                    <div className=" flexcenter Fstart itemFiltro confirmada">
                      <input checked={this.state.filtro2} type="checkbox" id="checkconfirmada" className="checkboxBubook" onChange={e => this.changeFiltros('filtro2',e.target.checked)} />
                      <label htmlFor="checkconfirmada" > Confirmadas </label>
                    </div>

                    <div className=" flexcenter Fstart itemFiltro noasistio">
                      <input checked={this.state.filtro3} type="checkbox" id="checknoasistio" className="checkboxBubook" onChange={e => this.changeFiltros('filtro3',e.target.checked)} />
                      <label htmlFor="checknoasistio" > No asistió </label>
                    </div>

                    <div className=" flexcenter Fstart itemFiltro asistio">
                      <input checked={this.state.filtro4} type="checkbox" id="checkasistio" className="checkboxBubook" onChange={e => this.changeFiltros('filtro4',e.target.checked)} />
                      <label htmlFor="checkasistio" > Asistió </label>
                    </div>

                    <div className=" flexcenter Fstart itemFiltro cancelada">
                      <input checked={this.state.filtro6} type="checkbox" id="checkcancelada" className="checkboxBubook" onChange={e => this.changeFiltros('filtro6',e.target.checked)} />
                      <label htmlFor="checkcancelada" > Cancelada </label>
                    </div>

                  </div>

                  <div className="flexcenter Fend">
                    <button className="newBtn btnSecundario" onClick={this.borrarFiltros} > Borrar filtros </button>
                  </div>

                </div>
              } title={null} trigger="click" placement="rightBottom" overlayClassName="contFiltrosCitas" >
              <i> <Filtrar/> </i>
            </Popover>
          </div>

         {/* detalle calendar */}
          <Modal Modal centered title="" closable={false} header={null} footer={null} zIndex={5} visible={this.state.reagending}
            className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}}
            onCancel={()=>document.getElementById('reagendingButton').click()}
            >
            <div className="reagenda">

              <div className="myheadCalendar flexcenter Fspaceb">
                <Icon type="left" className="control left" onClick={()=>this.setState({fechaReagendar: this.state.fechaReagendar.add(-1,'months') })} />
                <span>{this.state.fechaReagendar.format('DD-MMMM-YYYY')}</span>
                <Icon type="right" className="control right" onClick={()=>this.setState({fechaReagendar: this.state.fechaReagendar.add(1,'months') })} />
              </div>

              <Calendar className="mycalendar" value={this.state.fechaReagendar}
              //dateFullCellRender={this.cellrender}
              //onSelect={this.onSelect}
              //onPanelChange={this.onPanelChange}
              onChange={this.onPanelChange}
              />
              <div className="flexcenter Fend" style={{padding:'0 10px'}}>
                <button className="flexcenter Fcenter btn btn2" id="reagendingButton"
                  onClick={()=>this.setState({reagending:false,fechaReagendar:this.state.hoy, formatedDate : this.state.hoy.format('dddd, LL')})} >
                  Cancelar
                 </button>

                <button className="flexcenter Fcenter btn btn1" onClick={this.setReagendarFecha} > Aceptar </button>
              </div>
            </div>
          </Modal>

            {/* Tarjetas cinepolis */}
          <Modal Modal centered title="" header={null} footer={null} zIndex={5} 
            onCancel={
              ()=>{
                const {justHora} = this.state
                if(justHora){ this.setState({reagending2:false,justHora:false,}) }
                else{ this.setState({reagending2:false,reagending:true}) }
              }
            }
            visible={this.state.reagending2} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
            <div className="reagenda">
              <div className="title"> Selecione un horario para la cita </div>
              <label className="nta"> Duración de la cita: {this.state.duracionCita} minutos. </label>
              <div className="citasCinepolis">
              {
                this.state.listaCitas2.map((cita,i)=>(
                  <div className="cuadrito flexcenter Fspacea" key={'cuadrito_'+i} onClick={()=>this.setReagendarHora(cita)} >
                    <div className={'cirulo '+(cita['descanso']?' descanso':cita['clase'])} />
                    {cita['inicio']}
                  </div>
                ))
              }
              </div>
            </div>
          </Modal>

         {/* motivo cancelacion */}
          <Modal centered title="" header={null} footer={null} zIndex={5} onCancel={()=>this.setState({cancelanding:false}) }
            visible={this.state.cancelanding} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
            <div className="controlesNuevaCita">
              <div className="title"> Ingrese el motivo de la cancelación </div>
              <div className="data flexcenter Fspaceb">
                <label>Motivo de cancelación </label>
              </div>
              <Input.TextArea className={"inputs-input"} id="motivoCancel" maxLength={50} autoSize={{minRows:1,maxRows:4}} />
              <button style={{marginTop:'25px'}} className="flexcenter Fcenter btnAdd" onClick={()=>this.agregarCita(3)} > Aceptar </button>
            </div>
          </Modal>

          <Modal centered title="" header={null} footer={null} zIndex={4} onCancel={ ()=> this.setState({addingCita:false,editanding:false, currentCita:{cita:Object.create(this.state.citaInterface)}, fechaReagendar:this.state.hoy, formatedDate : this.state.hoy.format('dddd, LL'), caducada:false, finalizada:false} ) }
            visible={this.state.addingCita} className="modalBaseBuBooker" width={350} maskStyle={{backgroundColor:'rgba(0,0,0,.3)'}} >
            <div className="controlesNuevaCita">
              <div className="title"> Detalle de la cita </div>
              
              <label className="nta flexcenter Fcenter linkeable" onClick={()=>this.clickHoverable(1)}  > {capitalizeFirs(this.state.formatedDate)} </label>
              <div className="detalle">

                <div className="data flexcenter Fspaceb">
                  <label> Cliente </label>
                </div>

                <div className="data flexcenter Fspaceb">
                  <Input className="inputs-input" value={this.state.currentCita['NOMBRE_CLIENTE']} readOnly />:
                </div>
                
                <div className="data flexcenter Fspaceb middle">
                  <label>Horario cita</label>
                  <label>Estatus</label>
                </div>


                <div className="data flexcenter Fspaceb middle">
                  <Input className="inputs-input linkeable" onClick={()=>this.clickHoverable(2)} value={this.state.mutableDataCita['inicio']+'-'+this.state.mutableDataCita['fin']} readOnly />
                  <Input className="inputs-input" value={this.switchEstatus(this.state.estatus)} readOnly />
                </div>

                <div className="data flexcenter Fspaceb">
                  <label> Comentarios por parte del cliente </label>
                </div>
                {
                  this.state.addingCita&&
                  <Input.TextArea className="inputs-input" readOnly={Number(this.state.estatus)!==1} defaultValue={this.state.currentCita['OBSERVACIONES_CLIENTES']} id="indicacion" maxLength={450} autoSize={{minRows: 1, maxRows:4,} } />
                }

                {
                  Number(this.state.estatus)===6&&
                  this.state.currentCita['MOTIVOS_CLIENTES']!==''&&
                  <React.Fragment>
                    <div className="data flexcenter Fspaceb">
                      <label> Motivo de la cancelación </label>
                    </div>
                    <Input.TextArea className="inputs-input" value={this.state.currentCita['MOTIVOS_CLIENTES']} readOnly maxLength={50} autoSize={{minRows: 1, maxRows:4,} } />
                  </React.Fragment>
                }

                <div className="data flexcenter Fspaceb">
                  <label> Comentarios por parte de la empresa </label>
                </div>

                {
                  this.state.addingCita&&
                  <Input.TextArea className="inputs-input" readOnly value={this.state.currentCita['OBSERVACIONES_EMPRESA']} maxLength={450} autoSize={{minRows: 1, maxRows:4,} } />   
                }


                {
                  Number(this.state.estatus)===6&&
                  this.state.currentCita['MOTIVOS_EMPRESA']!==''&&
                  <React.Fragment>
                    <div className="data flexcenter Fspaceb">
                      <label> Motivo de la cancelación </label>
                    </div>
                    <Input.TextArea className="inputs-input" value={this.state.currentCita['MOTIVOS_EMPRESA']} readOnly maxLength={50} autoSize={{minRows: 1, maxRows:4,} } />
                  </React.Fragment>
                }

                {
                  this.state.editanding && //editando un cita
                  
                  <div style={{marginTop:'25px'}} className="contButtons">
                    {
                      (!this.state.caducada && !this.state.finalizada)&&//que la cita quee esté editando no esté caducada
                        <div className="flexcenter Fspaceb">
                          {
                            Number(this.state.estatus)===2?
                            <button className="flexcenter Fcenter btn btn2" onClick={()=>this.setState({cancelanding:true})} > Cancelar </button>:
                            <button className="flexcenter Fcenter btn btn2" onClick={()=>this.setState({confirmEliminar:true})} > Eliminar </button>
                          }
                          <button className="flexcenter Fcenter btn btn2" style={{color:'#F07505'}} id="btnReagendar" onClick={()=>this.setState({reagending:true,justHora:false})} > Reagendar </button>
                        </div>
                    }

                    <button className="flexcenter Fcenter btnAdd" onClick={()=>this.agregarCita(2)}>
                    {
                      (this.state.finalizada||this.state.caducada||this.state.estatus!==1) ? 'Aceptar' : 'Agendar'
                    }
                    </button>

                  </div>
                }

              </div>
            </div>
          </Modal>
          
          
          <div className="listCItasBuBooker">
          {

            Object.entries(this.state.citas2).length===0?            
            <div className="nonResult flexcenter Fspaceb">
              <img alt="..." src={panelAdmin+'noncitas.png'} />
            </div>:
            Object.entries(this.state.citas2).map(([fecha,citas],i) =>(
              <React.Fragment key={"block_"+i}>
                <div className="header" > {fecha} </div>
                <div className="conCitas">
                  {
                    citas.map((c,i)=> (
                      <div className="instanciaCita flexcenter Fspaceb" key={'citaHoy_'+i} onClick={()=>this.clickOnCita(c)} >
                        <div className="horas flexcenter Fcenter">
                          <span> {this.switchFecha(c['FECHA_CITA_INICIO'])} </span>
                          <span> {this.switchFecha(c['FECHA_CITA_TERMINO'])} </span>
                        </div>
                        <div className={"texto flexcenter Fstart "}>
                          <div className={this.switchEstatus(c['ESTATUS'],2)+' barras'} />
                          
                          <div className="inTexto flextop Fcenter">
                            <span>{c['NOMBRE_COMERCIAL_EMPRESA']}</span>
                            {
                              c['OBSERVACIONES_CLIENTES'].trim() !=='' &&
                              <label>{c['OBSERVACIONES_CLIENTES']}</label>
                            }
                          </div>

                        </div>
                      </div>
                    ))
                  }
                </div>
              </React.Fragment>
            ))
          }            
          </div>

  		  </div>
      </div>
    );
  }
}

export default withRouter(Configuracion);