import React, {useState,useEffect} from 'react';

import axios from 'axios';
import {Globalrequest as request} from './../../../request.js'
import { Host as host,ImgPanelAdmin as panelAdmin,Img as img} from './../../../app.json';

import Loading from './../../../modalCargando/loading';
import Spinner from './../../../modalCargando/loadingSpinner';
import Alert from './../../../modalCargando/modalAlert';

import NumberFormat from 'react-number-format';
/* Librerías */
import moment from 'moment';
import { Modal , DatePicker,Switch,Input ,Icon} from 'antd';
import { withRouter } from "react-router-dom";

import Cropper from 'react-cropper';

import './configuracion.scss'


const Configuracion = props => {

  const [data,setData] = useState({telefono:-1})
  
  const [modalSpinner,setModalSpinner] = useState({visible:false,text:''})
  const [modalAlert,setModalAlert] = useState({visible:false,text:''})

  const [cropper,setCropper] = useState({})
  const [modalCropper,setModalCropper] = useState({visible:false})

  const [modalContrasena,setModalContrasena] = useState({visible:false})

  
  const [modalConfirmEliminarCuenta,setModalConfirmEliminarCuenta] = useState({visible:false})
  const [modalCuentaEliminada,setModalCuentaEliminada] = useState({visible:false})

  const [loading,setLoading] = useState(false)

  const [changingPW,setChangingPW] = useState(false)
  const [contra,setContra] = useState('')
  const [pass,setPass] = useState(true)  

  const [srcImgPerfil,setSrcImgPerfil] = useState('-1')
  

  const changeFile = evt => {
    let {files} = evt.target
    if(files.length===0)return
    if(!files[0].type.includes('image/')){
        setModalAlert({text:'Solo puedes elegir imagenes',visible:true})
        return;
    }
    let file = evt.target.files[0]
    let image = new Image()
    image.src = URL.createObjectURL(file);
    cropper.replace(URL.createObjectURL(file))
    evt.target.value=""
    setModalCropper({visible:true})
  }

  const cancelCropper = () => {
    cropper.replace('')
    setModalCropper({visible:false})
  }

  const acceptCropper = () => {
    setLoading(true)
    setModalCropper({visible:false})
    let src = cropper.getCroppedCanvas().toDataURL()
    let imagen = new Image()
    imagen.onload = () => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        canvas.width = imagen.width;
        canvas.height = imagen.height;
        ctx.drawImage(imagen, 0, 0);
        canvas.toBlob((blob)=>{
          let reader = new FileReader();
          reader.readAsDataURL(blob); 
          reader.onloadend = () => {
            let {setsrcPerfil} = props
            let base64data = reader.result;
            setsrcPerfil(base64data)
            document.getElementById('imgPerfil').src = base64data
            setSrcImgPerfil(base64data)
            setLoading(false)
          }
        },"image/webp")
    };
    imagen.src=src;
  }

  const submitContrasena = () => {
    let {contra} = data
    let key = document.getElementById('contraseniad')

    if(key.value !== contra ){
      setModalAlert({text:'La contraseña ingresada es incorrecta.',visible:true})
      return
    }
    setModalContrasena({visible:false})
    setChangingPW(true)
    return
  }

  const closeModalContrasena = () => {
    document.getElementById('contraseniad').value=""
    setModalContrasena({visible:false})
  }

  const puedMoverMiCuenta_ = () => {
    setLoading(true)
    axios.post(`${host}usuarios/usuarios/pendientesBubooker`,{idB:sessionStorage.getItem('id')},request).then(rt=>{
      setLoading(false)
      if(rt.data['status']==='ok'){
        let pedidos = Number(rt.data['data'][0]['PEDIDOS'])
        let citas = Number(rt.data['data'][0]['CITAS'])
        if(pedidos>0){
          setModalAlert({visible:true,text:'No puedes eiminar tu cuenta porque tienes pedidos pendientes.',})
        }else if(citas>0){
          setModalAlert({visible:true,text:'No puedes eiminar tu cuenta porque tienes citas pendientes.',})
        }else{
          setModalConfirmEliminarCuenta({visible:true})
        }
      }else{
        setModalAlert({visible:true,text:'Ocurrio un error mientras cosultamos tu información. Por favor intentalo más tarde.',})
      }
    })
  }

  const eliminarCuenta = () => {
    axios.post(`${host}payment/GatewayStripe/deleteBuBookerWeb`,{user:sessionStorage.getItem('id')},request).then(rt=>{
      if(Number(rt.data['result'])===1){
        setModalCuentaEliminada({visible:true})
        setModalConfirmEliminarCuenta({visible:false})
      }
    })
  }

  const fakeClick = id =>{
    document.getElementById(id).click()
  }

  const submit = () => {

    let formData = new FormData(document.getElementById('formulaCliente'))
    let HTTPHeader = {}
    for(let [key,value] of formData.entries()){
      HTTPHeader[key] = value.trim()
      if(value.trim()==='' && key!=='contra' && key!=='contra2'){
        setModalAlert({text:`El campo ${key} no puede estar vacío`,visible:true})
        return
      }
    }
    let telefono = HTTPHeader['telefono']
    telefono = telefono.trim().split('(').join('').split(')').join('').split(' ').join('').split('_').join('')
    if(telefono.length<10){
      setModalAlert({text:`El campo telefono no cumple con el formato`,visible:true})
      return
    }

    let newContra = contra

    if(changingPW){
      newContra = formData.get('contra')
      let newContra2 = formData.get('contra2')
      
      if(newContra.length < 6 ){
        setModalAlert({text:`El campo contraseña debe tener al menos 6 caracteres.`,visible:true})
        return
      }

      if(newContra.length > 0 && newContra.trim()===""){
        setModalAlert({text:'La contraseña debe contener al menos una letra,número, o caracter especial',visible:true})
        return
      }

      if( newContra !== newContra2 ){
        setModalAlert({text:`Las contraseñas ingresadas no coinciden.`,visible:true})
        return
      }
    }

    HTTPHeader['telefono'] = telefono
    HTTPHeader['sexo'] = data['sexo']
    HTTPHeader['fechaN'] = data['fechaN'].format('YYYY-MM-DD')
    HTTPHeader['src'] = ''
    HTTPHeader['Op'] = 2
    HTTPHeader['srcImgPerfil'] = srcImgPerfil
    HTTPHeader['contra'] = newContra
    HTTPHeader['status'] = data['status']

    HTTPHeader['id'] = data['id']
    HTTPHeader['notiCorreo'] = data['notiCorreo']
    HTTPHeader['notiPush'] = data['notiPush']
    HTTPHeader['notiMsn'] = data['notiMsn']
    
    setModalSpinner({visible:true,text:'Guardando cambios'})

    axios.post(`${host}usuarios/usuarios/registrar`,HTTPHeader,request).then(rt=>{
      if(Number(rt.data)===-2){
        setModalSpinner({visible:false,text:''})
        setModalAlert({visible:true,text:'Los cambios se guardaron correctamente'})

        let dataA = Object.assign({},data)
        dataA['nombre'] = HTTPHeader['nombre']
        dataA['apellidos'] = HTTPHeader['apellidos']
        dataA['telefono'] = HTTPHeader['telefono']
        dataA['nombre'] = HTTPHeader['nombre']
        dataA['contra'] = HTTPHeader['contra']

        const {updateState} = props

        setData(dataA)
        setContra(newContra)
        setChangingPW(false)
        updateState('nopuedoCambiarSinAvisarte',false)

      }
      else{
        setModalSpinner({visible:false,text:''})
        setModalAlert({visible:true,text:'Parece que hubo un error, por favor vuelve a intentarlo más tarde'})
      }
    })
  }

  const errorImg = (evt) => {
    evt.target.src = panelAdmin+'perfilDefault.jpg'
  }

  const changeData = (index,value) => {
    let dataA = Object.assign({},data)
    let {setMensaje,updateState} = props    
    setMensaje(0)
    updateState('nopuedoCambiarSinAvisarte',true)
    dataA[index] = value
    setData(dataA)
  }

  const changeDataInput = () => {
    let {setMensaje,updateState} = props
    setMensaje(0)
    updateState('nopuedoCambiarSinAvisarte',true)
  }

  const [first,setFirst] = useState(true)

  useEffect(()=>{
    if(first){
      let {makeRef} = props
      makeRef({mounted:true,component:this,name:'Config'})
      setFirst(false)
    }
  },[props,first])

  useEffect(()=>{
    (()=>{
      let HTTPHeader = {id:sessionStorage.getItem('id')}
      setLoading(true)
      axios.post(`${host}usuarios/usuarios/detalleUsuario`,HTTPHeader,request).then(rt=>{
        if(rt.data['status']==='OK'){
          setLoading(false)
          let buildData = {}
          buildData['nombre'] = rt.data['NOMBRE']
          buildData['apellidos'] = rt.data['APELLIDO']
          buildData['fechaN'] = moment(rt.data['FECHANACIMIENTO'],'YYYY-MM-DD')
          buildData['telefono'] = rt.data['TELEFONO']
          buildData['sexo'] = Number(rt.data['SEXO'])
          buildData['mail'] = rt.data['CORREOREGISTRO']
          buildData['contra'] = rt.data['CONTRASENA']
          buildData['src'] = rt.data['DIRECCIONFOTOPERFIL']
          buildData['notiCorreo'] = Number(rt.data['NOTIFICACIONCORREO'])
          buildData['notiPush'] = Number(rt.data['NOTIFICACIONPUSH'])
          buildData['notiMsn'] = Number(rt.data['NOTIFICACIONMSN'])
          buildData['status'] = rt.data['ESTATUS']
          buildData['id'] = rt.data['ID']
          setData(buildData)
          setContra(rt.data['CONTRASENA'])
          document.getElementById('imgPerfil').src = rt.data['FOLDER']+rt.data['DIRECCIONFOTOPERFIL']+'.png?'+Math.random()
        }
      })
    })()
    return () => {  }
  },[])

  return(
    <div className="containerBrowserBubookerChildTwo">

        <Spinner loading={modalSpinner['visible']} text={modalSpinner['text']}/>

        <Modal centered title="Configura tu foto de perfil" closable={false} maskClosable={false} keyboard = {false} 
            okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
            cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',} }}
            visible={modalCropper['visible']}
            forceRender={true}
            className="miAntModal newVar alertMy modalCrop"
            onCancel={cancelCropper}
            onOk={acceptCropper}
            >
            <div className="wrapperCropper" title="Usa el scroll para hacer zoom" >
              <Cropper ref={element => setCropper(element)} src='' style={{height:'100%', width: '100%',zIndex:'5'}} aspectRatio={1} />                      
            </div>
        </Modal>

        <Alert text={modalAlert['text']} visible={modalAlert['visible']} onOk={ () => setModalAlert({visible:false,text:''}) } />
        
        <Modal centered title='Cambiar contraseña' maskClosable={false} keyboard = {false} 
          okText="Aceptar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
          cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
          visible={modalContrasena['visible']} className="miAntModal alertMy modalUnidad" width={400}
          onOk={submitContrasena} destroyOnClose
          onCancel={closeModalContrasena}>

          <div className="miUniDiv">
                          
            <div className="inputs-group">
              <label className="inputs-label" style={{top:'-10px'}} > Ingrese su contraseña actual </label>
              <Input.Password type="password" autoFocus className="inputs-input" id="contraseniad" />
            </div>

          </div>

        </Modal>

        <Modal centered title="¿Estás seguro que deseas eliminar tu cuenta?" maskClosable={false} keyboard = {false} 
              okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
              cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
              visible={modalConfirmEliminarCuenta['visible']}
              closable={false}
              onOk={eliminarCuenta}
              onCancel={() => setModalConfirmEliminarCuenta({visible:false})}
              className="miAntModal newVar alertMy" >
              <div className="myAlertBody">
                <span className="txtAlert" style={{textAlign:'justify',padding:'5px'}} >
                  Si lo haces, tu perfil será desactivado y se perderán permanentemente tus datos, mensajes, pedidos y reservaciones. Esta acción no se puede deshacer.
                </span>
              </div>
        </Modal>

        <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
            okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
            cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',display:'none'} }}
            visible={modalCuentaEliminada['visible']}
            closable={false}
            onOk={()=>{
              let {cerraSesion,indiceRedirecter} = props
              indiceRedirecter('/')
              cerraSesion(true)
            }}
            className="miAntModal newVar alertMy"
            >
            <div className="myAlertBody" style={{textAlign:'justify',padding:'5px'}} >
              <span className="txtAlert"> Se ha programado tu cuenta para eliminarse el día {moment().add(30,'days').format('DD MMMM YYYY')}. Recuerda que puedes reactivarla iniciando sesión.</span>
            </div>
        </Modal>

        <Loading loading={loading} />


        <div className="perfilCliente">
          
          <div className="head">
            <div className="srcPerfil">
              <input type="file" accept="image/*" id="inputPerfil" onChange={changeFile} />
              <label htmlFor="inputPerfil"><img className="inputNewPortada"  src={img+'new_camera_icon.png'} alt="..." /></label>
              <img alt="..." id="imgPerfil" onError={errorImg} />
            </div>
            <div className="name flexcentercenter" > { data['nombre']+' '+data['apellidos']} </div>
            <div className="mail flexcentercenter"> {data['mail']} </div>
          </div>

          <div className="body">
            <div className="wrapperForm">
              <form id="formulaCliente">

                <div className="flexcenter Fspaceb inputsEnLinea">
                  <div className="inputs-group">
                    <label className="inputs-label" htmlFor="nombred" > Nombre (s) </label>
                    <input type="text" className="inputs-input" name="nombre" id="nombred" defaultValue={data['nombre']} onChange={changeDataInput} />
                  </div>

                  <div className="inputs-group">
                    <label className="inputs-label" > Apellido (s) </label>
                      <input type="text" className="inputs-input" name="apellidos" id="apellidosd" defaultValue={data['apellidos']} onChange={changeDataInput} />
                  </div>
                </div>

                <div className="flexend Fspaceb inputsEnLinea">

                  <div className="inputs-group">
                    <label className="inputs-label"> Fecha de nacimiento </label>
                    <DatePicker disabledDate= { d => d.isAfter(moment().subtract(14,'years') ) } format="DD/MM/YYYY" locale='es-MX' value={data['fechaN']} allowClear={false} placeholder="" name="fechaN" onChange={value=>changeData('fechaN',value)} className="MyDate" />
                  </div>

                  <div className="inputs-group2 flexspacebend">
                    <label className="inputs-label" > Sexo </label>
                    <div className="contSexs flexspacebend">
                      <div className={"flexcentercenter fakeSex "+(data['sexo']===1?'active':'')} onClick={()=>changeData('sexo',1)} > Hombre </div>
                      <div className={"flexcentercenter fakeSex "+(data['sexo']===2?'active':'')} onClick={()=>changeData('sexo',2)} > Mujer </div>
                    </div>
                  </div>

                </div>

                <div className="flexcenter Fspaceb inputsEnLinea">

                  {
                    data['telefono'] !== -1 && 
                    <div className="inputs-group">
                      <label className="inputs-label" > Teléfono </label>
                      <NumberFormat format="(###) ### ####" mask="_" defaultValue={data['telefono']} onChange={changeDataInput} type="text" className="inputs-input" name="telefono" id="telefonod" />
                    </div>
                  }

                  <div className="inputs-group">
                    <label className="inputs-label" > Correo electrónico </label>
                    <input type="text" className="inputs-input readOnly" name="mail" id="maild" defaultValue={data['mail']} readOnly />
                  </div>

                </div>

                {
                  !changingPW?
                  <div className="changer flexcentercenter">
                    <span onClick={()=>setModalContrasena({visible:true})} > ¿Cambiar contraseña? </span>                
                  </div>:
                  <React.Fragment>
                    <h3 className="labelnewPW" > Ingrese la nueva contraseña </h3>
                        
                    <div className="inputs-group">
                      <label className="inputs-label" > Contraseña </label>
                      <Input defaultValue={contra} className="inputs-input"
                          type={pass?'password':'text'}
                          name="contra"
                          suffix={<Icon type={pass?'eye-invisible':'eye'} onClick={()=> setPass(!pass) } />  } />
                    </div>


                    <div className="inputs-group" style={{marginBottom:'10px'}} >
                      <label className="inputs-label" > Repetir contraseña </label>
                      <Input className="inputs-input" type={pass?'password':'text'} name="contra2"  />
                    </div>

                  </React.Fragment>
                }

                <div className="separadorGris"/>

                <div className="cardBodyOption flexspacebcenter" onClick={()=>fakeClick('notiCorreo')} >
                  <label className=""> Notificaciones por correo </label>
                  <Switch id="notiCorreo" checked={data['notiCorreo'] === 1 } className="myswitch" onChange={check => changeData('notiCorreo',check?1:2)} />
                </div>

                <div className="cardBodyOption flexspacebcenter" onClick={()=>fakeClick('notiPush')} >
                  <label className=""> Notificaciones push </label>
                  <Switch id="notiPush" checked={data['notiPush'] === 1 } className="myswitch" onChange={check => changeData('notiPush',check?1:2)} />
                </div>

                <div className="cardBodyOption flexspacebcenter" onClick={()=>fakeClick('notiMsn')} >
                  <label className=""> Notificaciones por mensaje </label>
                  <Switch id="notiMsn" checked={data['notiMsn'] === 1 } className="myswitch" onChange={check => changeData('notiMsn',check?1:2)} />
                </div>

                {/*<div className="separadorGris"/>*/}

                <div className="cardBodyOption flexspacebcenter" onClick={puedMoverMiCuenta_} >
                  <label className=""> Eliminar mi cuenta </label>
                  <Switch checked={data['eliminada'] } className="myswitch" />
                </div>
                              
              </form>
            </div>

            <div className="divConBotton flexcenter Fend">
              <button onClick={submit} > Guardar </button>
            </div>

          </div>
        </div>
      </div>
  )
}

export default withRouter(Configuracion);