import React from 'react';
import axios from 'axios'; 

import html2canvas from 'html2canvas';

import { Host,ImgGral as img2 ,Img,shortcutANDROID} from '../app.json';
import { Modal, Icon,Cascader,Popover,Badge,Tooltip,message} from 'antd';
import { Globalrequest as request, isLogged , isOtraPestana } from '../request.js';

import { CSSTransition } from "react-transition-group"; 
import { BrowserRouter , Route, Link,withRouter } from "react-router-dom";

import SocialMedia from './../modalCargando/sociallinks'

import {
  //Carrito,
  Ubicacion,
  //Corazon
} from "./../utilities/svg.js";

import {copyTextGeneral,removeacentos} from './../utilities/generalfunctions'

import {ReportarProblema,InvitarAmigos} from './principal/makereport';

import Home from './principal/home'
import CarritoModal from './principal/carrito'
import Perfil from './principal/perfil';
import Configuracion from './../home/bubooker/router';
import ModalAlert from './../modalCargando/modalAlert';
import ListFavs from './../home/bubooker/favoritos/lista'
import Notificaciones from './../home/bubooker/notificaciones/lista'
import './indice.scss';

const rutas = [
  { path: '/', Component: Home },
  { path: '/empresas/:usuario', Component: Perfil },
  { path: '/config', Component: Configuracion },
  { path: '/config/:any', Component: Configuracion },
  { path: '/config/:any/:any', Component: Configuracion },
]

class ModalAgradecimiento extends React.Component{
  constructor(props){
    super(props)
    this.exportD = this.exportD.bind(this)
    this.state={
      downloading:false,
    }
  }
  exportD(op){
    
    document.querySelector('#franjaNaranja').classList.toggle('visible')
    document.querySelector('#franjaGris').classList.toggle('visible')

    this.setState({downloading:true})

    setTimeout(() =>
      html2canvas(document.querySelector("#capture")).then(canvas => {

        let {folioAgra} = this.props
        var download = document.getElementById("down");
        var image = canvas.toDataURL("image/png").replace("image/png", "image/octet-stream")
        download.setAttribute("href", image);
        download.setAttribute("download", 'ComprobandePedido#'+folioAgra+'.png');
        
        download.click()
        this.setState({downloading:false})

        document.querySelector('#capture').style.backgroundimage = "none"
        document.querySelector('#franjaNaranja').classList.toggle('visible')
        document.querySelector('#franjaGris').classList.toggle('visible')

        //if(op===1){      }
        /*else{ canvas.toBlob(blob => navigator.clipboard.write( [ new ClipboardItem({'image/png': blob})] ) ) }*/
      }).catch(e => console.log(e) )
    ,1000)

  }

  copyText(id,meta){
    let a = copyTextGeneral(id)
    if(a.startsWith('ok'))
      message.success(meta+' copiado al portapapeles')
    else
      console.log(a)
  }

  render(){
    return(
      <Modal centered closable={false} footer={null} title={null}
        visible={this.props.visible}
        onCancel={()=>this.props.handler(false)}
        style={{top:0}}
        width={585}
        className="modalBaseBuBooker" >
        
         <div className="selectFind viewMora bgplayfull" >

            <div className="head2 flexcenter Fspaceb" style={{marginTop:'-10px',width:'100%'}} >
              <img src={`${img2}elementos/Grupo19236N.png`} alt="..." className="icon-alert" style={{marginLeft:'-10px'}} />
              <Icon type="close" onClick={()=>this.props.handler(false)} />
            </div>
            
            <div className="body2" id="capture" >

              <div id="franjaNaranja" />

              <div className=" flexcenter Fcenter imgCheck" >
                <img alt="..." src={Img+'tick.png'} />
              </div>

              <div className="title black mdmd"> ¡Gracias por realizar tu pedido!  </div>
              
              <div className="infoPedido">

                <div className="info "> Folio #{this.props.folioAgra} </div>
                <div className="info "> {this.props.fechaHoraAgra} </div>

                <div className={"info status "+this.props.estatusAgra.split(' ').join('').trim().toLowerCase()}> {this.props.estatusAgra} </div>

                <div className="info "> Total $ {this.props.totalAgra} MXN </div>

                <div className="info "> Método de pago {this.props.tipoPago}  </div>

                <div className="info "> Pago {this.props.referenciaAgra} </div>

              </div>
              {
                (this.props['datosTarjeta'] && this.props['datosTarjeta'] !== 'none') &&
                <div>
                  <div className="tarjetaEmbded mini flextop Fspaceb">
                    
                    <div>

                      <div className="info flex">
                        <div className="subinfo flex">
                          <div className="meta" style={{fontWeight:'bold',fontSize:'15px'}} > Datos de tranferencia </div>
                        </div>
                      </div>
                      
                      <div className="info flex"> <div className="meta" style={{fontWeight:'bold'}} >{this.props['datosTarjeta']['nombreBanco'] }</div>   </div>
                      <img alt="..." className="nomg" src={ this.props['datosTarjeta']['src'] } width={110} />

                    </div>

                    <div className="flexcenter" style={{marginLeft:'15px'}} >
                      <div>
                        <div className="info flextop Fspaceb">
                          <div className="subinfo flex">
                            <div className="meta" style={{fontWeight:'bold'}} >Titular: </div>
                            <div className="content"> {this.props['datosTarjeta']['titular']} </div>
                          </div>
                        </div>

                        <div className="info flexcenter Fspaceb">
                        <div className="subinfo flex">
                          <div className="meta" style={{fontWeight:'bold'}} >Núm. Cuenta: </div>
                          <div className="content" id="copyA1" > {this.props['datosTarjeta']['cuenta']} </div>
                        </div>

                        <Icon type="copy" onClick={()=>this.copyText('copyA1','Número de Cuenta')} />
                      </div>
                      </div>
                      <div>
                        <div className="info flexcenter Fspaceb">
                          <div className="subinfo flex">
                            <div className="meta" style={{fontWeight:'bold'}} >Núm. Tarjeta: </div>
                            <div className="content" id="copyA2"> {this.props['datosTarjeta']['tarjeta']} </div>
                          </div>
                          
                          <Icon type="copy" onClick={()=>this.copyText('copyA2','Número de Tarjeta')} />
                        </div>
                        
                        <div className="info flexcenter Fspaceb">
                        
                        <div className="subinfo flex">
                          <div className="meta" style={{fontWeight:'bold'}} >Cuenta CLABE: </div>
                          <div className="content" id="copyA3" > {this.props['datosTarjeta']['clabe']} </div>
                        </div>

                        <Icon type="copy" onClick={()=>this.copyText('copyA3','Cuenta CLABE')} />
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="ntaPed" style={{marginBottom:'8px'}} >
                    Recuerda ingresar la referencia de pago al realizar la transferencia. Una vez realizado el pago, carga el comprobante de transferencia en el apartado "Mis compras".
                    <br></br>
                  </div>
                </div>
              }

              <div className="ntaPed">
                Podrás darle seguimiento en el apartado de<br/>
                "Mis compras"
              </div>

              <div id="franjaGris" />

            </div>

            <div className="groupIcons flexcenter Fcenter">

            {
              /*
              <Popover title={null} trigger="hover" placement="top"
                content={
                  <div className="Share-Popover">
                    <Tooltip title={<span> Descargar como imagen </span>} trigger="hover"> <div  className="down" onClick={()=>this.exportD(1)} > <Icon type="download" /> </div> </Tooltip>
                    <Tooltip title={<span> Copiar al portapapeles </span>} trigger="hover"> <div className="copy" onClick={()=>this.exportD(2)} > <Icon type="copy" /> </div> </Tooltip>
                  </div>
                } overlayClassName="poopShare"  >
                <i>
                  <Compartir className="compartir" />
                </i>
              </Popover>
              */
            }

            <div className="Share-Popover">
              
              {
               this.state.downloading ?
               <Icon type="loading" style={{fontSize:'25px'}} />:
               <Tooltip title={<span> Descargar como imagen </span>} trigger="hover"> <div  className="down" onClick={()=>this.exportD(1)} > <Icon type="download" /> </div> </Tooltip>
              }
              

            </div>

            </div>

            <div style={{marginTop:'10px'}} className="flexcenter Fcenter">
              <button disabled={this.state.downloading} className="btn btn1" onClick={()=>this.props.handler(false)} style={{width:'95%',fontSize:'17px'}} > Aceptar </button>
            </div>

          </div>

         <a id="down" href="#" > . </a>


      </Modal>
    )
  }

}


class Index extends React.Component{
  constructor(props) {
    super(props);

    this.showDataComponentMounted = this.showDataComponentMounted.bind(this)
    
    this.verificaDatos = this.verificaDatos.bind(this)
    this.onChangeCascader = this.onChangeCascader.bind(this)
    this.getEstadosCiudades = this.getEstadosCiudades.bind(this)
    this.actualizarEmpresas = this.actualizarEmpresas.bind(this)
    this.actualizarCategorias = this.actualizarCategorias.bind(this)
    this.actualizarEmpresasToSearch = this.actualizarEmpresasToSearch.bind(this)

    this.setIntoView = this.setIntoView.bind(this)
    this.getLocation = this.getLocation.bind(this)
    this.ciudadEstadoEnMemorio = this.ciudadEstadoEnMemorio.bind(this)
    this.aceptarCiudad = this.aceptarCiudad.bind(this)
    this.redirectTo = this.redirectTo.bind(this)
    this.redirectToMenu = this.redirectToMenu.bind(this)
    
    this.enter = this.enter.bind(this)
    this.leave = this.leave.bind(this)
    this.searchCategoria = this.searchCategoria.bind(this)

    this.focus = this.focus.bind(this)
    this.blur = this.blur.bind(this)

    this.stateHandler = this.stateHandler.bind(this)
    this.saveBeforeLogin = this.saveBeforeLogin.bind(this)
    this.filtrarEmpresas = this.filtrarEmpresas.bind(this)
    this.busqeda = this.busqeda.bind(this)
    this.irAlaEmpresa = this.irAlaEmpresa.bind(this)
    this.openCarrito = this.openCarrito.bind(this)
    this.controlAlert = this.controlAlert.bind(this)
    this.controlAgra = this.controlAgra.bind(this)

    this.actualizarNotificaciones = this.actualizarNotificaciones.bind(this)

    this.actualizaCarritoContador = this.actualizaCarritoContador.bind(this)

    this.totalEnCarrito = this.totalEnCarrito.bind(this)

    this.eliminarProductoFromCarritoFromPerfil = this.eliminarProductoFromCarritoFromPerfil.bind(this)

    this.openModalReport = this.openModalReport.bind(this)

    this.state={
      cascaderOptions:[],
      /**/
      permisoUbicacion:false,
      estadoUsuario:localStorage.getItem('estadoUsuario'),
      ciudadUsuario:localStorage.getItem('ciudadUsuario'),
      latitude:0,
      longitude:0,
      /**/
      empresasBusqueda:[],
      empresasBusquedaFiltradas:[],
      empresasBusquedaFiltradas2:[],
      popularesFiltradas:[],
      showFilter:false,
      valueSearch:'',
      /**/
      empresasPopulares:[],
      empresasNuevas:[],
      empresasCercanas:[],
      categorias:[],
      categoriaActual:{HIJOS:[],},
      /**/
      favoritos:[],
      /**/
      positionOption:{enableHighAccuracy: true,},
      modalLocation:false,
      modalNoLocation:false,
      modalNoFound:false,
      txtAlert:'',
      modalAlert:false,
      modalVerMas:false,
      /**/
      first:true,
      /**/
      actualpage:'',
      /**/
      withUser:isLogged(),
      srcPerfil:'',
      nombreUsuario:'',
      /**/
      showCarrito:false,
      buscandoCategoria:false,
      categoriaBuscando:'',
      /**/
      countCarrito:0,
      countNoti:0,
      /**/
      modalMakeReport:false,
      /**/
      modalAgradecimiento:false,
      estatusAgra:'',
      folioAgra:'',
      fechaHoraAgra:'',
      referenciaAgra:'',
      totalAgra:'',
      /**/
      dataR:{
        nombreR:'',
        telefonoR:'',
        correoR:'',
      },
      invitanding:false,
    }
  }

  componentDidMount(){
    document.title="BuBook Mx"
    let {withUser} = this.state
    this.getLocation()
    this.getEstadosCiudades();
    this.actualizarEmpresas();
    this.actualizarCategorias()
    this.actualizarEmpresasToSearch()
    if(withUser){
      let {srcPerfil,nombreUsuario} = this.state
      srcPerfil = sessionStorage.getItem('src')+'?'+Math.random()
      nombreUsuario = atob(sessionStorage.getItem('nameb'))
      this.setState({srcPerfil,nombreUsuario})

      this.actualizarNotificaciones()
      this.TimerInterval = setInterval(()=>this.actualizarNotificaciones(),1000*60*2)
      
      let userType = localStorage.getItem('userType')
      let typeUser = localStorage.getItem('typeUser')
      if(userType==='user'|| Number(typeUser) ===1){//es bubooker
        setTimeout( this.actualizaCarritoContador , 2 * 1000);
        this.verifyValid()
      }
    }

  }
  componentWillMount(){
    this.verificaDatos()
    clearInterval(this.TimerInterval)
  }

  componentWillUnmount(){
    window.navigator.geolocation.clearWatch(this.watcher)

  }

  showDataComponentMounted(){ return this.child }

  verifyValid(){
    let valid = Number(sessionStorage.getItem('isEliminada'))
    sessionStorage.removeItem('isEliminada')
    if(valid===4){
      axios.post(`${Host}payment/GatewayStripe/resumeBuBookerWeb`,{user:sessionStorage.getItem('id')},request).then(rt => {
        if(Number(rt.data['result'])===1){
          this.controlAlert(true,rt.data['Se ha reactivado tu cuenta.'])
        }else{
          this.setState({modalCuentaEliminadaBad:true})
        }
      });
    }
  }

  actualizaCarritoContador(){
    let {countCarrito} = this.state
    let metadata = JSON.parse(sessionStorage.getItem('metadata'))
    if(metadata===undefined||metadata===null)return
    metadata = JSON.parse(sessionStorage.getItem('metadata'))['datos']
    let carrito = JSON.parse(localStorage.getItem('carrito'))||{}
    let indexB = 'Usuario['+metadata['METAID']+']'
    carrito = carrito[indexB]||{ id: metadata['id'], EMPRESAS:{}, }
    countCarrito = Object.values(carrito['EMPRESAS']).reduce(
      (resultado,empresa) => resultado += Object.values(empresa['SUCURSALES']).reduce(
        (totalSucursales,sucursal)=>totalSucursales += Object.values(sucursal['PRODUCTOS']).reduce(
          (totalProductos,producto) =>{
            totalProductos+=Number(producto['cantidad'])
            if(producto['hijo'].hasOwnProperty('precio')) totalProductos+=Number(producto['hijo']['cantidad'])
            return totalProductos
          }
        ,0)
      ,0)
    ,0)
    this.setState({countCarrito})
  }

  totalEnCarrito(indexEmpresa,indexSucursal,productoFromCarrito,metakey,excluding){
    let metadata = JSON.parse(sessionStorage.getItem('metadata'))
    if(metadata===undefined||metadata===null)return 0;
    metadata = JSON.parse(sessionStorage.getItem('metadata'))['datos']
    let carrito = JSON.parse(localStorage.getItem('carrito'))||{}
    let indexB = 'Usuario['+metadata['METAID']+']'
    carrito = carrito[indexB]||{ id: metadata['id'], EMPRESAS:{}, }
    if(excluding){//Excluyendo el producto que hay en carrito
      if( carrito['EMPRESAS'][indexEmpresa] && carrito['EMPRESAS'][indexEmpresa]['SUCURSALES'][indexSucursal] ){
        let
          keyEliminando2 = productoFromCarrito['metakey'] ,
          subKeyEliminando2 = productoFromCarrito['hijoMetaKey'] ,
          keyPromoEliminando2 = productoFromCarrito['promo'],
          listaProductos = carrito['EMPRESAS'][indexEmpresa]['SUCURSALES'][indexSucursal]['PRODUCTOS']
          let nuevalista = listaProductos.filter(p =>{
            if(p['metakey'] === keyEliminando2){
              if(Number(p['tipoPromo']) > 2 && subKeyEliminando2!==-1 && subKeyEliminando2 !== '-1' ){//producto con promo, y eliminando un hijo
                return !(p['hijo']['metakey'] === subKeyEliminando2 && p['dependenciaPromocionMETA'] === keyPromoEliminando2)
              }else if(subKeyEliminando2!==-1 && subKeyEliminando2!=='-1'){//eliminando un paquete con hijo
                return true
              }else if(Number(p['tipoPromo']) > 2){
                return true
              }
              return p['metakey'] !== keyEliminando2
            }
            return true
          })
          let countCarrito = Object.values(nuevalista).reduce(
            (resultado,producto) => {
              if(producto['metakey'] === metakey) resultado+=producto['cantidad'];
              if(Number(producto['tipoPromo']) > 2 && producto['hijo']['metakey'] === metakey)
                resultado+=producto['hijo']['cantidad'];
              return resultado
            }
            ,0)
          return countCarrito;
      }
    }else{
      if( carrito['EMPRESAS'][indexEmpresa] && carrito['EMPRESAS'][indexEmpresa]['SUCURSALES'][indexSucursal] ){
        let countCarrito = Object.values(carrito['EMPRESAS'][indexEmpresa]['SUCURSALES'][indexSucursal]['PRODUCTOS']  ).reduce(
          (resultado,producto) => {
            if(producto['metakey'] === metakey) resultado+=producto['cantidad'];
            if(Number(producto['tipoPromo']) > 2 && producto['hijo']['metakey'] === metakey)
              resultado+=producto['hijo']['cantidad'];
            return resultado
          }
          ,0)
        return countCarrito;
      }
    }

    return 0;
  }

  eliminarProductoFromCarritoFromPerfil(indexEmpresa,indexSucursal,productoFromCarrito){
    let metadata = JSON.parse(sessionStorage.getItem('metadata'))
    metadata = JSON.parse(sessionStorage.getItem('metadata'))['datos']
    let carrito = JSON.parse(localStorage.getItem('carrito'))
    let 
      indexB = 'Usuario['+metadata['METAID']+']'  ,
      listaProductos = carrito[indexB]['EMPRESAS'][indexEmpresa]['SUCURSALES'][indexSucursal]['PRODUCTOS']
    let 
      keyEliminando2 = productoFromCarrito['metakey'] ,
      subKeyEliminando2 = productoFromCarrito['hijoMetaKey'] ,
      keyPromoEliminando2 = productoFromCarrito['promo']
    let nuevalista = listaProductos.filter(p =>{
      if(p['metakey'] === keyEliminando2){
        if(Number(p['tipoPromo']) > 2 && subKeyEliminando2!==-1 && subKeyEliminando2 !== '-1' ){//producto con promo, y eliminando un hijo
          return !(p['hijo']['metakey'] === subKeyEliminando2 && p['dependenciaPromocionMETA'] === keyPromoEliminando2)
        }else if(subKeyEliminando2!==-1 && subKeyEliminando2!=='-1'){//eliminando un paquete con hijo
          return true
        }else if(Number(p['tipoPromo']) > 2){
          return true
        }
        return p['metakey'] !== keyEliminando2
      }
      return true
    })
    carrito[indexB]['EMPRESAS'][indexEmpresa]['SUCURSALES'][indexSucursal]['PRODUCTOS'] = nuevalista
    localStorage.setItem('carrito',JSON.stringify(carrito))
  }


  verificaDatos(){
    let {withUser} = this.state

    let userType = localStorage.getItem('userType')
    let typeUser = localStorage.getItem('typeUser')

    if(withUser && !isOtraPestana()){//misma pestaña
      if(userType==='nouser'|| Number(typeUser) ===2){//empresa
        this.props.redirectTo('/home')
      }
    }
    else if(withUser && isOtraPestana() ){//otra pestaña
      if(userType==='user'|| Number(typeUser) ===1){//es bubooker
        let data = {mail:localStorage.getItem('mail'),op:-1}
        axios.post(`${Host}usuarios/usuarios/datosPorUsuario`,data,request).then(rt2=>{
          if(rt2.data['status']==='ok'){
            let {srcPerfil,nombreUsuario} = this.state
            localStorage.setItem('id',rt2.data['datos']['id'])
            localStorage.setItem('mail',rt2.data['datos']['maile'])
            localStorage.setItem('userType','user')
            localStorage.setItem('typeUser','1')

            sessionStorage.setItem('id',rt2.data['datos']['id'])
            sessionStorage.setItem('mail',rt2.data['datos']['maile'])

            nombreUsuario = rt2.data['datos']['nombre']+' '+rt2.data['datos']['apellido']
            srcPerfil = rt2.data['datos']['src']

            sessionStorage.setItem('nameb',btoa(nombreUsuario) )
            sessionStorage.setItem('src',srcPerfil)

            sessionStorage.setItem('metadata',JSON.stringify(rt2.data))
            if(this.child['mounted'] && this.child['component'].actualizarFavoritos ) {
              this.child['component'].actualizarFavoritos()
            }
            this.setState({nombreUsuario,srcPerfil})
          }
          else{
            localStorage.removeItem('id')
            localStorage.removeItem('mail')
            localStorage.getItem('userType')
            localStorage.getItem('typeUser')
            window.location.reload()
          }
        })
      }
      else if(userType==='nouser'|| Number(typeUser) ===2){//empresa
        this.props.redirectTo('/home')
      }
    }
    else{    }
  }

  onChangeCascader(value){
    if(value.length===0) localStorage.setItem('ciudadUsuario',value)
    else{
      localStorage.setItem('estadoUsuario',value[0])
      localStorage.setItem('ciudadUsuario',value[1])
      this.setState({estadoUsuario:value[0],ciudadUsuario:value[1]})
      setTimeout(() => this.actualizarEmpresas(), 1);
    }
  }

  getEstadosCiudades(){
    axios.post(`${Host}indice/indice/estadosCiudades`,0,request).then(rt=>{
      let cascaderOptions = []
      let estados = []
      
      for(let registro of rt.data){
        let estadoAux = registro.ESTADO.trim().toLowerCase()
        if(!estados.some(e => e.toLowerCase() === estadoAux))estados.push(registro.ESTADO.trim())
      }
      
      let ciudades = []
      let ciudadesAux = []

      for(let estado of estados){
        ciudadesAux = rt.data.filter(registro => registro.ESTADO.trim().toLowerCase() === estado.toLowerCase())
        ciudadesAux = ciudadesAux.map(registro =>  registro.CIUDAD.trim())
        ciudadesAux = ciudadesAux.filter((valor,indice,vector) => vector.indexOf(valor) === indice )
        ciudades.push(ciudadesAux)
      }

      for(let i=0;i<estados.length;i++){
        if(estados[i] === 0 || estados[i] === '0' || estados[i] === '' )continue
        let children = ciudades[i].map(ciudad => ({label:ciudad , value:ciudad}) )
        let obj = {
          label: estados[i],
          value: estados[i],
          children:children
        }
        cascaderOptions.push(obj)
      }
      this.setState({cascaderOptions:cascaderOptions})
    })
  }

  actualizarEmpresas(){
    //return
    let {estadoUsuario,ciudadUsuario,latitude,longitude} = this.state
    if(estadoUsuario===''||ciudadUsuario==="")return
    let data ={ estado:estadoUsuario,ciudad:ciudadUsuario,lat:latitude,lng:longitude}
    axios.post(`${Host}indice/indice/getDataEmpresas`,data,request).then(rt=>{

      /*let empresasPopulares = rt.data['populares'].concat(rt.data['populares']).concat(rt.data['populares'])
      let empresasNuevas = rt.data['nuevas'].concat(rt.data['nuevas']).concat(rt.data['nuevas'])
      let empresasCercanas = rt.data['cercanas'].concat(rt.data['cercanas']).concat(rt.data['cercanas'])*/

      this.setState({
        empresasPopulares:rt.data['populares'],
        empresasNuevas:rt.data['nuevas'],
        empresasCercanas:rt.data['cercanas'],
      })

      //this.setState({ empresasPopulares, empresasNuevas, empresasCercanas:empresasNuevas, })

    })
  }

  actualizarCategorias(){
    axios.post(`${Host}indice/indice/listaCategorias`,0,request).then(rt=>{
      localStorage.setItem('categorias',JSON.stringify(rt.data))
      this.setState({categorias:rt.data,})
    })
  }

  actualizarEmpresasToSearch(){
    axios.post(`${Host}indice/indice/listaEmpresasGeneral`,0,request).then(rt=>{
      this.setState({empresasBusqueda:rt.data})
    })
  }

  setIntoView(id){
    let {showFilter,buscandoCategoria} = this.state
    let section = document.getElementById(id)
    if(section!==null && section!==undefined){
      section.scrollIntoView(true,{ behavior:"smooth", block: "start"})
    }
    else{
      if(showFilter){
        this.setState({showFilter:false,})
        setTimeout( ()=>document.getElementById(id).scrollIntoView(true,{ behavior:"smooth", block: "start"}), 1*1001 )
      }
      else if(buscandoCategoria){
        this.setState({buscandoCategoria:false,})
        setTimeout( ()=>document.getElementById(id).scrollIntoView(true,{ behavior:"smooth", block: "start"}), 1*1001 )
      }
      else this.redirectTo('/')
    }
  }

  showCategoriasAux(){
    document.getElementById('containerLogo').scrollIntoView({behavior : 'smooth' })
    setTimeout(() => {
      document.querySelector('#triggerPopCat').click()
    }, 800);
  }

  openAppLink(op){
    if(op===1){

    }else if(op === 2){
      window.open(shortcutANDROID)
    }
  }

  getLocation(){
    
    var location = window.navigator && window.navigator.geolocation
    if (location) {
      this.watcher =  location.watchPosition(
        (position) => {
          let data = { lat : position.coords.latitude , lng : position.coords.longitude }
          let {first,latitude,longitude} = this.state

          localStorage.setItem('latitude',position.coords.latitude)
          localStorage.setItem('longitude',position.coords.longitude)

          if(first){
            setTimeout(()=>this.actualizarEmpresas(),10)
            this.setState({first:false,})
          }

          if(latitude === position.coords.latitude && longitude===position.coords.longitude)
            return

          this.setState({ latitude: position.coords.latitude,  longitude: position.coords.longitude,permisoUbicacion:true})

          axios.post(`${Host}empresas/empresas/getLocation`,data,request).then(rt=>{
            if(rt.data['status']!=='OK')return
            let results = rt.data['results'][0]['address_components']
            let ciudadUsuario = ''
            let estadoUsuario = ''
            for(let pos in results ){
              let type = results[pos]['types'][0]
              let value = results[pos]['long_name']
              if(type==='administrative_area_level_1'){ estadoUsuario=value }
              if(type==='locality'){ciudadUsuario =  value}
            }
            if(ciudadUsuario==='' || estadoUsuario === ''){
              if(this.ciudadEstadoEnMemorio())this.setState({modalNoLocation:true})
            }
            else{
              let {cascaderOptions} = this.state
              let aux = cascaderOptions.filter(option => option.value.trim().toLowerCase() === estadoUsuario.trim().toLowerCase())//buscar estado que coincida

              if(aux.length === 0){//estado no encontrado
                if(this.ciudadEstadoEnMemorio())
                  this.setState({modalNoFound:true})
              }
              else{//estado si encontrado
                aux = aux[0]
                let aux2 = aux.children.filter(c => c.value.trim().toLowerCase() === ciudadUsuario.trim().toLowerCase())//buscar ciudad que coincida
                if(aux2.length === 0){//ciudad no encontrada
                  if(this.ciudadEstadoEnMemorio())
                    this.setState({modalNoFound:true})
                }
                else{//ciudad si encontrada
                  this.setState({ ciudadUsuario:ciudadUsuario,estadoUsuario:estadoUsuario})
                  
                  if(first){
                    setTimeout(()=>this.actualizarEmpresas(),1)
                    this.setState({first:false,})
                  }

                  localStorage.setItem('ciudadUsuario',ciudadUsuario)
                  localStorage.setItem('estadoUsuario',estadoUsuario)
                }
              }
            }
          })
        },
        (errorPosition) => {
          if(errorPosition.code === 1){
            if(this.ciudadEstadoEnMemorio())this.setState({modalLocation:true,permisoUbicacion:false})
            console.log('no me diste permiso :c')
          }else{
            console.log('no me diste permiso :c',errorPosition)
          }
        },
        this.state.positionOption
      )
      return true;
    }
  }

  ciudadEstadoEnMemorio(){
    let a = (localStorage.getItem('estadoUsuario') === null || localStorage.getItem('estadoUsuario') === undefined || localStorage.getItem('estadoUsuario') === '' )
    let b = (localStorage.getItem('ciudadUsuario') === null || localStorage.getItem('ciudadUsuario') === undefined || localStorage.getItem('ciudadUsuario') === '' )
    return (a||b);
  }

  aceptarCiudad(modal){
    let {estadoUsuario,ciudadUsuario} = this.state

    if((estadoUsuario === '' || estadoUsuario === null||estadoUsuario === undefined) || (ciudadUsuario === ''||ciudadUsuario === null||ciudadUsuario === undefined)){
      this.setState({modalAlert:true,txtAlert:'Debe elegir una ciudad o activar su ubicación'})
      return
    }
    this.setState({[modal]:false})
  }

  redirectTo(url){
    this.setState({actualpage:url,showFilter:false,popularesFiltradas:[],empresasBusquedaFiltradas:[],empresasBusquedaFiltradas2:[],buscandoCategoria:false,categoriaBuscando:''})
    setTimeout(function(){ document.getElementById('auxToG').click() },1);
  }

  redirectToMenu(url){
    if(this.child['name']==='Menu'){
      this.child['component'].redirectTo(url)
    }
    else{
      this.redirectTo(url)
    }
  }

  addClass(id,clase){
    document.getElementById(id).classList.add(clase)
  }

  removeClass(id,clase){
    document.getElementById(id).classList.remove(clase)
  }

  enter(categoria){
    this.setState({categoriaActual:categoria})
  }
  
  leave(a){
    if(!a){
      let categoria = {HIJOS:[]}
      this.setState({categoriaActual:categoria})
    }
  }

  searchCategoria(categoria){
    let {buscandoCategoria,categoriaBuscando,empresasPopulares,popularesFiltradas,empresasBusquedaFiltradas2,empresasBusqueda,showFilter,empresasBusquedaFiltradas} = this.state
    categoriaBuscando = categoria['DESCRIPCION']
    buscandoCategoria = true
    popularesFiltradas = empresasPopulares.filter(e => e['CATEGORIA'].split('~').some(c => c.toLowerCase().trim() === categoria['DESCRIPCION'].trim().toLowerCase() ) )
    empresasBusquedaFiltradas2 = empresasBusqueda.filter(e =>
      e['NOMBRE_CATEGORIA'].split('~').some(c => c.toLowerCase().trim() === categoria['DESCRIPCION'].trim().toLowerCase() ) && 
      Number(e['SUCURSALES']) > 0 && Number(e['ESTATUS']) === 1
    )
    showFilter=false
    empresasBusquedaFiltradas=[]
    document.getElementById('triggerPopCat').click()
    document.getElementById('searcher').value =""
    document.getElementById('searcher').blur()
    document.getElementById('searchResult').style.display='none'
    if(this.child['name']!=='Home'){
      this.redirectTo('/')
    }

    empresasBusquedaFiltradas2 = empresasBusquedaFiltradas2.map(empresa => {
      empresa['IMG_PERFIL'] = empresa['DIRECCION_IMG_PERFIL']
      empresa['NOMBRE_SOCIAL'] = empresa['NOMBRE_COMERCIAL']
      empresa['CATEGORIA'] = empresa['NOMBRE_CATEGORIA'].split('~')[0]
      return empresa
    })


    this.setState({buscandoCategoria,categoriaBuscando,popularesFiltradas,empresasBusquedaFiltradas2,showFilter,empresasBusquedaFiltradas})
  }
  
  focus(id,clase){
    this.addClass(id,clase)
    let {empresasBusquedaFiltradas} = this.state
    let searchResult = document.getElementById('searchResult')    
    if(empresasBusquedaFiltradas.length>0)
      searchResult.style.display="block"
  }
  
  blur(id,clase){
    this.removeClass(id,clase)
    setTimeout(()=>document.getElementById('searchResult').style.display="none",100)
  }

  stateHandler(field,value){
    this.setState({[field]:value})
  }

  saveBeforeLogin(a){
    if(a===0)this.props.redirectTo('/auth/login')
    else this.props.redirectTo('/auth/registro/bubooker')
  }

  filtrarEmpresas(e){
    let {empresasBusquedaFiltradas,buscandoCategoria,popularesFiltradas} = this.state

    if(e.key==='Enter'&&empresasBusquedaFiltradas.length>0){
      let bandera = document.getElementById('promocionesD')      
      if(bandera===null||bandera===undefined){this.redirectTo('/')}
      let {empresasBusquedaFiltradas2} = this.state
      empresasBusquedaFiltradas2 = empresasBusquedaFiltradas
      empresasBusquedaFiltradas = []
      let value = document.getElementById('searcher').value
      document.getElementById('searcher').value =""
      document.getElementById('searcher').blur()
      document.getElementById('searchResult').style.display='none'
      
      buscandoCategoria = false
      popularesFiltradas=[]

      this.setState({showFilter:true,valueSearch:value,empresasBusquedaFiltradas2,empresasBusquedaFiltradas,buscandoCategoria,popularesFiltradas})
    }
  }

  busqeda(e){
    let {value} = e.target
    let {empresasBusqueda,empresasBusquedaFiltradas} = this.state
    let searchResult = document.getElementById('searchResult')
    value =  value.trim().toLowerCase()
    value = removeacentos(value)
    empresasBusquedaFiltradas = []
    if(value==='')
      searchResult.style.display='none'
    else{
      searchResult.style.display='block'
      let empresasToSearch = empresasBusqueda.filter(e => e['LATITUD'].trim()!=='' && e['LONGITUD'].trim() !== '' )
      for(let empresa of empresasToSearch){
        let categorias = empresa['NOMBRE_CATEGORIA'].split('~')
        let tags = empresa['TAGS'].split('~')
        let byname = removeacentos(empresa['NOMBRE_COMERCIAL'].trim().toLowerCase()).includes(value)
        let bycat = categorias.some( c => removeacentos(c.trim().toLowerCase()).includes(value))
        let bytag = tags.some(t => removeacentos(t.trim().toLowerCase()).includes(value))
        if(byname||bytag||bycat) empresasBusquedaFiltradas.push(empresa)
      }
    }

    empresasBusquedaFiltradas = empresasBusquedaFiltradas.map(empresa => {
      empresa['IMG_PERFIL'] = empresa['DIRECCION_IMG_PERFIL']
      empresa['NOMBRE_SOCIAL'] = empresa['NOMBRE_COMERCIAL']
      empresa['CATEGORIA'] = empresa['NOMBRE_CATEGORIA'].split('~')[0]
      return empresa
    })
    this.setState({empresasBusquedaFiltradas})    
  }

  changeSrcError(evt){
    evt.target.src=img2+'panelAdmin/perfilDefault.jpg';
  }


  preclickIrAlaEmpresa(e, trigger){
    e.stopPropagation()
    const {link} = e.target.dataset
    this.irAlaEmpresa(link)
  }

  irAlaEmpresa(link,metaIDSucursal=false){
    console.log('link',link)    
    document.getElementById('searcher').value=""
    document.getElementById('searcher').blur()
    document.getElementById('searchResult').style.display='none'
    this.setState({empresasBusquedaFiltradas:[],empresasBusquedaFiltradas2:[],buscandoCategoria:false,popularesFiltradas:[],categoriaBuscando:''})
    if(this.child['mounted']&&this.child['name']==='Perfil'){
      this.redirectTo('/empresas/'+link)
      this.child['component'].actualizarPerfil(link,metaIDSucursal)
    }
    else{
      this.redirectTo('/empresas/'+link)
    }
  }

  openCarrito(){
    let {withUser} = this.state
    if(withUser)
      this.setState({showCarrito:true})
    else
      this.setState({modalVerMas:true})
  }

  controlAlert(modalAlert,txtAlert){
    this.setState({modalAlert,txtAlert})
  }

  controlAgra(obj){
    this.setState(obj)
  }

  actualizarNotificaciones(){
    let data = {idB:sessionStorage.getItem('id'),op:1}
    axios.post(`${Host}clientes/clientes/notificacionesPorBubooker`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let {countNoti}= this.state
        countNoti=rt.data['data']
        this.setState({countNoti})
      }
    })
  }

  openModalReport(a,extra={},trigger=true){
    if(a===1){
      let {dataR} = this.state
      let metadata = JSON.parse(sessionStorage.getItem('metadata'))['datos']
      dataR['nombreR'] = metadata['nombre'] + ' ' + metadata['apellido']
      dataR['correoR'] = metadata['mail']
      dataR['telefonoR'] = metadata['telefono']
      dataR['tipoUR'] = 2
      dataR['extra'] = extra
      this.setState({modalMakeReport:true,dataR,})
    }else{
      this.setState({invitanding:true,})
    }
    if(trigger)
      document.getElementById('triggerPerfilID').click()
  }

  render(){
    return(
      <div className="wapperIndex">

        <Modal centered width={450} closable={false} footer={null} title={null}
          visible={this.state.modalVerMas}
          onCancel={()=>this.setState({modalVerMas:false})}
          style={{top:0}}
          className="modalBaseBuBooker mxw3" >
          <div className="selectFind viewMora">

            <div className="head2 flexcenter Fspaceb" style={{marginTop:'-10px'}} >
              <img src={`${img2}elementos/Grupo19236N.png`} alt="..." className="icon-alert"/>
              <Icon type="close" onClick={()=>this.setState({modalVerMas:false})} />
            </div>
            
            <div className="body2">
              
              <div className="title">Para ver más en BuBook </div>
              <div className="title black"> necesitas una cuenta  </div>
              
              <div className="btnGroup" >
                  <div className="flexcenter Fcenter btn btn1" onClick={()=>this.saveBeforeLogin(0)} > Entrar </div>
                  <div className="separatore" > o </div>
                  <div className="flexcenter Fcenter btn btn2" onClick={()=>this.saveBeforeLogin(1)} > ¡Quiero registrarme! </div>
              </div>

            </div>

          </div>
        </Modal>

        <Modal centered title="" maskClosable={false} keyboard = {false} 
            okText="Aceptar"
            okButtonProps={{ className:'btn btn1' , style: { width:'100px',} }}
            cancelButtonProps={{style: { display:'none'} }}
            visible={this.state.modalLocation}
            closable={false}
            onOk={()=>this.aceptarCiudad('modalLocation')}
            className="modalBaseBuBooker mxw4"
        >
          <div className="selectFind">
              <div className="head flexcenter Fcenter">
                  <img src={`${img2}iconos/bubook_blanco.png`} alt="..." className="icon-alert"/>
              </div>

              <div className="body">
                <h4> Personaliza tus busquedas </h4>
                <label> Activa tu ubicación  para poder recomendarte lo mejor cerca de ti, o indica la ciudad que deseas explorar. </label>

                <div className="cascaderWithIcon inverted flexcenter Fstart">
                  <Ubicacion className="MyIcon" />
                  <Cascader onChange={this.onChangeCascader} options={this.state.cascaderOptions}
                    value={[this.state.estadoUsuario,this.state.ciudadUsuario]}
                    placeholder="Ingresar dirección"
                    allowClear={false} expandTrigger="hover" suffixIcon={null}
                    className="cascaderCity" popupClassName="cascaderCityOptions" />
                </div>

              </div>
          </div>
        </Modal>

        <Modal centered title="" maskClosable={false} keyboard = {false} 
            okText="Aceptar"
            okButtonProps={{ className:'btn btn1' , style: { width:'100px',} }}
            cancelButtonProps={{style: { display:'none'} }}
            visible={this.state.modalNoLocation}
            closable={false}
            onOk={()=>this.aceptarCiudad('modalNoLocation')}
            className="modalBaseBuBooker mxw4" >
            <div className="selectFind">
              
              <div className="head flexcenter Fcenter" >
                  <img src={`${img2}iconos/bubook_blanco.png`} alt="..." className="icon-alert"/>
              </div>

                <div className="body">
                  <h4> ¡Ops, no pudimos localizarte! </h4>
                  <label>No pudimos encontrar tu ubicación actual, por favor indica la ciudad que deseas explorar.</label>
                  
                  <div className="cascaderWithIcon inverted flexcenter Fstart">
                    <Ubicacion className="MyIcon" />
                    <Cascader onChange={this.onChangeCascader} options={this.state.cascaderOptions}
                      value={[this.state.estadoUsuario,this.state.ciudadUsuario]}
                      placeholder="Ingresar dirección" allowClear={false} expandTrigger="hover" suffixIcon={null}
                      className="cascaderCity" popupClassName="cascaderCityOptions" />
                  </div>

                </div>
            </div>
        </Modal>

         <Modal centered title="" maskClosable={false} keyboard = {false} 
            okText="Aceptar"
            okButtonProps={{ className:'btn btn1' , style: { width:'100px',} }}
            cancelButtonProps={{style: { display:'none'} }}
            visible={this.state.modalNoFound}
            closable={false}
            onOk={()=>this.aceptarCiudad('modalNoFound')}
            className="modalBaseBuBooker mxw4" >
            <div className="selectFind">
              
              <div className="head flexcenter Fcenter" >
                <img src={`${img2}iconos/bubook_blanco.png`} alt="..." className="icon-alert"/>
              </div>
              <div className="body">
                <h4> ¡Ops, no tenemos nada para mostrar! </h4>
                <label>Lo sentimos, seguimos en expansión, puedes ingresar otra ubicación para explorar.</label>
                <div className="cascaderWithIcon inverted flexcenter Fstart">
                  <Ubicacion className="MyIcon" />
                  <Cascader onChange={this.onChangeCascader} options={this.state.cascaderOptions}
                    value={[this.state.estadoUsuario,this.state.ciudadUsuario]}
                    placeholder="Ingresar dirección" allowClear={false} expandTrigger="hover" suffixIcon={null}
                    className="cascaderCity" popupClassName="cascaderCityOptions" />
                </div>
              </div>

            </div>
        </Modal>

        <Modal
          onCancel={()=>this.setState({showCarrito:false})}
          visible={this.state.showCarrito} destroyOnClose
          className="modalCarrito"
          wrapClassName="modalCarritoParent"
          maskClosable={false} keyboard={false}
          centered closable={false} footer={null} header={null} >
          
          <CarritoModal closeSelf={()=>this.setState({showCarrito:false})} controlAlert={this.controlAlert} controlAgra={this.controlAgra} onErrorImg={this.changeSrcError} actualizaCarritoContador={this.actualizaCarritoContador} totalEnCarrito={this.totalEnCarrito} eliminarProductoFromCarritoFromPerfil={this.eliminarProductoFromCarritoFromPerfil}  showDataComponentMounted={this.showDataComponentMounted} irAlaEmpresa={(link,metaIDSucursal) => this.irAlaEmpresa(link,metaIDSucursal)}  />

        </Modal>

        <ModalAgradecimiento
          visible={this.state.modalAgradecimiento}
          handler={(modalAgradecimiento => this.setState({modalAgradecimiento}))}
          estatusAgra={this.state.estatusAgra}
          folioAgra={this.state.folioAgra}
          fechaHoraAgra={this.state.fechaHoraAgra}
          referenciaAgra={this.state.referenciaAgra}
          totalAgra={this.state.totalAgra}
          tipoPago={this.state.tipoPago}
          datosTarjeta={ this.state.datosTarjeta
            /*{
              nombreBanco:'AZTECA',
              src:'https://bubook.nibirusystem.com/img/panelAdmin/bancoAzteca.png',
              titular:'Obvio microbio',
              cuenta:'111 111 11111111111111',
              tarjeta:'3333 3333 3333 3333',
              clabe:'222 222 222222222222 22',
            }*/
          }
          />

        <ReportarProblema dataR={this.state.dataR} controlAlert={this.controlAlert}  visible={this.state.modalMakeReport} handler={(modalMakeReport)=>this.setState({modalMakeReport})} />
        
        <InvitarAmigos visible={this.state.invitanding} handler={invitanding => this.setState({invitanding})} />

        <ModalAlert visible={this.state.modalAlert} text={this.state.txtAlert} onOk={()=>this.setState({modalAlert:false,txtAlert:''})} />

        <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
            okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
            cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',display:'none'} }}
            visible={this.state.modalCuentaEliminadaBad}
            closable={false}
            onOk={this.props.cerraSesion}
            className="miAntModal newVar alertMy"
            >
            <div className="myAlertBody" style={{textAlign:'justify',padding:'5px'}} >
              <span className="txtAlert"> Parece que ocurrió un error al intentar reactivar tu cuenta. Por favor intentalo mas tarde.</span>
            </div>
        </Modal>

        <div id="headIndex" className="headIndex">

          <div className="orangeBar flexcenter Fcenter">
            <div className="limiter flexcenter">
              
              <div id="containerLogo" className="groupBar1 flexcenter Fspaceb" onClick={()=>this.redirectTo('/')} >
                <div className="itemOrange"> <img alt="..." src={img2+'elementos/Grupo19236.png'} className="logo" /></div>
              </div>

              <div className="groupBar2 flexcenter Fend">

                <div className="itemOrange">
                  <div className="searchInput flexcenter Fspaceb" id="searchInput">
                    <div className="searchResult" id="searchResult" onClick={()=>console.log('Results')}  >
                    {
                      this.state.empresasBusquedaFiltradas.length > 0 ? 
                      this.state.empresasBusquedaFiltradas.map((empresa,i) =>
                        <div
                          key={'preFiltro_'+i}
                          className="listResults flexcenter Fstart"
                          title={empresa['NOMBRE_COMERCIAL']}
                          //onClick={()=>this.irAlaEmpresa(empresa['USUARIO_EMPRESA'])}
                          data-link={ empresa['USUARIO_EMPRESA'] }
                          onClick={(e)=> this.preclickIrAlaEmpresa(e,1) }
                        >

                          <img
                            alt="..." src={empresa['FOLDER'] + empresa['DIRECCION_IMG_PERFIL']+'.png'}
                            onError={this.changeSrcError} data-link={ empresa['USUARIO_EMPRESA'] }
                            onClick={(e)=> this.preclickIrAlaEmpresa(e,2) }
                          />

                          <span
                            data-link={ empresa['USUARIO_EMPRESA'] }
                            onClick={(e)=> this.preclickIrAlaEmpresa(e,3) }
                          >
                            {empresa['NOMBRE_COMERCIAL']}
                          </span>

                        </div>
                      ):
                      <span className="noResults"> No encontramos resultados </span>
                    }
                    </div>
                    <Icon type="search"/>
                    <input type="text" placeholder="¿Qué es lo que está buscando?" id="searcher"
                      autoComplete="off"
                      onFocus={()=>this.focus('searchInput','active')}
                      onBlur={()=>this.blur('searchInput','active')}
                      onChange={this.busqeda} onKeyPress={this.filtrarEmpresas} />
                  </div>
                </div>
                
                <div className="itemOrange"> <div className="dividerBar"></div> </div>

                <div className="itemOrange">
                  <div className="cascaderWithIcon flexcenter Fspacea">
                    <Ubicacion className="MyIcon" />
                    <Cascader onChange={this.onChangeCascader} options={this.state.cascaderOptions}
                      value={[this.state.estadoUsuario,this.state.ciudadUsuario]}
                      placeholder="Ingresar dirección" allowClear={false} expandTrigger="hover" suffixIcon={null}
                      className="cascaderCity" popupClassName="cascaderCityOptions" />
                  </div>
                </div>

                <div className="itemOrange">
                  <Badge title="Ver carrito" onClick={this.openCarrito} count={this.state.countCarrito} className="myBadge" offset={[-20,5]} overflowCount={9} dot={false}>
                    {/*<i onClick={this.openCarrito}> <Carrito className="carrito" /> </i>*/}
                    <img src={img2+"panelAdmin/carritoB.svg"} style={{margin:'0 10px'}} className="bell" title="Ver carrito" id="fotoCarritoTrigger" alt="..." />
                  </Badge>
                </div>

                {
                  this.state.withUser&&
                  <div className="itemOrange">
                    <Badge title="Ver carrito" count={this.state.countNoti} className="myBadge" offset={[-10,5]} showZero={false} overflowCount={9} dot={false}>
                      <Popover content={
                          <div className="contenedorCategorias">
                            <div className="contWhite">
                              <div className="contWhiteContent" style={{borderRadius:'10px'}} >
                                <Notificaciones indiceRedirecter={this.redirectTo} stateHandler={this.stateHandler} showDataComponentMounted={this.showDataComponentMounted} />
                              </div>
                            </div>
                          </div>
                        } title={null} trigger="click" placement="bottomLeft" overlayClassName="popPerfil rd" destroyTooltipOnHide>
                        <img src={img2+"panelAdmin/notificacionesB.svg"} className="bell" title="Ver notificaciones" id="fotoCampanitaTrigger" alt="..." />
                      </Popover>
                    </Badge>
                  </div>
                }

                {
                  this.state.withUser&&
                  <div className="itemOrange" style={{cursor:'pointer'}} title="Mis empresas favoritas">
                    <Popover content={
                      <div className="contenedorCategorias">
                        <div className="contWhite">
                          <div className="contWhiteContent" style={{borderRadius:'10px'}} >
                            <ListFavs indiceRedirecter={this.redirectTo} stateHandler={this.stateHandler} showDataComponentMounted={this.showDataComponentMounted} />
                          </div>
                        </div>
                      </div>
                    } title={null} trigger="click" placement="bottomLeft" overlayClassName="popPerfil rd" destroyTooltipOnHide destroyOnClose >
                      {/*<i> <Corazon className="coraFavs" /> </i>*/}
                      <img src={img2+"panelAdmin/favoritosB.svg"} style={{margin:'0 10px'}} className="bell" title="Ver notificaciones" id="fotoCorazonTrigger" alt="..." />
                    </Popover>
                  </div>
                }
                
                <div className="itemOrange">{/*boton*/}
                {
                  this.state.withUser?
                   <Popover content={
                    <div className="contenedorCategorias">
                        <div className="contWhite">
                        <div className="contWhiteContent">
                          
                          <div className="separador nmg"/>

                          <div className="itemCategoria flexcenter Fspaceb" onClick={()=>this.redirectToMenu('/config/pedidos')} > Mis compras </div>
                          <div className="itemCategoria flexcenter Fspaceb" onClick={()=>this.redirectToMenu('/config/direcciones')} > Mis direcciones </div>
                          <div className="itemCategoria flexcenter Fspaceb" onClick={()=>this.redirectToMenu('/config/historial')} > Historial de navegación </div>
                          <div className="itemCategoria flexcenter Fspaceb" onClick={()=>this.redirectToMenu('/config/citas')} > Citas agendadas </div>
                          <div className="itemCategoria flexcenter Fspaceb" onClick={()=>this.redirectToMenu('/config/metodosdepago')} > Métodos de pago </div>

                          <div className="separador nmg"/>

                          <div className="itemCategoria flexcenter Fspaceb" onClick={()=>this.redirectToMenu('/config/perfil')} > Configuración </div>
                          <div className="itemCategoria flexcenter Fspaceb" onClick={()=>this.openModalReport(1)} > Reportar un problema </div>
                          
                          {/*<div className="itemCategoria flexcenter Fspaceb" onClick={()=>this.openModalReport(2)} > Invitar amigos </div>*/}

                          <div className="separador nmg"/>

                          <div className="itemCategoria flexcenter Fspaceb" onClick={()=>this.props.cerraSesion(false)} > Cerrar sesión </div>

                        </div>
                        </div>
                      </div>
                    } title={null} trigger="click" placement="bottom" overlayClassName="popPerfil" >
                    <div className="triggerPerfil flexcenter Fspaceb" id="triggerPerfilID" >
                      <img alt="..." id="srcPerfilIndex" src={this.state.srcPerfil} onError={this.changeSrcError} />
                      <span>{this.state.nombreUsuario}</span>
                      <Icon type="down" />
                    </div>
                  </Popover>
                  :
                  <button className="btnBlack" onClick={()=>this.saveBeforeLogin(0)} > Ingresar </button>
                }
                </div>

              </div>
            </div>
          </div>
          <div className="mediumBar"></div>
          <div className="blackBar flexcenter Fcenter">
            <div className="limiter flexcenter">
              <div className="barAnchor flexcenter Fspaceb">
                <div className="itemBlack flexcenter Fspaceb">
                  <Popover content={
                    <div className="contenedorCategorias flexcenter Fcenter">
                      <div className="contBlack">
                      <div className="contBlackContent">
                      {
                        this.state.categorias.map((categoria,i)=>(
                          <div className="itemCategoria flexcenter Fspaceb" key={'categoria_'+i}
                            onMouseEnter={()=>this.enter(categoria)} onClick={()=>this.searchCategoria(categoria)} >
                            {categoria['DESCRIPCION']}
                            {
                              categoria['HIJOS'].length>0 &&
                              <Icon type="right" />
                            }
                          </div>
                        ))
                      }
                      </div>
                      </div>

                      {
                        this.state.categoriaActual['HIJOS'].length>0 &&<div className="contWhite">
                          <div className="contWhiteContent">
                          <div className="title">{this.state.categoriaActual['DESCRIPCION']}</div>
                          {
                            this.state.categoriaActual['HIJOS'].map((categoria,i)=>(
                              <div className="itemCategoria" key={'subcategoria_'+i}  onClick={()=>this.searchCategoria(categoria)} >{categoria['DESCRIPCION']}</div>
                            ))
                          }
                          </div>
                          </div>
                       }
                    </div>
                  } title={null} trigger="click" placement="bottomLeft" onVisibleChange={this.leave} overlayClassName="popCateg" >
                    <span id="triggerPopCat" > Categorías </span> <Icon type="down" className="iconBar" />
                  </Popover>
                </div>
                <div className="itemBlack flexcenter Fcenter" onClick={()=>this.setIntoView('promocionesD')}> <span> Promociones </span> </div>
                <div className="itemBlack flexcenter Fcenter" onClick={()=>this.setIntoView('popularesD')}> <span> Los más populares </span> </div>
                <div className="itemBlack flexcenter Fcenter" onClick={()=>this.setIntoView('nuevosD')}> <span> Nuevos en BuBook </span> </div>
                <div className="itemBlack flexcenter Fcenter" onClick={()=>this.setIntoView('esquinaD')}> <span> A la vuelta de la esquina </span> </div>
                <div className="itemBlack flexcenter Fcenter" onClick={()=>this.props.redirectTo('/infoempresas')} > <span> BuBook para empresas </span> </div>
                <div className="itemBlack flexcenter Fcenter"
                >
                  <span> Ayuda </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div id="contentIndexWrapper" className="contentIndexWrapper">
        <BrowserRouter>
          <Link to={this.state.actualpage} id="auxToG"/>
          {
            rutas.map(({path, Component }) => (
              <Route key={path} exact path={path}  >
              {({ match }) => (
                <CSSTransition in={match != null} timeout={500} classNames="page" unmountOnExit > 
                  <Component
                    empresasCercanas={this.state.empresasCercanas}
                    empresasNuevas={this.state.empresasNuevas}
                    empresasPopulares={this.state.empresasPopulares}

                    showFilter={this.state.showFilter}
                    empresasBusquedaFiltradas={this.state.empresasBusquedaFiltradas2}
                    valueSearch={this.state.valueSearch}

                    popularesFiltradas={this.state.popularesFiltradas}
                    buscandoCategoria={this.state.buscandoCategoria}
                    categoriaBuscando={this.state.categoriaBuscando}

                    permisoUbicacion={this.state.permisoUbicacion}
                    indiceRedirecter={this.redirectTo}
                    appRedirecter={this.props.redirectTo}
                    withUser={this.state.withUser}
                    stateHandler={this.stateHandler}
                    makeRef={component => this.child = component }

                    openModalReport={this.openModalReport}

                    cerraSesion={this.props.cerraSesion}

                    setsrcPerfil={srcPerfil => this.setState({srcPerfil}) }

                    actualizaCarritoContador={this.actualizaCarritoContador}
                    totalEnCarrito={this.totalEnCarrito}
                    eliminarProductoFromCarritoFromPerfil={this.eliminarProductoFromCarritoFromPerfil}
                  />
                </CSSTransition>
                )}
              </Route>
            ))
          }
        </BrowserRouter>
        </div>

        <div id="footerNotAlways" className="footer notAlways">
          
          <div className="container">
            <div className="content">
              
              <div className="logo"><img alt="..." src={img2+'elementos/otrologo.png'} /></div>
              
              <div className="list">
                <div className="title"> Menú </div>
                <div className="item crs" onClick={this.showCategoriasAux} > Categorías </div>
                <div className="item crs" onClick={()=>this.setIntoView('promocionesD')} > Promociones </div>
                <div className="item crs" onClick={()=>this.setIntoView('popularesD')}> Los más populares </div>
                <div className="item crs" onClick={()=>this.setIntoView('nuevosD')}> Nuevos en BuBook </div>
                <div className="item crs" onClick={()=>this.setIntoView('esquinaD')} > A la vuelta de la esquina </div>
                <div className="item crs" onClick={()=>this.props.redirectTo('/infoempresas')} > BuBook para empresas </div>
                <div className="item crs" > Ayuda </div>
              </div>

              <div className="list">
                <div className="title"> Contáctanos </div>
                <div className="item"> Teléfono: +52 (222) 954 5168 </div>
                <div className="item"> <a href = "mailto:ventas@bubook.mx"> Email: ventas@bubook.mx </a> </div>
                <div className="item"> Acapulco de Juárez, Gro. </div>

                <SocialMedia></SocialMedia>
                
              </div>


              <div className="list">
                <div className="title"> Descarga la app </div>
                <div className="itemImg" onClick={()=>this.openAppLink(1)}> <img alt="..." src={img2+'elementos/Recurso134x2x.png'}/> </div>
                <div className="itemImg" onClick={()=>this.openAppLink(2)} > <img alt="..." src={img2+'elementos/Recurso144x2x.png'}/> </div>
              </div>
            </div>
          </div>

          <div className="footer2">
            <div className="container">
              <div className="texto">
                <div> BuBook.mx <label className="registerIcon">&#174;</label>&nbsp;- Todos los derechos reservados | <span className="crPo hvNa" onClick={()=>window.open('/legacy/politicadeprivacidad')}> Aviso de privacidad </span> | <span> Términos y condiciones </span> </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default withRouter(Index);