import {ImgPanelAdmin as imgPanel} from './../app.json';

const unique = (a) => { return a.filter((value, index, self) => self.indexOf(value) === index); }

const groupBy = (xs, key) => {
	return xs.reduce((rv, x) => {
	  (rv[x[key]] = rv[x[key]] || []).push(x);
	  return rv;
	}, {});
}

const formatNumber = (x,decimals) => {
	x = Number(x).toFixed(decimals)
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

const capitalizeFirs = (string) => {
	if(string)
		return string[0].toUpperCase()+string.slice(1)
	return ''
}


const removeacentos = (string) => {
	if(string){
		let b = string.trim().toLowerCase()
		return b.split('á').join('a')
						.split('é').join('e')
						.split('í').join('i')
						.split('ó').join('o')
						.split('ú').join('u')
	}
	return ''
}

const getImageBanco = (banco)=>{
	banco--;
	let obj = {
		4:imgPanel+"amex.png",
		8:imgPanel+"bancoAzteca.png",
		12:imgPanel+"banamex.png",
		15:imgPanel+"bancoppel.svg",
		18:imgPanel+"banorte.png",
		24:imgPanel+"bbva.svg",
		46:imgPanel+"hsbc.svg",
		47:imgPanel+"inbursa.png",
		68:imgPanel+"santander.svg",
		69:imgPanel+"scotiabank.svg",
	}
	if(banco in obj)
		return obj[banco]
	else
		return imgPanel+'bancoGeneral.svg'
}

const formatDatosCard = (value,type) => {
	if(type===1){//#cuenta 
		return value.slice(0,3) +' '+ value.slice(3,6) +' '+value.slice(6)
	}
	if(type===2){//#tarjeta
		return value.slice(0,4) +' '+ value.slice(4,8) +' '+value.slice(8,12)+' '+value.slice(12,16)
	}
	 if(type===3){//#cuenta clabe
		return value.slice(0,3) +' '+ value.slice(3,6) +' '+value.slice(6,17)+' '+value.slice(17)
	}
}

const copyTextGeneral = (id) => {
	try{
		let div2 = document.getElementById(id)
		let selection = window.getSelection();
		let range = document.createRange();
		range.selectNodeContents(div2);
		selection.removeAllRanges();
		selection.addRange(range);
		document.execCommand("copy");
		selection.removeAllRanges();
		return 'ok'
	} catch(e) {
		return 'none: '.e.toString()
	}
}

export {unique,groupBy,formatNumber,capitalizeFirs,getImageBanco,formatDatosCard,copyTextGeneral,removeacentos};