import React from 'react';
import axios from 'axios';
import { Globalrequest as request } from './../../../request.js';
import {Host as host} from './../../../app.json'
import {Modal,Select,Col,Row,DatePicker} from 'antd';
import {withRouter} from 'react-router-dom'

import 'moment/locale/es'
import moment from 'moment'
import {userContext} from './../context'

import Loading from '../../../modalCargando/loading';
import { Bar , Line } from 'react-chartjs-2';
import   './estadisticas.scss';

const ListaProducto = (props) => {
  return(
    <Modal centered className="miAntModal alertMy modalUnidad" visible={props.visible}
      title={props.title}
      maskClosable={false} keyboard = {false} 
      okText="Aceptar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
      cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px',display:'none'} }}
      onOk={()=>props.handler(false,-1)}
      onCancel={()=>props.handler(false,-1)}
      >
      <div>
        
        <Row className="tablaHead">
          <Col span={14} className="nombre" > Producto </Col>
          <Col span={6}> Existencia </Col>
          <Col span={4}> Ventas </Col>
        </Row>

        <div className="tablainline">
          {
            props.data.length > 0 ?
            props.data.map((row,indice) =>(
              <Row key={'rowN_'+indice} className='tablaBody' >
                <Col span={14} className="notOverflow nombre">
                  {row['nombre']}
                </Col>
                <Col span={6} className="notOverflow"> {row['existencia']} </Col>
                <Col span={4} className="notOverflow"> {row['vendidos']} </Col>
              </Row>
            ))
            :                  
            <div className="datosPublico empty" style={{cursor:'pointer'}} >
              <div className="info">
                <div className="txt"> {props.textNodata} </div>
               </div>
            </div>
          }
        </div>

      </div>
    </Modal>
  )
}


const naranjaStart = '#FF9700';
//const naranjaEnd = '#F77400';
const azulBuBook = '#37C8EF';
const green = '#00FF00';

const options = {
  responsive: true,
  hover: { mode: 'label' },
  scales: { yAxes: [{ display: true, ticks: { beginAtZero: true, } }] },
}

class Lista extends React.Component{

  static contextType = userContext

  constructor(props) {
    super(props);
    
    this.actualizarSucursales2 = this.actualizarSucursales2.bind(this)
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.actualizarProductos = this.actualizarProductos.bind(this)
    
    this.changeFiltro2 = this.changeFiltro2.bind(this)
    
    this.borrarFiltros = this.borrarFiltros.bind(this)
    this.filtrarCompleto = this.filtrarCompleto.bind(this)
    
    this.state = {
      filtro1:1,
      filtro2:'',
      /**/
      loading:false,
      loading2:false,
      modalAlert:false,
      txtAlert:'',
      /**/
      sucursales:this.props.sucursales,
      /**/
      totalCompras:0,
      totalFavoritos:0,
      totalVisitas:0,
      totalHombres:0,
      totalMujeres:0,
      totalTotal:0,
      /**/
      dataBar:{
          labels: [],
          datasets: []
       },
       dataLine:{
          labels: [],
          datasets: []
        },
        misDatos:{
          datosPublico:{},
          interaccion:[],
          publicoEdad:[],
          publicoCiudad:[],
          vendidos:[],
          Novendidos:[],
        },
        expand:false,
        expand1:false,
        /**/
        inicioPeriodo:'',
        finPeriodo:'',
        /**/
        fecha1:moment(),
        fecha2:moment(),
        modalVendidos:false,
        opM:-1,
      }
  }

  componentWillMount(){
    let {sucursales} = this.state
    let id = sessionStorage.getItem('id')
    if(id===undefined||id===null)return
    if(sucursales.length===0){
      this.actualizarSucursales2()
    }
  }

  componentDidMount(){
    document.title="Mis estadísticas"

    let {validCurrentUser,redirectTo2} = this.props


    let obj = JSON.parse(JSON.stringify(this.context))
    if(Object.keys(this.context['data']).length === 0 ){
      let permisosPreSaved = sessionStorage.getItem('perfiles')||'none'
      if(permisosPreSaved!=='none')
        obj['data'] = JSON.parse(permisosPreSaved)  
    }
    
    if(!validCurrentUser(obj,'estadisticas','bool',false,false)){
      redirectTo2('/home')
      return
    }

    this.actualizarProductos()
    this.actualizarCatalogo()
  }

   actualizarSucursales2(){
    let data = JSON.parse(sessionStorage.getItem('metadata'))
    this.props.setSucursales(data['sucursales'])
    this.setState({ sucursales : data['sucursales'] })
    this.props.editState('datos',data['datos'])
    this.props.editState('sucursales',data['sucursales'])
    this.props.editState('categorias',data['categorias'])
    this.props.editState('tags',data['tags'])
    this.props.editState('notificaciones',data['notificaciones'])
  }

  actualizarCatalogo(){
    let {filtro1,fecha1,fecha2,inicioPeriodo,finPeriodo} = this.state
    inicioPeriodo = moment()
    finPeriodo = moment()
    let inicio,fin
    fin = finPeriodo.format('YYYY-MM-DD')
    if(filtro1===1)inicio = inicioPeriodo.subtract(7,'days').format('YYYY-MM-DD')
    else if(filtro1===2)inicio = inicioPeriodo.subtract(30,'days').format('YYYY-MM-DD')
    else if(filtro1===3)inicio = ''
    else if(filtro1===4){
      if(fecha2.isBefore(fecha1)){
        this.setState({modalAlert:true,txtAlert:'La fecha fin debe ser mayor o igual que la fecha inicio'})
        return
      }
      inicio = fecha1.format('YYYY-MM-DD')
      fin = fecha2.format('YYYY-MM-DD')
    }

    let data = {idE:sessionStorage.getItem('id'),inicio:inicio,fin:fin}
    this.setState({loading:true})
    axios.post(`${host}estadisticas/estadisticas/estadisticasPorEmpresa`,data,request).then(rt=>{
      let {misDatos,dataBar,dataLine,totalCompras,totalVisitas,totalFavoritos} = this.state
      misDatos['datosPublico'] = rt.data['publico'][0]
      misDatos['interaccion'] = rt.data['interaccion'].reverse()
      misDatos['publicoEdad'] = rt.data['publicoEdad']
      misDatos['publicoCiudad'] = rt.data['publicoCiudad']

      let compras=[],visitas=[],favoritos=[]
      dataLine['labels']=[]
      for(let interaccion of misDatos['interaccion']){
        dataLine['labels'].push(moment(interaccion['FECHA'],'YYYY-MM-DD').format('DD/MM/YYYY'))
        compras.push(Number(interaccion['COMPRAS']))
        visitas.push(Number(interaccion['VISITAS']))
        favoritos.push(Number(interaccion['FAVORITO']))
      }
      inicioPeriodo = dataLine['labels'][0]
      finPeriodo = dataLine['labels'][dataLine['labels'].length-1]
      dataLine['datasets'] = [
        {fill: false,label:'Compras',data:compras,borderColor:naranjaStart, lineTension:0.1, backgroundColor:naranjaStart},
        {fill: false,label:'Visitas',data:visitas,borderColor:green, lineTension:0.1, backgroundColor:green},
        {fill: false,label:'Favoritos',data:favoritos,borderColor:azulBuBook, lineTension:0.1, backgroundColor:azulBuBook},
      ]

      totalCompras = compras.reduce((a,b)=>a+b,0)
      totalVisitas = visitas.reduce((a,b)=>a+b,0)
      totalFavoritos = favoritos.reduce((a,b)=>a+b,0)

      let labels = []
      let hombres=[],mujeres=[]
      for(let row of misDatos['publicoEdad']){
        if(Number(row['INDICE'])===7) labels.push('+'+row['INICIO'])
        else labels.push(row['INICIO']+'-'+row['FIN'])
        hombres.push(Number(row['HOMBRES']))
        mujeres.push(Number(row['MUJERES']))
      }
      dataBar['labels'] = labels
      dataBar['datasets'] = [
        {label:'Mujeres',data:mujeres,backgroundColor:green},
        {label:'Hombres',data:hombres,backgroundColor:naranjaStart},
      ]
      let totalHombres = hombres.reduce((a,b)=>a+b,0)
      let totalMujeres = mujeres.reduce((a,b)=>a+b,0)

      let totalTotal = totalMujeres + totalHombres
      this.setState({misDatos,loading:false,dataBar,totalHombres,totalMujeres,totalTotal,dataLine ,totalCompras ,totalVisitas,totalFavoritos,inicioPeriodo,finPeriodo})
    })
  }

  actualizarProductos(){
    let {misDatos,filtro2} = this.state
    let isTodes = (filtro2==='')
    let data = {
      idC: isTodes?sessionStorage.getItem('id'):filtro2,
      tipoC:isTodes?1:2,
    }
    this.setState({loading2:true})
    axios.post(`${host}estadisticas/estadisticas/productosPorEmpresa`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let productos = rt.data['productos'].slice(0);//[0,1,2,3,4,5] -> Ordenados menor a mayor
        misDatos['vendidos'] = productos.slice(0).reverse()
        misDatos['Novendidos'] = productos.slice(0)
        this.setState({misDatos,loading2:false})
      }
    })
  }

  changeFiltro2(filtro2){
    this.setState({filtro2})
  }

  borrarFiltros(){
    let {filtro1,filtro2,fecha1,fecha2} = this.state
    filtro1 = 1;
    filtro2 = '';
    fecha1 = moment();
    fecha2 = moment();
    this.setState({filtro1,filtro2,fecha1,fecha2})
    setTimeout(()=> this.actualizarProductos(), 10)
    setTimeout(()=> this.actualizarCatalogo(), 10)
  }

  filtrarCompleto(){
    setTimeout(()=> this.actualizarProductos(), 10)
    setTimeout(()=> this.actualizarCatalogo(), 10)
  }

  classToggle(){
    document.getElementById('toggleFiltrarId').classList.toggle('toggleFiltrar2');
    document.getElementById('contControlesId').classList.toggle('contControles2');
  }
  
  render(){
    return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3" >
            
            <Loading loading={this.state.loading||this.state.loading2} />

            <ListaProducto visible={this.state.modalVendidos}
              title={this.state.opM === 1 ? 'Productos y servicios más vendidos' : 'Productos y servicios menos vendidos'}
              handler={(modalVendidos,opM) => this.setState({modalVendidos,opM})}
              data={this.state.opM === 1 ? this.state.misDatos['vendidos'] : this.state.misDatos['Novendidos']}
              textNodata={this.state.opM === 1 ? 'Aun no cuentas con productos vendidos' : 'Aún no tienes productos registrados'} />

            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' }}
                cancelButtonProps={{ className:'btn btn2', style: {display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalAlert   ?
                        <span className="txtAlert"> {this.state.txtAlert} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            <div className="tituloYControles">
              <div className="metaControles">
                <h3 className="tituloPanel"> Estadísticas <span style={{fontWeight:'normal'}} >({this.state.inicioPeriodo} - {this.state.finPeriodo})</span></h3>
                <span className="toggleFiltrar" id="toggleFiltrarId" onClick={this.classToggle} > Filtrar </span>
              </div>

              <div className="contControles" id="contControlesId">
                
                <div className="contControlesHijo" id="contControlesHijoId">

                  <div className="filtradosExtras">

                    <div className="inputs-group-inline">
                      <label className="inputs-label" htmlFor="pesopd" >  Filtrar por fecha </label>
                      <Select className="inputs-input" value={this.state.filtro1} onChange={(filtro1)=>this.setState({filtro1})} >
                        <Select.Option value={1} >Últimos 7 días </Select.Option>
                        <Select.Option value={2} >Últimos 30 días </Select.Option>
                        <Select.Option value={3} >Desde el principio </Select.Option>
                        <Select.Option value={4} >Personalizado </Select.Option>
                      </Select>
                    </div>
                    
                    {
                      this.state.filtro1===4&&
                      <div className="fechaSexoEstadisticas" style={{marginTop:'3px'}} >
                        <div className="inputs-group inputs-group-inline">
                          <label className="inputs-label" htmlFor="pesopd"> Fecha inicio </label>
                          <DatePicker format="DD/MM/YYYY" locale='es-MX' className="MyDate" allowClear={false} placeholder="" value={this.state.fecha1} onChange={(fecha1)=>this.setState({fecha1})} />
                        </div>

                        <div className="inputs-group inputs-group-inline">
                          <label className="inputs-label" htmlFor="timeprepapd" > Fecha fin </label>
                          <DatePicker format="DD/MM/YYYY" locale='es-MX' className="MyDate" allowClear={false} placeholder="" value={this.state.fecha2} onChange={(fecha2)=>this.setState({fecha2})} />
                        </div>
                      </div>
                    }

                  </div>

                  <div className="filtradosExtras">
                    
                    <div className="inputs-group-inline">
                      <label className="inputs-label" htmlFor="pesopd" >  Filtrar por sucursal </label>
                      <Select value={this.state.filtro2} className="inputs-input" onChange={this.changeFiltro2}>
                        {
                          this.state.sucursales.map((sucu,indice)=>{
                            return(
                              <Select.Option value={sucu.ID} key={'sucList_'+indice} > {sucu.NOMBRE } </Select.Option>
                            )
                          })
                        }
                        <Select.Option value={''} > Todas las sucursales </Select.Option>
                      </Select>
                    </div>
                    
                  </div>
                  
                  <div className="cleanDiv flexcenter Fend" style={{paddingRight:'10px'}} >
                    <button className="cleanBut" onClick={this.borrarFiltros} > Borrar filtros </button>
                    <button className="FillBut" onClick={this.filtrarCompleto} > Filtrar </button>
                  </div>

                </div>


              </div>

            </div>

            <div className="cont-instancias2">
              
              <div className="subGrafica">
                <label className="titleGrafica"> Público alcanzado </label>
                <div className="datosPublico">
                  
                  <div className="info">
                    <div className="number">{this.state.misDatos['datosPublico']['VISTO_PERFIL']}</div>
                    <div className="txt"> Total de personas que han interactuado con tu perfil </div>
                  </div>

                  <div className="info">
                    <div className="number">{this.state.misDatos['datosPublico']['FAVORITO']}</div>
                    <div className="txt"> Total personas que te han agregado como favorito </div>
                  </div>

                </div>

                {/*<Line data={this.state.line} />*/}
                {/*<div className="chart">
                                  <Bar data={this.state.data} width={100} height={100} options={{ maintainAspectRatio: false }} />
                                </div>*/}
              </div>

              <div className="subGrafica">
                <label className="titleGrafica"> Interacción de usuarios </label>

                <div className="chart">
                <Line data={this.state.dataLine} />
                </div>

                <div className="datosPublico">
                  
                  <div className="info">
                    <div className="txt"> Visitas </div>
                    <div className="number">{this.state.totalVisitas}</div>
                  </div>
                  
                  <div className="info">
                    <div className="txt"> Compras </div>
                    <div className="number">{this.state.totalCompras}</div>
                  </div>
                  
                  <div className="info">
                    <div className="txt"> Favoritos </div>
                    <div className="number">{this.state.totalFavoritos}</div>
                  </div>

                </div>
                {/*<div className="chart">
                                  <Bar data={this.state.data} width={100} height={100} options={{ maintainAspectRatio: false }} />
                                </div>*/}
              </div>

              <div className="subGrafica">
                <label className="titleGrafica"> Público por rango de edad y sexo </label>

                <div className="metChart">
                  
                  <div className="meta">
                    <div> {this.state.totalMujeres} ( {this.state.totalTotal > 0 ? ((this.state.totalMujeres / this.state.totalTotal)*100).toFixed(2) : 0 } %) </div>
                    <div className="orange orangeM"> Mujeres </div>
                  </div>

                  <div className="meta">
                    <div> {this.state.totalHombres} ( {this.state.totalTotal > 0 ? ((this.state.totalHombres / this.state.totalTotal)*100).toFixed(2) : 0 } %) </div>
                    <div className="orange orangeH"> Hombres </div>
                  </div>

                </div>

                <div className="chart">
                <Bar data={this.state.dataBar} options={options} />
                </div>

              </div>

              <div className="subGrafica">
                <label className="titleGrafica"> Principales ciudades de interacción </label>

                  <div className="tablainline">
                    <Row className="tablaHead">
                      <Col span={8}> Ciudad </Col>
                      <Col span={8}> Estado </Col>
                      <Col span={8}> Público </Col>
                    </Row>
                    {
                      this.state.misDatos['publicoCiudad'].length > 0 ?
                      
                        this.state.misDatos['publicoCiudad'].map((row,indice) =>(
                          <Row key={'row_'+indice} className="tablaBody" >
                            <Col className="notOverflow" span={8}> {row['CIUDAD']} </Col>
                            <Col className="notOverflow" span={8}> {row['ESTADO']} </Col>
                            <Col className="notOverflow" span={8}> {row['PUBLICO']} </Col>
                          </Row>
                        ))
                        :
                  
                      <div className="datosPublico">
                        <div className="info">
                          <div className="txt"> Aún no hay actividad registrada </div>
                         </div>
                      </div>
                  }
                  </div>
              </div>

              <div className="subGrafica">
                <Row className="inlineinline" >
                  <Col className="colinle" onClick={()=>this.setState({modalVendidos:true,opM:1})} style={{cursor:'pointer'}} title="Ver todos"  >
                    <label className="titleGrafica" style={{cursor:'pointer'}} > Productos y servicios más  vendidos </label>
                    <div>
                    <div className="tablainline">
                      <Row className="tablaHead">
                        <Col span={14} className="nombre" > Producto </Col>
                        <Col span={6}> Existencia </Col>
                        <Col span={4}> Ventas </Col>
                      </Row>
                      {
                        this.state.misDatos['vendidos'].length > 0 && Number(this.state.misDatos['vendidos'][0]['vendidos'])>0 ?
                          this.state.misDatos['vendidos'].slice(0,5).map((row,indice) =>(
                            <Row className={'tablaBody'} key={'rowV_'+indice}  >
                              <Col span={14} className="notOverflow nombre"> {row['nombre']} </Col>
                              <Col span={6} className="notOverflow" > {row['existencia']} </Col>
                              <Col span={4} className="notOverflow" > {row['vendidos']} </Col>
                            </Row>
                          ))
                          :
                          <div className="datosPublico">
                            <div className="info">
                              <div className="txt"> Aún no cuentas con productos vendidos. </div>
                            </div>
                          </div>
                      }
                    </div>
                    </div>
                  </Col>
                  <Col className="colinle" onClick={()=>this.setState({modalVendidos:true,opM:2})} style={{cursor:'pointer'}} title="Ver todos" >
                    <label className="titleGrafica" style={{cursor:'pointer'}} > Productos y servicios menos vendidos </label>
                    <div>
                      <div className="tablainline">
                        <Row className="tablaHead">
                          <Col span={14} className="nombre" > Producto </Col>
                          <Col span={6}> Existencia </Col>
                          <Col span={4}> Ventas </Col>
                        </Row>
                        {
                          this.state.misDatos['Novendidos'].length > 0 ?

                          this.state.misDatos['Novendidos'].slice(0,5).map((row,indice) =>(
                            <Row key={'rowN_'+indice} className='tablaBody' >
                              <Col span={14} className="notOverflow nombre">
                                {row['nombre']}
                              </Col>
                              <Col span={6} className="notOverflow"> {row['existencia']} </Col>
                              <Col span={4} className="notOverflow"> {row['vendidos']} </Col>
                            </Row>
                          ))
                          :                  
                          <div className="datosPublico empty" >
                            <div className="info">
                              <div className="txt"> Aún no tienes productos registrados. </div>
                             </div>
                          </div>
                        }
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>

            </div>

            
            <div className="cont-instancias5"></div>
          </div>
        </div>
       </div>
    );
  }
}

export default withRouter(Lista);