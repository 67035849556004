import React from 'react';
import {withRouter} from "react-router-dom";
import {Landing as landingImg , ImgGral as img, shortcutANDROID} from './../app.json'
import SocialMedia from './../modalCargando/sociallinks'
import {Icon} from 'antd';
import './landing.scss'

class Landing extends React.Component{  
  //constructor(props){ super(props); }
  //componentDidMount(){}
  openTerminos(){ window.open('/legacy/politicadeprivacidad') }
  
  showSection(id){
    document.getElementById(id).scrollIntoView()
  }

  openAppLink(op){
    if(op===1){

    }else if(op === 2){
      window.open(shortcutANDROID)
    }
  }

  render(){
    return(
      <div className="landingWrapper">
        <div className="landing">
               <div className="contactBar">
                <div className="container">
                  <div className="itemsGroup">
                    
                    <div className="item"> <img alt="..." src={landingImg+'sobre.svg'} /> ventas@bubook.mx </div>
                    <div className="grouper">
                      <div className="item"> <img alt="..." src={landingImg+'telefono.svg'}/> +52 (222) 954 5168 </div>
                      <div className="item"> <img alt="..." src={landingImg+'audifonos.svg'}/> Support & FAQs </div>
                    </div>

                  </div>
                </div>
                </div>
                <div className="content1">
                <div className="container">
                  
                  <div className="navbarWrapper">
                    <div className="logo" onClick={()=>this.props.redirectTo('/')} ><img alt="..." src={landingImg+'Grupo19236.png'} /></div>
                    <Icon type="menu" style={{fontSize:'17px'}} id="icon" onClick={()=>document.getElementById('navvar').classList.toggle('visible') } />
                    <div className="navbar" id="navvar">
                      <div className="item" onClick={()=>this.showSection('content2')} > Acerca de Bubook.mx </div>
                      <div className="item" onClick={()=>this.showSection('content3Top1')}> ¿Qué ofrece? </div>
                      <div className="item" onClick={()=>this.showSection('content3Top2')}> ¿Cómo funciona? </div>
                      <div className="item" onClick={()=>this.showSection('content5')} > ¿Precios? </div>
                      <div className="btn btnWhite flexcenter Fcenter" onClick={()=>this.props.redirectTo('/auth/registro/negocio')}> Registrarme </div>
                      <div className="btn btnBlack flexcenter Fcenter" onClick={()=>this.props.redirectTo('/auth/login')}> Entrar </div>
                    </div>
                   </div> 
                   
                   <div className="bigTitle">
                     <div className="titleWhite"> ¡Comienza a vender </div>
                     <div className="titleWhite black"> en línea! </div>
                     
                     <img alt="..." src={landingImg+'Recurso124x2x.png'} id="motoNone" />

                     <div className="separadorRects"> <div className="rect1"></div> <div className="rect2"></div>  <div className="rect3"></div>  </div>
                     <div className="txtMinihite">
                       <div className="txtWhite"> Administra de manera rápida y sencilla </div>
                       <div className="txtWhite"> tus ventas, pedidos, productos y más. </div>
                       <div className="txtWhite"> Todo desde un solo lugar. </div>
                     </div>
                   </div>

                </div>
                </div>
             </div>
             <div className="landing">
                <div className="content2" id="content2" >
                  <div className="container">
                    <div className="body">
                      <div className="title">
                        <div className="title black"> Vender debe de ser </div>
                        <div className="title orange"> rápido, sencillo y a </div>
                        <div className="title orange"> cualquier hora. </div>
                      </div>

                      <img alt="..." src={landingImg+'Recurso114x2x.png'} id="celplacesNone" />

                      <div className="separadorRects"> <div className="rect3"></div> <div className="rect2"></div>  <div className="rect1"></div>  </div>
                      
                      <div className="texto">
                        <div> Bubook.mx <span className="registerIcon">&#174;</span>&nbsp;es una plataforma online que te ayudará </div>
                        <div> a gestionar tus ventas donde quiera que vayas, </div>
                        <div> atender pedidos sin complicaciones, administrar </div>
                        <div> tus inventarios, compartir tu catálogo de productos </div>
                        <div> y servicios, pero sobre todo automatizar tu proceso </div>
                        <div> de venta. </div>
                      </div>

                      <div className="divButton">
                        <div onClick={()=>this.props.redirectTo('/auth/registro/negocio')}> ¡Pruébalo gratis por 14 días! </div>
                      </div>

                      <img alt="..." src={landingImg+'Recurso94x2x.png'} id="indicator1leftNone" />

                    </div>
                  </div>
                </div>
             </div>
             <div className="landing">
                <div className="content3 content3Top1" id="content3Top1" >
                  <img src={landingImg+'Recurso94x2x.png'}alt="..." className="leftCircle" />
                  <div className="container">
                    <div className="content">
                      <div className="titleBlock">
                        <div className="title black"> Bubook.mx <span className="registerIcon">&#174;</span>&nbsp;está pensado para </div>
                        <div className="title orange"> expandir tu mercado actual </div>
                        <div className="separadorRects"> <div className="rect3"></div> <div className="rect2"></div>  <div className="rect1"></div>  </div>
                      </div>
                      <div className="texto">
                        <div> Utiliza el tiempo a tu favor con las herramientas que Bubook.mx te ofrece: </div>
                      </div>
                      <div className="cardsTools">
                      <div className="row">
                        <div className="cardItem">
                          <div><div className="interno"></div><img alt="..." src={landingImg+'nodes.png'} /></div>
                          <span title="Control de inventarios masivos"> Control de inventarios masivos </span>
                        </div>
                        <div className="cardItem">
                          <div><div className="interno"></div><img alt="..." src={landingImg+'place.png'} /></div>
                          <span title="Control de múltiples sucursales"> Control de múltiples sucursales </span>
                        </div>
                        <div className="cardItem">
                          <div><div className="interno"></div><img alt="..." src={landingImg+'libreta.png'} /></div>
                          <span title="Control de citas y reservaciones"> Control de citas y reservaciones </span>
                        </div>
                        <div className="cardItem">
                          <div><div className="interno"></div><img alt="..." src={landingImg+'avion.png'} /></div>
                          <span title="Envío de encuestas personalizadas"> Envío de encuestas personalizadas </span>
                        </div>
                        </div>
                        <div className="row">
                        <div className="cardItem">
                          <div><div className="interno"></div><img alt="..." src={landingImg+'bandera.png'} /></div>
                          <span title="Publicación de promociones y ofertas"> Publicación de promociones y ofertas </span>
                        </div>
                        <div className="cardItem">
                          <div><div className="interno"></div><img alt="..." src={landingImg+'pc.png'} /></div>
                          <span title="ntegración y automatizada con medios de pag">Integración automatizada con métodos de pago</span>
                        </div>
                        <div className="cardItem">
                          <div><div className="interno"></div><img alt="..." src={landingImg+'engrane.png'} /></div>
                          <span title="Actualizaciones y nuevas funcionalidades"> Actualizaciones y nuevas funcionalidades </span>
                        </div>
                        <div className="cardItem">
                          <div><div className="interno"></div><img alt="..." src={landingImg+'pastel.png'} /></div>
                          <span title="Reportes y gráficos de ventas, entre otros"> Reportes y gráficas de ventas, entre otros </span>
                        </div>
                      </div>
                      </div>
                      <div className="texto abs">
                        <div> Combina las funciones principales de un E-Commerce y los fusiona con Sistemas de Información de Marketing </div>
                        <div> (SIM), Customer Relationship Management (CRM) y pasarelas de pago electrónico. </div>
                      </div>
                    </div>
                  </div>
                </div>
             </div>
             <div className="landing">
                <div className="content3 content3Top2" id="content3Top2">
                  <div className="container">
                    <div className="content">
                      <div className="titleBlock mg">
                        <div className="title black"> ¿Cómo empezar a </div>
                        <div className="title orange"> vender online? </div>
                      </div>
                      <div className="cardsTools2">
                        <div className="cardItem2">
                          <div className="aRatio"><img alt="..." src={landingImg+'Recurso64x2.svg'} /></div>
                          <span> 1. Crea tu cuenta de Bubook.mx <label className="registerIcon">&#174;</label></span>
                          <div className="cicles"> <div></div><div></div> <div></div><div></div>  </div>
                        </div>
                        <div className="cardItem2">
                          <div className="aRatio"><img alt="..." src={landingImg+'Recurso84x2x.svg'} /></div>
                          <span> 2. Sube tus productos y servicios </span>
                          <div className="cicles"> <div></div><div></div> <div></div><div></div>  </div>
                        </div>
                        <div className="cardItem2">
                          <div className="aRatio"><img alt="..." src={landingImg+'Recurso154x2x.svg'} /></div>
                          <span> 3. ¡Comienza a vender! </span>
                          <div className="cicles"> <div></div><div></div> <div></div><div></div>  </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
             </div>
             <div className="landing">
                <div className="content4" id="content4" >
                <img alt="..." src={landingImg+'Recurso174x2x.png'} style={{ width: '80%', marginLeft: '5%' }} className="ticket" />
                  <div className="container">
                    <div className="content">
                      <div className="titleBlock">
                        <div className="titleBlockhijo">
                        <div className="title black"> Para una mejor experiencia </div>
                        <div className="title"> descarga la app </div>
                        </div>
                      </div>

                      <div className="contImgs">
                        <img onClick={()=>this.openAppLink(1)} alt="..." src={landingImg+"Recurso134x2x.png"} />
                        <img onClick={()=>this.openAppLink(2)} alt="..." src={landingImg+"Recurso144x2x.png"}/>
                      </div>

                    </div>
                  </div>
                </div>
             </div>
             <div className="landing">
                <div className="content5" id="content5" >
                  <div className="container">
                    <div className="content">
                      
                      <div className="titleBlock">
                        <div className="title black"> ¿Cuánto cuesta tener tu </div>
                        <div className="title orange"> propia aplicación? </div>
                      </div>

                      <div className="separadorRects"> <div className="rect3"></div> <div className="rect2"></div>  <div className="rect1"></div>  </div>

                      <div className="texto">
                        <div> ¡Elige la opción de suscripción que mejor se adapte a ti! </div>
                      </div>

                      <div className="contPlanes">
                      
                        <div className="plan">
                          <div className="etiqueta"> <div className="trapecio"> Mensual </div>  <div className="triangulo"></div> </div>
                          <div className="Precio">  <span>$</span> <label>749</label> <h4> <span>.00</span> <label>MXN</label> </h4> </div>
                        </div>

                        <div className="plan">
                          <div className="etiqueta"> <div className="trapecio"> Anual </div>  <div className="triangulo"></div> </div>
                          <div className="Precio">  <span>$</span> <label>7,490</label> <h4> <span>.00</span> <label>MXN</label> </h4> </div>
                          {/*<div className="nota"> <div> + 2 meses </div> <div> GRATIS </div> </div>*/}
                        </div>

                      </div>


                    </div>
                    <div className="button">
                      <div onClick={()=>this.props.redirectTo('/auth/registro/negocio')}> REGISTRARME </div>                    
                    </div>
                    
                    <div className="texto">
                      <div> El pago de la membresía incluye el registro de hasta 3 sucursales por empresa, cada sucursal extra tendrá un </div>
                      <div> costo de $99.00 MXN. Los precios, la disponibilidad y las especificaciones están sujetos a cambios sin previo aviso. </div>
                    </div>

                  </div>
                </div>
             </div>
             <div className="landing">
                <div className="footer">
                  <div className="container">
                    <div className="content">
                      
                      <div className="logo" onClick={()=>this.props.redirectTo('/')} ><img alt="..." src={landingImg+'otrologo.png'} /></div>
                      
                      <div className="list">
                        <div className="title"> Menú </div>
                        <div className="item crs" onClick={()=>this.showSection('content1')} > Acerca de BuBook </div>
                        <div className="item crs" onClick={()=>this.showSection('content3Top1')}> ¿Qué ofrece? </div>
                        <div className="item crs" onClick={()=>this.showSection('content3Top2')}> ¿Cómo funciona? </div>
                        <div className="item crs" onClick={()=>this.showSection('content5')} > ¿Precios? </div>
                        <div className="item crs" onClick={()=>this.props.redirectTo('/auth/login')} > Inicio de sesión </div>
                      </div>

                      <div className="list">
                        <div className="title"> Contáctanos </div>
                        <div className="item"> Teléfono: +52 (222) 954 5168 </div>
                        <div className="item"> <a href = "mailto:ventas@bubook.mx">Email: ventas@bubook.mx ggg</a> </div>
                        <div className="item"> Acapulco de Juárez, Gro. </div>

                        <SocialMedia></SocialMedia>
                        
                      </div>      


                      <div className="list">
                        <div className="title"> Descarga la app </div>
                        <div className="itemImg" onClick={()=>this.openAppLink(1)}> <img alt="..." src={landingImg+'Recurso134x2x.png'}/> </div>
                        <div className="itemImg" onClick={()=>this.openAppLink(2)} > <img alt="..." src={landingImg+'Recurso144x2x.png'}/> </div>
                      </div>

                    </div>
                  </div>

                  <div className="footer2">
                    <div className="container">
                      <div className="texto">
                        <div> BuBook <label className="registerIcon">&#174;</label>&nbsp;- Todos los derechos reservados | <i style={{fontStyle:'normal'}} className="semiBlock"> <span className="crPo hvNa" onClick={this.openTerminos}> Aviso de privacidad </span> | <span> Términos y condiciones </span> </i> </div>
                      </div>
                    </div>
                  </div>

                </div>
             </div>
      </div>
    )
  }
}

export default withRouter(Landing);