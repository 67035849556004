import React from 'react';
import axios from 'axios';
import {Globalrequest as request} from './../../../request.js'
import { Host as host,ImgPanelAdmin as panelAdmin,Img as img} from './../../../app.json';

import Loading from './../../../modalCargando/loading';
import Alert from './../../../modalCargando/modalAlert';
import {Corazon} from "./../../../utilities/svg.js";

/* Librerías */
import { Icon} from 'antd';
import { withRouter } from "react-router-dom";

//import {loadStripe} from '@stripe/stripe-js';

import './metodos.scss'

class MetodosPago extends React.Component{
  constructor(props) {
    super(props);
    
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.confirmEliminar = this.confirmEliminar.bind(this)
    this.submitEliminar = this.submitEliminar.bind(this)
    this.makeFav = this.makeFav.bind(this)

    /*this.loadStripe = this.loadStripe.bind(this)
    this.submit = this.submit.bind(this)
    this.detalle = this.detalle.bind(this)
    this.closeModal = this.closeModal.bind(this)*/
    
    this.state={
    	id:sessionStorage.getItem('id'),
      listado:[],

      loading:false,
      loading2:false,
      modalAlert:false,
      txtAlert:'',
      modalEliminar:false,
      txtEliminar:'',

      agregarEditar:false,

      idEliminar:-1,

      tarjetaComplete:false,
      tarjetaError:true,
    }
  }

  componentDidMount(){
    document.title = "Mis métodos de pago"
    
    /*let {makeRef} = this.props
    makeRef({mounted:true,component:this,name:'Metodos'})*/
    
    //this.loadStripe()
    this.actualizarCatalogo()
  }

  actualizarCatalogo(){
    let {id,listado} = this.state
    let data = { idC:id}
    this.setState({loading:true})
    axios.post(`${host}clientes/clientes/listaMetodosCliente`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        listado = rt.data['msg']
        this.setState({listado,loading:false})
      }
    })
  }

  formatCardNumber(num){
    return '**** **** **** '+num.slice(-4)
  }

  getIconCard(num){
    let visa = /^4[0-9]{12}(?:[0-9]{3})?$/
    let amex = /^3[47][0-9]{13}$/
    let master = /(5[1-5][0-9]{14}|2(22[1-9][0-9]{12}|2[3-9][0-9]{13}|[3-6][0-9]{14}|7[0-1][0-9]{13}|720[0-9]{12}))$/
    if(visa.test(num))
      return panelAdmin+'visa.png'
    else if(amex.test(num))
      return panelAdmin+'amex.png'
    else if(master.test(num))
      return panelAdmin+'mascard.png'
    else
      return panelAdmin+'card.png'
  }

  confirmEliminar(id){
    this.setState({modalEliminar:true,txtEliminar:'¿Está seguro que desea eliminar esta tarjeta?',idEliminar:id})
  }

  submitEliminar(){
    let {idEliminar} = this.state
    let data={idMetodo:idEliminar}
    this.setState({loading:true,modalEliminar:false,txtEliminar:''})
    axios.post(`${host}clientes/clientes/eliminarMetodoCliente/0`,data,request).then(rt=>{
       if(rt.data['status']==='ok'){
        let rta = Number(rt.data['msg'])
        let msj = ''
        let {listado} = this.state
        if(rta===1){
          msj = "La tarjeta ha sido eliminada correctamente"
          listado = listado.filter(dir => dir['ID']!==idEliminar)
        }else{
          msj="Parece que ocurrió un error, por favor intentalo más tarde."
        }
        this.setState({loading:false,txtAlert:msj,modalAlert:true,listado,idEliminar:-1})
      }else{
        this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde.',modalAlert:true,})
      }
    })
  }

  makeFav(idD){
    let {listado}=this.state
    let card = listado.filter(card => card['ID']===idD )
    card = card[0]
    if(Number(card['ESTATUS'])===1)return
    let data = { idC:sessionStorage.getItem('id'), numT:card['NUM_TARJETA'] , vence:card['VENCIMIENTO'] , estatus:1, id:idD, }
    this.setState({loading:true,})
    axios.post(`${host}clientes/clientes/registrarTarjetaUsuario`,data,request).then(rt=>{
       if(rt.data['status']==='ok'){
        let rta = Number(rt.data['msg'])
        let msj = ''
        let {listado} = this.state
        if(rta===-2){
          for(let card of listado){
            card['FAVORITO'] = (card['ID']===idD)?1:2
          }
          this.setState({listado,loading:false})
        }else{
          msj="Parece que ocurrió un error, por favor intentalo más tarde."
          this.setState({loading:false,txtAlert:msj,modalAlert:true,})
        }
      }else{
        this.setState({loading:false,txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde.',modalAlert:true,})
      }
    })
  }
  /*
  submit(){
    let{tarjetaError,tarjetaComplete,cardElement} = this.state
    if(!tarjetaComplete){
      this.setState({modalAlert:true, txtAlert:'Debe completar los datos de su tarjeta'})
      return
    }
    if(tarjetaError){
      this.setState({modalAlert:true, txtAlert:'Debe corregir los datos de su trajeta'})
      return
    }
    let name = document.getElementById('named').value.trim()

    if(name===''){
      this.setState({modalAlert:true, txtAlert:'El nombre del titular de la tarjeta es obligatorio'})
      return
    }

    let obj = {
      type: 'card',
      card: cardElement,
      billing_details: {
        name: name,
      },
    }

    //obj = JSON.stringify(obj)

    console.log(cardElement)
    console.log(obj)

    let a = cardElement._formSubmit()
    console.log(a)

    console.log(document.getElementsByName('cardnumber'))
    console.log(document.getElementsByName('cvc'))
    console.log(document.getElementsByName('exp-date'))

    return

    this.setState({loading:true})
    this.stripe.createPaymentMethod(obj).then(result => {
      this.setState({loading:false})
      if(typeof(result)==="object" && result.hasOwnProperty('paymentMethod')){
        let data = {paymentMethod:result['paymentMethod']['id']}
        axios.post(`${host}payment/GatewayStripe/saveCard/0`,data,request).then(rt=>{
          if(Number(rt.data['return'])===0){
              axios.post(`${host}payment/GatewayStripe/registrarTarjetaUsuariojajaja/0`,obj,request).then(rt=>{ })
          }
        })
      }
    })
  }
  loadStripe(){
    this.setState({loading2:true})
    loadStripe(stripeKey,{locale: 'es'}).then(stripe => {
      this.stripe = stripe
      let elements = this.stripe.elements()        
      let cardElement = elements.create('card', {
        iconStyle:'solid',
        style:{
          base:{
            fontSize:'15px',
          },
          invalid:{color:'#ED3B48',iconColor:'#ED3B48'},
        },
      })
      this.setState({cardElement:cardElement,loading2:false})
    })
  }

  detalle(id){
    let {listado} = this.state
    if(id===-1){
      this.setState({agregarEditar:true})
      let {cardElement} = this.state
      cardElement.mount(('#stripeParent'))
      cardElement.on('change', (event) => {
        let div = document.getElementById('toshow')
        this.setState({tarjetaComplete:event['complete']})
        if (event.error){
          div.textContent = '*'+event.error.message;
          this.setState({tarjetaError: true })
        }
        else{
            div.textContent = '';
            this.setState({tarjetaError: false })
        }
      })
    }
  }
  closeModal(){
    let {cardElement} = this.state
    cardElement.unmount()
    this.setState({agregarEditar:false})
  }
  */

  render(){
    return(
  		<div className="containerBrowserBubookerChildTwo">
      <div className="containerBrowserBubookerChildThree">
        
        <Loading loading={this.state.loading||this.state.loading2} />

        <Alert text={this.state.txtAlert} visible={this.state.modalAlert} onOk={()=>this.setState({modalAlert:false,txtAlert:''})} /> 
        <Alert text={this.state.txtEliminar} visible={this.state.modalEliminar} withCancel onOk={this.submitEliminar} onCancel={()=>this.setState({modalEliminar:false,txtEliminar:''})}  /> 

        {/*<Modal centered title={'Nuevo método de pago'} maskClosable={false} keyboard = {false} 
            onOk={this.submit} okText="Guardar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
            onCancel={this.closeModal} cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
            visible={this.state.agregarEditar} closable={false}
            className="miAntModal alertMy modalUnidad" forceRender >

            <div className="miUniDiv">
              
              <div className="inputs-group">
                <label className="inputs-label" htmlFor="named" style={{top:'-10px'}} > Nombre del titular </label>
                {
                  this.state.agregarEditar&&
                  <input type="text" autoFocus maxLength={30} className="inputs-input" name="name" id="named" />
                }
              </div>

              <div className="inputs-group" style={{top:'20px'}} >
                <label className="inputs-label" > Datos de la tarjeta </label>
              </div>

              <div id="stripeParent" style={{marginTop:'0px'}} />

              <div id="toshow" />

            </div>
        </Modal>*/}

        <div style={{marginTop:'20px'}} className="itemBackBu flexcenter Fstart" onClick={()=>this.props.indiceRedirecter('/')} >
          <Icon type="left" />
          <span> Regresar </span>
        </div>
        
        <div className="titleSectionBubooker flexcenter Fstart">
          Métodos de pago
        </div>

        <div className="listAdressClient">
        {
          this.state.listado.length===0?
          <div className="nonResult flexcenter Fspaceb">
            <img alt="..." src={panelAdmin+'nonproductos.png'} />
          </div>:
          this.state.listado.map((card,i) => (
            <div className="itemDireccion flexcenter Fspaceb" key={'dir_'+i}>

              <div className="metaInfo card flexcenter Fstart">
                <div className="info card">

                  <h4>
                    <i className={ Number(card['ESTATUS']) === 1 ? 'yesfavorito' : 'nofavorito'} onClick={()=>this.makeFav(card['ID'])} >
                      <Corazon />
                    </i>
                  </h4>
                  
                </div>

                <span> {this.formatCardNumber(card['NUM_TARJETA'])} </span>

              </div>

              <div className="flexcenter Fstart">
                <img alt="..." src={this.getIconCard(card['NUM_TARJETA'])} className="cardIcon" />
                <img onClick={()=>this.confirmEliminar(card['ID'])} src={img+'basurero_gris.png'} className="trashIcon" alt="..." title='Eliminar registro' />
              </div>

            </div>
          ))
        }

        </div>
      </div>
  		</div>
    );
  }
}

export default withRouter(MetodosPago);