import React from 'react';
import axios from 'axios';
import {Globalrequest as request} from './../../../request.js'
import { Host as host,ImgPanelAdmin as panelAdmin} from './../../../app.json';

import Loading from './../../../modalCargando/loading';
import Alert from './../../../modalCargando/modalAlert';

import {formatNumber} from "./../../../utilities/generalfunctions";

/* Librerías */
import moment from 'moment';
import {  Icon,} from 'antd';
import { withRouter } from "react-router-dom";

class Direcciones extends React.Component{
  constructor(props) {
    super(props);
    
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.detallePedido = this.detallePedido.bind(this)

    this.state={
      pedidos:[],
      pedidos2:[],
      pedidosDetalle:{},
      /**/
      loading:false,
      modalAlert:false,
      txtAlert:'',
      listado:[],
      first:true,
    }
  }

  componentDidMount(){
    document.title="Mis compras"
    let {makeRef} = this.props
    makeRef({mounted:true,component:this,name:'Pedidos'})
    this.actualizarCatalogo()
  }

  formatFecha(fecha,inputFormat,outputFormat){
    let fecha2 = moment(fecha,inputFormat)
    return fecha2.format(outputFormat)
  }

  actualizarCatalogo(){
    let data = {idC:sessionStorage.getItem('id'),idE:-1}
    this.setState({loading:true})
    axios.post(`${host}pedidos/pedidos/listaPedidosPorBubooker`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let pedidos = rt.data['data']
        for (let p of pedidos){
          p['LABEL_PEDIDO'] = moment(p['FECHA_PEDIDO'],'YYYY-MM-DD HH:mm:ss').format('DD-MMMM-YYYY')
        }        
        let pedidosDetalle = pedidos.reduce((r, a) => {
         r[a['LABEL_PEDIDO']] = [...r[a['LABEL_PEDIDO']] || [], a];
         return r;
        }, {});

        pedidosDetalle = Object.entries(pedidosDetalle).reduce((result,[key,value])=>{
          if(value.length===1)
            result[key] = value
          else
            result[key] = value.sort(
              (a,b) => moment(b['FECHA_PEDIDO'],'YYYY-MM-DD HH:mm:ss').valueOf()-moment(a['FECHA_PEDIDO'],'YYYY-MM-DD HH:mm:ss').valueOf() )
          return result
        },{})

        this.setState({loading:false,pedidos:pedidos,pedidos2:pedidos,pedidosDetalle:pedidosDetalle,prove:true})
      }
    }).finally(()=>{
      let {first} = this.state
      if(first){
        let prefiltro = sessionStorage.getItem('prefiltro')
        if(prefiltro !== null && prefiltro !== undefined){
          sessionStorage.removeItem('prefiltro')
          prefiltro = prefiltro.split('[/**/]')
          this.changeFiltros(prefiltro[1],prefiltro[0])
          this.classToggle()
        }
        this.setState({first:false})
      }
    })
  }

  detallePedido(folio){
    let {redirectTo} = this.props
    redirectTo('/config/pedidos/'+folio)
  }

  changeSrcError(evt){
    evt.target.src = panelAdmin+'/perfilDefault.jpg';
  }

  render(){
    return(
      <div className="containerBrowserBubookerChildTwo">
      <div className="containerBrowserBubookerChildThree">
        
        <Loading loading={this.state.loading} />
        <Alert text={this.state.txtAlert} visible={this.state.modalAlert} onOk={()=>this.setState({modalAlert:false,txtAlert:''})} /> 
        <Alert text={this.state.txtEliminar} visible={this.state.modalEliminar} withCancel onOk={this.submitEliminar} onCancel={()=>this.setState({modalEliminar:false,txtEliminar:''})}  /> 

        <div style={{marginTop:'20px'}} className="itemBackBu flexcenter Fstart" onClick={()=>this.props.indiceRedirecter('/')} >
          <Icon type="left" />
          <span> Regresar </span>
        </div>
        
        <div className="titleSectionBubooker flexcenter Fstart">
          Mis compras
        </div>

        <div className="listAdressClient">
         
         {
           Object.keys(this.state.pedidosDetalle).length===0?            
            <div className="nonResult flexcenter Fspaceb">
              <img alt="..." src={panelAdmin+'noncitas.png'} />
            </div>:
           Object.entries(this.state.pedidosDetalle).map( ([key,value]) => 
            <div key={'pedidosFecha_'+key} className="tarjetaPedido2" >
              <div className="headGris">{key}</div>
              <div>
                {
                  value.map((pedido,indice) => (
                    <div key={'pedido_'+indice} className="detallePedido flexcenter Fstart" onClick={() => this.detallePedido(pedido['FOLIO'])} >
                      
                      <div className="imgPedido" >
                        <img alt="..." src={ pedido['FOLDER'] + pedido['IMG_PERFIL'] + '.png' } onError={this.changeSrcError} />
                      </div>
                      
                      <div style={{width:'100%'}} >
                        <div className="info infoInline">                        
                          <div className="infoInlineChild"> <div className="meta">{pedido['NOMBRE_EMPRESA']} </div>  </div>
                          <div className="infoInlineChild"> <div className="meta">Folio: </div>  <div className="content">{pedido['FOLIO']}</div> </div>
                        </div>


                        <div className="info infoInline">

                          <div className="infoInlineChild"> <div className="meta">Sucursal: </div>  <div className="content"> {pedido['SUCURSAL']} </div></div>
                          <div className="infoInlineChild">
                            <div className={"content "+pedido['ESTATUS'].toLowerCase().split(' ').join('')}> {pedido['ESTATUS']} </div>
                          </div>

                        </div>

                        <div className="info infoInline">
                          <div className="infoInlineChild"> <div className="meta">Hora: </div>  <div className="content">{this.formatFecha(pedido['FECHA_PEDIDO'],'YYYY-MM-DD HH:mm:ss','HH:mm:ss')}</div> </div>
                          <div className="infoInlineChild"> <div className="meta">Total: </div>  <div className="content"/>${formatNumber(pedido['TOTAL'],2)} MXN </div>
                        </div>
                      </div>

                    </div>
                  ))
                }
              </div>
            </div>
           )
         }

        </div>
      </div>
      </div>
    );
  }
}

export default withRouter(Direcciones);