import React from 'react';
import axios from 'axios'
import { Globalrequest as request,isLogged} from './../request.js';
import {withRouter} from "react-router-dom";
import {Img as img,Host as host,ImgGral as img2} from './../app.json'
import { Icon , Modal , Divider,Popover,Input} from 'antd';

import ModalAlert from './../modalCargando/modalAlert';
import LoadingSpinner from './../modalCargando/loadingSpinner';

import './login.scss' 

class Login extends React.Component{
    constructor(props){
        super(props)
        this.submit = this.submit.bind(this)
        this.showPassword = this.showPassword.bind(this)
        this.goToRegistro = this.goToRegistro.bind(this)
        this.handleModalRecuperacion = this.handleModalRecuperacion.bind(this)

        this.sendRecuperacion = this.sendRecuperacion.bind(this)

        /**/
        this.reSendMail = this.reSendMail.bind(this)
        /**/
        this.submitNewCorreo = this.submitNewCorreo.bind(this)
        
        this.noIiniciar = this.noIiniciar.bind(this)

        this.state = {
          pass:true,
          options:[],
          mail:'',
          contra:'',
          modalRecuperacion:false,
          loading:false,
          modalAlert:false,
          txtAlert:'',

          canChangeMail:true,

          modal:false,
          modalChangeCorreo:false,
          mailmodal:'jajaja@mail.com',
          /**/
          modalNoIniciar:false,
          txtNoIniciar:'',
        }
    }

    componentWillMount(){
      document.title = "Inicio de sesión";
      if(isLogged()){
        this.props.redirectTo('/')
      }
    }

    componentDidMount(){ }

    noIiniciar(){
      let {cerraSesion} = this.props
      cerraSesion(true)
      this.setState({modalNoIniciar:false,txtNoIniciar:false,loading:false,})
    }
    
    submit(e){
      e.preventDefault()
      let formData = new FormData(document.getElementById('formulaLogin'))
      if(
        formData.get('email').trim()===''||
        formData.get('contra').trim()===''
      ){
        this.setState({txtAlert:'Complete los campos',modalAlert:true})
        return
      }

      let mail = formData.get('email').trim()
      let regexMail = /(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$){1,200}/;      
      if( !regexMail.test(mail)) {
        this.setState({txtAlert:'Ingrese un correo válido',modalAlert:true})
         return
      }

      this.setState({loading:true})
      let data = { mail: formData.get('email'), contra: formData.get('contra') }
      axios.post(`${host}usuarios/usuarios/login`,data,request).then(rt=>{
        if(rt.data['status']==='ok'){
          if( Number(rt.data['found']) < 0 || Number(rt.data['pssw']) < 0 ){
            this.setState({txtAlert:rt.data['msj'],modalAlert:true,loading:false})
            return
          }
          else{
            let tipo = Number(rt.data['type'])
            let valid = Number(rt.data['valid'])
            if(valid===3){
              this.setState({txtAlert:'Tu cuenta ha sido bloqueada, contacta a soporte para saber los detalles',modalAlert:true,loading:false})
              return
            }
            else if(valid===2&&tipo!==3){
              this.setState({mailmodal:data['mail'],modal:true,loading:false})
            }
            else if(valid===2){
              this.setState({txtAlert:'Tu cuenta está inactiva.',modalAlert:true,loading:false})
              return
            }
            else{
              let ciudadUsuario = localStorage.getItem('ciudadUsuario')
              let estadoUsuario = localStorage.getItem('estadoUsuario')
              let carrito = JSON.parse(localStorage.getItem('carrito')) || {}

              let latitude = localStorage.getItem('latitude')
              let longitude = localStorage.getItem('longitude')

              let ruta = sessionStorage.getItem('ruta')

              sessionStorage.clear()
              localStorage.clear()
              
              localStorage.setItem('ciudadUsuario',ciudadUsuario)
              localStorage.setItem('estadoUsuario',estadoUsuario)
              localStorage.setItem('carrito',JSON.stringify(carrito))
              
              sessionStorage.setItem( 'latitude',latitude )
              sessionStorage.setItem( 'longitude', longitude)

              let data = { mail: formData.get('email'), op:1 }

              if(tipo===1){//bubooker
                axios.post(`${host}usuarios/usuarios/datosPorUsuario`,data,request).then(rt2=>{

                  localStorage.setItem('id',rt2.data['datos']['id'])
                  localStorage.setItem('mail',rt2.data['datos']['maile'])
                  localStorage.setItem('userType','user')
                  localStorage.setItem('typeUser','1')

                  sessionStorage.setItem('id',rt2.data['datos']['id'])
                  sessionStorage.setItem('mail',rt2.data['datos']['maile'])

                  sessionStorage.setItem('nameb',btoa(rt2.data['datos']['nombre']+' '+rt2.data['datos']['apellido']) )
                  sessionStorage.setItem('src',rt2.data['datos']['src'])
                  sessionStorage.setItem('metadata',JSON.stringify(rt2.data))

                  /**/
                  sessionStorage.setItem('isEliminada',valid)
                  /**/

                  this.setState({loading:false})
  
                  if(ruta!==undefined&&ruta!==null){
                    sessionStorage.removeItem('ruta')
                    this.props.redirectTo('/empresas/'+ruta)
                  }
                  else{ this.props.redirectTo('/') }
  
                })
              }
              if(tipo===2||tipo===3){
                data['detalle'] = -1;
                data['extra'] = valid===1;

                axios.post(`${host}empresas/empresas/datosPorEmpresa`,data,request).then(rt2=>{
                  
                  rt2.data['datos']['LABEL'] = btoa(data['mail'])
                  rt2.data['datos']['ALIAS'] = btoa(btoa(rt.data['alias']))

                  localStorage.setItem('id',rt2.data['datos']['ID'])//id encriptado
                  localStorage.setItem('mail',rt2.data['datos']['CORREO_REGISTRO2'])// mail encriptado
                  
                  localStorage.setItem('userType','nouser')
                  localStorage.setItem('typeUser','2')

                  sessionStorage.setItem('subid',rt.data['code'])
                  sessionStorage.setItem('submail',rt.data['index'])

                  /**/
                  sessionStorage.setItem('id',rt2.data['datos']['ID'])// id encriptado
                  sessionStorage.setItem('mail',rt2.data['datos']['CORREO_REGISTRO2']) // mail encriptado
                  sessionStorage.setItem('folder',rt2.data['folder']) //folder
                  sessionStorage.setItem('src',rt2.data['datos']['DIRECCION_IMG_PERFIL']) //imagenPerfil
                  /**/
                  sessionStorage.setItem('idNotificacionPorAtender','null')
                  sessionStorage.setItem('isEliminada',rt2.data['datos']['ESTATUS'])
                  sessionStorage.setItem('isEliminadaID',rt.data['exe'])
                  /**/

                  if(tipo===3){
                    localStorage.setItem('userSubType','nosubuser')
                    localStorage.setItem('typeSubUser','3')

                    localStorage.setItem('subid',rt.data['code'])//subid encriptado
                    localStorage.setItem('submail',rt.data['index'])//submail encriptado
                    rt.data['DATA'] = JSON.stringify(rt.data['DATA'])
                    rt2.data['datos']['LABEL'] = btoa(data['mail'])

                    let data2 = { id:rt.data['code'], idE:sessionStorage.getItem('id'), forCheck:1, }

                    axios.post(`${host}subusuarios/subusuarios/permisosUsuario`,data2,request).then(rt3=>{
                      if(rt3.data['status']==='ok' && rt3.data['data']==='ok' ){
                        let selected =  Object.values(rt3.data['head']).filter(s => s['SELECTED'])
                        if(selected.length>0){
                          sessionStorage.setItem('perfiles',JSON.stringify( rt3.data['head'] ) )
                          this.setState({loading:false})
                          sessionStorage.setItem('metadata',JSON.stringify(rt2.data))
                          this.props.redirectTo('/home')
                        }else{
                          this.setState({modalNoIniciar:true,txtNoIniciar:'Este perfil no tiene perfil no cuenta con permisos sobre ninguna sucursal.'})
                        }
                      }else{
                        this.setState({modalNoIniciar:true,txtNoIniciar:'Parece que hubo un error al consultar tus permisos de usuario, por favor intentalo más tarde.'})
                      }
                    })

                  }else{
                    this.setState({loading:false})
                    sessionStorage.setItem('metadata',JSON.stringify(rt2.data))
                    this.props.redirectTo('/home')
                  }
                })
              }
            }
          }
        }
      })
    }
    
    showPassword(){
      let {pass} = this.state
      this.setState({pass:!pass})
    }

    handleModalRecuperacion(a){
      if(!a){
        document.getElementById('mail22').value=""
      }
      this.setState({modalRecuperacion:a})
    }

    sendRecuperacion(){
      let mail = document.getElementById('mail22').value
      let regexMail = /(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$){1,200}/;
      if( !regexMail.test(mail)) {
        this.setState({txtAlert:'Ingrese un correo válido',modalAlert:true})
         return
      }
      let data = {mail:mail}
      this.setState({loading:true})
      axios.post(`${host}usuarios/usuarios/recuperarCuenta`,data,request).then(rt=>{
        let re = Number(rt.data['RESULTADO'])
        if(re===-1){
          this.setState({txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #01-02]',modalAlert:true})
        }
        if(re=== 0){
          this.setState({txtAlert:'El correo ingresado no está registrado',modalAlert:true})
        }
        if(re === 1){
          this.setState({txtAlert:'Correo enviado exitosamente',modalAlert:true})
        }
        this.setState({loading:false})
      })
    }

    reSendMail(){
        let data = {mail:this.state.mailmodal}
        this.setState({loading:true})
        axios.post(`${host}usuarios/usuarios/sendMail`,data,request).then(rt=>{
          this.setState({loading:false})
            if(Number(rt.data['RESULTADO']) === 0){
                this.setState({txtAlert:"Correo no registrado",modalAlert:true})
            }
            if(Number(rt.data['RESULTADO']) === -1){
                this.setState({txtAlert:"Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #01-02]",modalAlert:true})
            }
            if(Number(rt.data['RESULTADO']) === 1){
                this.setState({txtAlert:"Correo enviado correctamente",modalAlert:true})
            }
        })
    }

    submitNewCorreo(){
      let newCorreo = document.getElementById('mail23').value
      let {mailmodal} = this.state
      newCorreo = newCorreo.trim()
      if(newCorreo === ''){
          this.setState({txtAlert:"El nuevo correo es obligatorio",modalAlert:true})
          return
      }
      let regexMail = /(^[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?$){1,200}/;
      if(!regexMail.test(newCorreo)){
          this.setState({txtAlert:"Ingrese un correo válido",modalAlert:true})
          return
      }
      let data={ oldCorreo:mailmodal, newCorreo:newCorreo, }
      this.setState({loading:true})
      axios.post(`${host}usuarios/usuarios/cambiarCorreoGeneral`,data,request).then(rt=>{
        this.setState({loading:false})
          if(Number(rt.data)===-1){
              this.setState({txtAlert:"Ya hay una cuenta asociada al correo ingresado",modalAlert:true})
              return
          }else if(Number(rt.data)===-2){
              document.getElementById('mail23').value = ''
              this.setState({mailmodal:newCorreo,modalChangeCorreo:false,
                  txtAlert:"Correo enviado correctamente",modalAlert:true
              })
          }else{
              this.setState({txtAlert:"Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #01-02]",modalAlert:true})
          }
      })
    }

    goToRegistro(a){
      if(a===0) this.props.redirectTo('/auth/registro/bubooker')
      if(a===1) this.props.redirectTo('/auth/registro/negocio')
    }

    render(){
 
      return(
          <div className="cont-Login" >

          <Modal centered title='Recuperar contraseña' maskClosable={false} keyboard = {false} 
            okText="Enviar correo" okButtonProps={{ loading: this.state.loading, className:'btn btn1' , style:{ width:'120px'} }}
            cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
            className="miAntModal alertMy modalUnidad"
            visible={this.state.modalRecuperacion} closable={false}
            onOk={this.sendRecuperacion}
            onCancel={() => this.handleModalRecuperacion(false)}
            zIndex={10} >
            <div className="miUniDiv">
              
              <div className="txt" style={{textAlign:'justify',marginTop:'-30px',marginBottom:'30px'}} >
                Escribe la direccion de correo electrónico asociado a tu cuenta.
              </div>

              <div className="inputs-group">
                <label className="inputs-label" style={{top:'-10px'}} > Correo electrónico </label>
                <input type="text" autoFocus className="inputs-input" id="mail22" />
              </div>

              <div className="txt" style={{textAlign:'justify',marginBottom:'-40px',marginTop:'10px'}} >
                Si ya no usas el correo electrónico asociado a tu cuenta de BuBook Mx, puedes comunicarte con el departamento de atención al cliente para obtener ayuda a fin de restaurar el acceso a tu cuenta.
              </div>

            </div>
          </Modal>

          <Modal centered title="" maskClosable={false} keyboard = {false}
              okText="Aceptar" okButtonProps={{ loading: this.state.loading , className: 'btn btn1'}}
              cancelButtonProps={{ className: 'btn btn2' , style:{display:'none'} }}
              visible={this.state.modal}
              closable={false}
              onOk={()=>this.setState({modal:false,})}
              className="miAntModal"
              zIndex={90}
              >

              <div className="mymodal">
                  <div className="body-alert" >
                      <img src={`${img2}correo/bubook.png`} alt="..." className="icon-alert" />
                      <div>
                          <h4> ¡Registro exitoso! </h4>
                          <span className="bloque1" >
                              Estás a un solo paso de ser parte de BuBook Mx y poder encontrar eso que estabas buscando en las diferentes empresas y negocios registrados.
                          </span>
                          
                          <span className="bloque1" >
                              Para poder seguir brindando una atención y servicio de calidad, por favor activa tu cuenta en:
                          </span>

                          <label className="nota nopoint"> {this.state.mailmodal} </label>
                          <span className="bloque2" >
                              ¿Aún no te ha llegado la activación?
                          </span>

                          <br/>

                          <span className="bloque1" >
                              Revisa la bandeja de los correos no deseados (spam) o solicita un&nbsp;
                              <label className="nota" onClick={this.reSendMail}>nuevo mensaje de activación </label>
                          </span>
                          
                          <br/> <br/>
                          <label className="notanotanota" >
                              ¿No es tu correo?<br/>
                              <span  onClick={()=> this.setState({modalChangeCorreo:true})} >Cambiar correo registrado</span>
                          </label>

                      </div>
                  </div>
              </div>
          </Modal>

          <Modal centered title='Cambio de correo' maskClosable={false} keyboard = {false} 
            okText="Guardar" okButtonProps={{ loading: this.state.loading, className:'btn btn1' , style:{ width:'120px'} }}
            cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
            className="miAntModal alertMy modalUnidad"
            visible={this.state.modalChangeCorreo} closable={false}
            onOk={this.submitNewCorreo}
            onCancel={()=> this.setState({modalChangeCorreo:false})}
            zIndex={95} >
            <div className="miUniDiv">
                
              <div className="inputs-group">
                <label className="inputs-label" style={{top:'-10px'}} > Ingresa tu nuevo correo electrónico </label>
                <input type="text" autoFocus className="inputs-input" id="mail23" />
              </div>

            </div>
          </Modal>


          <LoadingSpinner loading={this.state.loading} text={'Cargando'} />
          <ModalAlert visible={this.state.modalAlert} text={this.state.txtAlert} onOk={() => this.setState({modalAlert:false,txtAlert:''})}/>

          <ModalAlert visible={this.state.modalNoIniciar} text={this.state.txtNoIniciar} onOk={() => this.noIiniciar()}/>


              <div className="logginInner centrado flexcenter Fspaceb" style={{maxWidth:'800px',minWidth:'800px'}} >
                
                <div className="sideTarjetaAuth">
                  <img alt="..." className="imgLogo" src={img+'bubook_blanco.png'} />
                  <img alt="..." className="imgCard" src={img+'cardAuth.png'} />
                </div>

                <div className="login pdd">

                  <div style={{marginTop:'20px'}} className="itemBack flexcenter Fstart"
                  onClick={()=>this.props.redirectTo('/')}
                  >
                    {/*<Icon type="arrow-left" />*/}
                    <Icon type="home" />
                    <span> Ir a inicio </span>
                  </div>

                  <div style={{marginTop:'35px'}} className="labelForForm"> Inicia sesión en BuBook </div>

                    <form id="formulaLogin" style={{marginTop:'20px'}} >

                      
                      <Input prefix={<Icon type="mail" />} name="email" id="emaild" placeholder="Correo electrónico" className="inputBase" />

                      <Input.Password prefix={<Icon type="lock" />} name="contra" id="contrad" placeholder="Contraseña" className="inputBase pss" />

                      <button style={{margin:'20px 0',display:'block',width:'100%'}} className="btnBase botonNaranja flexcenter Fcenter" onClick={this.submit}>
                       Iniciar sesión
                      </button>

                    </form>

                    <div className="bloqueAyuda flexcenter">
                      <span> ¿Olvidaste tu contraseña? </span>
                      <label onClick={() => this.handleModalRecuperacion(true)}> Obtén ayuda </label>
                      <Divider className='myDivider loginDivider'> o </Divider>
                    </div>

                    <Popover content={
                    <div className="contenedorCategorias">
                        <div className="contWhite">
                        <div className="contWhiteContent">
                          <div className="itemCategoria flexcenter Fspaceb" onClick={()=>this.goToRegistro(0)} > Para mí </div>
                          <div className="itemCategoria flexcenter Fspaceb" onClick={()=>this.goToRegistro(1)}> Para administrar mi negocio </div>
                        </div>
                        </div>
                      </div>
                    } title={null} trigger="click" placement="bottom" overlayClassName="popReg" >
                    <div className="btnBase botonNegro flexcenter Fcenter">
                      ¡Quiero registrarme!
                      <Icon type="down" className="iconSee white" />
                    </div>
                  </Popover>

                    <div className="txtFoot">
                       {/*Página sujeta a la <span>Política de privacidad</span> y a los <span>Términos y condiciones1</span> de BuBook.*/}
                    </div>
                </div>
              
              </div>

          </div>
      )
    }
  }
  
  export default withRouter(Login);