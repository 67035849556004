import React,{ useState } from 'react';
import axios from 'axios'; 

import Loading from "./../../modalCargando/loading";

import { Host as host } from './../../app.json';

import { Modal,Select,Input,message} from 'antd';
import { Globalrequest as request } from './../../request.js';
import NumberFormat from 'react-number-format';

const ReportarProblema = (props) => {

	const [tipoR,setTipoR] = useState(1)
	const [loading,setLoading] = useState(false)
  const [cant,setCant] = useState(0)
  

	const showAddComent = () =>{
    let {dataR,controlAlert,handler} = props
    let mensaje = document.getElementById('msjR').value.trim()
    if(mensaje===''){
      controlAlert({modalAlert:true,txtAlert:'Por favor, ingrese un mensaje para su reporte'})
      return
    }
    let data={
    	tipoUsuario:dataR['tipoUR'],
    	idUsuario:sessionStorage.getItem('id'),
    	nombre:dataR['nombreR'],
    	correo:dataR['correoR'],
    	telefono:dataR['telefonoR'],
    	tipoAyuda:tipoR,
    	mensaje,
    	id:-1,
    }
    setLoading(true)
    if(dataR['extra']&&dataR['extra']['use']){
      data['tipoAyuda'] = 6
      data['hideMessage'] = dataR['extra']['hideMessage']
      axios.post(`${host}pedidos/pedidos/reportarEmpresa`,data,request).then(rt=>{
        if(rt.data['status']==='ok'){
          controlAlert(true,'Reporte guardado exitosamente.')
        }else{
          controlAlert(true,'Parece que ocurrió un error, por favor intentelo más tarde.')
        }
        handler(false)
      })
      return
    }
    axios.post(`${host}pedidos/pedidos/reportarproblemaReal`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
      	controlAlert(true,'Reporte guardado exitosamente.')
      }else{
      	controlAlert(true,'Parece que ocurrió un error, por favor intentelo más tarde.')
      }
      handler(false)
    })
  }

	return(
		<Modal centered title={<span className="flexcenter Fstart" >Reportar un problema</span>}
      okText="Enviar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
      cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
      visible={props.visible} width={400}
      closable maskClosable={false} keyboard = {false} destroyOnClose
      className="miAntModal alertMy modalUnidad"
      onCancel={()=>props.handler(false) }
      onOk={showAddComent} zIndex={5} >
      <div className="miUniDiv" id="miUniDiv" style={{margin:'-30px -10px',height:'auto'}} >

      	<Loading loading={loading} />

        <div className="inputs-group" style={{marginTop:'40px',marginBottom:'5px',height:'auto'}}>
          <label className="inputs-label" htmlFor="reportd" style={{top:'-10px'}} > Nombre </label>
          <Input className="inputs-input" value={props.dataR['nombreR']} readOnly /> 
        </div>

        <div className="inputs-group" style={{marginTop:'40px',marginBottom:'5px',height:'auto'}}>
          <label className="inputs-label" htmlFor="reportd" style={{top:'-10px'}} > Correo electrónico </label>
          <Input className="inputs-input" value={props.dataR['correoR']} readOnly /> 
        </div>

        <div className="inputs-group" style={{marginTop:'40px',marginBottom:'5px',height:'auto'}}>
          <label className="inputs-label" htmlFor="reportd" style={{top:'-10px'}} > Teléfono </label>
          <NumberFormat className="inputs-input"  format="(###) ### ####" mask="_" value={props.dataR['telefonoR']} readOnly /> 
        </div>

        <div className="inputs-group" style={{marginTop:'40px',marginBottom:'5px',height:'auto'}}>
          <label className="inputs-label" htmlFor="reportd" style={{top:'-10px'}} > Motivo </label>
          <Select className="inputs-input" onChange={tipoR => setTipoR(tipoR)}
            defaultValue={ (props.dataR['extra']&&props.dataR['extra']['use'])?props.dataR['extra']['tipoR'] : null }
            disabled={(props.dataR['extra']&&props.dataR['extra']['use'])}
          >
            <Select.Option value={1}> Solicitar información </Select.Option>
            <Select.Option value={2}> Informar sobre un error </Select.Option>
            <Select.Option value={3}> Recomendar una nueva funcionalidad </Select.Option>
            <Select.Option value={4}> Contactar con atención al cliente </Select.Option>
            <Select.Option value={5}> Otro </Select.Option>
            <Select.Option value={6} style={{display:'none'}} > Reportar empresa </Select.Option>
          </Select>
        </div>

        <div className="inputs-group" style={{marginTop:'40px',marginBottom:'5px',height:'auto'}}>
          <label className="inputs-label" htmlFor="reportd" style={{top:'-10px'}} > Mensaje </label>
          <Input.TextArea
            id="msjR"
            className="inputs-input"
            maxLength={ (props.dataR['extra'] && props.dataR['extra']['use']) ? 400 : 500 }
            autoSize={{minRows:1,maxRows:4}}
            onChange={ e => setCant(e.target.value.length)}
            />
        </div>

        <label className="flexcenter Fend">
          {cant} / {(props.dataR['extra'] && props.dataR['extra']['use']) ? '400' : '500' /* use ? Reportar empresa : Reportar problema */ }
        </label>

      </div>
    </Modal>
	)
}

const InvitarAmigos = (props) => {

  const copyText = (id) =>{
    try{
      let div2 = document.getElementById(id)
      let selection = window.getSelection();
      let range = document.createRange();
      range.selectNodeContents(div2);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand("copy");
      selection.removeAllRanges();
      message.success('Texto copiado al portapapeles')
    } catch(e) { console.log(e) }
  }

  return(
    <Modal centered title={<span className="flexcenter Fstart" > Comparte Bubook.mx con tus amigos </span>}
      okText="Copiar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
      cancelText="Cerrar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
      visible={props.visible} width={400}
      closable maskClosable={false} keyboard = {false} destroyOnClose
      className="miAntModal alertMy modalUnidad "
      onCancel={()=>props.handler(false) }
      onOk={()=>copyText('inviteCopy')} zIndex={5} >
      <div className="miUniDiv" id="inviteCopy" style={{margin:'-30px -10px',height:'auto'}} >
        
        <div className="titleShare"> ¡Únete a BuBook.mx! </div>

        <div className="deescShare"> Vender debe ser algo rápido, sencillo y a cualquier hora. Bubook.mx es una plataforma que establece una conexión entre empresas, negocios y clientes.  </div>

        <div className="listShare">
          <div className="itemShare"> <span> &#10003; </span> <label> Control del inventario masivo </label> </div>
          <div className="itemShare"> <span> &#10003; </span> <label> Publicación de promociones y ofertas </label> </div>
          <div className="itemShare"> <span> &#10003; </span> <label> Control de múltiples sucursales </label> </div>
          <div className="itemShare"> <span> &#10003; </span> <label> Control de citas y reservaciones </label> </div>
        </div>

        <div className="deescShare"> Entra a <a href="https://bubook.nibirusystem.com" rel="noopener noreferrer" target="_blank" > https://bubook.nibirusystem.com </a> para más información o descarga nuestra app desde <a href="https://bit.ly/33vKv9Z" rel="noopener noreferrer" target="_blank" > https://bit.ly/33vKv9Z </a> </div>

      </div>
    </Modal>
  )
}

export {ReportarProblema,InvitarAmigos};