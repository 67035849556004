import React from 'react'
import {withRouter} from "react-router-dom";
import {Landing as landingImg} from './../app.json'

import './privacidad.scss' 

class Login extends React.Component{
    constructor(props){
        super(props)
        this.state = { pass:true, }
    }

    componentDidMount(){
      document.title = "Aviso de privacidad";

    }
    
    render(){
 
      return(
        <div className="legacyWrapper">
          <div className="content">
            
            <div className="head">
              <img alt="..." src={landingImg+'Grupo19236.png'} onClick={()=>this.props.redirectTo('/')} />
            </div>

            <div className="body">
              
              <div className="title"> Aviso de privacidad </div>
              
              <div className="section primary">
                <div className="txt">En cumplimiento con lo dispuesto por la Ley Federal de Protección de Datos publicada el 05 de Julio de 2010 en el Diario Oficial de la Federación, le informamos que los datos personales y sensibles que sean proporcionados, serán utilizados únicamente para los fines más adelante mencionados. Usted tiene derecho a solicitar la limitación y revocar el consentimiento del uso o divulgación de la información proporcionada así como a reclamar los Derechos ARCO (Acceso, Rectificación, Cancelación y Oposición) en términos de la Ley antes referida, acudiendo a nuestro domicilio, a través de la línea telefónica o del correo electrónico que se mencionan en el presente Aviso de Privacidad. El procedimiento para solicitar la limitación, revocar el consentimiento del uso o divulgación de la información, así como el procedimiento para ejercer los Derechos ARCO requiere de la identificación del Titular o de su Representante Legal para poder atenderle dentro de los plazos establecidos por la Ley y a través de los medios telefónicos o de correo electrónico que apliquen en cada caso.</div>
              </div>

              <div className="section">
                <div className="title"> RESPONSABLE DEL TRATAMIENTO DE SUS DATOS PERSONALES </div>
                <div className="txt">El responsable del tratamiento de sus datos personales será BuBook. Para efectos del presente aviso de privacidad, BuBook señala como su domicilio el ubicado en Calle Cristobal Colón Fraccionamiento Costa Azul, C.P. 39850 Acapulco, Guerrero.</div>
              </div>

              <div className="section">
                <div className="title"> FINALIDADES DEL TRATAMIENTO DE SUS DATOS PERSONALES </div>
                <div className="txt">Los datos personales que BuBook recabe serán usados principalmente para: Los datos personales que BuBook recabe serán usados principalmente para:</div>
                
                <div className="titleList"><span> I.- </span> Finalidades que dan origen y son necesarias para la existencia, mantenimiento y cumplimiento de la relación jurídica entre el responsable y titular.</div>
                <div className="itemList"><span> - </span> Reclutamiento y / o Selección de Empleados o Solicitantes de Empleo, Proveedores y Prestadores de Servicio.</div>
                <div className="itemList"><span> - </span> Celebrar diversos contratos y convenios con Empleados, Proveedores, Prestadores de Servicios y terceros en general.</div>
                <div className="itemList"><span> - </span> Cumplir con obligaciones contractuales.</div>

                <div className="titleList"><span> II.- </span> Otras  Finalidades.</div>
                <div className="itemList"><span> - </span> Atención a Clientes, Proveedores y Prestadores de Servicios.</div>
                <div className="itemList"><span> - </span> Monitoreo de la Calidad del Servicio al Cliente y Encuestas de Satisfacción.</div>
                <div className="itemList"><span> - </span> Envío de Publicidad y Promociones</div>
                <div className="itemList"><span> - </span> Programas de Lealtad</div>
                <div className="itemList"><span> - </span> Investigación y Estudios de Mercado</div>

                <div className="txt">En caso de que se desee manifestar la negativa para el tratamiento de los Datos Personales con relación a las finalidades que no son necesarias para la relación jurídica entre BuBook y el Titular de dichos Datos, éste último podrá llevar a cabo la solicitud correspondiente acudiendo a nuestro domicilio, a través de la línea telefónica o del correo electrónico que se mencionan en el presente aviso.</div>

              </div>

              <div className="section">
                <div className="title"> TRANSFERENCIA DE DATOS PERSONALES </div>
                <div className="txt">BuBook podrá transferir sus datos personales a terceros mexicanos o extranjeros, filiales, subsidiarias, controladoras, asociadas, comisionistas o sociedades integrantes de BuBook que le provean de servicios necesarios para la Administración de Recursos Humanos y Pago de Nóminas, de Servicios de Corresponsalía Bancaria y Servicios Financieros en General, Prestación de Servicios Profesionales en materia Fiscal, Laboral, Penal, de Sistemas Computacionales, de Servicios Médicos, de Mercadotecnia y Publicidad, requiriéndose para el caso de éstas últimas dos el consentimiento del titular. En caso de que el titular no esté de acuerdo con la transferencia de datos que requieran su consentimiento podrá oponerse a dicho tratamiento a través de los medios referidos en el presente aviso</div>
              </div>

              <div className="section">
                <div className="title"> USO DE MECANISMOS DE IDENTIFICACION EN MEDIOS REMOTOS </div>
              </div>

              <div className="section">
                <div className="title"> MODIFICACIONES DEL AVISO DE PRIVACIDAD </div>
                <div className="txt">Los cambios que pudieran efectuarse al presente aviso serán comunicados en su oportunidad por este medio.</div>
              </div>

            </div>
          </div>
        </div>
      )
    }
  }
  
  export default withRouter(Login);