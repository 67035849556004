import React from 'react';
import axios from 'axios';
import { Globalrequest as request } from './../../../request.js';
import { Host as host ,Img as img ,} from './../../../app.json'
import { Modal,Spin,Icon } from 'antd';
import moment from 'moment'

import {formatNumber} from './../../../utilities/generalfunctions'

class Stripe extends React.Component{
  constructor(props){
    super(props)

    this.handleError = this.handleError.bind(this)

    this.updateDatos = this.updateDatos.bind(this)
    this.submit = this.submit.bind(this)
    this.disallow = this.disallow.bind(this)

    this.openSuscri = this.openSuscri.bind(this)
    

    this.state={
      modalAlert:false,
      txtAlert:'',
      modalDesuscripcion:false,
      modalCancelDesuscripcion:false,
      loadingStripe:false,
      txtStripe:'Cargando',
      modalDetalle:false,
      indexDetalle:-1,
      /**/
      tipoMembresia:0,//1->Suscripcion de Stripe || 2->Cortesia
      historialDePagos:[],
      membresia:{
        amount:'',
        date:'',
        error:'',
        interval:'',
        plan:'',
        status:'',
        subscription:'',
      },
      membresiaAlternativa:{

      },
      btnDisabled:false,
    }
  }

  componentDidMount(){
    const {getRef} = this.props;
    getRef(this);
  }

  componentWillUnmount(){
     const {getRef} = this.props;
     getRef(undefined);
  }

  handleError(error,bdd){
    alert(error)
    alert(bdd)
    this.props.close()
  }

  updateDatos(){
    let data = {idE:sessionStorage.getItem('id')}

    this.setState({loadingStripe:true,txtStripe:'Obteniendo datos de tus membresía'})
    axios.post(`${host}empresas/empresas/datosMembresiaEmpresa/`,data,request).then(rt=>{
      let{tipoMembresia} = this.state
      
      if(rt.data['status']==='ok'){
        tipoMembresia = Number(rt.data['tipo'])
        
        if(tipoMembresia===1){
          let {validData} = this.props
          let a = {customer:validData['idFK']}

          this.setState({txtStripe:'Obteniendo historial de pago'})
          axios.post(`${host}payment/GatewayStripe/listCustomerInvoicesWeb/dataJsonInvoicesWeb`,a,request).then(rt=>{
            this.setState({loadingStripe:true,txtStripe:'Consultando membresía actual',historialDePagos:rt.data,tipoMembresia})
            axios.post(`${host}payment/GatewayStripe/getCurrentMembershipWeb/dataJsonCurrentPlan`,a,request).then(rt2=>{
              this.setState({loadingStripe:false,membresia:rt2.data})
              if(rt2.data['action'] === 'true')this.setState({btnDisabled:true})
            }).catch(error => this.handleError(error,2))
          }).catch(error => this.handleError(error,1))

        }else{
          if(rt.data['msg'] !== 'none' ){
            let {membresiaAlternativa} = this.state
            membresiaAlternativa['label'] = Number(rt.data['msg']['tipo'])===1?'Cortesía':'Membresía SC'
            membresiaAlternativa['inicio'] = moment(rt.data['msg']['inicio'],'YYYY-MM-DD').format('DD/MMMM/YYYY')
            membresiaAlternativa['fin'] = moment(rt.data['msg']['vence'],'YYYY-MM-DD').format('DD/MMMM/YYYY')
            membresiaAlternativa['estatus'] = Number(rt.data['msg']['estatus'])===1?'active':'past_due'
            this.setState({txtStripe:'',loadingStripe:false,tipoMembresia,membresiaAlternativa})
          }
        }


      }

    })

  }

  submit(){
    let {membresia} = this.state
    let {datos} = this.props
    let data = {subscription:membresia['subscription'],phone:datos.TELEFONO_CONTACTO}
    this.setState({loadingStripe:true,txtStripe:'Cancelando suscripción',modalDesuscripcion:false})
    axios.post(`${host}payment/GatewayStripe/cancelSub/rejectSuscription`,data,request).then(rt=>{
      let msj = '', disabled = true
      if(typeof(rt.data)==='object'){
        msj = rt.data['mensaje']
        if(Number(rt.data['status'])<0)disabled=false
      }
      else{
        msj = 'Parece que hubó un error, por favor intentelo más tarde o contacte con soporte'
        disabled =false
      }
      this.setState({loadingStripe:false,txtStripe:'',modalAlert:true,txtAlert:msj,btnDisabled:disabled})
    })
  }

  disallow(){
    let {membresia} = this.state
    let data = {subscription:membresia['subscription']}
    this.setState({loadingStripe:true,txtStripe:'Reanudando suscripción',modalCancelDesuscripcion:false})
    axios.post(`${host}payment/GatewayStripe/reverseCancellation/rejectRejectionSuscription`,data,request).then(rt=>{
      let msj = '', disabled = false
      if(typeof(rt.data)==='object'){
        msj = rt.data['mensaje']
        if(Number(rt.data['status'])<0)disabled=true
      }
      else{
        msj = 'Parece que hubó un error, por favor intentelo más tarde o contacte con soporte'
        disabled=true
      }
      this.setState({loadingStripe:false,txtStripe:'',modalAlert:true,txtAlert:msj,btnDisabled:disabled})
    })
  }

  getPeriodo(interval){
    switch(interval){
      case "month": return "mes";
        case "week": return "semana";
        case "day": return "día";
        default: return "periodo";
    }
  }

  getStatus(sttus,b=0){
    let a = '';
    switch(sttus){
      case "incomplete": a = "Incompleta"; break;//txtStatus.setTextColor(getResources().getColor(R.color.naranjaBuBook));; break;
      case "incomplete_expired": a = "Expirada"; break;//txtStatus.setTextColor(getResources().getColor(R.color.colorRojo));; break;
      case "trialing": a = "Periodo de prueba"; break;//txtStatus.setTextColor(getResources().getColor(R.color.naranjaBuBookBajito));; break;
      case "active": a = "Activa"; break;//txtStatus.setTextColor(getResources().getColor(R.color.colorVerde));; break;
      case "past_due": a = "Vencida"; break;//txtStatus.setTextColor(getResources().getColor(R.color.naranjaBuBook));; break;
      case "canceled": a = "Cancelada"; break;//txtStatus.setTextColor(getResources().getColor(R.color.colorRojo));; break;
      case "unpaid": a = "Sin pagar"; break;//txtStatus.setTextColor(getResources().getColor(R.color.colorRojo)); break;
      default: a = 'Estatus';
    }
    return b>0?a.split(' ').join('') : a
  }

  openSuscri(){
    let{controlStripeModal,setFromMembresia} = this.props
    setFromMembresia(true)
    controlStripeModal(true)
  }
 

  render(){
    return(
      <Modal centered title="" maskClosable={false} keyboard = {false} 
          visible={this.props.modalMembresia}
          closable={false} zIndex={999} header={null} footer={null}
          className="nodalRegistroStripe"
          wrapClassName="nodalRegistroStripePapa">

        <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
          okText="Aceptar"
          cancelText="Cancelar"
          okButtonProps={{ className:'btn btn1' ,style:{width:'120px'} }}
          cancelButtonProps={{ className:'btn btn2', style: {width:'120px'} }}
          visible={this.state.modalDesuscripcion}
          closable={false}
          onOk={this.submit}
          onCancel={() => this.setState({modalDesuscripcion:false}) }
          className="miAntModal newVar alertMy"
          >
          <div className="myAlertBody">
            <span className="txtAlert"> ¿Está seguro que desea cancelar la suscripción? </span>
          </div>
        </Modal>

        <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
          okText="Aceptar"
          cancelText="Cancelar"
          okButtonProps={{ className:'btn btn1' ,style:{width:'120px'} }}
          cancelButtonProps={{ className:'btn btn2', style: {width:'120px'} }}
          visible={this.state.modalCancelDesuscripcion}
          closable={false}
          onOk={this.disallow}
          onCancel={() => this.setState({modalCancelDesuscripcion:false}) }
          className="miAntModal newVar alertMy"
          >
          <div className="myAlertBody">
            <span className="txtAlert"> ¿Está seguro que desea reanudar su suscripción? </span>
          </div>
        </Modal>

        <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
          okText="Aceptar"
          okButtonProps={{ className:'btn btn1' ,style:{width:'120px'} }}
          cancelButtonProps={{ className:'btn btn2', style: {display:'none'} }}
          visible={this.state.modalAlert}
          closable={false}
          onOk={() => this.setState({modalAlert:false}) }
          className="miAntModal newVar alertMy"
          >
          <div className="myAlertBody">
              {
                  this.state.modalAlert   ?
                  <span className="txtAlert"> {this.state.txtAlert} </span>:
                  <React.Fragment/>
              }
          </div>
        </Modal>

        
        <Modal centered maskClosable={false} keyboard = {false} 
          visible={this.state.loadingStripe} closable={false} width={300}
          footer={null} header={null} bodyStyle={{padding:'0'}} >
          <div className="loadLogin">
            <label> {this.state.txtStripe} </label>
            <Spin indicator={<Icon type="loading" style={{ fontSize: 50,color:'#FF9700'}} />} />
          </div>
        </Modal>

        <Modal centered title="" maskClosable={false} keyboard = {false} 
          visible={this.state.modalDetalle}
          closable={false} header={null} footer={null}
          className="nodalRegistroStripe"
          wrapClassName="nodalRegistroStripePapa">
          <div className="contentstripe">
            
            <div className="head pdd">
              <img src={`${img}bubook_blanco.png`} alt="Bubook" title="Bubook" className="logoBu" />
            </div>

            <div className="body">
              <h4 className="titleB"> Detalle de facturación </h4>

              {
                this.state.indexDetalle !== -1 &&
                <React.Fragment>
                <div className="listItemsInfo">
                  <div className="itemInfo"> <span> Fecha </span> <label>{moment(this.state.historialDePagos[this.state.indexDetalle]['fechaPago'],'DD/MM/YYYY').format('DD MMMM YYYY')}</label> </div>
                  <div className="itemInfo"> <span> Factura </span> <label>{this.state.historialDePagos[this.state.indexDetalle]['factura']}</label> </div>
                  <div className="itemInfo"> <span> Método de pago </span> <label>Tarjeta (<span className="dots"> &#183;&#183;&#183;&#183; &#183;&#183;&#183;&#183; &#183;&#183;&#183;&#183; </span> {this.state.historialDePagos[this.state.indexDetalle]['tarjeta']})</label> </div>
                  <div className="itemInfo"> <span> Vendedor </span> <label> BusinessBook Inc. </label> </div>
                  <div className="itemInfo"> <span> Dirección </span> <label> Cristóbal Crolón 270, Fracc. Costa Azul, Acapulco, México. </label> </div>
                  <div className="itemInfo"> <span> RFC </span> <label> DTE181115JJ7 </label> </div>
                  <div className="itemInfo"> <span> Producto </span> <label>{this.state.historialDePagos[this.state.indexDetalle]['producto'] +' ' +this.state.historialDePagos[this.state.indexDetalle]['plan']}</label> </div>
                  <div className="itemInfo"> <span> Total </span> <label>$ {formatNumber( Number(this.state.historialDePagos[this.state.indexDetalle]['importe'])/100 ,2 )} MXN</label> </div>
                </div>
                
                <div className="warning">
                  BuBook carga $ {formatNumber(Number(this.state.historialDePagos[this.state.indexDetalle]['importe'])/100,-2) } MXN por el servicio. Es importante señalar que los cargos liquidados con tarjetas de crédito pueden estar sujetos a costos e impuestos adicionales realizados por tu banco. El cargo de estos costos e impuestos no es realizado por BuBook.
                </div>

                <div className="warning">
                  Al suscribirte y cambiar de plan, autorizas a BuBook para realizar el cargo automaticamente cada mes, hasta que canceles tu suscripción. Si llega a haber algún cambio en los precios, te notificaremos por adelantado. En el panel de configuración, puedes revisar la fecha de renovación o cancelar la suscripción cuando quieras. No se hacen reembolsos parciales. Aplican los términos y condiciones de la {this.state.historialDePagos[this.state.indexDetalle]['plan']} de Bubook.
                </div>

                </React.Fragment>
              }

            </div>
            
            <div className="footStripe">
              <button className="btnFootStripeModal" onClick={()=>this.setState({modalDetalle:false,indexDetalle:-1,})} > Aceptar </button>
            </div>

          </div>
        </Modal>

        <div className="contentstripe">
          
          <div className="head pdd">
            <img src={`${img}bubook_blanco.png`} alt="Bubook" title="Bubook" className="logoBu" />
          </div>

          <div className="body">
            <h4 className="titleB"> Detalle de la membresía </h4>
            
            
            <h4 className="titleB titleLeft clrGery"> Tu membresía </h4>
            {
              this.state.tipoMembresia ===1 &&
              <React.Fragment>
                {
                  this.state.btnDisabled &&
                  <div className="tarjetaMembresiaRed">
                    Has cancelado tu suscripción. La cancelación será efectiva al final de tu periodo de facturación actual el { moment(this.state.membresia['date'],'DD/MM/YYYY').format('DD MMMM YYYY') }. Puedes volver a suscribirte en cualquier momento después de que finalice el ciclo de facturación.
                  </div>
                }

                <div className="tarjetaMembresia">

                  <div className="title"> <span>Tu plan</span>  <label className={this.getStatus(this.state.membresia['status'],1)}> {this.getStatus(this.state.membresia['status'])} </label> </div>
                  <div className="miplan miplanb"> {this.state.membresia['plan']} por ${ formatNumber(Number(this.state.membresia['amount'])/100 ,2) } / {this.getPeriodo(this.state.membresia['interval'])}  </div>

                  <div className="title"> <span>Tu próxima factura </span>   </div>
                  <div className="miplan"> {moment(this.state.membresia['date'],'DD/MM/YYYY').format('DD MMMM YYYY')} </div>

                </div>

                <div className="warning">
                  Las cuotas de membresía se facturan al principio de cada periodo y podrían aparecer en tu cuenta algunos días despues de la fecha de facturación.
                </div> 

                <div className="contButton">
                  {
                    this.state.btnDisabled ? 
                    <button className="disabled" onClick={()=> this.setState({modalCancelDesuscripcion:true})} > Reanudar suscripción </button>:
                    <button  onClick={()=> this.setState({modalDesuscripcion:true})} > Cancelar suscripción </button>
                  }
                </div>

                <h4 className="titleB titleLeft clrGery"> Historial de pagos </h4>

                {
                  this.state.historialDePagos.map((pago,indice) => (
                    <div key={indice+'_pago'} className="pagotarjeta" onClick={()=>this.setState({modalDetalle:true,indexDetalle:indice})} >
                      <div className="headPago">  <span>{pago['fechaPago']}</span>  <label>${formatNumber(Number(pago['importe'])/100,2)} MXN</label>  </div>
                      <div className="bodyPago">  {pago['producto'] +' '+pago['plan']} </div>
                      <div className="bodyPago">  {pago['fechaInicio']+' - '+pago['fechaFin']} </div>
                      <div className="bodyPago">  <span className="dots">&#183;&#183;&#183;&#183; &#183;&#183;&#183;&#183; &#183;&#183;&#183;&#183;</span> {pago['tarjeta']} </div>
                    </div>
                  ))
                }
              </React.Fragment>
            }

            {
              this.state.tipoMembresia === 2 &&
              <React.Fragment>

                <div className="tarjetaMembresia">

                  <div className="title">
                    <span>Tu plan</span>
                    <label className={this.getStatus(this.state.membresiaAlternativa['estatus'],1)}> {this.getStatus(this.state.membresiaAlternativa['estatus'])} </label>
                  </div>

                  <div className="miplan"> {this.state.membresiaAlternativa['label']} </div>


                  <div className="title flexcenter Fspaceb">
                    <span> Inicio </span>
                    <span> Vencimiento </span>
                  </div>

                  <div className="flexcenter Fspaceb">
                    <div className="miplan"> {this.state.membresiaAlternativa['inicio']} </div>
                    <div className="miplan"> {this.state.membresiaAlternativa['fin']} </div>
                  </div>

                </div>

                <div className="warning">
                  Actualmente estás suscrito a Bubook Mx a través de una Cortesía. Deberás suscribirte a una nueva membresía antes del Vencimiento de tu cortesía actual para no perder acceso a las funciones principales de la aplicación.
                </div>

                <div className="contButton">
                  <button onClick={this.openSuscri} > Suscríbete a un plan </button>
                </div>

              </React.Fragment>
            }

          </div>
          
          <div className="footStripe">
            <button className="btnFootStripeModal" onClick={this.props.close} > Aceptar </button>
          </div>

        </div>
      </Modal>
    )
  }
}
export default Stripe;