import React from 'react';

import axios from 'axios';
import { Globalrequest as request } from './../../../request.js';
import {Host as host } from './../../../app.json'

import {userContext} from './../context'

import { withRouter    } from "react-router-dom";

import Loading from '../../../modalCargando/loading';
import {Pie} from 'react-chartjs-2';

import {formatNumber} from './../../../utilities/generalfunctions'

import moment from 'moment'
import {Modal,Select,Col,Row,DatePicker} from 'antd';

const options = {
  responsive: true,
  hover: { mode: 'label' },
  scales: { yAxes: [{ display: true, ticks: { beginAtZero: true, } }] },
}


class Welcome extends React.Component{

  static contextType = userContext

  constructor(props){
    super(props);
    this.verifyEliminada = this.verifyEliminada.bind(this)
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.actualizarSucursales2 = this.actualizarSucursales2.bind(this)
    this.borrarFiltros = this.borrarFiltros.bind(this)

    this.classToggle = this.classToggle.bind(this)    

    this.state = {
      detalle1:false,
      detalle2:false,
      detalle3:false,
      /**/
      detalle1Content:[ {ESTATUS:1,CANTIDAD:0},{ESTATUS:2,CANTIDAD:0},{ESTATUS:3,CANTIDAD:0},{ESTATUS:4,CANTIDAD:0},{ESTATUS:5,CANTIDAD:0} ],
      detalle2Content:[ {ESTATUS:1, CANTIDAD:0, MONTO:0},{ESTATUS:2, CANTIDAD:0, MONTO:0},{ESTATUS:2, CANTIDAD:0, MONTO:0} ],
      detalle3Content:[{TIKECT_MIN:0,TIKECT_MAX:0}],
      /**/
      filtro1:1,
      filtro2:-1,
      /**/
      inicioPeriodo:'',
      finPeriodo:'',
      /**/
      fecha1:moment(),
      fecha2:moment(),
      /**/
      loading:false,
      modalAlert:false,
      txtAlert:'',
      /**/
      misDatos:{
        //balance:[],
        pastelPago:[ {NOMBRE:'Efectivo',CANTIDAD:0},{NOMBRE:'Tarjeta crédito/débito',CANTIDAD:0},{NOMBRE:'Transferencia',CANTIDAD:0} ],
        pastelRecoger:{ EN_TIENDA:0, EN_DOMICILIO:0, },
        balance:{ VENTAS_TOTAL:0, NO_VENTAS:0, TOCKET_PROMEDIO:0, }
      },
      expand:false,
      expand1:false,
      dataPiePago:{
        labels: [],
        datasets:[]
      },
      dataPieRecoger:{
        labels: [],
        datasets:[]
      },
      nombreComercial:'',
      sucursales:this.props.sucursales,
    }
  }

  componentWillMount(){
    let {nombreComercial} = this.state
    let datos = JSON.parse(sessionStorage.getItem('metadata'))
    if(datos!==undefined&&datos!==null){
      datos = datos['datos']
      if(datos!==undefined&&datos!==null){
        nombreComercial = datos['NOMBRE_COMERCIAL']
        this.setState({nombreComercial})
      }
    }
  }

  componentDidMount(){
    document.title= 'Home'
    let {sucursales} = this.state
    let datos = JSON.parse(sessionStorage.getItem('metadata'))
    if(datos!==undefined&&datos!==null){
      this.verifyEliminada()
      this.actualizarCatalogo()
      if(sucursales.length === 0){
        this.actualizarSucursales2()
      }
    }
  }

  verifyEliminada(){
    let isEliminada = sessionStorage.getItem('isEliminada')
    if(isEliminada!==null&&isEliminada!==undefined){
      isEliminada = Number(isEliminada)
      let isEliminadaID = sessionStorage.getItem('isEliminadaID')
      sessionStorage.removeItem('isEliminada')
      sessionStorage.removeItem('isEliminadaID')
      let data = {customer:isEliminadaID}
      let {editState} = this.props      
      if(isEliminada===5){
        editState('pending',true)
        axios.post(`${host}payment/GatewayStripe/resumeSub`,data,request).then(rt=>{
          if(Number(rt.data['result'])===1){
            editState('pending',false)
            editState('txtAlert',rt.data['message'])
            editState('modalAlert',true)
          }
          else{
            editState('modalCuentaEliminadaBad',true)
            editState('pending',false)
          }
        })
      }
    }
  }

  actualizarCatalogo(){
    let{validCurrentUser} = this.props
    if(this.context['type']===2){
      let obj = JSON.parse(JSON.stringify(this.context))
      if( Object.entries(this.context['data']).length ===0 ){
        let permisosPreSaved = sessionStorage.getItem('perfiles')||'none'
        if(permisosPreSaved!=='none')
          obj['data'] = JSON.parse(permisosPreSaved)
        else
          return
      }
      
      if(!validCurrentUser(obj,'estadisticas','bool'))
        return
    }

    let {filtro1,filtro2,fecha1,fecha2,inicioPeriodo,finPeriodo} = this.state
    inicioPeriodo = moment()
    finPeriodo = moment()
    let inicio,fin
    fin = finPeriodo.format('YYYY-MM-DD')
    if(filtro1===1){
      inicio = inicioPeriodo.format('YYYY-MM-DD')
    }
    else if(filtro1===2){
      inicio = inicioPeriodo.subtract(1,'days').format('YYYY-MM-DD')
      fin = finPeriodo.subtract(1,'days').format('YYYY-MM-DD')
    }
    else if(filtro1===3)inicio = inicioPeriodo.subtract(7,'days').format('YYYY-MM-DD')
    else if(filtro1===4)inicio = inicioPeriodo.subtract(30,'days').format('YYYY-MM-DD')
    else if(filtro1===5)inicio = ''
    else if(filtro1===6){
      if(fecha2.isBefore(fecha1)){
        this.setState({modalAlert:true,txtAlert:'La fecha fin debe ser mayor o igual que la fecha inicio'})
        return
      }
      inicio = fecha1.format('YYYY-MM-DD')
      fin = fecha2.format('YYYY-MM-DD')
    }
    let data = {idE:sessionStorage.getItem('id'),idS:filtro2,inicio:inicio,fin:fin}
    this.setState({loading:true})
    axios.post(`${host}estadisticas/estadisticas/estadisticasPorEmpresaHome`,data,request).then(rt=>{
      let {misDatos,dataPiePago,dataPieRecoger,detalle1Content,detalle2Content,detalle3Content} = this.state

      detalle1Content = rt.data['detalle1']
      detalle2Content = rt.data['detalle2']
      detalle3Content = rt.data['detalle3']
      
      misDatos['balance'] = rt.data['balance'][0]!==undefined ? rt.data['balance'][0] : {VENTAS_TOTAL:0,NO_VENTAS:0,TOCKET_PROMEDIO:0,}
      misDatos['pastelPago'] = rt.data['pastelPago']
      misDatos['pastelRecoger'] = rt.data['pastelRecoger'][0]

      let efectivoSucursal = misDatos['pastelPago'].filter(pp => Number(pp['ID'])===1 )
      let efectivoDomicilio = misDatos['pastelPago'].filter(pp => Number(pp['ID'])===8 )
      efectivoSucursal = efectivoSucursal.length>0?Number(efectivoSucursal[0]['CANTIDAD']) : 0
      efectivoDomicilio = efectivoDomicilio.length>0?Number(efectivoDomicilio[0]['CANTIDAD']) : 0

      let tarjetaSucursal = misDatos['pastelPago'].filter(pp => Number(pp['ID'])===2 )
      let tarjetaDomicilio = misDatos['pastelPago'].filter(pp => Number(pp['ID'])===9 )


      tarjetaSucursal = tarjetaSucursal.length>0?Number(tarjetaSucursal[0]['CANTIDAD']) : 0
      tarjetaDomicilio = tarjetaDomicilio.length>0?Number(tarjetaDomicilio[0]['CANTIDAD']) : 0

      misDatos['pastelPago'] = misDatos['pastelPago'].filter(pp => Number(pp['ID']) !== 1 && Number(pp['ID']) !== 8 && Number(pp['ID']) !== 9 && Number(pp['ID']) !== 2)
      
      misDatos['pastelPago'].unshift(
        {ID: "18", TIPO: "1", NOMBRE: "Efectivo", CANTIDAD: efectivoSucursal+efectivoDomicilio},
        {ID: "29", TIPO: "1", NOMBRE: "Tarjeta Crédito/Débito", CANTIDAD: tarjetaSucursal + tarjetaDomicilio} ,
      )

      let backgroundColor = ['#ffd8a6','#2fc4b2','#e71414','#f17808','#3797a4','#5fdde5'], hoverBackgroundColor=['#ffd8a6','#2fc4b2','#e71414','#f17808','#3797a4','#5fdde5'] , nums = []
      dataPiePago['labels'] = []
      dataPiePago['datasets'] = []
      for(let pago of misDatos['pastelPago']){
        dataPiePago['labels'].push(pago['NOMBRE'])
        nums.push(Number(pago['CANTIDAD']))
      }      
      //dataPiePago['datasets'].push({data: nums,backgroundColor,hoverBackgroundColor})
      dataPiePago['datasets'] = [{data: nums,backgroundColor,hoverBackgroundColor}]

      dataPieRecoger['labels'] = ['En sucursal','A domicilio']
      let nums2 = [ Number(rt.data['pastelRecoger'][0]['EN_TIENDA']) , Number(rt.data['pastelRecoger'][0]['EN_DOMICILIO']) ]
      //dataPieRecoger['datasets'].push({data: nums2,backgroundColor,hoverBackgroundColor})

      dataPieRecoger['datasets'] = [{data: nums2,backgroundColor,hoverBackgroundColor}]

      this.setState({misDatos,loading:false,dataPiePago,dataPieRecoger,detalle1Content,detalle2Content,detalle3Content})
    })
  }

  actualizarSucursales2(){
    let data = JSON.parse(sessionStorage.getItem('metadata'))
    if(data!==null&&data!==undefined){
      this.props.setSucursales(data['sucursales'])
      this.setState({ sucursales : data['sucursales'] })
      this.props.editState('datos',data['datos'])
      this.props.editState('sucursales',data['sucursales'])
      this.props.editState('categorias',data['categorias'])
      this.props.editState('tags',data['tags'])
      this.props.editState('notificaciones',data['notificaciones'])
    }
  }

  classToggle(){
    let {validCurrentUser} = this.props
    if(!validCurrentUser(this.context,'estadisticas','bool')){
      this.setState({txtAlert:'El perfil actual no cuenta con los permisos para ver estadísticas.',modalAlert:true})
      return
    }
    document.getElementById('toggleFiltrarId').classList.toggle('toggleFiltrar2');
    document.getElementById('contControlesId').classList.toggle('contControles2');
  }

  borrarFiltros(){
    let {filtro1,fecha1,fecha2} = this.state
    filtro1 = 1;
    fecha1 = moment();
    fecha2 = moment();
    this.setState({filtro1,fecha1,fecha2})
    setTimeout(()=> this.actualizarCatalogo(), 10)
  }

  switchEstatusDetalle(estatus,op){
    estatus = Number(estatus)
    if(op===1){
      if(estatus===1)return 'Pendientes';
      if(estatus===2)return 'En proceso';
      if(estatus===3)return 'En ruta';
      if(estatus===4)return 'Entregados';
      if(estatus===5)return 'Cancelados';
    }
    if(op===2){
      if(estatus===1)return 'Pendientes de pago';
      if(estatus===2)return 'Pagados';
      if(estatus===3)return 'Reembolsados';
    }
  }

  render(){
     return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3" >
            
            <Loading loading={this.state.loading} />


            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' }}
                cancelButtonProps={{ className:'btn btn2', style: {display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalAlert   ?
                        <span className="txtAlert"> {this.state.txtAlert} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            <Modal centered title={<span style={{textAlign:'left',display:'block'}}> Detalle de pedido </span>} maskClosable={false} keyboard = {false} 
                okText="Guardar" okButtonProps={{ style:{ display:'none'} }}
                cancelText="Cancelar" cancelButtonProps={{ style:{ display:'none'} }}
                visible={this.state.detalle1}
                className="miAntModal alertMy modalUnidad nopdd "
                onCancel={()=>this.setState({detalle1:false})}
                footer={null}
              >
              <div className="miUniDiv listDetalle ">
              {
                this.state.detalle1Content.map((content,i) => (
                  <div className="flexcenter Fstart itemDetalle" key={'detalle1_'+i} >
                    <label> { this.switchEstatusDetalle(content['ESTATUS'],1) } </label>
                    <span> {content['CANTIDAD']} </span>
                  </div>
                ))
              }
              </div>
            </Modal>

            <Modal centered title={<span style={{textAlign:'left',display:'block'}}> Detalle de ventas </span>} maskClosable={false} keyboard = {false} 
                okText="Guardar" okButtonProps={{ style:{ display:'none'} }}
                cancelText="Cancelar" cancelButtonProps={{ style:{ display:'none'} }}
                visible={this.state.detalle2}
                className="miAntModal alertMy modalUnidad nopdd "
                onCancel={()=>this.setState({detalle2:false})}
                footer={null}
              >
              <div className="miUniDiv listDetalle ">
                {
                  this.state.detalle2Content.map((content,i) => (
                    <div className="flexcenter Fstart itemDetalle" key={'detalle2_'+i} >
                      <label> { this.switchEstatusDetalle(content['ESTATUS'],2) } </label>
                      <span className="middle"> {content['CANTIDAD']} </span>
                      <span className="middle"> ${formatNumber(content['MONTO'],2)} MXN </span>
                    </div>
                  ))
                }
              </div>
            </Modal>

            <Modal centered title={<span style={{textAlign:'left',display:'block'}}> Detalle de tickets </span>} maskClosable={false} keyboard = {false} 
                okText="Guardar" okButtonProps={{ style:{ display:'none'} }}
                cancelText="Cancelar" cancelButtonProps={{ style:{ display:'none'} }}
                visible={this.state.detalle3}
                className="miAntModal alertMy modalUnidad nopdd "
                onCancel={()=>this.setState({detalle3:false})}
                footer={null}
              >
              <div className="miUniDiv listDetalle ">
                
                <div className="flexcenter Fstart itemDetalle">
                  <label> Ticket más alto </label>
                  <span> ${formatNumber(this.state.detalle3Content[0]['TIKECT_MAX'],2)} MXN</span>
                </div>

                <div className="flexcenter Fstart itemDetalle">
                  <label> Ticket promedio </label>
                  <span> ${formatNumber(this.state.misDatos['balance']['TOCKET_PROMEDIO'],2)} MXN</span>
                </div>

                <div className="flexcenter Fstart itemDetalle">
                  <label> Ticket más bajo </label>
                  <span> ${formatNumber(this.state.detalle3Content[0]['TIKECT_MIN'],2)} MXN</span>
                </div>
              
              </div>

            </Modal>

             <div className="tituloYControles">
              <div className="metaControles">
                <h3 className="tituloPanel"> Hola, {this.state.nombreComercial} </h3>
                <span className="toggleFiltrar" id="toggleFiltrarId" onClick={this.classToggle} > Filtrar </span>
              </div>

              <div className="contControles" id="contControlesId">
                <div className="contControlesHijo" id="contControlesHijoId">

                  <div className="filtradosExtras">

                    <div className="inputs-group-inline" style={{width:'50%'}} >
                      <label className="inputs-label" htmlFor="pesopd" >  Sucursal </label>
                      <Select className="inputs-input" value={this.state.filtro2} onChange={(filtro2)=>this.setState({filtro2})} >
                      <Select.Option value={-1} > Todas </Select.Option>
                      {
                        this.state.sucursales.map((sucursal,i)=>(
                          <Select.Option value={sucursal['ID']} key={'sucursal_'+i}> {sucursal['NOMBRE']} </Select.Option>
                        ))
                      }
                      </Select>
                    </div>
                    
                    <div className="inputs-group-inline" style={{width:'50%'}} >
                      <label className="inputs-label" htmlFor="pesopd">  Filtrar por fecha </label>
                      <Select className="inputs-input" value={this.state.filtro1} onChange={(filtro1)=>this.setState({filtro1})} >
                        <Select.Option value={1}> Hoy </Select.Option>
                        <Select.Option value={2}> Ayer </Select.Option>
                        <Select.Option value={3}> Últimos 7 días </Select.Option>
                        <Select.Option value={4}> Últimos 30 días </Select.Option>
                        <Select.Option value={5}> Desde el principio </Select.Option>
                        <Select.Option value={6}> Personalizado </Select.Option>
                      </Select>
                    </div>
                    
                  </div>

                  <div className="filtradosExtras">
                    {
                      this.state.filtro1===6&&
                      <div className="fechaSexoEstadisticas" style={{width:'100%'}} >
                        <div className="inputs-group inputs-group-inline" style={{width:'50%',padding:'10px',marginRight:'0'}} >
                          <label className="inputs-label" htmlFor="pesopd"> Fecha inicio </label>
                          <DatePicker format="DD/MM/YYYY" locale='es-MX' className="MyDate" allowClear={false} placeholder="" value={this.state.fecha1} onChange={(fecha1)=>this.setState({fecha1})} />
                        </div>

                        <div className="inputs-group inputs-group-inline" style={{width:'50%',padding:'10px'}} >
                          <label className="inputs-label" htmlFor="timeprepapd" > Fecha fin </label>
                          <DatePicker format="DD/MM/YYYY" locale='es-MX' className="MyDate" allowClear={false} placeholder="" value={this.state.fecha2} onChange={(fecha2)=>this.setState({fecha2})} />
                        </div>
                      </div>
                    }
                  </div>

                  <div className="cleanDiv flexcenter Fend" style={{paddingRight:'10px'}} >
                    <button className="cleanBut" onClick={this.borrarFiltros} > Borrar filtros </button>
                    <button className="FillBut" onClick={this.actualizarCatalogo} > Filtrar </button>
                  </div>
                </div>
              </div>

            </div>

            <div className="cont-instancias2">

              <div className="subGrafica">
                <label className="titleGrafica titleGraficaleft"> Resumen de ventas </label>

                <div className="tablainline">
                    <Row className="tablaHead">
                      <Col span={8} onClick={()=>this.setState({detalle2:true})} > Mis ventas </Col>
                      <Col span={8} onClick={()=>this.setState({detalle1:true})} > Total de pedidos </Col>
                      <Col span={8} onClick={()=>this.setState({detalle3:true})} > Ticket promedio </Col>
                    </Row>

                    <Row className="tablaBody">

                      <Col className="notOverflow crsPot" title="Ver detalle" span={8} onClick={()=>this.setState({detalle2:true})} >
                        ${ formatNumber(this.state.misDatos['balance']['VENTAS_TOTAL'],2) }MXN
                      </Col>

                      <Col className="notOverflow crsPot" title="Ver detalle" span={8} onClick={()=>this.setState({detalle1:true})} >
                        {this.state.misDatos['balance']['NO_VENTAS']}
                      </Col>

                      <Col className="notOverflow crsPot" title="Ver detalle" span={8} onClick={()=>this.setState({detalle3:true})} >
                        ${formatNumber(this.state.misDatos['balance']['TOCKET_PROMEDIO'],2)}MXN
                      </Col>

                    </Row>

                  </div>
              </div>

              <div className="subGrafica">
                <Row className="inlineinline" >
                  <Col className="colinle">
                    <label className="titleGrafica titleGraficaleft"> Métodos de pago </label>
                    <div>
                      <div className="tablainline">
                        <Pie data={this.state.dataPiePago} options={options} />
                      </div>
                    </div>
                  </Col>
                  <Col className="colinle">
                    <br/><br/><br/>
                    <div>
                      <div className="tablainline">

                      {
                        this.state.misDatos['pastelPago'].map((pago,indice) =>
                         (
                          <Row className="metadata" key={"pagoPie_"+indice}> 
                            <Col span={12} className="meta"> {pago['NOMBRE']} </Col>
                            <Col span={12} className="data"> {pago['CANTIDAD']} </Col>
                          </Row>
                        ))
                      }
                      </div>                    
                    </div>
                  </Col>
                </Row>
              </div>

              <div className="subGrafica">
                <Row className="inlineinline" >
                  <Col className="colinle">
                    <label className="titleGrafica titleGraficaleft"> Tipos de servicio </label>
                    <div>
                      <div className="tablainline">
                        <Pie data={this.state.dataPieRecoger} options={options} />
                      </div>
                    </div>
                  </Col>
                  <Col className="colinle">
                    <br/><br/><br/>
                    <div className="tablainlinePapa" >
                      <div className="tablainline">
                        <Row className="metadata" >
                          <Col span={12} className="meta"> En sucursal </Col>
                          <Col span={12} className="data"> {this.state.misDatos['pastelRecoger']['EN_TIENDA']} </Col>
                        </Row>
                         <Row className="metadata" >
                          <Col span={12} className="meta"> A domicilio </Col>
                          <Col span={12} className="data"> {this.state.misDatos['pastelRecoger']['EN_DOMICILIO']} </Col>
                        </Row>
                      </div>                    
                    </div>
                  </Col>
                </Row>
              </div>

            </div>

            
            <div className="cont-instancias5"></div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Welcome);