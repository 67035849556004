import React from 'react';
import axios from 'axios';
import {   withRouter    } from "react-router-dom";

import moment from 'moment'
import {userContext} from './../context'

import { Globalrequest as request } from './../../../request.js';
import {Host as host ,  Img as img,gmapsKey} from './../../../app.json'
import {Modal, Switch, TimePicker,Checkbox} from 'antd'

import GoogleMapReact from 'google-map-react';
import NumberFormat from 'react-number-format';

import Loading from '../../../modalCargando/loading';

import {formatNumber} from './../../../utilities/generalfunctions'
import {Marka} from '../../../modalCargando/miDiv';

import './sucursal.scss';

class Coberturas extends React.Component{

  static contextType = userContext

  constructor(props) {
    super(props);
    this.apiLoadEnd2 = this.apiLoadEnd2.bind(this)
    this.showNuevaCobertura = this.showNuevaCobertura.bind(this)
    this.mapDragEnd2 = this.mapDragEnd2.bind(this)
    this.rangoChange = this.rangoChange.bind(this)
    this.changeSwitch2 = this.changeSwitch2.bind(this)
    this.ponHorarios = this.ponHorarios.bind(this)
    this.changeCosto = this.changeCosto.bind(this)
    this.horaChange = this.horaChange.bind(this)
    this.submit = this.submit.bind(this)
    this.showModalConfirm = this.showModalConfirm.bind(this)
    this.eliminar = this.eliminar.bind(this)
    this.gratisChange = this.gratisChange.bind(this)

    this.closeModal = this.closeModal.bind(this)

    this.state={
      APIMaps:{},
      map:{},
      /**/
      idS:sessionStorage.getItem('idSucu'),
      /**/
      detalles:{},
      coberturas:[],
      horarios:[],
      /**/
      loading:false,
      loading2:false,
      modalAlert:false,
      txtAlert:'',
      modalConfirm:false,
      idConfirm:-1,
      txtConfirm:'',
      /**/
      modalMaps:false,
      modalHorario:false,
      currentCobertura:{
        gratis:false,
        center: { lat: 16.859166 , lng: -99.872328, },
        cobertura:0.0,
        horarios:[
          {desde:moment('00:00:00', 'HH:mm:ss'),hasta:moment('00:00:00', 'HH:mm:ss'),estatus:1},
          {desde:moment('00:00:00', 'HH:mm:ss'),hasta:moment('00:00:00', 'HH:mm:ss'),estatus:1},
          {desde:moment('00:00:00', 'HH:mm:ss'),hasta:moment('00:00:00', 'HH:mm:ss'),estatus:1},
          {desde:moment('00:00:00', 'HH:mm:ss'),hasta:moment('00:00:00', 'HH:mm:ss'),estatus:1},
          {desde:moment('00:00:00', 'HH:mm:ss'),hasta:moment('00:00:00', 'HH:mm:ss'),estatus:1},
          {desde:moment('00:00:00', 'HH:mm:ss'),hasta:moment('00:00:00', 'HH:mm:ss'),estatus:1},
          {desde:moment('00:00:00', 'HH:mm:ss'),hasta:moment('00:00:00', 'HH:mm:ss'),estatus:1},
        ]
      }
    }

  }
  componentDidMount(){
    document.title = "Coberturas de envío"
    let idS = sessionStorage.getItem('idSucu')
    this.setState({loading:true})
    if(idS !== null && idS !== undefined){
      sessionStorage.removeItem('idSucu')
      this.actualizarCatalogo()
    }
    else{
      this.props.redirectTo2('/home/sucursales')
    }
  }

  actualizarCatalogo(){
    let {idS} = this.state
    let data = {idS:idS}    
    this.setState({loading2:true,idS:idS})
    axios.post(`${host}empresas/empresas/detallesSucursal`,data,request).then(rt=>{
      this.setState({detalles:rt.data,coberturas:rt.data['COBERTURAS'],horarios:rt.data['HORARIOS'],loading2:false})
    })
  }

  apiLoadEnd2(map, maps){

    let APIMaps = maps
    this.setState({APIMaps:APIMaps,loading:false,map:map})

    map.setOptions({
      fullscreenControl: false,
      mapTypeControl: true,
      zoomControl: true,
      zoomControlOptions: {
        style: maps.ZoomControlStyle.SMALL
      },
      mapTypeControlOptions: {
        style: maps.MapTypeControlStyle.DROPDOWN_MENU,
      },
    })

    /*let controlUI = document.createElement('div');
    controlUI.className="findme"
    controlUI.title="Ir a mi ubicación actual"
    let centerControlDiv = document.createElement('div');
    centerControlDiv.appendChild(controlUI);
    controlUI.addEventListener('click', this.findMe ) ;
    centerControlDiv.index = 1;
    map.controls[maps.ControlPosition.RIGHT_CENTER].push(centerControlDiv);

    let controlUI2 = document.createElement('div');
    controlUI2.className="findme2"
    controlUI2.id="findme2"
    controlUI2.title="Marcar centro de cobertura"
    controlUI2.innerHTML = '<span> Marcar centro de cobertura </span>';
    let centerControlDiv2 = document.createElement('div');
    centerControlDiv2.appendChild(controlUI2);
    controlUI2.addEventListener('click', this.findMe2 ) ;
    centerControlDiv2.index = 1;
    map.controls[maps.ControlPosition.BOTTOM_CENTER ].push(centerControlDiv2);*/

  }
  showNuevaCobertura(cobertura){
    let {detalles,horarios,APIMaps,map} = this.state
    let {validCurrentUser} = this.props
    let currentCobertura = {}

    if(!validCurrentUser(this.context,'sucursales','bool',this.state.detalles['CODE'],'EDITAR')){
      this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar esta sucursal'})
      return
    }

    if(cobertura===-1){
      currentCobertura['gratis'] = false
      currentCobertura['center'] = { lat: Number(detalles['ALTITUD']) , lng: Number(detalles['LONGITUD']) }
      currentCobertura['id'] = -1
      currentCobertura['cobertura'] = 0.0
      currentCobertura['horarios'] = horarios.map( h => {
        let ob = {
          id:-1,
          dia:Number(h.DIA),
          estatus:Number(h.ESTATUS),
          desde:moment (h['HORA_ABRE'] ,'HH:mm'),
          hasta:moment (h['HORA_CIERRA'] ,'HH:mm'),
        }
        return ob
      })
      currentCobertura['horarios'].sort( (a,b) => (a.dia - b.dia)  )
      currentCobertura['costo'] = 0
    }else{
      currentCobertura['id'] = cobertura['ID']
      currentCobertura['center'] = {lat: Number(cobertura['LATITUD']) ,lng: Number(cobertura['LONGITUD']) }
      currentCobertura['cobertura'] = Number(cobertura['COBERTURA_ENVIO'])
      currentCobertura['horarios'] = cobertura['HORARIOS'].map( h => {
        let ob = {
          id:h['ID'],
          dia:Number(h.DIA),
          estatus:Number(h.ESTATUS),
          desde:moment (h['HORA_INICIO'] ,'HH:mm'),
          hasta:moment (h['HORA_TERMINO'] ,'HH:mm'),
        }
        return ob
      })
      currentCobertura['gratis'] = Number(cobertura['COSTO_ENVIO']) === 0
      currentCobertura['costo'] = Number(cobertura['COSTO_ENVIO'])
      if(!currentCobertura['gratis']){
        setTimeout(() => {
          document.querySelector('#inputCosto').value = `$${currentCobertura['costo'].toFixed(2)}`
        },500)
      }
    }

    currentCobertura['circle'] = new APIMaps.Circle({
      strokeColor: '#FF0000',
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: '#FF0000',
      fillOpacity: 0.3,
      center: currentCobertura['center'],
      radius: 1000,
    })

    currentCobertura['circle'].setMap(map)
    currentCobertura['circle'].setRadius( 1000 * (currentCobertura['cobertura']) )
    currentCobertura['idSucursal'] = detalles['ID']

    this.setState({currentCobertura:currentCobertura,modalMaps:true})
  }

  mapDragEnd2(map){
    let c = map.getCenter()
    let lat = c.lat()
    let lng = c.lng()
    let {currentCobertura} = this.state
    currentCobertura['center'] = { lat: lat , lng: lng , }
    currentCobertura['circle'].setCenter(currentCobertura['center'])
    this.setState({ currentCobertura:currentCobertura });
  }

  rangoChange(e){
    let {currentCobertura} = this.state
    currentCobertura['cobertura']= Number(e.target.value)
    currentCobertura['circle'].setRadius( 1000 * (currentCobertura['cobertura']) )
    this.setState({currentCobertura:currentCobertura})
  }

  jumpClick(e){
    if(e.target.className==='inputs-label')e.preventDefault()
  }

  changeSwitch2(pos,checked){
    let {currentCobertura} = this.state
    currentCobertura['horarios'][pos]['estatus'] = checked ? 1: 2;
    this.setState({currentCobertura:currentCobertura})
  }

  ponHorarios(){
    let {currentCobertura} = this.state
    console.log(currentCobertura)
    
    if(Number(currentCobertura['cobertura']) === 0){
      this.setState({modalAlert:true,txtAlert:'La cobertura debe ser mayor a 0'})
      return
    }
    if(Number(currentCobertura['costo']) < 0){
      this.setState({modalAlert:true,txtAlert:'El costo debe ser mayor o igual a 0'})
      return
    }
    if(Number(currentCobertura['costo']) === 0 && !currentCobertura['gratis']) {
      this.setState({modalAlert:true,txtAlert:'Debe establecer un costo o marcarlo como gratis.'})
      return
    }

    this.setState({modalHorario:true,modalMaps:false})
  }

  changeCosto(e){
    let {currentCobertura} = this.state
    currentCobertura['costo'] = Number(e.target.value.split('$').join(''))
    this.setState({currentCobertura:currentCobertura})
  }

  horaChange(value,pos,campo){
    let {currentCobertura} = this.state
    currentCobertura['horarios'][pos][campo] = value
    this.setState({currentCobertura:currentCobertura})
  }

  submit(){
    let {currentCobertura} = this.state
    let activos = currentCobertura['horarios'].filter(h=>h.estatus === 1)
    if(activos.length === 0){
      this.setState({modalAlert:true,txtAlert:'Debe haber al menor un día de atención'})
      return
    }
    currentCobertura['circle'].setMap(null)
    currentCobertura['circle'] = undefined
    
    currentCobertura['horarios2'] = currentCobertura['horarios'].map(h => {
      let h2 = {}
      h2 =  Object.assign(h2, h);
      h2['desde'] = h2['desde'].format('HH:mm')
      h2['hasta'] = h2['hasta'].format('HH:mm')
      return h2;
    })
    currentCobertura['costo'] = currentCobertura['gratis']?0:currentCobertura['costo']
    
    this.setState({loading2:true})
    axios.post(`${host}empresas/empresas/altaCobertura`,currentCobertura,request).then(rt=>{
      let rta = Number(rt.data)
      if(rta>0){
        this.setState({modalAlert:true,txtAlert:'Cobertura agregada correctamente',loading2:false,modalHorario:false})
        this.actualizarCatalogo()
      }

      else if(rta===-2){
        this.setState({modalAlert:true,txtAlert:'Cobertura editada correctamente',loading2:false,modalHorario:false})
        this.actualizarCatalogo()
      }
      else{
        this.setState({modalAlert:true,txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #16-01]',loading2:false,modalHorario:false})
      }
    })
  }

  showModalConfirm(id,msj){// 1 -> eliminar || 2 -> desactivar || 3 -> eliminar 
    let {validCurrentUser} = this.props
    if(!validCurrentUser(this.context,'sucursales','bool',id['CODE'],'EDITAR') ){
      this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar esta sucursal'})
      return
    }
    this.setState({modalConfirm:true,idConfirm:id['ID'],txtConfirm:msj})
  }
  
  eliminar(){
    let {idConfirm} = this.state
    let data = {idC:idConfirm}
    axios.post(`${host}empresas/empresas/eliminarCobertura`,data,request).then(rt=>{
      let rta = Number(rt.data)
      if(rta===1){
        this.setState({modalAlert:true,txtAlert:'Cobertura eliminada correctamente',loading2:false,modalConfirm:false,txtConfirm:'',idConfirm:-1})
        this.actualizarCatalogo()
      }
      else{
        this.setState({modalAlert:true,txtAlert:'Parece que ocurrió un error, por favor intentelo más tarde. [Código de error: SV-ER: #16-02]',loading2:false,modalConfirm:false,txtConfirm:'',idConfirm:-1})
      }
    })
  }

  getCosto(costo){
    costo = Number(costo)
    return costo > 0 ? (<span className="nogratis">${formatNumber(costo,2)} MXN </span>) : (<span className="gratis"> Gratis </span>)
  }

  gratisChange(e){
    let {currentCobertura}=this.state
    currentCobertura['gratis'] = e.target.checked
    this.setState({currentCobertura})
  }

  closeModal(){
    let {currentCobertura} = this.state
    currentCobertura['cobertura'] = 0
    currentCobertura['circle'].setRadius(0)
    this.setState({modalMaps:false,currentCobertura})
  }
  
  render(){
    return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3">

            <Modal centered title="Confirmar" maskClosable={false} keyboard = {false} 
                okText="Aceptar" 
                okButtonProps={{ className:'btn btn1' }}
                cancelButtonProps={{ className:'btn btn2', style: {display:'none'} }}
                cancelText="Cancelar" 
                visible={this.state.modalConfirm}
                closable={false}
                onOk={this.eliminar}
                onCancel={()=>this.setState({modalConfirm:false,txtConfirm:'',idConfirm:-1})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                  <span className="txtAlert"> {this.state.txtConfirm} </span>
                </div>
            </Modal>

            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar"
                okButtonProps={{ className:'btn btn1' }}
                cancelButtonProps={{ className:'btn btn2', style: {display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalAlert   ?
                        <span className="txtAlert"> {this.state.txtAlert} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            <Modal centered title={this.state.currentCobertura['id']===-1?"Agregar zona de cobertura":"Editar zona de cobertura"} maskClosable={false} keyboard = {false} 
                okText="Guardar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
                cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
                visible={this.state.modalMaps}
                closable={false}
                className="modalGMaps miAntModal"
                onCancel={this.closeModal}
                onOk={this.ponHorarios}
                forceRender={true}
             >
             <div className="contentModalMapa" >
               <div className="divmap">
                  <GoogleMapReact
                    bootstrapURLKeys={{key: gmapsKey}}
                    ref={ ref => {this.mimapa2 = ref} }
                    draggable={true}
                    center={this.state.currentCobertura['center']}
                    defaultZoom={15}
                    onGoogleApiLoaded={ ({map, maps}) => this.apiLoadEnd2(map,maps) }
                    onDragEnd = {this.mapDragEnd2}
                    yesIWantToUseGoogleMapApiInternals={true}
                    > 
                  </GoogleMapReact>
                  <Marka/>
               </div>

               <div className="inputs-group">
                 <label className="inputs-label"> Indica tu zona de cobertura </label>
                 <input type="range" value={this.state.currentCobertura['cobertura']} className="inputs-input myrange" name="cobertura" id="coberturad" onChange={this.rangoChange} min="0" max="10" step="0.1" />
                 <label className="inputs-label labelValueRange" > {this.state.currentCobertura['cobertura']} Km </label>
               </div>

               <div className="myCheckProd flexcenter Fstart">
                 {/*
                  <input
                    type="checkbox"
                    checked={this.state.currentCobertura['gratis']}
                    onChange={this.gratisChange}
                  />
                  <label > Costo de envío gratis  </label>
                 */}
                 <Checkbox
                    className="checkInFilter"
                    checked={this.state.currentCobertura['gratis']}
                    onChange={this.gratisChange}
                  > 
                    Costo de envío gratis
                  </Checkbox>
               </div>

               {
                  !this.state.currentCobertura['gratis']&&
                  <div className="inputs-group">
                    <label className="inputs-label"> Costo de envío </label>
                    <NumberFormat
                      placeholder="$0.00"
                      //value={this.state.currentCobertura['costo']}
                      className="inputs-input" type="text"
                      id="inputCosto"
                      prefix={'$'} decimalSeparator='.' thousandSeparator=',' allowNegative={false} decimalScale={2} fixedDecimalScale
                      onChange={this.changeCosto} />
                  </div>
               }

             </div>
            </Modal>

            <Modal centered title="Horarios de envío" maskClosable={false} keyboard = {false} 
                okText="Guardar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
                cancelText="Regresar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
                visible={this.state.modalHorario}
                closable={false}
                className="miAntModal miconfigModal miconfigModalCitas miconfigModalCitasMg"
                onCancel={()=>this.setState({modalHorario:false,modalMaps:true})}
                onOk={this.submit}
             >
             <div className="contentModalMapa">
             
                <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                          
                        <label className="inputs-label" >
                          {this.state.currentCobertura['semaforo'] === 1 ? 'Lunes a Viernes' : 'Lunes' }
                            <div className="contSwitch">
                              <div className="panelAux" id="mibandera" onClick={this.controlSemanal} ></div>
                              <Switch id="swSlave1"  checked={this.state.currentCobertura['horarios'][0]['estatus']===1}  className="myswitch" onChange={ (checked) => this.changeSwitch2(0,checked)} />
                            </div>
                        </label>
                          <div className="mytimers" style={{display:this.state.currentCobertura['horarios'][0]['estatus']===1?'flex':'none'}} >
                            <TimePicker allowClear={false} placeholder="Desde" value={this.state.currentCobertura['horarios'][0]['desde']} onChange={ (value,svalue) => this.horaChange(value,0,'desde') } name="desde1" format={'HH:mm'} />
                            <TimePicker allowClear={false} placeholder="Hasta" value={this.state.currentCobertura['horarios'][0]['hasta']} onChange={ (value,svalue) => this.horaChange(value,0,'hasta') } name="hasta1" format={'HH:mm'} />
                          </div>
                </div>

                <div style={{display: this.state.currentCobertura['semaforo'] === 1 ? 'none' : 'block'  }} className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                    
                    <label className="inputs-label">
                      Martes
                      <Switch id="swSlave2" className="myswitch" checked={this.state.currentCobertura['horarios'][1]['estatus']===1} onChange={ (checked,event) => this.changeSwitch2(1,checked)}/>
                    </label>
                    
                    <div className="mytimers" style={{display:this.state.currentCobertura['horarios'][1]['estatus']===1?'flex':'none'}} >
                      <TimePicker allowClear={false} placeholder="Desde" value={this.state.currentCobertura['horarios'][1]['desde']} onChange={ (value,svalue) => this.horaChange(value,1,'desde') } name="desde2" format={'HH:mm'} />
                      <TimePicker allowClear={false} placeholder="Hasta" value={this.state.currentCobertura['horarios'][1]['hasta']} onChange={ (value,svalue) => this.horaChange(value,1,'hasta') } name="hasta2" format={'HH:mm'} />
                    </div>
                </div>

                 <div style={{display: this.state.currentCobertura['semaforo'] === 1 ? 'none' : 'block'  }} className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                    
                    <label className="inputs-label">
                      Miércoles
                      <Switch id="swSlave3"  className="myswitch" checked={this.state.currentCobertura['horarios'][2]['estatus']===1} onChange={ (checked,event) => this.changeSwitch2(2,checked)}/>
                    </label>
                    
                    <div className="mytimers" style={{display:this.state.currentCobertura['horarios'][2]['estatus']===1?'flex':'none'}} >
                      <TimePicker allowClear={false} placeholder="Desde" value={this.state.currentCobertura['horarios'][2]['desde']} onChange={ (value,svalue) => this.horaChange(value,2,'desde') } name="desde3" format={'HH:mm'} />
                      <TimePicker allowClear={false} placeholder="Hasta" value={this.state.currentCobertura['horarios'][2]['hasta']} onChange={ (value,svalue) => this.horaChange(value,2,'hasta') } name="hasta3" format={'HH:mm'} />
                    </div>
                 </div>

                  <div style={{display: this.state.currentCobertura['semaforo'] === 1 ? 'none' : 'block'  }} className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                      
                      <label className="inputs-label">
                        Jueves
                        <Switch id="swSlave4" className="myswitch" checked={this.state.currentCobertura['horarios'][3]['estatus']===1} onChange={ (checked,event) => this.changeSwitch2(3,checked)}/>
                      </label>
                      
                      <div className="mytimers" style={{display:this.state.currentCobertura['horarios'][3]['estatus']===1?'flex':'none'}} >
                        <TimePicker allowClear={false} placeholder="Desde" value={this.state.currentCobertura['horarios'][3]['desde']} onChange={ (value,svalue) => this.horaChange(value,3,'desde') } name="desde4" format={'HH:mm'} />
                        <TimePicker allowClear={false} placeholder="Hasta" value={this.state.currentCobertura['horarios'][3]['hasta']} onChange={ (value,svalue) => this.horaChange(value,3,'hasta') } name="hasta4" format={'HH:mm'} />
                      </div>
                  </div>

                  <div style={{display: this.state.currentCobertura['semaforo'] === 1 ? 'none' : 'block'  }} className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                      
                      <label className="inputs-label">
                        Viernes
                        <Switch id="swSlave5" className="myswitch" checked={this.state.currentCobertura['horarios'][4]['estatus']===1} onChange={ (checked,event) => this.changeSwitch2(4,checked)}/>
                      </label>
                      
                      <div className="mytimers" style={{display:this.state.currentCobertura['horarios'][4]['estatus']===1?'flex':'none'}} >
                        <TimePicker allowClear={false} placeholder="Desde" value={this.state.currentCobertura['horarios'][4]['desde']} onChange={ (value,svalue) => this.horaChange(value,4,'desde') } name="desde5" format={'HH:mm'} />
                        <TimePicker allowClear={false} placeholder="Hasta" value={this.state.currentCobertura['horarios'][4]['hasta']} onChange={ (value,svalue) => this.horaChange(value,4,'hasta') } name="hasta5" format={'HH:mm'} />
                      </div>
                  </div>

                  <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                      
                      <label className="inputs-label">
                        Sábado
                        <Switch id="swSlave6" className="myswitch" checked={this.state.currentCobertura['horarios'][5]['estatus']===1} onChange={ (checked,event) => this.changeSwitch2(5,checked)}/>
                      </label>
                      
                      <div className="mytimers"  style={{display:this.state.currentCobertura['horarios'][5]['estatus']===1?'flex':'none'}} >
                        <TimePicker allowClear={false} placeholder="Desde" value={this.state.currentCobertura['horarios'][5]['desde']} onChange={ (value,svalue) => this.horaChange(value,5,'desde') } name="desde6" format={'HH:mm'} />
                        <TimePicker allowClear={false} placeholder="Hasta" value={this.state.currentCobertura['horarios'][5]['hasta']} onChange={ (value,svalue) => this.horaChange(value,5,'hasta') } name="hasta6" format={'HH:mm'} />
                      </div>
                  </div>

                  <div className="inputs-group inputs-groupSwitch" onClick={this.jumpClick} >
                      
                      <label className="inputs-label">
                        Domingo
                        <Switch id="swSlave7" className="myswitch" checked={this.state.currentCobertura['horarios'][6]['estatus']===1} onChange={ (checked,event) => this.changeSwitch2(6,checked)}/>
                      </label>
                      
                      <div className="mytimers" style={{display:this.state.currentCobertura['horarios'][6]['estatus']===1?'flex':'none'}} >
                        <TimePicker allowClear={false} placeholder="Desde" value={this.state.currentCobertura['horarios'][6]['desde']} onChange={ (value,svalue) => this.horaChange(value,6,'desde') } name="desde7" format={'HH:mm'} />
                        <TimePicker allowClear={false} placeholder="Hasta" value={this.state.currentCobertura['horarios'][6]['hasta']} onChange={ (value,svalue) => this.horaChange(value,6,'hasta') } name="hasta7" format={'HH:mm'} />
                      </div>
                  </div>

                 </div>
            </Modal>

            <Loading loading={(this.state.loading || this.state.loading2)} zIndex={2000} />

            {
              this.props.validCurrentUser(this.context,'sucursales','bool',this.state.detalles['CODE'],'EDITAR')&&
              <div className="buttomFlotanding" title="Agregar nuevo registro" onClick={() => this.showNuevaCobertura(-1)} > + </div>
            }

            <div className="metaControles">
              <h3 className="tituloPanel"> Zonas de cobertura - {this.state.detalles['NOMBRE']} </h3>
            </div>
            <div className="cont-Unidades2">
            {
              this.state.coberturas.length > 0 ?
              this.state.coberturas.map((cobertura,indice) =>(
                  <div className="miunidad2" key={'cobertura_'+cobertura.ID} style={{cursor:'pointer'}} >
                      <span className="nombre" onClick={() => this.showNuevaCobertura(cobertura)}> Cobertura {(indice+1)} </span>
                      {this.getCosto(cobertura.COSTO_ENVIO)}
                      <span className="delete">
                        <img src={img+'basurero_gris.png'} onClick={()=>this.showModalConfirm(cobertura,'¿Desea eliminar la cobertura '+(indice+1)+'?')} alt="..." title='Eliminar registro' />
                      </span>
                   </div>
               ))
              :<div className="datosPublico" style={{width:'100%'}} >
                  <div className="info">
                    <div className="txt"> Aún no registras puntos de cobertura</div>
                   </div>
                </div>
            }
            </div>

          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Coberturas);