import React,{useContext,useEffect,useState} from 'react';

import axios from 'axios';

import { Globalrequest as request } from './../../../request.js';
import {Host as host ,  Img as img } from './../../../app.json'
import { Modal } from 'antd';

import {withRouter} from 'react-router-dom'

import NumberFormat from 'react-number-format';

import Alert from '../../../modalCargando/modalAlert';

import Loading from '../../../modalCargando/loading';
import {userContext} from './../context'

import   './categorias.scss';


const Lista = props => {
  
  const contextType = useContext(userContext)

  const [idE,] = useState(sessionStorage.getItem('id'))

  const [loading,setLoading] = useState(false)
  const [editanding,setEditanding] = useState(false)
  const [agregarEditar,setAgregarEditar] = useState(false)

  const [catalogoDeCategorias,setCatalogoDeCategorias] = useState([])
  const [nuevaCategoria,setNuevaCategoria] = useState({})

  const [modalAlert,setModalAlert] = useState({visible:false,txt:''})
  const [modalConfirm,setModalConfirm] = useState({visible:false,txt:'',tipo:-1,id:-1})
  const [modalRecorrer,setModalRecorrer] = useState({visible:false,nivel:-1})
  const [modalIntercambiar,setModalIntercambiar] = useState({visible:false,nivel:-1})
  const [modalNivel3,setModalNivel3] = useState({visible:false,title:'',txt:'',op:-1})

  const [nextLevel,setNextLevel] = useState(-1)

  const actualizarCatalogo = () => {
    let HTTPHeaders = {id:sessionStorage.getItem('id')}
    setLoading(true)
    axios.post(`${host}productos/productos/catalogoDeCategorias`,HTTPHeaders,request).then(rt=>{
      let rta = rt.data.sort((a,b) => Number(a.NIVEL) - Number(b.NIVEL) )
      let nextLevelLocal
      if(rta.length === 0) nextLevelLocal = 1
      else{ nextLevelLocal = Number(rta[rta.length-1]['NIVEL']) + 1 }
      setLoading(false)
      setCatalogoDeCategorias(rta)
      setNextLevel(nextLevelLocal)
    })
  }

  const showModaNew = (id) => {
    let {valid,mood,validCurrentUser} = props

    if(id!==-1 && !validCurrentUser(contextType,'categorias','bool',false,'EDITAR') ){
      setModalAlert({visible:true,txt:'El perfil actual no  tiene permisos para editar Categorías',})
      return
    }

    if(!valid){
      if(Number(mood)===1){
        setModalAlert({visible:true,txt:'No puedes acceder a esta opción porque tu cuenta está con funciones limitadas.',})
      }else{
        setModalAlert({visible:true,txt:'No puedes acceder a esta opción porque debes tener una suscripción activa.',})
      }
      return
    }

    let nuevaCategoriaLocal = {}, editandingLocal=false
    
    if(id===-1){
      nuevaCategoriaLocal = { nivel:nextLevel, descripcion:'', id:-1, idE:idE, IdC:0, }
      editandingLocal = false
    }
    else{
      nuevaCategoriaLocal = { nivel: id['NIVEL'], descripcion: id['DESCRIPCION'], id: id['ID'], idE: id['ID_EMPRESA'], IdC: id['ID_CATEGORIA'], }
      editandingLocal = true
    }
    setNuevaCategoria(nuevaCategoriaLocal)
    setEditanding(editandingLocal)
    setAgregarEditar(true)
  }
  
  const showModalConfirm = (e,id,tipo,msj) => {//  1 -> eliminar || 2 -> desactivar || 3 -> eliminar  
    e.stopPropagation()
    let {valid,mood,validCurrentUser} = props

    if(!validCurrentUser(contextType,'categorias','bool',false,'ELIMINAR')){
      setModalAlert({visible:true,txt:'El perfil actual no  tiene permisos para eliminar Categorías ',})
      return
    }

    if(!valid){
      if(Number(mood)===1){
        setModalAlert({visible:true,txt:'No puedes acceder a esta opción porque tu cuenta está con funciones limitadas.'})
        setModalConfirm({visible:false,txt:'',tipo:-1,id:-1})
      }else{
        setModalAlert({visible:true,txt:'No puedes acceder a esta opción porque debes tener una suscripción activa.'})
        setModalConfirm({visible:false,txt:'',tipo:-1,id:-1})
      }
      return
    }

    if(contextType['type']===2)
      msj = 'Esta acción afectará a todas la sucursales de la empresa.'
    else
      msj = '¿Desea eliminar la categoría '+msj+'?'
    setModalConfirm({visible:true,txt:msj,tipo:tipo,id:id})
  }

  const acceptConfirm = () => {
    let {id,tipo} = modalConfirm

    switch(tipo){
      case 1:eliminar(id);break;
      default:break;
    }
  }

  const eliminar = (id) => {
    let unidad = catalogoDeCategorias.filter( unidad =>  unidad.ID === id )
    let HTTPHeaders = {id:unidad[0].ID}
    setLoading(true)
    setModalConfirm({visible:false,txt:'',tipo:-1,id:'-1'})

    axios.post(`${host}productos/productos/eliminarCategoriaEmpresa`,HTTPHeaders,request).then(rt=>{
      if(Number(rt.data)===-1){
        setModalAlert({visible:true,txt:'Categoría eliminada'})
        actualizarCatalogo()
      }
      else if(Number(rt.data)>0){
        setLoading(false)
        setModalAlert({visible:true,txt:'No puedes eliminar esta categoría porque tienes productos asociados.',})
      }
      else{
        setLoading(false)
        setModalAlert({txt:'Parece que ocurrió un problema, por favor intentalo más tarde. [Código de error: SV-ER: #02-01].',visible:true})
      }
    })
  }

  const submit = (force) => {
    let formData = new FormData(document.getElementById('formulaCategoria'));

    if(contextType['type']===2 && !force){
      let labelAction = editanding ? 'Editar' : 'Crear'
      setModalNivel3({visible:true,title:`¿${labelAction} Unidad de medida?`,txt:'Esta acción afectará a todas las sucursales de la empresa.',op:1})
      return
    }

    
    if( formData.get('descripcion').trim()==='' || formData.get('nivel').trim()==='' ){
      setModalAlert({txt:'No puede haber campos vacíos',visible:true})
      return
    }

    if( Number(formData.get('nivel').trim()) <= 0 ){
      setModalAlert({txt:'El nivel debe ser mayor a 0',visible:true})
      return
    }

    let repetidos = catalogoDeCategorias.filter( unidad => unidad.ID !== formData.get('id'))
    repetidos = repetidos.filter( categoria => categoria.DESCRIPCION.trim().toLowerCase() === formData.get('descripcion').trim().toLowerCase() )

    if(repetidos.length >=1 ){
      setModalAlert({txt:'Ya existe una categoría con el nombre '+formData.get('descripcion'),visible:true})
      return
    }

    let repetidos2 = catalogoDeCategorias.filter( unidad => unidad.ID !== formData.get('id'))
    repetidos2 = repetidos2.filter( categoria => Number(categoria.NIVEL) === Number(formData.get('nivel')) )
    
    if(repetidos2.length>=1){
      
      if(Number(formData.get('id')) !== -1){
        setModalIntercambiar({visible:true,nivel:formData.get('nivel')})
      }

      if(Number(formData.get('id')) === -1){
        setModalRecorrer({visible:true,nivel:formData.get('nivel')})
      }
      return
    }
    
    let HTTPHeaders = {
      nuevos:[{idE:formData.get('idE'), nivel:formData.get('nivel'), idC:formData.get('idC'), descripcion:formData.get('descripcion'), id:formData.get('id'), }]
    }
    
    setAgregarEditar(false)
    setLoading(true)
    axios.post(`${host}productos/productos/agregarCategorias2/`,HTTPHeaders,request).then(rt=>{
      if(Number(rt.data)>0){
        setModalAlert({txt:'El registro de la categoría fue correcto',visible:true})
        actualizarCatalogo();
      }
      else if(Number(rt.data)===-2){
        setModalAlert({txt:'La categoría se editó correctamente',visible:true})
        actualizarCatalogo();
      }
      else{
        setModalAlert({txt:'Parece que ocurrió un error, por favor intentalo más tarde. [Código de error: SV-ER: #02-02]',visible:true})
      }
    })
  }
  
  const recorrer = () => {
    let {nivel} = modalRecorrer
    let formData = new FormData(document.getElementById('formulaCategoria'));    
    let nuevos = [{idE:formData.get('idE'), nivel:formData.get('nivel'), idC:formData.get('idC'), descripcion:formData.get('descripcion'), id:formData.get('id'), }]


    console.log(nivel,nextLevel,)


    for(let i = nivel;i<=nextLevel;i++){
      let aux = catalogoDeCategorias.filter(cat => Number(cat.NIVEL) === Number(i))
      console.log(aux)
      if(aux.length > 0){
        aux =  aux[0]
        aux = {idE:aux['ID_EMPRESA'], nivel:(Number(i)+1), idC:aux['ID_CATEGORIA'], descripcion:aux['DESCRIPCION'], id:aux['ID'], }
        nuevos.push(aux)
      }
      else break;
    }
    let HTTPHeaders = { nuevos }

    setLoading(true)
    setAgregarEditar(false)
    setModalRecorrer({visible:false,nivel:-1})

    axios.post(`${host}productos/productos/agregarCategorias2/recorrer`,HTTPHeaders,request).then(rt=>{
      if(Number(rt.data)>0){
        setModalAlert({txt:'El registro de la categoría fue correcto',visible:true})
        actualizarCatalogo();
      }
      else{
        setModalAlert({txt:'Parece que ocurrió un error, por favor intentalo más tarde. [Código de error: SV-ER: #02-02]',visible:true})
      }
    })

  }

  const intercambiar = () => {
    let {nivel} = modalIntercambiar
    let formData = new FormData(document.getElementById('formulaCategoria'));    
    let nuevos = [{ idE:formData.get('idE'), nivel:formData.get('nivel'), idC:formData.get('idC'), descripcion:formData.get('descripcion'), id:formData.get('id'), }]    
    let oldLevel = catalogoDeCategorias.filter(cat => cat.ID === formData.get('id') )
    oldLevel = oldLevel[0]['NIVEL']

    let aux = catalogoDeCategorias.filter(cat => Number(cat.NIVEL) === Number(nivel))
    aux = aux[0]
    aux = {idE:aux['ID_EMPRESA'], nivel:oldLevel, idC:aux['ID_CATEGORIA'], descripcion:aux['DESCRIPCION'], id:aux['ID'], }
    nuevos.push(aux)
    let HTTPHeaders = { nuevos }
    setLoading(true)
    setAgregarEditar(false)
    setModalIntercambiar({visible:false,nivel:-1})

    axios.post(`${host}productos/productos/agregarCategorias2/intercambiar`,HTTPHeaders,request).then(rt=>{
      if(Number(rt.data)>0){
        setModalAlert({txt:'La categoria se editó correctamente',visible:true})
        actualizarCatalogo();
      }
      else{
        setModalAlert({txt:'Parece que ocurrió un error, por favor intentalo más tarde. [Código de error: SV-ER: #02-02]',visible:true})
      }
    })
  }

  const okNivel3 = () => {
    let {op} = modalNivel3
    if(op===1)//agregareditar
      submit(true)
    setModalNivel3({visible:false,title:'',txt:'',op:-1})
  }

  useEffect(()=>{
    document.title = "Mis categorías"
    actualizarCatalogo()
    return ()=>{}
  },[])

  return(
    <div className="cont-sucursales" >
      <div className="cont-sucursales2" >
        <div className="cont-sucursales3">

          <Loading loading={loading} />

          <Modal centered title={editanding ? 'Editar categoría' : 'Agregar categoría'} maskClosable={false} keyboard = {false} 
              okText="Guardar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
              cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
              visible={agregarEditar}
              closable={false}
              className="miAntModal alertMy modalUnidad"
              onCancel={()=>setAgregarEditar(false)}
              onOk={()=>submit(false)} >
            <form id="formulaCategoria" onSubmit={e => e.preventDefault()} >
              {
                  agregarEditar &&
                  <div className="miUniDiv">

                    <div className="nivelNom">
                        <div className="inputs-group ja0" style={{position:'relative'}} >
                        {
                          !props.validCurrentUser(contextType,'categorias','bool',false,'EDITAR') && 
                          <div className='fakemask' style={{height:'170%',top:'-10px'}} title='No puedes editar el nivel de esta Categoría.'/>
                        }

                          <label className="inputs-label" htmlFor="niveld" > Nivel </label>
                          <NumberFormat type="text" maxLength={6} className="inputs-input" name="nivel" defaultValue={nuevaCategoria.nivel} id="niveld"/>
                        </div>
                      
                      <div className="inputs-group ja1">
                          <label className="inputs-label" htmlFor="descripciond" > Nombre de la categoría </label>
                          <input type="text" autoFocus maxLength={30} className="inputs-input" name="descripcion" defaultValue={nuevaCategoria.descripcion} id="descripciond"/>
                      </div>
                    </div>

                      <input type="hidden" name="id"  value={nuevaCategoria.id}  />
                      <input type="hidden" name="idE" value={nuevaCategoria.idE} />
                      <input type="hidden" name="idC" value={nuevaCategoria.IdC} />

                  </div>
              }
            </form>
          </Modal>

          <Alert visible={modalAlert['visible']} text={modalAlert['txt']} onOk={() => setModalAlert({visible:false,txt:''})} />

          <Alert title="¿Eliminar categoría?" visible={modalConfirm['visible']} text={modalConfirm['txt']} onOk={acceptConfirm}
            withCancel onCancel={ () => setModalConfirm({visible:false,tipo:-1,txt:'',id:-1})}
          />
          
          
          {/*modalrecorrer*/}

          <Alert visible={modalRecorrer['visible']} text={`Ya existe un categoría nivel ${modalRecorrer['nivel']}, ¿Desea recorrer las categorías ya existentes?`} onOk={recorrer}
            withCancel onCancel={()=> setModalRecorrer({visible:false,nivel:-1})} />
          
          {/*modalintercambiar*/}

          <Alert visible={modalIntercambiar['visible']} text={`Ya existe un categoría nivel ${modalIntercambiar['nivel']}, ¿Desea intercambiar los niveles de las categorías?`} onOk={intercambiar}
            withCancel onCancel={()=> setModalIntercambiar({visible:false,nivel:-1})} />

          {/**/}

          <Alert title={modalNivel3['title']} visible={modalNivel3['visible']} text={modalNivel3['txt']}
            onOk={okNivel3} withCancel onCancel={() => setModalNivel3({visible:false,op:-1,txt:'',title:'',})} />


          <div className="metaControles">
            <h3 className="tituloPanel"> Categorías </h3>
          </div>

          <div className="cont-Unidades2">

          {
            catalogoDeCategorias.map((categoria,indice) => {
              return(
                <div className="miunidad" key={'categoria_'+categoria.ID} onClick={()=>showModaNew(categoria)} >
                    <span className="nombre">{categoria.NIVEL}.- {categoria.DESCRIPCION}</span>
                    <span className="delete">
                      <img src={img+'basurero_gris.png'} onClick={ (e)=>showModalConfirm(e,categoria['ID'],1,categoria['DESCRIPCION'])} alt="..." title='Eliminar registro' />
                    </span>
                 </div>
              )
            })
          }

          </div>
          {
            props.validCurrentUser(contextType,'categorias','bool',false,'CREAR') && 
            <div className="buttomFlotanding" title="Agregar nuevo registro" onClick={()=>showModaNew(-1)} > + </div>
          }

          <div className="cont-instancias5"></div>

        </div>
      </div>
    </div>
  )

}


export default withRouter(Lista);