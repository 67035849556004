import React from 'react';

import axios from 'axios';

import { Globalrequest as request } from './../../../request.js';
import {Host as host ,  Img as img , ImgPanelAdmin as ImgPanel} from './../../../app.json'
import { Select, Popover, Icon, Modal , Checkbox } from 'antd';

import {withRouter} from 'react-router-dom'

import Loading from '../../../modalCargando/loading';
import {MiDiv} from '../../../modalCargando/miDiv';

import   './productos.scss';

import {userContext} from './../context'

import {formatNumber} from './../../../utilities/generalfunctions'

class Lista extends React.Component{

  static contextType = userContext

  constructor(props) {
    super(props);

    this.actualizarSucursales2 = this.actualizarSucursales2.bind(this)

    this.actualizarProductos = this.actualizarProductos.bind(this)

    this.acceptConfirm = this.acceptConfirm.bind(this)
    this.duplicar = this.duplicar.bind(this)
    this.desactivar = this.desactivar.bind(this)
    this.eliminar = this.eliminar.bind(this)
    this.changeFiltros = this.changeFiltros.bind(this)
    this.changeFiltros2 = this.changeFiltros2.bind(this)
    this.filtrar = this.filtrar.bind(this)
    this.estasCategorias = this.estasCategorias.bind(this)
    this.borrarFiltros = this.borrarFiltros.bind(this)

    this.prove = this.prove.bind(this)
    this.state = {
      idNotificacionPorAtender:sessionStorage.getItem('idNotificacionPorAtender'),
      first:true,
      /**/
      sucursales:this.props.sucursales,
      productos : [],
      loading:true,
      filtro0:true,
      filtro00:false,
      filtro01:2,
      filtro1:0,
      filtro2:'',
      filtro3:0,
      filtro4:'',//Nombre
      filtro5:'',//sucursal
      catalogoDeCategorias:[],
      listaCategorias:[],
      productosFiltrados:[],
      /* */
      categoriasByNivel : [],
      /**/
      modalAlert:false,
      txtAlert:'',
      modalConfirm:false,
      txtConfirm:'',
      idConfirm:'',
      tipoConfirm:0,
    }
  }

  componentDidMount(){
    document.title = "Mis productos"

    const id = sessionStorage.getItem('id')
    if( id===null || id===undefined )return;

    let {idNotificacionPorAtender} = this.state
    if(idNotificacionPorAtender !== 'null'){
      let data = {idN:idNotificacionPorAtender,estatus:2}
      axios.post(`${host}clientes/clientes/cambiarEstatusNotificacion`,data,request)
      sessionStorage.setItem('idNotificacionPorAtender','null')
    }

    let data = {id:sessionStorage.getItem('id')}
    this.actualizarProductos();
    axios.post(`${host}productos/productos/catalogoDeCategorias`,data,request).then(rt=>{
      let {catalogoDeCategorias,categoriasByNivel,listaCategorias} = this.state
      catalogoDeCategorias = rt.data
      categoriasByNivel = catalogoDeCategorias.sort((a,b)=> a.NIVEL - b.NIVEL )
      listaCategorias = categoriasByNivel.map(c => c.DESCRIPCION)
      this.setState({ catalogoDeCategorias : catalogoDeCategorias , categoriasByNivel : categoriasByNivel ,listaCategorias : listaCategorias})
    })
    sessionStorage.removeItem('idP')
    if(this.state.sucursales.length === 0){
      this.actualizarSucursales2()
    }
  }

  actualizarSucursales2(){
    let data = JSON.parse(sessionStorage.getItem('metadata'))
    this.props.setSucursales(data['sucursales'])
    this.setState({ sucursales : data['sucursales'] })
    this.props.editState('datos',data['datos'])
    this.props.editState('sucursales',data['sucursales'])
    this.props.editState('categorias',data['categorias'])
    this.props.editState('tags',data['tags'])
    this.props.editState('notificaciones',data['notificaciones'])
  }

  actualizarProductos(){
    let data = {id:sessionStorage.getItem('id')}
    let {folder} = sessionStorage
    this.setState({loading:true})
    axios.post(`${host}productos/productos/productosPorEmpresa`,data,request).then(rt=>{
      let rta = rt.data
      let productos = []
      for(let producto of rta){
        producto['IMAGEN'] = producto.IMAGEN===''?img+'new_camera_icon_prod.png': folder+producto.FOLDER+'/'+producto.IMAGEN+'.png?'+Math.random()
        productos.push(producto)
      }
      this.setState({productos:productos,loading:false})
      this.setState({productosFiltrados:productos})
      this.borrarFiltros()
    }).finally(()=>{
      let {first} = this.state
      if(first){
        let prefiltro = sessionStorage.getItem('prefiltro')
        if(prefiltro !== null && prefiltro !== undefined){
          sessionStorage.removeItem('prefiltro')
          prefiltro = prefiltro.split('[/**/]')
          this.changeFiltros(prefiltro[1],prefiltro[0])
          this.classToggle()
        }
        this.setState({first:false})
      }
    })
  }



  showModalConfirm(id,tipo,msj,e){//  1 -> duplicar || 2 -> desactivar || 3 -> eliminar || 4 -> desactivar
    let {validCurrentUser}  = this.props
    let sucursales = [], permisosToCheck = ['','CREAR','EDITAR','ELIMINAR','EDITAR']
    if(Number(id['USUAR_VARIENTES'])===1){
      for(let variante of id['existenciaVariantes']){
        let aux = variante.filter(existencia => !sucursales.includes(existencia['code']) )
        if(aux.length>0){
          for(let a of aux)sucursales.push(a['code'])
        }
      }
    }else{
      for(let existencia of id['stockPropio']){
        if(!sucursales.includes(existencia['code']))
          sucursales.push(existencia['code'])
      }
    }

    for(let suc of sucursales ){
      if(!validCurrentUser(this.context,'productos','bool',suc,permisosToCheck[tipo])){
        this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para '+permisosToCheck[tipo].toLowerCase()+' Productos y Servicios sobre todas las sucursales relacionadas con el registro actual.'})
        return
      }
    }

    this.setState({modalConfirm:true,idConfirm:id,tipoConfirm:tipo,txtConfirm:msj})
    e.stopPropagation()
  }

  acceptConfirm(){
    let {valid,mood} = this.props
    if(!valid){
      if(Number(mood)===1){
        this.setState({modalConfirm:false,idConfirm:'',tipoConfirm:0,modalAlert:true,txtAlert:'No puedes acceder a esta opción porque tu cuenta está con funciones limitadas.'})
      }else{
        this.setState({modalConfirm:false,idConfirm:'',tipoConfirm:0,modalAlert:true,txtAlert:'No puedes acceder a esta opción porque debes tener una suscripción activa.'})
      }
      return
    }

    let {idConfirm,tipoConfirm} = this.state
    this.setState({modalConfirm:false})
    switch(tipoConfirm){
      case 1:this.duplicar(idConfirm);break;
      case 2:this.desactivar(idConfirm,2);break;
      case 3:this.eliminar(idConfirm);break;
      case 4:this.desactivar(idConfirm,1);break;
      default:break;
    }
  }

  /**/

  duplicar(id){
    let {productos} = this.state
    let nombreBase = id['NOMBRE']
    nombreBase = nombreBase.split('-copia-')[0]
    let copias = productos.filter(p => p.NOMBRE.includes(nombreBase+'-copia-'))
    let count = copias.length+1
    let data = {id:id['ID'],count:count,idEmpresa:sessionStorage.getItem('id')}
    this.setState({loading:true})
    axios.post(`${host}productos/productos/duplicarProducto`,data,request).then(rt=>{
      this.setState({loading:false})
      let msj
      if(Number(rt.data>0)){
        msj = 'El producto ha sido duplicado correctamente'
        this.actualizarProductos();
      }
      else{
        msj = 'Parece que ocurrió un error, por favor intentalo más tarde. [Código de error: SV-ER: #05-01]'
      }
      this.setState({ modalAlert:true, txtAlert:msj,})
    })
  }
  
  desactivar(id,status){
     let {productos} = this.state
     let index =  productos.indexOf(id)
     let data = {id:id['ID'],status:status}
     this.setState({loading:true})
     axios.post(`${host}productos/productos/desActivacionRapida`,data,request).then(rt=>{
       this.setState({loading:false})
       let msj = ''
       if(Number(rt.data)===-2){
         msj = 'El producto ha sido '+['activado','desactivado'][status-1]+' correctamente'
         productos[index]['ESTATUS']=''+status
       }
       else{
         msj = 'Parece que ocurrió un error, por favor intentalo más tarde. [Código de error: SV-ER: #05-01]'
       }
       this.setState({ modalAlert:true, txtAlert:msj, loading:false,})
       this.borrarFiltros()
     })
  }

  eliminar(id){
    let {productos} = this.state
    let index = productos.indexOf(id)
    let data = {id:id['ID'],idEmpresa:sessionStorage.getItem('id')}
    this.setState({loading:true})
    axios.post(`${host}productos/productos/eliminarProducto`,data,request).then(rt=>{
      let rta = Number(rt.data) , msj = ''
      if(rta === 1 ){
        msj = 'El producto ha sido eliminado correctamente'
        productos.splice(index,1)
      }
      else if(rta === 0 ){
        msj = 'No puedes eliminar este producto porque tienes pedidos por atender.'
      }
      else{
        msj = "Parece que ocurrió un error, por favor intentalo más tarde [Código de error: SV-ER: #05-02]"
      }
      this.setState({ modalAlert:true, txtAlert:msj, loading:false,})
      this.borrarFiltros()
    })

  }

  getClassStock(producto){
    if(Number(producto.TIPO)===2){
      return 'servicio';
    }
    if(Number(producto.USUAR_VARIENTES)===1){
      return 'variantes';
    }
    else if(Number(producto.USAR_STOCK)===2)
      return 'nostock';
    else if(Number(producto.USAR_STOCK)===1){
      if( Number(producto.STOCK_ACT) <= Number(producto.STOCK_MINIMO) || Number(producto.STOCK_ACT) === 0 ){
        return 'noexiste';
      }
      else if(  Number(producto.STOCK_ACT) > Number(producto.STOCK_MINIMO) ){
        return 'existe';
      }
    }
  }
  
  getLabelStock(producto){
    if(Number(producto.TIPO)===2) return (<label>Servicio</label>);
    if(Number(producto.USUAR_VARIENTES)===1) return '';
    if(Number(producto.USAR_STOCK)===1) return (<label>{producto.STOCK_ACT}</label>);
    return ''
  }

  getTitleStock(producto){
    if(Number(producto.TIPO)===2) return 'Servicio';
    if(Number(producto.USUAR_VARIENTES)===1) return 'Producto con variantes';
    if(Number(producto.USAR_STOCK)===2) return 'Producto sin control de Stock';
    if(Number(producto.USAR_STOCK)===1) return 'Producto con control de Stock';
    return ''
  }

  getLabelPrecio(producto){
    if(Number(producto.USUAR_VARIENTES)===1){
      let cv = Number(producto.CANT_VARIANTES);
      return cv+' Variante' + (cv === 1 ? '' : 's');
    }
    return '$'+formatNumber(producto.PRECIO,2)+' MXN'
  }

  getImgeStock(producto){
    if(Number(producto.USUAR_VARIENTES)===1)
      return ImgPanel+'variantes_icono_empresa.png'
    else
      return ImgPanel+'caja_icono_empresa.png';
  }
  
  prove(id){
    sessionStorage.setItem('idP',id)
    this.props.redirectTo2('/home/productos/editar')
  }

  subClick(e,id){
    e.stopPropagation()
    let abuelo = document.getElementById('getAbuelo_'+id).parentElement.parentElement
    if(abuelo.classList.contains('ant-popover-hidden'))
      document.getElementById('tigreId_'+id).click()
  }
  
  classToggle(){
    document.getElementById('toggleFiltrarId').classList.toggle('toggleFiltrar2');
    document.getElementById('contControlesId').classList.toggle('contControles2');
  }
  
  changeFiltros(value,filtro){
    if(filtro === 'filtro2')alert(1)
   this.setState({[filtro]:value})
   setTimeout(this.filtrar,.1);
  }

  changeFiltros2(e){
    let {target} = e
    if(target.id==='HabilitadosC'){
      if(target.checked)this.setState({filtro00:target.checked})
      else this.setState({filtro00:target.checked,filtro01:!target.checked})
    }
    if(target.id==='DesabilitadosC'){
      if(target.checked)this.setState({filtro01:target.checked})
      else this.setState({filtro00:!target.checked,filtro01:target.checked})
    }
    setTimeout(this.filtrar,.1);
  }

  filtrar(){
    let {filtro0,filtro00,filtro01,filtro1,filtro2,filtro3,filtro4,filtro5,productosFiltrados,productos} = this.state
    filtro0 = (filtro00 && filtro01) ? 1 : (filtro00 && !filtro01) ? 2 : 3;
    productosFiltrados = productos;
    switch(filtro0){
      case 1: productosFiltrados = productos;break;
      case 2: productosFiltrados = productosFiltrados.filter(producto => producto.ESTATUS === '1' ); break;
      case 3: productosFiltrados = productosFiltrados.filter(producto => producto.ESTATUS === '2' ); break;
      default:break;
    }
    switch(filtro1){
      case '1': productosFiltrados = productosFiltrados.sort( (p1,p2) => p1.NOMBRE.toLocaleLowerCase().localeCompare(p2.NOMBRE.toLocaleLowerCase()) ) ; break;
      case '2': productosFiltrados = productosFiltrados.sort( (p1,p2) => p1.NOMBRE.toLocaleLowerCase().localeCompare(p2.NOMBRE.toLocaleLowerCase()) ).reverse() ; break;
      case '3': productosFiltrados = productosFiltrados.sort( (p1,p2) => p1.STOCK_ACT - p2.STOCK_ACT ).reverse() ; break;
      case '4': productosFiltrados = productosFiltrados.sort( (p1,p2) => p1.STOCK_ACT - p2.STOCK_ACT ); break;
      default:break;
    }

    filtro2 = document.getElementById('catsReadOnly').value
    productosFiltrados = filtro2 === 'Todas' ? productosFiltrados : productosFiltrados.filter(producto =>  filtro2.includes(producto.CATEGORIA) )  
    
    switch(filtro3){
      case '1': productosFiltrados = productosFiltrados.filter((producto) => (Number(producto.USAR_STOCK) === 1) && (Number(producto.STOCK_ACT) > Number(producto.STOCK_MINIMO)) ); break;
      case '2': productosFiltrados = productosFiltrados.filter((producto) => (Number(producto.USAR_STOCK) === 1) && (Number(producto.STOCK_ACT) <= Number(producto.STOCK_MINIMO)) ); break;
      case '3': productosFiltrados = productosFiltrados.filter((producto) => (Number(producto.USAR_STOCK) === 1) && (Number(producto.STOCK_ACT) <= 0) ); break;
      case '4': productosFiltrados = productosFiltrados.filter((producto) => Number(producto.USAR_STOCK) === 2 ); break;
      default:break;
    }

    productosFiltrados = filtro4 === '' ? productosFiltrados : productosFiltrados.filter( producto => producto.NOMBRE.toLocaleLowerCase().includes(filtro4.toLocaleLowerCase()))
    productosFiltrados = filtro5 === '' ?productosFiltrados : productosFiltrados.filter(producto => producto.stockPropio.some(stock => stock.nombre === filtro5) || (producto.USUAR_VARIENTES === '1' && producto.existenciaVariantes.some(exisVar => exisVar.some(ev => ev['nombre']===filtro5)) ) )

    
    this.setState({productosFiltrados:productosFiltrados})
  }

  estasCategorias(e,descripcion){
    let {catalogoDeCategorias} = this.state
    let {checked,id} = e.target
    let input = document.getElementById('catsReadOnly')
    if(id !== 'check_check_'){
      if(checked){
        if(input.value.includes('Todas')){
          document.getElementById('check_check_').checked = false
          input.value = input.value.split('Todas').join('')
        }
        input.value += descripcion + ' | '
      }
      else{ input.value = input.value.split(descripcion + ' | ').join('') }
    }
    else{
      if(checked){
        for(let categoria of catalogoDeCategorias){
          document.getElementById('check_'+categoria.ID).checked = false
        }
        input.value = descripcion
      }
      else{
        input.value = ''
      }
    }
    input.focus()
    this.filtrar()
  }

  borrarFiltros(){
    this.setState({ filtro0 : 0, filtro00  : true, filtro01  : false, filtro1  : 0, filtro2 : 'Todas' , filtro3  : 0, filtro4 : '' , filtro5 : '' })
    document.getElementById('catsReadOnly').value = 'Todas'
    setTimeout(this.filtrar,.1);
  }

  render(){
    return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3">

          {
            this.state.productosFiltrados.length === 0 && 
            <div className="imgnonResult">
              <div  className="nonResultChild">
                <img src={ImgPanel+'nonproductos.png'} alt="..." />
              </div>
            </div>
          }

          <Loading loading={this.state.loading} />

            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{className:'btn btn1', style:{ width:'120px' } }}
                cancelButtonProps={{ className:'bnt bnt2', style:{display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalAlert   ?
                        <span className="txtAlert"> {this.state.txtAlert} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            <Modal centered title="Confirmar" maskClosable={false} keyboard = {false} 
                okText="Continuar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
                cancelText="Cancelar"
                visible={this.state.modalConfirm}
                closable={false}
                onOk={this.acceptConfirm}
                onCancel={ () => this.setState({modalConfirm:false,tipoConfirm:0,txtConfirm:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                  {
                    this.state.modalConfirm   ?
                          <span className="txtAlert"> {this.state.txtConfirm} </span>:
                          <React.Fragment/>
                  }
                </div>
            </Modal>

            {
              this.props.validCurrentUser(this.context,'productos','bool',false,'CREAR')&&
              <div className="buttomFlotanding" title="Agregar nuevo registro" onClick={()=>this.props.redirectTo2('/home/productos/nuevo')} > + </div>
            }

            <div className="tituloYControles">
              
              <div className="metaControles">
                <h3 className="tituloPanel"> Productos y servicios </h3>
                <span className="toggleFiltrar" id="toggleFiltrarId" onClick={this.classToggle} > Filtrar </span>
              </div>

              <div className="contControles" id="contControlesId">

                <div className="contControlesHijo" id="contControlesHijoId">

                  <div className="filtradosExtras">
                      <div className="inputs-group-inline">
                          <label className="inputs-label" > Buscar por nombre </label>
                          <input className="inputs-input" type="text" onChange={e => this.changeFiltros(e.target.value,'filtro4')} />
                      </div>
                      <div className="inputs-group-inline">
                        <label className="inputs-label" htmlFor="pesopd" >  Filtrar por sucursal </label>
                        <Select value={this.state.filtro5} className="inputs-input" onChange={(value) => this.changeFiltros(value,'filtro5') } >
                          {
                            this.state.sucursales.map((sucu,indice)=>{
                              return(
                                <Select.Option value={sucu.NOMBRE} key={'sucList_'+indice} > {sucu.NOMBRE } </Select.Option>
                              )
                            })
                          }
                          <Select.Option value={''} > Todas las sucursales </Select.Option>
                        </Select>
                      </div>
                  </div>
                  
                  <div className="tiposFiltrados">
                    
                    <div className="bloqueFiltro" >
                      <label className="filTle" > Ordenar por </label>
                      
                      <div className="opFiltro">
                        <label> Nombre A-Z </label>
                        <input checked={this.state.filtro1 === "1"} type="radio" className="radioBubook" name="filtro1" value="1" onChange={()=>this.changeFiltros("1",'filtro1')} />
                      </div>

                      <div className="opFiltro">
                        <label> Nombre Z-A </label>
                        <input checked={this.state.filtro1 === "2"} type="radio" className="radioBubook" name="filtro1" value="2" onChange={()=>this.changeFiltros("2",'filtro1')} />
                      </div>

                      <div className="separador"></div>

                      <div className="opFiltro">
                        <label> Stock mayor a menor </label>
                        <input checked={this.state.filtro1 === "3"} type="radio" className="radioBubook" name="filtro1" value="3" onChange={()=>this.changeFiltros("3",'filtro1')} />
                      </div>

                      <div className="opFiltro">
                        <label> Stock menor a mayor </label>
                        <input checked={this.state.filtro1 === "4"} type="radio" className="radioBubook" name="filtro1" value="4" onChange={()=>this.changeFiltros("4",'filtro1')} />
                      </div>

                    </div>

                    <div className="bloqueFiltro" >
                      <label className="filTle" > Stock </label>
                      
                      <div className="opFiltro">
                        <label> Arriba del mínimo </label>
                        <input checked={this.state.filtro3 === "1"} type="radio" className="radioBubook" name="filtro3" value="1" onChange={()=>this.changeFiltros("1",'filtro3')} />
                      </div>

                      <div className="opFiltro">
                        <label> Debajo del mínimo </label>
                        <input checked={this.state.filtro3 === "2"} type="radio" className="radioBubook" name="filtro3" value="2" onChange={()=>this.changeFiltros("2",'filtro3')} />
                      </div>

                      <div className="opFiltro">
                        <label> Agotados </label>
                        <input checked={this.state.filtro3 === "3"} type="radio" className="radioBubook" name="filtro3" value="3" onChange={()=>this.changeFiltros("3",'filtro3')} />
                      </div>

                      <div className="opFiltro">
                        <label> Sin control de stock </label>
                        <input checked={this.state.filtro3 === "4"} type="radio" className="radioBubook" name="filtro3" value="4" onChange={()=>this.changeFiltros("4",'filtro3')} />
                      </div>

                    </div>

                    <div className="bloqueFiltro" >
                      <label className="filTle" > Categorías </label>
                      <div className="inputs-group">
                          <input className="inputs-input" id="catsReadOnly" type="text" defaultValue={'Todas'} readOnly/>
                          <Icon type="down" className="iconcion" />
                          <div className="divFlotante" >
                            <div className="sucuFlotante">
                              
                              <Checkbox id='check_check_' style={{marginLeft:'10px'}} className="checkInFilter" onChange={(e) => this.estasCategorias(e,'Todas')} defaultChecked={true} >
                                Todas
                              </Checkbox>
                              {/*
                                <input type="checkbox" id='check_check_' onChange={(e) => this.estasCategorias(e,'Todas')} defaultChecked={true} />
                                <label htmlFor='check_check_' > Todas </label>
                              */}
                            </div>
                          {
                            this.state.catalogoDeCategorias.map((categoria,indice)=>{
                              return(
                                <div key={'indice_'+indice} className="sucuFlotante">
                                  <Checkbox style={{marginLeft:'10px'}} id={'check_'+categoria.ID} className="checkInFilter" onChange={(e) => this.estasCategorias(e,categoria.DESCRIPCION)}  >
                                    {categoria.DESCRIPCION}
                                  </Checkbox>
                                  {/*
                                    <input type="checkbox" id={'check_'+categoria.ID} onChange={(e) => this.estasCategorias(e,categoria.DESCRIPCION)} />
                                    <label htmlFor={'check_'+categoria.ID} > {categoria.DESCRIPCION} </label>
                                  */}
                                 </div>
                              )
                            })
                          }
                          </div>
                      </div>
                    <div className="pState">
                      <label className=""> Estatus del producto </label>
                      
                      <div className="myCheckProd">

                        <Checkbox checked={this.state.filtro00} id={'HabilitadosC'} className="checkInFilter"  onChange={this.changeFiltros2}>
                          Activos
                        </Checkbox>

                        {/*
                          <input style={{marginLeft:'0px'}} checked={this.state.filtro00} type="checkbox" id={'HabilitadosC'} onChange={this.changeFiltros2 } />
                          <label htmlFor='HabilitadosC' > Activos </label>
                        */}
                      </div>

                      <div className="myCheckProd">
                        <Checkbox checked={this.state.filtro01} id={'DesabilitadosC'} className="checkInFilter"  onChange={this.changeFiltros2}>
                          Inactivos
                        </Checkbox>
                        {
                          /*
                            <input style={{marginLeft:'0px'}} checked={this.state.filtro01} type="checkbox" id={'DesabilitadosC'} onChange={this.changeFiltros2} />
                            <label htmlFor='DesabilitadosC' > Inactivos </label>
                          */
                        }
                      </div>

                    </div>

                    <div className="cleanDiv">
                      <button className="cleanBut" onClick={this.borrarFiltros} > Borrar filtros </button>
                    </div>


                    </div>

                  </div>

                </div>
              </div>

            </div>

            {
              this.state.categoriasByNivel.map((categoria,indice) => {
                if(!this.state.productosFiltrados.some(p => p.CATEGORIA === categoria.DESCRIPCION))return(<React.Fragment key={indice}/>)
                else
                return(
                  <React.Fragment key={'cat_filter'+indice}>
                    <div className="cont-instancias2">
                      <label className="nombreCate" > <span className="nombreCateint"> {categoria.DESCRIPCION} </span> </label>
                      <div className="cont-instancias">
                      {
                        this.state.productosFiltrados.filter(p => p.CATEGORIA === categoria.DESCRIPCION).sort((p1,p2) => p1.ID - p2.ID).map(
                          (producto,indicep) => {
                            return(
                              <div key={'prod_'+producto.ID}  id={'prod_'+producto.ID} className="tarjetaProducto2"
                              onClick={(e)=>this.subClick(e,producto.ID)}
                              >

                              <span className="options" onClick={e => e.stopPropagation() } >
                                  <Popover
                                    title="Opciones del producto"
                                    forceRender={true}
                                    id={'getAbuelo_'+producto.ID}
                                    trigger={['click','hover']}
                                      content={
                                        <div className="op-Suc">
                                          <label  onClick={()=>this.prove(producto.ID)} > Actualizar datos </label>
                                          <label onClick={(e)=>this.showModalConfirm(producto,1,'¿Desea duplicar el producto '+ producto.NOMBRE +'?',e)}> Duplicar </label>
                                          {
                                            producto.ESTATUS === '1' ? 
                                            <label onClick={(e)=>this.showModalConfirm(producto,2,'¿Desea desactivar el producto '+ producto.NOMBRE +'?',e)}> Desactivar </label>:
                                            <label onClick={(e)=>this.showModalConfirm(producto,4,'¿Desea activar el producto '+ producto.NOMBRE +'?',e)}> Activar </label>
                                          }
                                          <label onClick={(e)=>this.showModalConfirm(producto,3,'¿Desea eliminar el producto '+ producto.NOMBRE +'?',e)}> Eliminar </label>
                                        </div>
                                      }
                                      overlayClassName="pop-Suc"
                                      placement="top"
                                    >
                                    <Icon type="setting" id={'tigreId_'+producto.ID}  style={{fontSize:'20px'}} onClick={e => e.stopPropagation() }  />
                                  </Popover>
                                </span>
                                
                                <div className="metaBodyEx">
                                  <div className="metaBanner">
                                    <img
                                      src={producto.IMAGEN}
                                      className={producto.IMAGEN.includes('new_camera_icon_prod')?'no':''}
                                      alt="..." />
                                  </div>
                                  
                                  <div className={'metaContStock '+this.getClassStock(producto)} title={this.getTitleStock(producto)} >
                                      <img src={this.getImgeStock(producto)} alt="..."/>
                                      { this.getLabelStock(producto)} 
                                  </div>
                                </div>

                                <div className="metaBody">

                                  <div className="metaCont">

                                    <div className="metaContLabel">
                                      <label className="metaName" title={producto.NOMBRE}> {producto.NOMBRE} </label>
                                      <MiDiv renderable class="metaDesc" content={producto.DESCRIPCION} myid={'desc_'+producto.ID} />
                                    </div>

                                  </div>

                                  <div className="FootCont">
                                    <label className="metaPrec"> {this.getLabelPrecio(producto)} </label>
                                  </div>

                                </div>

                              </div>
                            )
                          }
                        )
                      }
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
            }
            <div className="cont-instancias5"></div>
          </div>
        </div>
       </div>
    );
  }
}

export default withRouter(Lista);