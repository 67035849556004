import React from 'react';
import { Modal,Spin,Icon } from 'antd';
import   './loading.scss';
class Loading extends React.Component{
  render(){
    return(
      <Modal centered maskClosable={false} keyboard = {false} 
        visible={this.props.loading} closable={false} width={300}
        footer={null} header={null} bodyStyle={{padding:'0'}} wrapClassName="loadingBlanco"
        zIndex = {this.props.zIndex?this.props.zIndex:1000}
        >
        <div className="loadLogin">
          <label style={{color:'white'}} > Cargando </label>
          <br/>
          <Spin indicator={<Icon type="loading" style={{ fontSize: 50,color:'white'}} />} />
        </div>
      </Modal>
    )
  }
};

export default(Loading);