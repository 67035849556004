import React from 'react';

import axios from 'axios';
import 'moment/locale/es'
import moment from 'moment'
import { Globalrequest as request } from './../../../request.js';
import {Host as host ,  Img as img , ImgPanelAdmin as ImgPanel} from './../../../app.json'
import { Select, Popover, Icon, Modal, Checkbox } from 'antd';

import {userContext} from './../context'

import {withRouter} from 'react-router-dom'

import Loading from '../../../modalCargando/loading';

import   './promociones.scss';


class Lista extends React.Component{

  static contextType = userContext

  constructor(props) {
    super(props);
    this.actualizarPromociones = this.actualizarPromociones.bind(this)
    this.goToEdit = this.goToEdit.bind(this)

    this.showModalConfirm = this.showModalConfirm.bind(this)
    this.eliminar = this.eliminar.bind(this)
    this.desactivarActivar = this.desactivarActivar.bind(this)
    this.acceptConfirm = this.acceptConfirm.bind(this)

    this.actualizarSucursales2 = this.actualizarSucursales2.bind(this)

    this.changeFiltros = this.changeFiltros.bind(this)
    this.changeFiltros2 = this.changeFiltros2.bind(this)
    this.filtrar = this.filtrar.bind(this)
    this.borrarFiltros = this.borrarFiltros.bind(this)

    this.errorImgPromo = this.errorImgPromo.bind(this)

    this.state = {
      idNotificacionPorAtender:sessionStorage.getItem('idNotificacionPorAtender'),
      first:true,
      /**/
      filtro1:'',
      filtro2:'',
      filtro3:'',
      filtro4:true,
      filtro5:true,
      filtro6:0,
      /**/
      folder:sessionStorage.getItem('folder'),
      sucursales:this.props.sucursales,
      promociones:[],
      promociones2:[],
      loading:false,
      txtAlert:'',
      modalAlert:false,
      modalConfirm:false,
      txtConfirm:'',
    }
  }

  componentDidMount(){
    document.title="Mis promociones"
    let idE = sessionStorage.getItem('id')
    if(idE===null||idE===undefined)return
    let {idNotificacionPorAtender,sucursales} = this.state
    if(idNotificacionPorAtender !== 'null'){
      let data = {idN:idNotificacionPorAtender,estatus:2}
      axios.post(`${host}clientes/clientes/cambiarEstatusNotificacion`,data,request)
      sessionStorage.setItem('idNotificacionPorAtender','null')
    }
    this.actualizarPromociones()
    sessionStorage.removeItem('idPr')
    if(sucursales.length === 0){
      this.actualizarSucursales2()
    }
    /*let data = {
      lisCategorias : "[{\"DESCRIPCION\":\"Inmobiliarias\",\"ID\":\"33\",\"ID_CATEGORIA\":\"23\",\"aBoolean\":false,\"imagen\":0,\"operacion\":0},{\"DESCRIPCION\":\"Limpieza\",\"ID\":\"34\",\"ID_CATEGORIA\":\"24\",\"aBoolean\":false,\"imagen\":0,\"operacion\":0},{\"DESCRIPCION\":\"Arte y Manualidades\",\"ID\":\"0\",\"ID_CATEGORIA\":\"1\",\"aBoolean\":false,\"imagen\":0,\"operacion\":1}]",
      empresa : "{\"APELLIDO_CONTACTO\":\"luna\",\"CANT_RESENIAS\":\"0\",\"CARGO_DESEMPENIADO\":\"administrativo \",\"CATALOGO_COMPARTIDO\":\"0\",\"CATEGORIA\":\"\",\"CONTRASENA\":\"1qa\",\"CORREO_REGISTRO\":\"contacto@nibirusystem.com\",\"DIRECCION_IMG_PERFIL\":\"ImgPerfilEmpresa_11\",\"DIRECCION_IMG_PORTADA\":\"ImgPortadaEmpresa_11\",\"ESTATUS\":\"1\",\"FACEBOOK\":\"Nibiru System  \",\"FAVORITO\":\"0\",\"HORARIO\":\"09:00:00-19:00:00\",\"ID\":\"11\",\"ID_REGIMEN_FISCAL\":\"9\",\"INSTAGRAM\":\"ns.nibirusystem \",\"NOMBRE_CATEGORIA\":\"\",\"NOMBRE_COMERCIAL\":\"Nibiru System\",\"NOMBRE_CONTACTO\":\"karen \",\"NOMBRE_SOCIAL\":\"\",\"NumeroSucursales\":\"0\",\"PAGINAWEB\":\"Nibiru System\",\"PAGO_LINEA\":\"\",\"PEDIDOS_MULTIPLE_SUCURSAL\":\"2\",\"PUNTUACION\":\"0\",\"PUNTUACION_1\":\"0\",\"PUNTUACION_2\":\"0\",\"PUNTUACION_3\":\"0\",\"PUNTUACION_4\":\"0\",\"PUNTUACION_5\":\"0\",\"PUNTUACION_USER\":\"0\",\"RAZON_SOCIAL\":\"Desarrollos Tecnológicos y Estratégicos MOEM\",\"RFC\":\"DTE18115JJ74\",\"TELEFONO_CONTACTO\":\"7441364919\",\"TIPO_PAGO_COMPARTIDO\":\"2\",\"TIPO_REGIMEN\":\"1\",\"USAR_CONTROL_CITAS\":\"2\",\"USUARIO_EMPRESA\":\"losnibiruss\",\"VENTA_SIN_EXISTENCIAS\":\"2\",\"tag\":[\"Tecnología\",\"DesarrollodeSoftware\",\"App\"]}",
    }
    axios.post(`${host}movil/Fragmentproductosservicios/getpdateinformaciongeneral`,data,request).then(rt=>{  })*/
  }

  actualizarSucursales2(){
    let data = JSON.parse(sessionStorage.getItem('metadata'))
    this.props.setSucursales(data['sucursales'])
    this.setState({ sucursales : data['sucursales'] })
    this.props.editState('datos',data['datos'])
    this.props.editState('sucursales',data['sucursales'])
    this.props.editState('categorias',data['categorias'])
    this.props.editState('tags',data['tags'])
    this.props.editState('notificaciones',data['notificaciones'])
  }

  actualizarPromociones(){
    let data = {idE: sessionStorage.getItem('id') }
    this.setState({loading:true})
    axios.post(`${host}promociones/promociones/listaPromocionesEmpresa`,data,request).then(rt=>{
      let {promociones,folder} = this.state
      let promociones2 = []
      promociones = rt.data
      for(let promocion of promociones){
        let promocion2 = {}
        promocion2 = Object.assign(promocion2,promocion)
        promocion2['src'] = folder+'/Promociones/'+promocion2['DIRECCION_IMAGEN']+'.png?'+Math.random()
        
        promocion2['FECHA_INICIA2'] = moment(promocion2['FECHA_INICIA'],'YYYY-MM-DD').format('DD/MM/YYYY')
        promocion2['FECHA_TERMINO2'] = moment(promocion2['FECHA_TERMINO'],'YYYY-MM-DD').format('DD/MM/YYYY')
        
        promocion2['FECHA_INICIA3'] = moment(promocion2['FECHA_INICIA'],'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')
        promocion2['FECHA_TERMINO3'] = moment(promocion2['FECHA_TERMINO'],'YYYY-MM-DD HH:mm').format('DD/MM/YYYY HH:mm')
        promociones2['STATUS'] = (Number(promociones2['ESTATUS']) === 1)
        promociones2.push(promocion2)
      }
      this.setState({loading:false,promociones:promociones2,promociones2:promociones2})
    }).finally(()=>{
      let {first} = this.state
      if(first){
        let prefiltro = sessionStorage.getItem('prefiltro')
        if(prefiltro !== null && prefiltro !== undefined){
          sessionStorage.removeItem('prefiltro')
          prefiltro = prefiltro.split('[/**/]')
          this.changeFiltros(Number(prefiltro[1]),prefiltro[0])
          this.classToggle()
        }
        this.setState({first:false})
      }
    })
    this.borrarFiltros()
  }
  goToEdit(id){
    sessionStorage.setItem('idPr',id);
    this.props.redirectTo2('/home/promociones/editar')
  }

  subClick(e,id){
    e.stopPropagation()
    let abuelo = document.getElementById('getAbuelo_'+id).parentElement.parentElement
    if(abuelo.classList.contains('ant-popover-hidden'))
      document.getElementById('tigreId_'+id).click()
  }

  showModalConfirm(id,tipo,msj){//  1 -> eliminar || 2 -> desactivar || 3 -> eliminar || 4-> activar
    let {validCurrentUser}  = this.props
    let permisosToCheck = ['','ELIMINAR','EDITAR','','EDITAR']
    for(let suc of id['CODE'] ){
      if(!validCurrentUser(this.context,'promociones','bool',suc,permisosToCheck[tipo])){
        this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para '+permisosToCheck[tipo].toLowerCase()+' Promociones sobre todas las sucursales relacionadas con el registro actual.'})
        return
      }
    }
    this.setState({modalConfirm:true,idConfirm:id['ID'],tipoConfirm:tipo,txtConfirm:msj})
  }

  acceptConfirm(){
    let {idConfirm,tipoConfirm} = this.state
    let {valid,mood} = this.props
    if(!valid){
      if(Number(mood)===1){
        this.setState({modalConfirm:false,idConfirm:'',tipoConfirm:0,modalAlert:true,txtAlert:'No puedes acceder a esta opción porque tu cuenta está con funciones limitadas.'})
      }else{
        this.setState({modalConfirm:false,idConfirm:'',tipoConfirm:0,modalAlert:true,txtAlert:'No puedes acceder a esta opción porque debes tener una suscripción activa.'})
      }
      return
    }

    this.setState({modalConfirm:false})
    switch(tipoConfirm){
      case 1:this.eliminar(idConfirm);break;
      case 2:this.desactivarActivar(idConfirm,2);break;//desactivar
      case 4:this.desactivarActivar(idConfirm,1);break;//activar
      default:break;
    }
  }

  eliminar(id){
    let {promociones2} = this.state
    let promocion = promociones2.filter( promocion =>  promocion.ID === id );
    let data = {id:promocion[0]['ID'], idE:sessionStorage.getItem('id'),src:promocion[0]['DIRECCION_IMAGEN']}
    this.setState({loading:true})
    axios.post(`${host}promociones/promociones/eliminar`,data,request).then(rt=>{
      if(Number(rt.data)===1){
        this.setState({txtAlert:'Promoción eliminada',modalAlert:true})
        this.actualizarPromociones()
      }
      else{
        this.setState({txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde. [Código de error: SV-ER: #08-02]',modalAlert:true,loading:false})
      }
    })
  }

  desactivarActivar(id,op){
    let {promociones2} = this.state
    let promocion = promociones2.filter( promocion =>  promocion.ID === id );
    let data = promocion[0]
    if(op===1 && Number(data['TIPO_VIGENCIA']) === 1 ){
      let fin = moment(data['FECHA_TERMINO'],'YYYY-MM-DD HH:mm:ss')//2020-09-08 23:00:00
      if(moment().isAfter(fin)){
        this.setState({modalAlert:true,txtAlert:'No puedes activar una promoción vencida'})
        return
      }
    }
    data['NUEVO_ESTATUS'] = op
    this.setState({loading:true})

    axios.post(`${host}promociones/promociones/desactivarActivar`,data,request).then(rt=>{
      if(Number(rt.data)===-2){
        let status = op===1?'activada':'desactivada'
        this.setState({txtAlert:'Promoción '+status+' correctamente',modalAlert:true})
        this.actualizarPromociones()
      }
      else{
        this.setState({txtAlert:'Parece que ocurrió un error, por favor intentalo más tarde. [Código de error: SV-ER: #08-01]',modalAlert:true,loading:false})
      }
    })
  }

  changeFiltros(value,filtro){
    this.setState({ [filtro] : value})
    setTimeout(this.filtrar,.1);
  }

  changeFiltros2(e){
    let {target} = e
    if(target.id==='HabilitadosC'){
      if(target.checked)this.setState({filtro4:target.checked})
      else this.setState({filtro4:target.checked,filtro5:!target.checked})
    }
    if(target.id==='DesabilitadosC'){
      if(target.checked)this.setState({filtro5:target.checked})
      else this.setState({filtro5:target.checked,filtro4:!target.checked})
    }
    setTimeout(this.filtrar,.1);
  }

  filtrar(){
    let {filtro1,filtro2,filtro3,filtro4,filtro5, filtro6, promociones, promociones2} = this.state
    
    let filtre = (filtro4 && filtro5) ? 1 : (!filtro4 && filtro5) ? 2 : 3 ;
    promociones2 = promociones

    promociones2 = filtro1 === '' ? promociones2 : promociones2.filter(p => p.TITULO.toLocaleLowerCase().trim().includes(filtro1.trim().toLocaleLowerCase()))
    promociones2 = filtro2 === '' ? promociones2 : promociones2.filter(p => p.existenciaPropio.includes(filtro2) )
    
    switch(filtro3){
      case 1: promociones2 = promociones2.sort( (a,b)=> a.TITULO.toLocaleLowerCase().localeCompare( b.TITULO.toLocaleLowerCase() ) );break;
      case 2: promociones2 = promociones2.sort( (a,b)=> a.TITULO.toLocaleLowerCase().localeCompare( b.TITULO.toLocaleLowerCase() ) ).reverse();break;
      default:break;
    }
    switch(filtre){
      //case 1: promociones2=promociones2;break;
      case 2: promociones2 = promociones2.filter(p=> p.ESTATUS === '2'); break;
      case 3: promociones2 = promociones2.filter(p=> p.ESTATUS === '1'); break;
      default:break;
    }

    let dateTope = moment()
    let dateTope2 = moment().add(3,'days')

    switch(filtro6){
      case 0: break;
      case 1: promociones2 = promociones2.filter(p => dateTope.isSameOrBefore(p.FECHA_TERMINO,'day') ); break;
      case 2: promociones2 = promociones2.filter(p => moment(p.FECHA_TERMINO).isSameOrAfter(dateTope,'day') && moment(p.FECHA_TERMINO).isSameOrBefore(dateTope2,'day') ); break;
      case 3: promociones2 = promociones2.filter(p => dateTope.isAfter(p.FECHA_TERMINO,'day') ); break;
      default:break;
    }

    this.setState({promociones2:promociones2})
  }

  borrarFiltros(){
    this.setState({ filtro1 :'', filtro2:'',filtro3 : 0, filtro4:true, filtro5:true,filtro6:0})
    setTimeout(this.filtrar,.1);
  }
 
  classToggle(){
    document.getElementById('toggleFiltrarId').classList.toggle('toggleFiltrar2');
    document.getElementById('contControlesId').classList.toggle('contControles2');
  }

  errorImgPromo(e,p){
    e.target.src=ImgPanel+'portadalDefault.jpg'
  }

  render(){
    return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3">


          {
              this.state.promociones2.length === 0 ? 
              <div className="imgnonResult">
                <div  className="nonResultChild">
                  <img src={ImgPanel+'nonpromociones.png'} alt="..." />
                </div>
              </div>:<React.Fragment/>
            }
            
            
            <Loading loading={this.state.loading} />

            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelButtonProps={{ className:'btn btn2' , style: { width:'120px',display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalAlert   ?
                        <span className="txtAlert"> {this.state.txtAlert} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            <Modal centered title="Confirmar" maskClosable={false} keyboard = {false} 
                okText="Continuar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px',display:'inline-flex'} }}
                cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px',display:'inline-flex'} }}
                visible={this.state.modalConfirm}
                closable={false}
                onOk={this.acceptConfirm}
                onCancel={ () => this.setState({modalConfirm:false,tipoConfirm:0,txtConfirm:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                  {
                    this.state.modalConfirm   ?
                          <span className="txtAlert"> {this.state.txtConfirm} </span>:
                          <React.Fragment/>
                  }
                </div>
            </Modal>

            {
              this.props.validCurrentUser(this.context,'promociones','bool',false,'CREAR')&&
              <div className="buttomFlotanding" title="Agregar nuevo registro" onClick={()=>this.props.redirectTo2('/home/promociones/nueva')} > + </div>
            }

            <div className="tituloYControles">
              
              <div className="metaControles">
                <h3 className="tituloPanel"> Promociones y descuentos </h3>
                <span className="toggleFiltrar" id="toggleFiltrarId" onClick={this.classToggle} > Filtrar </span>
              </div>

              <div className="contControles" id="contControlesId">

                <div className="contControlesHijo" id="contControlesHijoId">

                  <div className="filtradosExtras">
                      <div className="inputs-group-inline">
                          <label className="inputs-label" > Buscar por título </label>
                          <input className="inputs-input" type="text" onChange={e => this.changeFiltros(e.target.value,'filtro1')} />
                      </div>
                      <div className="inputs-group-inline">
                        <label className="inputs-label" htmlFor="pesopd" >  Filtrar por sucursal </label>
                        <Select value={this.state.filtro2} className="inputs-input" onChange={(value) => this.changeFiltros(value,'filtro2') } >
                          {
                            this.state.sucursales.map((sucu,indice)=>{
                              return(
                                <Select.Option value={sucu.NOMBRE} key={'sucList_'+indice} > {sucu.NOMBRE } </Select.Option>
                              )
                            })
                          }
                          <Select.Option value="" > Todas las sucursales </Select.Option>
                        </Select>
                      </div>
                  </div>
                  
                  <div className="tiposFiltrados tiposFiltrados2" style={{height:'160px'}} >
                    
                    <div className="bloqueFiltro" >
                      <label className="filTle" > Ordenar por </label>
                      
                      <div className="opFiltro">
                        <label> Nombre A-Z </label>
                        <input checked={this.state.filtro3 === 1} type="radio" className="radioBubook" name="filtro1" value="1" onChange={()=>this.changeFiltros(1,'filtro3')} />
                      </div>

                      <div className="opFiltro">
                        <label> Nombre Z-A </label>
                        <input checked={this.state.filtro3 === 2} type="radio" className="radioBubook" name="filtro1" value="2" onChange={()=>this.changeFiltros(2,'filtro3')} />
                      </div>

                    </div>

                    <div className="bloqueFiltro">
                      <div className="pState">
                        <label className=""> Estatus de la promoción </label>
                        
                        <div className="myCheckProd">

                          <Checkbox checked={this.state.filtro4} id={'HabilitadosC'} className="checkInFilter"  onChange={this.changeFiltros2}>
                            Activas
                          </Checkbox>

                          {/*
                            <input style={{marginLeft:'0px'}} checked={this.state.filtro4} type="checkbox" id={'HabilitadosC'} onChange={this.changeFiltros2} />
                            <label htmlFor='HabilitadosC' > Activas </label>
                          */}
                        </div>

                        <div className="myCheckProd">

                          <Checkbox checked={this.state.filtro5} id={'DesabilitadosC'} className="checkInFilter"  onChange={this.changeFiltros2}>
                            Inactivas
                          </Checkbox>

                          {/*
                            <input style={{marginLeft:'0px'}} checked={this.state.filtro5} type="checkbox" id={'DesabilitadosC'} onChange={this.changeFiltros2} />
                            <label htmlFor='DesabilitadosC' > Inactivas </label>
                          */}
                        </div>

                      </div>
                    </div>
                    
                    <div className="bloqueFiltro">
                      <label className="filTle"> Vigencia </label>
                      <div className="inputs-group-inline">
                        <Select value={this.state.filtro6} className="inputs-input" onChange={(value) => this.changeFiltros(value,'filtro6') } >
                          <Select.Option value={1}> Vigentes </Select.Option>
                          <Select.Option value={2}> Póximas a vencer (3 días) </Select.Option>
                          <Select.Option value={3}> Vencidas </Select.Option>
                          <Select.Option value={0}> Todos </Select.Option>
                        </Select>
                      </div>

                      <div className="cleanDiv" style={{height: '50px'}} >
                        <button className="cleanBut" onClick={this.borrarFiltros} > Borrar filtros </button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>

            <div className="cont-Promociones" >
            {
              this.state.promociones2.map((promo,indice) => {
                return(
                  <div className="targetaPromocion" key={'promo_'+promo.ID} onClick={e => this.subClick(e,promo.ID)} >
                    
                    <div className="tituloPromo">
                      <div className="nombre">
                        <span className="title" >{promo.TITULO} </span>
                        <span className="options" onClick={e => e.stopPropagation() } >
                          <Popover
                            title="Opciones de la promoción"
                              
                              forceRender={true}
                              id={'getAbuelo_'+promo.ID}
                              trigger={['click','hover']}
                              content={
                                <div className="op-Suc">
                                  <label onClick={()=>this.goToEdit(promo.ID)} > Actualizar datos </label>
                                  {
                                    promo.ESTATUS === '1' ? 
                                    <label onClick={(e)=>this.showModalConfirm(promo,2,'¿Desea desactivar la promoción '+ promo.TITULO +'?',e)}> Desactivar </label>:
                                    <label onClick={(e)=>this.showModalConfirm(promo,4,'¿Desea activar la promoción '+ promo.TITULO +'?',e)}> Activar </label>
                                  }
                                  <label onClick={(e)=>this.showModalConfirm(promo,1,'¿Desea eliminar la promoción '+promo.TITULO+'?')} > Eliminar </label>
                                </div>
                              }
                              overlayClassName="pop-Suc"
                              placement="top"
                            >
                            <Icon type="setting" id={'tigreId_'+promo.ID}  style={{fontSize:'20px'}} onClick={e => e.stopPropagation() }  />
                          </Popover>
                        </span>
                      </div>
                      <div className="descri">
                        <span className="vigencia" title={promo['FECHA_INICIA3'] + ' - ' + promo['FECHA_TERMINO3']} >
                         {
                           Number(promo['TIPO_VIGENCIA'])===1&&
                           <React.Fragment> {promo['FECHA_INICIA2'] + ' - ' + promo['FECHA_TERMINO2']} </React.Fragment>
                         }
                        </span>
                         <span className={Number(promo['ESTATUS'])===1 ? 'status Activa' : 'status Inactiva'}>
                          <span> {Number(promo['ESTATUS'])===1 ? 'Activa' : 'Inactiva'} </span>
                        </span>
                      </div>
                    </div>

                    <div className="bodyPromo"
                    //onClick={()=>this.goToEdit(promo.ID)}
                    >
                      <img src={promo.src}  alt="..." onError={e => this.errorImgPromo(e,promo) } />
                    </div>

                  </div>
                )
              })
            }
            </div>

            <div className="cont-instancias5"></div>

          </div>
        </div>
       </div>
    );
  }
}
export default withRouter(Lista);