import React from 'react';
import axios from 'axios';
import { Globalrequest as request } from './../../../request.js';
import {Host as host, ImgPanelAdmin as ImgPanel} from './../../../app.json'
import {Modal,Select,DatePicker,Checkbox } from 'antd';
import {withRouter} from 'react-router-dom'
import 'moment/locale/es'
import moment from 'moment'
import Loading from '../../../modalCargando/loading';

import {userContext} from './../context'

import {formatNumber} from './../../../utilities/generalfunctions'

import   './pedidos.scss';

class Lista extends React.Component{

  static contextType = userContext

  constructor(props){
    super(props);
    
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.actualizarSucursales2 = this.actualizarSucursales2.bind(this)
    
    this.prove = this.prove.bind(this)

    this.classToggle = this.classToggle.bind(this)

    this.changeFiltros = this.changeFiltros.bind(this)
    this.changeFiltros2 = this.changeFiltros2.bind(this)
    this.filtrar = this.filtrar.bind(this)
    this.changeState = this.changeState.bind(this)
    this.borrarFiltros = this.borrarFiltros.bind(this)

    this.state = {
      idNotificacionPorAtender:sessionStorage.getItem('idNotificacionPorAtender'),
      first:true,
      /**/
      loading:false,
      modalAlert:false,
      txtAlert:'',
      sucursales:this.props.sucursales,
      usuarioEmpresa:'',
      pedidos:[],
      pedidos2:[],
      pedidosDetalle:'',
      /**/
      filtro1:0,
      filtro10:moment(),
      filtro11:moment(),
      filtro2:'',
      filtro30:true,
      filtro31:true,
      filtro4:'',
      filtro5:'',
      filtro6:'',
      /**/
    }
  }

  componentWillMount(){
    let {usuarioEmpresa} = this.state
    let data = sessionStorage.getItem('metadata')
    if(data!==null&&data!==undefined){
      data = JSON.parse(data)['datos']['NOMBRE_COMERCIAL']
      usuarioEmpresa = data
      this.setState({usuarioEmpresa})
    }
  }


  componentDidMount(){    
    document.title="Mis pedidos"
    let id = sessionStorage.getItem('id')
    if(id===null || id===undefined ){return}

    let {idNotificacionPorAtender} = this.state
    if(idNotificacionPorAtender !== 'null'){
      let data = {idN:idNotificacionPorAtender,estatus:2}
      axios.post(`${host}clientes/clientes/cambiarEstatusNotificacion`,data,request)
      sessionStorage.setItem('idNotificacionPorAtender','null')
    }
    sessionStorage.removeItem('idPed')
    this.actualizarCatalogo()
    if(this.state.sucursales.length === 0){
      this.actualizarSucursales2()
    }
  }

  actualizarSucursales2(){
    let data = JSON.parse(sessionStorage.getItem('metadata'))
    this.props.setSucursales(data['sucursales'])
    this.setState({ sucursales : data['sucursales'] })
    this.props.editState('datos',data['datos'])
    this.props.editState('sucursales',data['sucursales'])
    this.props.editState('categorias',data['categorias'])
    this.props.editState('tags',data['tags'])
    this.props.editState('notificaciones',data['notificaciones'])
  }

  actualizarCatalogo(){
    let data = {idE:sessionStorage.getItem('id'),idC:-1}
    this.setState({loading:true})
    axios.post(`${host}pedidos/pedidos/listaPedidosPorCliente`,data,request).then(rt=>{
      let pedidos = rt.data
      let pedidosDetalle = pedidos.reduce((r, a) => {
       r[a.FECHA_PEDIDO] = [...r[a.FECHA_PEDIDO] || [], a];
       return r;
      }, {});
      this.setState({loading:false,pedidos:pedidos,pedidos2:pedidos,pedidosDetalle:pedidosDetalle,prove:true})
    }).finally(()=>{
      let {first} = this.state
      if(first){
        let prefiltro = sessionStorage.getItem('prefiltro')
        if(prefiltro !== null && prefiltro !== undefined){
          sessionStorage.removeItem('prefiltro')
          prefiltro = prefiltro.split('[/**/]')
          this.changeFiltros(prefiltro[1],prefiltro[0])
          this.classToggle()
        }
        this.setState({first:false})
      }
    })
  }

  classToggle(){
    let {validCurrentUser} = this.props
    if(!validCurrentUser(this.context,'pedidos','bool',false,'EDITAR')){
      this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para atender Pedidos.'})
      return
    }
    document.getElementById('toggleFiltrarId').classList.toggle('toggleFiltrar2');
    document.getElementById('contControlesId').classList.toggle('contControles2');
  }

  formatFecha(fecha,inputFormat,outputFormat){
    let fecha2 = moment(fecha,inputFormat)
    return fecha2.format(outputFormat)
  }

  prove(id,idTipoPago){
    let {validCurrentUser} = this.props
    if(!validCurrentUser(this.context,'pedidos','bool',false,'EDITAR')){
      this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para atender Pedidos.'})
      return
    }

    if(idTipoPago.trim()===''){
      this.setState({txtAlert:'El pedido no tiene un Tipo de pago asociado.',modalAlert:true,})
      return
    }
    sessionStorage.setItem('idPed',id)
    this.props.redirectTo2('/home/pedidos/detalle')
  }
  
  changeFiltros(valor,campo){
    this.setState({[campo]:valor})
    setTimeout(this.filtrar,.1);
  }

  changeState(value,campo){
    this.setState({[campo]:value})
    setTimeout(this.filtrar,.1);
  }

  borrarFiltros(){
    this.setState({filtro1:0, filtro10:moment(),filtro11:moment(), filtro2:'', filtro30:true, filtro31:true, filtro4:'', filtro5:'',filtro6:''})
    document.getElementById('filtro4').value=""
    document.getElementById('filtro6').value=""
    setTimeout(this.filtrar,10);   
  }

  changeFiltros2(e){
    let {target} = e
    
    console.log(target.id)

    if(target.id==='HabilitadosC'){
      if(target.checked)this.setState({filtro30:target.checked})
      else this.setState({filtro30:target.checked,filtro31:!target.checked})
    }
    if(target.id==='DesabilitadosC'){
      if(target.checked)this.setState({filtro31:target.checked})
      else this.setState({filtro30:!target.checked,filtro31:target.checked})
    }
    setTimeout(this.filtrar,.1);
  }

  filtrar(){
    let {filtro1,filtro10,filtro11,filtro2,filtro30,filtro31,filtro4,filtro5,filtro6,pedidos,pedidos2} = this.state
    pedidos2 = pedidos
    let filtroFecha = moment()
    switch(filtro1){
      case 1:
      pedidos2 = pedidos2.filter(p=> moment(p.FECHA_PEDIDO,'YYYY-MM-DD').format('DD-MM-YYYY') === filtroFecha.format('DD-MM-YYYY') )
      break;
      case 2:
      filtroFecha = filtroFecha.subtract(1,'days')
      pedidos2 = pedidos2.filter(p=> moment(p.FECHA_PEDIDO,'YYYY-MM-DD').isSameOrAfter(filtroFecha,'day') )
      break;
      case 3:
      filtroFecha = filtroFecha.subtract(7,'days')
      pedidos2 = pedidos2.filter(p=> moment(p.FECHA_PEDIDO,'YYYY-MM-DD').isSameOrAfter(filtroFecha,'day') )
      break;
      case 4:
      filtroFecha = filtroFecha.subtract(30,'days')
      pedidos2 = pedidos2.filter(p=> moment(p.FECHA_PEDIDO,'YYYY-MM-DD').isSameOrAfter(filtroFecha,'day') )
      break;
      case 5:
      pedidos2 = pedidos2.filter(p=> moment(p.FECHA_PEDIDO,'YYYY-MM-DD').isSameOrAfter(filtro10,'day') &&  moment(p.FECHA_PEDIDO,'YYYY-MM-DD').isSameOrBefore(filtro11,'day'))
      break;
      default:break;
    }

    pedidos2 = filtro2 ==='' ? pedidos2 : pedidos2.filter(p => p.NOMBRE_SUCURSAL.trim().toLowerCase() === filtro2.trim().toLowerCase() )
    let filtroN = (filtro30&&filtro31) ? 1 : (filtro30&&!filtro31) ? 2 : 3;
    switch(filtroN){
      //case 1:break;
      case 2: pedidos2 = pedidos2.filter(p => Number(p.TIPO_ENTREGA) === 1);break;
      case 3: pedidos2 = pedidos2.filter(p => Number(p.TIPO_ENTREGA) === 2); break;
      default:break;
    }
    pedidos2 = filtro4 ==='' ? pedidos2 : pedidos2.filter(p => p.CLIENTE.trim().toLowerCase().includes(filtro4.trim().toLowerCase()))
    pedidos2 = filtro6 ==='' ? pedidos2 : pedidos2.filter(p => p.FOLIO.trim().toLowerCase().includes(filtro6.trim().toLowerCase()))
    pedidos2 = filtro5 ==='' ? pedidos2 : pedidos2.filter(p => p.ESTATUS.trim().toLowerCase() === filtro5.trim().toLowerCase() )

    let pedidosDetalle = pedidos2.reduce((r, a) => {
      r[a.FECHA_PEDIDO] = [...r[a.FECHA_PEDIDO] || [], a];
      return r;
     }, {});

    this.setState({pedidos2:pedidos2,pedidosDetalle:pedidosDetalle})
  }

  render(){
    return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3">

          {
              Object.keys(this.state.pedidosDetalle).length === 0 ? 
              <div className="imgnonResult">
                <div  className="nonResultChild">
                  <img src={ImgPanel+'nonpedidos.png'} alt="..." />
                </div>
              </div>:<React.Fragment/>
            }
            
            <Loading loading={this.state.loading} />


            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' }}
                cancelButtonProps={{ className:'btn btn2', style: {display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalAlert   ?
                        <span className="txtAlert"> {this.state.txtAlert} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            <div className="tituloYControles">
              
              <div className="metaControles">
                <h3 className="tituloPanel"> Ventas y pedidos </h3>
                <span className="toggleFiltrar" id="toggleFiltrarId" onClick={this.classToggle} > Filtrar </span>
              </div>

              <div className="contControles" id="contControlesId">

                <div className="contControlesHijo" id="contControlesHijoId">

                <div className="filtradosExtras">
                  <div className="inputs-group-inline">
                    <label className="inputs-label" > Cliente </label>
                    <input type="text" onChange={datos => this.changeFiltros(datos.target.value,'filtro4') } id="filtro4" className="inputs-input" style={{paddingLeft:'10px'}} />
                  </div>
                </div>
                  
                  <div className="tiposFiltrados tiposFiltrados2">
                    
                    <div className="bloqueFiltro" >
                      <label className="filTle" > Fecha </label>
                      
                      <div className="opFiltro">
                        <label> Hoy </label>
                        <input checked={this.state.filtro1 === 1} type="radio" className="radioBubook" name="filtro1" onChange={()=>this.changeFiltros(1,'filtro1')} />
                      </div>

                      <div className="opFiltro">
                        <label> Ayer </label>
                        <input checked={this.state.filtro1 === 2} type="radio" className="radioBubook" name="filtro1" onChange={()=>this.changeFiltros(2,'filtro1')} />
                      </div>

                      <div className="opFiltro">
                        <label> Esta semana </label>
                        <input checked={this.state.filtro1 === 3} type="radio" className="radioBubook" name="filtro1" onChange={()=>this.changeFiltros(3,'filtro1')} />
                      </div>

                      <div className="opFiltro">
                        <label> Este mes </label>
                        <input checked={this.state.filtro1 === 4} type="radio" className="radioBubook" name="filtro1" onChange={()=>this.changeFiltros(4,'filtro1')} />
                      </div>

                      <div className="opFiltro">
                        <label> Personalizado</label>
                        <input checked={this.state.filtro1 === 5} type="radio" className="radioBubook" name="filtro1" onChange={()=>this.changeFiltros(5,'filtro1')} />
                      </div>

                      {/* */}
                      {
                        this.state.filtro1===5 ?
                          <div className="fechaSexo">
                            <div className="inputs-group inputs-group-inline">
                              <label className="inputs-label" htmlFor="pesopd"> Fecha inicio </label>
                              <DatePicker format="DD/MM/YYYY" locale='es-MX' className="MyDate" placeholder="" value={this.state.filtro10} onChange={(date)=>this.changeState(date,'filtro10')} />
                            </div>

                            <div className="inputs-group inputs-group-inline">
                              <label className="inputs-label" htmlFor="timeprepapd" > Fecha fin </label>
                              <DatePicker format="DD/MM/YYYY" locale='es-MX' className="MyDate" placeholder="" value={this.state.filtro11} onChange={(date)=>this.changeState(date,'filtro11')} />
                            </div>
                          </div>:<React.Fragment/>
                      }
                      {/* */}


                    </div>
                    
                    <div className="bloqueFiltro">
                      
                    <div className="pState">
                      <label className=""> Sucursal </label>
                        <div className="inputs-group-inline" style={{marginTop:'16px'}} >
                        <Select value={this.state.filtro2} className="inputs-input" onChange={(value) => this.changeFiltros(value,'filtro2') } >
                          {
                            this.state.sucursales.map((sucu,indice)=>{
                              return(
                                <Select.Option value={sucu.NOMBRE} key={'sucList_'+indice} > {sucu.NOMBRE } </Select.Option>
                              )
                            })
                          }
                          <Select.Option value={''} > Todas las sucursales </Select.Option>
                        </Select>
                        </div>
                      </div>

                      <div className="pState">
                        <label className=""> Método de entrega </label>
                        <div className="myCheckProd">
                          <Checkbox checked={this.state.filtro30} id={'HabilitadosC'} className="checkInFilter"  onChange={this.changeFiltros2}>
                            Recoger en sucursal
                          </Checkbox>
                          
                          {
                            //<input checked={this.state.filtro30} type="checkbox" id={'HabilitadosC'} onChange={this.changeFiltros2} />
                          }

                        </div>

                        <div className="myCheckProd">
                          <Checkbox checked={this.state.filtro31} id={'DesabilitadosC'} className="checkInFilter"  onChange={this.changeFiltros2}>
                            Envío a domicilio
                          </Checkbox>
                          {
                            //<input checked={this.state.filtro31} type="checkbox" id={'DesabilitadosC'} onChange={this.changeFiltros2} />
                          }
                        </div>

                      </div>

                    </div>

                    <div className="bloqueFiltro" >
                    
                    <label className="filTle"> Datos de pedido </label>

                    <div className="inputs-group-inline">
                        <label className="inputs-label" > Folio </label>
                        <input type="text" onChange={e => this.changeFiltros(e.target.value,'filtro6') } id="filtro6" className="inputs-input" style={{paddingLeft:'10px'}} />
                    </div>

                    <div className="inputs-group-inline">
                      <label className="inputs-label" htmlFor="pesopd" >  Estatus </label>
                      <Select value={this.state.filtro5} className="inputs-input" onChange={(value) => this.changeFiltros(value,'filtro5') } >
                        <Select.Option value={'Pendiente'}> Pendiente </Select.Option>
                        <Select.Option value={'En proceso'}> En proceso </Select.Option>
                        <Select.Option value={'En ruta'}> En ruta </Select.Option>
                        <Select.Option value={'Entregado'}> Entregado </Select.Option>
                        <Select.Option value={'Cancelado'}> Cancelado </Select.Option>
                        <Select.Option value={''}> Todos </Select.Option>
                      </Select>
                    </div>

                      <div className="cleanDiv">
                        <button className="cleanBut" onClick={this.borrarFiltros} > Borrar filtros </button>
                      </div>

                    </div>

                  </div>
                   

                </div>
              </div>

            </div>

            <div className="cont-instancias2">
              {
                Object.keys(this.state.pedidosDetalle).map( key => 
                  <div key={'pedidosFecha_'+key} className="tarjetaPedido2" >
                    <div className="headGris">{this.formatFecha(key,'YYYY-MM-DD','DD/MMMM/YYYY')}</div>
                    <div>
                      {
                        this.state.pedidosDetalle[key].map(pedido => (
                          <div key={'pedido_'+pedido.ID} className="detallePedido"  onClick={() => this.prove(pedido.ID,pedido.ID_TIPO_PAGO)} >
                            
                            <div className="info infoInline">
                              <div className="infoInlineChild"> <div className="meta">Folio: </div>  <div className="content">{pedido['FOLIO']}</div></div>
                              <div className="infoInlineChild"> <div className="meta">Sucursal: </div>  <div className="content">{pedido['NOMBRE_SUCURSAL']}</div></div>
                               <div className="infoInlineChild flexcenter Fend">
                                <div className={"content "+pedido['ESTATUS'].toLowerCase().split(' ').join('')}> {pedido['ESTATUS']} </div>
                              </div>
                            </div>

                            <div className="info infoInline">
                              <div className="infoInlineChild"> <div className="meta">Hora pedido: </div>  <div className="content"> {pedido['HORA_PEDIDO']} </div></div>
                              <div className="infoInlineChild"> <div className="meta">Cliente: </div>  <div className="content">{pedido['CLIENTE']}</div> </div>
                              <div className="infoInlineChild flexcenter Fend"> <div className="meta">Total: </div>  <div className="content">${ formatNumber(pedido['TOTAL'],2) } MXN</div></div>
                            </div>

                           {
                              (pedido['ESTATUS'].trim().toLowerCase() === 'entregado' ||pedido['ESTATUS'].trim().toLowerCase() === 'cancelado')&&
                              <div className="info infoInline">
                                
                                <div className="infoInlineChild">
                                  <div className="meta">Atendió: </div>
                                  <div className="content">{ pedido['ATENDIDO_POR'].trim() !== '' ? pedido['ATENDIDO_POR'].trim() : this.state.usuarioEmpresa }</div>
                                </div>

                                <div className="infoInlineChild"> <div className="meta">Hora atendió: </div>  <div className="content">{this.formatFecha(pedido['HORA_ATENDIDO'],'YYYY-MM-DD HH:mm:ss','DD/MM/YYYY HH:mm:ss')}</div> </div>

                                <div className="infoInlineChild"> <div className="meta"/>  <div className="content"/> </div>

                              </div>
                           }
                          </div>
                        ))
                      }
                    </div>
                  </div>
                )
              }
            </div>

            <div className="cont-instancias5"></div>
          </div>
        </div>
       </div>
    );
  }
}

export default withRouter(Lista);