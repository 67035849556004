import React,{ useState , useEffect } from 'react';
import { Host,ImgGral as img2 ,ImgPanelAdmin as imgPanel, Img as img,stripeKey, gmapsKey,bancos} from '../../app.json';
import { Modal,Input,Icon,Select,Popover,DatePicker,TimePicker,Tooltip} from 'antd';

import { Globalrequest as request } from './../../request.js';
import axios from 'axios';
import moment from 'moment'
import Loading from "./../../modalCargando/loading";
import GoogleMapReact from 'google-map-react';

import { loadStripe } from '@stripe/stripe-js';

import {ConPromocion} from "./../../utilities/svg";

import {formatNumber,getImageBanco,formatDatosCard} from './../../utilities/generalfunctions'

const Mark = props => {

  return(
    <div className="markada" id={props.id} >
      <Tooltip title={props.text} getTooltipContainer={() => document.querySelector("#"+props.id)} overlayClassName='tooltipMark' visible={props.visible} />
    </div>
  )
}

/*const Handlercantidad = (props) => {

  const [cantidad,setCantidad] = useState(props.cantidadProducto)

  const changeCantidad = (op,indexProducto) => {
    setCantidad(cantidad+op)
    props.changeCantidad(op,indexProducto)
  }

  return(
    <span className="precio flexcenter Fcenter">

      <div className="controls" >
        <label onClick={()=>changeCantidad(-1,props.indexProducto)} > - </label>
        <label className="cantidad"> {cantidad} </label>
        <label onClick={()=>changeCantidad(1,props.indexProducto)} > + </label>
      </div>

      <span className="content">
        ${formatNumber(props.precioProducto * cantidad,2)} MXN
      </span>

    </span>
  )
}*/

const AddComentario = (props) => {

  const close = () =>{
    props.setComentario(document.getElementById('cmtario').value.trim())
    props.handler(false)
  }

  return(
    <Modal
      centered closable={false} footer={null} title={null}
      visible={props.visible} destroyOnClose width={380}
      className="modalBaseBuBooker">
      <div className="coberturasSelector">

        <div className="head">
          <h3 id="titletletle"> Agregar comentario </h3>
          
          <Icon type="close" className="iconCloseModal" onClick={()=>props.handler(false)} />

        </div>

        <div className="inputs-group" >
          <label className="inputs-label" > Comentario </label>
          <Input.TextArea id="cmtario" defaultValue={props.preValue} className="inputs-input" autoSize={{minRows:1,maxRows:4}} autoFocus />
        </div>

        <div style={{marginTop:'10px'}}  className="flexcenter Fend">
          <button className="btn btn1" onClick={close} style={{width:'95%'}} > Continuar </button>          
        </div>

      </div>

    </Modal>
  )
}
const EntregaProgramada = (props) => {

  const [fecha,setFecha] = useState('')
  const [valid,setValid] = useState(false)

  const [horaDeFecha,setHoraDeFecha] = useState('')

  const changeFecha = (fecha) =>{
    setFecha(fecha)
    setValid(true)
    props.showHideElemento('ntaErrore','none','')
  }
  
  const changeHora = (hora) => {
    props.showHideElemento('ntaErrore','none','')
    setHoraDeFecha(hora)
  }

  const validInternalData = () => {
    /*FECHA*/
    let {listaHorariosSucursal,tipoEntrega,indiceCobertura,listCoberturas,getEstatusSucursal} = props
    let dia = fecha.day()
    dia = dia===0?7:dia

    let horarioFecha = tipoEntrega===0 ?
      listaHorariosSucursal.filter(h => Number(h['dia'])===dia && Number(h['estatus'])===1 ) ://recoger en sucursal
      listCoberturas[indiceCobertura]['HORARIOS_ATENCION'].filter(h => Number(h['dia'])===dia && Number(h['estatus'])===1 )//a domicilio

    let msjToShow = tipoEntrega===0 ?
      'La sucursal no se encuentra disponible en el horario seleccionado.'://recoger en sucursal
      'El servicio a domicilio no se encuentra disponible en el horario seleccionado.'//a domicilio
                                //momento en que la sucursal cierra en la fecha elegida
    if( horarioFecha.length > 0 ){
      horarioFecha = horarioFecha[0]
      let fechaToeval = moment(horaDeFecha.format('HH:mm') + fecha.format('DD-MM-YYYY') ,'HH:mm DD-MM-YYYY')

      if( fechaToeval.isSameOrAfter(moment(),'day') &&  getEstatusSucursal(horarioFecha['atencion'],horaDeFecha).trim().toLowerCase()==="abierto"){
        props.showHideElemento('ntaErrore','flex','')
        props.setDataProgramada(true,true,fecha,horaDeFecha)
      }
      else//si abre ese dia pero no a esa hora
        props.showHideElemento('ntaErrore','flex',msjToShow)
    }
    else
      props.showHideElemento('ntaErrore','flex',msjToShow)//no abre ese dia
  }

  useEffect(()=>{
    if(props.visible){
      if(document.getElementById('triggerpope')!==null && document.getElementById('triggerpope2')!==null)
        document.getElementById('triggerpope2').click()
      else if(document.getElementById('triggerpope')!==null)
        document.getElementById('triggerpope').click()
    }    
  },[props.visible])


  return(
    <Modal
      centered closable={false} footer={null} title={null}
      visible={props.visible} destroyOnClose width={380}
      className="modalBaseBuBooker">
      <div className="coberturasSelector">

        <div className="head">
          <h3 id="titletletle"> Configura la entrega de tu pedido </h3>
          
          <Icon type="close" className="iconCloseModal" onClick={()=>props.handler(false)} />

        </div>

        <div className="inputs-group" >
          <label className="inputs-label" > Especifica la fecha </label>
          {
            props.defaultFecha===''?
            <DatePicker placeholder="" className="inputs-input"
              disabledDate= { d => d.isBefore(moment().subtract(1,'days')) }
              allowClear={false} showToday={false} 
              format="DD / MM / YYYY" locale='es-MX' onChange={changeFecha}  />
            :
            <DatePicker placeholder="" className="inputs-input"
              disabledDate= { d => d.isBefore(moment().subtract(1,'days')) }
              allowClear={false} showToday={false} defaultValue={props.defaultFecha}
              format="DD / MM / YYYY" locale='es-MX' onChange={changeFecha}  />
          }
        </div>

        {
          valid&&
          <div className="inputs-group" >
            <label className="inputs-label" > Especifica la hora </label>
            {
              props.defaultHora===''?
              <TimePicker placeholder="" className="inputs-input"
              allowClear={false} format={'HH:mm'} onChange={changeHora} />
              :
              <TimePicker placeholder="" className="inputs-input" defaultValue={props.defaultHora}
              allowClear={false} format={'HH:mm'} onChange={changeHora} />
            }
          </div>
        }

        <span id="ntaErrore" className="ntaError flexcenter Fcenter" style={{display:'none',maxWidth:'330px',textAlign:'center'}}>  </span>

        <div style={{paddingRight:'15px',marginTop:'10px'}}  className="flexcenter Fend">
          <button className="btn btn1" onClick={validInternalData} > Continuar </button>          
        </div>

      </div>

    </Modal>
  )
}
const ListaCoberturas = (props) => {

  const [API,setAPI] = useState({})
  const [map,setMap] = useState({})
  const [mapCenter,setMapCenter] = useState({lat: 16.859166 , lng: -99.872328,})
  const [mark,setMark] = useState({})

  const [indexDireccion,setIndexDireccion] = useState(-1)
  const [indexCobertura,setIndexCobertura] = useState(-1)

  const paintMark = (direccion,map,API) => {
    let mark = new API.Marker({
      position:{lat: Number(direccion['LATITUD']) , lng: Number(direccion['ALTITUD']) ,},
      title:direccion['NOMBRE'],
      label:direccion['NOMBRE'],
    })
    mark.setMap(map)
    return mark
  }

  const validDirection = (mark,listaCoberturas,API,map,paintCircles=false) => {//retorna el indice de la cobertura a la que aplica
    let aplica = -1
    let i=-1;
    for(let item of listaCoberturas){
      i++
      let circle = new API.Circle({
        strokeColor: '#00FF00',
        strokeOpacity: 0.5,
        strokeWeight: 2,
        fillColor: '#005500',
        fillOpacity: 0.3,
        center: {lat:Number(item['LATITUD']),lng:Number(item['LONGITUD'])} ,
        radius:Number(item['COBERTURA_ENVIO'])*1000,
      })
      if(paintCircles)
        circle.setMap(map)
      if( aplica<0 && API['geometry']['spherical'].computeDistanceBetween(circle.getCenter(), mark.getPosition()) < circle.getRadius() )
        aplica=i
    }
    return aplica
  }

  const apiLoadEnd = ({map, maps}) => {    
    let mark = paintMark(props.listDirecciones[0],map,maps)
    setMark(mark)
    let valida = validDirection(mark,props.listCoberturas,maps,map,true)
    setAPI(maps)
    setMap(map)
    setMapCenter({lng:mark.getPosition().lng(),lat:mark.getPosition().lat()})
    if(valida<0)
      props.showHideElemento('ntaError','flex','Dirección fuera de la zona de cobertura')
    else
      props.showHideElemento('ntaError','flex','')
    
    setIndexDireccion(0)
    setIndexCobertura(valida)
  }

  const changeIndexDireccion = (index) => {
    let {listDirecciones,listCoberturas,showHideElemento} = props

    mark.setMap(null)
    let mark2 = paintMark(listDirecciones[index],map,API)
    setMark(mark2)
    setMapCenter({lng:mark2.getPosition().lng(),lat:mark2.getPosition().lat()})
    
    let valida = validDirection(mark2,listCoberturas,API,map)

    if(valida<0){
      showHideElemento('ntaError','flex','Dirección fuera de la zona de cobertura')
      document.getElementById('ntaError').classList.toggle('free',false)
      document.getElementById('ntaError').classList.toggle('noerror',false)
    }
    else{
      let costo = listCoberturas[valida]['COSTO_ENVIO'],clase=''
      if(Number(costo)===0){
        clase = 'free'
        costo = "Envío Gratis"
      }else{
        clase = 'noerror'
        costo = "Costo de envío $"+formatNumber(costo,2)+' MXN'
      }
      showHideElemento('ntaError','flex',costo)
      document.getElementById('ntaError').classList.toggle(clase,true)
    }

    setIndexDireccion(index)
    setIndexCobertura(valida)
    
  }

  useEffect(()=>{
    if(props.visible){
      if(document.getElementById('triggerpop')!==null)
        document.getElementById('triggerpop').click()
    }    
  },[props.visible])

  /*useEffect(()=>{
    return () =>  {}
  },[])*/

  return(
    <Modal
      centered closable={false} footer={null} title={null}
      visible={props.visible}
      className="modalBaseBuBooker">
      <div className="coberturasSelector">

        <div className="head">
          <h3 id="titletletle" >Selecciona una dirección</h3>
          
          <Icon type="close" className="iconCloseModal" onClick={()=>props.handler(false)} />

        </div>

        <div className="inputs-group" >
          
          <label className="inputs-label" > Elige una dirección </label>

          <Select className="inputs-input" value={indexDireccion} onChange={changeIndexDireccion} autoFocus >
          {
            props.listDirecciones.map((s,i)=>(
              <Select.Option value={i} key={i+':provider'} > {s['NOMBRE']} </Select.Option>
            ))
          }
          </Select>

        </div>

        <span id="ntaError" className="ntaError flexcenter Fcenter" style={{display:'none',maxWidth:'400px',textAlign:'center'}}>  </span>

        <div className="contentMap" >
        <GoogleMapReact
          bootstrapURLKeys={{key: gmapsKey,libraries:'geometry'}}
          draggable={true}
          center={mapCenter}
          defaultZoom={15}
          yesIWantToUseGoogleMapApiInternals={true}
          onGoogleApiLoaded={apiLoadEnd}
          >
          {
            mark.map && 
            <Mark
              id={'mrk_id'}
              lat={mark.getPosition().lat()}
              lng={mark.getPosition().lng()}
              text={mark['title']}
              visible  />
          }

        </GoogleMapReact>
        </div>

        <div style={{paddingRight:'15px',marginTop:'10px'}}  className="flexcenter Fend">
          <button className="btn btn1" onClick={()=>props.setDireccionCobertura(indexDireccion,indexCobertura)} > Continuar </button>          
        </div>

      </div>

    </Modal>
  )
}

const FilaPaquete = (props) => {

  const getPrecioHijo = (hijo,tipo) => { //tipo === 1 ? 'padre' : 'hijo'
    const {producto} = props
    if(producto['tipoPromo'] === 4 && tipo === 2){
      if( (hijo['precio'] * hijo['cantidad']) - hijo['descuentoEnPesos']===0){
        return 'Gratis'
      }else{
        return '$'+formatNumber( (hijo['precio'] * hijo['cantidad']) - hijo['descuentoEnPesos'] ,2)+' MXN'
      }
    }else{
      if(hijo['precio']-hijo['descuentoEnPesos']===0){
        return 'Gratis'
      }else{
        return '$'+formatNumber(hijo['cantidad']*(hijo['precio'] - hijo['descuentoEnPesos']),2)+' MXN'
      }
    }
  }

  const validPromocion = (result) => {
    return result === 2 || result === 11 || result === 12
  }


  const goToDetalle = () => {
    const {continuarComprando,producto,getEstatusProducto} = props
    let estatus = getEstatusProducto(props.producto)
    let promocionInvalida = validPromocion(estatus)

    if(estatus === 1){ // productos eliminados, ya sea en solitario o padres de una promoción.
      let msj = 'Parece que el producto ya no está disponible. ¿Deseas eliminarlo del carrito?'

      if(producto['tipoPromo']>1){
        msj = 'Parece que el producto principal de esta promoción fue eliminado por la empresa. ¿Deseas eliminarlo del carrito?.'
      }
      eliminar(false,msj)
      return
    }

    let tipoPromo = Number(producto['tipoPromo'])
    let preopen = {
      key: producto['key'],
      metakey:producto['metakey'],
      cantidad:producto['cantidad'],
      comentario:producto['comentario'],
      /**/
      tipoPromo:tipoPromo,
      keypromo: tipoPromo>1 ? producto['dependenciaPromocion'] : '-1' ,
      promo: tipoPromo>1 ? producto['dependenciaPromocionMETA'] : '-1' ,
      detalle: tipoPromo>1 ? producto['detallePromocionMETA'] : -1,
      promocionInvalida,
      hijoMetaKey: producto['hijo'].hasOwnProperty('metakey') ? producto['hijo']['metakey'] : -1,
      hijo:{},
    }

    if( producto['hijo'].hasOwnProperty('metakey') && !promocionInvalida ){
      preopen['hijo']['metakey'] = producto['hijo']['metakey']
      preopen['hijo']['cantidad'] = producto['hijo']['cantidad']
      preopen['hijo']['detallePromo'] = producto['hijo']['detallePromocionMETA']
      
      preopen['hijo']['detallePromo'] = producto['hijo']['detallePromocionMETA']
      preopen['hijo']['key'] = producto['hijo']['key']
    }

    /*if(promocionInvalida){//(producto,e,_force=false,msj=false)
      const {eliminar} = props
      eliminar(producto,false,true)
    }*/

    sessionStorage.setItem('productoFromCarrito', JSON.stringify(preopen) )
    continuarComprando()
  }

  const eliminar = (e,msg=false) => {
    const {eliminar,producto} = props
    eliminar(producto,e,false,msg)
  }


  return(
    <React.Fragment>
      <div className={"itemPaquetitoCarrito flexcenter Fspaceb" + (props.mini? ' mini' : '') + (props.producto['hijo'].hasOwnProperty('key') ? '' : ' endRow') }
        title={
           props.producto['cambioDetected'] ? 'Producto actualizado por la empresa':
          props.producto['tipoPromo']===2?'Producto con descuento aplicado por promoción':''
        } onClick={goToDetalle} >
        <div className="infoPack flexcenter Fstart">
          <div className="cnt" > {props.producto['cantidad']}x </div>
          <div className="midler">
            <div className="nbr flexcenter Fstart" >
              { props.producto['cambioDetected']&& <Icon type="info-circle" className="deleted" /> }
              {props.producto['nombre']}
              { (props.producto['tipoPromo']===2&&!props.mini) && <i> <ConPromocion /> </i> }
            </div>
            {
              props.producto['comentario'].trim()!==''&&
              <div className="cmtr"> {props.producto['comentario']} </div>
            }
          </div>
        </div>

        <React.Fragment>

        <div className="precio flexbottom Fcenter tgh">
        {
          props.producto['tipoPromo']===2&&
          <div className="oldnormal">${formatNumber(props.producto['cantidad']*(props.producto['precio']),2)} MXN</div>
        }
          <div className="normal"> {getPrecioHijo(props.producto,1)} </div>
        </div>
        {
          !props.mini&&
          <div className="opelim flexcenter Fcenter ">
            <img className={"basurero "+ (props.producto['hijo'].hasOwnProperty('key')?'moved':'')} src={img+'basurero_gris.png'} alt="..." title='Eliminar' onClick={eliminar} />
          </div>
        }
        </React.Fragment>

      </div>
      
      {
        props.producto['hijo'].hasOwnProperty('key') &&
        <div className={"itemPaquetitoCarrito hijo endRow flexcenter Fspaceb" + (props.mini? ' mini' : '')}
          title={
            props.producto['cambioDetected'] ? 'Producto actualizado por la empresa':
            'Producto con descuento aplicado por promoción'
          } onClick={goToDetalle} >
          <div className="infoPack flexcenter Fstart">
            <div className="cnt" > {props.producto['hijo']['cantidad']}x </div>
            
            <div className="midler">
              <div className="flexcenter Fstart nbr">
                {props.producto['hijo']['nombre']} 
                { !props.mini&&<i> <ConPromocion /> </i> }
              </div>
            </div>

          </div>

          <div className="precio flexbottom Fcenter tgh">
            <div className="oldnormal">${formatNumber(props.producto['hijo']['cantidad']*(props.producto['hijo']['precio']),2)} MXN</div>
            <div className="normal"> {getPrecioHijo(props.producto['hijo'],2)} </div>
          </div>
        </div>
      }

    </React.Fragment>
  )
}

const ListaDatosDeTransferencia = (props) =>{
  
  const guardarDatosTransferencia = () =>{
    let {showHideElemento,setDatoTransferencia,handler,setDatosTarjetaElegida,listDatosTransferencia,setFormaPago} = props
    if(indice===-1)
      showHideElemento('ntaErrorDt','flex','Debe elegir un dato de transferencia')
    else{

      const itemActual = listDatosTransferencia[indice]
      const datosTarjetaElegida = {
        src: getImageBanco(Number(itemActual['BANCO']) ),
        nombreBanco: bancos[Number(itemActual['BANCO'])-1],
        titular: itemActual['NOMBRE_TITULAR'],
        cuenta: formatDatosCard(itemActual['NUM_CUENTA'],1),
        tarjeta: formatDatosCard(itemActual['NUM_TARJETA'],2),
        clabe: formatDatosCard(itemActual['CUENTA_CLAVE'],3),
      }
      setDatosTarjetaElegida(datosTarjetaElegida)

      showHideElemento('ntaErrorDt','none','')
      setDatoTransferencia(indice)
      setFormaPago(4)
      handler(false)
    }
  }

  const [indice,setIndice] = useState(-1)

  useEffect(()=>{
    if(props.visible){
      if(document.getElementById('triggerpop')!==null)
        document.getElementById('triggerpopdd').click()
    }    
  },[props.visible])

  return(
    <Modal
      centered closable={false} footer={null} title={null}
      visible={props.visible} destroyOnClose width={380}
      className="modalBaseBuBooker">
      <div className="coberturasSelector">

        <div className="head">
          <h3 id="titletletle"> Selecciona una opción </h3>
          <Icon type="close" className="iconCloseModal" onClick={()=>props.handler(false)} />
        </div>

        <div className="listTarjetas">
        {
          props.listDatosTransferencia.map((card,index) => (
            <div className="itemCard flexcenter Fstart" key={'card_'+index} onClick={()=>setIndice(index)} >
              <div className={indice===index?'radioFake selected':'radioFake'} />
              <div className="data flexcenter Fspaceb">
                <span> {bancos[Number(card['BANCO'])-1]} </span>
                <div className="experimental">
                  <img src={getImageBanco(Number(card['BANCO']))} alt="..." />
                </div>
              </div>
            </div>
          ))
        }
        </div>

        <span id="ntaErrorDt" className="ntaError flexcenter Fcenter" style={{display:'none'}}>  </span>

        <div style={{marginTop:'10px'}}  className="flexcenter Fcenter">
          <button className="btn btn1" onClick={guardarDatosTransferencia} style={{width:'95%'}} > Continuar </button>          
        </div>

      </div>

    </Modal>
  )
}

const MensajeWithAction = (props) => {
  return(
    <Modal centered title=""
      closable={false}
      header={null}
      footer={null} visible={props.visible}
      className="nodalRegistroStripe"
      width={420}
      wrapClassName="nodalRegistroStripePapa">
      <div className="detalleProducto contentstripe noscroll">

        <div className="contentclose withPPE flexcenter Fspaceb">
          <div className="title"> {props.titulo}  </div>
          {
            props.withClose&&
            <Icon type="close" className="iconCloseModal" onClick={props.onClose} />
          }
        </div>

        <div className="info mgtopNeg" style={{marginTop:'10px'}} >
          {props.msj}
        </div>

        <div className="flexcenter Fend">
          {
            props.withCancel &&
            <div className="btnAdd adpromo nosee flexcenter Fcenter" onClick={props.onCancel} title="Continuar" style={{marginRight:'15px',padding:'6px'}} > {props.cancelText} </div>
          }
          <div className="btnAdd adpromo flexcenter Fcenter" onClick={props.onOk} title="Ver promociones disponibles" style={{padding:'8px 12px'}} > {props.okText} </div>
        </div>

      </div>
    </Modal>
  )
}

const CarritoModal = (props) => {
  
  let metadata = JSON.parse(sessionStorage.getItem('metadata'))['datos']
  let carrito = JSON.parse(localStorage.getItem('carrito'))||{}
  let indexB = 'Usuario['+metadata['METAID']+']'
  carrito = carrito[indexB]||{ id: metadata['id'], EMPRESAS:{}, }

  const [loading,setLoading] = useState(false)

  const [title,setTitle] = useState('Mi carrito')
  const [footer,setFooter] = useState(0)

  const [listaEmpresas,setListaEmpresas] = useState(carrito['EMPRESAS'])
  const [indexEmpresaActual,setIndexEmpresaActual] = useState(-1)
  const [indexSucursalActual,setIndexSucursalActual] = useState(-1)  

  const [totalSucursal,setTotalSucursal] = useState(0)
  const [estatusSucursal,setEstatusSucursal] = useState('')
  const [listaHorariosSucursal,setListaHorariosSucursal] = useState('')

  const [totalPedido,setTotalPedido] = useState(0)
  const [descuentoPedido,setDescuentoPedido] = useState(0)

  const [listaProductos,setListaProductos] = useState({})

  const [listaCambiosProductos,setListaCambiosProductos] = useState({})

  const [listaInforProductos,setListaInforProductos] = useState({})
  
  //const [srcBase,setSrcBase] = useState('')
  const [nivel,setNivel] = useState(1)  

  /**/
  const [tipoEntrega,setTipoEntrega] = useState(0)
  const [costoEntrega,setCostoEntrega] = useState('N/A')
  const [listaTiposEntrega,setListaTiposEntrega] = useState(['Recoger en sucursal'])
  
  const [listaCoberturas,setListaCoberturas] = useState([])
  const [indiceCobertura,setIndiceCobertura] = useState(-1)

  const [listaDirecciones,setListaDirecciones] = useState([])
  const [indiceDireccion,setIndiceDireccion] = useState(-1)

  /**/
  const listaFormasEntrega = ['Inmediata' ,'Programada']
  const [formaEntrega,setFormaEntrega] = useState(0)

  const [listaFormasDePago,setListaFormasDePago] = useState([[],[]])
  const [formaPago,setFormaPago] = useState(0)

  const [listaDatosTransferencia,setListaDatosTransferencia] = useState([])
  const [datoTransferencia,setDatoTransferencia] = useState(-1)
  const [showListaDatosTranferencia,setShowListaDatosTranferencia] = useState(false)
  
  const [datosTarjetaElegida,setDatosTarjetaElegida] = useState('none')
  /**/
  const [listaPagoEstripe,setListaPagoEstripe] = useState([])
  
  /**/
  const [showCoberturas,setShowCoberturas] = useState(false)
  const [showProgramada,setShowProgramada] = useState(false)
  /**/
  const [hora,setHora] = useState('')//useState(moment('12:00:00','HH:mm:ss'))
  const [fecha,setFecha] = useState('')  //useState(moment())  
  /**/
  const [showComentario,setShowComentario] = useState(false)
  const [comentario,setComentario] = useState('')
  /**/
  const [referenciaPago,setReferenciaPago] = useState('')  
  /**/

  const [domicilioSucursal,setDomicilioSucursal] = useState('')

  const [minimoForDomicilio,setMinimoForDomicilio] = useState(0)

  /**/
  const [timer,setTimer] = useState('')
  /**/
  const [cambioDetectado,setCambioDetectado] = useState(false)

  const [keyEliminando,setKeyEliminando] = useState(-1)
  const [subKeyEliminando,setSubKeyEliminando] = useState(-1)
  const [keyPromoEliminando,setKeyPromoEliminando] = useState(-1)//dependenciaPromocionMETA

  const srcPerfil = folder => {
    let index = folder.substring( folder.indexOf('[')+1,folder.indexOf(']'))
    return folder+'ImgPerfilEmpresa_'+index+'.png'
  }

  const validClick = () => {
    if(nivel===1){ props.closeSelf() }
    if(nivel===2){
      setTitle('Mi carrito')
      setFooter(getTotalCarrito(listaEmpresas))
      setNivel(1)
    }
  }

  /*const changeCanidad = (op,indexProducto) => {
    setTotalSucursal(totalSucursal+Number(listaProductos[indexProducto]['precio']))
    listaProductos[indexProducto]['cantidad'] += op
    carrito['EMPRESAS'][indexEmpresaActual]['SUCURSALES'][indexSucursalActual]['PRODUCTOS'][indexProducto]['cantidad'] += op
  }*/

  /**/
  const getTotalCarrito = (listaEmpresas) => {
    return Object.values(listaEmpresas).map(empresa => getTotalEmpresa(empresa['SUCURSALES'])).reduce((a,b)=>a+b,0) ;
  }
  /**/

  const getTotalEmpresa = (listaSucursales) => {
    return Object.values(listaSucursales).map(sucursal => getTotalSucursal(sucursal['PRODUCTOS'])-getDescuentoSucursal(sucursal['PRODUCTOS']) ).reduce((a,b)=>a+b,0)
  }
  
  /*const distribucionEmpresa = (empresa) => {
    let s = Object.keys(empresa['SUCURSALES']).length
    let p = Object.values(empresa['SUCURSALES']).map(suc => Object.keys(suc['PRODUCTOS']).length ).reduce((a,b)=>a+b,0)
    s = s+(s>1?' sucursales':' sucursal')
    p = p+(p>1?' Productos':' Producto')
    return p+' en '+s
  }*/
  /**/

  /*
  tipos de errores

  Cambio de precio
  Stock agotado
  Eliminado / desactivado

  Si hay promocion:

  Promocion eliminada / desactivada
  Cambio el producto padre
  Detalle padre eliminado
  CantidadPadre cambio

  Cambio el producto hijo
  Detalle Hijo elimiado
  Cantidad hijo cambio
  Descuento en hijo cambio
  */

  const getEstatusProducto = (producto) => {
    //Producto eliminado o producto hijo eliminado
    if(producto['eliminado'])
      return 1;

    if(producto['promoEliminada'])
      return 2;

    if(producto['agotado'])
      return 6

    /*Hijo*/

    if(producto['hijo'].hasOwnProperty('metakey')){

      if(producto['hijo']['eliminado'])
      return 11;

      if(producto['hijo']['promoEliminada'])
        return 12;

      if(producto['hijo']['agotado'])
        return 16;
    }

    //Producto editado o producto hijo editado

    if(producto['cambioCantidad'])
      return 3;
    if(producto['cambioDescuentoPorcentaje'])
      return 4;
    if(producto['cambioDescuentoPesos'])
      return 5;

    /*Hijo*/

    if(producto['hijo'].hasOwnProperty('metakey')){
      
      if(producto['hijo']['cambioCantidad'])
        return 13;
      if(producto['hijo']['cambioDescuentoPorcentaje'])
        return 14;
      if(producto['hijo']['cambioDescuentoPesos'])
        return 15;
    }

  }

  const detectCambios = (productosCarrito,productosConsulta,detallesConsulta) => {
    let cambioDetectadoGral = false
    let listaCambiosProductos = []

    for(let producto of productosCarrito){
      let cambioDetectado = false
      let productitoToMakeChange = {metakey:producto['metakey'],eliminado:false,promoEliminada:false,detalleEliminado:false,hijo:{eliminado:false,detalleEliminado:false}}

      let productoConsulta = productosConsulta.filter(aux => aux['ID']===producto['metakey'])

      if(productoConsulta.length>0){
        productoConsulta = productoConsulta[0]
        productoConsulta['STOCK'] = Number(productoConsulta['STOCK']) - producto['cantidad']
        /**/
        productitoToMakeChange['status'] = Number(productoConsulta['ESTATUS'])
        productitoToMakeChange['precio'] = Number(productoConsulta['PRECIO'])
        productitoToMakeChange['usar_stock'] = Number(productoConsulta['USAR_STOCK'])
        productitoToMakeChange['stock'] = Number(productoConsulta['STOCK'])
        productitoToMakeChange['venta_stock'] = Number(productoConsulta['VENTA_STOCK'])
        /**/
        producto['eliminado'] = productitoToMakeChange['status'] !== 1
        producto['agotado'] = (productitoToMakeChange['stock'] < 0 && productitoToMakeChange['venta_stock'] !== 1 && productitoToMakeChange['usar_stock'] === 1 ) 
        /**/

        cambioDetectado = producto['agotado'] || producto['eliminado'] || ( Number(producto['precio']) !== Number(productoConsulta['PRECIO']) )

        if(!producto['eliminado']){
          if(producto['tipoPromo']>1){
            let promoVigente = productoConsulta['ID_PROMOCION'].some(promo => promo === producto['dependenciaPromocionMETA'])
            if(promoVigente){
               let detalleConsulta = detallesConsulta.filter(aux => aux['ID']===producto['detallePromocionMETA'] && aux['ID_PRODUCTO']===producto['metakey'])
              if(detallesConsulta.length>0){
                detalleConsulta = detalleConsulta[0]
                
                productitoToMakeChange['descuentoPesos'] = Number(detalleConsulta['DESCUENTO_MONETARIO'])
                productitoToMakeChange['descuentoPorce'] = Number(detalleConsulta['DESCUENTO_PORCENTAJE'])
                productitoToMakeChange['cantidad'] = Number(detalleConsulta['CANTIDAD'])

                let descuentoPorce = (producto['descuentoEnPesos']/producto['precio'])*100

                producto['cambioCantidad'] = ( Number(producto['minimoPromo'])!==Number(detalleConsulta['CANTIDAD']) && producto['tipoPromo']>2 )
                producto['cambioDescuentoPorcentaje'] = descuentoPorce !== productitoToMakeChange['descuentoPorce'] && productitoToMakeChange['descuentoPorce'] > 0
                producto['cambioDescuentoPesos'] = Number(producto['descuentoEnPesos']) !== productitoToMakeChange['descuentoPesos'] && productitoToMakeChange['descuentoPesos'] > 0 

                if(!cambioDetectado)
                  cambioDetectado = producto['cambioCantidad'] || producto['cambioDescuentoPorcentaje'] || producto['cambioDescuentoPesos']

                if(producto['hijo'].hasOwnProperty('metakey')){
                  let pihjo = productosConsulta.filter(aux => aux['ID'] === producto['hijo']['metakey'])

                  if(pihjo.length>0){
                    pihjo = pihjo[0]

                    productitoToMakeChange['hijo']['status'] = Number(pihjo['ESTATUS'])
                    productitoToMakeChange['hijo']['precio'] = Number(pihjo['PRECIO'])
                    productitoToMakeChange['hijo']['usar_stock'] = Number(pihjo['USAR_STOCK'])
                    productitoToMakeChange['hijo']['stock'] = Number(pihjo['STOCK'])

                    producto['hijo']['eliminado'] = productitoToMakeChange['hijo']['status'] !== 1

                    producto['hijo']['agotado'] = (productitoToMakeChange['hijo']['stock'] < 0 && productitoToMakeChange['venta_stock'] !== 1 && productitoToMakeChange['hijo']['usar_stock'] === 1 ) 

                    if(!cambioDetectado)
                      cambioDetectado = producto['hijo']['agotado'] || producto['hijo']['eliminado'] || ( Number(producto['hijo']['precio']) !== productitoToMakeChange['hijo']['precio'] )

                    if(!producto['hijo']['eliminado']){//&& !producto['hijo']['agotado']

                      let detalleHijo = detallesConsulta.filter(aux => aux['ID'] === producto['hijo']['detallePromocionMETA'] && aux['ID_PRODUCTO']===producto['hijo']['metakey'] )

                      if(detalleHijo.length>0){
                        detalleHijo = detalleHijo[0]
                        productitoToMakeChange['hijo']['descuentoPesos'] = Number(detalleHijo['DESCUENTO_MONETARIO'])
                        productitoToMakeChange['hijo']['descuentoPorce'] = Number(detalleHijo['DESCUENTO_PORCENTAJE'])
                        productitoToMakeChange['hijo']['cantidad'] = Number(detalleHijo['CANTIDAD'])

                        let descuentoPorce = (producto['hijo']['descuentoEnPesos']/producto['hijo']['precio'])*100

                        producto['hijo']['cambioCantidad'] = ( Number( producto['hijo']['minimoPromo'] ) !== productitoToMakeChange['hijo']['cantidad'] )
                        producto['hijo']['cambioDescuentoPorcentaje'] = descuentoPorce !== productitoToMakeChange['hijo']['descuentoPorce'] && productitoToMakeChange['hijo']['descuentoPorce'] > 0
                        producto['hijo']['cambioDescuentoPesos'] = Number( producto['hijo']['descuentoEnPesos'] ) !== productitoToMakeChange['hijo']['descuentoPesos'] && productitoToMakeChange['hijo']['descuentoPesos'] > 0 

                        if(!cambioDetectado)
                          cambioDetectado = producto['hijo']['cambioCantidad'] ||
                                            producto['hijo']['cambioDescuentoPorcentaje'] ||
                                            producto['hijo']['cambioDescuentoPesos']

                        producto['hijo']['promoEliminada'] = false

                      }else{
                        productitoToMakeChange['hijo']['detalleEliminado'] = true
                        producto['hijo']['promoEliminada'] = true
                        cambioDetectado=true
                      }
                    }


                  }else{
                    productitoToMakeChange['hijo']['eliminado'] = true
                    producto['hijo']['eliminado'] = true
                    cambioDetectado=true
                  }
                }
                producto['promoEliminada'] = false
              }else{
                cambioDetectado = true
                producto['promoEliminada'] = true
                productitoToMakeChange['detalleEliminado'] = true
              }
            }else{
              cambioDetectado = true
              producto['promoEliminada'] = true
              productitoToMakeChange['detalleEliminado'] = true
            }
          }
        }

      }else{
        productitoToMakeChange['eliminado']=true
        producto['eliminado']=true
        cambioDetectado = true
      }

      if(!cambioDetectadoGral)
        cambioDetectadoGral = cambioDetectado
      producto['cambioDetected'] = cambioDetectado
      listaCambiosProductos.push(productitoToMakeChange)
    }
    setListaCambiosProductos(listaCambiosProductos)
    return cambioDetectadoGral
  }

  const makeChangeOfProductos = () => {
    setLoading(true)
    for(let a in listaProductos){
      let producto = listaProductos[a]
      let productoAux = listaCambiosProductos[a]
      if(producto['cambioDetected']){
        producto['precio'] = productoAux['precio']

        if(producto['cambioDescuentoPorcentaje']){
          producto['descuentoEnPesos'] = (productoAux['descuentoPorce'] * producto['precio'])/100
          producto['cambioDescuentoPorcentaje'] = false
        }

        if(producto['cambioDescuentoPesos']){
          producto['descuentoEnPesos'] = productoAux['descuentoPesos']
          producto['cambioDescuentoPesos'] = false
        }

        if(producto['hijo'].hasOwnProperty('metakey')){
          producto['hijo']['precio'] = productoAux['hijo']['precio']

          if(producto['hijo']['cambioDescuentoPorcentaje']){
            producto['hijo']['descuentoEnPesos'] = (productoAux['hijo']['descuentoPorce'] * producto['hijo']['precio'])/100
            producto['hijo']['cambioDescuentoPorcentaje'] = false
          }
  
          if(producto['hijo']['cambioDescuentoPesos']){
            producto['hijo']['descuentoEnPesos'] = productoAux['hijo']['descuentoPesos']
            producto['hijo']['cambioDescuentoPesos'] = false
          }

        }

      }
    }
    setLoading(false)
    listaEmpresas[indexEmpresaActual]['SUCURSALES'][indexSucursalActual]['PRODUCTOS'] = listaProductos

    let totalSucursal = getTotalSucursal(listaProductos)
    let descuentoSucursal = getDescuentoSucursal(listaProductos)
    
    setTotalSucursal(totalSucursal-descuentoSucursal)
    setTotalPedido(totalSucursal-descuentoSucursal)
    setDescuentoPedido(descuentoSucursal)
   
    setListaProductosOnLocal(indexB,indexEmpresaActual,indexSucursalActual,listaProductos)
    setListaProductos(listaProductos)
    setListaEmpresas(listaEmpresas)
  }

  const setListaProductosOnLocal = (indexBubooker,indexEmpresa,indexSucursal,listaProductos) => {
    let carritoGral = JSON.parse(localStorage.getItem('carrito'))||{}
    carritoGral[indexBubooker]['EMPRESAS'][indexEmpresa]['SUCURSALES'][indexSucursal]['PRODUCTOS'] = listaProductos
    localStorage.setItem('carrito',JSON.stringify(carritoGral))
  }

  /**/
  const detallesSucursal = (indexEmpresa,indexSucursal) => {

    let sucursalActual = listaEmpresas[indexEmpresa]['SUCURSALES'][indexSucursal]

    let totalSucursal = getTotalSucursal(sucursalActual['PRODUCTOS'])
    let descuentoSucursal = getDescuentoSucursal(sucursalActual['PRODUCTOS'])
    
    //setTitle(listaEmpresas[indexEmpresa]['nombreE']+' - '+sucursalActual['nombre'])
    setTitle(null)

    setTotalSucursal(totalSucursal-descuentoSucursal)
    setTotalPedido(totalSucursal-descuentoSucursal)
    setDescuentoPedido(descuentoSucursal)
    
    setIndexEmpresaActual(indexEmpresa)
    setIndexSucursalActual(indexSucursal)

    setCambioDetectado(false)

    let idVarios = sucursalActual['PRODUCTOS'].reduce((result,producto)=>{
      result['productos'].push(producto['key'])
      if(producto['tipoPromo']>1)
        result['detalles'].push(producto['detallePromocion'])
      if(producto['hijo'].hasOwnProperty('nombre')){
        result['productos'].push(producto['hijo']['key'])
        if(producto['tipoPromo']>2)
          result['detalles'].push(producto['hijo']['detallePromocion'])
      }
      return result
    } ,{productos:[],detalles:[]})

    let idS = indexSucursal.replace('Sucursal[','')
    idS = idS.slice(0,-1)
    const getInfoPedidoSucursalParameters = { idS, idB:sessionStorage.getItem('id'),idE:listaEmpresas[indexEmpresa]['id']}
    setLoading(true)
    axios.post(`${Host}empresas/empresas/getInfoPedidoSucursal`,getInfoPedidoSucursalParameters,request).then(rt=>{
      if(rt.data['status']==='ok'){

        if(Number(rt.data['withSign'])!==1){//no hay empresa
          handlerAction(1,true,'Aceptar','','Empresa no encontrada','Parece que esta empresa ya no se encuentra en BuBook. Tu carrito será eliminado.',false,false)
          setLoading(false)
          return
        }

        let listaFormasDePago = [rt.data['pagoSucursal'],rt.data['pagoDomicilio']]

        let listaTiposEntrega = ['Recoger en sucursal']
        if(rt.data['domicilios'].length>0)
          listaTiposEntrega.push('Envío a domicilio')

        if(rt.data['pagoTransferencia'].length>0)
          setListaDatosTransferencia(rt.data['pagoTransferencia'])

        if(rt.data['pagoEnLinea'].length>0)
          setListaPagoEstripe(rt.data['pagoEnLinea'])

        let dia = moment().day()
        let estatusSucursalA = 'Cerrado'
        dia = dia===0?7:dia
        let horarioFecha = rt.data['HORARIOS_ATENCION'].filter(h => Number(h['dia'])===dia && Number(h['estatus'])===1 )//recoger en sucursal
        if(horarioFecha.length>0){
          estatusSucursalA = getEstatusSucursal(horarioFecha[0]['atencion'])

          if(estatusSucursalA.toLowerCase()==='abierto'){
            let cierra = moment(horarioFecha[0]['atencion'].split('-')[1],'HH:mm')
            let minutosDiff = cierra.diff(moment(),'milliseconds')//
            if(minutosDiff < 0){
              minutosDiff = moment().diff(cierra,'milliseconds')//milliseconds
              minutosDiff = minutosDiff + (12*60*60*1000)
            }
            setTimer(setInterval(cerrarSucursal,minutosDiff) )
          }

        }

        setIntegrationID(rt.data['integration'])
        
        mountStripe(rt.data['integration'])

        setEstatusSucursal(estatusSucursalA)
        setListaHorariosSucursal(rt.data['HORARIOS_ATENCION'])
        setListaFormasDePago(listaFormasDePago)
        setListaCoberturas(rt.data['domicilios'])
        setListaDirecciones(rt.data['domiciliosCliente'])
        setDomicilioSucursal(rt.data['domicilioSucursal'])        
        setListaTiposEntrega(listaTiposEntrega)
        setMinimoForDomicilio(Number(rt.data['minimoDomicilio']))
        setNivel(2)


        setTimeout(() => {
          if(rt.data['horarioAtencion'].trim() === '0')return
          const horario = rt.data['horarioAtencion'].split('-')
          document.querySelector('#placeHolderHorario').innerText = moment(horario[0],'HH:mm:ss').format('HH:mm') +' - '+ moment(horario[1],'HH:mm:ss').format('HH:mm')
        }, 1*1000);


        let data2 = {idVarios,idS}
        verifyValidProductos(data2,sucursalActual['PRODUCTOS'],false,indexEmpresa,indexSucursal)

      }else if(rt.data['status']==='nonsucursal'){
        if(Number(rt.data['withSign'])<=0){//no hay empresa
          handlerAction(1,true,'Aceptar','','Empresa no encontrada','Parece que esta empresa ya no se encuentra en BuBook. Tu carrito será eliminado.',false,false)
          setLoading(false)
        }else{//no hay sucursal
          handlerAction(2,true,'Aceptar','','Sucursal no encontrada','Parece que esta sucursal ya no se encuentra en BuBook. Tu carrito será eliminado.',false,false)
          setLoading(false)
        }
      }
    })

  }

  const verifyValidProductos = (data2,listaProductos,loadingToSet,indexEmpresa,indexSucursal,__function__ = -1,pagoEnLinea=false) => {
    
    axios.post(`${Host}empresas/empresas/infoProductosCarrito`,data2,request).then(rt2=>{
      if(pagoEnLinea)
        setLoading(false)

      setListaInforProductos( JSON.parse(JSON.stringify(rt2.data)) )

      let cambioDetectado = detectCambios(listaProductos,rt2.data['dataProductos'],rt2.data['dataDetalles'],false)
      setListaProductos(listaProductos)

      setLoading(loadingToSet)

      if(cambioDetectado){
        handlerAction(3,true,'Aceptar','','Actualizar carrito','Parece que los artículos de tu carrito han sufrido cambios. Si continúas, la información de tu carrito será actualizada.',false,false)
        setCambioDetectado(true)
        setLoading(false)
        //if(pagoEnLinea){}
      }else if(__function__!==-1){
        //if(pagoEnLinea){}
        __function__()
      }

    })
  }

  const cerrarSucursal = () =>{
    setEstatusSucursal('Cerrado')
    clearInterval(timer)
  }

  const getTotalSucursal = (listaProductos) => {
    //return Object.values(listaProductos).map(producto => ((producto['precio'] - producto['descuentoEnPesos']) * producto['cantidad'])).reduce((a,b)=>a+b,0)
    return Object.values(listaProductos).reduce((resultado,producto)=>{
      resultado+=(producto['precio']*producto['cantidad'])
      if(producto['hijo'].hasOwnProperty('precio'))
        resultado+=(producto['hijo']['precio']*producto['hijo']['cantidad'])
      return resultado
    },0)
  }

   const getDescuentoSucursal = (listaProductos) => {
    return Object.values(listaProductos).reduce((resultado,producto)=>{
      resultado+= producto['descuentoEnPesos'] * producto['cantidad']
      if(producto['hijo'].hasOwnProperty('precio')){
        if( Number(producto['tipoPromo']) === 4 ){
          resultado+=producto['hijo']['descuentoEnPesos']
        }else{
          resultado+=producto['hijo']['descuentoEnPesos']*producto['hijo']['cantidad']
        }
      }
      return resultado
    },0)
  }

  const getEstatusSucursal = (horarioAtencion,now=moment()) => {
    if(horarioAtencion.trim()===''||horarioAtencion.trim()==='0')return 'Cerrado'
    else{
      let horas = horarioAtencion.split('-')
      let abre = moment(horas[0],'HH:mm:ss')
      let cierra = moment(horas[1],'HH:mm:ss')
      let newHorarioLabel = ''

      if(abre.isBefore(cierra))
        newHorarioLabel = now.isBetween(abre,cierra) ? 'Abierto' : 'Cerrado'
      else if(abre.isAfter(cierra))
        newHorarioLabel = now.isBetween(cierra,abre) ? 'Cerrado' : 'Abierto'
      else
        newHorarioLabel = 'abierto'
      return newHorarioLabel
    }
  }

  const getTotalProductosSucursal = (listaProductos) => {
    let aux = listaProductos.reduce((total,producto)=>{
      total += Number(producto['cantidad'])
      if(producto['hijo'].hasOwnProperty('precio')) total+=Number(producto['hijo']['cantidad'])
      return total
    },0)
    return aux + (aux>1?' Productos':' Producto')
  }

  /**/
  const tipoEntregaChange = indice => {
    if(indice===1){
      setShowCoberturas(true)
      return
    }
    setTipoEntrega(indice)
  }

  const formaEntregaChange = indice => {
    if(indice===1){
      setShowProgramada(true)
      return
    }
    setFormaEntrega(indice)
  }

  const formaDePagoChange = indice => {
    if(indice===4){
      setShowListaDatosTranferencia(true)
      return
    }
    setDatosTarjetaElegida('none')
    setFormaPago(indice)
  }

  const setDireccionCobertura = (indiceDireccion,indiceCobertura) => {
    if(indiceCobertura<0){
      props.controlAlert(true,'El domicilio elegido se encuentra fuera de la zona de cobertura')
    }else{
      //let direccionActual = listaDirecciones[indiceDireccion]
      let coberturaActual = listaCoberturas[indiceCobertura]
      let costoEntrega = 0
      if( Number(coberturaActual['COSTO_ENVIO']) > 0 ){
          setTotalPedido(totalSucursal+Number(coberturaActual['COSTO_ENVIO']))
          costoEntrega = '$'+formatNumber(coberturaActual['COSTO_ENVIO'],2)+' MXN'
        }else{
          costoEntrega = "Gratis"
        }
        setTipoEntrega(1)
        setCostoEntrega(costoEntrega)
        setIndiceDireccion(indiceDireccion)
        setIndiceCobertura(indiceCobertura)
        setShowCoberturas(false)
    }
  }

  const setDataProgramada = (valid,valid2,fecha,hora) => {
    if(!valid || !valid2){
      props.controlAlert(true,'Verifica que la hora y fecha indicadas sean válidas')
    }else{
      setHora(hora)
      setFecha(fecha)
      setShowProgramada(false)
      setFormaEntrega(1)
    }
  }

  const formatAdress = (obj) => {
    return obj['CALLE'] + ' ' +  obj['NUMERO_EXTERIOR'] + ' '  + obj['NUMERO_INTERIOR'] + ' ' + obj['COLONIA'] + ' ' + obj['COD_POSTAL'] + ' ' + obj['CIUDAD'] + ' ' + obj['ESTADO'] + ' ' + obj['PAIS']
  }

  const sendPedido = () =>{
    let {controlAlert} = props
    
    if(formaEntrega===0){//inmediata
      
      if(tipoEntrega===0&&estatusSucursal.toLowerCase()!=='abierto'){//sucursal
        controlAlert(true,'La sucursal se encuentra cerrada en ese momento. Solo se permiten compras programadas.')
        return
      }

      
      if(tipoEntrega===1){//domicilio
        let dia = moment().day()
        dia = dia===0?7:dia
        let horarioToEval = listaCoberturas[indiceCobertura]['HORARIOS_ATENCION'].filter(h => Number(h['dia']) === dia && Number(h['estatus'])===1 )
        if(horarioToEval.length===0 || getEstatusSucursal(horarioToEval[0]['atencion'],moment()).trim().toLowerCase()==="cerrado" ){
          controlAlert(true,'El servicio a domicilio de esta zona de cobertura no se encuentra disponible en este momento, solo se permiten compras programadas.')
          return
        }
      }
    }

    else if(formaEntrega===1){//programada
      if(fecha===''){
        controlAlert(true,'Debe especificar una fecha para su entrega programada')
        return
      }
      if(hora===''){
        controlAlert(true,'Debe especificar una hora para su entrega programada')
        return
      }
    }

    let total = totalSucursal + (tipoEntrega===1?Number(listaCoberturas[indiceCobertura]['COSTO_ENVIO']):0)

    if(total < minimoForDomicilio  && tipoEntrega===1){
      controlAlert(true,'La sucursal requiere una compra mínima de $'+formatNumber(minimoForDomicilio,2)+' MXN para entrega a domicilio.')
      return
    }

    
    if(formaPago!==5){//no es pago en linea
      const referenciaPago2 = moment().format('HHmmssSSSYYMMDD')
      setReferenciaPago(referenciaPago2)
      let idVarios = listaEmpresas[indexEmpresaActual]['SUCURSALES'][indexSucursalActual]['PRODUCTOS'].reduce((result,producto)=>{
        result['productos'].push(producto['key'])
        if(producto['tipoPromo']>1)
          result['detalles'].push(producto['detallePromocion'])
        if(producto['hijo'].hasOwnProperty('nombre')){
          result['productos'].push(producto['hijo']['key'])
          if(producto['tipoPromo']>2)
            result['detalles'].push(producto['hijo']['detallePromocion'])
        }
        return result
      } ,{productos:[],detalles:[]})

      let idS = indexSucursalActual.replace('Sucursal[','')
      idS = idS.slice(0,-1)
      let data2 = {idVarios,idS}
      setLoading(true)
      setTimeout(() => {
        verifyValidProductos(data2,listaEmpresas[indexEmpresaActual]['SUCURSALES'][indexSucursalActual]['PRODUCTOS'],true,indexEmpresaActual,indexSucursalActual,savePedido)
      }, 200);
    }
    else{//pago en linea      
      if(total<10){
        controlAlert(true,'El total de compra mínimo requerido para pagar en linea es $'+formatNumber(10,2)+' MXN')
        return
      }
      mountCard()//hacer visible el modal de pago en linea
    }

  }

  const savePedido = (idPaymentIntegracion='',statusPago=1) => {
    let {controlAlert,controlAgra,closeSelf,actualizaCarritoContador} = props

    let idTipoPago=0,fkIdTipoPago=0
    if(formaPago===4){//si es transferencia se ocupa el id de datos de transferencia
      idTipoPago = -4
      fkIdTipoPago = listaDatosTransferencia[datoTransferencia]['ID']
    }
    else if(formaPago===5){//eligió pago en linea
      idTipoPago = -5
      fkIdTipoPago = -1
    }else{
      idTipoPago = listaFormasDePago[tipoEntrega][formaPago]['ID_TIPO_PAGO']
      fkIdTipoPago = -1
    }

    const total = totalSucursal + (tipoEntrega===1?Number(listaCoberturas[indiceCobertura]['COSTO_ENVIO']):0)

    let dataA1 = {}
    dataA1['idUsuario'] = metadata['id']
    dataA1['idSucursal'] = indexSucursalActual.replace('Sucursal[','').slice(0,-1) 
    dataA1['folio'] = ''  //vaci
    
    dataA1['fechaEntrega'] = formaEntrega===1?fecha.format('YYYY-MM-DD'):'' //YYYY-mm-d
    dataA1['horaEntrega'] = formaEntrega===1?hora.format('HH:mm:')+'00':''  //hh:mm:s
    dataA1['subTotal'] = totalSucursal+descuentoPedido 
    dataA1['cotizacionEnvio'] = '0' 
    dataA1['costoEnvio'] = tipoEntrega===1?listaCoberturas[indiceCobertura]['COSTO_ENVIO']:'0' 
    dataA1['tipoDescuento'] = listaProductos.some(p => p['tipoPromo']>1)?1:2  //si todos los productos llevan descuento=1 de lo contrario=2
    dataA1['descuento'] = descuentoPedido 
    dataA1['total'] = total
    dataA1['idDomicilioCliente'] = tipoEntrega===1?listaDirecciones[indiceDireccion]['ID']:'-1' 
    dataA1['idTipoPago'] = idTipoPago
    dataA1['fkIdTipoPago'] = fkIdTipoPago

    dataA1['referenciaPagoCliente'] = ''
    dataA1['imgComprobantePagoCliente'] = ''
    dataA1['comentarios'] = comentario
    dataA1['estatus'] = 1
    dataA1['id'] = '-1'
    dataA1['detalles'] = listaProductos
    /* okPedido */
    dataA1['fechaEntrega']=formaEntrega===1?fecha.format('YYYY-MM-DD'):''//YYYY-mm-d
    dataA1['horaEntrega']=formaEntrega===1?hora.format('HH:mm:')+'00':'' //hh:mm:s
    dataA1['tiempoEntrega'] = formaEntrega===1?2:1  //1 innmediata 2 programada

    //Memoria
    dataA1['referenciaPago'] = document.querySelector('#referenciaPago').innerText.split(' ').join('')
    //Parametros
    dataA1['idPaymentIntegracion'] = idPaymentIntegracion
    dataA1['statusPago'] = statusPago
  

    console.log('savePedido',dataA1)
    setLoading(true)
    axios.post(`${Host}pedidos/pedidos/altaPedido`,dataA1,request).then(rt=>{
      setLoading(false)
      if(rt.data['status']==='ok'){

        let [, nuevaListaEmpresas] = eliminarSucursalCarrito(indexB,listaEmpresas,indexEmpresaActual,indexSucursalActual)
        carrito['EMPRESAS'] = nuevaListaEmpresas
        controlAgra({
          modalAgradecimiento:true,
          estatusAgra:statusPago===1?'Pendiente de pago':statusPago===2?'Pago confirmado':'Pendiente de pago',
          folioAgra:rt.data['folio'],
          fechaHoraAgra:moment().format('DD/MM/YYYY HH:mm:ss'),
          referenciaAgra:dataA1['referenciaPago'],
          totalAgra:formatNumber(dataA1['total'],2),
          tipoPago:formaPago===4? 'Transferencia bancaria': formaPago===5?'Stripe': listaFormasDePago[tipoEntrega][formaPago]['NOMBRE'],
          datosTarjeta:datosTarjetaElegida
        })
        closeSelf()
        actualizaCarritoContador()
      }else{
        controlAlert(true,'Parece que ocurrió un error al registrar el pedido. Por favor intentelo más tarde.')
      }
    })
  }

  /**/
  const [vermas,setVermas] = useState(1)
  /**/
  const [integrationID,setIntegrationID] = useState('')
  const [intentID,setIntentID] = useState('')
  const [clientSecret,setClientSecret] = useState('')
  /**/
  const [stripeAPI,setStripeAPI] = useState({})
  const [cardElement,setCardElement] = useState({})
  /**/
  const [tarjetaComplete,setTarjetaComplete] = useState(false)
  const [tarjetaError,setTarjetaError] = useState(false)
  const [pagading,setPaganding] = useState(false)

  const getSuffix = (nombreComercial) =>{
    nombreComercial = nombreComercial.toLowerCase()
    nombreComercial = nombreComercial.split('á').join('a')
    nombreComercial = nombreComercial.split('é').join('e')
    nombreComercial = nombreComercial.split('í').join('i')
    nombreComercial = nombreComercial.split('ó').join('o')
    nombreComercial = nombreComercial.split('ú').join('u')
    nombreComercial = nombreComercial.split('ü').join('u')
    nombreComercial = nombreComercial.split('ñ').join('ni')
    let regex = /[^a-z0-9&\\+\\-]*/g
    nombreComercial = nombreComercial.replace(regex,'')
    return nombreComercial.length<11?nombreComercial.toUpperCase()+'ACAMX':nombreComercial.slice(0,11).toUpperCase()
  }

  const getIntent=()=>{
    let referenciaPago2 = moment().format('HHmmssSSSYYMMDD')
    setReferenciaPago(referenciaPago2)
    let {controlAlert} = props
    const createIntentForPurchaseParameters = {
      amount:totalPedido*100,
      description:'Pago en línea realizado a través de BuBook.mx',
      email:metadata['mail'],
      suffix:getSuffix(listaEmpresas[indexEmpresaActual]['nombreE']),
      orderId:referenciaPago2,
      stripeId:integrationID,
    }
    setLoading(true)
    axios.post(`${Host}payment/GatewayStripe/createIntentForPurchase`,createIntentForPurchaseParameters,request).then(rt=>{
      if(rt.data['secret']==='error'){
        controlAlert(true,rt.data['message'])
        setPaganding(false)
        setLoading(false)
      }else{
        setIntentID(rt.data['message'])
        setClientSecret(rt.data['secret'])
        setLoading(false)
      }
    })
  }

  const mountStripe = (a='') => {
    loadStripe(stripeKey, { stripeAccount:a,locale: 'es' }).then(stripe => {
      setStripeAPI(stripe)
      let elements = stripe.elements()
      let cardElement = elements.create('card', {
        iconStyle: 'solid',
        style: { invalid: { color: '#ED3B48', iconColor: '#ED3B48' }, },
      })
      cardElement.on('change', (event) => {
        let div = document.getElementById('toshow')
        setTarjetaComplete(event['complete'])
        if (event.error) {
          div.textContent = '*' + event.error.message;
          setTarjetaError(true)
        }
        else {
          div.textContent = '';
          setTarjetaError(false)
        }
      })
      setCardElement(cardElement)
    })
  }

  const mountCard = () => {
    setPaganding(true)
    if(intentID==='')
      getIntent()
    setTimeout(() => cardElement.mount('#cardElementPay'), 10)
  }

  const unmountCardPayment = () =>{
    setPaganding(false)
    setTimeout(() => cardElement.unmount(), 10)
  }

  const validPayPedido = () => {
    let {controlAlert,} = props
    if (!tarjetaComplete) {
      controlAlert(true, 'Debe completar los datos de su tarjeta')
      return
    }
    if (tarjetaError) {
      controlAlert(true, 'Debe corregir los datos de su trajeta')
      return
    }

    setLoading(true)
    let idVarios = listaEmpresas[indexEmpresaActual]['SUCURSALES'][indexSucursalActual]['PRODUCTOS'].reduce((result,producto)=>{
      result['productos'].push(producto['key'])
      if(producto['tipoPromo']>1)
        result['detalles'].push(producto['detallePromocion'])
      if(producto['hijo'].hasOwnProperty('nombre')){
        result['productos'].push(producto['hijo']['key'])
        if(producto['tipoPromo']>2)
          result['detalles'].push(producto['hijo']['detallePromocion'])
      }
      return result
    } ,{productos:[],detalles:[]})

    let idS = indexSucursalActual.replace('Sucursal[','')
    idS = idS.slice(0,-1)
    let data2 = {idVarios,idS}
    setLoading(true)
    verifyValidProductos(data2,listaEmpresas[indexEmpresaActual]['SUCURSALES'][indexSucursalActual]['PRODUCTOS'],true,indexEmpresaActual,indexSucursalActual,makePayPedido,true)

  }

  const makePayPedido = () => {
    let {controlAlert,} = props
    setLoading(true)
    stripeAPI.confirmCardPayment(clientSecret,{ payment_method: { card: cardElement, } }).then(result => {
      setLoading(false)
      if (result.error) {
        let msjtoshow = ''
        let msj = result.error['message']
        if (msj.includes("insufficient")) {
          msjtoshow = "La tarjeta no tiene fondos suficientes para completar la compra. Intenta nuevamente con otra tarjeta.";
        } else if (msj.includes("lost")) {
          msjtoshow = "La tarjeta ingresada se encuentra actualmente reportada como extraviada. Intenta nuevamente con otra tarjeta.";
        } else if (msj.includes("stolen")) {
          msjtoshow = "La tarjeta ingresada se encuentra actualmente reportada como robada. Intenta nuevamente con otra tarjeta.";
        } else if (msj.includes("expired")) {
          msjtoshow = "La tarjeta ingresada ha expirado. Intenta nuevamente con otra tarjeta.";
        } else if (msj.includes("security")) {
          msjtoshow = "El código de seguridad de la tarjeta ingresada es incorrecto. Verifica e inténtalo nuevamente.";
        } else if (msj.includes("declined")) {
          msjtoshow = "Tu tarjeta fue rechazada.";
        } else if (msj.includes("support")) {
          msjtoshow = "Tu tarjeta no admite este tipo de compra.";
        } else {
          msjtoshow = msj;
        }
        controlAlert(true, msjtoshow)
        return;
      } else {
        let idPaymentIntegracion = result.paymentIntent['id']
        let statusPago = 2
        savePedido(idPaymentIntegracion,statusPago)
      }
    })

  }


  /**/

  useEffect(()=>{
    setFooter(getTotalCarrito(carrito['EMPRESAS']))
    
    document.querySelector('#fotoCarritoTrigger').src = imgPanel+"/carrito.svg"
    return () => { document.querySelector('#fotoCarritoTrigger').src = imgPanel+"/carritoB.svg" }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  /**/

  const showHideElemento = (id,display,innerText) => {
    document.getElementById(id).style.display=display
    document.getElementById(id).innerText=innerText
  }

  /**/
  const [opAction,set_opAction] = useState(-1)
  const [opVisible,set_opVisible] = useState(false)
  const [opOkText,set_opOkText] = useState('okText')
  const [opCancelText,set_opCancelText] = useState('cancelText')
  const [opTitulo,set_opTitulo] = useState('Titulo')
  const [opMensaje,set_opMensaje] = useState('Mensaje')
  const [opWithCancel,set_opWithCancel] = useState(false)
  const [opWithClose,set_opWithClose] = useState(false)

  const handlerAction = (opAction,opVisible,opOkText,opCancelText,opTitulo,opMensaje,opWithCancel,opWithClose) => {
    set_opAction(opAction)
    set_opVisible(opVisible)
    set_opOkText(opOkText)
    set_opCancelText(opCancelText)
    set_opTitulo(opTitulo)
    set_opMensaje(opMensaje)
    set_opWithCancel(opWithCancel)
    set_opWithClose(opWithClose)
  }

  /**/

  const updateCarritoPerfilActual = () =>{
    let {showDataComponentMounted} = props
    let component = showDataComponentMounted()
    if(component['name']==="Perfil"){
      component['component'].updateValueCarritoFromCarrito()
    }
  }

  const eliminarEmpresaCarrito = (indexBubooker,listaEmpresas,indiceEmpresa) => {
    let aux =
      Object.keys(listaEmpresas)
      .reduce(
        (result,indexEmpresa) => {
          if(indexEmpresa !== indiceEmpresa){
            result[indexEmpresa] = listaEmpresas[indexEmpresa];
          }
          return result;
        },{}
      )

    let carritoGral = JSON.parse(localStorage.getItem('carrito'))||{}
    carritoGral[indexBubooker]['EMPRESAS'] = aux
    localStorage.setItem('carrito',JSON.stringify(carritoGral))
    updateCarritoPerfilActual()
    return [1,aux]
  }

  const eliminarSucursalCarrito = (indexBubooker,listaEmpresas,indiceEmpresa,indiceSucursal) => {
    let nivelReturn = 1
    let nuevaListaEmpresas = JSON.parse(JSON.stringify(listaEmpresas))
    let count = 0
    nuevaListaEmpresas[indiceEmpresa]['SUCURSALES'] = 
      Object.keys(listaEmpresas[indiceEmpresa]['SUCURSALES'])
      .reduce( 
        (result,indexSucursal) => {
          if(indiceSucursal !== indexSucursal){
            result[indexSucursal] = listaEmpresas[indiceEmpresa]['SUCURSALES'][indexSucursal];
            count++;
          }
          return result;
        },{}
      )
    
    if(count === 0 ){
      [nivelReturn,nuevaListaEmpresas] = eliminarEmpresaCarrito(indexBubooker,nuevaListaEmpresas,indiceEmpresa,indiceSucursal)
    }else{
      let carritoGral = JSON.parse(localStorage.getItem('carrito'))||{}
      carritoGral[indexBubooker]['EMPRESAS'] = nuevaListaEmpresas
      localStorage.setItem('carrito',JSON.stringify(carritoGral))
      updateCarritoPerfilActual()
    }
    return [nivelReturn,nuevaListaEmpresas]
  }


  const searchForValue = (array,name)=>{
    return array.filter(item => item['name'] === name)
  }

  const okAction = (op,extra=false) => {
    handlerAction(-1,false,'','','','',false,false)
    let {actualizaCarritoContador} = props
    
    if(op===3){//cambio en producto de carrito detectado
      makeChangeOfProductos()
      handlerAction(-1,false,'','','','',false,false)
    }

    if(op===4||op===5||op===2){
      let [nivelReturn, nuevaListaEmpresas] = eliminarSucursalCarrito(indexB,listaEmpresas,indexEmpresaActual,indexSucursalActual)
      actualizaCarritoContador()
      carrito['EMPRESAS'] = nuevaListaEmpresas
      setListaEmpresas(nuevaListaEmpresas)
      if(nivelReturn===1){
        setNivel(1)
        setTitle('Mi carrito')
        setFooter(getTotalCarrito(carrito['EMPRESAS']))
      }
    }
    if(op===1){
      let [nivelReturn, nuevaListaEmpresas] = eliminarEmpresaCarrito(indexB,listaEmpresas,indexEmpresaActual,indexSucursalActual)
      actualizaCarritoContador()
      carrito['EMPRESAS'] = nuevaListaEmpresas
      setListaEmpresas(nuevaListaEmpresas)
      if(nivelReturn===1){
        setNivel(1)
        setTitle('Mi carrito')
        setFooter(getTotalCarrito(carrito['EMPRESAS']))
      }
    }
    if(op===6){
      let
        keyEliminando2 = keyEliminando,
        reflow = false,
        subKeyEliminando2 = subKeyEliminando,
        keyPromoEliminando2 = keyPromoEliminando

      if(extra){
        let valueBuscado1 = searchForValue(extra,'keyEliminando')
        if(valueBuscado1.length > 0 ){
          keyEliminando2 = valueBuscado1[0]['value']
          reflow = true
        }

        let valueBuscado2 = searchForValue(extra,'subKeyEliminando')
        if(valueBuscado2.length > 0 ){
          subKeyEliminando2 = valueBuscado2[0]['value']
          reflow = true
        }
        
        let valueBuscado3 = searchForValue(extra,'keyPromoEliminando')
        if(valueBuscado3.length > 0 ){
          keyPromoEliminando2 = valueBuscado3[0]['value']
          reflow = true
        }

      }
      
      let nuevalista = listaProductos.filter(p =>{
          if(p['metakey'] === keyEliminando2){
            if(Number(p['tipoPromo']) > 2 && subKeyEliminando2!==-1 ){//producto con promo, y eliminando un hijo
              return !(p['hijo']['metakey'] === subKeyEliminando2 && p['dependenciaPromocionMETA'] === keyPromoEliminando2)
            }else if(subKeyEliminando2!==-1){//eliminando un paquete con hijo
              return true
            }else if(Number(p['tipoPromo']) > 2){
              return true
            }
            return p['metakey'] !== keyEliminando2
          }
          return true
        }
      )

      //ÑeliminarProductoCarrito(indexB,listaEmpresas,indexEmpresaActual,indexSucursalActual,keyEliminando,1)

      if(nuevalista.length===0 && !reflow){
        handlerAction(5,true,'Aceptar','','Carrito sin articulos','Tu carrito ha quedado sin artículos disponibles.',false,false)
      }else{
        listaEmpresas[indexEmpresaActual]['SUCURSALES'][indexSucursalActual]['PRODUCTOS'] = nuevalista

        let totalSucursal = getTotalSucursal(nuevalista)
        let descuentoSucursal = getDescuentoSucursal(nuevalista)
        
        setTotalSucursal(totalSucursal-descuentoSucursal)
        setTotalPedido(totalSucursal-descuentoSucursal)
        setDescuentoPedido(descuentoSucursal)
       
        setListaProductosOnLocal(indexB,indexEmpresaActual,indexSucursalActual,nuevalista)
        setListaProductos(nuevalista)
        setListaEmpresas(listaEmpresas)

        const listaInforProductosAux = JSON.parse(JSON.stringify(listaInforProductos))

        let cambioDetectado = detectCambios(nuevalista,listaInforProductosAux['dataProductos'],listaInforProductosAux['dataDetalles'],false)
        setCambioDetectado(cambioDetectado)

        setKeyEliminando(-1)
        setSubKeyEliminando(-1)
        setKeyPromoEliminando(-1)

        actualizaCarritoContador()

      }
    }
  }

  const cancelAction = (op,extra=false) => {
    if(op===3){
      handlerAction(-1,false,'','','','',false,false)
    }
    if(op===4){
      handlerAction(-1,false,'','','','',false,false)
    }
    if(op===6){
      setKeyEliminando(-1)
      setSubKeyEliminando(-1)
      setKeyPromoEliminando(-1)
      handlerAction(-1,false,'','','','',false,false)
    }
  }

  const confirmarEliminar = (producto,e,_force=false,msj=false) => {
    if(e)
      e.stopPropagation()
    if(_force){
      let params = [{name:'keyEliminando',value:producto['metakey']}]
      if(Number(producto['tipoPromo']) > 2 ){
        params.push(
          {name:'subKeyEliminando',value:producto['hijo']['metakey']},
          {name:'keyPromoEliminando',value:producto['dependenciaPromocionMETA']}
        )
      }
      okAction(6,params)
      return
    }
    setKeyEliminando(producto['metakey'])
    if( Number(producto['tipoPromo']) > 2){
      setSubKeyEliminando(producto['hijo']['metakey'])
      setKeyPromoEliminando(producto['dependenciaPromocionMETA'])
    }

    let msg = "Si continua, el producto será eliminado del carrito de forma permanente. ¿Desea continuar?"
    if(msj)
      msg = msj
    handlerAction(6,true,'Aceptar','Cancelar','Eliminar producto',msg,true,true)
  }

  const continuarComprando = () => {
    let {irAlaEmpresa,closeSelf} = props
    sessionStorage.setItem('presucursal',listaEmpresas[indexEmpresaActual]['SUCURSALES'][indexSucursalActual]['metaid'])
    irAlaEmpresa(listaEmpresas[indexEmpresaActual]['link'],listaEmpresas[indexEmpresaActual]['SUCURSALES'][indexSucursalActual]['metaid'])
    closeSelf()
  }

  return(
    <div className="contentCarrito">

      <ListaCoberturas
        visible={showCoberturas}
        handler={setShowCoberturas}
        listCoberturas={listaCoberturas}
        setIndiceCobertura={setIndiceCobertura}
        setIndiceDireccion={setIndiceDireccion}
        listDirecciones={listaDirecciones}
        setDireccionCobertura={setDireccionCobertura}
        showHideElemento={showHideElemento} />

      <EntregaProgramada
        visible={showProgramada}
        handler={setShowProgramada}
        listCoberturas={listaCoberturas}
        indiceCobertura={indiceCobertura}
        tipoEntrega={tipoEntrega}
        estatusSucursal={estatusSucursal}
        listaHorariosSucursal={listaHorariosSucursal}
        getEstatusSucursal={getEstatusSucursal}
        showHideElemento={showHideElemento}
        setDataProgramada={setDataProgramada}
        defaultFecha={fecha}
        defaultHora={hora} />

      <ListaDatosDeTransferencia
        listDatosTransferencia={listaDatosTransferencia}
        visible={showListaDatosTranferencia}
        handler={setShowListaDatosTranferencia}
        showHideElemento={showHideElemento}
        setDatoTransferencia={setDatoTransferencia}
        setFormaPago={setFormaPago}
        setDatosTarjetaElegida={setDatosTarjetaElegida} />

      <AddComentario
        visible={showComentario}
        handler={setShowComentario}
        setComentario={setComentario}
        preValue={comentario} />

      <MensajeWithAction
        visible={opVisible}
        titulo={opTitulo}
        msj={opMensaje}
        withCancel={opWithCancel}
        onCancel={()=>cancelAction(opAction)}
        cancelText={opCancelText}
        onOk={()=>okAction(opAction)}
        okText={opOkText}
        withClose={opWithClose}
        onClose={()=>cancelAction(opAction)} />


      <div className="headCarrito">

        <Icon type="close" className="iconCloseModal fter" onClick={props.closeSelf} />

        <div className="controls flextop Fspaceb">
        {nivel!==1?
          <div className="itemBack flexcenter Fspaceb" onClick={validClick} >
            <Icon type="left" />
            <span> Regresar </span>
          </div>:<div/>
        }
        </div>


        <div className="content flexcenter Fspaceb">
          {title}
        </div>

        {
          nivel===2 &&
          <div className="flexcenter Fcenter infoPedido">

            <div style={{color:'deepskyblue',width:'100%'}} id="referenciaPago" > {referenciaPago} </div>

            <div className="flexcenter Fspaceb presentation" >
              <div className="initial-data flexcenter Fcenter ">
                
                <div className="flexcenter Fcenter" >
                  <img onError={props.onErrorImg} src={ srcPerfil(listaEmpresas[indexEmpresaActual]['str']) } alt="..." />
                </div>
                
                <div style={{marginLeft:'20px'}} >
                  <div className="info flexcenter Fcenter ">
                    <div className="nombreEmpresa"> { listaEmpresas[indexEmpresaActual]['nombreE'] } </div>
                  </div>

                  <div className="info flexcenter Fstart ">
                    <div className="nombreSuc" style={{paddingLeft:'8px'}} >
                      Suc. { listaEmpresas[indexEmpresaActual]['SUCURSALES'][indexSucursalActual]['nombre'] }
                    </div>
                  </div>
                </div>

              </div>
              
              <div className="info flexcenter Fspaceb" style={{flexDirection:'column'}} >
                <div className={'sucStatus '+estatusSucursal}> {estatusSucursal} </div>
                <div id="placeHolderHorario">  </div>
              </div>

            </div>

            <div className="info flexcenter Fcenter ">
              <div className="nombreEmpresa">
                Detalle del pedido
              </div>
            </div>

          </div>

        }

      </div>

      {
        nivel===1&&
        <div className="bodyCarrito bodyCarrito1">
          {
            Object.entries(listaEmpresas).length===0?
            <div className="nonResult flexcenter Fspaceb" style={{flexDirection:'column',marginTop:'10px'}} >
              <img alt="..." src={img2+'panelAdmin/nonproductos.png'} height={300} />
              <span className="nonResult flexcenter Fcenter" style={{fontSize:'17px'}} > ¡No cuentas con productos en tu carrito! </span>
            </div>:
            Object.entries(listaEmpresas).map(([indexEmpresa,empresa],i) =>(
              <div key={'carritoItemE_'+i} className="auxContainerSucursales" >
              {
                Object.entries(empresa['SUCURSALES']).map(([indexSucursal,sucursal],j) => (
                  <div className="itemEmpresaCarrito flexcenter Fspaceb" key={'carritoItem_'+i+'_'+j} >

                    <div className="flexcenter Fstart" key={'carritoItem_'+i} >
                      <img alt="..." src={srcPerfil(empresa['str'])} onError={props.onErrorImg} />
                      <div className="detalleEmpresa flextop Fstart">

                        <div className="nombreE" > {empresa['nombreE']+' - '+sucursal['nombre']} </div>
                        <div className="precio"> ${formatNumber(getTotalSucursal(sucursal['PRODUCTOS'])-getDescuentoSucursal(sucursal['PRODUCTOS']),2)} MXN</div>
                        <div className="distri"> {getTotalProductosSucursal(sucursal['PRODUCTOS'])} </div>

                      </div>
                    </div>

                    <span className="detail" onClick={()=>detallesSucursal(indexEmpresa,indexSucursal)} > Ver detalles </span>

                  </div>
                ))
              }
              </div>
            ))
          }
        </div>
      }


      {
        nivel===2&&
        <div className="bodyCarrito" style={{margin:'10px 0'}}>
          <div className="itemPaquetitoCarrito flexcenter Fspaceb header">
        
            <div className="infoPack flexcenter Fstart">
              <div className="cnt" > Cantidad </div>
              <div className="midler">
                <div className="nbr" > Nombre </div>
              </div>
            </div>
            
            <div className="precio" style={{paddingRight:'35px'}} > Precio </div>

          </div>
          {
            Object.entries(listaProductos).map(([indexProducto,producto],i) =>(
              <FilaPaquete
                producto={producto}
                key={'carritoItemS_'+i}
                eliminar={confirmarEliminar}
                continuarComprando={continuarComprando}
                getEstatusProducto={getEstatusProducto}
                />
            ))
          }
        </div>
      }

      <div className={"footerCarrito nivel"+nivel}>
        {
          (cambioDetectado||Object.keys(listaProductos).length===0)?
           <React.Fragment/>
          :
          nivel===2?
          <React.Fragment>

            <div className="columner flextop Fspaceb" >
              
              <div className="conlumInner">

                <div className="info flexcenter Fstart">
                  <div className="nombreSuc">
                    Resumen de envío
                  </div>
                </div>

                <div className="totalSucursal flexcenter Fspaceb" style={{padding:'8px 0'}} >
                  <span className="labelForList">Tiempo de entrega:</span>
                  <Popover
                    title={null} trigger="click" placement="bottomRight" overlayClassName="popPerfil rd" destroyTooltipOnHide
                    content={
                      <div className="contenedorCategorias wior">
                        <div className="conTiposEntrea">
                          <div className="conTiposEntreaContent">
                            {
                              listaFormasEntrega.map((entrega,indice) => (
                                <div key={indice+'_entrega'} className={indice===formaEntrega?'intemEntrega selected':'intemEntrega'} onClick={()=>formaEntregaChange(indice)} > {entrega} </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    } >
                    <div id="triggerpope" className="contentData clor flexcenter Fspaceb"> {listaFormasEntrega[formaEntrega]} <Icon type="down"/> </div>
                  </Popover>
                </div>
                
                <div className="subinfo flexcenter Fstart" >
                {
                  formaEntrega===1
                  &&                    
                  <div> {fecha.format('DD/MM/YYYY')} {hora.format('HH:mm')} Hrs. </div>
                }
                </div>

                <div className="totalSucursal flexcenter Fspaceb" >
                  <span className="labelForList">Tipo de entrega:</span>

                  <Popover
                    title={null} trigger="click" placement="bottomRight" overlayClassName="popPerfil rd" destroyTooltipOnHide 
                    content={
                      <div className="contenedorCategorias wior">
                        <div className="conTiposEntrea">
                          <div className="conTiposEntreaContent" >
                            {
                              listaTiposEntrega.map((entrega,indice) => (
                                <div key={indice+'_entrega'} className={indice===tipoEntrega?'intemEntrega selected':'intemEntrega'} onClick={()=>tipoEntregaChange(indice)} > {entrega} </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    } >
                    <div id="triggerpop" className="contentData clor flexcenter Fspaceb"> {listaTiposEntrega[tipoEntrega]} <Icon type="down"/> </div>
                  </Popover>
                </div>

                <div className="subinfo flexcenter Fstart" >
                  <div>
                    {
                      tipoEntrega === 0?
                      domicilioSucursal: formatAdress(listaDirecciones[indiceDireccion])
                    }
                  </div>
                </div>

                {/* nota al vendedor */}
                <div className="btnOrdenar flexcenter Fstart" style={{marginTop:'-10px'}} >
                  <span  onClick={()=>setShowComentario(true)}> Nota al vendedor </span>
                </div>
                {
                  comentario!==''&&
                  <div className="subinfo flextop Fstart" style={{flexDirection:'column'}} onClick={()=>setShowComentario(true)} title="Editar comentario" >
                  {
                    comentario.split('\n').slice(0,2).map((line,i,lines) => (
                      <div> { i===lines.length-1 && i>0? line+'...':line } </div>
                    ))
                  }
                  </div>
                }
                
              </div>

              <div className="conlumInner">

                <div className="info flexcenter Fstart">
                  <div className="nombreSuc">
                    Resumen total
                  </div>
                </div>

                <div className="totalSucursal flexcenter Fspaceb" >
                  <span className="labelForList">Forma de pago:</span>
                  
                  <Popover
                    title={null} trigger="click" placement="bottomRight" overlayClassName="popPerfil rd" destroyTooltipOnHide={true} destroyOnClose
                    content={
                      <div className="contenedorCategorias wior">
                        <div className="conTiposEntrea">
                          <div className="conTiposEntreaContent">
                            {
                              listaFormasDePago[tipoEntrega].map((entrega,indice) => (
                                <div key={indice+'_entrega'} className={indice===formaPago?'intemEntrega selected':'intemEntrega'} onClick={()=>formaDePagoChange(indice)} > {entrega['NOMBRE']} </div>
                              ))
                            }
                            {
                              listaDatosTransferencia.length>0&&
                              <div className={4===formaPago?'intemEntrega selected':'intemEntrega'} onClick={()=>formaDePagoChange(4)} > Transferencia </div>
                            }
                            {
                              listaPagoEstripe.length>0&&
                              <div className={5===formaPago?'intemEntrega selected':'intemEntrega'} onClick={()=>formaDePagoChange(5)} > Pago en linea </div>
                            }
                          </div>
                        </div>
                      </div>
                    } >
                     <div className="contentData clor flexcenter Fspaceb" id='triggerpopdd' title={
                         formaPago===4?
                         'Transferencia':
                         formaPago===5?'Pago en linea':
                         listaFormasDePago[tipoEntrega][formaPago]['NOMBRE']
                       } style={{minWidth:'initial'}}  > 
                       {
                         formaPago===4?
                         'Transferencia':
                         formaPago===5?'Pago en linea':
                         listaFormasDePago[tipoEntrega][formaPago]['NOMBRE']
                       }
                        <Icon type="down"/>
                      </div>
                  </Popover>
                </div>

                <div className="totalSucursal flexcenter Fspaceb" >
                  Subtotal:
                  <div className="contentData"> ${formatNumber(getTotalSucursal(listaProductos),2)} MXN</div>
                </div>

                <div className="totalSucursal flexcenter Fspaceb">
                  Descuento:
                  <div className="contentData"> ${formatNumber(descuentoPedido,2)} MXN</div>
                </div>

                {
                  tipoEntrega===1&&
                  <div className="totalSucursal flexcenter Fspaceb" >
                    Costos de envío:
                    <div className="contentData">  {costoEntrega} </div>
                  </div>
                }

                <div className="totalSucursal flexcenter Fspaceb">
                  Total:
                  <div className="contentData"> ${formatNumber(totalPedido,2)} MXN</div>
                </div>
                
              </div>

            </div>

            {/* 3 botones */}
            <div className="flexcenter Fspaceb">

              <div className="btnOrdenar nosee flexcenter Fcenter" style={{marginRight:'15px'}} onClick={continuarComprando} > Seguir comprando </div>

              <div className="flexcenter Fend" >
                <div className="btnOrdenar nosee flexcenter Fcenter" style={{marginRight:'15px'}}
                  onClick={()=>handlerAction(4,true,"Eliminar","Cancelar","Eliminar carrito",'Al eliminar el carrito se perderán los elementos añadidos.',true,true)}
                  > Eliminar carrito </div>
                <div className="btnOrdenar flexcenter Fend" > <button onClick={sendPedido} > Confirmar </button> </div>
              </div>

            </div>
          </React.Fragment>
          
          :
          <React.Fragment>
            Total: ${formatNumber(footer,2)} MXN
          </React.Fragment>
        }
      </div>

      <Modal centered title="" maskClosable={false} keyboard={false}
        visible={pagading} closable={true} width={400}
        header={null} footer={null} onCancel={unmountCardPayment}
        className="nodalRegistroStripe" wrapClassName="nodalRegistroStripePapa" >

        <div className="contentstripe">

          <div className="body">

            <form id="formulaStripe">

              <div className="bloqueInputs">
                <h4 className="titleB titleLeft"> Método de pago </h4>

                <div className="inputs-group stripePayonline" id="cardElementPay"></div>
                <div className="error-content" id="toshow" style={{marginTop:'0',marginBottom:'15px'}} ></div>
              </div>

            </form>

            <div className={"warning justifed overred"+vermas}>
              <span> Al pagar confirmas que has leído y aceptado los <a target="_blank"  rel="noopener noreferrer" href="https://bubook.nibirusystem.com/legacy/politicadeprivacidad"> términos y condiciones </a> de <i> BuBook.Mx </i>  </span>
              {
                vermas===1?
                <span className="showM" onClick={()=>setVermas(2)} > <sub> Ver más </sub> </span>:
                <span> Asimismo, reconoces que la compra que estás a punto de realizar generará un cargo a tu tarjeta que será transferido directamente hacia la cuenta del vendedor, en donde BuBook solo actúa como la plataforma intermediaria </span>
              }

              {
                vermas===2&&
                <span className="showM" onClick={()=>setVermas(3)} > <sub> Ver más </sub> </span>
              }

              {
                vermas===3&&
                <React.Fragment>
                  <span> que facilita la compra en línea a través del uso de una pasarela de pago. Por lo tanto, esta compra es un acuerdo entre el cliente y el vendedor. Cualquier inconveniente debe ser tratado directamente con la parte que provee el bien o servicio. </span>
                  <span className="showM" onClick={()=>setVermas(1)} > <sub> Ver menos </sub> </span>
                </React.Fragment>
              }

            </div>

            <div className="contButton" >
              <button onClick={validPayPedido} > Confirmar pago </button>
            </div>

          </div>
        </div>
      </Modal>

      <Loading  loading={loading} zIndex={2000} />

    </div>
  )
}

export default CarritoModal;