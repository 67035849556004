import React from 'react';
import axios from 'axios';
import {Globalrequest as request} from './../../../request.js'
import { Host as host,ImgPanelAdmin as panelAdmin,Img as img} from './../../../app.json';

/* Librerías */
import moment from 'moment';
import { Spin,Icon } from 'antd';

class Direcciones extends React.Component{
  constructor(props) {
    super(props);
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.action = this.action.bind(this)
    this.setAllSeen = this.setAllSeen.bind(this)

    this.eliminarNotificacion = this.eliminarNotificacion.bind(this)
    this.clickEliminar = this.clickEliminar.bind(this)

    this.state={
      notificaciones:[],
      notificacionesDetalle:{},
      
      loading:false,
      modalConfirm:false,
      idConfirm:-1,
    }
  }

  componentDidMount(){
    document.querySelector('#fotoCampanitaTrigger').src = panelAdmin+"/notificaciones.svg"
    this.actualizarCatalogo()
  }

  componentWillUnmount(){
    document.querySelector('#fotoCampanitaTrigger').src = panelAdmin+"/notificacionesB.svg"
  }

  actualizarCatalogo(){
    let data = {idB:sessionStorage.getItem('id'),op:2}
    this.setState({loading:true})
    axios.post(`${host}clientes/clientes/notificacionesPorBubooker`,data,request).then(rt=>{
      if(rt.data['status']==='ok'){
        let {notificaciones,notificacionesDetalle} = this.state
        notificaciones = rt.data['array'].map(n => {n.FECHA = moment(n['FECHA_HORA_GENERA'],'YYYY-MM-DD HH:mm:ss').format('DD/MMMM/YYYY'); return n;} )
        notificaciones = notificaciones.reverse()
        notificacionesDetalle = notificaciones.reduce((r, a) => { r[a.FECHA] = [...r[a.FECHA] || [], a]; return r; }, {});
        this.props.stateHandler('countNoti',rt.data['unseen'])
        this.setState({notificaciones,notificacionesDetalle,loading:false})
      }
    })
  }

  getDescripcion(descripcion,tipo){
    tipo = Number(tipo)
    let pos = descripcion.indexOf('Ç-Ç')
    if(pos===-1)
      return descripcion;
    descripcion = descripcion.split('Ç-Ç')
    if(tipo===4||tipo===9)
      return descripcion[1]
    return descripcion[0]
  }

  action(notificacion){
    let {indiceRedirecter,showDataComponentMounted,redirectTo} = this.props
    
    let arrayIDs = ''
    arrayIDs += (notificacion['ID']+'[/**/]')
    sessionStorage.setItem('idNotificacionPorAtenderB',arrayIDs)
    let tipo = Number(notificacion['TIPO'])
    if(tipo === 1 || tipo === 2 || tipo === 12 ){
      
      if(tipo===12){
        arrayIDs += notificacion['ID']+'[/**/]'
        sessionStorage.setItem('idNotificacionPorAtenderB',arrayIDs)  
        sessionStorage.setItem('showPago',1)
      }

      let folio = notificacion['DESCRIPCION'].split('\n').filter(pos => pos.toLowerCase().includes('folio'))
      folio = folio.length>0?folio[0]:''
      if(folio!==''){
        folio = folio.split(' ')[1]||''
        if(folio!==''){
          const componente = showDataComponentMounted()
          if(componente['name']==='Menu'){
            const componente2 = componente['component'].showDataComponentMounted()
            if(componente2 !== null && componente2 !== undefined){
              if(componente2['mounted'] && componente2['name']==='DetalleP'){
                componente2['component'].actualizarDatos(folio)            
              }else{
                componente['component'].redirectTo('/config/pedidos/'+folio)
                return
              }
            }
          }
          indiceRedirecter('/config/pedidos/'+folio)
        }
      }
    }

    else if(tipo === 3 || tipo === 4){//ir a la pagina de la empresa
      let descripcion = notificacion['DESCRIPCION']
      let pos = descripcion.indexOf('Ç-Ç')
      if(pos===-1){
        return
      }else{
        descripcion = descripcion.split('Ç-Ç')
        if(descripcion.length<3)return
        else{
          let link = descripcion[2]
          indiceRedirecter('/empresas/'+link)
          let componente = showDataComponentMounted()
          if(componente['name']==='Perfil')
            componente['component'].actualizarPerfil(link)
        }
      }
    }

    else if( tipo === 9){//ir a la pagina de la empresa
      let descripcion = notificacion['TITULO']
      console.log(descripcion)
      let pos = descripcion.indexOf('Ç-Ç')
      if(pos===-1){
        return
      }else{
        descripcion = descripcion.split('Ç-Ç')
        
        let link = descripcion[descripcion.length - 1]
        indiceRedirecter('/empresas/'+link)
        let componente = showDataComponentMounted()
        if(componente['name']==='Perfil')
          componente['component'].actualizarPerfil(link)
      }
    }

    else if(tipo === 7 || tipo === 8 || tipo === 10 || tipo === 11){
      let arrayIDs = ''
      arrayIDs += (notificacion['ID']+'[/**/]')
      sessionStorage.setItem('idCit',notificacion['ID_MOVIMIENTO'])
      sessionStorage.setItem('idNotificacionPorAtenderB',arrayIDs)
      let componente = showDataComponentMounted()
      indiceRedirecter('/config/citas')
      if(componente['name']==='Menu'){
        let componenteHijo = componente['component'].showDataComponentMounted()
        if(componenteHijo !== undefined && componenteHijo['name']==='Citas')
          componenteHijo['component'].actualizarCatalogo()
        else
          componente['component'].redirectTo('/config/citas')
      }
    }

    document.getElementById('fotoCampanitaTrigger').click()

  }

  getDiff(date){
    date = moment(date,'YYYY-MM-DD HH:mm')
    return moment(date,'YYYY-MM-DD HH:mm').format('HH:mm')
  }

  setAllSeen(){
    let {notificaciones} = this.state
    let idN = ''
    for(let not of notificaciones){
      if(Number(not['ESTATUS'])===1)
        idN+=(not['ID']+'[/**/]')
    }
    let data = {idN,estatus:2}
    this.setState({loading:true,primero:true,modalConfirm:false})
    axios.post(`${host}clientes/clientes/cambiarEstatusNotificacionBubooker`,data,request).then(rt=>{
      if(Number(rt.data)===1){
        this.actualizarCatalogo()
      }
      this.setState({loading:false})
    })
  }

  eliminarNotificacion(){
    let{idConfirm} = this.state
    let data = {idN:idConfirm, bandera:253,}
    this.setState({modalConfirm:false,loading:true})
    axios.post(`${host}clientes/clientes/eliminarNotificacion`,data,request).then(rt=>{
      this.actualizarCatalogo()
    })
  }

  clickEliminar(e,idConfirm){
    e.stopPropagation()
    this.setState({modalConfirm:true,idConfirm})
  }

  formatEstatus(nombre){
    if(nombre.length===0)
      return ""
    nombre = nombre[0]
    nombre = nombre.toLowerCase()
    nombre = nombre.split('á').join('a')
    nombre = nombre.split('é').join('e')
    nombre = nombre.split('í').join('i')
    nombre = nombre.split('ó').join('o')
    nombre = nombre.split('ú').join('u')
    nombre = nombre.split('ü').join('u')
    nombre = nombre.split(' ').join('')
    return nombre
  }

  getSrcNoti(tipo,desc){
    tipo = Number(tipo)
    if(tipo===8){
      desc = desc.split('\n')
      if(desc.length>0){
        desc = this.formatEstatus(desc.splice(-1,1))
        if(['confirmado','asistio','confirmada'].includes(desc))
          return panelAdmin+'notificaciones/n08bB.svg'
      }      
    }
    if(tipo<10)
      tipo = '0'+tipo
    return panelAdmin+'notificaciones/n'+tipo+'b.svg'
  }

  render(){
    return(
  		<div className="contentFavsEmbed" id="contentFavsEmbed2">

        <div className="titleSectionBubooker flexcenter Fspaceb" style={{fontSize:'17px',padding:'10px 15px'}} >
          Notificaciones <span onClick={this.setAllSeen}> Marcar como leídas  </span>
        </div>

        <div>
        
        {
          this.state.loading&&
          <div className="fakeModal flexcenter Fcenter">
            <div className="loadLogin">
              <label style={{color:'white'}} > Cargando </label> <br/>
              <Spin indicator={<Icon type="loading" style={{ fontSize: 50,color:'white'}} />} />
            </div>          
          </div>
        }

        {
          this.state.modalConfirm&&
          <div className="fakeModal flexcenter Fcenter">
            
            <div className="content" style={{width:'350px'}} >
              <h3 className="title"> Eliminar notificacion </h3>
              <div className="body"> ¿Está seguro que desea eliminar esta notificación? </div>
              <div className="foot flexcenter Fend ">
                <button className="btn btn2" onClick={()=>this.setState({modalConfirm:false,idConfirm:1})} > Cancelar </button>
                <button className="btn btn1" onClick={this.eliminarNotificacion} > Aceptar </button>
              </div>
            </div> 

          </div>
        }
          
          <div className="listAdressClient nomchg">
          {
            Object.keys(this.state.notificacionesDetalle).length === 0 && !this.state.loading ? 
            <div className="nonResult flexcenter Fspaceb" style={{flexDirection:'column'}} >
              <img alt="..." src={panelAdmin+'noncitas.png'} height={300} />
              <span className="nonResult flexcenter Fcenter" style={{fontSize:'15px'}} > ¡No se encontraron resultados! </span>
            </div>:
            Object.entries(this.state.notificacionesDetalle).map( ([fecha,notificaciones]) => 
              <div key={'date_'+fecha}>
                <div className="headGris mini">{fecha}</div>
                {
                  notificaciones.map((notificacion,indice) => (
                    <div key={'noti_'+ notificacion['ID'] } className={"notificacion mini flexcenter Fstart estatus"+notificacion['ESTATUS']} onClick={()=>this.action(notificacion)} >
                      <img alt="..." src={this.getSrcNoti(notificacion['TIPO'],this.getDescripcion(notificacion['DESCRIPCION'],notificacion['TIPO']))} onError={e => e.target.src = panelAdmin+'notificaciones/ne01.svg' } />
                      
                      <div className="info" >
                        <div className="titulo" title={this.getDescripcion(notificacion['TITULO'],1)} > {this.getDescripcion(notificacion['TITULO'],1)} </div>
                        <div className={'descripcion'} title={this.getDescripcion(notificacion['TITULO'],notificacion['TIPO'])} >
                          {
                            this.getDescripcion(notificacion['DESCRIPCION'],notificacion['TIPO']).split('\n').map((line,i) =>(
                              <React.Fragment key={'line_'+i} >{line}<br/></React.Fragment>
                            ))
                          }
                        </div>
                        <div className="hace" > {this.getDiff(notificacion['FECHA_HORA_GENERA'])} </div>                          
                      </div>

                      <img className="basurero" src={img+'basurero_gris.png'} alt="..." title='Eliminar notificación' onClick={e => this.clickEliminar(e,notificacion['ID'])} />

                    </div>
                  ))
                }
              </div>
            )
          }
          </div>
        </div>
  		</div>
    );
  }
}

export default(Direcciones);