import React from 'react';

import axios from 'axios';
import 'moment/locale/es'
import moment from 'moment'
import { Globalrequest as request } from './../../../request.js';
import {Host as host } from './../../../app.json'
import {Modal} from 'antd';
import {withRouter} from 'react-router-dom'
import Loading from '../../../modalCargando/loading';

import {formatNumber} from "./../../../utilities/generalfunctions";

import   './clientes.scss';

class Lista extends React.Component{
  constructor(props) {
    super(props);
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)
    this.showDetalle = this.showDetalle.bind(this)
    this.filtrar = this.filtrar.bind(this)

    this.state = {
      loading:false,
      /**/
      clientes:[],
      clientes2:[],
      modalAlert:false,
      txtAlert:'',
      /**/
      modalDetalle:false,
      detalle:{},
      /**/
      filtro1:'',
      /**/
      total:0,
      hombres:0,
      mujeres:0,
      promedio:0,
      nombreEmpresa: JSON.parse(sessionStorage.getItem('metadata'))['datos']['NOMBRE_COMERCIAL']
    }
  }

  componentDidMount(){
    document.title="Mis clientes"
    this.actualizarCatalogo()
  }

  actualizarCatalogo(){
    let {clientes,total,hombres,mujeres,promedio} = this.state
    let data = {idE:sessionStorage.getItem('id')}
    axios.post(`${host}clientes/clientes/listaClientesPorEmpresa`,data,request).then(rt=>{
      clientes = rt.data
      console.log(
        JSON.parse(sessionStorage.getItem('metadata'))
      )
      total = clientes.length
      if(total>0){
        mujeres=0
        hombres=0
        promedio=0
        for(let cliente of clientes){
          promedio+=Number(cliente['EDAD'])
          if(Number(cliente['SEXO']) === 1)
            hombres++
          else
            mujeres++
          
          cliente['PEDIDOS'] = cliente['PEDIDOS'].reduce((r, a) => {
            r[a.FECHA_PEDIDO] = [...r[a.FECHA_PEDIDO] || [], a];
            return r;
          }, {});
        }
        
        promedio = Number(promedio/total).toFixed(2)
        this.setState({
          clientes:clientes,
          clientes2:clientes,
          total:total,
          hombres:hombres,
          mujeres:mujeres,
          promedio:promedio,
          loading:false,
        })
      }
    })
  }

  showDetalle(id){
    let {detalle, clientes} = this.state
    detalle = clientes.filter(cliente => cliente.ID === id)
    detalle = detalle[0]
    this.setState({detalle,modalDetalle:true})
  }

  formatFecha(fecha){
      let fecha2 = moment(fecha,'YYYY-MM-DD')
      return fecha2.format('DD/MMMM/YYYY')
  }

  filtrar(e){
      let {value} = e.target
      let {clientes,clientes2} = this.state
      value = value.trim().toLowerCase()
      if(value===''){
          this.setState({clientes2:clientes})
          return
      }
      clientes2 = clientes
      clientes2 = clientes2.filter(c => c.NOMBRE.toLowerCase().includes(value))
      this.setState({clientes2:clientes2})
  }

  formatNumberCel(num){
    if(num===undefined||num===null)return
    let numa = num.slice(0,3)
    let numb = num.slice(3,6)
    let numc = num.slice(6)
    return '('+numa+') '+numb + ' ' +numc ;
  }

  formatSexo(sexo){
    sexo = Number(sexo)
    return sexo===1?'Hombre':'Mujer'
  }

  showDetallePedido(id){
    sessionStorage.setItem('idPed',id)
    this.props.redirectTo2('/home/pedidos/detalle')
  }

  render(){
    return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3">


            <Loading loading={this.state.loading} />

            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' }}
                cancelButtonProps={{ className:'btn btn2', style: {display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalAlert   ?
                        <span className="txtAlert"> {this.state.txtAlert} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>

            <Modal centered title="Detalle cliente" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
                cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px',display:'none'} }}
                visible={this.state.modalDetalle}
                className="miAntModal alertMy modalUnidad"
                onOk={()=>this.setState({modalDetalle:false,detalle:{}})}
                onCancel={()=>this.setState({modalDetalle:false,detalle:{}})}
              >
              {
                this.state.modalDetalle?
                <div className="detalleCliente mxh">
                  <div className="head">
                    <div className="info"> <div className="meta">Nombre: </div>  <div className="content">{this.state.detalle['NOMBRE']}</div> </div>
                    
                    <div className="info infoInline">
                      <div className="infoInlineChild"><div className="meta">Sexo: </div>  <div className="content">{this.formatSexo(this.state.detalle['SEXO'])}</div></div>
                      <div className="infoInlineChild"><div className="meta">Edad: </div>  <div className="content">{this.state.detalle['EDAD']} años</div></div>
                    </div>

                    <div className="info"> <div className="meta">Correo: </div>  <div className="content">{this.state.detalle['CORREO']}</div> </div>

                    <div className="info"> <div className="meta">Teléfono: </div>  <div className="content">{this.formatNumberCel(this.state.detalle['TELEFONO'])}</div> </div>
                  </div>
                  <div className="titleSeparador"> Compras realizadas </div>
                  <div className="body">
                  {
                    Object.entries(this.state.detalle['PEDIDOS']).map( ([fecha,pedidos]) => 
                      <div key={'pedidosFecha_'+fecha} className="tarjetaPedido" >
                        <div className="headGris flexcenter Fspaceb"> <label> {this.formatFecha(fecha)}</label> <label> {pedidos.length}</label>  </div>
                        <div>
                          {
                            pedidos.map(pedido => (
                              <div key={'pedido_'+pedido.ID} className="detallePedido" onClick={()=>this.showDetallePedido(pedido['ID'])} >
                                
                                <div className="info infoInline">
                                  
                                  <div className="infoInlineChild"> <div className="meta">Sucursal: </div>  <div className="content">{pedido['NOMBRE_SUCURSAL']}</div></div>

                                  <div className="infoInlineChild">
                                    <div className={"content "+pedido['ESTATUS'].toLowerCase()}> {pedido['ESTATUS']} </div>
                                  </div>

                                </div>

                                <div className="info infoInline">
                                  <div className="infoInlineChild"> <div className="meta">Folio: </div>  <div className="content">{pedido['FOLIO']}</div></div>
                                </div>

                                <div className="info infoInline">
                                  <div className="infoInlineChild"> <div className="meta">Hora pedido: </div>  <div className="content"> {pedido['HORA_PEDIDO']} </div></div>
                                  <div className="infoInlineChild"> <div className="meta">Total: </div>  <div className="content">${formatNumber(pedido['TOTAL'],2)} MXN</div></div>
                               </div>

                               {
                                  (
                                    pedido['ESTATUS'].trim().toLowerCase() === 'ENTREGADO'.trim().toLocaleLowerCase() ||
                                    pedido['ESTATUS'].trim().toLowerCase() === 'CANCELADO'.trim().toLocaleLowerCase()
                                  )&&
                                 <React.Fragment>
                                   <div className="spaceMakerCli"></div>
                                   <div className="info"> <div className="meta">Atendió: </div>  <div className="content">{pedido['ATENDIDO_POR'] || this.state.nombreEmpresa }</div> </div>
                                   <div className="info"> <div className="meta">Hora atendió: </div>  <div className="content">{pedido['HORA_ATENDIDO']}</div> </div>
                                 </React.Fragment>
                               }
                              </div>
                            ))
                          }
                        </div>
                      </div>
                    )
                  }
                  </div>
                </div>:<React.Fragment/>
              }
            </Modal>

            <div className="contBuscador">
                <div className="inputs-group">
                    <label className="inputs-label" htmlFor="nombrepd" > Buscar clientes por nombre </label>
                    <input type="text" className="inputs-input" onChange={this.filtrar}/>
                </div>
            </div>

            <div className="infoClientes">
                <div className="info"> <span>Total</span>  <label>{this.state.total}</label> </div>
                <div className="info"> <span>Hombres</span>  <label>{this.state.hombres}</label> </div>
                <div className="info"> <span>Mujeres</span>  <label>{this.state.mujeres}</label> </div>
                <div className="info"> <span>Edad promedio</span>  <label>{Number(this.state.promedio,0)} años</label> </div>
            </div>

            <div className="metaControles">
              <h3 className="tituloPanel"> Clientes </h3>
            </div>

            <div className="cont-Promociones">
              {
                this.state.clientes2.map(cliente => (
                  <div key={'cliente_'+cliente.ID} className="tarjetaCliente" onClick={()=> this.showDetalle(cliente.ID)} >
                    <div className="nombre" title={cliente.NOMBRE} > {cliente.NOMBRE} </div>
                    <div className="mail" title={cliente.CORREO}> {cliente.CORREO} </div>
                  </div>
                ))
              }
            </div>

            <div className="cont-instancias5"></div>
          </div>
        </div>
       </div>
    );
  }
}

export default withRouter(Lista);