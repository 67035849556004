import React from 'react';

import {isLogged} from './../../request.js'

import { Modal} from 'antd';
import { CSSTransition } from "react-transition-group";
import { BrowserRouter , Route, Link,withRouter } from "react-router-dom";
/* Componentes */
import Perfil from './configuracion/configuracion';
import Citas from './citas/lista';
import Direcciones from './direcciones/lista';
import RegistrarDireccion from './direcciones/registrar';
import Historial from './historial/lista';
import MetodosDePago from './metodospago/lista';
import Pedidos from './pedidos/lista';
import DetallesPedido from './pedidos/detalle';

/**/
const rutas = [
  { Name:'Config',path: '/config/perfil', Component: Perfil },
  { Name:'Citas',path: '/config/citas', Component: Citas },
  { Name:'DireccionesA',path: '/config/direcciones', Component: Direcciones },
  { Name:'DireccionesB',path: '/config/direcciones/:name', Component: RegistrarDireccion },
  { Name:'Historial',path: '/config/historial', Component: Historial },
  { Name:'Metodos',path: '/config/metodosdepago', Component: MetodosDePago },
  { Name:'Pedidos',path: '/config/pedidos', Component: Pedidos },
  { Name:'DetalleP',path: '/config/pedidos/:folio', Component: DetallesPedido },
]

class Router extends React.Component{
  
  constructor(props) {
    super(props);

    this.redirectTo = this.redirectTo.bind(this)
    this.redirectToDefinitivo = this.redirectToDefinitivo.bind(this)
    this.setMensaje = this.setMensaje.bind(this)
    this.updateState = this.updateState.bind(this)

    this.showDataComponentMounted = this.showDataComponentMounted.bind(this)
    this.actualizarFavoritos = this.actualizarFavoritos.bind(this)

    this.state={
      modal:false,
      actualpage:'',
      actualpage2 : '',
      nopuedoCambiarSinAvisarte:false,
      msjBeforeGo:'',
    }
  }

  componentDidMount(){

    window.addEventListener('resize', this.ajustarAltoContenido);
    this.ajustarAltoContenido()

    let {makeRef,indiceRedirecter} = this.props
    if(!isLogged()){
      indiceRedirecter('/')
      return
    }else
      makeRef({mounted:true,component:this,name:'Menu'})
  }

  componentWillUnmount(){
    window.removeEventListener('resize', this.ajustarAltoContenido);
  }

  actualizarFavoritos(){}

  ajustarAltoContenido(){
    try{
      const total = document.documentElement.clientHeight
      const header = document.querySelector('#headIndex').clientHeight
      const footer = document.querySelector('#footerNotAlways').clientHeight
      const result = total-header-footer
      const body = document.querySelector('#contentIndexWrapper')
      if(body){
        console.log('ajustarAltoContenido',result)
        body.style.minHeight = `${result}px`
      }
    }finally{}
  }

  redirectTo(url){    
    let {nopuedoCambiarSinAvisarte} = this.state
    if(nopuedoCambiarSinAvisarte){
      this.setState({actualpage2:url,modal:true})
    }
    else{
      this.setState({actualpage:url})
      setTimeout(function(){ document.getElementById('auxToBB').click() },1);
    }
  }

  redirectToDefinitivo(){
    let {actualpage2} = this.state
    this.setState({actualpage:actualpage2,actualpage2:'',modal:false,nopuedoCambiarSinAvisarte:false})
    setTimeout(function(){ document.getElementById('auxToBB').click() },1);
  }

  setMensaje(a){
    let msjs = [
      'Los cambios realizados en su configuración se perderán',
    ]
    this.setState({msjBeforeGo:msjs[a]})
  }

  updateState(campo,valor){
    this.setState({[campo]:valor})
  }

  showDataComponentMounted(){
    return this.child
  }

  render(){
    return(
      <BrowserRouter >
        <Modal centered title="¿Desea salir sin guardar cambios?" maskClosable={false} keyboard = {false} 
            okText="Continuar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
            cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
            visible={this.state.modal}
            closable={false}
            onOk={this.redirectToDefinitivo}
            onCancel={()=>this.setState({modal:false})}
            className="miAntModal newVar alertMy"
            >
            {
              this.state.modal?
              <div className="myAlertBody">
                <span className="txtAlert"> {this.state.msjBeforeGo} </span>
            </div>:<React.Fragment/>
            }
        </Modal>
        <Link to={this.state.actualpage} id="auxToBB"/>
        {
          rutas.map(({path, Name ,Component }) => (
            <Route key={path} exact path={path}  >
            {({ match }) => (
              <CSSTransition in={match != null} timeout={500} classNames="page" unmountOnExit > 
                <Component
                  cerraSesion={this.props.cerraSesion}
                  redirectTo={this.redirectTo}
                  setMensaje = {this.setMensaje}
                  updateState={this.updateState}
                  stateHandler = {this.props.stateHandler}
                  indiceRedirecter = {this.props.indiceRedirecter}
                  makeRef={component => this.child = component }
                  setsrcPerfil={this.props.setsrcPerfil}
                /> 
              </CSSTransition>
              )}
            </Route>
          ))
        }
      </BrowserRouter>
    )
  }
}

export default withRouter(Router);