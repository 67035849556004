import React from 'react';

const permisosGral = {
  usuarios:1,//
  sucursales:2,//
  infor:3,//
  redes:4,//
  metodoPago:5,//
  suscripcion:6,
  productos:7,
  promociones:8,
  categorias:9,//
  unidades:10,//
  pedidos:11,//
  sinexistencia:12,//
  citas:13,
  citasDATA:14,//
  resenas:15,//
  estadisticas:16,//
}

const userContext = React.createContext(
  {
  	type:0,//admin 1 usuario 2
  	data:{},
  }
);

export {permisosGral,userContext};