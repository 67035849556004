import React from 'react';
import axios from 'axios';
import { Globalrequest as request } from './../request.js';
import {Host as host} from './../app.json'
import {  withRouter  } from "react-router-dom";


class AltaStripe extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            mail: props.match.params.var,
        }
    }
    componentDidMount(){}
    
    enviar(){
        let url = document.getElementById('url').value
        let json = document.getElementById('json').value
        axios.post(`${host}${url}`,json,request).then(rt=>{
            alert('Yacabó')
        })        
    }

    render(){
        return(
            <div className='cont-Aviso' style={{padding:'50px'}} >
                <h2>El valor de la variable es: {this.state.mail}</h2>
                <a href={'intent:/#Intent;scheme=bubook;package=com.nibirusystem.android.bubook;S.codigo='+this.state.mail+';end'}>UmU</a><br/><br/><br/><br/>
                <label> Url </label>
                <input type="text" id="url" style={{width:'100%'}} />
                <label> JSON </label>
                <textarea id="json" style={{width:'100%'}}> </textarea>
                <button onClick={this.enviar} >Enviar</button>
            </div>
        );
    }
}
export default withRouter(AltaStripe);