import React from 'react';
import {Modal } from 'antd';
import {withRouter} from 'react-router-dom'
import Loading from '../../../modalCargando/loading';
import   './transacciones.scss';

class Lista extends React.Component{
  constructor(props) {
    super(props);
    this.actualizarCatalogo = this.actualizarCatalogo.bind(this)

    this.state = {
      loading:false,
      modalAlert:false,
      txtAlert:'',
    }
  }

  componentDidMount(){
    document.title="Mis transacciones"
    this.actualizarCatalogo()
  }

  actualizarCatalogo(){
    /*let data = {id:sessionStorage.getItem('id')}
    this.setState({loading:true})
    axios.post(`${host}productos/productos/catalogoDeUnidades`,data,request).then(rt=>{
      this.setState({catalogoDeUnidades:rt.data,catalogoDeUnidades2:rt.data,loading:false})
    })*/
  }

  render(){
    return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" >
          <div className="cont-sucursales3">


            <Loading loading={this.state.loading} />

            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' }}
                cancelButtonProps={{ className:'btn btn2', style: {display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalAlert   ?
                        <span className="txtAlert"> {this.state.txtAlert} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>


            <div className="metaControles">
              <h3 className="tituloPanel"> Transacciones </h3>
            </div>
            
            <div className="cont-instancias5"></div>

          </div>
        </div>
       </div>
    );
  }
}

export default withRouter(Lista);