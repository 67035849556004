import React from 'react';

import axios from 'axios';
import moment from 'moment';
import { Globalrequest as request } from './../../../request.js';
import {Host as host ,  Img as img} from './../../../app.json'
import Cropper from 'react-cropper';
import NumberFormat from 'react-number-format';

import Loading from '../../../modalCargando/loading';
import Alert from './../../../modalCargando/modalAlert';
import NotFound from './../../../modalCargando/notfound';

import { Select , Modal, Icon, Popover , Switch , TimePicker, message ,Input, Checkbox} from 'antd';

import {withRouter} from 'react-router-dom'
import   './productos.scss';

import {userContext} from './../context'

class Editar extends React.Component{
  
  static contextType = userContext

  constructor(props) {
    super(props);
    /*  */

    this.getCatalogoDeCategorias = this.getCatalogoDeCategorias.bind(this)
    this.getCatalogoDeUnidades = this.getCatalogoDeUnidades.bind(this)
    this.initialize = this.initialize.bind(this)
    
    /***/

    this.prensenteEn = this.prensenteEn.bind(this)
    this.timePrepa = this.timePrepa.bind(this)
    this.changeMinimo = this.changeMinimo.bind(this)
    this.changeMinimoPropio = this.changeMinimoPropio.bind(this)
    /*  */
    this.actualizarSucursales = this.actualizarSucursales.bind(this)
    /*  */
    this.changeState = this.changeState.bind(this)
    this.changeStateU = this.changeStateU.bind(this)
    this.showModalVariante = this.showModalVariante.bind(this)
    this.controlStock = this.controlStock.bind(this)
    this.hideModalVariante = this.hideModalVariante.bind(this)
    this.agregarVariante = this.agregarVariante.bind(this)
    this.removeVariante = this.removeVariante.bind(this)
    
    this.fileChange = this.fileChange.bind(this)
    this.cancelCroper = this.cancelCroper.bind(this)
    this.aceptCroper = this.aceptCroper.bind(this)
    this.clearSrc = this.clearSrc.bind(this)
    this.changePrincipal = this.changePrincipal.bind(this)
    this.changeStatus = this.changeStatus.bind(this)

    this.submit = this.submit.bind(this)
    this.cancelar = this.cancelar.bind(this)

    this.validarDatosProducto = this.validarDatosProducto.bind(this)

    this.modalEliminarSucursal = this.modalEliminarSucursal.bind(this)
    this.modalDesactivarVariantes = this.modalDesactivarVariantes.bind(this)

    this.changeSizeTextArea = this.changeSizeTextArea.bind(this)

    this.subcampoChange = this.subcampoChange.bind(this)

    this.addCategoria = this.addCategoria.bind(this)
    this.addUnidad = this.addUnidad.bind(this)

    this.verifyValiditySuc = this.verifyValiditySuc.bind(this)

    this.okNivel3 = this.okNivel3.bind(this)

    this.state = {
      productoNotFound:false,
      idNotificacionPorAtender:sessionStorage.getItem('idNotificacionPorAtender'),
      /**/
      ultiomNivelCategoria:0,
      /**/
      folder:sessionStorage.getItem('folder'),
      /**/
      rows: 1,
      minRows: 1,
      maxRows: 10,
      /* */
      reemplazanding:false,
      idP:sessionStorage.getItem('idP'),
      loading:false,
      loading2:false,
      loading3:false,
      /* */
      productos : [],
      categorias : [],
      sucursales : this.props.sucursales,
      /**/
      catalogoDeCategorias:[],
      catalogoDeUnidades:[],
      categoriaActual:'',
      unidadActual:'',
      /**/
      modalAdvertencia:false,
      modalVariantes:false,
      /* */
      modalEliminarSucursal:false,
      modalDesactivarVariantes:false,
      modalDesactivarStock:false,
      modalCroper:false,
      modalAlert:false,
      modalRespuesta:false,
      /* */
      idImgActual:'',
      /* */
      txtRespuesta:'',
      txtAlert:'',
      /* */
      varianteActual : {
          sucursales_v:[],
          src:'',
      },
      indexVarianteActual:0,
      srcVariante:'',
      /* */
      variantesEliminadas:false,
      stocksEliminados:false,
      imagenes : [
        {},
        {},
        {},
        {},
        {},
      ],
      forfilea : '',
      /* */
      stocks : [],
      variantes: [],
      usarVariantes:false,
      usarStock : false,
      stockPropio:[],
      newProducto : {
          variantes : [],
          nombre:'',
          precio:'',
          tipo:1,
          descripcion:'',
          categoria:'',
          categoriap:'',
          unidadMedida:'',
          peso:'',
          status:'',
          tiempoPreparacion:'',
          unidadp:'',
          Codigo:'',
      },
      contForFiles:0,
      interseccionActual:[],
      suffix:'',

      /**/
      agregarEditarUnidad:false,
      agregarEditarCate:false,

      titleNivel3:'',
      modalNivel3:false,
      txtNivel3:'',
      opNivel3:-1,
    }
  }

  prensenteEn(e , indice ){
    let {validCurrentUser,updateState} = this.props
    let {target} = e
    let { stockPropio } = this.state

    let permiso = e.target.checked ? 'CREAR' : 'ELIMINAR'

    if(!validCurrentUser(this.context,'productos','bool',stockPropio[indice]['code'],permiso) ){
      target.checked = !target.checked
      this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para '+permiso.toLowerCase()+' Productos y Servicios en esta sucursal'})
      return
    }
    updateState('nopuedoCambiarSinAvisarte',true)

    stockPropio[indice]['aquihayProducto'] = target.checked
    this.setState({stockPropio:stockPropio})
  }
  
  timePrepa( value, values){
    this.props.updateState('nopuedoCambiarSinAvisarte',true)
    this.setState({
      newProducto:{
        ...this.state.newProducto,
        tiempoPreparacion:value,
      }
    })
  }
  
  changeMinimo(e,ivar,istock,bandera){
      let {variantes} = this.state
      let {target} = e
      variantes[ivar]['sucursales_v'][istock][bandera] = target.value
      this.setState({variantes:variantes})
  }

  changeMinimoPropio(e,indiceStock,bandera){
      let {stockPropio} = this.state
      let {target} = e
      stockPropio[indiceStock][bandera] = target.value
      this.setState({stockPropio:stockPropio})
  }

  actualizarSucursales(){
    let data = JSON.parse(sessionStorage.getItem('metadata'))
    this.props.setSucursales(data['sucursales'])
    this.setState({ sucursales : data['sucursales'] , sucursales2 : data['sucursales'] ,datos : data['datos'], loading:false, })
    this.props.editState('datos',data['datos'])
    this.props.editState('sucursales',data['sucursales'])
    this.props.editState('categorias',data['categorias'])
    this.props.editState('tags',data['tags'])
    this.props.editState('notificaciones',data['notificaciones'])
  }

  componentDidMount(){
    document.title = "Editar producto"
    let {idNotificacionPorAtender} = this.state
    if(idNotificacionPorAtender !== 'null'){
      let data = {idN:idNotificacionPorAtender,estatus:2}
      axios.post(`${host}clientes/clientes/cambiarEstatusNotificacion`,data,request)
      sessionStorage.setItem('idNotificacionPorAtender','null')
    }
    this.props.setMensaje(2)

    let {idP} = this.state
    if(idP !== null && idP !== undefined){
      sessionStorage.removeItem('idP')
      this.getCatalogoDeCategorias(false,true);
      if(this.state.sucursales.length === 0){this.actualizarSucursales(); }
    }
    else{
        this.props.redirectTo2('/home/productos')
    }
  }

  initialize(){
    let {idP} = this.state
    let data = {id : idP}
    this.setState({loading3:true})
    axios.post(`${host}productos/productos/radiografiaDeProducto`,data,request).then(rt=>{
      if(rt.data === -1){
        this.setState({productoNotFound:true})
        return
      }
      let stockPropio = []
      let {validCurrentUser} = this.props
      let editable = true
      for(let stock of rt.data['stockPropio']){
        stock['aquihayProducto'] = Number(stock.ID) !== 0
        if(stock['aquihayProducto'] && editable){
          editable = validCurrentUser(this.context,'productos','bool',stock['code'],'EDITAR')
        }        
        stockPropio.push(stock)
      }


      let {catalogoDeCategorias,catalogoDeUnidades,contForFiles,folder,categoriaActual,unidadActual,suffix} = this.state
      let dess = rt.data['DESCRIPCION'].split('[/*/r/n*/]').join('\r\n')
      let nrows = rt.data['DESCRIPCION'].split('\r\n')
      nrows = nrows.length
      setTimeout(()=>{
        this.descipcionproducto.setState({value:dess})
      },100)


      if(Number(rt.data['TIPO'])===2){
        document.getElementById('formDelNuevoProducto').style.width="100%"
      }

      let imagenes = rt.data['imagenes']
      for(let i=0;i<5;i++){
        if( imagenes[i] === undefined || imagenes[i] === null ){
          imagenes[i] = {}
          imagenes[i]['ID'] = '0'
          imagenes[i]['ID_PRODUCTO'] = rt.data['ID']
          imagenes[i]['TIPO'] = '2'
          imagenes[i]['DIRECCION_IMG'] = ''
          imagenes[i]['new_DIRECCION_IMG'] = ''
          imagenes[i]['ELIMINADO'] = 2
        }else{
          imagenes[i]['new_DIRECCION_IMG'] = folder+rt.data['FOLDER']+'/'+imagenes[i]['DIRECCION_IMG'] + '.png?'+Math.random()
          imagenes[i]['ELIMINADO'] = 1
          contForFiles++
        }

      }
      for(let i=0;i<5;i++){
        if( imagenes[i]['ID'] === '0'){
          imagenes[i]['DIRECCION_IMG'] = this.dimeLibre(imagenes);
        }
      }

      if(rt.data.USUAR_VARIENTES === '1'){//variantes
          let variantes = []
          for(let variante of rt.data['variantes']){
              let new_var = {}
              new_var['id'] = variante['ID'];
              new_var['nombre_v'] = variante['NOMBRE'];
              new_var['precio_v'] = variante['PRECIO'];
              new_var['codigo_v'] = variante['CODIGO'];
              new_var['src'] = variante['IMAGEN']
              new_var['src_new'] = variante['IMAGEN'] === '' ? '' : folder+rt.data['FOLDER']+'/'+variante['IMAGEN'] + '.png?'+Math.random()
              new_var['precargada'] = true
              new_var['sucursales_v'] = []
              new_var['activa'] = true
              new_var['editable'] = true
              for(let stock of variante['stockPropio']){
                  let newStock={}
                  newStock['idsucursal'] = this.ramdomFunc(stock['NOMBRE_SUCURSAL'])//stock['ID_SUCURSAL']
                  newStock['idalmacen'] = stock['ID']
                  newStock['nomsucursal'] = stock['NOMBRE_SUCURSAL']
                  newStock['minimo'] = stock['STOCK_MINIMO']
                  newStock['existencia'] = stock['EXISTENCIA_ACTUAL']
                  newStock['activa'] = Number(stock['ID'])!==0
                  newStock['code'] = stock['code']
                  if(new_var['editable'] && newStock['activa'] ){
                    new_var['editable'] = validCurrentUser(this.context,'productos','bool',newStock['code'],'EDITAR')
                  }
                  new_var['sucursales_v'].push(newStock)
              }
              variantes.push(new_var)
          }
          editable = variantes.every(vari => vari['editable'] )
          this.setState({variantes:variantes})
      }
      if(rt.data.USAR_STOCK === '1'){ }//stocks
      categoriaActual=''
      for(let categoria of catalogoDeCategorias){
          if(rt.data['CATEGORIA']===categoria.DESCRIPCION){categoriaActual = categoria.ID;break;}
      }
      unidadActual=''
      suffix = ''
      for(let unidad of catalogoDeUnidades){
          if(rt.data['UNIDAD_MEDIDA']===unidad.DESCRIPCION){unidadActual=unidad.ID;suffix=unidad.DESCRIPCION;break;}
      }

      this.setState({
        editable,
        stockPropio:stockPropio,
        rows:nrows,
        loading:false,
        tipoString:Number(rt.data['TIPO'])===1?'producto':'servicio',
        newProducto:{
          ...this.state.newProducto,
          nombre:rt.data['NOMBRE'],
          precio:rt.data['PRECIO'],
          tipo:rt.data['TIPO'],
          descripcion:dess,
          Codigo:rt.data['CODIGO'],
          categoria:'',
          categoriap:'',
          unidadMedida:'',
          unidadp:'',
          peso:rt.data['PESO'],
          tiempoPreparacion:rt.data['TIEMPO_PREPARACION'],
          status: rt.data['ESTATUS'] === '' ? 1 : rt.data['ESTATUS'],
          id:rt.data['ID'],
        },
        usarStock:rt.data.USAR_STOCK === '1',
        usarVariantes:rt.data.USUAR_VARIENTES === '1',
        imagenes:imagenes,forfilea : imagenes[0]['DIRECCION_IMG'],contForFiles:contForFiles,
        categoriaActual,
        unidadActual,
        suffix,
        loading3:false,
      })
    })
  }

  getCatalogoDeCategorias(registre_=false,isFirst=false){
    let data = {id:sessionStorage.getItem('id')}
    if(this.state.sucursales.length === 0){this.actualizarSucursales(); }
    this.setState({loading:true})
    axios.post(`${host}productos/productos/catalogoDeCategorias`,data,request).then(rt=>{
      let {ultiomNivelCategoria,categoriaActual,catalogoDeCategorias,txtAlert,modalAlert} = this.state
      catalogoDeCategorias = rt.data.sort((a,b) => Number(a.NIVEL) - Number(b.NIVEL) )
      if(catalogoDeCategorias.length === 0){ultiomNivelCategoria = 1}
      else{
        let aux = catalogoDeCategorias.slice(0)
        aux = aux.sort((a,b) => Number(a.NIVEL) - Number(b.NIVEL) ).reverse()
        ultiomNivelCategoria = Number(aux[0]['NIVEL']) + 1
      }
      if(registre_){
        categoriaActual = catalogoDeCategorias[catalogoDeCategorias.length-1]['ID']
        txtAlert = "El registro de la categoría fue correcto"
        modalAlert=true
      }
      else{
        categoriaActual = ''
        txtAlert = ""
        modalAlert = false
      }
      this.setState({catalogoDeCategorias,ultiomNivelCategoria,categoriaActual,txtAlert,modalAlert,loading:false})
    }).finally(()=>{if(isFirst){this.getCatalogoDeUnidades(false,true)}} )
  }

  getCatalogoDeUnidades(registre_=false,isFirst=false){
    let data = {id:sessionStorage.getItem('id')}
    this.setState({loading2:true})
    axios.post(`${host}productos/productos/catalogoDeUnidades`,data,request).then(rt=>{
      let {txtAlert,modalAlert,catalogoDeUnidades,unidadActual} = this.state
      catalogoDeUnidades = rt.data
      if(registre_){
        txtAlert = 'El registro de la unidad de medida fue correcto'
        modalAlert=true
        unidadActual = catalogoDeUnidades[catalogoDeUnidades.length-1]['ID']
      }else{
        txtAlert=''
        modalAlert=false
        unidadActual=''
      }
      this.setState({loading2:false,catalogoDeUnidades,txtAlert,modalAlert,unidadActual})
    }).finally(()=>{if(isFirst){this.initialize()}} )
  }



  dimeLibre(ob){
    let id = ob[0]['ID_PRODUCTO'];
    for(let i=0;i<5;i++){
      let nombreAux = 'ImagenProducto'+i+'['+id+']'
      if(!ob.some(o => o.DIRECCION_IMG === nombreAux))return nombreAux;
    }
  }
  
  ramdomFunc(name){
    let {sucursales} = this.state
    for(let sucursal of sucursales){
        if(sucursal.NOMBRE === name)return sucursal.ID
    }
  }

  changeState(campo,valor){
    if(campo==='categoriaActual'&&valor==='-1'){
      this.setState({agregarEditarCate:true})
    }else{
      this.props.updateState('nopuedoCambiarSinAvisarte',true)
      this.setState({[campo]:valor})
    }
  }

  changeStateU(campo,valor){
    if(valor==='-1'){
      this.setState({agregarEditarUnidad:true})
    }else{
      let {catalogoDeUnidades,suffix} = this.state
      let aux = catalogoDeUnidades.filter(c => c.ID === valor)
      suffix = aux.length > 0 ? aux[0]['DESCRIPCION'] : ''
      this.setState({[campo]:valor,suffix:suffix})
    }
  }

  showModalVariante(variante,c=1){//indice variantes, 
      let {usarVariantes,editable} = this.state

      if(c===0 && !editable){
        this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Productos y Servicios sobre todas las sucursales relacionadas con el registro actual.'})
        return
      }

      if(c===0 && usarVariantes){
          this.setState({modalDesactivarVariantes:true})
          return
      }

      let { interseccionActual , sucursales , variantes} = this.state

      let aux = variante === -1 ? [] : variantes[variante]['sucursales_v']
      let  a = sucursales.map( e => e.NOMBRE )
      let  b = []
      for(let au of aux){
          if(au.activa){
              b.push(au.nomsucursal)
          }
      }
      interseccionActual = a.length === 1 ? [true] : a.map( e => b.includes(e) ) ;
      if(variante===-1){
        if(!this.validarDatosProducto("var"))return
        this.setState({
          varianteActual : {
              nombre_v:'',
              precio_v:'',
              codigo_v:'',
              sucursales_v:[],
              src:'',
              src_new:'',
          },
          modalVariantes:true,
          indexVarianteActual:variante,
          interseccionActual:interseccionActual
        })
      }else{
        this.setState({
          varianteActual : variantes[variante],
          modalVariantes:true,
          indexVarianteActual:variante,
          interseccionActual:interseccionActual,
          srcVariante: variantes[variante]['src_new'] //=== '' ? variantes[variante]['src'] : variantes[variante]['src_new']
        })
      }
  }
  
 controlStock(){
    let {usarStock,stockPropio,usarVariantes,editable,tipoString} = this.state

    if(!editable){
      this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Productos y Servicios sobre todas las sucursales relacionadas con el registro actual'})
      return
    }


    if(!usarStock){
      if(!this.validarDatosProducto())return
      if(!usarVariantes && !stockPropio.some(stock => stock.aquihayProducto)){
        this.setState({txtAlert: `El ${tipoString} debe estar en al menos una sucursal`,modalAlert:true})
        //this.setState({txtAlert:'El producto debe estar en al menos una sucursal',modalAlert:true})
        return
      }
      this.setState({usarStock:true})
    }else{
        this.setState({modalDesactivarStock:true})
    }
  }

  hideModalVariante(modal){
    if(modal==='modalVariantes'){
      this.setState({
        modalVariantes:false,
        indexVarianteActual:-2,
        varianteActual : {
            sucursales_v:[]
        },
      })
    }
  }
  agregarVariante(){
    let variante = {}
    let {sucursales,variantes,indexVarianteActual,usarVariantes,newProducto,srcVariante} = this.state
    let formData = new FormData(document.getElementById('formulaNewVariante'))
    if(
        formData.get('nombre_v').trim()===''||
        formData.get('precio_v').trim()===''
        //||formData.get('codigo_v').trim()===''
    ){
        //this.showError('No debe haber capos vacíos','spanerrorVar1')
        this.setState({txtAlert:'No debe haber capos vacíos en la variante',modalAlert:true})
        return
    }
    
    let found = false
    let nombresiguales = variantes.filter(v => v.nombre_v.trim().toLowerCase() === formData.get('nombre_v'))
    if(
      (indexVarianteActual === -1 && nombresiguales.length>0) ||
      (indexVarianteActual!==-1 && nombresiguales>1) )found=true;
    /*for(let variante in variantes){
        if(Number(variante) !== indexVarianteActual && indexVarianteActual !== -1 ){
            if(variantes[variante]['nombre_v'].trim().toLowerCase()===formData.get('nombre_v').trim().toLowerCase())found=true
        }
    }*/

    //console.log(variantes,variante,indexVarianteActual)

    if(found || (newProducto.nombre.trim().toLowerCase()===formData.get('nombre_v').trim().toLowerCase())){
        //this.showError('El nombre de cada variante debe ser único','spanerrorVar1')
        this.setState({txtAlert:'El nombre de cada variante debe ser único',modalAlert:true})
        return
    }
    variante['nombre_v'] = formData.get('nombre_v')
    variante['precio_v'] = formData.get('precio_v')
    variante['codigo_v'] = formData.get('codigo_v')
    variante['src'] = formData.get('src')
    variante['src_new'] = srcVariante;
    variante['precargada'] = true;
    variante['activa'] = true
    variante['sucursales_v'] = []

    formData = new FormData(document.getElementById('missucs'))
    let indicesSucursal = []
    let c = 0
    for(let a of formData.entries()){
        indicesSucursal.push(a[0].split('sucursal_').join(''))
        c++
    }
    if(c===0){
        this.setState({txtAlert:'La variante debe encontrarse al menos en una sucursal',modalAlert:true})
        //this.showError('La variante debe encontrarse al menos en una sucursal','spanerrorVar1')
        return
    }
    
    /*TODO CORRECTO*/

    let variantePresenteEn = indicesSucursal.map( element => sucursales[element]['NOMBRE'] )

    if(indexVarianteActual===-1){//Creando una nueva variante
        variante['id'] = 0;
        variante['precargada'] = false
        for(let sucursal of sucursales){
            let newStock={}
            newStock['idsucursal'] = sucursal.ID
            newStock['idalmacen'] = 0
            newStock['nomsucursal'] = sucursal.NOMBRE
            newStock['minimo'] = 1
            newStock['existencia'] = 1
            newStock['activa'] = variantePresenteEn.some(nombre => nombre === sucursal.NOMBRE)
            variante['sucursales_v'].push(newStock)
        }
        variantes.push(variante)
        usarVariantes=true
    }
    else{
        variante['id'] = variantes[indexVarianteActual]['id']
        for(let sucursal_v of variantes[indexVarianteActual]['sucursales_v']){
            let newStock={}
            newStock['idsucursal'] = sucursal_v['idsucursal']
            newStock['idalmacen'] = sucursal_v['idalmacen']
            newStock['nomsucursal'] = sucursal_v['nomsucursal']
            newStock['minimo'] = sucursal_v['minimo']
            newStock['existencia'] = sucursal_v['existencia']
            newStock['activa'] = variantePresenteEn.some(nombre => nombre === sucursal_v.nomsucursal)
            variante['sucursales_v'].push(newStock)
        }
        variantes[indexVarianteActual] = variante
    }   
    this.setState({ variantes:variantes ,usarVariantes:usarVariantes,srcVariante:''})
    this.hideModalVariante('modalVariantes')
  }

  removeVariante(indice){
    this.props.updateState('nopuedoCambiarSinAvisarte',true)
    let {variantes} = this.state
    variantes[indice]['activa'] = false
    this.setState({variantes:variantes,modalEliminarSucursal:false})
    if(!variantes.some(variante => variante.activa))this.setState({usarStock:false,usarVariantes:false,})
  }

  fileChange(e,index=-1){
    let {id,files} = e.target
    if(files.length===0)return
    if(!files[0].type.includes('image/')){
        this.setState({txtAlert:'Solo puedes elegir imagenes',modalAlert:true})
        return;
    }
    this.cropper.replace(URL.createObjectURL(files[0]))
    e.target.value = ''
    if(index===-1)this.setState({modalCroper:true,idImgActual:id})
    else this.setState({modalCroper:true,idImgActual:index})
  }

  cancelCroper(){
      this.setState({idImgActual:'',modalCroper:false})
  }

  aceptCroper(){
    this.props.updateState('nopuedoCambiarSinAvisarte',true)
    let {idImgActual,contForFiles,reemplazanding,imagenes} = this.state
    let src = this.cropper.getCroppedCanvas().toDataURL()
    if(idImgActual==="srcVariante"){
        this.setState({ srcVariante : src,modalCroper:false })
        document.getElementById('for'+idImgActual).src=src
        document.getElementById('maskvariante').style.display='block'
    }
    else{
      let posAct = (reemplazanding || contForFiles === 5) ? idImgActual : contForFiles;
      if(contForFiles===5)contForFiles=4
      contForFiles = reemplazanding ? contForFiles : (contForFiles+1)
      imagenes[posAct]['new_DIRECCION_IMG'] = src
      imagenes[posAct]['ELIMINADO'] = 1
      imagenes[posAct]['TIPO'] = Number(posAct) === 0 ? '1' : '2'
      this.setState({reemplazanding:false,modalCroper:false,imagenes:imagenes,contForFiles})
    }
  }
  clearSrc(id,flag,e){
      if(flag===1){
          let {imagenes,contForFiles} = this.state
          imagenes[id]['ELIMINADO'] = 2
          imagenes[id]['new_DIRECCION_IMG']=''
          if(id===0){
            imagenes[0]['TIPO'] = '2'
            if(contForFiles>1)
              imagenes[1]['TIPO'] = '1'
          }

          this.setState({imagenes,contForFiles:(contForFiles-1)})
      }else{
          let {srcVariante} = this.state
          srcVariante = ''
          this.setState({srcVariante:srcVariante})
          document.getElementById('for'+id).src = img+'new_camera_icon_prod.png'
          document.getElementById('maskvariante').style.display='none'
      }
      e.preventDefault()
  }

  changePrincipal(indexNewPrin){
    let { imagenes } = this.state
    imagenes[indexNewPrin]['TIPO'] = '1'
    imagenes[0]['TIPO'] = '2'
    this.setState({ imagenes:imagenes})
  }

  changeStatus(){
    let {newProducto,editable,tipoString} = this.state
    
    if(!editable){
      this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Productos y Servicios sobre todas las sucursales relacionadas con el registro actual.'})
      return
    }

    let state
    if(newProducto['status'] === '1'){
      state='2'
      message.success(`El ${tipoString} ha sido desactivado`)
    }else{
      state='1'
      message.success(`El ${tipoString} ha sido activado`)
    }
    newProducto['status'] = state
    this.setState({newProducto:newProducto})
  }

  submit(){
    let { categoriaActual , newProducto ,unidadActual,imagenes,suffix,usarVariantes,tipoString} = this.state

    let formData = new FormData(document.getElementById('formuladatosproducto'))

    let converter={
      nombrep:'Nombre',
      preciop:'Precio',
      descripcionp:'Descripción',
    }
      
    for(let a of formData.entries()){
      if( a[0]==='pesop' || a[0]==='timeprepap' || a[0]==='codigop')continue
      
      if(a[0] === 'preciop' && usarVariantes)continue

      if(a[1].trim()===''){
        this.setState({txtAlert:"El campo '"+converter[a[0]] +"' es obligatorio",modalAlert:true})
        return
      }
    }
    
    newProducto['peso'] = formData.get('pesop')
    newProducto['peso'] = newProducto['peso'].split(suffix).join('').trim()
    
    newProducto['tiempoPreparacion'] = formData.get('timeprepap')+':00'
     
    if(categoriaActual === ''){
      this.setState({txtAlert:"El campo 'Categoría' es obligatorio",modalAlert:true})
      return
    }

    if(unidadActual === ''){
      this.setState({txtAlert:"El campo 'Unidad de medida' es obligatorio",modalAlert:true})
      return
    }
     
    let categoriap = Number(categoriaActual) === -1 ? formData.get('categoriap') : ''
    let unidadp = Number(unidadActual) === -1 ? formData.get('unidadp') : ''
    
    document.getElementById('spanerrorVar2').style.display='none'

    let{usarStock,variantes,variantesEliminadas,stocksEliminados,stockPropio,ultiomNivelCategoria} = this.state
    let found = true
    if(usarVariantes){
        if(usarStock){
            for(let variante of variantes){
                for(let sucursal of variante['sucursales_v']){
                    if(sucursal.activa){
                        if( Number(sucursal.minimo)<=0  || Number(sucursal.existencia)<=0 || sucursal.minimo === '' || sucursal.existencia === '' ){found = false; break;}
                    }
                }
                if(!found)break
            }
        }
        else{}        
    }
    else if(usarStock){
        for(let stock of stockPropio){
            if(stock.aquihayProducto){
                if(Number(stock.STOCK_MINIMO)<=0 || stock.STOCK_MINIMO === '' || Number(stock.EXISTENCIA_ACTUAL)<=0 || stock.EXISTENCIA_ACTUAL === ''){found = false; break;}
            }
        }
    }
    
    if(!found){
        this.setState({txtAlert:'Los datos de stock no pueden estar vacíos y deben ser mayores a 0',modalAlert:true})
      return
    }

    if(!usarVariantes){
        if(!stockPropio.some(stock => stock.aquihayProducto)) found = false
    }

    if(!found){
      this.setState({txtAlert: `El ${tipoString} debe estar en al menos una sucursal`,modalAlert:true})
      //this.setState({txtAlert:'El producto debe estar en al menos una sucursal',modalAlert:true})
      return
    }

    newProducto['imagenes'] = imagenes
    newProducto['variantes'] = variantes
    newProducto['stockPropio'] = stockPropio

    newProducto['usarVariantes'] = usarVariantes ? 1 : 2 ;
    newProducto['usarStock'] = usarStock ? 1 : 2 ;

    newProducto['nombre'] = formData.get('nombrep')
    newProducto['precio'] = usarVariantes ? 0 : formData.get('preciop') ; 
    newProducto['descripcion'] = formData.get('descripcionp')
    newProducto['categoria'] = categoriaActual
    newProducto['unidadMedida'] = unidadActual
    newProducto['Codigo'] = formData.get('codigop')
    newProducto['categoriap'] = categoriap
    //newProducto['unidadp'] = unidadp
    
    newProducto['fkId'] = 0;
    newProducto['idEmpresa'] = sessionStorage.getItem('id');
    newProducto['categoriap'] = categoriap
    newProducto['unidadp'] = unidadp
    newProducto['categoria'] = categoriaActual
    newProducto['unidadMedida'] = unidadActual
    newProducto['nivelCat'] = ultiomNivelCategoria
    
    newProducto['variantesEliminadas'] = variantesEliminadas
    newProducto['stocksEliminados'] = stocksEliminados

    newProducto['Op'] = 2

    this.setState({loading:true})    
    axios.post(`${host}productos/productos/registrarEditarProducto`,newProducto,request).then(rt=>{
      this.setState({loading:false})
      this.props.updateState('nopuedoCambiarSinAvisarte',false)
      let rta = Number(rt.data)
      let msj
      if(rta === -2 ){ msj = 'Los datos del '+(Number(newProducto['tipo'])===1 ? 'producto' : 'servicio')+' se actualizaron correctamente' }
      else { msj = 'Parece que ocurrió un error, por favor intentalo más tarde. [Código de error: SV-ER: #07-01]' }
      this.setState({ modalRespuesta:true, txtRespuesta:msj, })
    })
  }

  cancelar(){
    this.props.redirectTo2('/home/productos')
  }


  validarDatosProducto(some="stock"){
    let {categoriaActual,unidadActual} = this.state

      let formData = new FormData(document.getElementById('formuladatosproducto'))
      
      let converter={
        nombrep:'Nombre',
        preciop:'Precio',
        descripcionp:'Descripción',
      }
      
      for(let a of formData.entries()){
        if( a[0]==='pesop' || a[0]==='timeprepap' || a[0]==='codigop')continue
        if( some==="var" && a[0]==='preciop' )continue
        if(a[1].trim()===''){
          this.setState({txtAlert:"El campo '"+converter[a[0]] +"' es obligatorio",modalAlert:true})
          return false
        }
      }

      if(categoriaActual === ''){
        this.setState({txtAlert:"El campo 'Categoría' es obligatorio",modalAlert:true})
        return false
      }

      if(unidadActual === ''){
        this.setState({txtAlert:"El campo 'Unidad de medida' es obligatorio",modalAlert:true})
        return false
      }

      
      let categoriap = Number(categoriaActual) === -1 ? formData.get('categoriap') : ''
      let unidadp = Number(unidadActual) === -1 ? formData.get('unidadp') : ''

      document.getElementById('spanerrorVar2').style.display='none'
      this.setState({
        newProducto : {
            ...this.state.newProducto,
            nombre:formData.get('nombrep'),
            precio:formData.get('preciop'),
            descripcion:formData.get('descripcionp'),
            categoria:categoriaActual,
            unidadMedida:unidadActual,
            Codigo:formData.get('codigop'),
            categoriap:categoriap,
            unidadp:unidadp,
        }
      })
      return true
  }

  modalEliminarSucursal(indice){
    if(indice===-1){
      this.setState({modalEliminarSucursal:false,indexVarianteActual:-2})
    }
    else{
      let {editable} = this.state
      if(!editable){
        this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Productos y Servicios sobre todas las sucursales relacionadas con el registro actual.'})
        return
      }
      this.setState({modalEliminarSucursal:true,indexVarianteActual:indice})      
    }
  }
  modalDesactivarVariantes(op){
    if(op===1){
      this.props.updateState('nopuedoCambiarSinAvisarte',true)
      this.setState({
        modalDesactivarVariantes:false,
        indexVarianteActual:-2,
        varianteActual : {
        sucursales_v:[]
        },
        variantes:[],
        usarVariantes:false,
        variantesEliminadas:true,
      })
    }
    else{
          this.setState({modalDesactivarVariantes:false,indexVarianteActual:-2,})
    }
  }

  showError(msj,id){
      document.getElementById(id).style.display='block'
      document.getElementById(id).innerHTML=msj
  }

  changeSizeTextArea(e){
    this.props.updateState('nopuedoCambiarSinAvisarte',true)
    let textareaLineHeight = 15;
    let { minRows, maxRows } = this.state;  
    let previousRows = e.target.rows;
        e.target.rows = minRows; // reset number of rows in textarea 
    let currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);
        if (currentRows === previousRows) { e.target.rows = currentRows; }  
    if (currentRows >= maxRows) {
        e.target.rows = maxRows;
        e.target.scrollTop = e.target.scrollHeight;
    }
    this.setState({
      value: e.target.value,
      rows: currentRows < maxRows ? currentRows : maxRows,
    });
  }

  subcampoChange(e,campo){
    this.setState({
      newProducto:{
        ...this.state.newProducto,
        [campo]:e.target.value
      }
    })
  }

  errorImgVar(e){e.target.src=img+'default_image.png'}
  errorImgVar2(e){e.target.src=img+'new_camera_icon_prod.png'}

  addCategoria(force){
    let {catalogoDeCategorias} = this.state
    let nivel = document.getElementById('nivelcat').value.trim()
    let descripcion = document.getElementById('cdescripciond').value.trim()

    if(this.context['type']===2 && !force){
      this.setState({ modalNivel3:true,titleNivel3:`¿Crear Unidad de medida?`,txtNivel3:'Esta acción afectará a todas las sucursales de la empresa.',opNivel3:1 })
      return
    }

    if( nivel==='' || descripcion==='' ){
      this.setState({modalAlert:true,txtAlert:'Debe completar los campos de la categoría'})
      return
    }
    nivel = Number(nivel.trim())

    let repetidos = catalogoDeCategorias.filter( cat => cat['DESCRIPCION'].trim().toLowerCase() === descripcion.trim().toLowerCase()  )
    if(repetidos.length>0){
      this.setState({modalAlert:true,txtAlert:"Ya existe una categoría llamada '"+descripcion+"'"})
      return
    }
    repetidos = catalogoDeCategorias.filter( cat => Number(cat['NIVEL'].trim()) === nivel  )
    if(repetidos.length>0){
      this.setState({modalAlert:true,txtAlert:"Ya existe una categoría con nivel '"+nivel+"'"})
      return
    }
    let nuevos = [{idE:sessionStorage.getItem('id'), nivel, idC:0, descripcion, id:-1, }]
    let data = { nuevos }
    this.setState({loading:true,agregarEditarCate:false})
    axios.post(`${host}productos/productos/agregarCategorias2`,data,request).then(rt=>{
      if(Number(rt.data)>0){
        this.getCatalogoDeCategorias(true);
      }
    })
  }

  addUnidad(force){
    let {catalogoDeUnidades} = this.state
    let descripcion = document.getElementById('udescripciond').value.trim()

    if(this.context['type']===2 && !force){
      this.setState({ modalNivel3:true,titleNivel3:`¿Crear Unidad de medida?`,txtNivel3:'Esta acción afectará a todas las sucursales de la empresa.',opNivel3:2 })
      return
    }

    if(descripcion===''){
      this.setState({txtAlert:'Debe completar los campos de la unidad de medida',modalAlert:true})
      return
    }
    let repetidos = catalogoDeUnidades.filter( unidad => unidad['DESCRIPCION'].toLowerCase().trim() === descripcion.toLowerCase().trim() )
    if(repetidos.length>0){
      this.setState({modalAlert:true,txtAlert:"Ya existe una unidad llamada '"+descripcion+"'"})
      return
    }
    let data={ id: -1 , idE: sessionStorage.getItem('id') , descripcion , }
    this.setState({loading2:true,agregarEditarUnidad:false})
    axios.post(`${host}productos/productos/agregarUnidadesDeMedida`,data,request).then(rt=>{
      if(Number(rt.data)>0){
        this.getCatalogoDeUnidades(true);
      }
    })
  }

  okNivel3(){
    let {opNivel3} = this.state
    if(opNivel3===1)
      this.addCategoria(true)
    if(opNivel3===2)
      this.addUnidad(true)
    this.setState({modalNivel3:false,titleNivel3:'',txtNivel3:'',opNivel3:-1})
  }

  verifyValiditySuc(e,code){
    let{validCurrentUser} = this.props
    let permiso = e.target.checked ? 'CREAR' : 'ELIMINAR'
    if(!validCurrentUser(this.context,'productos','bool',code,permiso)){
      this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para '+permiso.toLowerCase()+' Productos y Servicios en esta sucursal'})
      e.target.checked = !e.target.checked
      return
    }
  }

  render(){
    return (
      <div className="cont-sucursales" >
        <div className="cont-sucursales2" id="miheight" >

          <div className="cont-sucursales3">


            <NotFound 
              visible={ this.state.productoNotFound }
              title="Este producto ya no está disponible"
              txtNotFound={"Parece que el producto que estabas buscando ha sido eliminado. y ya no se encuentra disponible."}
              okText="Lista de productos"
              onOk={()=> this.props.redirectTo2('/home/productos') }
            />
            
            <Loading loading={this.state.loading||this.state.loading2||this.state.loading3} />            
                        
            <Modal centered title="Configura tu imagen" maskClosable={false} keyboard = {false} 
                okText="Continuar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
                cancelText="Cancelar"
                forceRender={true}
                visible={this.state.modalCroper}
                closable={false}
                className="miAntModal newVar alertMy modalCrop"
                onOk={this.aceptCroper}
                onCancel={this.cancelCroper}
                zIndex={9999}
                >
                <div className="myAlertBody">
                  
                  <div className="cont-Cropper" id="myCroper" title="Usa el scroll para hacer zoom" >
                    <Cropper
                      ref={element => this.cropper = element}
                      src=''
                      style={{height:'100%', width: '100%',zIndex:'5'}}
                      aspectRatio={4 / 3}
                      />
                  </div>

                </div>
            </Modal>

            <Modal centered title={<label style={{color:'#F77400'}}>Agregar variante</label>} closable={false} maskClosable={false} keyboard={false} 
              okButtonProps={{ style: { display:'none' } }} okText=""
              cancelButtonProps={{ style: { display:'none' } }} cancelText=""
              visible={this.state.modalVariantes}
              className="miAntModal newVar miconfigModal" >

                <form id="formulaNewVariante">
                        {
                            this.state.modalVariantes
                            ?
                            <div className="miconfigDiv" style={{marginTop:'10px',position:'relative'}} >
                            {
                              !this.state.editable&&
                                <div className='fakemask' style={{zIndex:'201'}}
                                   title='El perfil actual no tiene permisos para editar Productos y Servicios sobre todas las sucursales relacionadas con el registro actual.'
                                   onClick={()=>this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Productos y Servicios sobre todas las sucursales relacionadas con el registro actual.'})} />
                            }

                                <div className="contSrcs">
                                    <label htmlFor="srcVariante" >
                                        <img onError={this.errorImgVar2} src={
                                          this.state.varianteActual.src_new === ''
                                          ? img+'new_camera_icon_prod.png' : 
                                          this.state.varianteActual.src_new} id='forsrcVariante' alt="..." />
                                         <Popover
                                            title="Opciones"
                                            content={
                                                <div className="op-Suc">
                                                <label htmlFor="srcVariante" > Reemplazar </label>
                                                <label onClick={(e)=>this.clearSrc('srcVariante',2,e)}> Eliminar </label>
                                                </div>
                                            }
                                            overlayClassName="pop-Suc"
                                            placement="bottom"
                                            >
                                            <div className="mask" onClick={ e => e.preventDefault()} id="maskvariante" style={{display:this.state.varianteActual.src_new === '' ? 'none' : 'block' }} > </div>
                                        </Popover>
                                    </label>
                                    <input type="file" accept="image/*" id="srcVariante" name="srcVariante" onChange={this.fileChange} />
                                </div>
                                
                                <div className="inputs-group">
                                    <label className="inputs-label" htmlFor="nombre_vd" > Nombre del producto </label>
                                    <input type="text" className="inputs-input" defaultValue={this.state.varianteActual.nombre_v} name="nombre_v"  id="nombre_vd" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                                </div>
                                
                                <div className="inputs-group">
                                    <label className="inputs-label" htmlFor="precio_vd" > Precio </label>
                                    <NumberFormat className="inputs-input" name="precio_v" id="precio_vd" type="text"
                                    defaultValue={this.state.varianteActual.precio_v} 
                                    prefix={'$'} decimalSeparator='.' thousandSeparator=',' allowNegative={false} decimalScale={2} fixedDecimalScale
                                    onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                                </div>

                                <div className="inputs-group">
                                    <label className="inputs-label" htmlFor="codigo_vd" > Código <span>(opcional)</span>  </label>
                                    <input type="text" className="inputs-input" defaultValue={this.state.varianteActual.codigo_v} name="codigo_v" id="codigo_vd" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                                </div>

                                <input type="hidden" id="srchidden" name="src" value={ this.state.varianteActual.src } onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />

                            </div>:
                            <React.Fragment/>
                        }
                    
                </form>

                {
                  this.state.modalVariantes &&
                  <React.Fragment>
                    <div className="contCheckSuc milistcheck" style={{width:'auto',position:'relative'}} >
                    {
                      !this.state.editable&&
                      <div className='fakemask' style={{zIndex:'201'}}
                        title='El perfil actual no tiene permisos para editar Productos y Servicios sobre todas las sucursales relacionadas con el registro actual.'
                        onClick={()=>this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Productos y Servicios sobre todas las sucursales relacionadas con el registro actual.'})} />
                    }
                      <span className="tititle tititle2"> Marca las sucursales en las que esta variante se encuentra disponible: </span>
                      <form id="missucs">
                      {
                        this.state.sucursales.map((sucursal,indice)=>{
                          return(
                            <div key={'suc_'+sucursal.ID} className="myCheckProd">
                              {/*
                                <input
                                  type="checkbox"
                                  defaultChecked={this.state.interseccionActual[indice]}
                                  name={"sucursal_"+indice}
                                  onChange={(e)=>this.verifyValiditySuc(e,sucursal)}
                                  id={'suc_'+sucursal.ID}
                                />
                                <label htmlFor={'suc_'+sucursal.ID} > {sucursal.NOMBRE}  </label>
                              */}
                              <Checkbox
                                defaultChecked={this.state.interseccionActual[indice]}
                                name={"sucursal_"+indice}
                                onChange={(e)=>this.verifyValiditySuc(e,sucursal)}
                                id={'suc_'+sucursal.ID}
                                className="checkInFilter"
                              > 
                                {sucursal.NOMBRE}
                              </Checkbox>
                            </div>
                          )
                        })
                      }
                      </form>
                    </div>
                      
                    <div className="newProductFoot">
                      <div className="dosBtones">
                        <button className="btnEliminar" onClick={()=>this.hideModalVariante('modalVariantes')} >
                          {this.state.editable ? 'Cancelar' : 'Aceptar'}
                        </button>
                        {
                          this.state.editable&&
                          <button className="btnAceptar" onClick={this.agregarVariante } > Guardar </button>
                        }
                      </div>
                    </div>
                  </React.Fragment>
                }
            </Modal>
   
            <Modal centered title="Confirmar" maskClosable={false} keyboard = {false} 
                okText="Continuar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
                cancelText="Cancelar"
                //visible={this.state.modalAdvertencia}
                visible={this.state.modalEliminarSucursal}
                closable={false}
                onOk={() => this.removeVariante(this.state.indexVarianteActual)}
                onCancel={()=>this.modalEliminarSucursal(-1)}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    <span className="txtAlert"> ¿Desea eliminar esta variante? </span>
                </div>
            </Modal>

            <Modal centered title="Confirmar" maskClosable={false} keyboard = {false} 
                okText="Continuar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
                cancelText="Cancelar"
                //visible={this.state.modalAdvertencia}
                visible={this.state.modalDesactivarVariantes}
                closable={false}
                onOk={() => this.modalDesactivarVariantes(1)}
                onCancel={()=>this.modalDesactivarVariantes(-1)}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    <span className="txtAlert"> Al desactivar esta opción se eliminarán las variantes ya creadas así como sus Stocks. ¿Desea continuar? </span>
                </div>
            </Modal>

            <Modal centered title="Confirmar" maskClosable={false} keyboard = {false} 
                okText="Continuar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelButtonProps={{ className:'btn btn2' , style: { width:'120px'} }}
                cancelText="Cancelar"
                visible={this.state.modalDesactivarStock}
                //visible={true}
                closable={false}
                onOk={() => {this.setState({modalDesactivarStock:false,usarStock:false}); this.props.updateState('nopuedoCambiarSinAvisarte',true)} }
                onCancel={()=> this.setState({modalDesactivarStock:false})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    <span className="txtAlert"> Al desactivar esta opción se eliminarán los datos sobre Stock ya capturados. ¿Desea continuar? </span>
                </div>
            </Modal>
            
            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Aceptar" okButtonProps={{ className:'btn btn1' , style: { width:'120px'} }}
                cancelButtonProps={{ className:'btn btn2' , style: { width:'120px', display:'none'} }}
                visible={this.state.modalAlert}
                closable={false}
                onOk={() => this.setState({modalAlert:false,txtAlert:''})}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                {
                  this.state.modalAlert   ?
                  <span className="txtAlert"> {this.state.txtAlert} </span>:
                  <React.Fragment/>
                }
                </div>
            </Modal>

            <Modal centered title={"Agregar unidad de medida"} maskClosable={false} keyboard = {false} 
                okText="Guardar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
                cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
                visible={this.state.agregarEditarUnidad} zIndex={20}
                className="miAntModal alertMy modalUnidad" destroyOnClose
                onCancel={()=>this.setState({agregarEditarUnidad:false})} onOk={()=>this.addUnidad(false)} >
                  <div className="miUniDiv">
                    <div className="inputs-group">
                        <label className="inputs-label" htmlFor="descripciond" style={{top:'-10px'}} > Descripción </label>
                        <input type="text" autoFocus maxLength={30} className="inputs-input" name="udescripcion" id="udescripciond"/>
                    </div>
                  </div>
            </Modal>

            <Modal centered title={'Agregar categoría'} maskClosable={false} keyboard = {false} 
              okText="Guardar" okButtonProps={{ className:'btn btn1' , style:{ width:'120px'} }}
              cancelText="Cancelar" cancelButtonProps={{ className:'btn btn2' , style:{ width:'120px'} }}
              visible={this.state.agregarEditarCate} zIndex={20}
              className="miAntModal alertMy modalUnidad" destroyOnClose
              onCancel={()=>this.setState({agregarEditarCate:false})}
              onOk={()=>this.addCategoria(false)} >
                <div className="miUniDiv">
                  <div className="nivelNom">
                    <div className="inputs-group ja0" style={{position:'relative'}} >
                      {
                        !this.props.validCurrentUser(this.context,'categorias','bool',false,'EDITAR') && 
                        <div className='fakemask' style={{height:'170%',top:'-10px'}} title='No puedes editar el nivel de esta Categoría.'/>
                      }

                      <label className="inputs-label" htmlFor="niveld" > Nivel </label>
                      <NumberFormat type="text" maxLength={6} className="inputs-input" name="nivel" id="nivelcat" defaultValue={this.state.ultiomNivelCategoria } />
                    </div>
                    
                    <div className="inputs-group ja1">
                        <label className="inputs-label" htmlFor="descripciond" > Nombre de la categoría </label>
                        <input type="text" autoFocus maxLength={30} className="inputs-input" name="cdescripcion"  id="cdescripciond"/>
                    </div>
                  </div>
                </div>
            </Modal>

            <Alert title={this.state.titleNivel3} visible={this.state.modalNivel3} text={this.state.txtNivel3}
              onOk={this.okNivel3} withCancel onCancel={() => this.setState({modalNivel3:false,opNivel3:-1,txtNivel3:'',titleNivel3:'',})} zIndex={1001} />

            <Modal centered title="Atención" maskClosable={false} keyboard = {false} 
                okText="Lista de productos" okButtonProps={{ className:"btn btn1" ,style:{width:'180px'} }}
                cancelText="Nuevo registro" cancelButtonProps={{ className:"btn btn2" ,style:{width:'170px'} }}
                visible={this.state.modalRespuesta}
                closable={false}
                onOk={()=>this.props.redirectTo2('/home/productos')}
                onCancel={()=>this.props.redirectTo2('/home/productos/nuevo')}
                className="miAntModal newVar alertMy"
                >
                <div className="myAlertBody">
                    {
                        this.state.modalRespuesta   ?
                        <span className="txtAlert"> {this.state.txtRespuesta} </span>:
                        <React.Fragment/>
                    }
                </div>
            </Modal>
            
            <div className="metaControles">
              <h3 className="tituloPanel"> Detalle {this.state.tipoString} / {this.state.newProducto.nombre} </h3>
            </div>

              <div className="newProductHead" style={{position:'relative'}} >
                  {
                    !this.state.editable &&
                    <div className='fakemask' style={{zIndex:'201'}}
                     title='El perfil actual no tiene permisos para editar Productos y Servicios sobre todas las sucursales relacionadas con el registro actual.'
                     onClick={()=>this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Productos y Servicios sobre todas las sucursales relacionadas con el registro actual.'})} />
                  }


                  {/*<label> Fotos del producto </label>*/}
                  <div className="contSrcs">
                  {
                    this.state.imagenes.sort((i1,i2) => Number(i1.TIPO) - Number(i2.TIPO) ).sort( (i1,i2) => Number(i1.ELIMINADO) - Number(i2.ELIMINADO)  ).map((imagen,indice)=>{
                      return(
                        <div key={'imgMaped_'+indice}>

                          <label htmlFor={['filea', 'fileb', 'filec', 'filed', 'filee'][indice]}>
                            <img src={ (imagen.new_DIRECCION_IMG !== '' && imagen.new_DIRECCION_IMG !== null && imagen.new_DIRECCION_IMG !== undefined)
                                        ? imagen.new_DIRECCION_IMG : img+'new_camera_icon_prod.png' } id="forfilea" alt="..." />
                            {
                              (imagen.new_DIRECCION_IMG === '' || imagen.new_DIRECCION_IMG === null || imagen.new_DIRECCION_IMG === undefined) ? <React.Fragment/>:
                              <React.Fragment>
                              <Popover
                                  title="Opciones"
                                  content={
                                      <div className="op-Suc">
                                      {indice!==0?<label onClick={()=>this.changePrincipal(indice)} > Establecer como principal </label>:<React.Fragment/>}
                                      <label htmlFor={['filea', 'fileb', 'filec', 'filed', 'filee'][indice]} onClick={(()=>this.setState({reemplazanding:true}))}> Reemplazar </label>
                                      <label onClick={(e)=>this.clearSrc(indice,1,e)} > Eliminar </label>
                                      </div>
                                  }
                                  overlayClassName="pop-Suc"
                                  placement="bottom"
                                  >
                                  <div className="mask" onClick={ e => e.preventDefault() } ></div>
                              </Popover>
                              <img src={img+'estrella_completa2A.png'} className="favImg" alt="..." style={{display:indice!==0?'none':'block'}} />
                              </React.Fragment>
                            }
                           </label>
                        </div>
                      )
                    })
                  }
                      
                      
                      <input type="file" accept="image/*" id="filea" name="filea" onChange={(e)=>this.fileChange(e,0)} />
                      <input type="file" accept="image/*" id="fileb" name="fileb" onChange={(e)=>this.fileChange(e,1)} />
                      <input type="file" accept="image/*" id="filec" name="filec" onChange={(e)=>this.fileChange(e,2)} />
                      <input type="file" accept="image/*" id="filed" name="filed" onChange={(e)=>this.fileChange(e,3)} />
                      <input type="file" accept="image/*" id="filee" name="filee" onChange={(e)=>this.fileChange(e,4)} />

                  </div>
              </div>
              

              {
                  !this.state.usarVariantes&&
                  <div className="newProductDiv newProductDivSucursales ">
                    <div className="newProductDivTitle">
                        <label > Marca las sucursales en las que el {this.state.tipoString} se encuentra disponible: </label>
                    </div>
                    <div className="listSucursales milistcheck milistchecknolist">
                    {
                        this.state.stockPropio.map((stock,indice)=>{
                            return(
                                <div key={'stock_'+indice+'_'+stock.ID_PRODUCTO} className="myCheckProd">
                                  {/*
                                    <input
                                      type="checkbox"
                                      id={'sucIn_'+stock.NOMBRE_SUCURSAL}
                                      defaultChecked={stock.aquihayProducto}
                                      onChange={(e)=>this.prensenteEn( e , indice)}
                                    />
                                    <label htmlFor={'sucIn_'+stock.NOMBRE_SUCURSAL} > {stock.NOMBRE_SUCURSAL} </label>
                                  */}
                                  <Checkbox
                                    id={'sucIn_'+stock.NOMBRE_SUCURSAL}
                                    defaultChecked={stock.aquihayProducto}
                                    onChange={(e)=>this.prensenteEn( e , indice)}                                    
                                    className="checkInFilter"
                                  >
                                    {stock.NOMBRE_SUCURSAL}
                                  </Checkbox>
                                </div>
                            )
                        })
                    }
                    </div>
                  </div>
              }

              <div className="newProductBody">
                <div className="newProductDiv newProductBodyForm" id="formDelNuevoProducto" style={{position:'relative'}} >
                  {
                    !this.state.editable &&
                    <div className='fakemask'
                     title='El perfil actual no tiene permisos para editar Productos y Servicios sobre todas las sucursales relacionadas con el registro actual'
                     onClick={()=>this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Productos y Servicios sobre todas las sucursales relacionadas con el registro actual'})} />
                  }

                    <form id="formuladatosproducto" >
                        
                        <div className="inputs-group">
                            <label className="inputs-label" htmlFor="nombrepd" > Nombre </label>
                            <input type="text" defaultValue={this.state.newProducto.nombre} className="inputs-input" name="nombrep" id="nombrepd" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                        </div>

                        <div className="inputs-group" style={{display:this.state.usarVariantes?'none':'block'}} >
                            <label className="inputs-label" htmlFor="preciopd" > Precio </label>
                            <NumberFormat value={this.state.newProducto.precio} className="inputs-input" name="preciop" id="preciopd" type="text"
                              prefix={'$'} decimalSeparator='.' thousandSeparator=',' allowNegative={false} decimalScale={2} fixedDecimalScale
                              onChange={(e)=>this.subcampoChange(e,'precio')} />
                        </div>

                        <div className="inputs-group">
                            <label className="inputs-label" htmlFor="descripcionpd" > Descripción </label>
                            <Input.TextArea
                              className="inputs-input" name="descripcionp" id="descripcionpd"
                              autoSize={{minRows: 1, maxRows:10,} } 
                              ref={e => this.descipcionproducto = e}
                              /> 
                        </div>

                        <div className="inputs-group">
                            <label className="inputs-label lbl-Activa"> Categoría </label>
                            <Select value={this.state.categoriaActual} onChange={ (value) => this.changeState('categoriaActual',value) } >
                                {
                                    this.state.catalogoDeCategorias.map((x,indice)=>{
                                        return(
                                            <Select.Option key={'cat_'+x['ID']} value={x['ID']} >{x['NIVEL']}.- {x['DESCRIPCION']}</Select.Option>
                                        )
                                    })
                                }
                                {
                                  this.props.validCurrentUser(this.context,'categorias','bool',false,'CREAR')&&
                                  <Select.Option value={'-1'} > Nueva categoría </Select.Option>
                                }
                            </Select>
                        </div>

                        {
                          Number(this.state.newProducto.tipo)===2?<React.Fragment/>:
                          <div className="inputs-group">
                            <label className="inputs-label lbl-Activa"> Unidad de medida </label>
                            <Select value={this.state.unidadActual} onChange={ (value) => this.changeState('unidadActual',value) }  >
                                {
                                    this.state.catalogoDeUnidades.filter(um => um.DESCRIPCION !== 'Servicio').map((x,indice)=>{
                                        return(
                                            <Select.Option key={'cat_'+x['ID']} value={x['ID']} > {x['DESCRIPCION']} </Select.Option>
                                        )
                                    })
                                }
                                {
                                  this.props.validCurrentUser(this.context,'unidades','bool',false,'CREAR')&&
                                  <Select.Option value={'-1'} > Nueva unidad </Select.Option>
                                }
                            </Select>
                          </div>
                        }

                        <div className="inputs-group" style={{display:Number(this.state.newProducto.tipo) === 2 ? 'none': 'initial' }} >
                            <label className="inputs-label" htmlFor="codigopd" > Código <span>(opcional)</span> </label>
                            <input type="text" defaultValue={this.state.newProducto.Codigo} className="inputs-input" name="codigop" id="codigopd" onChange={()=>this.props.updateState('nopuedoCambiarSinAvisarte',true)} />
                        </div>

                        <div className="fechaSexo" style={{display:Number(this.state.newProducto.tipo) === 2 ? 'none': 'initial' }} >

                            <div className="inputs-group">
                                <label className="inputs-label" htmlFor="pesopd" > Cantidad <span>(opcional)</span> </label>
                                <NumberFormat value={this.state.newProducto.peso} className="inputs-input"  name="pesop" id="pesopd" type="text"
                                  onChange={(e)=>this.changeStateU(e,'peso')} suffix={' '+ this.state.suffix} />
                            </div>

                            <div className="inputs-group">
                                <label className="inputs-label" htmlFor="timeprepapd" > Tiempo de preparación <span>(opcional)</span> </label>
                                <div className="mytimer">
                                    <TimePicker placeholder="HH:MM" value={moment(this.state.newProducto.tiempoPreparacion,'HH:mm')} onChange={(value,values)=>this.timePrepa(value,values)}
                                    minuteStep={5} allowClear={false} id="timeprepapd" name="timeprepap" format={'HH:mm'}
                                    />
                                </div>
                            </div>

                        </div>


                        <span className="nota error" id="spanerrorVar2">  </span>
                    </form>
                </div>

                {
                  Number(this.state.newProducto.tipo) === 2 ? <React.Fragment/>:
                  <div className="newProductDiv newProductBodyStock">
                        <div className="newProductDivTitle">
                            <label > Controlar stock </label>
                            <Switch checked={this.state.usarStock} className="myswitch" />
                            <div className="overChecked" onClick={ () => this.controlStock() } ></div>
                        </div>
                        {
                            this.state.usarStock?
                            this.state.usarVariantes?
                            this.state.sucursales.map((sucursal,indice)=>{
                                let {variantes} = this.state
                                
                                let productosDeEstaSucursal = variantes.map( (variante,ivar) => {
                                    let ob = {}
                                    if(variante.sucursales_v.some(a => (sucursal.ID === a.idsucursal && a.activa ) ) && variante.activa ){
                                        ob['nombre'] = variante.nombre_v
                                        ob['stocks'] = variante.sucursales_v
                                        ob['ivar'] = ivar
                                        return ob;
                                    }else{
                                        return -1
                                    }
                                })
                                if(productosDeEstaSucursal.every(item => item !== -1) )
                                  return(
                                    <form key={'SucProd'+indice} id={"formStocks_"+sucursal.ID}>
                                        <div className="lisSucsProds" >
                                            <label className="nomSuc" >
                                                Sucursal: {sucursal.NOMBRE}
                                            </label>
                                            {
                                                productosDeEstaSucursal.map((a,indice) => {
                                                    if(a!==-1)return(
                                                        <React.Fragment key={'producto_'+indice+'_sucursal_'+sucursal.ID}>
                                                        <label className="nomProd">
                                                            {a.nombre}
                                                        </label>
                                                        {
                                                            a.stocks.map((stock,istock)=>{
                                                                if(stock.idsucursal === sucursal.ID)
                                                                return(
                                                                    <div className="fechaSexo" key={'almacen_'+stock.idalmacen} style={{position:'relative'}} >

                                                                        {
                                                                          !this.props.validCurrentUser(this.context,'productos','bool',stock['code'],'EDITAR') &&
                                                                          <div className='fakemask' style={{zIndex:'201'}}
                                                                             title='El perfil actual no tiene permisos para editar Productos y Servicios sobre la sucursal actual.'
                                                                             onClick={()=>this.setState({modalAlert:true,txtAlert:'El perfil actual no tiene permisos para editar Productos y Servicios sobre la sucursal actual.'})} />
                                                                        }

                                                                        <div className="inputs-group inputs-group-inline">
                                                                            <label className="inputs-label"> Stock mínimo </label>
                                                                            <NumberFormat className="inputs-input" thousandSeparator={true} onChange={(e)=>this.changeMinimo(e,a.ivar,istock,'minimo')} defaultValue={stock.minimo} name={'minimo_'+a+'_'+sucursal.ID} type="text" />
                                                                        </div>

                                                                        <div className="inputs-group inputs-group-inline">
                                                                            <label className="inputs-label"> Existencia actual </label>
                                                                            <NumberFormat className="inputs-input" thousandSeparator={true} onChange={(e)=>this.changeMinimo(e,a.ivar,istock,'existencia')} defaultValue={stock.existencia} name={'existencia_'+a+'_'+sucursal.ID} type="text" />
                                                                        </div>
                                                                        
                                                                    </div>
                                                                )
                                                                else return(<React.Fragment key={istock} />)
                                                            })
                                                        }
                                                        </React.Fragment>
                                                    )
                                                    else return(<React.Fragment key={indice} />)
                                                })
                                            }

                                        </div>
                                    </form>
                                  )
                                else
                                  return(<React.Fragment key={'SucProd'+indice} />)
                            })
                            :
                            this.state.stockPropio.map((stock,indice)=> {
                                if(stock.aquihayProducto)
                                return(
                                    <form key={'stock'+indice} id={"stock_"+stock.ID}>
                                        <div className="lisSucsProds">
                                            <label className="nomSuc" >
                                                Sucursal: {stock.NOMBRE_SUCURSAL}
                                            </label>

                                            <label className="nomProd" >
                                                {this.state.newProducto.nombre}
                                            </label>
                                            
                                            <div className="fechaSexo">
                                                
                                                <div className="inputs-group inputs-group-inline">
                                                <label className="inputs-label"> Stock mínimo </label>
                                                <NumberFormat className="inputs-input" thousandSeparator={true} onChange={(e)=>this.changeMinimoPropio(e,indice,'STOCK_MINIMO')} defaultValue={stock.STOCK_MINIMO} name={'minimo_'+stock.ID} type="text" />
                                                </div>

                                                <div className="inputs-group inputs-group-inline">
                                                <label className="inputs-label"> Existencia actual </label>
                                                <NumberFormat className="inputs-input" thousandSeparator={true} onChange={(e)=>this.changeMinimoPropio(e,indice,'EXISTENCIA_ACTUAL')} defaultValue={stock.EXISTENCIA_ACTUAL} name={'existencia_'+stock.ID} type="text" />
                                                </div>

                                            </div>

                                        </div>
                                    </form>
                                )
                                else return(<React.Fragment key={indice} />)
                            }):
                            <React.Fragment/>
                        }
                        <span className="nota error" id="spanerrorStock2">  </span>
                  </div>
                }

                {
                  Number(this.state.newProducto.tipo) === 2 ? <React.Fragment/>:
                  <div className="newProductDiv newProductBodyVar">
                        <div className="newProductDivTitle">
                            <label > Variantes </label>
                            <Switch checked={this.state.usarVariantes} className="myswitch"/>
                            <div className="overChecked" onClick={ () => this.showModalVariante(-1,0) } ></div>
                        </div>
                        {
                            this.state.variantes.map((variante,indice)=>{
                                if(variante.activa)
                                return(
                                    <div className="lisvariantItem" key={'item_'+indice} >

                                        <div className="miniVar" >
                                            <img onError={this.errorImgVar} src={ variante.src_new } alt="..." />
                                            <label onClick={ () => this.showModalVariante(indice) } >
                                                {variante.nombre_v}
                                            </label>
                                        </div>
                                        <span>
                                            ${ Number(variante.precio_v.replace(/[$,]/g, "")).toFixed(2) + ' MXN'}
                                            <img src={img+'basurero_gris.png'} onClick={() =>  this.modalEliminarSucursal(indice)} alt="..." title='Eliminar variante' />
                                        </span>
                                    </div>
                                )
                                else return(<React.Fragment key={indice} />)
                            })
                        }
                        {
                            (this.state.variantes.length > 0 && this.state.editable)&&
                            <div>
                                <button className="newVarButton" onClick={ () => this.showModalVariante(-1) } > <Icon type="plus" /> Agregar variante </button>
                            </div>
                        }
                  </div>
                }

              </div>

              <div className="newProductFoot">

                <div className="dosBtones tresbotones">
                  {
                    this.state.editable&&
                    <button className="btnEliminar" onClick={this.changeStatus} > { this.state.newProducto.status === '1' ? 'Desactivar' : 'Activar' } </button>
                  }

                  <button className="btnEliminar" onClick={this.cancelar} > Cancelar </button>

                  <button className="btnAceptar" onClick={this.submit} disabled={!this.state.editable} > Guardar </button>


                </div>
              </div>

          </div>

        </div>
      </div>
    );
  }
}

export default withRouter(Editar);