import React,{useState,useContext,useEffect} from 'react';
import axios from 'axios';
import { Globalrequest as request } from './../../../request.js';
import {Host as host, ImgPanelAdmin as ImgPanel,Img as img} from './../../../app.json'
import {Select} from 'antd';
import {withRouter} from 'react-router-dom'
import 'moment/locale/es'
import moment from 'moment'
import Loading from '../../../modalCargando/loading';
import ModalAlert from '../../../modalCargando/modalAlert';

import {userContext} from './../context'

import   './notificaciones.scss';

const interval = 2 * 60 * 1000

const Lista = props => {
  const contextType = useContext(userContext)

  //const [timerID,setTimerID] = useState(null)

  const [notificacionesDetalle,setNotificacionesDetalle] = useState({})

  const [sucursales,setSucursales] = useState([])
  const [notificaciones,setNotificaciones] = useState([])
  const [notificaciones2,setNotificaciones2] = useState([])

  const [loading,setLoading] = useState(true)
  const [primero,setPrimero] = useState(true)
  const [unseen,setUnseen] = useState(0)

  const [filtro2,setFiltro2] = useState('')

  const [modalAlert,setModalAlert] = useState({visible:false,txt:''})
  const [modalConfirm,setModalConfirm] = useState({visible:false,})
  const [modalConfirm2,setModalConfirm2] = useState({visible:false,id:-1})

  const actualizarNotificaciones = () => {
    let HTTPHeaders = {idE:sessionStorage.getItem('id'),op:2}
    axios.post(`${host}clientes/clientes/notificacionesPorEmpresa`,HTTPHeaders,request).then(rt=>{

      const {editState,validCurrentUser} = props

      let notificacionesPRE = [],
          tiposToFilter = [1,2,3,12,13],
          tiposToFilter2 = [10],
          unseenLocal=0

      let obj = JSON.parse(JSON.stringify(contextType))
      if(Object.keys(contextType['data']).length === 0 ){
        let permisosPreSaved = sessionStorage.getItem('perfiles')||'none'
        if(permisosPreSaved!=='none')
          obj['data'] = JSON.parse(permisosPreSaved)  
      }

      for (let notificacion of rt.data['array']){
        if(obj['type']===2 && (tiposToFilter.includes( Number(notificacion['TIPO'] ) ) || tiposToFilter2.includes( Number(notificacion['TIPO'] ) ) ) ){
          let permisosToCheck = ''
          if(tiposToFilter.includes( Number(notificacion['TIPO'] ) ) )//pedidos
            permisosToCheck = 'pedidos'
          if(tiposToFilter2.includes( Number(notificacion['TIPO'] ) ) )//citas
            permisosToCheck = 'citas'
          if( validCurrentUser(obj,permisosToCheck,'bool',notificacion['CODE'],false) ){
            notificacionesPRE.push(notificacion)
            if(Number(notificacion['ESTATUS'])===1)unseenLocal++
          }
        }
        else{
          notificacionesPRE.push(notificacion)
          if(Number(notificacion['ESTATUS'])===1)unseenLocal++
        }
      }

      const notificacionesLocal = notificacionesPRE.map(n => {n.FECHA = moment(n.FECHA_HORA_GENERA,'YYYY-MM-DD HH:mm:ss').format('DD/MMMM/YYYY'); return n;} )
      const notificacionesDetalleLocal = notificacionesLocal.reduce((r, a) => { r[a.FECHA] = [...r[a.FECHA] || [], a]; return r; }, {});
      const sucursalesLocal = rt.data['dataS']
      setTimeout(()=>editState('useen', unseenLocal),100)
      setSucursales(sucursalesLocal)
      setNotificacionesDetalle(notificacionesDetalleLocal)
      setNotificaciones(notificacionesLocal)
      setNotificaciones2(notificacionesLocal)
      setUnseen(unseenLocal)
      if(primero){
        setLoading(false)
        setPrimero(false)
      }
    })
  }

  const action = (notificacion) => {
    console.log(notificacion,notificacion['ANEXO'] === false)
    
    let arrayIDs = ''
    const {redirectTo2} = props
    switch(Number(notificacion['TIPO'])){
      case 1:
        arrayIDs += (notificacion['ID']+'[/**/]')
        sessionStorage.setItem('idPed',notificacion['ID_MOVIMIENTO'])
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        redirectTo2('/home/pedidos/detalle')
      break;
      case 2:
        arrayIDs += (notificacion['ID']+'[/**/]')
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        if(notificacion['ANEXO'] === false){
          sessionStorage.setItem('idPed',notificacion['ID_MOVIMIENTO'])
          redirectTo2('/home/pedidos/detalle')
        }
        else{
          sessionStorage.setItem('prefiltro','filtro5[/**/]Pendiente')
          redirectTo2('/home/pedidos')
        }
      break;
      case 3:
        arrayIDs += (notificacion['ID']+'[/**/]')
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        if(notificacion['ANEXO'] === false){
          sessionStorage.setItem('idPed',notificacion['ID_MOVIMIENTO'])
          redirectTo2('/home/pedidos/detalle')
        }
        else{
          sessionStorage.setItem('prefiltro','filtro5[/**/]En proceso')
          redirectTo2('/home/pedidos')
        }
      break;
      case 4:
        arrayIDs += (notificacion['ID']+'[/**/]')
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        redirectTo2('/home/reseñas')
      break;
      case 5:
        arrayIDs += (notificacion['ID']+'[/**/]')
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        if(notificacion['ANEXO'] === false){
          sessionStorage.setItem('idPr',notificacion['ID_MOVIMIENTO'])
          redirectTo2('/home/promociones/editar')
        }
        else{
          sessionStorage.setItem('prefiltro','filtro6[/**/]2')
          redirectTo2('/home/promociones')
        }
      break;//dudas
      case 6:
        arrayIDs += (notificacion['ID']+'[/**/]')
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        if(notificacion['ANEXO'] === false){
          sessionStorage.setItem('idPr',notificacion['ID_MOVIMIENTO'])
          redirectTo2('/home/promociones/editar')
        }
        else{
          sessionStorage.setItem('prefiltro','filtro6[/**/]2')
          redirectTo2('/home/promociones')
        }
      break;
      case 7:
        arrayIDs += (notificacion['ID']+'[/**/]')
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        if(notificacion['ANEXO'] === false){
          sessionStorage.setItem('idP',notificacion['ID_MOVIMIENTO'])
          redirectTo2('/home/productos/editar')
        }
        else{
          sessionStorage.setItem('prefiltro','filtro3[/**/]2')
          redirectTo2('/home/productos')
        }
      break;
      case 8:
        arrayIDs += (notificacion['ID']+'[/**/]')
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        if(notificacion['ANEXO'] === false){
          sessionStorage.setItem('idP',notificacion['ID_MOVIMIENTO'])
          redirectTo2('/home/productos/editar')
        }
        else{
          sessionStorage.setItem('prefiltro','filtro3[/**/]3')
          redirectTo2('/home/productos')
        }
      break;
      case 9:
        arrayIDs += (notificacion['ID']+'[/**/]')
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        sessionStorage.setItem('preopen','ok')
        redirectTo2('/home/configuracion')
      break;
      case 10:
        arrayIDs += (notificacion['ID']+'[/**/]')
        sessionStorage.setItem('idCit',notificacion['ID_MOVIMIENTO'])
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        redirectTo2('/home/agenda')
      break;
      case 11:
        arrayIDs += (notificacion['ID']+'[/**/]')
        sessionStorage.setItem('idCit',notificacion['ID_MOVIMIENTO'])
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        redirectTo2('/home/agenda')
      break;
      case 12:
        arrayIDs = notificacion['ID']+'[/**/]'
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        sessionStorage.setItem('idPed',notificacion['ID_MOVIMIENTO'])
        sessionStorage.setItem('showPago',1)
        redirectTo2('/home/pedidos/detalle')
      break;
      case 13:
        arrayIDs = notificacion['ID']+'[/**/]'
        sessionStorage.setItem('idNotificacionPorAtender',arrayIDs)
        sessionStorage.setItem('idPed',notificacion['ID_MOVIMIENTO'])
        redirectTo2('/home/pedidos/detalle')
      break;
      default:break;
    }
  }

  const setAllSeen = () => {
    if(unseen===0)return
    setLoading(true)
    setModalConfirm({visible:false})
    let idN = ''
    for(let not of notificaciones){
      if(Number(not['ESTATUS'])===1) idN+=(not['ID']+'[/**/]')
    }
    let HTTPHeaders = {idN,estatus:2}
    axios.post(`${host}clientes/clientes/cambiarEstatusNotificacion`,HTTPHeaders,request).then(rt=>{
      if(rt.data===1){
        actualizarNotificaciones()
        setTimeout(()=>setLoading(false),4*1000)
      }
    })
  }

  const classToggle = () => {
    document.getElementById('toggleFiltrarId').classList.toggle('toggleFiltrar2');
    document.getElementById('contControlesId').classList.toggle('contControles2');
  }

  const getDiff = (date) => {
    date = moment(date,'YYYY-MM-DD HH:mm')
    return moment(date,'YYYY-MM-DD HH:mm').format('HH:mm')
  }

  const changeFiltro2 = (_filtro2) => {
    setFiltro2(_filtro2)
    setTimeout(()=>actualizarProductos(), 10)
  }

  const actualizarProductos = () =>{
    const notificacionesL = filtro2===''? notificaciones2 : notificaciones2.filter(not => not['ID_SUCURSAL'] === filtro2 )
    const notificacionesDetalleL = notificacionesL.reduce((r, a) => { r[a.FECHA] = [...r[a.FECHA] || [], a]; return r; }, {});
    setNotificaciones(notificacionesL)
    setNotificacionesDetalle(notificacionesDetalleL)
  }

  const getSrcNoti = (tipo) =>{
    if(Number(tipo)<10)
      tipo = '0'+tipo
    return ImgPanel+'notificaciones/ne'+tipo+'.svg'
  }

  const eliminarNotificacion = () => {
    const {id} = modalConfirm2
    const HTTPHeaders = {idN:id, bandera:215,}
    setLoading(true)
    setModalConfirm2({visible:false,id:-1})
    axios.post(`${host}clientes/clientes/eliminarNotificacion`,HTTPHeaders,request).then(rt=>{
      actualizarNotificaciones()
      setTimeout(()=>setLoading(false),4*1000)
    })
  }

  const clickEliminar = (e,idConfirm) => {
    e.stopPropagation()
    setModalConfirm2({visible:true,id:idConfirm})
  }

  const borrarFiltros = () =>{
    changeFiltro2('')
  }

  useEffect(()=>{
    const id = sessionStorage.getItem('id')
    if( id===null || id===undefined )return;
    actualizarNotificaciones()
    const timerIDL = setInterval( () => actualizarNotificaciones(), interval);
    //setTimerID(timerIDL)
    return()=>{
      clearInterval(timerIDL)
      //setTimerID(null)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[])

  return (
    <div className="cont-sucursales" >
      <div className="cont-sucursales2" >
        <div className="cont-sucursales3">

        {
            Object.keys(notificacionesDetalle).length === 0 &&
            <div className="imgnonResult">
              <div  className="nonResultChild">
                <img src={ImgPanel+'nonnotificaciones.png'} alt="..." />
              </div>
            </div>
          }
          
          <Loading loading={loading} />

          <ModalAlert visible={modalAlert['visible']} text={modalAlert['txt']} onOk={() => setModalAlert({visible:false,txt:''})} />

          <ModalAlert visible={modalConfirm['visible']} text={'¿Desea marcar todas sus notificaciones como leídas?'} withCancel onCancel={() => setModalConfirm({visible:false})} onOk={setAllSeen} />

          <ModalAlert visible={modalConfirm2['visible']} text={'¿Está seguro que desea eliminar esta notificación?'} withCancel onCancel={() => setModalConfirm2({visible:false,id:-1})} onOk={eliminarNotificacion} />

          <div className="tituloYControles">
            
            <div className="metaControles">
              <h3 className="tituloPanel"> Notificaciones </h3>
              <span className="toggleFiltrar" id="toggleFiltrarId" onClick={classToggle} > Filtrar </span>
            </div>

            <div className="contControles" id="contControlesId">

              <div className="contControlesHijo" id="contControlesHijoId">
                <div className="filtradosExtras">

                  <div className="inputs-group-inline">
                    <label className="inputs-label" htmlFor="pesopd" >  Filtrar por sucursal </label>
                    <Select value={filtro2} className="inputs-input" onChange={changeFiltro2}>
                      {
                        sucursales.map((sucu,indice)=>{
                          return(
                            <Select.Option value={sucu.id} key={'sucList_'+indice} > {sucu.nombre } </Select.Option>
                          )
                        })
                      }
                      <Select.Option value={''} > Todas las sucursales </Select.Option>
                    </Select>
                  </div>

                </div>
                
                <div className="cleanDiv" style={{maxWidth:'100%',padding:'10px'}} >
                  <button className="cleanBut" onClick={borrarFiltros} > Borrar filtros </button>
                </div>

              </div>

            </div>

            {
              Object.entries(notificacionesDetalle).length > 0 &&
              <div className="flexcenter Fend" style={{marginTop:'10px'}} >
                <button className="btn btn2" onClick={()=>setModalConfirm({visible:true,})} > Marcar todas como leídas </button>
              </div>
            }

          </div>

          <div className="cont-instancias2">
            {
              Object.entries(notificacionesDetalle).map( ([fecha,notificaciones]) => 
                <div key={'date_'+fecha}>
                  <div className="headGris">{fecha}</div>
                  {
                    notificaciones.map(n => (
                    <div key={'noti_'+n.ID} className={"notificacion flexcenter Fstart estatus"+n.ESTATUS} onClick={()=>action(n)} >
                      <img alt="..." src={getSrcNoti(n['TIPO'])} onError={e => e.target.src = ImgPanel+'notificaciones/ne01.svg' } />
                      
                      <div className="info" style={{flexGrow:'1'}} >
                        <div className="titulo" > {n.TITULO} </div>
                        <div className={'descripcion'}>
                          {n.DESCRIPCION}
                        </div>
                        <div className="hace" > {getDiff(n.FECHA_HORA_GENERA)} </div>                          
                      </div>

                      <img className="basurero" src={img+'basurero_gris.png'} alt="..." title='Eliminar notificación' onClick={e => clickEliminar(e,n['ID'])} />

                    </div>
                    ))
                  }
                </div>
              )
            }
          </div>

          <div className="cont-instancias5"></div>
        </div>
      </div>
     </div>
  );
}

export default withRouter(Lista);